import { createAction, props } from '@ngrx/store';

import { ArticleCategory } from '../../model/article.model';
import { Category } from '../../model/category-list.model';
import { BaseStateModel } from '@saep-ict/angular-core';

// model
export enum CategoryListActionEnum {
	UPDATE = '[Category List] Update',
	LOAD_RECURSIVELY = '[Category List] Load Recursively',
	LOAD_ALL = '[Category List] Load All',
	NOT_EXISTING = '[Category List] Not Existing',
	RESET = '[Category List] Reset',
	ERROR = '[Category List] Error'
}

export namespace CategoryListAction {
	export const update = createAction(
		CategoryListActionEnum.UPDATE,
		props<BaseStateModel<Category<ArticleCategory>[]>>()
	);
	export const loadRecursively = createAction(
		CategoryListActionEnum.LOAD_RECURSIVELY,
		props<BaseStateModel<Category<ArticleCategory>[]>>()
	);
	export const loadAll = createAction(CategoryListActionEnum.LOAD_ALL);
	export const notExisting = createAction(CategoryListActionEnum.NOT_EXISTING);
	export const reset = createAction(CategoryListActionEnum.RESET);
}
