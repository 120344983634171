<div class="container-fluid">
	<!-- HEADER -->
<div class="header">
	<!-- Quarter -->
	<div>{{ 'chart.field.time_range' | translate | sentencecase }}</div> &nbsp; &nbsp;
	<mat-form-field
				class="header__quarter"
				*ngIf="timeRange"
			>
				<mat-select
					[value]="timeRange[0].q"
					(selectionChange)="setTimeRange($event)"
				>
					<mat-option *ngFor="let rangeTemp of timeRange" [value]="rangeTemp.q">
						{{ 'general.time.quarter.' + rangeTemp.q | translate: { year: rangeTemp.y } | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

	<!-- Separator -->
	<div class="header__separator"></div>

	<!-- Date last update statistics -->
	<div class="header__statistics">
		{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
	</div>
</div>

<!-- TREND -->
<div class="row">
	<!-- Expired -->
	<div class="col-sm-12 col-md-12 col-lg-6">

	</div>

	<!-- Due -->
	<div class="col-sm-12 col-md-12 col-lg-6">
		
	</div>

</div>

<!-- CUSTOMERS WITH EXPIRED -->
<div class="row">
	<div class="col-12">
		
	</div>
</div>
</div>