<ng-container *ngIf="!accountCreated" [formGroup]="form">
	<div class="row">
		<!-- Tipologia utente -->
		<div class="col-lg-12">
			<mat-form-field>
				<mat-label>{{ 'organization.field.type' | translate | sentencecase }}</mat-label>
				<mat-select
					(selectionChange)="organizationTypeChange($event.value)"
					formControlName="organization_type"
				>
					<mat-option *ngFor="let item of organizationTypeList" [value]="item">
						{{ 'organization.type.' + item | translate | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>

	<form *ngIf="form.get('organization_type').value" class="registration-form">
		<!-- Dati personali -->
		<p class="col-lg-12 subtitle">
			{{ 'user.general_information' | translate | sentencecase }}
		</p>
		<organization-personal-data-form
			[(parentForm)]="form"
			[organization]="organization"
			[organizationTypeSelected]="organizationTypeSelected"
		></organization-personal-data-form>

		<!-- Sede legale / Residenza -->
		<p class="col-lg-12 subtitle">
			{{ organizationTypeSelected === organizationTypeEnum.COMPANY ? 'Sede legale' : 'Residenza' }}
		</p>
		<address-form [(parentForm)]="form" [organization]="organization"></address-form>

		<!-- User contact information -->
		<p class="col-lg-12 subtitle">
			{{ 'contact.details' | translate | sentencecase }}
		</p>
		<user-contact-information-form
		[parentForm]="form" 
		[organizationTypeSelected]="organizationTypeSelected"
		>
		</user-contact-information-form>

		<!-- Dettagli CRM -->
		<div *ngIf="user && currentContext === ContextApplicationItemCodeEnum.CRM">
			<p class="col-lg-12 subtitle">
				{{ 'general.details.name_plural' | translate | sentencecase }}
			</p>
			<organization-detail-data-form
				[(parentForm)]="form"
				[organizationTypeSelected]="organizationTypeSelected"
				[organization]="organization"
			>
			</organization-detail-data-form>
		</div>
	</form>

	<!-- Pulsante crea -->
	<button
		mat-raised-button
		color="primary"
		class="mt-3 mr-1"
		(click)="onFormSubmit()"
		[disabled]="!form.valid"
		*ngIf="form.get('organization_type').value"
	>
		<ng-container *ngIf="!currentContext">
			{{ 'login.general.new_account' | translate | sentencecase }}
		</ng-container>
		<ng-container *ngIf="currentContext">
			{{ (organization ? 'general.save' : 'organization.add_new') | translate | sentencecase }}
		</ng-container>
	</button>
	<!-- Pulsante annulla per dialog -->
	<button mat-stroked-button type="button" class="mt-3 mr-1" *ngIf="isDialog" (click)="closeDialog.emit(true)">
		{{ 'general.cancel' | translate | sentencecase }}
	</button>
</ng-container>

<!-- Account creato -->
<ng-container *ngIf="accountCreated">
	<!-- Messaggio di successo se NON ho un contesto - area pubblic b2c -->
	<ng-container *ngIf="!currentContext">
		<div class="success-message">
			<mat-icon>check_circle</mat-icon>
			<p class="title">
				{{ 'login.general.registration_success' | translate | sentencecase }}
			</p>
			<p class="subtitle">
				{{ 'login.general.registration_check' | translate | sentencecase }}
			</p>
		</div>
	</ng-container>

	<!-- Messaggio di successo se ho un contesto -->
	<ng-container *ngIf="currentContext">
		<div class="success-message">
			<mat-icon>check_circle</mat-icon>
			<p class="title">
				Organizazione registrata con successo
			</p>
		</div>
	</ng-container>

	<!-- Pulsante close per dialog -->
	<button mat-stroked-button type="button" *ngIf="isDialog" (click)="closeDialog.emit(true)">
		{{ 'general.close' | translate | sentencecase }}
	</button>
</ng-container>
