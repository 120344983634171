import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { OrganizationListActionEnum, OrganizationListStateAction } from './organization-list.actions';
import { ContextCodeFilterModel } from '../../../service/pouch-db/filter/context-code-filter.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';

export namespace OrganizationListStateReducer {
	export const initialState: BaseStateModel<OrganizationStateModel[], ContextCodeFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(OrganizationListStateAction.load, state => state),
		on(OrganizationListStateAction.loadAll, state => state),
		on(OrganizationListStateAction.update, (state, param) => param),
		on(OrganizationListStateAction.updateItem, (state, param) => param),
		on(OrganizationListStateAction.createSuccess, (state, param) => param),
		on(OrganizationListStateAction.deleteItemSuccess, (state, param) => param),
		on(OrganizationListStateAction.reset, () => initialState),
		on(OrganizationListStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<OrganizationStateModel[]>, action: TypedAction<OrganizationListActionEnum>) {
		return _reducer(state, action);
	}
}
