import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BasePouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
export enum AgentActionEnum {
	UPDATE = '[agent] Update',
	LOAD = '[agent] Load',
	RESET = '[agent] Reset',
	ERROR = '[agent] Error'
}

export namespace AgentStateAction {
	export const load = createAction(AgentActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const update = createAction(AgentActionEnum.UPDATE, props<BaseStateModel<BodyTablePouchModel>>());
	export const reset = createAction(AgentActionEnum.RESET);
	export const error = createAction(AgentActionEnum.ERROR, props<BaseStateModel<any>>());
}
