<form (ngSubmit)="sendAccessRequest()" [formGroup]="form">
	<mat-form-field>
		<input matInput formControlName="business_name" placeholder="{{ 'company.name' | translate | sentencecase }}" />
		<mat-error *ngIf="form.get('business_name').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<!-- <mat-form-field>
		<input
			matInput
			formControlName="address"
			placeholder="{{ 'general.address.address' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('address').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field> -->
	<mat-form-field>
		<input
			matInput
			formControlName="country"
			placeholder="{{ 'general.address.state' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('country').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<!-- <mat-form-field>
		<input
			matInput
			formControlName="piva"
			placeholder="{{ 'organization.field.vat_number' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('piva').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field> -->
	<mat-form-field>
		<input
			matInput
			formControlName="city"
			placeholder="{{
				('general.address.city' | translate) +
					' ' +
					('general.and' | translate) +
					' ' +
					('general.address.province' | translate) | sentencecase
			}}"
		/>
		<mat-error *ngIf="form.get('city').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<!-- <mat-form-field>
		<input
			matInput
			formControlName="client_code"
			placeholder="{{ 'organization.field.client_code' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('client_code').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field> -->
	<!-- <mat-form-field>
		<input
			matInput
			formControlName="reference"
			placeholder="{{ 'organization.field.referent' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('reference').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field> -->
	<mat-form-field>
		<input
			matInput
			formControlName="full_name"
			placeholder="{{ 'user.field.full_name' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('full_name').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input
			matInput
			formControlName="department"
			placeholder="{{ 'organization.field.department' | translate | sentencecase }}"
		/>
		<mat-error *ngIf="form.get('department').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="email" placeholder="{{ 'contact.field.email' | translate | sentencecase }}" />
		<mat-error *ngIf="form.get('email').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
		<mat-error *ngIf="form.get('email').hasError('email')">
			{{ 'general.validators.email' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput formControlName="phone" placeholder="{{ 'contact.field.phone' | translate | sentencecase }}" />
		<mat-error *ngIf="form.get('phone').hasError('required')">
			{{ 'general.validators.required' | translate | sentencecase }}
		</mat-error>
	</mat-form-field>

	<div class="w-100 mb-3 mt-2">
		<mat-checkbox required formControlName="privacyCheck">
			<span class="access-request_checkbox">
				{{ 'login.access_request.checkbox.start' | translate | sentencecase }}
				<a [routerLink]="['/', ROUTE_URL.privacyPolicy]" target="_blank">{{
					'informative_page.type.privacy_policy' | translate | sentencecase
				}}</a>
				{{ 'login.access_request.checkbox.end' | translate }}
			</span>
		</mat-checkbox>
	</div>
	<recaptcha (recaptchaResponseEmitter)="recaptchaResponse($event)"></recaptcha>

	<mat-toolbar>
		<button mat-raised-button type="submit" color="primary" class="d-block w-100">
			{{ 'login.access_request.send_request' | translate | sentencecase }}
		</button>
	</mat-toolbar>

	<div class="link mt-16">
		<a routerLink="/authentication/login"> {{ 'general.cancel' | translate | sentencecase }} </a>
	</div>
</form>
