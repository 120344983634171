<breadcrumb *ngIf="orderState" [title]="'order.list' | translate | sentencecase" [pathList]="['Home']"></breadcrumb>
<div list-wrapper #listWrapper [filterOpened]="false" [titleSidebar]="'tc.general.filters' | translate | sentencecase ">
	<ng-container main>
		<mat-card>
			<order-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				(selectOrderId)="goToOrderDetail($event)"
				(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code, currentContext)"
				(localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
				[localListHandlerSidebarFilterShow]="true"
				[localListHandlerTitle]="
					('order.list' | translate | sentencecase) +
					' ' +
					(orderState ? ' - ' + ('order.status.' + orderState | translate | sentencecase) : '')
				"
			>
			</order-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container side>
		<form [formGroup]="orderListFilterForm" (ngSubmit)="submitOrderListFilters()">
			<!-- TODO: ripristinare una volta importato MD date-range-picker -->
			<!-- <mat-form-field>
				<input matInput placeholder="Data creazione" [satDatepicker]="picker_date" formControlName="date" />
				<sat-datepicker #picker_date [rangeMode]="true"></sat-datepicker>
				<sat-datepicker-toggle matSuffix [for]="picker_date"></sat-datepicker-toggle>
			</mat-form-field> -->
			<!-- <mat-form-field>
				<input
					matInput
					placeholder="Data consegna"
					[satDatepicker]="picker_due_date"
					formControlName="due_date"
				/>
				<sat-datepicker #picker_due_date [rangeMode]="true"></sat-datepicker>
				<sat-datepicker-toggle matSuffix [for]="picker_due_date"></sat-datepicker-toggle>
			</mat-form-field> -->

			<!-- Filtro: company -->
			<!-- <mat-form-field *ngIf="orderState && filteredCustomerList">
				<input
					matInput
					type="text"
					placeholder="Azienda"
					aria-label="Assignee"
					formControlName="business_name"
					[matAutocomplete]="auto"
					[formControl]="companyNameCtrl"
				/>
				<mat-autocomplete #auto="matAutocomplete">
					<mat-option
						*ngFor="let companyName of filteredCustomerList | async"
						[value]="companyName.business_name"
					>
						{{ companyName.business_name }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field> -->

			<!-- Filtro: codice articolo in product_list -->
			<mat-form-field *ngIf="filteredArticleCode">
				<input
					matInput
					type="text"
					[placeholder]="'article.code' | translate | sentencecase"
					aria-label="article code"
					formControlName="article_code"
				/>
			</mat-form-field>

			<!-- Filtro: referenza interna in product_list -->
			<mat-form-field *ngIf="filteredArticleRefClient">
				<input
					matInput
					type="text"
					[placeholder]="'order.field.order_so_client_ref' | translate | sentencecase"
					aria-label="article ref client"
					formControlName="article_client_ref"
				/>
			</mat-form-field>

			<!-- Filtro: order status -->
			<mat-form-field *ngIf="!orderState">
				<mat-select multiple formControlName="order_states" [placeholder]="'order.field.status' | translate | sentencecase">
					<mat-option *ngFor="let item of orderStatesFilterList" [value]="item">
						<div class="chips-mini">
							<span [ngClass]="item.toUpperCase()"></span>
						</div>
						{{ 'order.status.' + item | translate | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-toolbar>
				<button class="mr-8" mat-raised-button color="primary" type="submit"> {{ 'general.apply_filters'  | translate | sentencecase }}</button>
				<button mat-button color="default" type="button" (click)="resetOrderFormFilter()"> {{ 'general.cancel'  | translate | sentencecase }} </button>
			</mat-toolbar>
		</form>
	</ng-container>
</div>

<!-- TOFIX (B2C context) -->
<button
	*ngIf="canAddNew"
	class="mat-fab-button"
	[ngClass]="{ 'mat-fab-button__b2c': currentContext === ContextApplicationItemCodeEnum.B2C }"
	(click)="createNew()"
	mat-fab
	[matTooltip]="'order.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
</button>
