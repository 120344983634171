import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
	selector: 'recaptcha',
	templateUrl: './recaptcha.component.html',
	styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {
	@ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
	@Output() recaptchaResponseEmitter = new EventEmitter();
	siteKeyRecaptcha: string = '6LfS2sUaAAAAAApXoB753mRJP7YL1DicInde2dPW'; // Valutare se rimuovere config. dal compomente

	constructor() {}

	ngOnInit() {
		this.addRecaptchaScript();
	}

	renderReCaptcha() {
		window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
			sitekey: this.siteKeyRecaptcha,
			callback: captcha => {
				this.recaptchaResponseEmitter.emit(captcha);
			}
		});
	}

	recaptchaExpired() {
		this.recaptchaResponseEmitter.emit(null);
	}

	addRecaptchaScript() {
		window['grecaptchaCallback'] = () => {
			this.renderReCaptcha();
		};
		window['recaptchaExpired'] = () => {
			this.recaptchaExpired();
		};
		(function(id, obj) {
			let js,
				fjs = document.getElementsByTagName('script')[0];
			if (document.getElementById(id)) {
				obj.renderReCaptcha();
				return;
			}
			js = document.createElement('script');
			js.id = id;
			js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
			fjs.parentNode.insertBefore(js, fjs);
		})('recaptcha-jssdk', this);
	}
}
