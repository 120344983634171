import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, Injector, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ResourceActionReturnType, ResourceGlobalConfig, ResourceHandler } from '@ngx-resource/core';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MediaCenterModule, MediaCenterTranslateLoader } from '@saep-ict/media-center';
import { TicketCenterModule } from '@saep-ict/ticket-center';
import { PouchdbModule } from '@saep-ict/pouch-db';
import { SortablejsModule } from 'ngx-sortablejs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CustomerAppConfig } from './customer-app.config';
import { FrameModule } from './frame/frame.module';
import { InjectorModule } from './injector.module';
import { PageModule } from './page/page.module';
import { RoutingModule } from './router/app-routing.module';
import { AuthLoginGuard } from './service/guard/auth-login.guard';
import { AuthTokenGuard } from './service/guard/auth-token.guard';
import { AuthUserStateGuard } from './service/guard/auth-user-state.guard';
import { UtilGuardService } from './service/guard/util-guard/util-guard.service';
import { VerifyTokenEmailGuard } from './service/guard/verify-token-email.guard';
import { PouchDbSpin8AgentAdapter } from './service/pouch-db/spin8/pouchdb-spin8-agent.adapter';
import { ProbeTestService } from './service/rest/probe-test.service';
import { LoaderService } from '@saep-ict/angular-core';
import { EventHandlerService } from './service/util/event-handler.service';
import { ResourceUrlEncodedHandler } from './service/util/resource-urlencoded-handler.service';
import { AgentStateModule } from './state/agent/agent-state.module';
import { AgentEffects } from './state/agent/agent.effects';
import { ContactStateModule } from './state/contact/contact-state.module';
import { ContactEffects } from './state/contact/contact.effects';
import { ArticleListEffects } from './state/article-list/article-list.effects';
import { ArticleListStateModule } from './state/article-list/article-list-state.module';
import { AuxiliaryTableStateModule } from './state/auxiliary-table/auxiliary-table-state.module';
import { AuxiliaryTableEffects } from './state/auxiliary-table/auxiliary-table.effects';
import { CategoryListStateModule } from './state/category-list/category-list-state.module';
import { CategoryListEffects } from './state/category-list/category-list.effects';
import { CompanyAccountBalanceStateModule } from './state/company-account-balance/company-account-balance-state.module';
import { CompanyAccountBalanceEffects } from './state/company-account-balance/company-account-balance.effects';
import { OrganizationStateModule } from './state/organization/organization-state.module';
import { OrganizationEffects } from './state/organization/organization.effects';
import { DestinationListStateModule } from './state/destination-list/destination-list-state.module';
import { DestinationListEffects } from './state/destination-list/destination-list.effects';
import { DestinationStateModule } from './state/destination/destination-state.module';
import { DestinationEffects } from './state/destination/destination.effects';
import { OrderListStateModule } from './state/order-list/order-list-state.module';
import { OrderListEffects } from './state/order-list/order-list.effects';
import { OrderStateModule } from './state/order/order-state.module';
import { OrderEffects } from './state/order/order.effects';
import { UserActivateStateModule } from './state/user-activate/user-activate-state.module';
import { UserActivateEffects } from './state/user-activate/user-activate.effects';
import { UserStateModule } from './state/user/user-state.module';
import { UserEffects } from './state/user/user.effects';
import { UserManagementStateModule } from './state/backoffice/user-management/user-management-state.module';
import { UserManagementEffects } from './state/backoffice/user-management/user-management.effects';
import { BackofficeUserListStateModule } from './state/backoffice/context-code/backoffice-user-list/backoffice-user-list-state.module';
import { BackofficeUserListEffects } from './state/backoffice/context-code/backoffice-user-list/backoffice-user-list.effects';
import { AgentListStateModule } from './state/backoffice/context-code/agent-list/agent-list-state.module';
import { AgentListEffects } from './state/backoffice/context-code/agent-list/agent-list.effects';
import { OrganizationListStateModule } from './state/common/organization-list/organization-list-state.module';
import { OrganizationListEffects } from './state/common/organization-list/organization-list.effects';
import { ContextCodeAssociationEffects } from './state/backoffice/context-code/context-code-association/context-code-association.effects';
import { ContextCodeAssociationStateModule } from './state/backoffice/context-code/context-code-association/context-code-association-state.module';
import { PermissionAuxiliaryTableStateModule } from './state/permission-auxiliary-table/permission-auxiliary-table-state.module';
import { PermissionAuxiliaryTableEffects } from './state/permission-auxiliary-table/permission-auxiliary-table.effects';
import { OpportunityStateModule } from './state/opportunity/opportunity-state.module';
import { OpportunityEffects } from './state/opportunity/opportunity.effects';
import { CookieService } from 'ngx-cookie-service';
import { UserResolver } from './service/resolvers/user.resolver';
import { B2cUserListEffects } from './state/backoffice/context-code/b2c-user-list/b2c-user-list.effects';
import { B2cUserListStateModule } from './state/backoffice/context-code/b2c-user-list/b2c-user-list-state.module';
import { CrmUserListStateModule } from './state/backoffice/context-code/crm-user-list/crm-user-list-state.module';
import { CrmUserListEffects } from './state/backoffice/context-code/crm-user-list/crm-user-list.effects';
import { HomeHighlightsEffects } from './state/home-highlights/home-highlights.effects';
import { HomeHighlightsStateModule } from './state/home-highlights/home-highlights-state.module';
import { LoginContextCodeEffects } from './state/common/login/context-code/login-context-code.effects';
import { LoginContextCodeStateModule } from './state/common/login/context-code/login-context-code.module';
import { SearchResultsStateModule } from './state/search-results/search-results-state.module';
import { SearchResultsEffects } from './state/search-results/search-results.effects';
import { CheckoutGuard } from './service/guard/checkout.guard';
import { StatisticsAgentStateModule } from './state/statistics-agent/statistics-agent.state.module';
import { StatisticsAgentEffects } from './state/statistics-agent/statistics-agent.effect';
import { StatisticsOrdersStateModule } from './state/statistics-orders/statistics-orders.state.module';
import { StatisticsOrdersEffects } from './state/statistics-orders/statistics-orders.effect';
import { LOCATION_INITIALIZED } from '@angular/common';
import { ConnectionActionEnum, ConnectionStateAction } from './state/connection/connection.actions';
import { StateFeature } from './state';
import { filter, take } from 'rxjs/operators';
import { ConnectionEffects } from './state/connection/connection.effects';
import { PouchAdapterSelectorService } from './service/pouch-db/pouch-adapter-selector.service';
import { PouchDbAgentAdapter } from './service/pouch-db/spin8/pouchdb-agent.adapter';
import { PouchDbOrganizationB2CAdapter } from './service/pouch-db/spin8/pouchdb-organization-b2c.adapter';
import { PouchDbCommonsAdapter } from './service/pouch-db/spin8/pouchdb-commons.adapter';
import { ConnectionStateModule } from './state/connection/connection-state.module';
import { ReceiptListEffects } from './state/receipt-list/receipt-list.effects';
import { ReceiptListStateModule } from './state/receipt-list/receipt-list-state.module';
import { OfferEffects } from './state/offer/offer.effects';
import { OfferStateModule } from './state/offer/offer-state.module';
import { InformativePageStateModule } from './state/informative-page/informative-page-state.module';
import { InformativePageEffects } from './state/informative-page/informative-page.effects';
import { KanbanEffects } from './state/kanban/kanban.effects';
import { KanbanStateModule } from './state/kanban/kanban-state.module';

import { Plugins } from '@capacitor/core';
import { EnvConfiguratorModule } from '@saep-ict/env-configurator';
import { ArticleDetailStateModule } from './state/article-detail/article-detail-state.module';
import { ArticleDetailEffects } from './state/article-detail/article-detail.effects';
import { PouchDbBackofficeAdapter } from './service/pouch-db/spin8/pouchdb-backoffice.adapter';
import { PouchDbCommonsOrganizationAdapter } from './service/pouch-db/spin8/pouchdb-commons-organization.adapter';
import { PouchDbCommonsArticleAdapter } from './service/pouch-db/spin8/pouchdb-commons-article.adapter';
import { PouchDbCommonsOrderAdapter } from './service/pouch-db/spin8/pouchdb-commons-order.adapter';
import { PouchDbCommonsUserAdapter } from './service/pouch-db/spin8/pouchdb-commons-user.adapter';
import { PouchDbCommonsAgentAdapter } from './service/pouch-db/spin8/pouchdb-commons-agent.adapter';
import { PouchDbCommonsBackofficeAdapter } from './service/pouch-db/spin8/pouchdb-commons-backoffice.adapter';
import { PouchDbCrmAdapter } from './service/pouch-db/spin8/pouchdb-crm.adapter';
import { StatisticsOrganizationEffects } from './state/statistics-organization/statistics-organization.effect';
import { StatisticsOrganizationStateModule } from './state/statistics-organization/statistics-organization.state.module';
import { StatisticsBackofficeStateModule } from './state/statistics-backoffice/statistics-backoffice.state.module';
import { StatisticsBackofficeEffects } from './state/statistics-backoffice/statistics-backoffice.effect';
import { StatisticsDetailOrdersEffects } from './state/statistics-detail-orders/statistics-detail-orders.effect';
import { StatisticsDetailOrdersStateModule } from './state/statistics-detail-orders/statistics-detail-orders.state.module';
import { StatisticsCrmEffects } from './state/statistics-crm/statistics-crm.effect';
import { StatisticsCrmStateModule } from './state/statistics-crm/statistics-crm.state.module';
import { StatisticsDetailSoldStateModule } from './state/statistics-detail-sold/statistics-detail-sold.state.module';
import { StatisticsDetailSoldEffects } from './state/statistics-detail-sold/statistics-detail-sold.effect';
import { StatisticsDetailClientsStateModule } from './state/statistics-detail-clients/statistics-detail-clients.state.module';
import { StatisticsDetailClientsEffects } from './state/statistics-detail-clients/statistics-detail-clients.effect';
import { StatisticsDetailExpiredStateModule } from './state/statistics-detail-expired/statistics-detail-expired.state.module';
import { StatisticsDetailExpiredEffects } from './state/statistics-detail-expired/statistics-detail-expired.effect';
import { MultiTranslateHttpLoader } from './service/util/multitranslate-http-loader.service';
import { PouchDbCommonsContactAdapter } from './service/pouch-db/spin8/pouchdb-commons-contact.adapter';
import { PouchDbOrganizationB2BAdapter } from './service/pouch-db/spin8/pouchdb-organization-b2b.adapter';
import { LanguageListStateModule } from './state/language-list/language-list-state.module';
import { LanguageListEffects } from './state/language-list/language-list.effects';
import { ArticleDescriptionEffects } from './state/article-description/article-description.effects';
import { ArticleDescriptionStateModule } from './state/article-description/article-description-state.module';
import { Language } from './enum/language.enum';

const { Network } = Plugins;

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient, customerAppConfig: CustomerAppConfig) {
	return new MultiTranslateHttpLoader(http, customerAppConfig, [
		{ prefix: './assets/i18n/', suffix: '.json' },
		{ configKey: 'mc_config' },
		{ configKey: 'tc_config' }
	]);
}

export function customResourceHandlerFactory(http: HttpClient) {
	return new ResourceUrlEncodedHandler(http);
}

export function getConnection(store: Store<any>) {
	return () =>
		new Promise(resolve => {
			// store.dispatch(new StartAppInitializer());
			store.dispatch(ConnectionStateAction.load());
			store
				.select(StateFeature.getConnectionState)
				.pipe(
					filter(
						connection => connection && connection.data && connection.type === ConnectionActionEnum.UPDATE
					),
					take(1)
				)
				.subscribe(connection => {
					// store.dispatch(new FinishAppInitializer());
					resolve(true);
				});
		});
}

export function appInitializerFactory(
	translate: TranslateService,
	injector: Injector,
	customerAppConfig: CustomerAppConfig,
	localStorageService: LocalStorageService
) {
	return () =>
		new Promise<any>((resolve: any) => {
			customerAppConfig.libInitializationDone$.subscribe((e: boolean) => {
				if (e) {
					const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
					locationInitialized.then(() => {
						const langToSet = localStorageService.retrieve('language') || Language.DEFAULT;
						translate.setDefaultLang(langToSet);
						translate.use(langToSet).subscribe(
							() => {
								// console.info(`Successfully initialized '${langToSet}' language.'`);
							},
							err => {
								console.error(`Problem with '${langToSet}' language initialization.'`);
							},
							() => {
								resolve(null);
							}
						);
					});
				}
			});
		});
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		// SharedModule,
		BrowserModule.withServerTransition({ appId: 'spin8' }),
		BrowserAnimationsModule,
		RoutingModule,
		SortablejsModule,
		NgScrollbarModule,
		HttpClientModule,
		ResourceModule.forRoot({
			handler: { provide: ResourceHandler, useFactory: customResourceHandlerFactory, deps: [HttpClient] }
		}),
		// TranslateModule.forRoot({
		// 	loader: {
		// 		provide: TranslateLoader,
		// 		// WITH MEDIACENTER
		// 		useExisting: MultiTranslateHttpLoader
		// 	}
		// }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient, CustomerAppConfig]
			}
		}),

		NgxWebstorageModule.forRoot(),
		// EnvironmentFunction
		EnvConfiguratorModule.forRoot({ env: environment, appConfig: CustomerAppConfig }),
		// CouchFunction
		PouchdbModule.forRoot({
			config: [
				PouchDbSpin8AgentAdapter,
				PouchDbAgentAdapter,
				PouchDbOrganizationB2CAdapter,
				PouchDbOrganizationB2BAdapter,
				PouchDbCommonsAdapter,
				PouchDbBackofficeAdapter,
				PouchDbCrmAdapter,
				PouchDbCommonsOrganizationAdapter,
				PouchDbCommonsArticleAdapter,
				PouchDbCommonsOrderAdapter,
				PouchDbCommonsUserAdapter,
				PouchDbCommonsAgentAdapter,
				PouchDbCommonsBackofficeAdapter,
				PouchDbCommonsContactAdapter
			],
			appConfig: CustomerAppConfig
		}),
		// NgRx - Store
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false
				}
			}
		),
		ConnectionStateModule,
		UserStateModule,
		AgentStateModule,
		OrganizationStateModule,
		ContactStateModule,
		UserActivateStateModule,
		OrderStateModule,
		OrderListStateModule,
		AuxiliaryTableStateModule,
		DestinationStateModule,
		DestinationListStateModule,
		StatisticsAgentStateModule,
		StatisticsOrganizationStateModule,
		StatisticsBackofficeStateModule,
		StatisticsCrmStateModule,
		StatisticsDetailOrdersStateModule,
		StatisticsDetailSoldStateModule,
		StatisticsDetailClientsStateModule,
		StatisticsDetailExpiredStateModule,
		StatisticsOrdersStateModule,
		ArticleListStateModule,
		ArticleDescriptionStateModule,
		CategoryListStateModule,
		OrganizationListStateModule,
		ReceiptListStateModule,
		InformativePageStateModule,
		LanguageListStateModule,

		// B2C
		HomeHighlightsStateModule,
		SearchResultsStateModule,
		ArticleDetailStateModule,

		// Backoffice
		UserManagementStateModule,
		CompanyAccountBalanceStateModule,
		BackofficeUserListStateModule,
		AgentListStateModule,
		ContextCodeAssociationStateModule,
		PermissionAuxiliaryTableStateModule,
		B2cUserListStateModule,
		CrmUserListStateModule,

		// CRM
		OpportunityStateModule,
		OfferStateModule,

		// Kanban
		KanbanStateModule,

		// login
		LoginContextCodeStateModule,
		EffectsModule.forRoot([
			ConnectionEffects,
			AgentEffects,
			OrganizationEffects,
			UserEffects,
			UserActivateEffects,
			OrderEffects,
			OrderListEffects,
			AuxiliaryTableEffects,
			DestinationEffects,
			DestinationListEffects,
			ContactEffects,
			StatisticsAgentEffects,
			StatisticsOrganizationEffects,
			StatisticsBackofficeEffects,
			StatisticsOrdersEffects,
			ArticleListEffects,
			ArticleDescriptionEffects,
			CategoryListEffects,
			OrganizationListEffects,
			InformativePageEffects,
			LanguageListEffects,
			StatisticsDetailOrdersEffects,
			StatisticsDetailSoldEffects,
			StatisticsDetailClientsEffects,
			StatisticsDetailExpiredEffects,
			StatisticsCrmEffects,

			// Backoffice
			UserManagementEffects,
			CompanyAccountBalanceEffects,
			BackofficeUserListEffects,
			AgentListEffects,
			ContextCodeAssociationEffects,
			PermissionAuxiliaryTableEffects,

			// B2C
			B2cUserListEffects,
			HomeHighlightsEffects,
			SearchResultsEffects,
			ArticleDetailEffects,

			// CRM
			CrmUserListEffects,
			OpportunityEffects,
			OfferEffects,

			// Kanban
			KanbanEffects,

			// login
			LoginContextCodeEffects
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		// structure modules
		FrameModule,
		PageModule,
		InjectorModule,
		// saep-ict - media-center
		MediaCenterModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		}),
		// saep-ict - ticket-center
		TicketCenterModule.forRoot({
			appConfig: CustomerAppConfig,
			env: environment,
			loaderSerivce: LoaderService
		}),
		EffectsModule.forFeature([ReceiptListEffects])
	],
	providers: [
		PouchAdapterSelectorService,
		PouchDbSpin8AgentAdapter,
		PouchDbAgentAdapter,
		PouchDbOrganizationB2CAdapter,
		PouchDbOrganizationB2BAdapter,
		PouchDbCommonsAdapter,
		PouchDbBackofficeAdapter,
		PouchDbCrmAdapter,
		PouchDbCommonsOrganizationAdapter,
		PouchDbCommonsArticleAdapter,
		PouchDbCommonsOrderAdapter,
		PouchDbCommonsUserAdapter,
		PouchDbCommonsAgentAdapter,
		PouchDbCommonsBackofficeAdapter,
		PouchDbCommonsContactAdapter,
		EventHandlerService,
		DeviceDetectorService,
		MatIconRegistry,
		UtilGuardService,
		AuthTokenGuard,
		AuthUserStateGuard,
		AuthLoginGuard,
		VerifyTokenEmailGuard,
		UserResolver,
		ProbeTestService,
		TranslatePipe,
		CookieService,
		CheckoutGuard,
		ConnectionEffects,
		{ provide: APP_INITIALIZER, useFactory: getConnection, deps: [[new Inject(Store)]], multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector, CustomerAppConfig, LocalStorageService],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private store: Store<any>) {
		ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
	}

	private handler = Network.addListener('networkStatusChange', status => {
		console.log('Network status changed', status);
		this.store.dispatch(ConnectionStateAction.load());
	});
}
