import { Component, OnDestroy } from '@angular/core';
import { StatisticsDetailClientsStateAction } from '../../../../state/statistics-detail-clients/statistics-detail-clients.action';
import { StatisticsDetailOrdersStateAction } from '../../../../state/statistics-detail-orders/statistics-detail-orders.action';
import { chartTimeRange30Days } from '../../../../constants/chart-time-range.constants';
import { Store } from '@ngrx/store';
import { StatisticsDetailClients } from '../../../../model/statistics-detail-clients.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { filter, take } from 'rxjs/operators';
import { StateFeature } from '../../../../state';
import { ActiveClientsChartConfigService } from '../../../../service/chart-structure/implementation/active-clients-chart.service';
import { InactiveClientsChartConfigService } from '../../../../service/chart-structure/implementation/inactive-clients-chart.service';
import { Observable } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'dashboard-clients',
	templateUrl: './dashboard-clients.component.html',
	styleUrls: ['./dashboard-clients.component.scss'],
	providers: [ActiveClientsChartConfigService, InactiveClientsChartConfigService]
})
export class DashboardClientsComponent implements OnDestroy {
	timeRange = chartTimeRange30Days;
	dateUpdatedStatistics: number;

	// store
	statistics$: Observable<BaseStateModel<StatisticsDetailClients>> = this.store.select(
		StateFeature.getStatisticsDetailClients
	);
	statistics: StatisticsDetailClients;

	constructor(
		private store: Store<StatisticsDetailClients>,
		public activeClientsChartConfigService: ActiveClientsChartConfigService,
		public inactiveClientsChartConfigService: InactiveClientsChartConfigService
	) {
		// Load statistics clients
		this.store.dispatch(StatisticsDetailClientsStateAction.load());

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsDetailClients>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.update_date;
			});
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [this.activeClientsChartConfigService, this.inactiveClientsChartConfigService];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.store.dispatch(StatisticsDetailOrdersStateAction.reset());
	}
}
