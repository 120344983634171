import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	CurrentContextPermission,
	PermissionAuxiliaryDictionary,
	PermissionAuxiliaryTableStateModel,
	RestBasePk,
	UserDetailModel,
	ContextCodeAssociation
} from '@saep-ict/angular-core';
import { OfflineDeviceService, PouchDbAdapter, PouchUtilService } from '@saep-ict/pouch-db';
import { BodyTablePouchModel, OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { catchError, filter, map, skipWhile, take, takeUntil, mergeMap } from 'rxjs/operators';

import { CustomerAppConfig, CustomerAppConfigModel } from '../../customer-app.config';
import { ConfigurationPlaceholder } from '../../enum/configuration-placeholder.enum';
import { ConnectionModel } from '../../model/connection.model';
import { ROUTE_URL } from '../../router/route-naming';
import { StateFeature } from '../../state';
import { ArticleListStateAction } from '../../state/article-list/article-list.actions';
import {
	ContextCodeAssociationActionEnum,
	ContextCodeAssociationStateAction
} from '../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { CategoryListAction } from '../../state/category-list/category-list.actions';
import { OrganizationListStateAction } from '../../state/common/organization-list/organization-list.actions';
import { HomeHighlightsStateAction } from '../../state/home-highlights/home-highlights.actions';
import { PermissionAuxiliaryTableStateAction } from '../../state/permission-auxiliary-table/permission-auxiliary-table.actions';
import { StatisticsAgentStateAction } from '../../state/statistics-agent/statistics-agent.action';
import { ContextType, UtilGuardService } from '../guard/util-guard/util-guard.service';
import { PouchDbAgentAdapter } from '../pouch-db/spin8/pouchdb-agent.adapter';
import { AuthService } from '../rest/auth.service';
import { UserService } from '../rest/user.service';
import { OrderStateAction } from '../../state/order/order.actions';
import { OrderWithArticleDetailRequest } from '../../model/state/order-state.model';
import { StatisticsOrganizationStateAction } from '../../state/statistics-organization/statistics-organization.action';
import { StatisticsBackofficeStateAction } from '../../state/statistics-backoffice/statistics-backoffice.action';
import { StatisticsCrmStateAction } from '../../state/statistics-crm/statistics-crm.action';
import { ArticleDescriptionStateAction } from '../../state/article-description/article-description.actions';
import { LinkCodeModel } from '@saep-ict/angular-core';

// TODO da riportare in libreria nell'oggetto UserTypeContextModel
const customAssociation = [
	{
		configurationPlaceholder: ConfigurationPlaceholder.AGENT_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.AGENT
	},
	{
		configurationPlaceholder: ConfigurationPlaceholder.ORGANIZATION_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.B2B
	},
	{
		configurationPlaceholder: ConfigurationPlaceholder.ORGANIZATION_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.B2C
	},
	{
		configurationPlaceholder: ConfigurationPlaceholder.BACKOFFICE_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.BACKOFFICE
	},
	{
		configurationPlaceholder: ConfigurationPlaceholder.CRM_CODE_PLACEHOLDER,
		type: ContextApplicationItemCodeEnum.CRM
	}
];

@Injectable({
	providedIn: 'root'
})
export class ContextManagerService {
	@LocalStorage('user') user: UserDetailModel;
	@LocalStorage('permissions') permissions: PermissionAuxiliaryTableStateModel;
	@LocalStorage('current_order_id') current_order_id: string;
	@LocalStorage('link_code') currentPermission: LinkCodeModel;

	destroy$: Subject<boolean> = new Subject<boolean>();
	contextState$: Observable<BaseStateModel<ContextType>>;
	connection$: Observable<BaseStateModel<ConnectionModel>> = this.store.select(StateFeature.getConnectionState);
	contextCodeAssociation$: Observable<BaseStateModel<ContextCodeAssociation>> = this.store.select(
		StateFeature.getContextCodeAssociationState
	);

	constructor(
		private store: Store<any>,
		private userService: UserService,
		private authService: AuthService,
		protected appConfig: CustomerAppConfig,
		private pouchUtilService: PouchUtilService,
		private utilGuardService: UtilGuardService,
		private offlineDeviceService: OfflineDeviceService,
		private router: Router
	) {}

	// Device Case:
	// The first time I have to be online for retrieve the user's info, the next time I read it from localstorage
	// Browser Case:
	// I always retrieve the user's info from Be
	async retrieveUserInfo(): Promise<UserDetailModel> {
		// retrieving the user_id from the decoded token
		if (!this.authService && !this.authService.tokenPayload && !this.authService.tokenPayload.user_id) {
			throw new Error(`You must be logged in to retrieve this information`);
		}
		const userDetailRequest: RestBasePk = { id: this.authService.tokenPayload.user_id };
		if (!this.user || !(await this.retrieveConnectionState())) {
			// retrieving user payload
			this.user = (await this.userService.getUserDetail(userDetailRequest)).data;
			// retrieving avatar
			await this.userService
				.getUserAvatar({ id: userDetailRequest.id })
				.then(res => (this.user.avatar = res.icon))
				.catch(err => console.error(err));
		} else if (!this.user && (await this.retrieveConnectionState())) {
			throw new Error(`You must be online to get user info!`);
		}
		return this.user;
	}

	// Device Case:
	// The first time I have to be online for retrieve the permissions' info, the next time I read it from localstorage
	// Dopodichè le leggo da localstorage
	// I always retrieve the permissions' info from Be
	async retrieveAuxiliaryPermissionList() {
		if (!this.isLocalPermissionOk() || !(await this.retrieveConnectionState())) {
			this.store.dispatch(PermissionAuxiliaryTableStateAction.load());
		} else if (!this.isLocalPermissionOk() && (await this.retrieveConnectionState())) {
			throw new Error(`You must be online to get permissions info!`);
		}
		// TODO verify async
		this.store.dispatch(PermissionAuxiliaryTableStateAction.update(new BaseState(this.permissions)));
	}

	// start only generic db
	async connectToGenericDb() {
		const toStartDbList = this.appConfig.config.couch.filter(config => !config['context']);
		await this.pouchUtilService.explicitInitCouch(toStartDbList).catch(err => {
			console.log(err);
			throw new Error(`Can't start couchdb`);
		});
	}

	async initContextDb(current_context_type: ContextApplicationItemCodeEnum): Promise<boolean> {
		// retrieving operator code, use this method only if the context isn't ambiguous
		const contextLink = this.utilGuardService.checkUserContext(this.user, current_context_type);
		const codeOperator = contextLink.currentContext.context_code_list
			.find(code_list => code_list.code === this.currentPermission.code)
			?.code.toString();
		// TODO chante find to UserTypeContextModel
		const userTypeContext = customAssociation.find(current_context => {
			return current_context.type === current_context_type;
		});
		const config: CustomerAppConfigModel = await this.appConfig.config$.pipe(take(1)).toPromise();

		// change the configuration to init the correct couchdb
		const toStartDbList = config.couch.filter(couchConf => {
			if (couchConf.context && couchConf.context.includes(current_context_type)) {
				couchConf.database = couchConf.baseDatabaseTemplate.replace(
					userTypeContext.configurationPlaceholder,
					codeOperator
				);
				this.appConfig.config = config;
				return true;
			}
		});
		// change the database attribute in the couch/pouch instance
		this.pouchUtilService.pouchInstance.map((pouchInstance: PouchDbAdapter) => {
			if (pouchInstance['variation'] && pouchInstance['variation'] === current_context_type) {
				pouchInstance.database = pouchInstance.baseDatabaseTemplate.replace(
					userTypeContext.configurationPlaceholder,
					codeOperator
				);
			}
		});
		// only init the specific db
		if (toStartDbList && toStartDbList.length > 0) {
			try {
				await this.pouchUtilService.explicitInitCouch(toStartDbList);
			} catch(err) {
				throw new Error('Cannot start specific database');
			}
		}
		return true;
	}

	/**
	 * the pipe observe the state of the passed context, check if the context operator code
	 * exist and return the payload of the relative dispatched document
	 *
	 * @example - with current context Agent observe in the state the document agent_339
	 * @param key - current context application
	 */
	initObservableState(key: ContextApplicationItemCodeEnum): Observable<ContextType> {
		this.contextState$ = this.store.select(this.utilGuardService.retrieveContextPermission(key).state);
		return this.contextState$.pipe(
			filter((contextState: BaseStateModel<ContextType>) => contextState != null && contextState.data != null),
			takeUntil(this.destroy$),
			map((contextState: BaseStateModel<ContextType>) => {
				return contextState.data;
			})
		);
	}

	/**
	 * Method to formatting Object with current context and linked data
	 *
	 * @param context_application - Context Code Application Enum (B2B, AGENT ecc...)
	 * @param context_code - Context Code Operator (339, 810 ecc..)
	 * @param permission - list of current permission
	 * @returns Object contains current context_application, context_code, permission and context_code_association list
	 */
	async returnUserCurrentPermission(
		context_application: ContextApplicationItemCodeEnum,
		context_code: string,
		permission: string[]
	): Promise<CurrentContextPermission> {
		const currentContextPermission: CurrentContextPermission = {
			context_application: context_application,
			context_code: {
				code: context_code,
				context_code_association_list: []
			},
			permission: permission
		};
		let context_code_association: ContextCodeAssociation;
		let hasContextCodeAssociation: boolean;
		try {
			this.store.dispatch(
				ContextCodeAssociationStateAction.load(
					new BaseState({ _id: `context_code_association_${context_code}` })
				)
			);
			hasContextCodeAssociation = await new Promise(resolve => {
				this.contextCodeAssociation$
					.pipe(
						skipWhile(
							(contextCodeAssociation: BaseStateModel<ContextCodeAssociation>) => !contextCodeAssociation
						),
						take(1),
						map((contextCodeAssociation: BaseStateModel<ContextCodeAssociation>) => {
							if (contextCodeAssociation.type === ContextCodeAssociationActionEnum.ERROR) {
								resolve(false);
							}
							context_code_association = contextCodeAssociation.data;
							resolve(true);
						}),
						catchError(error => {
							resolve(false);
							return error;
						})
					)
					.subscribe();
			});
		} catch (err) {
			console.log(err);
		}
		if (
			hasContextCodeAssociation &&
			context_code_association &&
			context_code_association.context_code_association_list &&
			context_code_association.context_code_association_list.length > 0
		) {
			currentContextPermission.context_code.context_code_association_list =
				context_code_association.context_code_association_list;
		}
		return currentContextPermission;
	}

	/**
	 * offlineMode True: if the sqlite local db doesn't exist redirect to the startupDownload to download the data
	 *
	 * @returns boolean false if in offlinemode db isn't syncronized
	 */
	async verifyLocalDbSync(): Promise<boolean> {
		let connection: ConnectionModel;
		this.connection$.pipe(take(1)).subscribe(res => {
			connection = res ? res.data : null;
		});
		const selectedDevice = await this.offlineDeviceService.init();
		// retrieve only agent instance
		const contextDBInstance: PouchDbAdapter = <PouchDbAdapter>(
			this.pouchUtilService.pouchInstance.find(x => x instanceof PouchDbAgentAdapter)
		);
		if (!connection.offline && selectedDevice.offlineMode && (await contextDBInstance.isDbSyncronized())) {
			this.router.navigate([ROUTE_URL.startupDownload]);
			return false;
		} else {
			await this.offlineDeviceService.init();
		}
		return true;
	}

	async retrieveConnectionState(): Promise<boolean> {
		return (await this.store.select(StateFeature.getConnectionState).pipe(take(1)).toPromise()).data.offline;
	}

	isLocalPermissionOk() {
		return this.permissions && PermissionAuxiliaryDictionary.every(x => this.permissions[x.key]);
	}

	manageNotAuthenticated(currentUrl: string): boolean {
		// se nel "permissionContext": [1, 2, 3, 4, 5, 7], c'è quello del B2C, allora non sloggo
		if (
			this.appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C) &&
			!currentUrl.includes(ROUTE_URL.private)
		) {
			this.loadStorefrontContent();
			return true;
		} else {
			// altrimenti redirect al login per la sezione privata
			this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.login]);
			return false;
		}
	}

	/**
	 * Carico configurazione iniziale storefront (contenuti home, categorie e articoli, metodi di pagamento, ecc...)
	 */
	loadStorefrontContent(data?: OrganizationPouchModel) {
		this.store.dispatch(CategoryListAction.loadAll());
		this.store.dispatch(ArticleListStateAction.loadAll({ forceDefault: false }));
		this.store.dispatch(HomeHighlightsStateAction.load());
		// carica ultimo ordine per visualizzazione carrello (e checkout)
		if (this.current_order_id) {
			const requestOrderDetail: OrderWithArticleDetailRequest = {
				id: this.current_order_id,
				orderData: { organization: data, article: {} }
			};
			this.store.dispatch(OrderStateAction.loadWithDetail(new BaseState(requestOrderDetail)));
		}
	}

	async retrieveStateForContext(context: ContextApplicationItemCodeEnum, data: ContextType): Promise<boolean> {
		// aggiungere la casistica quando si entra come organization direttamente
		if (context === ContextApplicationItemCodeEnum.AGENT) {
			data = data as BodyTablePouchModel;

			// Carico organization list
			this.store.dispatch(OrganizationListStateAction.loadAll());
			const organizationList$ = this.store.select(StateFeature.getOrganizationListState);

			// Load Statistics agent
			this.store.dispatch(
				StatisticsAgentStateAction.load(new BaseState({ code_item: this.currentPermission.code }))
			);
			const statisticsAgent$ = this.store.select(StateFeature.getStatisticsAgent);

			// TODO: remove after testing
			// this.store.dispatch(CategoryListAction.loadAll());
			this.store.dispatch(CategoryListAction.notExisting());
			const categoryList$ = this.store.select(StateFeature.getCategoryListState);

			// se non recupero tutti i dati non vado oltre
			return new Promise(resolve => {
				organizationList$
					.pipe(
						filter(organizationList => !!(organizationList && organizationList.data)),
						mergeMap(organizationList => statisticsAgent$),
						filter(statisticsAgent => !!(statisticsAgent && statisticsAgent.data)),
						mergeMap(statisticsAgent => categoryList$),
						filter(categoryList => !!categoryList), // TODO - categoryList è vuoto quindi non controllo data
						map(categoryList => categoryList),
						take(1)
					)
					.subscribe(res => resolve(true));
			});

			// Load Statistics orders
			// let statisticsOrderFilter: BaseState<StatisticsOrders, StatisticsOrdersFilter> = {
			// 	data: {},
			// 	type: '',
			// 	dataSetting: {
			// 		appliedFilter: {
			// 			code_agent: data.code_item
			// 		}
			// 	}
			// };
			// this.store.dispatch(StatisticsOrdersStateAction.load(statisticsOrderFilter));

			// // TODO: definire quando dispatchare questa oppure la NOT_EXISTING
			// this.store.dispatch(
			// 	CategoryListAction.loadRecursively({
			// 		data: null,
			// 		dataSetting: {
			// 			adapter: PouchAdapterEnum.AGENT // potrebbe essere anche B2B su DB separato
			// 		}
			// 	})
			// );
		} else if (context === ContextApplicationItemCodeEnum.BACKOFFICE) {
			// TODO Gestire informazioni BO
			this.store.dispatch(CategoryListAction.notExisting());
			// this.store.dispatch(ArticleDescriptionStateAction.loadDescriptionFromRecap());

			// Load Statistics Backoffice
			this.store.dispatch(
				StatisticsBackofficeStateAction.load(new BaseState({ code_item: this.currentPermission.code }))
			);
			const statisticsBackoffice$ = this.store.select(StateFeature.getStatisticsBackoffice);

			// se non recupero tutti i dati non vado oltre
			return new Promise(resolve => {
				statisticsBackoffice$
					.pipe(
						filter(statisticsBackoffice => !!statisticsBackoffice), // TODO - statisticsBackoffice attualmente è vuoto quindi non controllo data
						map(statisticsBackoffice => statisticsBackoffice),
						take(1)
					)
					.subscribe(res => resolve(true));
			});
		} else if (context === ContextApplicationItemCodeEnum.CRM) {
			// Load Statistics CRM
			this.store.dispatch(
				StatisticsCrmStateAction.load(new BaseState({ code_item: this.currentPermission.code }))
			);
			const statisticsCrm$ = this.store.select(StateFeature.getStatisticsCrm);

			// se non recupero tutti i dati non vado oltre
			return new Promise(resolve => {
				statisticsCrm$
					.pipe(
						filter(statisticsCrm => !!statisticsCrm), // TODO - statisticsCrm attualmente è vuoto quindi non controllo data
						map(statisticsCrm => statisticsCrm)
					)
					.subscribe(res => resolve(true));
			});
		} else if (context === ContextApplicationItemCodeEnum.B2B) {
			this.store.dispatch(OrganizationListStateAction.loadAll());
			this.store.dispatch(CategoryListAction.notExisting());
			// this.store.dispatch(ArticleDescriptionStateAction.loadDescriptionFromRecap());

			// Load Statistics Organization
			this.store.dispatch(
				StatisticsOrganizationStateAction.load(new BaseState({ code_item: this.currentPermission.code }))
			);
			const statisticsOrganization$ = this.store.select(StateFeature.getStatisticsOrganization);

			// se non recupero tutti i dati non vado oltre
			return new Promise(resolve => {
				statisticsOrganization$
					.pipe(
						filter(statisticsOrganization => !!(statisticsOrganization && statisticsOrganization.data)),
						map(statisticsOrganization => statisticsOrganization),
						take(1)
					)
					.subscribe(res => resolve(true));
			});
		} else if (context === ContextApplicationItemCodeEnum.B2C) {
			this.loadStorefrontContent(data as OrganizationPouchModel);
			return new Promise(resolve => resolve(true));
		} else {
			return new Promise(resolve => resolve(true));
		}
	}
}
