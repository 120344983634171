import { Route } from '@angular/router';
import { PermissionKeyEnum } from '@saep-ict/angular-core';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { OrganizationComponent } from '../page/commons/organization/organization.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { ROUTE_URL } from './route-naming';
import { OpportunityComponent } from '../page/crm/opportunity/opportunity.component';
import { OpportunityKanbanComponent } from '../page/crm/opportunity/opportunity-kanban/opportunity-kanban.component';
import { OpportunityDetailComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail.component';
import { OpportunityDetailMainComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail-main/opportunity-detail-main.component';
import { OpportunityDetailCommentComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail-comment/opportunity-detail-comment.component';
import { OpportunityDetailActivityComponent } from '../page/crm/opportunity/opportunity-detail/opportunity-detail-activity/opportunity-detail-activity.component';
import { UserComponent } from '../page/commons/user/user.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailCommentComponent } from '../page/commons/organization/organization-detail/organization-detail-comment/organization-detail-comment.component';
import { OfferComponent } from '../page/crm/offer/offer.component';
import { OfferDetailComponent } from '../page/crm/offer/offer-detail/offer-detail.component';
import { OfferDetailHeadComponent } from '../page/crm/offer/offer-detail/offer-detail-head/offer-detail-head.component';
import { OfferDetailBodyComponent } from '../page/crm/offer/offer-detail/offer-detail-body/offer-detail-body.component';
import { DashboardCrmComponent } from '../page/crm/dashboard-crm/dashboard-crm.component';
import { FramePageComponent } from '../frame/admin/admin.component';

export namespace CrmRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: FramePageComponent,
			children: [
				// Root
				{
					path: '',
					component: DashboardCrmComponent,
					data: {
						permissionKey: [PermissionKeyEnum.CRM_DASHBOARD],
						meta: {
							title: 'CRM Dashboard',
							description: 'CRM Dashboard'
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				// User Profile
				{
					path: ROUTE_URL.user,
					component: UserComponent,
					data: {
						permissionKey: [PermissionKeyEnum.CRM_PROFILE],
						meta: {
							title: 'Profilo utente',
							description: "Sezione del profilo dell'utente"
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				// Organizations
				{
					path: `${ROUTE_URL.organizations}/:organizationLevel`,
					data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_LIST] },
					children: [
						{
							path: '',
							component: OrganizationComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_LIST],
								meta: {
									title: 'Lista organizzazioni',
									description: 'Lista delle organizzazioni filtrate per tipo'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idOrganization',
							component: OrganizationDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL],
								meta: {
									title: 'Dettaglio azienda',
									description: "Sezione di dettaglio dell'azienda"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.details,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.details,
									component: OrganizationDetailFullDetailComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.comments,
									component: OrganizationDetailCommentComponent,
									data: {
										refreshChild: true,
										permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL]
									},
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.addresses,
									component: OrganizationDetailDestinationComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.contacts,
									component: ContactComponent,
									data: {
										refreshChild: true,
										permissionKey: [PermissionKeyEnum.CRM_CONTACT_LIST]
									},
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.credits,
									component: OrganizationDetailCreditComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_ORGANIZATION_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.opportunity,
									component: OpportunityComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] },
									pathMatch: 'full'
								}
								// Attività
							]
						}
					]
				},
				// Contacts
				{
					path: ROUTE_URL.contacts,
					data: { permissionKey: [PermissionKeyEnum.CRM_CONTACT_LIST] },
					children: [
						{
							path: '',
							data: {
								permissionKey: [PermissionKeyEnum.CRM_CONTACT_LIST],
								meta: {
									title: 'Contatti agente',
									description: "Lista dei contatti associati all'agente"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							component: ContactComponent
						}
					]
				},
				// Opportunity
				{
					path: `${ROUTE_URL.opportunity}/:opportunityType`,
					data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST] }, // TODO - CRM_OPPORTUNITY_LIST
					children: [
						{
							path: '',
							component: OpportunityComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_LIST], // TODO - CRM_OPPORTUNITY_LIST
								meta: {
									title: 'Lista opportunity',
									description: 'Lista delle opportunity'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idOpportunity',
							component: OpportunityDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL], // TODO - CRM_OPPORTUNITY_DETAIL
								meta: {
									title: 'Dettaglio opportunity',
									description: "Sezione di dettaglio dell'opportunity"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.opportunityDetail,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] }, // TODO - CRM_OPPORTUNITY_DETAIL
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.opportunityDetail,
									component: OpportunityDetailMainComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] } // TODO - CRM_OPPORTUNITY_DETAIL
								},
								{
									path: ROUTE_URL.opportunityComment,
									component: OpportunityDetailCommentComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] } // TODO - CRM_OPPORTUNITY_DETAIL
								},
								{
									path: ROUTE_URL.opportunityActivity,
									component: OpportunityDetailActivityComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OPPORTUNITY_DETAIL] } // TODO - CRM_OPPORTUNITY_DETAIL
								},
								{
									path: ROUTE_URL.opportunityOffer,
									component: OfferComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST] } // TODO - CRM_OPPORTUNITY_DETAIL
								}
							]
						}
					]
				},
				// opportunity - kanban
				{
					path: `${ROUTE_URL.opportunityKanban}`,
					data: { permissionKey: [PermissionKeyEnum.CRM_DASHBOARD] }, // TODO - CRM_OPPORTUNITY_LIST
					component: OpportunityKanbanComponent
				},
				// offer
				{
					path: ROUTE_URL.offer,
					data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST] },
					children: [
						{
							path: '',
							component: OfferComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OFFER_LIST],
								meta: {
									title: 'Lista offerte',
									description: 'Lista delle offerte'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idOpportunity/:idOffer',
							component: OfferDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL],
								meta: {
									title: 'Dettaglio offerta',
									description: "Sezione di dettaglio dell'offerta"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.offerHead,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.offerHead,
									component: OfferDetailHeadComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] }
								},
								{
									path: ROUTE_URL.offerBody,
									component: OfferDetailBodyComponent,
									data: { permissionKey: [PermissionKeyEnum.CRM_OFFER_DETAIL] }
								}
							]
						}
					]
				}
			]
		}
	];
}
