<div class="row">
	<div class="col-md-12">
		<h2 mat-dialog-title>
			{{
				(data.opportunityType === opportunityTypeEnum.LEAD ? 'request.close' : 'opportunity.close')
					| translate
					| sentencecase
			}}
		</h2>
	</div>
</div>

<mat-dialog-content>
	<!-- Lead -->
	<ng-container *ngIf="data.opportunityType === opportunityTypeEnum.LEAD">
		{{ 'opportunity.question.close_lead' | translate | sentencecase }}
	</ng-container>

	<!-- Prospect/Client -->
	<ng-container *ngIf="data.opportunityType !== opportunityTypeEnum.LEAD">
		{{ 'opportunity.question.close_not_lead' | translate | sentencecase }}
	</ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-button [mat-dialog-close]="false">
		Annulla
	</button>

	<!-- Lead -->
	<ng-container *ngIf="data.opportunityType === opportunityTypeEnum.LEAD">
		<button
			mat-raised-button
			type="button"
			color="primary"
			(click)="closeDialog(opportunityStatusCodeEnum.Rejected)"
		>
			{{ 'request.close' | translate | sentencecase }}
		</button>
	</ng-container>

	<!-- Prospect/Client -->
	<ng-container *ngIf="data.opportunityType !== opportunityTypeEnum.LEAD">
		<button mat-stroked-button type="button" (click)="closeDialog(opportunityStatusCodeEnum.Lost)">
			{{ 'opportunity.status.LOST' | translate | sentencecase }}
		</button>

		<button mat-raised-button type="button" color="primary" (click)="closeDialog(opportunityStatusCodeEnum.Won)">
			{{ 'opportunity.status.WON' | translate | sentencecase }}
		</button>
	</ng-container>
</mat-dialog-actions>
