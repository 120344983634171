import { Injectable } from '@angular/core';

// widget & utility
import { BaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';

@Injectable({
	providedIn: 'root'
})
export class PermissionService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/{!endpoint}'
	})
	getPermissionAuxiliaryTable: IResourceMethodPromise<{ endpoint: string }, any>;

}
