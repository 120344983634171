import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { IrinoxOrderStatusEnum } from '../../../enum/app.enum';
import { OrderFilterTypeEnum } from '../../../enum/order-filter-type.enum';
import { ConfigurationCustomerContextSelector } from '../../../model/structure/configuration-customer.model';

export const StateRelatedLinkAgent: StateRelatedLink[] = [
	{
		state: OrderStatusEnum.DRAFT,
		related_list: [OrderStatusEnum.DRAFT, OrderStatusEnum.ERROR_PREPARING]
	},
	{
		state: OrderStatusEnum.SENDING,
		related_list: [
			OrderStatusEnum.SENDING,
			OrderStatusEnum.READY_TO_SEND,
			OrderStatusEnum.ERROR_SENDING,
			OrderStatusEnum.QUEUE
		]
	},
	{
		state: OrderStatusEnum.CONSOLIDATED,
		related_list: [OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderStatusEnum.FULFILLED,
		related_list: [OrderStatusEnum.FULFILLED, OrderStatusEnum.DELETED]
	},
	{
		state: OrderStatusEnum.PROCESSING,
		related_list: [OrderStatusEnum.PROCESSING]
	},
	{
		state: OrderStatusEnum.TO_AUTHORIZE,
		related_list: [OrderStatusEnum.TO_AUTHORIZE]
	},
	{
		state: OrderStatusEnum.NOT_AUTHORIZED,
		related_list: [OrderStatusEnum.NOT_AUTHORIZED]
	}
];

export const StateRelatedLinkBackoffice: StateRelatedLink[] = [
	{
		state: OrderStatusEnum.PROCESSING,
		related_list: [OrderStatusEnum.PROCESSING, IrinoxOrderStatusEnum.HANDLING, IrinoxOrderStatusEnum.CONFIRMED]
	},
	{
		state: OrderStatusEnum.CONSOLIDATED,
		related_list: [OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderStatusEnum.FULFILLED,
		related_list: [OrderStatusEnum.FULFILLED]
	}
];

export const StateRelatedLinkB2b: StateRelatedLink[] = [
	{
		state: OrderStatusEnum.PROCESSING,
		related_list: [OrderStatusEnum.PROCESSING, IrinoxOrderStatusEnum.HANDLING, IrinoxOrderStatusEnum.CONFIRMED]
	},
	{
		state: OrderStatusEnum.CONSOLIDATED,
		related_list: [OrderStatusEnum.CONSOLIDATED, OrderStatusEnum.PARTIALLY_FULFILLED]
	},
	{
		state: OrderStatusEnum.FULFILLED,
		related_list: [OrderStatusEnum.FULFILLED]
	}
];

export interface StateRelatedLink {
	state: OrderStatusEnum | OrderFilterTypeEnum;
	related_list: (OrderStatusEnum | string)[];
}

export interface ConfigurationStatusAggregationMap extends ConfigurationCustomerContextSelector<StateRelatedLink[]> {
	// Code here...
}

export const statusAggregationMap = <ConfigurationStatusAggregationMap>{
	BACKOFFICE: StateRelatedLinkBackoffice,
	AGENT: StateRelatedLinkAgent,
	B2B: StateRelatedLinkB2b
};
