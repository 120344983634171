import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListDataPouchModel, PricePouchModel, DiscountCurrentModel } from '@saep-ict/pouch_agent_models';

import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { ArticleFilterModel } from '../../filter/article-list-filter.model';
import { PriceArticleFilter } from '../../filter/price-filter.model';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';
import { PriceModel } from '../../../../model/price.model';

export class PricePouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	private getArticleSheetArticleFilters(filter: ArticleFilterModel): PriceArticleFilter {
		return {
			type: 'article_sheet',
			code: filter.organization.code,
			code_article: filter.article.code,
			valid: filter.valid
		};
	}

	private getArticleSheetLineFilters(filter: ArticleFilterModel): PriceArticleFilter {
		return {
			type: 'article_sheet',
			code: filter.organization.code,
			code_article: filter.article.line,
			valid: filter.valid
		};
	}

	private getListFilters(filter: ArticleFilterModel): PriceArticleFilter {
		return {
			type: 'article_price_list',
			code: filter.organization.code_price_list,
			code_article: filter.article.code,
			valid: filter.valid
		};
	}

	async getPrice(filter: ArticleFilterModel): Promise<PriceModel> {
		const promiseList = [];
		const prices: PriceModel = <PriceModel>{ article_price_list: [], article_sheet: [] };
		const listFilter: PriceArticleFilter = this.getListFilters(filter);
		promiseList.push(
			this.retrieveGenericArticleDetail<number>(new BaseState(null, { appliedFilter: listFilter }))
				.then(doc => {
					return prices.article_price_list.push(...doc);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
				})
		);
		const articleSheetListFilter: PriceArticleFilter[] = [
			this.getArticleSheetArticleFilters(filter),
			this.getArticleSheetLineFilters(filter)
		];
		for (const articleSheetFilter of articleSheetListFilter) {
			promiseList.push(
				this.retrieveGenericArticleDetail<DiscountCurrentModel>(
					new BaseState(null, { appliedFilter: articleSheetFilter })
				)
					.then(doc => {
						return prices.article_sheet.push(...doc);
					})
					.catch((err: PouchErrorResponse) => {
						console.log(err);
					})
			);
		}
		await Promise.all(promiseList);
		return prices;
	}

	private retrieveGenericArticleDetail<T extends DiscountCurrentModel | number>(
		filter: BaseStateModel<any, PriceArticleFilter>
	): Promise<PricePouchModel<T>[]> {
		this.selector = {
			$and: [filter.dataSetting.appliedFilter]
		};
		return new Promise((resolve, reject) => {
			this.getWithIndexFilter(
				'article-list',
				this.selector,
				filter.dataSetting.pagination,
				filter.dataSetting.sort ? filter.dataSetting.sort : []
			)
				.then((res: ListDataPouchModel<PricePouchModel<T>>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}
}
