import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WidgetModule } from '../../widget/widget.module';
import { SharedModule } from '../../shared/shared.module';
import { PageCommonsModule } from '../commons/page-commons.module';
import { B2cOrganizationDetailComponent } from './b2c-organization/b2c-organization-detail/b2c-organization-detail.component';
import { B2cOrganizationDetailGeneralComponent } from './b2c-organization/b2c-organization-detail/b2c-organization-detail-general/b2c-organization-detail-general.component';
import { IllustrationsModule } from '../../illustrations/illustrations.module';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule, IllustrationsModule],
	declarations: [B2cOrganizationDetailComponent, B2cOrganizationDetailGeneralComponent],
	exports: [],
	entryComponents: [B2cOrganizationDetailComponent, B2cOrganizationDetailGeneralComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageB2cModule {}
