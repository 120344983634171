import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { SearchResultsActionEnum, SearchResultsStateAction } from './search-results.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';

export namespace SearchResultsStateReducer {
	export const initialState: BaseStateModel<ArticlePouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(SearchResultsStateAction.load, state => state),
		on(SearchResultsStateAction.update, (state, results) => results),
		on(SearchResultsStateAction.reset, () => initialState),
		on(SearchResultsStateAction.error, (state, highlights) => ({
			data: state ? state.data : null,
			type: highlights.type
		}))
	);

	export function reducer(state: BaseStateModel<ArticlePouchModel[]>, action: TypedAction<SearchResultsActionEnum>) {
		return _reducer(state, action);
	}
}
