<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<mat-dialog-wrapper [title]="data.title">
		<ng-container content>
			<mat-form-field>
				<input
					matInput
					formControlName="delay_date"
					[matDatepicker]="picker"
					[min]="minDate"
					[matDatepickerFilter]="myDateFilter"
					stopPropagation
					readonly
					(click)="picker.open()"
				/>
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
				<mat-hint *ngIf="form.controls.delay_date.invalid && form.controls.delay_date.touched"
					>Enter delay date</mat-hint
				>
			</mat-form-field>
		</ng-container>

		<ng-container actions>
			<button mat-button type="button" [mat-dialog-close]="false">
				{{ 'general.cancel' | translate | sentencecase }}
			</button>
			<button mat-raised-button color="primary" type="submit">
				{{ 'general.save' | translate | sentencecase }}
			</button>
		</ng-container>
	</mat-dialog-wrapper>
</form>
