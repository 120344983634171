import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { DEFAULT_DESTINATION_CODE } from '../../constants/destination.constants';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { DestinationListFilterModel } from '../../service/pouch-db/filter/destination-filter.model';

import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { DestinationListActionEnum, DestinationListStateAction } from './destination-list.actions';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';

@Injectable()
export class DestinationListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DestinationListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>) =>
				from(this.getDestinationList(action))
			),
			map((action: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>) =>
				DestinationListStateAction.update(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(DestinationListStateAction.error(null));
				return caught;
			})
		)
	);

	loadFilterd$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DestinationListActionEnum.LOAD_FILTERED),
			mergeMap((action: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>) =>
				from(this.getDestinationListFiltered(action))
			),
			map((action: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>) =>
				DestinationListStateAction.update(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(DestinationListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getDestinationList(
		filter: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>
	): Promise<BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).destinationPouch
			.getDestinationList(filter)
			.then(async destinationList => {
				return new BaseState(destinationList, filter.dataSetting);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getDestinationListFiltered(
		filter: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>
	): Promise<BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).destinationPouch
			.getDestinationList(filter)
			.then(async destinationList => {
				destinationList = destinationList.filter(
					destination => destination.code === DEFAULT_DESTINATION_CODE
					// ||
					// (destination.code_agent && destination.code_agent.includes(filter.dataSetting.appliedFilter.code_agent))
					// TODO - commentato per far partire il progetto
				);
				return new BaseState(destinationList);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
