import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerCreditPouchModelCustom } from '../../../../state/company-account-balance/company-account-balance.reducer';
import { CompanyAccountBalanceFilterModel } from '../../filter/company-account-balance-filter.model';

export class CompanyAccountBalancePouch extends AbstractPouchdbMethod {
	loadAccountBalance(
		data: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>
	): Promise<any> {
		return new Promise(resolve => {
			const id = 'organization_credit_' + data.dataSetting.appliedFilter.organization_code;
			this.get(id)
				.then(accountBalance => {
					resolve(accountBalance);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	loadAccountBalanceSummary(
		data: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>
	): Promise<any> {
		return new Promise(resolve => {
			const id = 'organization_credit_summary_' + data.dataSetting.appliedFilter.organization_code;
			this.get(id)
				.then(accountBalanceSummary => {
					resolve(accountBalanceSummary);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
