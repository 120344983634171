import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class CatalogService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/categories'
	})
	getCategories: IResourceMethodPromise<void, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/articles/{!code}'
	})
	getArticle: IResourceMethodPromise<{ code: string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/catalog/articles'
	})
	getArticles: IResourceMethodPromise<{ filter: string }, any>;

	search(term: string): Promise<any> {
		if (term) {
			const params: { filter: string } = {
				filter: term
			};
			// filter: {
			// 	query: {
			// 		wildcard: {
			// 			description: {
			// 				value: `*${term}*`
			// 				// boost: 1.0,
			// 				// rewrite: 'constant_score'
			// 			}
			// 		}
			// 	}
			// }

			return this.getArticles(params);
		}
	}
}
