import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrderStatisticsList, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { BaseState, BaseStateModel, ChartService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../../state';
import { OrderListActionEnum } from '../../../../../state/order-list/order-list.actions';
import { CardDashboardConfig } from '../../../../../widget/card-dashboard/card-dashboard.component';
import { FulfilledQuartersChartConfigService } from '../../../../../service/chart-structure/implementation/fulfilled-quarters-chart.service';
import { FulfilledTrendDetailChartConfigService } from '../../../../../service/chart-structure/implementation/fulfilled-trend-detail-chart.service';
import { FulfilledQuantityChartConfigService } from '../../../../../service/chart-structure/implementation/fulfilled-quantity-chart.service';
import { MatSelectChange } from '@angular/material/select';
import { StatisticsOrganization } from '../../../../../model/statistics-organization.model';
import { AppUtilService } from '../../../../../service/util/app-util.service';
import { StatisticsOrganizationStateAction } from '../../../../../state/statistics-organization/statistics-organization.action';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';

@Component({
	selector: 'organization-detail-overview',
	templateUrl: './organization-detail-overview.component.html',
	styleUrls: ['./organization-detail-overview.component.scss'],
	providers: [
		FulfilledQuartersChartConfigService,
		FulfilledTrendDetailChartConfigService,
		FulfilledQuantityChartConfigService
	]
})
export class OrganizationDetailOverviewComponent implements OnInit, OnDestroy {
	dateUpdatedStatistics: number;
	timeRange;

	orderStatisticsSub: Subscription;
	orderStatistics$: Observable<BaseStateModel<OrderStatisticsList>> = this.store.select(
		StateFeature.getStatisticsOrders
	);
	statistics$: Observable<BaseStateModel<StatisticsOrganization>> = this.store.select(
		StateFeature.getStatisticsOrganization
	);
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(StateFeature.getOrganizationState);
	organization: OrganizationStateModel;

	// Average
	averageWeekOrderValue = 0;
	averageWeekOrderTrend = 0;

	// TODO - dato momentaneo
	cardDashboardConfig01: CardDashboardConfig = {
		title: 'order.name_plural',
		subtitle: 'order.status.DRAFT',
		icon: 'assignment',
		helpText: ''
	};
	cardDashboardConfig02: CardDashboardConfig = {
		...this.cardDashboardConfig01,
		subtitle: 'order.status.PROCESSING'
	};
	cardDashboardConfig03: CardDashboardConfig = {
		...this.cardDashboardConfig01,
		subtitle: 'order.status.CONSOLIDATED'
	};

	constructor(
		private chartService: ChartService,
		private store: Store<any>,
		public utilService: AppUtilService,
		public fulfilledQuartersChartConfigService: FulfilledQuartersChartConfigService,
		public fulfilledTrendDetailChartConfigService: FulfilledTrendDetailChartConfigService,
		public fulfilledQuantityChartConfigService: FulfilledQuantityChartConfigService
	) {
		// Recupero le statistiche
		this.organization$.pipe(filter(res => !!(res)), take(1)).subscribe(state => {
			this.organization = state.data;
			this.store.dispatch(StatisticsOrganizationStateAction.load(new BaseState({code_item: this.organization.code_item || this.organization.code})));
		})


		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsOrganization>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.date_update;

				const quarterList = [];
				this.utilService
					.findLeaf(state.data.data, 'fulfilled.quarters')
					.forEach(item => quarterList.push({ q: item.name, y: item.year }));
				this.timeRange = quarterList;
			});
	}

	ngOnInit() {
		this.orderStatisticsSub = this.orderStatistics$
			.pipe(
				skipWhile((stats: BaseStateModel<OrderStatisticsList>) => !(stats && stats.data)),
				map((stats: BaseStateModel<OrderStatisticsList>) => {
					if (stats.type === OrderListActionEnum.ERROR) {
						throw new Error(OrderListActionEnum.ERROR);
					}
					this.calculateOrderAverageAndTrend(stats.data);
					return stats;
				})
			)
			.subscribe();
	}

	calculateOrderAverageAndTrend(stats: OrderStatisticsList) {
		const states = [
			OrderStatusEnum.READY_TO_SEND,
			OrderStatusEnum.SENDING,
			OrderStatusEnum.PROCESSING,
			OrderStatusEnum.CONSOLIDATED
		];
		const weekTotalCount = this.chartService.getTotal(stats, states, 'week', 'count');
		const weekTotalAmount = this.chartService.getTotal(stats, states, 'week', 'total');

		const twoWeekTotalCount = this.chartService.getTotal(stats, states, 'twoWeek', 'count');
		const twoWeekTotalAmount = this.chartService.getTotal(stats, states, 'twoWeek', 'total');

		this.averageWeekOrderValue = weekTotalCount === 0 ? 0 : weekTotalAmount / weekTotalCount;

		const lastWeekTotalCount = twoWeekTotalCount - weekTotalCount;
		const lastWeekTotalAmount = twoWeekTotalAmount - weekTotalAmount;
		const lastWeekAverage = lastWeekTotalCount === 0 ? 0 : lastWeekTotalAmount / lastWeekTotalCount;

		this.averageWeekOrderTrend = this.chartService.calculateTrend(this.averageWeekOrderValue, lastWeekAverage);
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [
			this.fulfilledQuartersChartConfigService,
			this.fulfilledTrendDetailChartConfigService,
			this.fulfilledQuantityChartConfigService
		];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));
	}

	ngOnDestroy() {
		if (this.orderStatisticsSub) {
			this.orderStatisticsSub.unsubscribe();
		}
	}
}
