import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { ProductCatalogColumnService } from '../../../service/td-data-table/implementation/product-catalog.service';

const injector = Injector.create({
	providers: [{ provide: ProductCatalogColumnService, deps: [] }]
});

const ArticleCatalogColumnService = injector.get(ProductCatalogColumnService);

export namespace ConfigurationCustomerArticleContextApplicationColumnMap {
	export const base: ITdDataTableColumn[] = ArticleCatalogColumnService.columns;

	export const exludeForB2B: string[] = ['productDetail.qty_free', 'productDetail.discount_agent'];
	export const exludeForBackoffice: string[] = [
        'articlePrice.price',
        'articlePrice.discount',
        'productDetail.input_quantity',
        'productDetail.discount_agent',
        'productDetail.qty_free',
        'calculate_price'
    ];
}
