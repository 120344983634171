import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// model
import { OrderStateModel } from '../../../model/state/order-state.model';
import { OrderProductModel } from '../../../model/state/article-list-state.model';

// widget & utility
import _ from 'lodash';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { BaseStateModel, DateMomentService, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { UtilService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'order-list-wrapper',
	templateUrl: './order-list-wrapper.component.html',
	styleUrls: ['./order-list-wrapper.component.scss']
})
export class OrderListWrapperComponent extends LocalListHandlerWidgetWrapper implements OnInit {
	@Output() selectOrderId: EventEmitter<string> = new EventEmitter();
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	destination: DestinationPouchModel;

	isTablet: any = false;

	constructor(
		public utilOrderService: UtilOrderService,
		public dateMomentService: DateMomentService,
		public utilService: UtilService,
		private store: Store<any>,
		translate: TranslateService
	) {
		super(utilService, translate);

		// organization list
		this.utilOrderService.retrieveSyncState<OrganizationStateModel[]>(this.organizationList$).subscribe(res => {
			this.organizationList = res.data;
		});
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.checkTableColumnVisibility(window.innerWidth);
	}

	// widget & utility
	showGenericVariationIcon(row: OrderStateModel) {
		if (
			(row && row.header && row.header.order_progress_detail && !_.isEmpty(row.header.order_progress_detail)) ||
			(row.order_variation_list && row.order_variation_list.length)
		) {
			return true;
		}
		return false;
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	convertMillisStringToTime(stringMillis: string) {
		return this.dateMomentService.convertMillisStringToTime(stringMillis);
	}

	retrieveOrderTotalPrice(order: OrderStateModel) {
		let totalOrder = 0;
		order.product_list = order.product_list.filter(product => product.articleDetail);
		order.product_list.forEach(article => {
			const cloneArticle: OrderProductModel = Object.assign({}, article);
			const articleState = this.utilOrderService.mapCurrentArticle(cloneArticle);
			article.articleDetail.calculate_price = this.utilOrderService.retrieveTotalArticlePrice(articleState);
			totalOrder += this.articleTotalCount(article, order);
		});
		return totalOrder;
	}

	retrieveOrderDestination(order: OrderStateModel) {
		if (this.organizationList) {
			const organization = this.organizationList.find(
				organization => organization.code_item === order.header.organization?.code_item
			);
			if (organization && organization.destination_list) {
				const destination = organization.destination_list.find(
					destination => destination['code_erp'] === order.header.goods_destination_code
				);
				return (this.destination = destination || null);
			}
		}
		return (this.destination = null);
	}

	retrieveOrganizationCodeErp(order: OrderStateModel) {
		if (this.organizationList) {
			return (
				this.organizationList.find(
					organization => organization.code_item === order.header.organization?.code_item
				)?.code_erp || '-'
			);
		}
		return '-';
	}

	articleTotalCount(article: OrderProductModel, order: OrderStateModel): number {
		return article.articleDetail.calculate_price;
	}

	/**
	 * Check column visibility based on window width
	 * @param width This is the width of the window (px)
	 */
	checkTableColumnVisibility(width: number) {
		const _isTablet = this.isTablet;
		this.isTablet = width <= 1024;

		if (_isTablet !== this.isTablet) {
			['header.first_evasion_date'].forEach(columnName => {
				this.changeVisiblityColumn(columnName, this.isTablet);
			});
			if (this.dataTable) {
				this.dataTable.refresh();
			}
		}
	}

	/**
	 * Hide or show a column
	 * @param columnName the name of the column declared in the ITdDataTableColumn
	 * @param hide boolean to hide or not the column
	 */
	changeVisiblityColumn(columnName: string, hide: boolean) {
		const indexColumn = this.utilService.getElementIndex(
			this._localListHandlerWidgetWrapperColumnList,
			'name',
			columnName
		);
		if (indexColumn !== undefined) {
			this._localListHandlerWidgetWrapperColumnList[indexColumn].hidden = hide;
		}
	}
}
