import { Injectable } from '@angular/core';
import { BaseApiService } from './baseApi.service';
// model
import { OrderListModel } from '../../model/order.model';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { OrderStateModel } from '../../model/state/order-state.model';
import { RestBasePk, RestBaseResponse } from '@saep-ict/angular-core';

@Injectable()
export class OrderService extends BaseApiService {
	// MOCKUP
	orders: OrderListModel = {
		pagination: {
			page_current: 1,
			page_size: 10,
			total_element: 10
		},
		data: [
			{
				id: 'idOrdine1',
				client_code: '060210',
				company: {
					business_name: 'Rossi Bros'
				},
				number: '10116',
				client: 'Intrawear',
				date: '10/02/2019',
				due_date: '11/12/2019',
				status: 'ready_to_send',
				tot: 2000.0,
				currency: '€',
				indirizzo: 'Prova'
			},
			{
				id: 'idOrdine2',
				client_code: '062020',
				company: {
					business_name: 'Rossi Bros'
				},
				number: '10115',
				client: 'Quility',
				date: '17/01/2019',
				due_date: '10/12/2018',
				status: 'ready_to_send',
				tot: 31000.0,
				currency: '€'
			},
			{
				id: 'idOrdine3',
				client_code: '062030',
				company: {
					business_name: 'Rossi Bros'
				},
				number: '10114',
				client: 'Intrawear',
				date: '03/12/2018',
				due_date: '––',
				status: 'draft',
				tot: 5000.0,
				currency: '€'
			},
			{
				id: 'idOrdine4',
				client_code: '106090',
				company: {
					business_name: 'Verdi&Co'
				},
				number: '10113',
				client: 'Optique',
				date: '19/01/2019',
				due_date: '––',
				status: 'draft',
				tot: 100000.0,
				currency: '€'
			},
			{
				id: 'idOrdine5',
				client_code: '107090',
				company: {
					business_name: 'Verdi&Co'
				},
				number: '10112',
				client: 'Aquoavo',
				date: '10/10/2018',
				due_date: '14/12/2019',
				status: 'accepted',
				tot: 1560.0,
				currency: '€'
			},
			{
				id: 'idOrdine6',
				client_code: '103090',
				company: {
					business_name: 'Company&Co'
				},
				number: '10111',
				client: 'Aquoavo',
				date: '19/11/2018',
				due_date: '15/12/2019',
				status: 'accepted',
				tot: 120.0,
				currency: '€'
			},
			{
				id: 'idOrdine7',
				client_code: '306090',
				company: {
					business_name: 'Company&Co'
				},
				number: '10110',
				client: 'Optique',
				date: '29/10/2018',
				due_date: '16/12/2019',
				status: 'accepted',
				tot: 190.0,
				currency: '€'
			},
			{
				id: 'idOrdine8',
				client_code: '309090',
				company: {
					business_name: 'Company Srl'
				},
				number: '3',
				date: '30/01/2019',
				due_date: '17/12/2019',
				status: 'accepted',
				tot: 110000.0,
				currency: '€'
			},
			{
				id: 'idOrdine9',
				client_code: '609010',
				company: {
					business_name: 'Company Srl'
				},
				number: '3',
				date: '15/12/2018',
				due_date: '18/12/2019',
				status: 'blocked',
				tot: 3000.0,
				currency: '€'
			},
			{
				id: 'idOrdine10',
				client_code: '609050',
				company: {
					business_name: 'Company Srl'
				},
				number: '3',
				date: '25/02/2019',
				due_date: '19/12/2019',
				status: 'accepted',
				tot: 11000.0,
				currency: '€'
			}
		]
	};

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/orders/email-new'
	})
	sendNewOrderEmail: IResourceMethodPromise<{ data: object }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/orders/new'
	})
	getNewOrder: IResourceMethodPromise<RestBasePk, RestBaseResponse<any>>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/orders/new'
	})
	putNewOrder: IResourceMethodPromise<OrderStateModel, RestBaseResponse<any>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/orders/{!id}/delay-email'
	})
	sendOrderDelayEmail: IResourceMethodPromise<RestBasePk, RestBaseResponse<void>>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/orders/{!id}/download-odv'
	})
	downloadOrderOdv: IResourceMethodPromise<RestBasePk, RestBaseResponse<any>>;

	// dataDetailDraft: OrderModel = {
	// 	id: 'idOrdine3',
	// 	number: '10112',
	// 	id_protocol: '345',
	// 	id_erp: '7980',
	// 	client: '3',
	// 	reference: 'Mr. Smith',
	// 	date: '10/02/2019',
	// 	due_date: '11/12/2019',
	// 	status: 'draft',
	// 	note:
	// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	// 	tot: 5000.0,
	// 	shipping_address: '01',
	// 	payment: '02',
	// 	reason: '03',
	// 	discount_agent: {
	// 		value: 0,
	// 		type: 'currency'
	// 	},
	// 	currency: '€',
	// 	products: {
	// 		pagination: {
	// 			page_current: 1,
	// 			page_size: 20,
	// 			total_element: 58
	// 		},
	// 		data: [
	// 			// {
	// 			//   id: 'idProdotto1',
	// 			//   code: "4032",
	// 			//   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 			//   brand: "Tipologia Brand A",
	// 			//   line: "Tipologia Linea A",
	// 			//   price: 41.15,
	// 			//   discount: 20,
	// 			//   discount_agent: {
	// 			//     value: 15,
	// 			//     type: 'percentage'
	// 			//   },
	// 			//   disponibilita: "AVAILABLE",
	// 			//   note: "Nota A",
	// 			//   qty: 20,
	// 			//   qtyO: 10,
	// 			// },
	// 			// {
	// 			//   id: 'idProdotto2',
	// 			//   code: "4056",
	// 			//   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 			//   brand: "Tipologia Brand B",
	// 			//   line: "Tipologia Linea B",
	// 			//   price: 90.5,
	// 			//   discount: 12,
	// 			//   discount_agent: {
	// 			//     value: 0,
	// 			//     type: 'currency'
	// 			//   },
	// 			//   disponibilita: "LOW",
	// 			//   note: "",
	// 			//   qty: 3,
	// 			//   qtyO: 0,
	// 			// },
	// 			// {
	// 			//   id: 'idProdotto3',
	// 			//   code: "4159",
	// 			//   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 			//   brand: "Tipologia Brand C",
	// 			//   line: "Tipologia Linea C",
	// 			//   price: 100.90,
	// 			//   discount: 30,
	// 			//   discount_agent: {
	// 			//     value: 0,
	// 			//     type: 'percentage'
	// 			//   },
	// 			//   disponibilita: "NOT_AVAILABLE",
	// 			//   note: "Nota C",
	// 			//   qty: 80,
	// 			//   qtyO: 10,
	// 			// },
	// 			// {
	// 			//   id: 'idProdotto4',
	// 			//   code: "4172",
	// 			//   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 			//   brand: "Tipologia Brand D",
	// 			//   line: "Tipologia Linea D",
	// 			//   price: 250.00,
	// 			//   discount: 13,
	// 			//   discount_agent: {
	// 			//     value: 0,
	// 			//     type: 'percentage'
	// 			//   },
	// 			//   disponibilita: "NOT_AVAILABLE",
	// 			//   note: "",
	// 			//   qty: 0,
	// 			//   qtyO: 0,
	// 			// }
	// 		]
	// 	}
	// };
	// orderStates: string[] = ['DRAFT', 'READY_TO_SEND', 'PROCESSING'];
	// orderDetailDraft: OrderModel = {
	// 	id: '012',
	// 	number: '10112',
	// 	id_protocol: '345',
	// 	id_erp: '7980',
	// 	client: '3',
	// 	reference: 'Mr. Smith',
	// 	date: '10/02/2019',
	// 	due_date: '11/12/2019',
	// 	status: 'draft',
	// 	note:
	// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
	// 	tot: 5000.0,
	// 	shipping_address: '01',
	// 	payment: '02',
	// 	reason: '03',
	// 	discount_agent: {
	// 		value: 0,
	// 		type: 'percentage'
	// 	},
	// 	products: {
	// 		pagination: {
	// 			page_current: 1,
	// 			page_size: 20,
	// 			total_element: 58
	// 		},
	// 		data: [
	// 			{
	// 				id: 'idProdotto1',
	// 				code: '4032',
	// 				description:
	// 					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 				brand: 'Tipologia Brand A',
	// 				line: 'Tipologia Linea A',
	// 				price: 41.15,
	// 				discount: 20,
	// 				discount_agent: {
	// 					value: 0,
	// 					type: 'percentage'
	// 				},
	// 				note: 'Nota A',
	// 				qty: 20,
	// 				qtyO: 10
	// 			},
	// 			{
	// 				id: 'idProdotto2',
	// 				code: '4056',
	// 				description:
	// 					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 				brand: 'Tipologia Brand B',
	// 				line: 'Tipologia Linea B',
	// 				price: 90.5,
	// 				discount: 12,
	// 				discount_agent: {
	// 					value: 0,
	// 					type: 'percentage'
	// 				},
	// 				note: '',
	// 				qty: 3,
	// 				qtyO: 0
	// 			},
	// 			{
	// 				id: 'idProdotto3',
	// 				code: '4159',
	// 				description:
	// 					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 				brand: 'Tipologia Brand C',
	// 				line: 'Tipologia Linea C',
	// 				price: 100.9,
	// 				discount: 30,
	// 				discount_agent: {
	// 					value: 0,
	// 					type: 'percentage'
	// 				},
	// 				disponibilita: 'AVAILABLE',
	// 				note: 'Nota C',
	// 				qty: 80,
	// 				qtyO: 10
	// 			},
	// 			{
	// 				id: 'idProdotto4',
	// 				code: '4172',
	// 				description:
	// 					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam aliquet semper sollicitudin. Proin ut tincidunt nulla, non sagittis lectus. Vivamus lorem mauris, scelerisque ac elementum non, aliquam in lectus.',
	// 				brand: 'Tipologia Brand D',
	// 				line: 'Tipologia Linea D',
	// 				price: 250.0,
	// 				discount: 13,
	// 				discount_agent: {
	// 					value: 0,
	// 					type: 'percentage'
	// 				},
	// 				disponibilita: 'AVAILABLE',
	// 				note: '',
	// 				qty: 0,
	// 				qtyO: 0
	// 			}
	// 		]
	// 	}
	// };

	// getFilterdList(c: CompanyModel): Observable<OrderListModel> {
	// 	const orders = this.orders;
	// 	return new BehaviorSubject<OrderListModel>(orders);
	// }
}
