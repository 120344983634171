import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactActionEnum, ContactStateAction } from './contact.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import { LAST_CHARACTER } from '../../constants/pouchdb.constants';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';

@Injectable()
export class ContactEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactActionEnum.LOAD),
			mergeMap((action: BaseStateModel<{ _id: string }>) => {
				return from(this.getContact(action));
			}),
			map((contact: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
				return ContactStateAction.update(contact);
			}),
			catchError((error, caught) => {
				this.store.dispatch(ContactStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactActionEnum.SAVE),
			mergeMap((action: ContactPouchModel) => {
				return from(this.createContact(action));
			}),
			map((contact: ContactPouchModel) => ContactStateAction.loadAll()),
			catchError((errore, caught) => {
				this.store.dispatch(ContactStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) =>
				// from((await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).contactPouch.getTableContactList())
				from(this.getAllContact(action))
			),
			map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => ContactStateAction.update(state)),
			catchError((error, caught) => {
				this.store.dispatch(ContactStateAction.error(null));
				return caught;
			})
		)
	);

	// putContact$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(ContactActionEnum.UPDATE_CONTACT),
	// 		mergeMap((action: ContactPouchModel) => {
	// 			return from(this.updateContact(action));
	// 		}),
	// 		map((contact: ContactPouchModel) => {
	// 			return ContactStateAction.load(
	// 				new BaseState(null, {
	// 					// appliedFilter: {
	// 					// 	_id: contact._id
	// 					// }
	// 				})
	// 			);
	// 		}),
	// 		catchError((err, caught) => {
	// 			this.store.dispatch(
	// 				ContactStateAction.error({
	// 					data: err
	// 				})
	// 			);
	// 			return caught;
	// 		})
	// 	)
	// );

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<ContactPouchModel>) => from(this.deleteContact(action.data))),
			map((user: BaseStateModel<PouchDeleteResponse>) => ContactStateAction.removed()),
			catchError((error, caught) => {
				this.store.dispatch(ContactStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private utilPouchService: UtilPouchService
	) {}

	async updateContact(action: ContactPouchModel): Promise<ContactPouchModel> {
		const contact = await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.CONTACT)
		).contactPouch.updateContact(action);
		return contact;
	}

	async getContact(
		filter: BaseStateModel<{ _id: string }>
	): Promise<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> {
		const data = await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.CONTACT)
		).contactPouch.getContact(filter);
		const baseModel: BaseStateModel<ListDataPouchModel<ContactPouchModel>> = {
			...filter,
			data,
			type: 'app_contact'
		};
		return baseModel;
	}

	async getAllContact(
		action: BaseStateModel<ListDataPouchModel<ContactPouchModel>>
	): Promise<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> {
		const documentName = 'contact';
		const allDocsParam: any = {
			include_docs: true,
			startkey: documentName + '_',
			endkey: documentName + '_' + LAST_CHARACTER
		};

		await this.utilPouchService
			.allDocs<ContactPouchModel>(allDocsParam, CouchDocumentType.CONTACT)
			.then(res => {
				const contact_list = { docs: [] };
				res.data.forEach(doc => contact_list.docs.push(doc));
				action.data = contact_list;
			})
			.catch(err => console.log(err));
		return action;
	}

	async createContact(action: ContactPouchModel): Promise<ContactPouchModel> {
		const res_saved = await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.CONTACT)
		).contactPouch.createContact(action);
		console.log({ res_saved });
		return res_saved;
	}

	async deleteContact(data: ContactPouchModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.CONTACT)).contactPouch
			.deleteContact(data)
			.then(async contact => {
				return new BaseState(contact);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
