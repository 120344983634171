import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class ProbeTestService extends BaseApiService {

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/probe-test'
	})
	getProbeTest: IResourceMethodPromise<void, Response>;

}
