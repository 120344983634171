import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WidgetModule } from '../../widget/widget.module';
import { SharedModule } from '../../shared/shared.module';
import { PageCommonsModule } from '../commons/page-commons.module';

// page
import { OpportunityKanbanComponent } from './opportunity/opportunity-kanban/opportunity-kanban.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { OpportunityDetailComponent } from './opportunity/opportunity-detail/opportunity-detail.component';
import { OpportunityDetailMainComponent } from './opportunity/opportunity-detail/opportunity-detail-main/opportunity-detail-main.component';
import { OpportunityDetailCommentComponent } from './opportunity/opportunity-detail/opportunity-detail-comment/opportunity-detail-comment.component';
import { OpportunityDetailActivityComponent } from './opportunity/opportunity-detail/opportunity-detail-activity/opportunity-detail-activity.component';
import { OfferDetailComponent } from './offer/offer-detail/offer-detail.component';
import { OfferDetailHeadComponent } from './offer/offer-detail/offer-detail-head/offer-detail-head.component';
import { OfferDetailBodyComponent } from './offer/offer-detail/offer-detail-body/offer-detail-body.component';
import { OfferComponent } from './offer/offer.component';
import { DashboardCrmComponent } from './dashboard-crm/dashboard-crm.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [
		OpportunityKanbanComponent,
		OpportunityComponent,
		OpportunityDetailComponent,
		OpportunityDetailMainComponent,
		OpportunityDetailCommentComponent,
		OpportunityDetailActivityComponent,
		OfferComponent,
		OfferDetailComponent,
		OfferDetailHeadComponent,
		OfferDetailBodyComponent,
		DashboardCrmComponent
	],
	exports: [
		OpportunityKanbanComponent,
		OpportunityComponent,
		OpportunityDetailComponent,
		OpportunityDetailMainComponent,
		OpportunityDetailCommentComponent,
		OpportunityDetailActivityComponent,
		OfferComponent,
		OfferDetailComponent,
		OfferDetailHeadComponent,
		OfferDetailBodyComponent,
		DashboardCrmComponent
	],
	entryComponents: [
		OpportunityKanbanComponent,
		OpportunityComponent,
		OpportunityDetailComponent,
		OpportunityDetailMainComponent,
		OpportunityDetailCommentComponent,
		OpportunityDetailActivityComponent,
		OfferComponent,
		OfferDetailComponent,
		OfferDetailHeadComponent,
		OfferDetailBodyComponent,
		DashboardCrmComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageCrmModule {}
