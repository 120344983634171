<breadcrumb
	[title]="contextTypeAndApplicationLink.context_code_item.description | sentencecase"
	[pathList]="
		contextTypeAndApplicationLink
			? ['Context Code', contextTypeAndApplicationLink.context_code_item.description | sentencecase]
			: []
	"
></breadcrumb>
<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<mat-toolbar *ngIf="contextTypeAndApplicationLink">
					<mat-toolbar-row>
						{{ contextTypeAndApplicationLink.context_code_item.description | sentencecase }}
						<span class="flex-span"></span>
						<td-search-box
							backIcon="search"
							[placeholder]="
								('general.filter_by' | translate | sentencecase) +
								' ' +
								('context_code.field.code' | translate) +
								' o ' +
								('context_code.field.description' | translate)
							"
							[(ngModel)]="localListHandlerData.filters.localSearchText.value"
							[showUnderline]="true"
							[debounce]="500"
							[alwaysVisible]="false"
							(searchDebounce)="localListHandlerApplyFilter()"
						>
						</td-search-box>
					</mat-toolbar-row>
				</mat-toolbar>
				<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
				<ng-container
					*ngIf="
						localListHandlerData &&
							localListHandlerData.dataSubset &&
							localListHandlerData.dataSubset.length > 0;
						else noData
					"
				>
					<td-data-table
						#dataTable
						[data]="localListHandlerData.dataSubset"
						[columns]="permissionContextListColumnService.columns"
						[clickable]="true"
						[sortable]="true"
						(sortChange)="localListHandlerApplyFilter({ sort: $event })"
						(rowClick)="goToDetail($event)"
						[sortBy]="localListHandlerData.sort.name"
						[sortOrder]="localListHandlerData.sort.order"
					>
					</td-data-table>
					<mat-paginator
						#matPaginator
						(page)="localListHandlerApplyFilter({ pagination: $event })"
						[length]="localListHandlerData.pagination.totalFilteredItemCount"
						[pageSize]="localListHandlerData.pagination.pageSize"
						[pageSizeOptions]="[5, 10, 25, 100]"
					>
					</mat-paginator>
				</ng-container>
				<ng-template #noData>
					<p class="placeholder" *ngIf="!isLoading">
						{{ 'general.no_results' | translate | sentencecase }}
					</p>
				</ng-template>
			</mat-card>
		</div>
	</div>
</div>

<button
	mat-fab
	color="accent"
	class="mat-fab-button"
	(click)="dialogCreateContextCodeItem(contextTypeAndApplicationLink.context_code_item)"
	[matTooltip]="
		('general.add_new' | translate | sentencecase) +
		' ' +
		contextTypeAndApplicationLink.context_code_item.description
	"
	matTooltipPosition="left"
	*ngIf="permissionUtilService.canEditContextCode(contextTypeAndApplicationLink.context_application_item_code)"
>
	<mat-icon>add</mat-icon>
</button>
