import { RestBasePk } from '@saep-ict/angular-core';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { KanbanPouchModel } from '../../../../model/state/kanban-state.model';
import { PouchDeleteResponse } from '../../model/pouch-base-response.model';

export class KanbanPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getKanbanDetail(data: RestBasePk): Promise<KanbanPouchModel> {
		return new Promise((resolve, reject) => {
			this.get(data.id)
				.then((doc: KanbanPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putKanban(data: KanbanPouchModel, isGuid: boolean): Promise<KanbanPouchModel> {
		const guid = isGuid ? 'kanban' : data._id;
		if (!data.type) {
			data.type = 'kanban';
		}
		return new Promise((resolve, reject) => {
			this.put<KanbanPouchModel>(data, guid, isGuid)
				.then((doc: KanbanPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteKanban(kanban: KanbanPouchModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(kanban)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
