import { BaseStatusBarConfigService } from '@saep-ict/angular-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { Injectable } from '@angular/core';
import { IrinoxOrderStatusEnum } from '../../../enum/app.enum';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';

@Injectable()
export class StatusBarOrderService extends BaseStatusBarConfigService {
	constructor(private translate: TranslateService, private titlecasePipe: TitleCasePipe) {
		super();
	}

	_config = {
		condensed: true,
		theme: 'gray',
		steps: [
			// Bozza
			{
				id: OrderStatusEnum.DRAFT,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.DRAFT')),
				icon: 'create'
			},
			{
				id: OrderStatusEnum.ERROR_PREPARING,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.ERROR_PREPARING')),
				icon: 'create'
			},

			// Da autorizzare
			{
				id: OrderStatusEnum.TO_AUTHORIZE,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.TO_AUTHORIZE')),
				icon: 'library_add_check'
			},

			// Trasmissione
			{
				id: OrderStatusEnum.READY_TO_SEND,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.READY_TO_SEND')),
				icon: 'send'
			},
			{
				id: OrderStatusEnum.SENDING,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.SENDING')),
				icon: 'send'
			},

			// In lavorazione
			{
				id: IrinoxOrderStatusEnum.HANDLING,
				label: this.translate.instant('order.status.HANDLING'),
				icon: 'build'
			},

			// Trasmesso
			{
				id: OrderStatusEnum.PROCESSING,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.PROCESSING')),
				icon: 'rotate_right'
			},

			// In produzione
			{
				id: OrderStatusEnum.CONSOLIDATED,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.CONSOLIDATED')),
				icon: 'build'
			},
			{
				id: OrderStatusEnum.PARTIALLY_FULFILLED,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.PARTIALLY_FULFILLED')),
				icon: 'build'
			},
			{
				id: OrderStatusEnum.ERROR_SENDING,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.CONFIRMED')),
				icon: 'build'
			},

			// Confermati
			{
				id: IrinoxOrderStatusEnum.CONFIRMED,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.CONFIRMED')),
				icon: 'check'
			},

			// Archiviati
			{
				id: OrderStatusEnum.FULFILLED,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.ARCHIVED')),
				icon: 'archive'
			},
			{
				id: OrderStatusEnum.DELETED,
				label: this.titlecasePipe.transform(this.translate.instant('order.status.DELETED')),
				icon: 'archive'
			}
		]
	};
}
