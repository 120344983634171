import { Injectable } from '@angular/core';
import { BaseStateModel, ContextCodeAssociation } from '@saep-ict/angular-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../pouch-db/model/pouch-base.model';
import { PouchAdapterSelectorService } from '../pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';

@Injectable({
	providedIn: 'root'
})
export class StoreUtilService {
	constructor(private pouchAdapterSelectorService: PouchAdapterSelectorService) {}
	async getCouchDetailAndReturnInDetailState<D>(action: BaseStateModel<BasePouchModel>): Promise<BaseStateModel<D>> {
		try {
			const actionReturn = <BaseStateModel<D>>action;
			actionReturn.data = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.getDetail<D>(action.data._id);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}
	async getCouchDetailAndReturnInListState<D>(action: BaseStateModel<BasePouchModel>): Promise<BaseStateModel<D[]>> {
		try {
			const actionReturn = <BaseStateModel<D[]>>action;
			actionReturn.data = [
				await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).basePouch.getDetail<D>(
					action.data._id
				)
			];
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveCouchDetailAndReturnInDetailState<
		D extends BodyTablePouchCustomModel | OrganizationPouchModel | ContextCodeAssociation
	>(action: BaseStateModel<D>, couchDocumentType?: CouchDocumentType): Promise<BaseStateModel<D>> {
		try {
			const actionReturn = <BaseStateModel<D>>{
				type: action.type
			};
			actionReturn.data = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(couchDocumentType)
			).spin8Pouch.saveBodyTable<D>(action.data);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveCouchDetailAndReturnInListState<D extends BodyTablePouchCustomModel | OrganizationPouchModel>(
		action: BaseStateModel<D>
	): Promise<BaseStateModel<D[]>> {
		try {
			const actionReturn = <BaseStateModel<D[]>>{
				type: action.type,
				data: []
			};
			actionReturn.data = [
				await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).spin8Pouch.saveBodyTable<D>(
					action.data
				)
			];
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}
}
