import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { DestinationListFilterModel } from '../../service/pouch-db/filter/destination-filter.model';

export enum DestinationListActionEnum {
	UPDATE = '[destination list] Update',
	LOAD = '[destination list] Load',
	LOAD_FILTERED = '[destination list] Load filtered',
	COMPLETED = '[destination list] Completed',
	RESET = '[destination list] Reset',
	ERROR = '[destination list] Error'
}

export namespace DestinationListStateAction {
	export const load = createAction(
		DestinationListActionEnum.LOAD,
		props<BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>>()
	);
	export const loadFiltered = createAction(
		DestinationListActionEnum.LOAD_FILTERED,
		props<BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>>()
	);
	export const update = createAction(
		DestinationListActionEnum.UPDATE,
		props<BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>>()
	);
	export const completed = createAction(DestinationListActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const reset = createAction(DestinationListActionEnum.RESET);
	export const error = createAction(DestinationListActionEnum.ERROR, props<BaseStateModel<any>>());
}
