import { CompanyAccountBalancePouch } from './pouch-function/company-account-balance.pouch';
import { OrderStatisticsPouch } from './pouch-function/order-statistics.pouch';
import { AgentPouch } from './pouch-function/agent.pouch';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OfflineDeviceService, PouchDbAdapter } from '@saep-ict/pouch-db';
import { CustomerAppConfig } from '../../../customer-app.config';
import { ArticlePouch } from './pouch-function/article.pouch';
import { BasePouch } from './pouch-function/base.pouch';
import { OrganizationPouch } from './pouch-function/organization.pouch';
import { OrderPouch } from './pouch-function/order.pouch';
import { LoaderService } from '@saep-ict/angular-core';
import { StatisticsPouch } from './pouch-function/statistics.pouch';
import { Spin8Pouch } from './pouch-function/spin8.pouch';
import { PricePouch } from './pouch-function/price.pouch';
import { ContactPouch } from './pouch-function/contact.pouch';
import { DestinationPouch } from './pouch-function/destination.pouch';
import { OpportunityPouch } from './pouch-function/opportunity.pouch';
import { ArticleKitPouch } from './pouch-function/article-kit.pouch';
import { OfferPouch } from './pouch-function/offer.pouch';

@Injectable()
export class PouchDbCommonsOrderAdapter extends PouchDbAdapter {
	baseDatabaseTemplate = 'commons_order';
	database = 'commons_order';
	filteredOptions: any = {};
	basePouch: BasePouch;
	orderPouch: OrderPouch;
	organizationPouch: OrganizationPouch;
	articlePouch: ArticlePouch;
	agentPouch: AgentPouch;
	pricePouch: PricePouch;
	contactPouch: ContactPouch;
	destinationPouch: DestinationPouch;
	orderStatisticsPouch: OrderStatisticsPouch;
	companyAccountBalancePouch: CompanyAccountBalancePouch;
	statisticsPouch: StatisticsPouch;
	spin8Pouch: Spin8Pouch;
	opportunityPouch: OpportunityPouch;
	articleKitPouch: ArticleKitPouch;
	offerPouch: OfferPouch;

	constructor(
		protected config: CustomerAppConfig,
		private httpClient: HttpClient,
		private loaderService: LoaderService,
		offlineDeviceService: OfflineDeviceService
	) {
		super(config, offlineDeviceService);
	}

	async initFunctions() {
		this.spin8Pouch = new Spin8Pouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.basePouch = new BasePouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.orderPouch = new OrderPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.organizationPouch = new OrganizationPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.articlePouch = new ArticlePouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.agentPouch = new AgentPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.pricePouch = new PricePouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.contactPouch = new ContactPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.destinationPouch = new DestinationPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.orderStatisticsPouch = new OrderStatisticsPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.loaderService
		);
		this.companyAccountBalancePouch = new CompanyAccountBalancePouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.loaderService
		);
		this.statisticsPouch = new StatisticsPouch(
			this.getDB(),
			this.httpClient,
			this.config,
			this.loaderService
		);
		this.opportunityPouch = new OpportunityPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.articleKitPouch = new ArticleKitPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.offerPouch = new OfferPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
	}
}
