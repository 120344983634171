import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-b2c-organization-detail-general',
	templateUrl: './b2c-organization-detail-general.component.html',
	styleUrls: ['./b2c-organization-detail-general.component.scss']
})
export class B2cOrganizationDetailGeneralComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
