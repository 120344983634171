import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { OrganizationStateModel } from '../../model/state/organization-state.model';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class OrganizationService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/public/organization/new'
	})
	postNewOrganization: IResourceMethodPromise<OrganizationStateModel, RestBaseResponse<any>>;
}
