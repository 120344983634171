import { Injectable } from '@angular/core';
import { UtilService } from '@saep-ict/angular-core';

/**
 * Questo service sarà presente in ogni progetto e servirà ad estende l'UtiService della libreria @saep-ict/angular-core
 * con i metodi di utility specifici per l'applicazione di cui fa parte.
 * Va da sé che questo servizio è utilizzabile anche a scopo di test prima di portare, eventualmente,
 * nuove utility nel servizio in libreria.
 */
@Injectable({
	providedIn: 'root'
})
export class AppUtilService extends UtilService {
	/**
	 * Metodo che si aspetto in ingresso un `object` strutturato ricorsivamente come {key: string; value: any | any[]}
	 * e restituisce il value elaborando la catena ricorsiva espressa in ingresso dal `path`
	 * @param object oggetto principale da elaborare
	 * @param path percorso annidato da interrogare
	 * @returns il valore al percorso richiesto
	 */
	findLeaf(object: any, path: string) {
		path = path.replace(/\[(\w+)\]/g, '.$1');
		path = path.replace(/^\./, '');
		var a = path.split('.');
		for (var i = 0, n = a.length; i < n; ++i) {
			const k = a[i];
			const inner = object.find(stat => stat.key == k);
			if (inner) {
				object = inner.value;
			} else {
				return;
			}
		}
		return object;
	}
}
