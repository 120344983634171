import { MaxNumberInputDirective } from './max-number-input.directive';
import { NgModule } from '@angular/core';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { PercentInputDirective } from './percent-input.directive';
import { UppercaseDirective } from './uppercase.directive';
import { SliderItemDirective } from '../../widget/card-slider/slider-content.directive';

@NgModule({
  declarations: [
    OnlyNumbersDirective,
    PercentInputDirective,
    MaxNumberInputDirective,
    UppercaseDirective,
    SliderItemDirective,
  ],
  exports: [
    OnlyNumbersDirective,
    PercentInputDirective,
    MaxNumberInputDirective,
    UppercaseDirective,
    SliderItemDirective,
  ]
})
export class DirectivesModule { }
