<div class="hamburger-icon" (click)="toggleNavBar.emit()">

  <button mat-icon-button *ngIf="!navbarIsOpen" [@toggleHamburger]>
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-icon-button *ngIf="navbarIsOpen" [@toggleHamburger]>
    <mat-icon>close</mat-icon>
  </button>

</div>
