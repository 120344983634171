<div class="container-fluid">
	<div class="row" *ngIf="organization">
		<div class="col-12">
			<mat-card>
				<mat-card-content>
					<div class="row">
						<div class="col-12">
							<destination-list-wrapper
								[localListHandlerWidgetWrapperData]="listPageBaseData"
								[localListHandlerWidgetWrapperColumnList]="columns"
								[localListHandlerTextFilterShow]="true"
								[localListHandlerPaginatorShow]="true"
								(onEditDestination)="configurationCustomerUser.can_edit_organization.destination_edit[contextApplicationItemCodeEnum[user.current_permission.context_application]] ? openDialogDestinationEdit($event) : null"
								[localListHandlerTitle]="(currentContext === contextApplicationItemCodeEnum.BACKOFFICE
								? 'general.address.list'
								: 'destination.list')
								| translate
								| sentencecase"
							>
							</destination-list-wrapper>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
<!--
<button mat-fab 
	*ngIf="configurationCustomerUser.can_edit_organization.destination_edit[contextApplicationItemCodeEnum[user.current_permission.context_application]]"
	class="mat-fab-button"
	aria-label="Aggiungi indirizzo" 
	(click)="addAddress()">
	<mat-icon>add</mat-icon>
</button>
-->
