import { Component, Input, OnInit } from '@angular/core';
import { UtilKanbanService } from '../../service/util/util-kanban.service';
import { OpportunityPouchModel } from '../../model/state/opportunity-state.model';

@Component({
	selector: 'opportunity-rating',
	templateUrl: './opportunity-rating.component.html',
	styleUrls: ['./opportunity-rating.component.scss']
})
export class OpportunityRatingComponent implements OnInit {
	_opportunity: OpportunityPouchModel = null;
	@Input() set opportunity(opportunity: OpportunityPouchModel) {
		if (opportunity) {
			this._opportunity = opportunity;
			this.updateRatingList();
			this.ratingAvg = this.utilKanbanService.getAvg(this.ratingList.map(i => i.value));
		}
	}

	ratingList: { name: string; value: number }[];
	ratingAvg = 0;

	constructor(private utilKanbanService: UtilKanbanService) {}

	ngOnInit() {}

	updateRatingList() {
		this.ratingList = [
			{
				name: 'Organizzazione',
				value: this._opportunity.organization.crm.organization_rating || 0
			},
			{
				name: 'Interesse progetto',
				value: this._opportunity.project_interest || 0
			},
			{
				name: 'Probabilità chiusura',
				value: this._opportunity.close_probability || 0
			}
		];
	}
}
