import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContactActionEnum, ContactStateAction } from './contact.actions';

export namespace ContactStateReducer {
	export const initialState: BaseStateModel<ListDataPouchModel<ContactPouchModel>> = null;

	const _reducer = createReducer(
		initialState,
		on(ContactStateAction.load, state => state),
		on(ContactStateAction.loadAll, state => state),
		on(ContactStateAction.update, (state, contacts) => contacts),
		on(ContactStateAction.reset, () => initialState),
		on(ContactStateAction.error, (state, contact) => ({ data: state ? state.data : null, type: contact.type })),
		on(ContactStateAction.remove, (state, contact) => ({
			data: state ? state.data : null,
			type: contact.type
		})),
		on(ContactStateAction.removed, (state, contact) => state)
	);

	export function reducer(
		state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>,
		action: TypedAction<ContactActionEnum>
	) {
		return _reducer(state, action);
	}
}
