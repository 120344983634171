<div class="row">
	<div class="col-12">
		<h2>{{ title }}</h2>
	</div>
</div>
<mat-dialog-content class="mat-typography" mat-card-subtitle>
	<address-list
		[viewType]="ViewTypeEnum.list"
		[mode]="ListMode.pickable"
		(addressPicked)="pick($event)"
	></address-list>
</mat-dialog-content>
<mat-dialog-actions class="content-button" align="end">
	<button class="content-button__button" mat-stroked-button [mat-dialog-close]="false">
		Annulla
	</button>
</mat-dialog-actions>
