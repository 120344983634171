import { Route } from '@angular/router';
import { PermissionKeyEnum } from '@saep-ict/angular-core';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { ReservedAreaGuard } from '../service/guard/reserved-area.guard';
import { ROUTE_URL } from './route-naming';
import { PasswordUpdateComponent } from '../page/commons/authentication-wrapper/password-update/password-update.component';
import { B2cAuthenticationWrapper } from '../page/b2c/reserved-area/b2c-authentication-wrapper/b2c-authentication-wrapper.component';
import { PublicWrapperComponent } from '../frame/public-wrapper/public-wrapper.component';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { B2cOrganizationDetailComponent } from '../page/b2c/b2c-organization/b2c-organization-detail/b2c-organization-detail.component';
import { B2cOrganizationDetailGeneralComponent } from '../page/b2c/b2c-organization/b2c-organization-detail/b2c-organization-detail-general/b2c-organization-detail-general.component';
import { UserComponent } from '../page/commons/user/user.component';

export namespace B2CRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: PublicWrapperComponent,
			canActivate: [ReservedAreaGuard],
			data: {
				permission: 'reserved',
				permissionKey: [PermissionKeyEnum.B2C_DASHBOARD]
			},
			children: [
				{
					path: '',
					redirectTo: ROUTE_URL.myAccount,
					pathMatch: 'full',
					data: { permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DETAIL] }
				},
				{
					path: ROUTE_URL.myAccount,
					component: B2cOrganizationDetailComponent,
					resolve: {
						meta: MetaDataResolver
					},
					data: {
						meta: {
							title: 'Il mio account',
							description: 'Descrizione a caso per testare la parte meta ma nella pagina Il mio account'
						},
						permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DETAIL]
					},
					children: [
						{
							path: '',
							redirectTo: ROUTE_URL.details,
							data: { permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DETAIL] }, // TOFIX
							pathMatch: 'full'
						},
						{
							path: ROUTE_URL.details,
							component: B2cOrganizationDetailGeneralComponent,
							data: {
								permissionKey: [
									PermissionKeyEnum.B2C_ORGANIZATION_FULL_DETAIL,
									PermissionKeyEnum.B2C_CONTACT_LIST
								]
							} // TOFIX
						},
						{
							path: ROUTE_URL.addresses,
							component: OrganizationDetailDestinationComponent,
							data: { permissionKey: [PermissionKeyEnum.B2C_ORGANIZATION_DESTINATION] } // TOFIX
						},
						{
							path: ROUTE_URL.orders,
							component: OrderComponent,
							data: { permissionKey: [PermissionKeyEnum.B2C_ORDER_LIST] } // TOFIX
						},
						{
							path: ROUTE_URL.userData,
							component: UserComponent,
							data: { permissionKey: [PermissionKeyEnum.B2C_DASHBOARD] } // TOFIX
						}
					]
				},
				{
					path: ROUTE_URL.passwordUpdate,
					component: B2cAuthenticationWrapper,
					data: {
						breadcrumbsList: ['Home', 'Il mio account', 'Cambio Password'],
						context_type: 'password',
						permissionKey: [PermissionKeyEnum.B2C_DASHBOARD]
					},
					children: [
						{
							path: '',
							component: PasswordUpdateComponent,
							pathMatch: 'full',
							resolve: {
								meta: MetaDataResolver
							},
							data: {
								permissionKey: [PermissionKeyEnum.B2C_DASHBOARD],
								meta: {
									title: 'Cambio password',
									description:
										'Descrizione a caso per testare la parte meta ma nella pagina recovery password'
								},
								redirectLink: ['/', ROUTE_URL.private, ROUTE_URL.myAccount]
							}
						}
					]
				}
			]
			// loadChildren: () => import('../page/reserved-area/reserved-area.module').then(m => m.ReservedAreaModule),
			// canLoad: [ReservedAreaGuard]
		}
	];
}
