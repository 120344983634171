import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { AuxiliaryTableActionEnum, AuxiliaryTableStateAction } from './auxiliary-table.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { BaseStateModel, BaseState, BaseStateSerializerModel } from '@saep-ict/angular-core';
import { AuxiliaryTableStateModel, AuxiliaryDictionary } from '../../model/state/auxiliary-table-list';
import { from } from 'rxjs';
import { Store } from '@ngrx/store';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';

@Injectable()
export class AuxiliaryTableEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuxiliaryTableActionEnum.LOAD),
			mergeMap(() => from(this.getAuxiliaryTable())),
			map((auxiliaryTable: BaseStateModel<AuxiliaryTableStateModel>) =>
				AuxiliaryTableStateAction.update(auxiliaryTable)
			),
			catchError((error, caught) => {
				this.store.dispatch(AuxiliaryTableStateAction.error(null));
				return caught;
			})
		)
	);

	loadItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuxiliaryTableActionEnum.LOAD_ITEM),
			mergeMap((action: BaseStateSerializerModel<AuxiliaryTableStateModel>) =>
				from(this.getAuxiliaryTableItem(action))
			),
			map((auxiliaryTable: BaseStateSerializerModel<AuxiliaryTableStateModel>) =>
				AuxiliaryTableStateAction.updateItem(auxiliaryTable)
			),
			catchError((error, caught) => {
				this.store.dispatch(AuxiliaryTableStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter
	) {}

	async getAuxiliaryTable(): Promise<BaseStateModel<AuxiliaryTableStateModel>> {
		const promises = [];
		const auxiliaryTable: AuxiliaryTableStateModel = {};
		AuxiliaryDictionary.forEach((auxiliary: { key: string; type: string }) => {
			promises.push(
				this.pouchDbCommonsAdapter.auxiliaryTablePouch
					.getAuxiliaryTable(auxiliary.key)
					.then(doc => {
						return (auxiliaryTable[auxiliary.type] = doc);
					})
					.catch(err => {
						return (auxiliaryTable[auxiliary.type] = null);
					})
			);
		});
		await Promise.all(promises);
		return new BaseState(auxiliaryTable);
	}

	async getAuxiliaryTableItem(
		action: BaseStateSerializerModel<AuxiliaryTableStateModel>
	): Promise<BaseStateSerializerModel<AuxiliaryTableStateModel>> {
		const actionReturn: BaseStateSerializerModel<AuxiliaryTableStateModel> = action;
		return this.pouchDbCommonsAdapter.auxiliaryTablePouch
			.getAuxiliaryTable(action.stateSlice.key)
			.then(doc => {
				actionReturn.data[action.stateSlice.type] = doc;
				return actionReturn;
			})
			.catch(err => {
				actionReturn[action.type] = null;
				return actionReturn;
			});
	}
}
