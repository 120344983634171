import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel, DateMomentService, UtilService } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StatisticsOrganizationActionEnum, StatisticsOrganizationStateAction } from './statistics-organization.action';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import {
	StatisticsOrganization,
	StatisticsOrganizationBaseSerieOrder
} from '../../model/statistics-organization.model';
import { AppUtilService } from '../../service/util/app-util.service';

@Injectable()
export class StatisticsOrganizationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsOrganizationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<any>) => from(this.getStatisticsOrganization(action))),
			mergeMap((action: BaseStateModel<StatisticsOrganization>) => from(this.sortStatisticsOrganization(action))),
			mergeMap((action: BaseStateModel<StatisticsOrganization>) =>
				from(this.convertDateStatisticsOrganization(action))
			),
			map((action: BaseStateModel<StatisticsOrganization>) => StatisticsOrganizationStateAction.update(action)),
			catchError((error, caught) => {
				console.error(error);
				this.store.dispatch(StatisticsOrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService,
		private appUtilService: AppUtilService
	) {}

	// Chiavi degli oggetti
	keyClients = ['active_client', 'inactive_client'];
	keyOrder = ['confirmed', 'partially_fulfilled', 'consolidated', 'average', 'count'];
	keyClientsTop5 = ['days_30', 'days_60', 'days_90'];
	keyBalance = ['due', 'expired'];
	keyFulfilled = ['fulfilled_trend'];

	async getStatisticsOrganization(
		action: BaseStateModel<any>
	): Promise<BaseStateModel<StatisticsOrganization>> {
		await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
		).statisticsPouch
			.getStatistics(action.data.code_item)
			.then(res => {
				action.data = res;
			})
			.catch(err => {console.log(err); return action});
		return action;
	}

	async sortStatisticsOrganization(
		action: BaseStateModel<StatisticsOrganization>
	): Promise<BaseStateModel<StatisticsOrganization>> {
		if (!action.data.data) {
			return action;
		}
		// order_trend
		// ordino in base alla data: dalla meno alla più recente
		// this.keyOrder.forEach(key => {
		// 	if (action.data.orders.order_trend[key]) {
		// 		action.data.orders.order_trend[key].sort((a, b) => a.date - b.date);
		// 	}
		// });
		this.keyOrder.forEach(key => {
			if (this.appUtilService.findLeaf(action.data.data, `orders.order_trend.${key}`)) {
				this.appUtilService
					.findLeaf(action.data.data, `orders.order_trend.${key}`)
					.sort((a, b) => a.date - b.date);
			}
		});

		// fulfilled
		// ordino prima per anno (DESC) e poi per Quarter (ASC)
		// action.data.fulfilled.fulfilled_trend.sort((a, b) => {
		// 	if (typeof a.date === 'number' && typeof b.date === 'number') {
		// 		return a.date - b.date;
		// 	}
		// 	return 0;
		// });
		// action.data.fulfilled.quarters.sort(
		// 	(a, b) => b.year - a.year || +b.name.replace('Q', '') - +a.name.replace('Q', '')
		// );
		this.appUtilService.findLeaf(action.data.data, `fulfilled.fulfilled_trend`).sort((a, b) => {
			if (typeof a.date === 'number' && typeof b.date === 'number') {
				return a.date - b.date;
			}
			return 0;
		});
		// action.data.data
		// 	.find(stat => stat.key == 'fulfilled')
		// 	.value.find(el => el.key == 'quarters')
		// 	.value.sort((a, b) => b.year - a.year || +b.name.replace('Q', '') - +a.name.replace('Q', ''));

		// clients
		// ordino in base alla data: dalla meno alla più recente
		// this.keyClients.forEach(key => {
		// 	if (action.data.clients[key]) {
		// 		action.data.clients[key].sort((a, b) => a.date - b.date);
		// 	}
		// });
		// this.keyClients.forEach(key => {
		// 	if (action.data.data.find(stat => stat.key == 'clients').value.find(el => el.key == key).value[key]) {
		// 		action.data.data
		// 			.find(stat => stat.key == 'clients')
		// 			.value.find(el => el.key == key)
		// 			.value[key].sort((a, b) => a.date - b.date);
		// 	}
		// });

		// balance
		// ordino in base alla data: dalla meno alla più recente
		// this.keyBalance.forEach(key => {
		// 	if (action.data.balance[key]) {
		// 		action.data.balance[key].sort((a, b) => a.date - b.date);
		// 	}
		// });
		this.keyBalance.forEach(key => {
			if (this.appUtilService.findLeaf(action.data.data, `balance.${key}`)) {
				this.appUtilService.findLeaf(action.data.data, `balance.${key}`).sort((a, b) => a.date - b.date);
			}
		});
		return action;
	}

	async convertDateStatisticsOrganization(
		action: BaseStateModel<StatisticsOrganization>
	): Promise<BaseStateModel<StatisticsOrganization>> {
		if (!action.data.data) {
			return action
		}
		// order_trend
		// this.keyOrder.forEach(key => {
		// 	if (action.data.orders.order_trend[key]) {
		// 		action.data.orders.order_trend[key].map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 			element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 		});
		// 	}
		// });
		this.keyOrder.forEach(key => {
			this.appUtilService
				.findLeaf(action.data.data, `orders.order_trend.${key}`)
				.map((element: StatisticsOrganizationBaseSerieOrder) => {
					element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
				});
		});

		// clients
		// this.keyClients.forEach(key => {
		// 	action.data.clients[key].map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 		element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 	});
		// });
		// this.keyClients.forEach(key => {
		// 	action.data.data
		// 		.find(stat => stat.key == 'clients')
		// 		.value.find(el => el.key == key)
		// 		.value.map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 			element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 		});
		// });

		// balance
		// this.keyBalance.forEach(key => {
		// 	action.data.balance[key].map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 		element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 	});
		// });
		// this.keyBalance.forEach(key => {
		// 	this.appUtilService
		// 		.findLeaf(action.data.data, `balance.${key}`)
		// 		.map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 			element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 		});
		// });

		// fulfilled
		// this.keyFulfilled.forEach(key => {
		// 	if (action.data.fulfilled[key]) {
		// 		action.data.fulfilled[key].map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 			element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 		});
		// 	}
		// });
		// this.keyFulfilled.forEach(key => {
		// 	this.appUtilService
		// 		.findLeaf(action.data.data, `fulfilled.${key}`)
		// 		.map((element: StatisticsOrganizationBaseSerieOrder) => {
		// 			element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
		// 		});
		// });

		return action;
	}
}
