<span [innerHTML]="data.message | safeDomSanitizer: 'html'"></span>
<div
    *ngIf="data.action"
    class="mat-simple-snackbar"
>
    <div class="mat-simple-snackbar-action">
        <button mat-button (click)="snackBarRef.dismiss()">
            <span class="mat-button-wrapper">{{data.action}}</span>
        </button>
    </div>
</div>