import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	DialogConfirmComponent,
	DialogTextEditComponent,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';
import { StateFeature } from '../../../../../state';
import {
	OrganizationStateAction,
	OrganizationActionEnum
} from '../../../../../state/organization/organization.actions';
import { BaseState } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { CommentStateModel } from '../../../../../model/state/comment-state.model';
import moment from 'moment';

@Component({
	selector: 'organization-detail-comment',
	templateUrl: './organization-detail-comment.component.html',
	styleUrls: ['./organization-detail-comment.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationDetailCommentComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	currentContext: ContextApplicationItemCodeEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	addCommentValue = '';

	constructor(
		private store: Store<any>,
		private dialog: MatDialog,
		private translate: TranslateService,
		private subscribeManagerService: SubscribeManagerService
	) {
		// user
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = this.user.current_permission.context_application;
		});

		// organization
		this.subscribeManagerService.populate(
			this.subscribeOrganizationState().subscribe(),
			'subscribeOrganizationState'
		);
	}

	subscribeOrganizationState() {
		return this.organization$.pipe(
			filter((state: BaseStateModel<OrganizationStateModel>) => !!state),
			map((state: BaseStateModel<OrganizationStateModel>) => {
				switch (state.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = state.data;
						break;

					case OrganizationActionEnum.SAVE_SUCCESS:
						this.addCommentValue = '';
						this.store.dispatch(OrganizationStateAction.update(new BaseState(state.data)));
						break;

					default:
						break;
				}
			})
		);
	}

	ngOnInit() {}

	addComment() {
		const comment: CommentStateModel = {
			created_on: moment().valueOf(),
			user_id: this.user.username,
			text: this.addCommentValue
		};
		this.organization = this.prepareOrganization(comment);
		this.store.dispatch(OrganizationStateAction.save(new BaseState(this.organization)));
	}

	deleteComment(index) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.translate.instant('comment.question.delete')
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let organization = _.cloneDeep(this.organization);
				organization.comment_list.splice(index, 1);
				this.store.dispatch(OrganizationStateAction.save(new BaseState(organization)));
			}
		});
	}

	editComment(index) {
		const dialogRef: MatDialogRef<DialogTextEditComponent> = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: this.translate.instant('comment.edit'),
				text: this.organization.comment_list[index].text
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let organization = _.cloneDeep(this.organization);
				organization.comment_list[index].text = res;
				this.store.dispatch(OrganizationStateAction.save(new BaseState(organization)));
			}
		});
	}

	prepareOrganization(comment: CommentStateModel): OrganizationStateModel {
		let organization = _.cloneDeep(this.organization);
		if (organization.comment_list) {
			organization.comment_list.push(comment);
		} else {
			organization.comment_list = [comment];
		}
		return organization;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
