// model
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';
// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { CrmUserListActionEnum, CrmUserListStateAction } from './crm-user-list.actions';
import { BaseStateModel } from '@saep-ict/angular-core';

export namespace CrmUserListStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchModel[]> = null;
	const _reducer = createReducer(
		initialState,
		on(CrmUserListStateAction.loadList, state => state),
		on(CrmUserListStateAction.updateList, (state, param) => param),
		on(CrmUserListStateAction.updateItem, (state, param) => param),
		on(CrmUserListStateAction.createItemSuccess, (state, param) => param),
		on(CrmUserListStateAction.deleteItemSuccess, (state, param) => param),
		on(CrmUserListStateAction.reset, () => initialState),
		on(CrmUserListStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<BodyTablePouchCustomModel[]>, action: TypedAction<CrmUserListActionEnum>) {
		return _reducer(state, action);
	}
}
