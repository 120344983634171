import { Injectable } from '@angular/core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OrganizationPouchModel, ListViewDataPouchModel } from '@saep-ict/pouch_agent_models';
import { CouchDocumentType } from '../../constants/context-state.map';
import { BodyTablePouchCustomModel } from '../pouch-db/model/pouch-base.model';
import { PouchAdapterSelectorService } from '../pouch-db/pouch-adapter-selector.service';

@Injectable({
	providedIn: 'root'
})
export class UtilPouchService {
	constructor(private pouchAdapterSelectorService: PouchAdapterSelectorService) {}
	async saveAnyDocument(action: any): Promise<any> {
		try {
			const actionReturn = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.saveBodyTable(action);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async deleteAnyDocument(action: any): Promise<any> {
		try {
			const actionReturn = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.deleteBodyTable(action);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveCodeItem(
		action: BaseStateModel<BodyTablePouchCustomModel>,
		couchDocumentType?: CouchDocumentType
	): Promise<BaseStateModel<BodyTablePouchCustomModel>> {
		const actionReturn = <BaseStateModel<BodyTablePouchCustomModel>>action;
		actionReturn.data.code_item = actionReturn.data._id.replace(actionReturn.data.type + '_', '');
		try {
			actionReturn.data = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(couchDocumentType)
			).basePouch.saveBodyTable(actionReturn.data);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveCode(action: BaseStateModel<OrganizationPouchModel>): Promise<BaseStateModel<OrganizationPouchModel>> {
		const actionReturn = <BaseStateModel<OrganizationPouchModel>>action;
		actionReturn.data.code = actionReturn.data._id.replace(actionReturn.data.type + '_', '');
		try {
			actionReturn.data = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.saveBodyTable(actionReturn.data);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async getView<D>(path: string): Promise<BaseStateModel<D[]>> {
		try {
			const view: ListViewDataPouchModel<D> = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter()
			).basePouch.getView<D>(path);
			const actionReturn = <BaseStateModel<D[]>>{
				data: this.mapViewResponse(view)
			};
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async allDocs<D>(opts, documentType?: CouchDocumentType): Promise<BaseStateModel<D[]>> {
		try {
			const view: ListViewDataPouchModel<D> = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(documentType)
			).basePouch.allDocs<D>(opts);
			const actionReturn = <BaseStateModel<D[]>>{
				data: this.mapAllDocsResponse(view)
			};
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	// widget & utility

	mapViewResponse<D>(view: ListViewDataPouchModel<D>): D[] {
		return view.rows.map(i => i.value);
	}

	// TODO: modificare tipizzazione any una volta generalizzato ListViewDataPouchModel
	// in modo possa restituire anche doc
	mapAllDocsResponse<D>(view: any): any[] {
		return view.rows.map(i => i.doc);
	}
}
