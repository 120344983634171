<breadcrumb [title]="'general.' + breadcrumbTitle | translate | sentencecase" [pathList]="['Home']"> </breadcrumb>

<div class="container-fluid">
	<div class="row">
		<ng-container *ngFor="let card of cardList; let i = index">
			<div *ngIf="getVisibilityCard(card)" class="col-md-6">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							{{ card.title }}
							<span class="flex-span"></span>
							<mat-icon>{{ card.icon }}</mat-icon>
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<ng-template [ngIf]="card.upload">
							<attachment-resolver
								[multiple]="card.upload.multiple"
								[acceptedType]="card.upload.acceptedType"
								(fileEmitter)="onAttachmentResolverFileChange($event, i)"
							></attachment-resolver>
						</ng-template>
						<button
							*ngIf="!card.button.disabled"
							(click)="card.button.action(i)"
							mat-raised-button
							color="accent"
							class="full-width"
						>
							{{ card.button.label }}
						</button>
					</mat-card-content>
				</mat-card>
			</div>
		</ng-container>
	</div>
</div>

