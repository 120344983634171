import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class StorefrontConfigurationService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/configuration'
	})
	getConfiguration: IResourceMethodPromise<void, any>;
}
