import { Injectable } from '@angular/core';
import { BaseApiService } from './baseApi.service';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBasePk, RestBaseResponse } from '@saep-ict/angular-core';

@Injectable()
export class ProductService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/storages/buckets/as400/{!id}'
	})
	downloadProductDrawing: IResourceMethodPromise<RestBasePk, RestBaseResponse<any>>;
}
