import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ListWrapperComponent,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ReceiptStateModel } from '../../../model/state/receipt-state.model';
import { ReceiptColumnService } from '../../../service/td-data-table/implementation/receipt-list.service';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { StateFeature } from '../../../state';
import { ReceiptListActionEnum, ReceiptListStateAction } from '../../../state/receipt-list/receipt-list.actions';

@Component({
	selector: 'organization',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss'],
	providers: [SubscribeManagerService]
})
export class ReceiptComponent implements OnInit, OnDestroy {
	@ViewChild('listWrapper') public listWrapper: ListWrapperComponent;

	receiptList$: Observable<BaseStateModel<ReceiptStateModel[]>> = this.store.select(
		StateFeature.getReceiptListState
	);
	receiptList: ReceiptStateModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	public listPageBaseData = <LocalListHandlerBaseModel<ReceiptStateModel>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['document_number']
			}
		},
		sort: {
			name: 'document_number',
			order: 'DESC'
		}
	};

	filterOpened = false;
	columns: ITdDataTableColumn[] = this.receiptColumnService.columns;
	formFilters: FormGroup;
	documentTypes = ['invoice', 'credit_note'];

	constructor(
		private store: Store<any>,
		public receiptColumnService: ReceiptColumnService,
		private subscribeManagerService: SubscribeManagerService,
		private permissionUtilService: PermissionUtilService,
		private fb: FormBuilder
	) {
		this.createFormFilters();
		this.store.dispatch(ReceiptListStateAction.loadAll());

		this.subscribeManagerService.populate(
			this.subscribeReceiptList().subscribe(),
			'subscribeReceiptList'
		);

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.store.dispatch(ReceiptListStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	/**
	 * Subscription
	 */

	subscribeReceiptList() {
		return this.receiptList$.pipe(
			filter((state: BaseStateModel<ReceiptStateModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<ReceiptStateModel[]>) => {
				switch (state.type) {
					case ReceiptListActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case ReceiptListActionEnum.ERROR:
						throw new Error(ReceiptListActionEnum.ERROR);
						break;

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(receiptList: ReceiptStateModel[]) {
		this.receiptList = receiptList;
		this.updateListPageBaseData(this.receiptList);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: ReceiptStateModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			receipt_date: undefined,
			receipt_document_type: '',
			receipt_organization_name: ''
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			receipt_date: null,
			receipt_document_type: '',
			receipt_organization_name: '',
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.receiptList);

		// filtro sul tipo di documento
		if (this.formFilters && this.formFilters.value.receipt_document_type) {
			const type: string = this.formFilters.value.receipt_document_type;
			filteredList = filteredList.filter(item => {
				return item.document_type === type;
			});
		}

		// filtro sul nome dell'organizzazione
		if (this.formFilters && this.formFilters.value.receipt_organization_name) {
			const organizationName: string = this.formFilters.value.receipt_organization_name;
			filteredList = filteredList.filter(item => {
				return item.organization.name.indexOf(organizationName)+1;
			});
		}

		// filtro sulla data del documento
		if (this.formFilters && this.formFilters.value.receipt_date) {
			const date: number = this.formFilters.value.receipt_date;
			filteredList = filteredList.filter(item => {
				return item.date === date;
			});
		}

		this.updateListPageBaseData(filteredList);
	}
}
