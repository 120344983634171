<div
	*ngIf="_avatarConfigClass"
	class="avatar"
	[class.rounded]="_avatarConfigClass.isRounded"
	[ngStyle]="{ 'height.px': avatarSize, 'width.px': avatarSize }"
>
	<img
		*ngIf="_avatarConfigClass.urlImage"
		[src]="_avatarConfigClass.urlImage"
		[ngStyle]="{ 'height.px': avatarSize, 'width.px': avatarSize }"
	/>
	<div
		*ngIf="!_avatarConfigClass.urlImage"
		class="initials"
		[ngStyle]="{
			'line-height.px': avatarSize,
			'width.px': avatarSize,
			'font-size.px': avatarSize / 3
		}"
	>
		{{ _avatarConfigClass.userName ? this.getInitalsName(this._avatarConfigClass.userName) : '' }}
	</div>
</div>
