import { Component, OnInit, OnDestroy } from '@angular/core';
import { FileTypeEnum, UserDetailModel, BaseStateModel, PermissionKeyEnum, UtilService } from '@saep-ict/angular-core';

// store
import * as fromState from '../../../state/index';
import { Store } from '@ngrx/store';

// widget & utility
import { ConfigurationService } from '../../../service/rest/backoffice/configuration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { skipWhile, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

export interface CardItem {
	code: string; // card identifier
	type?: string; // query param per la chiamata
	title: string;
	icon: string;
	button: {
		disabled: boolean;
		label: string;
		action?: (value: any) => any;
	};
	upload?: {
		multiple: boolean;
		acceptedType: FileTypeEnum;
		data: { name: string; data: string }[];
	};
	permission: string[];
}

@Component({
	selector: 'data-configuration',
	templateUrl: './data-configuration.component.html',
	styleUrls: ['./data-configuration.component.scss'],
	providers: [SubscribeManagerService]
})
export class DataConfigurationComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(fromState.StateFeature.getUserState);
	user: BaseStateModel<UserDetailModel>;

	// card identifier list
	cardCodes: string[];

	breadcrumbTitle: string;

	cardList: CardItem[] = [
		{
			code: 'sync_web_app_with_AS400',
			title: 'Sync Web App with AS/400',
			icon: 'refresh',
			button: {
				disabled: false,
				label: 'Start',
				action: (index: number) => this.synchAs400()
			},
			permission: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
		},
		// {
		// 	title: "Sync Web App Product Stock",
		// 	icon: "storefront",
		// 	button: {
		// 		disabled: false,
		// 		label: "Start",
		// 		action: (index: number) => this.syncProductStock()
		// 	},
		// 	permission: [
		// 		PermissionKeyEnum.BACKOFFICE_DASHBOARD
		// 	]
		// },
		{
			code: 'upload_order_form_category',
			title: 'Upload Order Form Category',
			icon: 'storefront',
			type: 'agent-order-form',
			upload: {
				multiple: false,
				acceptedType: FileTypeEnum.EXCEL,
				data: []
			},
			button: {
				disabled: true,
				label: 'Upload',
				action: (index: number) => this.uploadCategoryTree(index)
			},
			permission: [PermissionKeyEnum.BACKOFFICE_DASHBOARD]
		}
	];

	constructor(
		private configurationService: ConfigurationService,
		private utilService: UtilService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private snackBar: MatSnackBar,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {
		this.cardCodes = this.activatedRoute.snapshot.data.cardCodes || [];
		this.breadcrumbTitle = this.router.url.split('/').pop();
		this.subscribeManagerService.populate(this.subscribeUser().subscribe(), 'user');
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	subscribeUser() {
		return this.user$.pipe(
			skipWhile((user: BaseStateModel<UserDetailModel>) => !user),
			map((user: BaseStateModel<UserDetailModel>) => {
				this.user = user;
			})
		);
	}

	// card visibility
	getVisibilityCard(card: CardItem) {
		if (
			this.cardCodes.includes(card.code) &&
			this.utilService.hasAtLeastOnePermission(this.user, card.permission)
		) {
			return true;
		}
		return false;
	}

	// data

	synchAs400() {
		this.configurationService.synchAs400(
			res => {
				this.genericSuccessMessage();
			},
			err => {
				this.genericErrorMessage();
			}
		);
	}

	syncProductStock() {
		this.configurationService.syncProductStock(
			res => {
				this.genericSuccessMessage();
			},
			err => {
				this.genericErrorMessage();
			}
		);
	}

	uploadCategoryTree(index) {
		this.configurationService.uploadCategoryTree(
			this.cardList[index].upload.data[0].data,
			{ type: this.cardList[index].type },
			res => {
				this.genericSuccessMessage();
			},
			err => {
				this.genericErrorMessage();
			}
		);
	}

	// events

	onAttachmentResolverFileChange(e: any[], index: number) {
		this.cardList[index].button.disabled = e.length === 0;
		this.cardList[index].upload.data = e;
	}

	// widget & utility

	genericSuccessMessage() {
		this.snackBar.open('Operation successfull', 'OK', {
			duration: 3000
		});
	}

	genericErrorMessage() {
		this.snackBar.open('An error has occurred, try again later', 'OK', {
			duration: 10000
		});
	}
}
