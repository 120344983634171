import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'enumIterator' })
export class EnumIteratorPipe implements PipeTransform {
  transform(value): any {
	return Object.keys(value)
		.filter(f => !isNaN(+f))
		.map(m => {
			return {
				description: value[m],
				id: +m
			};
    });
  }
}
