import { UtilCategoryListService } from '../../service/util/util-category-list.service';
import {
	ContextApplicationItemCodeEnum,
	LinkCodeModel,
	RestBaseMessageError,
	RestBasePk
} from '@saep-ict/angular-core';
import { ArticleCategory } from './../../model/article.model';
import { Category } from './../../model/category-list.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	OrderProgressPouchModel,
	DestinationPouchModel,
	ProgressOrderStatusEnum,
	OrderStatusEnum
} from '@saep-ict/pouch_agent_models';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map, mergeMap, skipWhile } from 'rxjs/operators';
import { BaseState, BaseStateModel, DataSetting } from '@saep-ict/angular-core';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OrderActionEnum, OrderStateAction } from './order.actions';
import {
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	OrderWithArticleDetailRequest,
	ProductVariationStateModel
} from '../../model/state/order-state.model';
import { ArticleListEffects } from '../article-list/article-list.effects';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { ArticleRecap, ArticleRecapArticleList, ArticleState } from '../../model/state/article-list-state.model';
import { OrderListFilterModel, OrderFilterModel } from '../../service/pouch-db/filter/order-filter.model';
import { DestinationFilterModel } from '../../service/pouch-db/filter/destination-filter.model';
import { UtilOrderService } from '../../service/util/util-order.service';
import { UtilArticleKitService } from '../../service/util/util-article-kit.service';
import { ProductVariationTypeEnum } from '../../enum/product-variation.enum';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { StateFeature } from '..';
import { CouchDocumentType } from '../../constants/context-state.map';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { CustomerAppConfig } from '../../customer-app.config';
import { OrderService } from '../../service/rest/order.service';
import { ArticleDescriptionEffects } from '../article-description/article-description.effects';

@Injectable()
export class OrderEffects {
	@LocalStorage('link_code') link_code: LinkCodeModel;
	@LocalStorage('current_order_id') current_order_id: string;

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.LOAD),
			mergeMap((action: BaseStateModel<RestBasePk>) => from(this.getOrderDetail(action.data))),
			map((order: BaseStateModel<OrderStateModel>) => OrderStateAction.update(order)),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(null));
				return caught;
			})
		)
	);

	loadWithDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.LOAD_WITH_DETAIL),
			mergeMap((action: BaseStateModel<OrderWithArticleDetailRequest, OrderFilterModel>) =>
				from(this.getOrderDetailWithArticle(action))
			),
			mergeMap((action: BaseStateModel<OrderStateModel>) => from(this.mergeOrderVariation(action))),
			// mergeMap((action: BaseStateModel<OrderStateModel, OrderFilterModel>) =>
			// 	from(this.mergeCategoryInformation(action))
			// ),
			mergeMap((action: BaseStateModel<OrderStateModel, OrderFilterModel>) =>
				from(this.mergeArticleKitDetails(action))
			),
			map((order: BaseStateModel<OrderStateModel, OrderFilterModel>) => OrderStateAction.update(order)),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.SAVE),
			concatMap((action: BaseStateModel<OrderWithArticleDetailRequest, OrderFilterModel>) =>
				from(this.sendOrderEmails(action))
			),
			mergeMap((action: BaseStateModel<OrderWithArticleDetailRequest, OrderFilterModel>) =>
				from(this.saveOrderDetail(action))
			),
			// mergeMap((action: BaseStateModel<OrderStateModel, OrderFilterModel>) =>
			// 	from(this.mergeCategoryInformation(action))
			// ),
			// mergeMap((action: BaseStateModel<OrderStateModel, OrderFilterModel>) =>
			// 	from(this.mergeArticleKitDetails(action))
			// ),
			map((order: BaseStateModel<OrderStateModel, OrderFilterModel>) => {
				this.store.dispatch(OrderStateAction.update(order));
				// se l'utente non è loggato o se è loggato nel contesto B2C (quindi nello storefront)
				if (
					(!this.appConfig.authenticationToken ||
						this.link_code.context == ContextApplicationItemCodeEnum.B2C) &&
					order.data.header.status == OrderStatusEnum.DRAFT
				) {
					this.current_order_id = order.data._id;
				} else {
					this.storage.clear('current_order_id');
				}
				return order;
			}),
			map((order: BaseStateModel<OrderStateModel, OrderFilterModel>) => {
				// se l'ordine è da autorizzare (quindi certamente per il caso di submit da B2C) emetti l'action `completed`
				if (order.data.header.status == OrderStatusEnum.TO_AUTHORIZE) {
					return OrderStateAction.completed(order);
				}
				return OrderStateAction.update(order);
			}),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<OrderStateModel>) => from(this.deleteOrder(action.data))),
			map((order: BaseStateModel<PouchDeleteResponse>) => OrderStateAction.removed(order)),
			catchError((error, caught) => {
				this.store.dispatch(OrderStateAction.error(null));
				return caught;
			})
		)
	);

	// articleDescription$: Observable<BaseStateModel<ArticleRecap>> = this.store.select(
	// 	StateFeature.getArticleDescription
	// );
	// articleDescription: ArticleRecap;

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilOrderService: UtilOrderService,
		private utilCategoryListService: UtilCategoryListService,
		private utilArticleKitService: UtilArticleKitService,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private storage: LocalStorageService,
		private appConfig: CustomerAppConfig,
		private orderService: OrderService
	) {
		// this.articleDescription$.pipe().subscribe(articleDescription => {
		// 	this.articleDescription = articleDescription ? articleDescription.data : null;
		// });
	}

	async getOrderDetail(data: RestBasePk): Promise<BaseStateModel<OrderStateModel>> {
		return new Promise(async (resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				resolve(
					(
						await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)
					).orderPouch.getOrderDetail(data)
				);
			} else {
				this.orderService.getNewOrder(data).then(order => resolve(order.data));
			}
		})
			.then(async (order: OrderStateModel) => {
				order = this.utilOrderService.addOrderId(order);
				return new BaseState(order);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getOrderDetailWithArticle(
		action: BaseStateModel<OrderWithArticleDetailRequest, OrderFilterModel>
	): Promise<BaseStateModel<OrderStateModel, OrderFilterModel>> {
		try {
			const order: BaseStateModel<OrderStateModel, OrderFilterModel> = await this.getOrderDetail({
				id: action.data.id
			});
			action.data.orderData.order = order.data;
			order.data = await this.mergeArticleWithProduct(action.data.orderData);
			// Progress order
			if (Object.values(ProgressOrderStatusEnum).includes(order.data.header.status as any)) {
				const orderFilter: OrderListFilterModel = { _id: order.data._id };
				let orderProgress = await this.getOrderProgress(orderFilter)
					.then(res => (orderProgress = res))
					.catch(err => console.error(err));
				if (orderProgress) {
					order.data.order_progress = orderProgress;
				}

				if (orderProgress && orderProgress.values) {
					// Ordina gli oggetti per data DESC e prende il primo
					orderProgress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
					const lastOrderProgress = orderProgress.values[0];

					//  values changed
					const valuesChanged = lastOrderProgress.values_changed;
					if (valuesChanged) {
						if (!order.data.header.order_progress_detail) {
							order.data.header.order_progress_detail = {};
						}

						// first evasion date
						if (valuesChanged[`root['header']['first_evasion_date']`]) {
							const firstEvasionDate: any =
								valuesChanged[`root['header']['first_evasion_date']`].old_value;
							order.data.header.order_progress_detail.first_evasion_date = firstEvasionDate;
						}

						// goods destination
						if (valuesChanged[`root['header']['goods_destination_code']`]) {
							const goodsDestinationCodeOldValue: any =
								valuesChanged[`root['header']['goods_destination_code']`].old_value;
							const destinationFilter: DestinationFilterModel = {
								id: goodsDestinationCodeOldValue,
								code_organization: order.data.header.organization.code_item
							};
							(
								await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)
							).destinationPouch
								.getDestination(destinationFilter)
								.then((destination: DestinationPouchModel) => {
									order.data.header.order_progress_detail.goods_destination = destination;
									return order;
								});
						}

						// order causal code
						if (valuesChanged[`root['header']['order_causal']`]) {
							const orderCausalOld: any = valuesChanged[`root['header']['order_causal']`].old_value;
							order.data.header.order_progress_detail.order_causal = orderCausalOld;
						}
					}
				}
			}
			const filter: OrderFilterModel = { organization: action.data.orderData.organization };
			order.dataSetting = { appliedFilter: filter };

			return order;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async mergeOrderVariation(data: BaseStateModel<OrderStateModel>): Promise<BaseStateModel<OrderStateModel>> {
		try {
			const order = data;

			// Progress order
			if (Object.values(ProgressOrderStatusEnum).includes(order.data.header.status as any)) {
				const orderProgress = order.data.order_progress;
				if (orderProgress && orderProgress.values) {
					if (!order.data.order_variation_list) {
						order.data.order_variation_list = [];
					}

					// Ordina gli oggetti per data DESC e prende il primo
					orderProgress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
					const lastOrderProgress = orderProgress.values[0];

					// values changed
					const valuesChanged = lastOrderProgress.values_changed;
					if (valuesChanged) {
						// Product list della first version di un ordine
						const orderProgressProductList = orderProgress['first_version']['product_list'];
						// Contiene gli indici dei prodotti REPLACED
						const indexListReplacedProducts = [];

						// Product REPLACED / PROPERTY_CHANGED
						for (const valueObj in valuesChanged) {
							// Skip se la proprietà proviene da prototype
							if (!valuesChanged.hasOwnProperty(valueObj)) {
								continue;
							}
							const productListLength = orderProgressProductList.length;

							if (valueObj.startsWith(`root['product_list']`)) {
								for (let i = 0; i < productListLength; i++) {
									if (
										valueObj.startsWith(`root['product_list'][${i}]`) &&
										!indexListReplacedProducts.includes(i)
									) {
										// Estrae il nome della proprietà cambiata
										let propertyChanged = valueObj.replace(`root['product_list'][${i}]`, '');
										if (propertyChanged !== '') {
											propertyChanged = propertyChanged.slice(2, -2);
										}

										// Codice prodotto che ha subito la variazione
										const code = orderProgressProductList[i].code;

										const obj: Object = valuesChanged[valueObj];
										let productCode: string;
										let productVariationType: any;

										if (propertyChanged === 'code') {
											// Product REPLACED
											productCode = obj['old_value'];
											productVariationType = ProductVariationTypeEnum.REPLACED;
											indexListReplacedProducts.push(i);
										} else {
											// Product PROPERTY_CHANGED
											productCode = code;
											productVariationType = ProductVariationTypeEnum.PROPERTY_CHANGED;
										}
										const itemChanged: ProductVariationStateModel = {
											productCode: productCode,
											type: productVariationType,
											propertyName: propertyChanged,
											oldValue: obj['old_value'],
											newValue: obj['new_value']
										};
										order.data.order_variation_list.push(itemChanged);
									}
								}
							}
						}
					}

					// Product ADDED
					const iterableItemAdded = lastOrderProgress.iterable_item_added;
					if (iterableItemAdded) {
						for (const key in iterableItemAdded) {
							// Skip se la proprietà proviene da prototype
							if (!iterableItemAdded.hasOwnProperty(key)) {
								continue;
							}
							const obj: Object = iterableItemAdded[key];
							const itemAdded: ProductVariationStateModel = {
								productCode: obj['code'],
								type: ProductVariationTypeEnum.ADDED
							};
							order.data.order_variation_list.push(itemAdded);
						}
					}

					// Product REMOVED
					const iterableItemRemoved = lastOrderProgress.iterable_item_removed;
					if (iterableItemRemoved) {
						for (const key in iterableItemRemoved) {
							// Skip se la proprietà proviene da prototype
							if (!iterableItemRemoved.hasOwnProperty(key)) {
								continue;
							}
							const obj: Object = iterableItemRemoved[key];
							const itemRemoved: ProductVariationStateModel = {
								productCode: obj['code'],
								type: ProductVariationTypeEnum.REMOVED
							};
							order.data.order_variation_list.push(itemRemoved);
						}
					}
				}
			}
			// product has no input_quantity
			order.data.product_list = this.utilOrderService.fillInputQuantityWithOrderedQuantity(
				order.data.product_list
			);
			return order;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async mergeArticleWithProduct(data: ArticleListFilterModel): Promise<OrderStateModel> {
		const hasPrice = data.article.excludePrice ? false : true;
		try {
			const code_list = data.order.product_list.map(product => product.code);
			data.article.code_list = code_list;
			const filter = new BaseState<ArticleState[], ArticleListFilterModel>(null, { appliedFilter: data });
			// let articleList: BaseStateModel<ArticleState[], ArticleListFilterModel>;
			// if (hasPrice) {
			// 	filter.dataSetting.appliedFilter.division = data.order.header.division;
			// 	articleList = await this.articleListEffect.getArticleListFromRecap(filter);
			// } else {
			// 	articleList = await this.articleListEffect.getArticleListFromSingleCall(filter);
			// }
			// let articleList: ArticleRecapArticleList[] = (
			// 	await this.articleDescriptionEffects.getArticleDescriptionList()
			// ).data.article_list;

			// Document links
			const orderDocumentLinks = await this.orderService
				.downloadOrderOdv({ id: data.order._id })
				.then(res => res.data)
				.catch(err => {
					console.error(err);
					return [];
				});

			data.order.product_list.forEach(product => {
				const currentArticle: any = product;
				// const currentArticle = articleList.find(article => {
				// 	return article['code_erp'] === product.code;
				// });
				if (currentArticle) {
					// article has no qty_box
					if (!(currentArticle.hasOwnProperty('qty_box') && currentArticle.qty_box !== null)) {
						currentArticle.qty_box = 1;
					}
					product.articleDetail = currentArticle;
					// if (this.articleDescription) {
					// 	const currentArticleDescription = this.articleDescription.article_list.find(article => {
					// 		return article['code_erp'] === product.code;
					// 	});
					// 	product.articleDescription = currentArticleDescription;
					// }
					delete product.articleDetail.productDetail;
				} else {
					product.articleDetail = {} as any;
				}

				// Article links
				const articleLinks = orderDocumentLinks.find(article => article['code_erp'] === product.code);
				product.articleDocumentLink = articleLinks?.values || [];
			});
			return data.order;
		} catch (err) {
			console.error('mergeArticleWithProduct', err);
			throw new Error(err.error + err.reason);
		}
	}

	async getOrderProgress(
		data: OrderListFilterModel
	): Promise<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>> {
		try {
			const orderProgress: OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel> = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)
			).orderPouch.getOrderProgress({
				id: data._id
			});
			return orderProgress;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async sendOrderEmails(
		action: BaseState<OrderWithArticleDetailRequest, OrderFilterModel>
	): Promise<BaseStateModel<OrderWithArticleDetailRequest, OrderFilterModel>> {
		const order = action.data.orderData.order;

		// Send notification of order delay
		return this.orderService
			.sendOrderDelayEmail({ id: order._id })
			.then(async res => {
				console.log(res);
				return action;
			})
			.catch((err: RestBaseMessageError) => {
				console.log(err);
				throw new Error(err.body.detail);
			});
	}

	async saveOrderDetail(
		action: BaseState<OrderWithArticleDetailRequest, OrderFilterModel>
	): Promise<BaseStateModel<OrderStateModel, OrderFilterModel>> {
		action.data.orderData.order.product_list.forEach(product => {
			product = this.removePropertiesFromProduct(product);
		});
		return new Promise(async (resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				resolve(
					(
						await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)
					).orderPouch.putOrder(action.data.orderData.order, !action.data.orderData.order._id ? true : false)
				);
			} else {
				this.orderService.putNewOrder(action.data.orderData.order).then(order => resolve(order.data));
			}
		})
			.then(async (order: OrderStateModel) => {
				// TODO: verificare se questa modifica sia consistente
				// if (action.data.orderData.organization) {
				action.data.orderData.order = order;
				order = await this.mergeArticleWithProduct(action.data.orderData);
				// }

				const filter: OrderFilterModel = { organization: action.data.orderData.organization };
				const dataSetting: DataSetting<OrderFilterModel> = { appliedFilter: filter };
				const result = new BaseState(order, dataSetting);

				return result;
			})
			.catch((err: PouchErrorResponse) => {
				throw { error: err.error, reason: err.reason, status: err.status };
			});
	}

	removePropertiesFromProduct(product) {
		if (product.articleDetail) {
			delete product.articleDetail;
		}
		if (product.articleDescription) {
			delete product.articleDescription;
		}
		if (product.articlePrice) {
			delete product.articlePrice;
		}
		if (product.qty_box) {
			delete product.qty_box;
		}
		if (product.articleDocumentLink) {
			delete product.articleDocumentLink;
		}
		if (product['articleKitList']) {
			delete product['articleKitList'];
		}
		if (product['calculate_price']) {
			delete product['calculate_price'];
		}
		if (product.discount) {
			delete product.discount;
		}
		if (product.input_quantity) {
			delete product.input_quantity;
		}
		return product;
	}

	async deleteOrder(data: OrderStateModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)).orderPouch
			.deleteOrder(data)
			.then(async order => {
				return new BaseState(order);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async mergeCategoryInformation(
		action: BaseState<OrderStateModel, OrderFilterModel>
	): Promise<BaseState<OrderStateModel, OrderFilterModel>> {
		const promises = [];
		for (let i = 0; i < action.data.product_list.length; i++) {
			const categoryParameter = <BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>>{
				data: null,
				dataSetting: {
					appliedFilter: {
						article: {
							only_without_children: true,
							level: 'leaf',
							code: action.data.product_list[i].code
						}
					}
				}
			};
			promises.push(
				this.utilCategoryListService
					.loadCategoryList(categoryParameter)
					.then((res: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) => {
						action.data.product_list[i].articleDetail.categoryNodeList = res.data;
					})
					.catch(err => {
						console.log(err);
					})
			);
		}
		return Promise.all(promises).then(res => {
			return action;
		});
	}

	async mergeArticleKitDetails(
		action: BaseStateModel<OrderStateModel, OrderFilterModel>
	): Promise<BaseStateModel<OrderStateModel, OrderFilterModel>> {
		// ciclo tutti i prodotti
		for (let i = 0; i < action.data.product_list.length; i++) {
			if (action.data.product_list[i].articleDetail) {
				action.data.product_list[i].articleDetail.articleKitList = [];

				// se hanno i kit
				if (action.data.product_list[i].articleDetail.art_kit === 'S') {
					// recupero il dettaglio
					await this.utilArticleKitService
						.getArticleKitDetail(
							action.data.product_list[i].articleDetail,
							action.dataSetting.appliedFilter.organization
						)
						.then((res: ArticleState[]) => {
							action.data.product_list[i].articleDetail.articleKitList = res;
						})
						.catch((err: PouchErrorResponse) => {
							console.log(err, 'article kit not found');
						});
				}
			}
		}

		return action;
	}

	async addOrderProgress(
		order: OrderStateModel,
		allOrderProgress?: OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>[]
	) {
		const orderFilter: OrderListFilterModel = {
			_id: order._id
		};
		const orderProgress = allOrderProgress
			? allOrderProgress.find(x => x.doc_id === order._id)
			: await this.getOrderProgress(orderFilter);
		if (orderProgress) {
			order.order_progress = orderProgress;
		}

		// order progress - values changed
		if (orderProgress && orderProgress.values) {
			const lastIndex = orderProgress.values.length - 1;
			const valuesChanged = orderProgress.values[lastIndex].values_changed;
			if (valuesChanged) {
				if (!order.header.order_progress_detail) {
					order.header.order_progress_detail = {};
				}
				// first_evasion_date
				if (valuesChanged[`root['header']['first_evasion_date']`]) {
					const firstEvasionDate: any = valuesChanged[`root['header']['first_evasion_date']`].old_value;
					order.header.order_progress_detail.first_evasion_date = firstEvasionDate;
				}

				// goods destination
				if (valuesChanged[`root['header']['goods_destination_code']`]) {
					const goodsDestinationCodeOldValue: any =
						valuesChanged[`root['header']['goods_destination_code']`].old_value;

					const destinationFilter: DestinationFilterModel = {
						id: goodsDestinationCodeOldValue,
						code_organization: order.header.organization.code_item
					};

					// TODO check it
					const organizationList$ = this.store.select(StateFeature.getOrganizationListState);
					const organizationListSub = organizationList$
						.pipe(
							skipWhile(res => !res),
							map(res => {
								const organization = res.data.find(x => x.code === order.header.organization.code_item);
								const oldDestination = organization.destination_list.find(
									x => x.code === goodsDestinationCodeOldValue
								);
								order.header.order_progress_detail.goods_destination = oldDestination;
							})
						)
						.subscribe();

					// this.pouchDbLecaAgentiAdapter.destinationPouch
					// 	.getDestination(destinationFilter)
					// 	.then((destination: DestinationPouchModel) => {
					// 		order.header.order_progress_detail.goods_destination = destination;
					// 		return order;
					// 	});
				}

				// starting_warehouse
				if (valuesChanged[`root['header']['starting_warehouse']`]) {
					const startingWarehouseOld: any = valuesChanged[`root['header']['starting_warehouse']`].old_value;
					order.header.order_progress_detail.starting_warehouse = startingWarehouseOld;
				}
			}
		}
	}
}
