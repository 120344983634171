import { Injectable } from '@angular/core';
import { of, from } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { SearchResultsActionEnum, SearchResultsStateAction } from './search-results.actions';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { Store } from '@ngrx/store';
import { SearchModel } from '../../model/structure/elastic-search.model';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { CatalogService } from '../../service/rest/catalog.service';

@Injectable()
export class SearchResultsEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SearchResultsActionEnum.LOAD),
			mergeMap((action: SearchModel) => from(this.getSearchResults(action.term))),
			map((results: BaseStateModel<ArticlePouchModel[]>) => SearchResultsStateAction.update(results)),
			catchError((error, caught) => {
				this.store.dispatch(SearchResultsStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private catalogService: CatalogService) {}

	async getSearchResults(term: string): Promise<BaseStateModel<ArticlePouchModel[]>> {
		try {
			const res = await this.catalogService.search(term);
			return new BaseState(res.data);
		} catch (err) {
			throw new Error(err);
		}
	}
}
