import { DestinationPouchModel, ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { DestinationFilterModel, DestinationListFilterModel } from '../../filter/destination-filter.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';

export class DestinationPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;
	getDestination(data: DestinationFilterModel): Promise<DestinationPouchModel> {
		return new Promise((resolve, reject) => {
			this.get(`customer_destination_${data.code_organization}_${data.id}`)
				.then((doc: DestinationPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getDestinationList(
		filter: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>
	): Promise<DestinationPouchModel[]> {
		const exists = {
			$exists: true
		};
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'customer_destination'
					}
				]
			};
			if (filter.dataSetting && filter.dataSetting.appliedFilter) {
				this.setField(
					'code_organization',
					filter.dataSetting.appliedFilter.code_organization
						? filter.dataSetting.appliedFilter.code_organization
						: exists
				);
				this.setField('valid', true);
				this.setField(
					'code',
					filter.dataSetting.appliedFilter.code ? filter.dataSetting.appliedFilter.code : exists
				);
			}
			this.getWithIndexFilter(
				'order-list',
				this.selector,
				filter.dataSetting.pagination,
				filter.dataSetting.sort ? filter.dataSetting.sort : []
			)
				.then((res: ListDataPouchModel<DestinationPouchModel>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	setField(key: string, condition: any) {
		this.selector.$and.push({ [key]: condition });
	}
}
