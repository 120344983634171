<form [formGroup]="addressFormDialog" (ngSubmit)="save()">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<h2>{{ label }}</h2>
			</div>
		</div>

		<mat-dialog-content class="mat-typography" mat-card-subtitle>
			<div class="row">
				<mat-form-field class="col-12">
					<mat-label>Indirizzo e numero civico</mat-label>
					<input matInput placeholder="Indirizzo e numero civico" formControlName="address" />
					<mat-error *ngIf="addressFormDialog.get('address').hasError('required')" class="help-block">
						Campo obbligatorio
					</mat-error>
				</mat-form-field>
				<div class="col-12 mb-2" if="addressData">
					<mat-slide-toggle
						[checked]="checkedToggle"
						formControlName="addressType"
						(change)="toggle($event)"
					>
						<mat-label>Sede legale</mat-label>
					</mat-slide-toggle>
				</div>
				<mat-form-field class="col-12">
					<mat-label>Città</mat-label>
					<input matInput placeholder="Città" formControlName="locality" />
					<mat-error *ngIf="addressFormDialog.get('locality').hasError('required')" class="help-block">
						Campo obbligatorio
					</mat-error>
				</mat-form-field>
				<mat-form-field class="col-12">
					<mat-label>CAP</mat-label>
					<input matInput placeholder="CAP" formControlName="zipCode" />
					<mat-error *ngIf="addressFormDialog.get('zipCode').hasError('required')" class="help-block">
						Campo obbligatorio
					</mat-error>
				</mat-form-field>
				<mat-form-field class="col-12">
					<mat-label>Provincia</mat-label>
					<input matInput placeholder="Provincia" formControlName="province" />
					<mat-error *ngIf="addressFormDialog.get('province').hasError('required')" class="help-block">
						Campo obbligatorio
					</mat-error>
				</mat-form-field>
				<mat-form-field class="col-12">
					<mat-label>Nazione</mat-label>
					<input matInput placeholder="Nazione" formControlName="country" />
					<mat-error *ngIf="addressFormDialog.get('country').hasError('required')" class="help-block">
						Campo obbligatorio
					</mat-error>
				</mat-form-field>
			</div>
		</mat-dialog-content>

		<mat-dialog-actions class="content-button">
			<!-- Da controllare -->
			<mat-toolbar class="height-auto">
				<button class="content-button__button" mat-stroked-button [mat-dialog-close]="false">
					Annulla
				</button>
				<button class="content-button__button" color="primary" type="submit" mat-raised-button>
					Salva
				</button>
			</mat-toolbar>
		</mat-dialog-actions>
	</div>
</form>
