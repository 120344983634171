<ng-container *ngIf="formControl.hasError('required')">
    {{ 'general.validators.required' | translate | sentencecase }}.
</ng-container>

<ng-container *ngIf="formControl.hasError('minlength') && minLengthValue">
    {{ 'general.validators.min_length' | translate: { amount: minLengthValue } | sentencecase }}.
</ng-container>

<ng-container *ngIf="formControl.hasError('pattern')">
    {{ 'general.validators.pattern' | translate | sentencecase }}.
</ng-container>

<ng-container *ngIf="formControl.hasError('email')">
    {{ 'general.validators.email' | translate | sentencecase }}.
</ng-container>

<ng-container *ngIf="formControl.hasError('min')">
    {{ 'general.validators.min' | translate | sentencecase }}.
</ng-container>

<ng-container *ngIf="formControl.hasError('max')">
    {{ 'general.validators.max' | translate | sentencecase }}.
</ng-container>