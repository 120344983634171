import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { B2cUserListActionEnum, B2cUserListStateAction } from './b2c-user-list.actions';

// widget & utility
import { from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { UtilPouchService } from '../../../../service/util/util-pouch.service';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';

@Injectable()
export class B2cUserListEffects {
	loadItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(B2cUserListActionEnum.LOAD_ITEM),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInListState<BodyTablePouchCustomModel>(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) => B2cUserListStateAction.updateItem(action)),
			catchError((error, caught) => {
				this.store.dispatch(B2cUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(B2cUserListActionEnum.LOAD_LIST),
			mergeMap(() => from(this.utilPouchService.getView<BodyTablePouchCustomModel>('b2c_user/get-all-b2c_user'))),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) => B2cUserListStateAction.updateList(action)),
			catchError((error, caught) => {
				this.store.dispatch(B2cUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	createItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(B2cUserListActionEnum.CREATE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.storeUtilService.saveCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(action))
			),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.utilPouchService.saveCodeItem(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				B2cUserListStateAction.createItemSuccess(new BaseState([action.data]))
			),
			catchError((error, caught) => {
				this.store.dispatch(B2cUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	deleteItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(B2cUserListActionEnum.DELETE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel, any>) =>
				from(this.utilPouchService.deleteAnyDocument(action.data))
			),
			map((action: BodyTablePouchCustomModel) =>
				B2cUserListStateAction.deleteItemSuccess(new BaseState(null, null))
			),
			catchError((error, caught) => {
				this.store.dispatch(B2cUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: UtilPouchService,
		private storeUtilService: StoreUtilService
	) {}
}
