import { createAction, props } from '@ngrx/store';
import { ArticleState } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';

export enum ArticleDetailActionEnum {
	LOAD_SINGLE_WITH_CODE = '[article detail] Load one with code',
	UPDATE = '[article detail] Update',
	RESET = '[article detail] Reset',
	ERROR = '[article detail] Error'
}

export namespace ArticleDetailStateAction {
	export const loadSingleWithCode = createAction(
		ArticleDetailActionEnum.LOAD_SINGLE_WITH_CODE,
		props<{ code: string }>()
	);
	export const update = createAction(ArticleDetailActionEnum.UPDATE, props<BaseStateModel<ArticleState>>());
	export const reset = createAction(ArticleDetailActionEnum.RESET);
	export const error = createAction(ArticleDetailActionEnum.ERROR, props<BaseStateModel<any>>());
}
