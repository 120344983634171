import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
      DragDropModule,
      SharedModule
    ],
    exports: [
        DragDropModule
    ]
  })
  export class KabanModule { }