<breadcrumb
	[title]="'receipt.title' | translate | sentencecase"
	[pathList]="['Home', 'general.archive', 'receipt.detail']"
>
</breadcrumb>
<div list-wrapper [filterOpened]="filterOpened" #listWrapper>
	<ng-container main>
		<mat-card>
			<receipt-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
                [localListHandlerSidebarFilterShow]="true"
                [localListHandlerTitle]="'receipt.archive' | translate: { amount: 6 } | sentencecase"
                (localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
			>
			</receipt-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container side>
		<form [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
			<!-- filtro sulla data del documento -->
			<!-- TODO: implement range date picker -->
			<mat-form-field>
				<mat-label>{{ 'receipt.field.date' | translate | sentencecase }}</mat-label>
				<input matInput [matDatepicker]="picker" formControlName="receipt_date" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>

			<!-- filtro sul tipo di documento -->
			<mat-form-field>
				<mat-label>{{ 'receipt.field.document_type' | translate | sentencecase }}</mat-label>
				<mat-select formControlName="receipt_document_type">
					<mat-option *ngFor="let item of documentTypes" [value]="item">
						{{ 'receipt.document_type.' + item | translate | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<!-- TODO: autocomplete -->
			<!-- filtro sul nome dell'organizzazione
            <mat-form-field>
                <mat-label>{{ 'receipt.field.organization' | translate | sentencecase }}</mat-label>
                <input matInput type="text" formControlName="receipt_organization_name">
            </mat-form-field> -->

			<mat-toolbar>
				<button class="mr-8" mat-raised-button color="primary" type="submit">
					{{ 'general.apply_filters' | translate | sentencecase }}
				</button>

				<button mat-button color="default" type="button" (click)="resetFilters()">
					{{ 'general.reset' | translate | sentencecase }}
				</button>
			</mat-toolbar>
		</form>
	</ng-container>
</div>
