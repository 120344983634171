import { Component, Input, OnChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { InternationalTrustScoreEnum } from '../..//enum/international-trust-score.enum';
import { OrganizationStateModel } from '../../model/state/organization-state.model';
import moment from 'moment';
import { OrganizationLevelEnum, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'organization-detail-data-form',
	templateUrl: './organization-detail-data-form.component.html',
	styleUrls: ['./organization-detail-data-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class OrganizationDetailDataFormComponent implements OnChanges {
	@Input() parentForm: FormGroup;
	@Input() organization: OrganizationStateModel;
	@Input() organizationTypeSelected: OrganizationTypeEnum;

	// enum
	internationalTrustScoreList = Object.values(InternationalTrustScoreEnum);
	organizationLevelList = Object.values(OrganizationLevelEnum);

	organizationType: OrganizationTypeEnum;
	isCompany = false;
	isPrivate = false;

	constructor(private fb: FormBuilder) {}

	setOrganizationType() {
		if (this.organization) {
			if (this.organization.organization_type) {
				return this.organization.organization_type;
			} else {
				return OrganizationTypeEnum.COMPANY; // manca organization_type
			}
		} else {
			return this.organizationTypeSelected; // registrazione account
		}
	}

	ngOnChanges() {
		this.organizationType = this.setOrganizationType();
		this.isCompany = this.organizationType === OrganizationTypeEnum.COMPANY;
		this.isPrivate = this.organizationType === OrganizationTypeEnum.PRIVATE;

		switch (this.organizationType) {
			case OrganizationTypeEnum.PRIVATE:
				this.parentForm.addControl(
					'details',
					this.fb.group({
						// Commons
						status: [''],
						dateFirstContact: [''],
						websiteUrl: [''],
						linkedInUrl: ['']
					})
				);
				break;

			case OrganizationTypeEnum.COMPANY:
				this.parentForm.addControl(
					'details',
					this.fb.group({
						// Commons
						status: [''],
						dateFirstContact: [''],
						websiteUrl: [''],
						linkedInUrl: [''],
						// Company
						organizationSector: [''],
						revenueYear: ['', { validators: [Validators.pattern('^[0-9]*$')] }],
						employeesNumber: ['', { validators: [Validators.pattern('^[0-9]*$')] }],
						organizationInternationalRating: [''],
						organizationRating: ['', { validators: [Validators.max(100), Validators.min(0)] }]
					})
				);
				break;
		}
		if (this.organization) {
			this.updateFormFieldValue();
		}
	}

	updateFormFieldValue() {
		// Common
		this.parentForm['controls']['details'].patchValue({
			status: this.organization.level,
			dateFirstContact: this.organization.date_first_contact
				? moment(this.organization.date_first_contact)
				: null,
			websiteUrl: this.organization.crm.url_website ? this.organization.crm.url_website : null,
			linkedInUrl: this.organization.crm.url_linkedin ? this.organization.crm.url_linkedin : null
		});

		switch (this.organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.parentForm['controls']['details'].patchValue({
					organizationSector: this.organization.crm.organization_sector
						? this.organization.crm.organization_sector
						: null,
					revenueYear: this.organization.crm.revenue_year ? this.organization.crm.revenue_year : null,
					employeesNumber: this.organization.crm.employees_number
						? this.organization.crm.employees_number
						: null,
					organizationInternationalRating: this.organization.crm.organization_international_rating
						? this.organization.crm.organization_international_rating
						: null,
					organizationRating: this.organization.crm.organization_rating
						? this.organization.crm.organization_rating
						: null
				});
		}
	}
}
