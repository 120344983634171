<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<mat-toolbar>
					<mat-toolbar-row>
						{{ 'comment.name_plural' | translate | sentencecase }}
						<span class="flex-span"></span>
					</mat-toolbar-row>
				</mat-toolbar>
	
				<div class="insert-comment">
					<mat-form-field class="insert-comment__input">
						<mat-label>{{ 'comment.add' | translate | sentencecase }}...</mat-label>
						<input matInput type="text" [(ngModel)]="addCommentValue" autocomplete="off" />
					</mat-form-field>
					<button
						class="insert-comment__button"
						mat-icon-button
						color="primary"
						[disabled]="!addCommentValue"
						(click)="addComment()"
						type="button"
					>
						<mat-icon>send</mat-icon>
					</button>
				</div>
				
				<comment-thread
					*ngIf="organization && organization.comment_list"
					[commentList]="organization.comment_list"
					[canEdit]="true"
					(onEdit)="editComment($event)"
					(onDelete)="deleteComment($event)"
				></comment-thread>
	
			</mat-card>
		</div>
	</div>
	
</div>