import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PsToTopService } from './ps-to-top.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';

/**
 * Questo componente funziona all'interno di una perfect-scrollbar.
 * Per farlo funzionare bisogna sfruttare il servizio PsToTopService con la funzione updateShowToTop()
 * in quanto la visibilità dello stesso è determinata dal valore emesso da questo BehaviorSubject.
 * 
 * Un esempio è presente in public-wrapper.components.ts
 */

 @Component({
	selector: 'ps-to-top',
	templateUrl: './ps-to-top.component.html',
	styleUrls: ['./ps-to-top.component.scss'],
	providers: [SubscribeManagerService]
})
export class PsToTopComponent implements OnInit, OnDestroy {
	@Output() onScrollToTop = new EventEmitter<any>();

	sub: Subscription;
	isVisible = false;

	constructor(
		public scrollToTopService: PsToTopService,
		private cdr: ChangeDetectorRef,
		private subscribeManagerService: SubscribeManagerService
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.scrollToTopService.showToTopSubject.subscribe(res => {
				if (res !== this.isVisible) {
					this.isVisible = res;
					// Aggiorna il template
					this.cdr.detectChanges();
				}
			}),
			'show-to-top'
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
