import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { CommentStateModel } from './comment-state.model';
import { OrganizationStateModel } from './organization-state.model';

export interface OpportunityPouchModel extends BasePouchModel {
	// organization
	organization?: OrganizationStateModel;

	// organization contact that is the referent
	contact_id: string;
	contact_name: string;

	// assegnee - owner of the opportunity
	assegnee_user_id: string;
	assegnee_user_name: string;
	assegnee_avatar?: string;
	assegnee_full_name?: string;

	// comments
	comment_list?: CommentStateModel[];

	// logs
	log_list?: OpportunityLogModel[];

	/**
	 * Opportunity information
	 */
	// Actual revenue amount for the opportunity
	actual_value?: number;
	// Type a value between 0 and 1,000,000,000,000 to indicate the lead's potential available budget.
	budget_amount?: number;
	// Type a number from 0 to 100 that represents the likelihood of closing the opportunity. This can aid the sales team in their efforts to convert the opportunity in a sale.
	close_probability?: number;
	// Date and time when the record was created
	created_on: number;
	// expected closing date of the opportunity to help make accurate revenue forecasts
	estimated_close_date?: number;
	// real closing date of the opportunity
	close_date?: number;
	// determinate if there is an offer attached to the opportunity
	has_offer?: boolean;
	// Date and time when the record was modified
	modified_on?: number;
	// Origin (id) of the opportunity
	origin_id?: string;
	// Origin (name) of the opportunity
	origin_name?: string;
	// Rating calcolato da altri parametri che indica il grado di interesse dell'opportunità
	project_interest?: number;
	// date and time of the prospecting follow-up meeting with the lead
	schedule_followup_prospect?: number;
	// Shows whether the opportunity is open, won, or lost. Won and lost opportunities are read-only and can't be edited until they are reactivated.
	status_code?: OpportunityStatusCodeEnum;
	// Shows the ID of the workflow step.
	step_id: string;
	// Shows the current phase in the sales pipeline for the opportunity. This is updated by a workflow.
	step_name?: string;
	// title identifier of the opportunity
	title: string;
	// Topic of the opportunity inserted by the operator
	topic?: string;

	order_into_step?: number;
}

export enum OpportunityStatusCodeEnum {
	Open = 'OPEN',
	Won = 'WON',
	Lost = 'LOST',
	Rejected = 'REJECTED'
}

export enum OpportunityStatusEnum {
	DRAFT = 'DRAFT'
}

export interface OpportunityLogModel {
	created_on: number;
	description: string;
}

export enum OpportunityTypeEnum {
	LEAD = 'lead',
	PROSPECT = 'prospect',
	CLIENT = 'client'
}
