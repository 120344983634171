import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, RestBasePk, SentencecasePipe } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { PouchDbSpin8AgentAdapter } from '../../service/pouch-db/spin8/pouchdb-spin8-agent.adapter';
import { OfferActionEnum, OfferStateAction } from './offer.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OfferStateModel } from '../../model/state/offer-state.model';
import { LAST_CHARACTER } from '../../constants/pouchdb.constants';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';

@Injectable()
export class OfferEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActionEnum.LOAD),
			mergeMap((state: BaseStateModel<RestBasePk>) => from(this.getOfferDetail(state.data))),
			map((state: BaseStateModel<OfferStateModel[]>) => OfferStateAction.update(state)),
			catchError((error, caught) => {
				this.store.dispatch(OfferStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActionEnum.LOAD_ALL),
			mergeMap((state: BaseStateModel<OfferStateModel[]>) => from(this.getAllOffer(state))),
			map((state: BaseStateModel<OfferStateModel[]>) => OfferStateAction.update(state)),
			catchError((error, caught) => {
				this.store.dispatch(OfferStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActionEnum.SAVE),
			mergeMap((state: BaseStateModel<OfferStateModel[]>) => from(this.putOffer(state.data))),
			map((state: BaseStateModel<OfferStateModel[]>) => OfferStateAction.update(state)),
			catchError((error, caught) => {
				this.store.dispatch(OfferStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OfferActionEnum.REMOVE),
			mergeMap((state: BaseStateModel<OfferStateModel>) => from(this.deleteOffer(state.data))),
			map((state: BaseStateModel<PouchDeleteResponse>) => OfferStateAction.removed()),
			catchError((error, caught) => {
				this.store.dispatch(OfferStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbSpin8AgentAdapter: PouchDbSpin8AgentAdapter,
		private utilPouchService: UtilPouchService,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async putOffer(data: OfferStateModel[]): Promise<BaseStateModel<OfferStateModel[]>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)).offerPouch
			.putOffer(data[0], data[0]._id ? false : true)
			.then(async (offer: OfferStateModel) => {
				this.showDialog('offer.save');
				return new BaseState([offer]);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getOfferDetail(data: RestBasePk): Promise<BaseStateModel<OfferStateModel[]>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)).offerPouch
			.getOfferDetail(data)
			.then(async (offer: OfferStateModel) => {
				return new BaseState([offer]);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async deleteOffer(data: OfferStateModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)).offerPouch
			.deleteOffer(data)
			.then(async offer => {
				return new BaseState(offer);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getAllOffer(action: BaseStateModel<OfferStateModel[]>): Promise<BaseStateModel<OfferStateModel[]>> {
		const documentName = 'offer';
		const allDocsParam: any = {
			include_docs: true,
			startkey: documentName + '_',
			endkey: documentName + '_' + LAST_CHARACTER
		};

		await this.utilPouchService
			.allDocs<OfferStateModel>(allDocsParam, CouchDocumentType.ORDER)
			.then(res => {
				action.data = res.data;
			})
			.catch(err => console.log(err));

		return action;
	}

	showDialog(msg: string, action: string = null, duration: number = 3000) {
		msg = this.sentencecasePipe.transform(this.translate.instant(msg));
		this.snackBar.open(msg, action, { duration: duration });
	}
}
