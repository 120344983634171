import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UtilPasswordVerifyService } from '../../service/util/util-password-verify.service';
import { passwordRules } from '../../constants/password-rules.constants';
import { PasswordStrength } from '../../enum/password-strength.enum';
import { Observable, Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';

@Component({
	selector: 'password-meter',
	templateUrl: './password-meter.component.html',
	styleUrls: ['./password-meter.component.scss']
})
export class PasswordMeterComponent implements OnInit, OnDestroy {
	isPasswordValid: boolean = false;
	@Output() isPasswordValidEmitter = new EventEmitter<boolean>();
	@Input() newPassword: Observable<string>;
	newPasswordSubscription: Subscription;
	PasswordStrength = PasswordStrength;
	passwordMeter: number = 0;
	passwordMeterDialog: string = 'login.general.password_meter.empty';
	passwordRules = passwordRules;
	satisfiedRules: boolean[] = new Array(passwordRules.length).map(elem => false);

	constructor(private utilPasswordVerifyService: UtilPasswordVerifyService) {}

	ngOnInit() {
		this.passwordRules = this.utilPasswordVerifyService.sortRulesIsRequired(this.passwordRules);
		this.newPasswordSubscription = this.newPassword.subscribe(newPassword => {
			this.assessPassword(newPassword);
		});
	}

	assessPassword(password: string): void {
		this.satisfiedRules = this.utilPasswordVerifyService.checkSatisfied(password, this.passwordRules);
		this.passwordMeter = this.utilPasswordVerifyService.mapScore(password, this.satisfiedRules);
		switch (this.passwordMeter) {
			case PasswordStrength.EMPTY:
				this.passwordMeterDialog = 'login.general.password_meter.empty';
				this.isPasswordValid = false;
				break;
			case PasswordStrength.WEAK:
				this.passwordMeterDialog = 'login.general.password_meter.weak';
				this.isPasswordValid = false;
				break;
			case PasswordStrength.GOOD:
				this.passwordMeterDialog = 'login.general.password_meter.good';
				this.isPasswordValid = true;
				break;
			case PasswordStrength.EXCELLENT:
				this.passwordMeterDialog = 'login.general.password_meter.excellent';
				this.isPasswordValid = true;
				break;
		}
		this.isPasswordValidEmitter.emit(this.isPasswordValid);
	}

	ngOnDestroy() {
		this.newPasswordSubscription.unsubscribe();
	}
}
