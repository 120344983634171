<div class="container-fluid">
	<div class="row">
		<div class="col-sm-6">
			<mat-button-toggle-group #group="matButtonToggleGroup" [value]="accountBalanceSectionEnum.DASHBOARD">
				<mat-button-toggle [value]="accountBalanceSectionEnum.DASHBOARD">
					{{ 'customer_credit.view_type.dashboard' | translate | sentencecase }}
				</mat-button-toggle>
				<mat-button-toggle [value]="accountBalanceSectionEnum.LIST">
					{{ 'customer_credit.view_type.list' | translate | sentencecase }}
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>
		<div class="col-sm-6 txt-right">
			<p *ngIf="customerCreditBatchLastDate">
				{{ 'general.last_update' | translate | sentencecase }}: {{ customerCreditBatchLastDate }}
			</p>
		</div>
	</div>

	<div class="switch-context__wrapper">
		<div class="switch-context__item" *ngIf="group.value === accountBalanceSectionEnum.DASHBOARD" [@inOutAnimation]>
			<!-- <div class="row"> -->
			<!-- Disponibilità -->
			<!-- <div class="col-lg-3 col-md-6">
					<mat-card class="stats">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.credit_available' | translate | sentencecase }}
								</h2>
							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">{{ creditAvailabile !== null ? (creditAvailabile | currency: 'EUR':'symbol':'':'it') : '-' }}</p>
					</mat-card>
				</div> -->

			<!-- Fuori Fido -->
			<!-- <div class="col-lg-3 col-md-6">
					<mat-card class="stats">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.out_of_credit' | translate | sentencecase }}
								</h2>
							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">{{ outOfCredit !== null ? (outOfCredit | currency: 'EUR':'symbol':'':'it') : '-' }}</p>
					</mat-card>
				</div> -->

			<!-- Fido -->
			<!-- <div class="col-lg-6 col-md-12">
					<mat-card class="stats stats--primary">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.credit_granted' | translate | sentencecase }}
								</h2>
								<span class="flex-span"></span>
								<span class="date" *ngIf="balanceHeader?.date_credit_granted">
									{{ 'general.last_update' | translate | sentencecase }}: {{ (balanceHeader.date_credit_granted | date: 'dd/MM/yyyy') }}
								</span>

							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">
							{{ balanceHeader?.sign_credit_granted }}
							{{
								balanceHeader?.amount_credit_granted
									? (balanceHeader?.amount_credit_granted | currency: 'EUR':'symbol':'':'it')
									: '-'
							}}
						</p>
					</mat-card>
				</div> -->
			<!-- </div> -->

			<div class="row">
				<!-- Chart DUE -->
				<div class="col-md-12 col-lg-6">
					<card-trend [config]="cardTrendConfigurationDue">
						<chart-wrapper
							[config]="chartConfigurationDue"
							*ngIf="chartConfigurationDue.datasets[0]"
						></chart-wrapper>
					</card-trend>
				</div>
				<!-- Chart OVERDUE -->
				<div class="col-md-12 col-lg-6">
					<card-trend [config]="cardTrendConfigurationOverdue">
						<chart-wrapper
							[config]="chartConfigurationOverdue"
							*ngIf="chartConfigurationOverdue.datasets[0]"
						></chart-wrapper>
					</card-trend>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 col-lg-3">
					<mat-card class="stats">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.credit' | translate | sentencecase }}
								</h2>
							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">
							{{ credits !== null ? (credits | currency: 'EUR':'symbol':'':'it') : '-' }}
						</p>
					</mat-card>
				</div>
				<div class="col-md-6 col-lg-3">
					<mat-card class="stats">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.debit' | translate | sentencecase }}
								</h2>
							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">
							{{ debits !== null ? (debits | currency: 'EUR':'symbol':'':'it') : '-' }}
						</p>
					</mat-card>
				</div>
				<div class="col-md-6 col-lg-3">
					<mat-card class="stats">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.credit_expired' | translate | sentencecase }}
								</h2>
							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">
							{{ creditsExpired !== null ? (creditsExpired | currency: 'EUR':'symbol':'':'it') : '-' }}
						</p>
					</mat-card>
				</div>
				<div class="col-md-6 col-lg-3">
					<mat-card class="stats">
						<mat-toolbar>
							<mat-toolbar-row>
								<h2 class="stats__title">
									{{ 'customer_credit.field.debit_expired' | translate | sentencecase }}
								</h2>
							</mat-toolbar-row>
						</mat-toolbar>
						<p class="stats__number">
							{{ debitsExpired !== null ? (debitsExpired | currency: 'EUR':'symbol':'':'it') : '-' }}
						</p>
					</mat-card>
				</div>
			</div>
		</div>

		<div class="switch-context__item" *ngIf="group.value === accountBalanceSectionEnum.LIST" [@inOutAnimation]>
			<div list-wrapper [filterOpened]="false" #listWrapper [minHeight]="minHeight">
				<ng-container main>
					<mat-card>
						<organization-detail-credit-list-wrapper
							[localListHandlerWidgetWrapperData]="listPageBaseData"
							[localListHandlerWidgetWrapperColumnList]="accountBalanceColumnService.columns"
							[localListHandlerTextFilterShow]="true"
							[localListHandlerPaginatorShow]="true"
						></organization-detail-credit-list-wrapper>
					</mat-card>
				</ng-container>

				<ng-container side>
					<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
						<!-- TODO: ripristinare una volta importato MD date-range-picker -->
						<!-- <mat-form-field>
							<input
								matInput
								placeholder="Document date"
								[satDatepicker]="pickerDateDocument"
								formControlName="date_document"
							/>
							<sat-datepicker #pickerDateDocument [rangeMode]="true"></sat-datepicker>
							<sat-datepicker-toggle matSuffix [for]="pickerDateDocument"></sat-datepicker-toggle>
						</mat-form-field> -->

						<!-- <mat-form-field>
							<input
								matInput
								placeholder="Expiration date"
								[satDatepicker]="pickerDateExpiration"
								formControlName="date_expiration"
							/>
							<sat-datepicker #pickerDateExpiration [rangeMode]="true"></sat-datepicker>
							<sat-datepicker-toggle matSuffix [for]="pickerDateExpiration"></sat-datepicker-toggle>
						</mat-form-field> -->
						<!-- Da controllare -->
						<!-- <mat-toolbar>
							<button mat-flat-button color="primary" class="btn-sidebar" type="submit">
								Apply filters
							</button>
							<button
								mat-stroked-button
								color="primary"
								class="btn-sidebar"
								type="button"
								(click)="resetFilters()"
							>
								Reset
							</button>
						</mat-toolbar> -->
					</form>
				</ng-container>
			</div>
		</div>
	</div>
</div>
