import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

// model
import {
	ArticleChangeContextModel,
	ArticleChangeContextResponseModel,
	OrderRowModel
} from '../../../../../model/order-util.model';
import { ArticleState } from '../../../../../model/state/article-list-state.model';
import { AuxiliaryTableStateModel } from '../../../../../model/state/auxiliary-table-list';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	ListWrapperComponent,
	Pagination,
	SubscribeManagerService,
	UserDetailModel,
	UtilService
} from '@saep-ict/angular-core';
import { OrderStateModel, OrderWithArticleDetailRequest } from '../../../../../model/state/order-state.model';
import { ArticleListFilterModel } from '../../../../../service/pouch-db/filter/article-list-filter.model';
import { Category } from '../../../../../model/category-list.model';
import { ArticleCategory } from '../../../../../model/article.model';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../../../state';
import { ArticleListActionEnum, ArticleListStateAction } from '../../../../../state/article-list/article-list.actions';
import { OrganizationActionEnum } from '../../../../../state/organization/organization.actions';
import { OrderActionEnum, OrderStateAction } from '../../../../../state/order/order.actions';
import { CategoryListActionEnum } from '../../../../../state/category-list/category-list.actions';

// widget & utility
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';
import { ProductCatalogColumnService } from '../../../../../service/td-data-table/implementation/product-catalog.service';
import { UtilOrderService } from '../../../../../service/util/util-order.service';
import { MockCategoryListService } from '../../../../../service/mock/mock-category-list.service';
import { IS_MULTIDIVISION, DEFAULT_DIVISION_CODE } from '../../../../../constants/division.constants';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';
import { UtilPriceService } from '../../../../../service/util/util-price.service';
import { ConfigurationCustomer } from '../../../../../constants/configuration-customer';

@Component({
	selector: 'app-order-detail-catalog',
	templateUrl: './order-detail-catalog.component.html',
	styleUrls: ['./order-detail-catalog.component.scss'],
	providers: [SubscribeManagerService, MockCategoryListService]
})
export class OrderDetailCatalogComponent implements OnInit, OnDestroy {
	@ViewChild('listWrapper') public listWrapper: ListWrapperComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	articleList$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleList);
	allArticleList: ArticleState[];
	articleList = new BaseState<ArticleState[], ArticleListFilterModel>([], {
		appliedFilter: {
			article: {
				description: null,
				has_hierarchy: []
			}
		},
		pagination: { page_current: 1, page_size: 10 }
	});

	categoryList$: Observable<BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categoryList: Category<ArticleCategory>[] = [];

	minHeight: string;
	isMobile = false;
	filterCategoryLabel: string = null;

	// TODO - boolean temporaneo per nascondere la sidebar
	hasSidebar = false;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	configurationCustomer = ConfigurationCustomer;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: UtilService,
		private utilOrderService: UtilOrderService,
		public productCatalogColumnService: ProductCatalogColumnService,
		private mockCategoryListService: MockCategoryListService,
		private utilPriceService: UtilPriceService
	) {
		// subscribe

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		this.subscribeManagerService.populate(
			this.retrieveAuxiliaryTableState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
				this.auxiliaryTable = res.data;
			}),
			'auxiliary-table'
		);

		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	ngOnInit() {
		this.minHeight = window.innerHeight - 300 + 'px';
		this.isMobile = window.innerWidth < 1200;
	}

	onResize(event) {
		this.minHeight = event.target.innerHeight - 300 + 'px';
		this.isMobile = event.target.innerWidth < 1200;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// this.store.dispatch(ArticleListStateAction.reset());
	}

	// subscribe
	initMandatoryData() {
		let hasDiff: boolean;
		return this.organization$.pipe(
			filter(
				(organization: BaseStateModel<OrganizationStateModel>) =>
					organization && organization.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationStateModel>) => {
				if (organization.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (organization.data) {
					this.organization = organization.data;
					this.articleList.dataSetting.appliedFilter.organization = this.organization;

					if (IS_MULTIDIVISION) {
						// TODO - gestire multidivision
					} else {
						this.articleList.dataSetting.appliedFilter.division = DEFAULT_DIVISION_CODE;
					}

					return this.order$;
				}
			}),
			filter(
				(order: BaseStateModel<OrderStateModel>) =>
					this.organization && order && order.type !== OrderActionEnum.LOAD
			),
			mergeMap((order: BaseStateModel<OrderStateModel>) => {
				if (order.type === OrderActionEnum.ERROR) {
					if (order.status !== 409) {
						throw new Error(OrderActionEnum.ERROR);
					}
				}
				if (order.type === OrderActionEnum.UPDATE) {
					const cloneOrder = _.cloneDeep(this.order);
					this.order = order.data;
					hasDiff = this.orderDiff(cloneOrder, this.order);
					if (hasDiff) {
						this.articleList.dataSetting.appliedFilter.division = this.order.header.division;
						if (!this.allArticleList) {
							this.store.dispatch(ArticleListStateAction.loadFromRecap(this.articleList));
						} else {
							this.getArticles();
						}
					}
				}
				return this.categoryList$;
			}),
			filter(
				(categoryListState: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) =>
					categoryListState && categoryListState.type !== CategoryListActionEnum.LOAD_RECURSIVELY
			),
			mergeMap((categoryListState: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) => {
				if (categoryListState.type === CategoryListActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (hasDiff) {
					// recupero degli articoli a partire dagli article node del category list (type: category)
					// oppure sui documenti type: article
					if (categoryListState.type === CategoryListActionEnum.NOT_EXISTING) {
						this.store.dispatch(ArticleListStateAction.loadFromRecap(this.articleList));
					} else {
						this.categoryList = categoryListState.data;

						// TODO - Rimuovere il mock una volta che i dati ci sono
						this.categoryList = this.mockCategoryListService.categoryList;
						// this.store.dispatch(ArticleListStateAction.loadFromCategory(this.articleList));
					}
				}
				return this.articleList$;
			}),
			filter(
				(articleList: BaseStateModel<ArticleState[]>) =>
					articleList &&
					articleList.type !== ArticleListActionEnum.LOAD &&
					articleList.type !== ArticleListActionEnum.LOAD_FROM_RECAP
			),
			map((articleList: BaseStateModel<ArticleState[]>) => {
				if (articleList.type === ArticleListActionEnum.ERROR) {
					throw new Error(ArticleListActionEnum.ERROR);
				}
				if (articleList.type === ArticleListActionEnum.UPDATE) {
					this.articleList = new BaseState<ArticleState[], ArticleListFilterModel>(
						this.utilOrderService.mergeProductDetailInArticle(articleList, this.order).data,
						this.articleList.dataSetting
					);
					if (!this.articleList.data.every(article => article.stock)) {
						this.productCatalogColumnService.columns = this.productCatalogColumnService.columns.filter(
							col => col.key != 'cf_available'
						);
					}
				}
			})
		);
	}

	retrieveAuxiliaryTableState<T>(state$: Observable<BaseStateModel<T>>): Observable<BaseStateModel<T>> {
		return state$.pipe(
			skipWhile((state: BaseStateModel<T>) => !(state && state.data)),
			tap((state: BaseStateModel<T>) => {
				return state;
			})
		);
	}

	getArticles() {
		const filteredData = this.applySearchFilter(this.allArticleList);
		this.articleList.dataSetting.pagination.total_element = filteredData.length;
		this.articleList.data = filteredData.slice(
			(this.articleList.dataSetting.pagination.page_current - 1) *
				this.articleList.dataSetting.pagination.page_size,
			(this.articleList.dataSetting.pagination.page_current - 1) *
				this.articleList.dataSetting.pagination.page_size +
				this.articleList.dataSetting.pagination.page_size
		);
		this.articleList = this.utilOrderService.mergeProductDetailInArticle(this.articleList, this.order);
		this.articleList.data.map(x => this.utilPriceService.mapArticlePrice(x, this.order.header.division));
	}

	applySearchFilter(articleList) {
		if (this.articleList.dataSetting.appliedFilter.article) {
			if (this.articleList.dataSetting.appliedFilter.article.description) {
				articleList = articleList.filter(article =>
					article.description
						.toLowerCase()
						.includes(this.articleList.dataSetting.appliedFilter.article.description.toLowerCase())
				);
			}
			if (this.articleList.dataSetting.appliedFilter.article.um_warehouse) {
				articleList = articleList.filter(
					x => x.um_warehouse === this.articleList.dataSetting.appliedFilter.article.um_warehouse
				);
			}
		}
		// key = nome campo, value = valore da escludere
		if (this.articleList.dataSetting.appliedFilter.article.exclude) {
			Object.keys(this.articleList.dataSetting.appliedFilter.article.exclude).forEach(key => {
				articleList = articleList.filter(
					x => x[key] !== this.articleList.dataSetting.appliedFilter.article.exclude[key]
				);
			});
		}
		return articleList;
	}

	// load
	getArticleList() {
		this.store.dispatch(ArticleListStateAction.loadFromCategory(this.articleList));
	}

	// event
	paginationChange(pagination: Pagination) {
		this.articleList.dataSetting.pagination = pagination;
		// this.getArticleList();
	}

	categorySelectedChange($event: Category<ArticleCategory>) {
		this.filterCategoryLabel = $event.description ? $event.description : null;
		this.articleList.dataSetting.appliedFilter.article.has_hierarchy = $event._id ? [$event._id] : [];
		this.store.dispatch(ArticleListStateAction.loadFromCategory(this.articleList));
	}

	rowChange(data: OrderRowModel) {
		let inputValue = data.event.target.value !== '' ? data.event.target.value : '0';
		inputValue = typeof inputValue === 'string' ? parseFloat(inputValue.replace(',', '.')) : inputValue;
		const request: ArticleChangeContextModel = {
			value: inputValue,
			article: data.row,
			articleList: this.articleList.data,
			order: this.order,
			auxiliaryTable: this.auxiliaryTable
		};
		const changeMethodList = {
			discount_agent: { method: () => this.utilOrderService.changeDiscountAgent(request) },
			qty: { method: () => this.utilOrderService.changeQuantity(request) },
			last_price: { method: () => this.utilOrderService.changeLastPrice(request) },
			qty_free: { method: () => this.utilOrderService.changeQuantityFree(request) }
		};
		const contextResponse: ArticleChangeContextResponseModel = changeMethodList[data.key].method();
		if (contextResponse) {
			if (contextResponse.currentArticle) {
				this.articleList.data[contextResponse.articleIndex] = contextResponse.currentArticle;
			}
			const requestOrderDetail: OrderWithArticleDetailRequest = {
				orderData: { order: _.cloneDeep(contextResponse.order), organization: this.organization, article: {} }
			};
			this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
		}
	}

	orderDiff(cloneOrder: OrderStateModel, order: OrderStateModel): boolean {
		let hasDiff = true;
		if (cloneOrder) {
			const result = <OrderStateModel>this.utilService.deepDiffMapper(cloneOrder, order);
			// if (
			// conditions...
			// ) {
			// 		hasDiff = false;
			// } else {
			hasDiff = false;
			// }
		}
		return hasDiff;
	}
}
