import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { WidgetModule } from '../../widget/widget.module';
import { SharedModule } from '../../shared/shared.module';
import { PageCommonsModule } from '../commons/page-commons.module';

// page
import { DataConfigurationComponent } from './data-configuration/data-configuration.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserManagementDetailComponent } from './user-management/user-management-detail/user-management-detail.component';
import { ContextCodeManagementComponent } from './context-code-management/context-code-management.component';
import { ContextCodeManagementDetailComponent } from './context-code-management/context-code-management-detail/context-code-management-detail.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [
		DataConfigurationComponent,
		UserManagementComponent,
		UserManagementDetailComponent,
		ContextCodeManagementComponent,
		ContextCodeManagementDetailComponent,
	],
	exports: [
		DataConfigurationComponent,
		UserManagementComponent,
		UserManagementDetailComponent,
		ContextCodeManagementComponent,
		ContextCodeManagementDetailComponent,
	],
	entryComponents: [
		DataConfigurationComponent,
		UserManagementComponent,
		UserManagementDetailComponent,
		ContextCodeManagementComponent,
		ContextCodeManagementDetailComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageBackofficeModule {}
