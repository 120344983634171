import { Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PATH_URL, ROUTE_URL } from '../../../../router/route-naming';
import { debounceTime, filter, map, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import {
	ExtraFieldOrderHeaderPouchModel,
	ItemDescriptionModel,
	OrderStateModel,
	OrderWithArticleDetailRequest,
	ShippingMethodsModel
} from '../../../../model/state/order-state.model';
import { AlertType } from '../../../../widget/alert/alert.component';
import { OperationState } from '../../../../widget/operation-state-banner/operation-state-banner.component';
import { DialogPickAddressComponent } from '../../../../widget/dialog/dialog-pick-address/dialog-pick-address.component';
import {
	AddressPouchModel,
	AddressTypeEnum,
	BodyTablePouchModel,
	DestinationPouchModel,
	OrderPouchModel,
	OrderStatusEnum,
	OrganizationTypeEnum,
	PaymentPouchModel
} from '@saep-ict/pouch_agent_models';
import { OrganizationActionEnum } from '../../../../state/organization/organization.actions';
import {
	DEFAULT_CAUSAL_CODE,
	ExtendedOrganizationPouchModel,
	ExtendedHeaderPouchModel
} from './checkout-to-update.model';
import { CurrencyPipe } from '@angular/common';
import { AuxiliaryTableStateModel } from '../../../../model/state/auxiliary-table-list';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { OrderActionEnum, OrderStateAction } from '../../../../state/order/order.actions';
import _ from 'lodash';
import { HighlightsViewModel } from '../../../../model/home-highlights.model';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { OrderService } from '../../../../service/rest/order.service';
import * as saepIctReferences from '../../../../constants/saep-ict-references.constants';
import { TranslateService } from '@ngx-translate/core';
import { DialogDestinationDetail } from '../../../../widget/dialog/dialog-destination-detail/dialog-destination-detail.component';
import { DEFAULT_DESTINATION_CODE } from '../../../../constants/destination.constants';

enum PaymentState {
	NOT_AUTH,
	AUTH,
	COMPLETED
}

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss'],
	providers: [SubscribeManagerService, OrderService]
})
export class CheckoutComponent implements OnInit {
	saepIctReferences = saepIctReferences;
	@Output() viewDetails = new EventEmitter();
	@ViewChild('formDirective')
	formDirective: FormGroupDirective;
	checkoutForm: FormGroup;
	key: Array<string> = ['receipt_country', 'receipt_address', 'receipt_cap', 'receipt_city', 'receipt_province'];
	AlertType = AlertType;
	OperationState = OperationState;
	PaymentState = PaymentState;
	OrganizationTypeEnum = OrganizationTypeEnum;
	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	homeHighlights$: Observable<BaseStateModel<HighlightsViewModel>> = this.store.select(
		StateFeature.getHomeHighlightsState
	);
	homeHighlights: HighlightsViewModel;
	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	loginContextCode: ExtendedOrganizationPouchModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	paymentMethods: PaymentPouchModel[];
	selectedPaymentMethod: ItemDescriptionModel;

	shippingMethods: ShippingMethodsModel[];
	defaultShippingMethod: ShippingMethodsModel;
	selectedShippingMethod: ShippingMethodsModel;

	selectedAddress: AddressPouchModel;

	operationCompleted: boolean = false;
	operationResult: OperationState = OperationState.success;

	currentContext: ContextApplicationItemCodeEnum;

	// flag che verifica se il form è stato inizializzato completamente almeno una volta
	formPrepared: boolean = false;
	isSmartphone = false;

	constructor(
		public router: Router,
		private fb: FormBuilder,
		private store: Store<any>,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		private currencyPipe: CurrencyPipe,
		private utilOrderService: UtilOrderService,
		private appConfig: CustomerAppConfig,
		private orderService: OrderService,
		private translate: TranslateService
	) {
		this.createForm();

		// User
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
				this.currentContext = this.user.current_permission.context_application;
			}
		});

		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	ngOnInit() {
		this.isSmartphone = window.innerWidth < 768;
	}

	ngOnDestroy() {
		if (this.operationCompleted && this.operationResult == OperationState.error) {
			this.formDirective.resetForm({
				additionalNotes: '',
				invoiceRequested: false,
				shippingMethod: this.defaultShippingMethod.code_item,
				paymentMethod: this.order.header.payment_code
			});
		}
		this.subscribeManagerService.destroy();
	}

	operationState() {
		if (!this.appConfig.authenticationToken) {
			return PaymentState.NOT_AUTH;
		}

		if (!this.operationCompleted) {
			return PaymentState.AUTH;
		}

		return PaymentState.COMPLETED;
	}

	initMandatoryData() {
		return this.order$.pipe(
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					order && order.type !== OrderActionEnum.LOAD
			),
			mergeMap((order: BaseStateModel<OrderStateModel>) => {
				switch (order.type) {
					case OrderActionEnum.ERROR:
						this.operationResult = OperationState.error;
						this.operationCompleted = true;
						// throw new Error(OrderActionEnum.ERROR);
						break;
					case OrderActionEnum.COMPLETED:
						this.operationCompleted = true;
						this.operationResult = OperationState.success;
						// resetta ordine attuale
						this.store.dispatch(OrderStateAction.reset());
						break;
				}
				this.order = order.data;
				return this.homeHighlights$;
			}),
			mergeMap(homeHighlights => {
				this.homeHighlights = homeHighlights ? homeHighlights.data : null;
				if (!this.formPrepared) {
					this.getShippingMethods();
				}
				return this.loginContextCode$;
			}),
			filter(
				(loginContextCode: BaseStateModel<BodyTablePouchModel>) =>
					loginContextCode && loginContextCode.type !== OrganizationActionEnum.LOAD
			),
			mergeMap(loginContextCode => {
				if (loginContextCode.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				this.loginContextCode = loginContextCode
					? ((loginContextCode.data as unknown) as ExtendedOrganizationPouchModel)
					: null;
				return this.auxiliaryTable$;
			}),
			map(auxiliaryTable => {
				this.auxiliaryTable = auxiliaryTable ? auxiliaryTable.data : null;
				if (!this.formPrepared) {
					this.getPaymentMethods();
				}
				this.setModelFormValueFromOrder();
				if (this.loginContextCode) {
					// this.utilOrderService.setOrder(this.order, this.loginContextCode, this.auxiliaryTable);
					// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
					// this.selectedAddress = this.loginContextCode.destination_list.find(
					// 	destination => destination.address_type === AddressTypeEnum.REGISTERED_OFFICE
					// ).address;
					this.selectedAddress = this.loginContextCode.destination_list.find(
						destination => destination.code === DEFAULT_DESTINATION_CODE
					).address;
				}
				this.formPrepared = true;
			})
		);
	}

	createForm() {
		this.checkoutForm = this.fb.group({
			shippingAddress: [''],
			additionalNotes: [''],
			invoiceRequested: false,
			shippingMethod: ['', Validators.required],
			paymentMethod: ['', Validators.required]
		});
	}

	setModelFormValueFromOrder() {
		this.checkoutForm.patchValue(
			{
				shippingAddress: this.order.header.goods_destination_code,
				paymentMethod: this.order.header.payment_code,
				additionalNotes: this.order.header.note_order,
				shippingMethod: this.order.header.shipping_methods_object
					? this.order.header.shipping_methods_object.code_item
					: this.defaultShippingMethod.code_item,
				invoiceRequested: (this.order.header as ExtendedHeaderPouchModel).invoice_requested
			},
			{ emitEvent: false }
		);

		if (!this.subscribeManagerService.hasSubscription('change-form')) {
			this.onChangeForm();
		}

		// data di consegna
		this.order.header.first_evasion_date = this.getFirstEvasionDate();
	}

	onChangeForm() {
		this.subscribeManagerService.populate(
			this.checkoutForm.valueChanges.pipe(debounceTime(500)).subscribe(async formChange => {
				const cloneOrder = _.cloneDeep(this.order);
				cloneOrder.header.goods_destination_code = formChange['shippingAddress'];
				cloneOrder.header.note_order = formChange['additionalNotes'];
				(cloneOrder.header as ExtendedHeaderPouchModel).invoice_requested = formChange['invoiceRequested'];

				// metodo di spedizione
				cloneOrder.header.shipping_methods_object = {
					code_item: formChange['shippingMethod']
				};
				this.selectedShippingMethod = this.shippingMethods.find(method => {
					return method.code_item === cloneOrder.header.shipping_methods_object.code_item;
				});

				// metodo di pagamento
				cloneOrder.header.payment_code = formChange['paymentMethod'];
				cloneOrder.header.payment_object = {
					code_item: cloneOrder.header.payment_code
				};
				this.selectedPaymentMethod = this.paymentMethods.find(method => {
					return method.code_item === cloneOrder.header.payment_code;
				});

				// set product list data
				if (cloneOrder.product_list && cloneOrder.product_list.length > 0) {
					for (let i = 0; i < cloneOrder.product_list.length; i++) {
						cloneOrder.product_list[i].order_causal_code = this.utilOrderService.setNonFreeRowCausal(
							cloneOrder.header.order_causal,
							this.auxiliaryTable.causalHeaderSoList
						);
						if (!cloneOrder.product_list[i].order_causal_code) {
							cloneOrder.product_list[i].order_causal_code = DEFAULT_CAUSAL_CODE;
						}
					}
				}
				cloneOrder.header.division = this.utilOrderService.getDivision();

				// console.log('cloneOrder', cloneOrder);
				this.store.dispatch(OrderStateAction.update(new BaseState(cloneOrder)));
			}),
			'change-form'
		);
	}

	/**
	 * OBJECTS
	 */

	setOrderCausalObject() {
		let orderCausal = null;

		// causale ordine di default
		if (this.order.header.order_causal) {
			if (this.auxiliaryTable.causalHeaderSoList) {
				orderCausal = this.auxiliaryTable.causalHeaderSoList.find(x => {
					return x.code_item === this.order.header.order_causal;
				});
			}
		}

		// se causale ordine non viene recuperata
		if (!orderCausal) {
			const organizationType =
				this.loginContextCode && this.loginContextCode.organization_type
					? this.loginContextCode.organization_type
					: OrganizationTypeEnum.PRIVATE; // se utente non è loggato
			orderCausal = this.homeHighlights.order_causal.find(x => {
				return x.description_short === organizationType;
			});
		}

		// popolo oggetto
		if (orderCausal) {
			this.order.header.order_causal_object = {
				code_item: orderCausal.code_item,
				description: orderCausal.description ? orderCausal.description : null,
				description_short: orderCausal.description_short ? orderCausal.description_short : null
			};
			this.order.header.order_causal = orderCausal.code_item;

			// product causal code
			this.order.product_list.map(product => (product.order_causal_code = orderCausal.causals_row[0]));
		}
	}

	setShippingMethodsObject() {
		const shippingMethod = this.selectedShippingMethod ? this.selectedShippingMethod : this.defaultShippingMethod;
		this.order.header.shipping_methods_object = {
			code_item: shippingMethod.code_item,
			description: shippingMethod.description,
			charge: shippingMethod.charge,
			delivery_days: shippingMethod.delivery_days
		};
	}

	setPaymentObject() {
		const paymentMethod = this.selectedPaymentMethod
			? this.selectedPaymentMethod
			: this.paymentMethods.find(method => {
					return method.code_item === this.order.header.payment_code;
			  });
		this.order.header.payment_object = {
			code_item: paymentMethod.code_item,
			description: paymentMethod.description ? paymentMethod.description : null,
			description_short: paymentMethod.description_short ? paymentMethod.description_short : null
		};
	}

	setGoodsDestinationObject() {
		if (this.selectedAddress) {
			this.order.header.goods_destination_object = {
				address: this.selectedAddress.address,
				locality: this.selectedAddress.locality,
				zip_code: this.selectedAddress.zip_code,
				province: this.selectedAddress.province,
				country: this.selectedAddress.country
			};
		}
	}

	setPriceObject() {
		this.order.header.price = {
			total: this.getTotalPrice(),
			article: this.getProductsTotalPrice(),
			shipping: this.getShippingPrice(),
			vat: this.getVatCalculated()
		};
	}

	/**
	 * END OBJECTS
	 */

	setObjectsOnSumbit() {
		// causale
		this.setOrderCausalObject();
		// metodo di spedizione
		this.setShippingMethodsObject();
		// metodo di pagamento
		this.setPaymentObject();
		// indirizzo di destinazione
		this.setGoodsDestinationObject();
		// riepilogo prezzi
		this.setPriceObject();
	}

	onSubmit() {
		if (this.checkoutForm.valid) {
			console.log('this.checkoutForm:', this.checkoutForm.value);
			this.setObjectsOnSumbit();
			// prepara ordine ad essere inviato nell'etere
			this.order.header.status = OrderStatusEnum.TO_AUTHORIZE;
			this.order.header.submission_date = new Date().getTime();
			// manda ordine nell'etere
			const requestOrderDetail: OrderWithArticleDetailRequest = {
				orderData: { order: _.cloneDeep(this.order), organization: this.loginContextCode, article: {} }
			};
			this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
			// TOFIX
			// this.sendNewOrderEmail();
		}
	}

	// Data di consegna stimata in base ai giorni di consegna del metodo di spedizione
	getFirstEvasionDate() {
		const today: Date = new Date(),
			noOfDaysToAdd = this.selectedShippingMethod
				? this.selectedShippingMethod.delivery_days
				: this.defaultShippingMethod.delivery_days;
		let endDate: Date,
			count = 0;
		while (count < noOfDaysToAdd) {
			endDate = new Date(today.setDate(today.getDate() + 1));
			// skip weekends (0 = sunday, 6 = saturday)
			if (endDate.getDay() != 0 && endDate.getDay() != 6) {
				count++;
			}
		}
		return endDate.getTime();
	}

	getShippingMethods() {
		if (this.homeHighlights) {
			// metodi di spedizione
			this.shippingMethods = this.homeHighlights.shipping_methods.sort((a, b) =>
				a.delivery_days < b.delivery_days ? 1 : -1
			);
			// metodo di spedizione di default (charge più basso)
			this.defaultShippingMethod = this.shippingMethods.reduce(function (prev, curr) {
				return prev.charge <= curr.charge ? prev : curr;
			});
		}
	}

	getPaymentMethods() {
		// metodi di pagamento doc di config
		let defaultPaymentList = [];

		if (this.loginContextCode && this.loginContextCode.organization_type === OrganizationTypeEnum.COMPANY) {
			// COMPANY
			defaultPaymentList = this.homeHighlights.company_payment_methods;
		} else {
			// PRIVATE o utente non loggato
			defaultPaymentList = this.homeHighlights.private_payment_methods;
		}

		// recupera solo i codici e aggiunge il codice di default
		let paymentMethodsCodeList = defaultPaymentList.map(item => {
			return item.code_item;
		});
		if (!paymentMethodsCodeList.includes(this.order.header.payment_code)) {
			paymentMethodsCodeList.push(this.order.header.payment_code);
		}

		// recupera metodo di pagamento da auxiliaryTable, altrimenti da homeHighlights
		this.paymentMethods = [];
		paymentMethodsCodeList.forEach(code => {
			const paymentMethod = this.auxiliaryTable.paymentList.find(method => {
				return method.code_item === code;
			});
			if (paymentMethod) {
				this.paymentMethods.push(paymentMethod);
			} else {
				// code item non trovato
				const defaultPayment = defaultPaymentList.find(method => {
					return method.code_item === code;
				});
				if (defaultPayment) {
					this.paymentMethods.push(defaultPayment);
				}
			}
		});
	}

	/**
	 * Metodo di spedizione selezionabile solo se il valore complessivo dell'ordine
	 * raggiunge una certa soglia
	 */
	checkDisabledShippingMethod(method: ShippingMethodsModel) {
		if (method.threshold <= this.getProductsTotalPrice() + this.getVatCalculated()) {
			return false;
		}
		return true;
	}

	getFormattedAddress() {
		return `${this.selectedAddress.address} - ${this.selectedAddress.zip_code} - ${this.selectedAddress.locality} (${this.selectedAddress.province}) - ${this.selectedAddress.country}`;
	}

	// Label: Tipo di metodo (giorni di consegna) - prezzo
	getShippingLabel(method: ShippingMethodsModel) {
		const deliveryDaysLabel =
			`(${method.delivery_days} ` +
			(method.delivery_days === 1
				? this.translate.instant('day.day')
				: this.translate.instant('day.day_plural')) +
			')';

		const chargeLabel = '<b>' + this.currencyPipe.transform(method.charge, 'EUR', 'symbol', '', 'it') + '</b>';

		return method.description + ' ' + deliveryDaysLabel + ' - ' + chargeLabel;
	}

	showWarning() {
		let lacks: string = '';
		if (!(this.loginContextCode.tax_data && this.loginContextCode.tax_data.sdi_code)) {
			lacks += (lacks ? ', ' : '') + 'Codice SDI';
		}
		if (!(this.loginContextCode.contact_details && this.loginContextCode.contact_details.pec)) {
			lacks += (lacks ? ', ' : '') + 'PEC';
		}
		return lacks;
	}

	toShow(matCheckbox: MatCheckboxChange) {
		if (matCheckbox.checked === true) {
			this.key.forEach((element: string) => {
				this.checkoutForm.get(element).setValidators(Validators.required);
				this.checkoutForm.get(element).updateValueAndValidity();
			});
		} else if (matCheckbox.checked === false) {
			this.key.forEach((element: string) => {
				this.checkoutForm.get(element).clearValidators();
				this.checkoutForm.get(element).updateValueAndValidity();
			});
		}
	}

	pickAddress() {
		const dialogRef = this.dialog.open(DialogPickAddressComponent, {
			data: {
				modalTitle: 'Scegli indirizzo di spedizione'
			},
			disableClose: true,
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe((destination: DestinationPouchModel) => {
			if (destination) {
				this.checkoutForm.get('shippingAddress').setValue(destination.code);
				this.selectedAddress = destination.address;
			}
		});
	}

	addAddress() {
		const dialogRef = this.dialog.open(DialogDestinationDetail, {
			data: {
				modalTitle: 'Aggiungi nuovo indirizzo'
			},
			disableClose: true,
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// if (this.dataTable) {
				//   this.dataTable.refresh();
				// }
			}
		});
	}

	/**
	 * Invia email di notifica di un nuovo ordine
	 */
	sendNewOrderEmail() {
		const data = {
			order_id: this.order._id,
			code: this.loginContextCode.code,
			context_application: this.currentContext
		};
		this.orderService
			.sendNewOrderEmail({ data })
			.then(res => {
				console.log(res);
			})
			.catch(err => {
				console.log(err);
				throw new Error(err);
			});
	}

	/**
	 * CALCOLI (TOFIX: calcolare 1 sola volta e passare i risultati)
	 */

	getShippingPrice() {
		return this.selectedShippingMethod ? this.selectedShippingMethod.charge : this.defaultShippingMethod.charge;
	}

	getProductsTotalPrice() {
		const productsTotalPrice = this.order.product_list.reduce((previous, current) => {
			return previous + current.price * current.ordered_quantity;
		}, 0);
		return Math.round(productsTotalPrice * 100) / 100;
	}

	getVatCalculated() {
		const vatCalculated = (this.getProductsTotalPrice() * 22) / 100;
		return Math.round(vatCalculated * 100) / 100;
	}

	getTotalPrice() {
		const totalPrice = this.getProductsTotalPrice() + this.getVatCalculated() + this.getShippingPrice();
		return Math.round(totalPrice * 100) / 100;
	}

	openPrivacyPolicy() {
		this.router.navigate([ROUTE_URL.privacyPolicy]);
	}

	openTermsAndConditions() {
		this.router.navigate([ROUTE_URL.termsAndConditions]);
	}
}
