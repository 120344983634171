<div class="container-fluid">
	<!-- HEADER -->
	<div class="header">
		<!-- Timerange -->
		<div>{{ 'chart.field.time_range' | translate | sentencecase }}</div>
		&nbsp; &nbsp;
		<mat-form-field class="header__timerange">
			<mat-select [value]="timeRange.values[0]" (selectionChange)="setTimeRange($event)">
				<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
					{{ 'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Separator -->
		<div class="header__separator"></div>

		<!-- Date last update statistics -->
		<div class="header__statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>

	<!-- ACTIVE ORDERS -->
	<div class="row">
		<div class="col-12">
			<card-dashboard [cardDashboardConfig]="orderTrendChartConfigService.cardDashboardConfigOrdersTrend">
				<chart-wrapper [config]="orderTrendChartConfigService.chart"></chart-wrapper>
			</card-dashboard>
		</div>
	</div>

	<!-- AVERAGE -->
	<div class="row">
		<!-- Ordini: Valore medio -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="orderAverageDetailChartConfigService.cardDashboardConfigOrdersAverageDetail"
			>
				<div class="orders-value">
					<div class="row orders-value__header" *ngIf="orderAverageDetailChartConfigService.metrics">
						<div class="orders-value__header__average">
							{{ orderAverageDetailChartConfigService.metrics.average | appFormatMultiplesCurrency }}
						</div>
						&nbsp; &nbsp;
						<div class="orders-value__header__trend">
							<icon-trend [value]="orderAverageDetailChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row orders-value__body">
						<chart-wrapper
							class="row orders-value__body__chart"
							[config]="orderAverageDetailChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>

		<!-- Ordini: Quantità -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				class="card-dashboard-accent"
				[cardDashboardConfig]="orderQuantityDetailChartConfigService.cardDashboardConfigOrdersQuantityDetail"
			>
				<div class="orders-amount">
					<div class="row orders-amount__header" *ngIf="orderQuantityDetailChartConfigService.metrics">
						<div class="orders-amount__header__average">
							{{ orderQuantityDetailChartConfigService.metrics.average | number: '1.2-2':'it' }}
						</div>
						&nbsp; &nbsp;
						<div class="orders-amount__header__trend">
							<icon-trend [value]="orderQuantityDetailChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row orders-amount__body">
						<chart-wrapper
							class="row orders-amount__body__chart"
							[config]="orderQuantityDetailChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<!-- PRODUCTS -->
	<div class="row">
		<!-- Venduto: grafico prodotti più venduti -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="productsBestSellerChartConfigService.cardDashboardConfigProductsBestSeller"
			>
				<ng-container *ngIf="productsBestSellerChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="productsBestSellerChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>

		<!-- Venduto: lista prodotti più venduti -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="productsTop5TableConfigService.cardDashboardConfigProductsTop5">
				<ng-container *ngIf="productsTop5TableConfigService.currentDataSet.length; else noData">
					<td-data-table
						[data]="productsTop5TableConfigService.currentDataSet"
						[columns]="productsTop5TableConfigService.columns"
					>
						<ng-template tdDataTableTemplate="description" let-value="value">
							<p>
								{{ value }}
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="value" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div>
	</div>

	<!-- CATEGORIES: now the code is commented out waiting for more precise instruction on category retrieval -->
	<div class="row">
		<!-- Venduto: grafico categorie più vendute -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<!-- <card-dashboard
			[cardDashboardConfig]="categoriesBestSellerChartConfigService.cardDashboardConfigCategoriesBestSeller">
			<ng-container *ngIf="categoriesBestSellerChartConfigService.chart.datasets.length">
				<chart-wrapper [config]="categoriesBestSellerChartConfigService.chart"></chart-wrapper>
			</ng-container>
		</card-dashboard> -->
		</div>

		<!-- Venduto: lista categorie più vendute -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<!-- <card-dashboard [cardDashboardConfig]="categoriesTop5TableConfigService.cardDashboardConfigCategoriesTop5">
			<ng-container *ngIf="categoriesTop5TableConfigService.currentDataSet.length; else noData">
				<td-data-table [data]="categoriesTop5TableConfigService.currentDataSet"
					[columns]="categoriesTop5TableConfigService.columns">
					<ng-template tdDataTableTemplate="description" let-value="value">
						<p>
							{{ value }}
						</p>
					</ng-template>
	
					<ng-template tdDataTableTemplate="value" let-value="value">
						<p>
							{{ value | appFormatMultiplesCurrency }}
						</p>
					</ng-template>
				</td-data-table>
			</ng-container>
			<ng-template #noData>
				<chart-no-data icon="grid_on"></chart-no-data>
			</ng-template>
		</card-dashboard> -->
		</div>
	</div>
</div>
