import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { InformativePageLangItemModel, InformativePageModel } from '../../../model/informative-page.model';
import {
	InformativePageActionEnum,
	InformativePageStateAction
} from '../../../state/informative-page/informative-page.actions';
import { map, skipWhile } from 'rxjs/operators';
import { Language } from '../../../enum/language.enum';

@Component({
	selector: 'informative-page',
	templateUrl: './informative-page.component.html',
	styleUrls: ['./informative-page.component.scss'],
	providers: [SubscribeManagerService]
})
export class InformativePageComponent implements OnDestroy {
	@LocalStorage('language') language: Language;

	informativePage$: Observable<BaseStateModel<InformativePageModel>> = this.store.select(
		StateFeature.getInformativePageState
	);
	informativePage: InformativePageLangItemModel;

	constructor(
		private store: Store<InformativePageModel>,
		private activatedRoute: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService
	) {
		if (!this.language) {
			this.language = Language.ENGLISH;
		}

		// returns the requested document based on the slug
		this.subscribeManagerService.populate(
			this.activatedRoute.url.subscribe(url => {
				this.store.dispatch(InformativePageStateAction.load({ slug: url[0].path }));
			}),
			'informative-page'
		);

		this.subscribeManagerService.populate(this.subscribeInformativePage().subscribe(), 'subscribeInformativePage');
	}

	subscribeInformativePage() {
		return this.informativePage$.pipe(
			skipWhile(
				(informativePageState: BaseState<InformativePageModel>) =>
					!(informativePageState && informativePageState.data)
			),
			map((informativePageState: BaseState<InformativePageModel>) => {
				switch (informativePageState.type) {
					case InformativePageActionEnum.UPDATE:
						// load the selected language
						this.loadInformativePageBody(informativePageState, this.language);

						// if couldn't find the selected language, load english
						if (!this.informativePage) {
							this.loadInformativePageBody(informativePageState, Language.ENGLISH);
						}
						break;

					case InformativePageActionEnum.ERROR:
						throw new Error(InformativePageActionEnum.ERROR);

					default:
						break;
				}

				return informativePageState;
			})
		);
	}

	loadInformativePageBody(informativePageState: BaseStateModel<InformativePageModel>, language: Language) {
		this.informativePage = informativePageState.data.lang_list.find(
			informativePage => informativePage.lang === language
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(InformativePageStateAction.reset());
	}
}
