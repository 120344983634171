import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { StatisticsDetailExpiredStateAction } from '../../../../state/statistics-detail-expired/statistics-detail-expired.action';
import { StatisticsDetailBalance } from '../../../../model/statistics-detail-balance.model';
import { Observable } from 'rxjs';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { filter, take } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
	selector: 'dashboard-expired',
	templateUrl: './dashboard-expired.component.html',
	styleUrls: ['./dashboard-expired.component.scss']
})
export class DashboardExpiredComponent implements OnDestroy {
	dateUpdatedStatistics: number;
	statistics$: Observable<BaseStateModel<StatisticsDetailBalance>> = this.store.select(
		StateFeature.getStatisticsDetailExpired
	);
	statistics: StatisticsDetailBalance;

	timeRange;

	constructor(private store: Store<StatisticsDetailBalance>) {
		// Load Statistics Expired
		this.store.dispatch(StatisticsDetailExpiredStateAction.load());

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsDetailBalance>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.update_date;

				const quarterList = [];
				// TODO: populate quarterList
				// this.timeRange = quarterList;
			});
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.store.dispatch(StatisticsDetailExpiredStateAction.reset());
	}
}
