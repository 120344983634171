import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseApiService } from './baseApi.service';
import { Injectable } from '@angular/core';
import { ResourceAction, ResourceRequestMethod, IResourceMethodPromise } from '@ngx-resource/core';

@Injectable({
	providedIn: 'root'
})
export class ContactApiService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/contact'
	})
	createContact: IResourceMethodPromise<ContactPouchModel, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Put,
		path: '/contact/{!_id}'
	})
	updateContact: IResourceMethodPromise<any, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/contact/activation/{!email}/{!customerCode}/{!idRecipient}/{!guid}'
	})
	activateContact: IResourceMethodPromise<
		{ email: String; customerCode: String; idRecipient: String; guid: String },
		any
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Delete,
		path: '/contact/{!customerCode}/{!idRecipient}'
	})
	removeContact: IResourceMethodPromise<{ customerCode: String; idRecipient: String }, any>;
}
