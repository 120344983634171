import { ITdDataTableColumn } from '@covalent/core/data-table';

import { BaseTableListService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ReceiptColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}

	_columns = [
        {
            name: "document_number",
            label: this.translate.instant('receipt.field.document_number'),
        },
        {
            name: "document_type",
            label:this.translate.instant('receipt.field.document_type'),
            sortable: false,
        },
        {
            name: "organization",
            label: this.translate.instant('receipt.field.organization'),
        },
        {
            name: "date",
            label: this.translate.instant('receipt.field.date'),
            width: {min: 100, max:  200},
            numeric: true
        },
        {
            name: "expiration_date",
            label: this.translate.instant('receipt.field.expiration_date'),
            width: {min: 100, max:  200},
            numeric: true
        },
        {
            name: "outlay",
            label: this.translate.instant('receipt.field.amount'),
            numeric: true,
        },
        {
            name: "download_path",
            label: "",
            sortable: false,
            numeric: true,
        }
    ];
}