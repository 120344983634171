import { BasePouch } from './base.pouch';
import { ListDataPouchModel, BasePouchModel } from '@saep-ict/pouch_agent_models';
import { Filters } from '../../../../model/structure/AbstractListModel';
import { ContextCodeFilterModel } from '../../filter/context-code-filter.model';
import { BaseStateModel } from '@saep-ict/angular-core';

export class Spin8Pouch extends BasePouch {
	getCustomerList(data: BaseStateModel<any, ContextCodeFilterModel>): Promise<any> {
		return new Promise(resolve => {
			const selector = {
				$and: [
					{
						type: 'customer'
					}
				]
			} as PouchDB.Find.Selector;

			const filters: Filters = data.dataSetting.appliedFilter;
			if (filters) {
				if (filters.exclude_code) {
					const s = {
						code: {
							$nin: filters.exclude_code
						}
					};
					selector.$and.push(s);
				}
				if (filters.text) {
					const s = {
						$or: [
							{
								code: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								business_name: {
									$regex: `(?i)${filters.text}`
								}
							}
						]
					};
					selector.$and.push(s);
				}
			}
			const sort = [];
			const sortItem = {};
			if (data.dataSetting.sort) {
				// TODO - reintroddurre l'ordinamento
				// 	// old
				// 	// sortItem[data.dataSetting.sort[0].name !== "code" ? data.dataSetting.sort[0].name : "code_item"] = data.dataSetting.sort[0].order;
				// 	const obj = Object.values(data.dataSetting.sort)[0];
				// 	if (obj){
				// 		const key = Object.keys(obj)[0];
				// 		const order = Object.values(obj)[0];
				// 		sortItem[key !== "code" ? key : "code_item"] = order;
				// 		sort.push(sortItem);
				// 	}
				// }
			}
			this.getWithIndexFilter('customer-list', selector, data.dataSetting.pagination, sort).then(
				(res: ListDataPouchModel<BasePouchModel>) => {
					resolve(res.docs);
				}
			);
		});
	}

	getBackofficeCodeList(data: BaseStateModel<any, ContextCodeFilterModel>): Promise<any> {
		return new Promise(resolve => {
			const selector = {
				$and: [
					{
						type: 'backoffice', // TODO - chiarire se è backoffice o backoffice_user
						code_item: {
							$exists: true
						},
						description: {
							$exists: true
						},
						description_short: {
							$exists: true
						}
					}
				]
			} as PouchDB.Find.Selector;

			const filters: Filters = data.dataSetting.appliedFilter;
			if (filters) {
				if (filters.exclude_code) {
					const s = {
						code_item: {
							$nin: filters.exclude_code
						}
					};
					selector.$and.push(s);
				}
				if (filters.text) {
					const s = {
						$or: [
							{
								code_item: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								description: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								description_short: {
									$regex: `(?i)${filters.text}`
								}
							}
						]
					};
					selector.$and.push(s);
				}
			}
			const sort = [];

			// TODO - reintroddurre l'ordinamento

			// const sortItem = {};
			// if (data.dataSetting.sort.length) {

			// 	// old
			// 	// sortItem[data.dataSetting.sort[0].name !== "code" ? data.dataSetting.sort[0].name : "code_item"] = data.dataSetting.sort[0].order;
			// 	const obj = Object.values(data.dataSetting.sort)[0];
			// 	if (obj){
			// 		const key = Object.keys(obj)[0];
			// 		const order = Object.values(obj)[0];
			// 		sortItem[key !== "code" ? key : "code_item"] = order;
			// 		sort.push(sortItem);
			// 	}
			// }
			this.getWithIndexFilter('backoffice-code-list', selector, data.dataSetting.pagination, sort)
				.then((res: ListDataPouchModel<BasePouchModel>) => {
					resolve(res.docs);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}
}
