import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ContextCodeFilterModel } from '../../service/pouch-db/filter/context-code-filter.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ReceiptListStateAction, ReceiptListActionEnum } from './receipt-list.actions';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { ReceiptStateModel } from '../../model/state/receipt-state.model';

export namespace ReceiptListStateReducer {
	export const initialState: BaseStateModel<ReceiptStateModel[], ContextCodeFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(ReceiptListStateAction.loadAll, state => state),
		on(ReceiptListStateAction.update, (state, param) => param),
		on(ReceiptListStateAction.reset, () => initialState),
		on(ReceiptListStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<ReceiptStateModel[]>, action: TypedAction<ReceiptListActionEnum>) {
		return _reducer(state, action);
	}
}