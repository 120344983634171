import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ChangeDetectorRef,
	AfterViewInit
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// model
import { OrderStateModel } from '../../../model/state/order-state.model';
import { BaseStateModel, UtilService } from '@saep-ict/angular-core';
import { ArticleFilterModel } from '../../../service/pouch-db/filter/article-list-filter.model';
import { ArticleState } from '../../../model/state/article-list-state.model';
import { OrderRowModel } from '../../../model/order-util.model';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';

// widget & utility
import { Subject, Observable } from 'rxjs';
import { TdDataTableComponent } from '@covalent/core/data-table';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { debounceTime } from 'rxjs/operators';
import { ArticleStock } from '../../../enum/article-stock.enum';
import { UtilArticleKitService } from '../../../service/util/util-article-kit.service';
import { ArticleCategory } from '../../../model/article.model';
import { Category } from '../../../model/category-list.model';

@Component({
	selector: 'article-list-table',
	templateUrl: './article-list-table.component.html',
	styleUrls: ['./article-list-table.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleListTableComponent implements OnInit, AfterViewInit {
	@Input() order: OrderStateModel;

	_articleList = <BaseStateModel<ArticleState[], ArticleFilterModel>>{};
	isTablet: any;
	@Input() set articleList(articleList: BaseStateModel<ArticleState[], ArticleFilterModel>) {
		if (articleList) {
			this._articleList = articleList;
			if (this.dataTable) {
				this.dataTable.refresh();
			}
		}
	}

	@Input() columns;
	@Output() articleChange = new EventEmitter<OrderRowModel>();

	dataTable: TdDataTableComponent;
	@ViewChild('dataTable') set _dataTable(dataTable: TdDataTableComponent) {
		if (dataTable) {
			this.dataTable = dataTable;
			this.addClassToTableRow();
		}
	}

	OrderStatusEnum = OrderStatusEnum;
	ArticleStock = ArticleStock;

	private updateRowSource = new Subject<OrderRowModel>();
	updateRow$: Observable<OrderRowModel> = this.updateRowSource.asObservable();

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService,
		private currencyPipe: CurrencyPipe,
		private cdr: ChangeDetectorRef,
		private utilService: UtilService,
		public utilArticleKitService: UtilArticleKitService
	) {
		// subscribe
		this.subscribeManagerService.populate(
			this.updateRow$.pipe(debounceTime(500)).subscribe((res: OrderRowModel) => {
				this.articleChange.emit(res);
			}),
			'row-change'
		);
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.checkTableColumnData(window.innerWidth);
	}

	updatedRowSelection($event: any, row: ArticleState, key: string) {
		this.updateRowSource.next({ event: $event, row: row, key: key });
	}

	retrieveArticlePrice(rowTable: ArticleState) {
		return (rowTable.articlePrice && rowTable.articlePrice.discount.length > 0) ||
			rowTable.productDetail.discount_agent
			? 'scontato: ' +
					this.currencyPipe.transform(
						this.utilOrderService.retrievePriceWithDiscount(
							rowTable.productDetail.price ? rowTable.productDetail.price : rowTable.articlePrice.price,
							rowTable.articlePrice.discount,
							rowTable.productDetail.discount_agent ? rowTable.productDetail.discount_agent : null
						),
						'EUR',
						'symbol',
						'',
						'it'
					)
			: null;
	}

	/**
	 * Check column visibility based on window width
	 * @param width This is the width of the window (px)
	 */
	checkTableColumnData(width: number) {
		const _isTablet = this.isTablet;
		this.isTablet = width <= 800;

		if (_isTablet !== this.isTablet) {
			['description'].forEach(columnName => {
				this.changeVisiblityColumn(columnName, this.isTablet);
			});

			// TODO - problem Maximum Excedeed Stack
			// ['code'].forEach(columnName => {
			// 	this.changeWidthColumn(columnName, this.isTablet, 120, 80);
			// });

			if (this.dataTable) {
				this.dataTable.refresh();
			}
		}
	}

	/**
	 * Hide or show a column
	 * @param columnName the name of the column declared in the ITdDataTableColumn
	 * @param hide boolean to hide or not the column
	 */
	changeVisiblityColumn(columnName: string, hide: boolean) {
		const indexColumn = this.utilService.getElementIndex(this.columns, 'name', columnName);
		if (indexColumn !== undefined) {
			this.columns[indexColumn].hidden = hide;
		}
	}

	addClassToTableRow() {
		//  wait until all rows are ready
		if (this.dataTable._rows.length === this.dataTable.data.length) {
			// get index of the row to change
			const testerChild: number[] = [];
			const testerParent: number[] = [];

			this.dataTable.data.forEach((row: ArticleState, i) => {
				/**
				 * testerParent
				 */
				// se trovo un nodo con 'tester' valorizzato
				const categoryNodeList = row.categoryNodeList
					? row.categoryNodeList.find((item: Category<ArticleCategory>) => !!item.tester)
					: null;
				if (categoryNodeList) {
					// e il contentuo di tester è uguale al code dell'articolo successivo
					const codeNextArticle: string = this.dataTable.data[i + 1].code;
					if (codeNextArticle && categoryNodeList.tester === codeNextArticle) {
						testerParent.push(i);
					}
				}

				/**
				 * testerChild
				 */
				// se il flag 'is_tester' è valorizzato
				if (row.categoryNodeList && row.categoryNodeList.find(item => item.is_tester)) {
					testerChild.push(i);
				}
			});

			// add class to freeSampleChild
			if (testerChild.length) {
				this.dataTable._rows.forEach((element, index) => {
					if (testerChild.includes(index)) {
						element['_elementRef'].nativeElement.classList.add('product-related__child');
					}
				});
			}

			// add class to freeSampleParent
			if (testerParent.length) {
				this.dataTable._rows.forEach((element, index) => {
					if (testerParent.includes(index)) {
						element['_elementRef'].nativeElement.classList.add('product-related__parent');
					}
				});
			}

			// refresh
			if (testerParent.length || testerChild.length) {
				this.dataTable.refresh();
			}
		}
	}
}
