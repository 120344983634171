import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginAuthRequestModel } from '@saep-ict/angular-core';
import { AuthService } from '../../../service/rest/auth.service';

@Component({
	selector: 'b2c-login-form',
	templateUrl: './b2c-login-form.component.html',
	styleUrls: ['./b2c-login-form.component.scss']
})
export class B2CLoginFormComponent implements OnInit {
	form: FormGroup;
	userLogin: LoginAuthRequestModel;
	hide: boolean = true;

	constructor(private fb: FormBuilder, private authService: AuthService) {
		this.createForm();
		// che ci fa sta roba alla pagina di login?
		// this.authService.clearState();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			email: ['', { validators: [Validators.required] }],
			password: ['', { validators: [Validators.required] }]
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.userLogin = this.form.value;
			this.authService.login(this.userLogin);
		}
	}
}
