import {
	ConfigurationCustomerUserManagementModel,
	CustomerConfigurationCompanyModel
} from '../../model/structure/configuration-customer.model';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { UserManagementStateAction } from '../../state/backoffice/user-management/user-management.actions';

// Manages the display of the following features in the CONTACTS section of the company
export const CustomerConfigurationCompany: CustomerConfigurationCompanyModel = {
	contact: {
		send_email_confirmation: true,
		can_activate_email_order_receipt: true
	},
	registration: {
		can_access_to_registration: true
	}
};

export const ConfigurationCustomerUser: ConfigurationCustomerUserManagementModel = {
	user_management_can_edit_context_code: {
		BACKOFFICE: true,
		B2B: false,
		AGENT: true,
		B2C: true,
		CRM: true
	},
	store_action: {
		load_list: UserManagementStateAction.loadListWithAvatar,
		load_detail: UserManagementStateAction.loadDetailWithAvatar,
		reset: UserManagementStateAction.reset
	},
	can_edit_organization: {
		detail: {
			BACKOFFICE: true,
			B2B: true,
			AGENT: true,
			B2C: true,
			CRM: true
		},
		destination_edit: {
			BACKOFFICE: true,
			B2B: true,
			AGENT: true,
			B2C: true,
			CRM: true
		},
		destination_add: {
			BACKOFFICE: true,
			B2B: true,
			AGENT: true,
			B2C: true,
			CRM: true
		}
	}
};
