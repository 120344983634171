<div class="container-fluid">
	<div class="row" *ngIf="organization">
		<!-- AGENT CONTEXT -->
		<ng-container *ngIf="currentContext === contextApplicationItemCodeEnum.AGENT">
			<div class="col-12">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'company.detail' | translate | sentencecase }}
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<div class="row">
							<div class="col-6">
								<mat-form-field>
									<input
										matInput
										disabled
										placeholder="{{ 'company.field.code' | translate | sentencecase }}"
										value="{{ organization.code }}"
									/>
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									<input
										matInput
										disabled
										placeholder="{{ 'company.field.status' | translate | sentencecase }}"
										value="{{ 'company.status.' + organization.status | translate | sentencecase }}"
									/>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<mat-form-field *ngIf="organization.tax_data">
									<input
										matInput
										disabled
										placeholder="{{ 'company.field.vat_number' | translate | sentencecase }}"
										value="{{ organization.tax_data.vat_number }}"
									/>
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field *ngIf="organization.tax_data">
									<input
										matInput
										disabled
										placeholder="{{ 'company.field.tax_code' | translate | sentencecase }}"
										value="{{ organization.tax_data.tax_code }}"
									/>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<mat-form-field>
									<input
										matInput
										disabled
										placeholder="{{ 'company.field.business_name' | translate | sentencecase }}"
										value="{{ organization.business_name | titlecase }}"
									/>
								</mat-form-field>
							</div>
							<div class="col-6">
								<mat-form-field>
									<input
										matInput
										disabled
										placeholder="{{
											'company.field.reference_internal.name' | translate | sentencecase
										}}"
										value="{{ organization.reference_internal | titlecase }}"
									/>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<div class="col-6">
								<mat-form-field *ngIf="defaultAddress">
									<input
										matInput
										disabled
										placeholder="{{ 'company.field.legal_headquarter' | translate | sentencecase }}"
										value="{{ defaultAddress.address | titlecase }} - {{
											defaultAddress.zip_code
										}} {{ defaultAddress.locality | titlecase }} ({{ defaultAddress.province }})"
									/>
								</mat-form-field>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>

			<div class="col-12">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'payment_method.list' | translate | sentencecase }}
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<td-data-table #dataTable [data]="paymentList" [columns]="paymentColumns"> </td-data-table>
					</mat-card-content>
				</mat-card>
			</div>
		</ng-container>
		<!-- END AGENT CONTEXT -->

		<!-- B2B/BACKOFFICE CONTEXT -->
		<div class="col-12">
			<mat-card>
				<mat-toolbar>
					<mat-toolbar-row>
						{{ 'user.general_information' | translate | sentencecase }}
					</mat-toolbar-row>
				</mat-toolbar>
				<form [formGroup]="form">
					<mat-card-content>
						<div class="row">
							<!-- Nome -->
							<div class="col-6" *ngIf="getVisibilityField('firstName')">
								<mat-form-field>
									<input
										matInput
										placeholder="{{ 'user.field.first_name' | translate | sentencecase }}"
										formControlName="firstName"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('firstName')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
							<!-- Cognome -->
							<div class="col-6" *ngIf="getVisibilityField('lastName')">
								<mat-form-field>
									<input
										matInput
										placeholder="{{ 'user.field.last_name' | translate | sentencecase }}"
										formControlName="lastName"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('lastName')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<!-- Azienda -->
						<div class="row" *ngIf="getVisibilityField('businessName')">
							<div class="col-12">
								<mat-form-field>
									<input
										matInput
										placeholder="{{ 'company.name' | translate | sentencecase }}"
										formControlName="businessName"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('businessName')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<!-- Email -->
							<div class="col-6" *ngIf="getVisibilityField('email')">
								<mat-form-field>
									<input
										matInput
										placeholder="{{ 'user.field.email' | translate | sentencecase }}"
										formControlName="email"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('email')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
							<!-- Telefono -->
							<div class="col-6" *ngIf="getVisibilityField('phone')">
								<mat-form-field>
									<input
										matInput
										placeholder="{{ 'contact.field.phone' | translate | sentencecase }}"
										formControlName="phone"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('phone')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
						<div class="row">
							<!-- Codice fiscale -->
							<div class="col-12" *ngIf="getVisibilityField('taxCode')">
								<mat-form-field>
									<input
										matInput
										appUppercase
										placeholder="{{ 'organization.field.tax_code' | translate | sentencecase }}"
										formControlName="taxCode"
										maxlength="16"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('taxCode')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
							<!-- Partita IVA -->
							<div class="col-12">
								<mat-form-field *ngIf="getVisibilityField('vatNumber')">
									<input
										matInput
										placeholder="{{ 'organization.field.vat_number' | translate | sentencecase }}"
										formControlName="vatNumber"
										maxlength="11"
									/>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('vatNumber')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
						</div>
					</mat-card-content>

					<!-- <mat-card-actions>
						<mat-toolbar>
							<button
								*ngIf="canEditOrganization"
								mat-raised-button
								color="primary"
								(click)="editOrganization()"
							>
								{{ isEditingOrganization ? 'Salva organizzazione' : 'Modifica organizzazione' }}
							</button>
						</mat-toolbar>
					</mat-card-actions> -->
				</form>
			</mat-card>
		</div>
		<!-- END B2B/BACKOFFICE CONTEXT -->

		<!-- CRM CONTEXT -->
		<div class="col-12" *ngIf="currentContext === contextApplicationItemCodeEnum.CRM">
			<mat-card>
				<mat-toolbar>
					<mat-toolbar-row>
						{{ 'general.other_info' | translate | sentencecase }}
					</mat-toolbar-row>
				</mat-toolbar>
				<mat-card-content>
					<div class="row" *ngIf="otherInformationList.length > 0; else noData">
						<ng-container *ngFor="let otherInformation of otherInformationList">
							<div class="col-md-6" *ngIf="otherInformation.value !== null">
								{{ otherInformation.label }} :
								<strong [innerHtml]="otherInformation.value"></strong>
							</div>
						</ng-container>
					</div>
					<ng-template #noData>
						<div class="row">
							<div class="col-12">
								<p class="placeholder">
									{{ 'general.no_data' | translate | sentencecase }}
								</p>
							</div>
						</div>
					</ng-template>
				</mat-card-content>
			</mat-card>
		</div>
		<!-- END CRM CONTEXT -->

		<!-- B2C CONTEXT -->
		<div class="col-12" *ngIf="currentContext === contextApplicationItemCodeEnum.B2C">
			<mat-card>
				<mat-toolbar>
					<mat-toolbar-row>
						{{ 'user.general_information' | translate | sentencecase }}
					</mat-toolbar-row>
				</mat-toolbar>
				<mat-card-content>
					<div class="row">
						<div class="col-12">
							<mat-form-field>
								<input
									matInput
									disabled
									placeholder="{{ 'user.type' | translate | sentencecase }}"
									value="{{ organization.organization_type }}"
								/>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<mat-form-field>
								<input
									matInput
									disabled
									placeholder="{{
										(organization.organization_type === organizationTypeEnum.COMPANY
											? 'company.name'
											: 'user.field.full_name'
										)
											| translate
											| sentencecase
									}}"
									value="{{ organization.business_name }}"
								/>
							</mat-form-field>
						</div>
					</div>
					<div class="row">
						<div class="col-12" *ngIf="organization.organization_type === organizationTypeEnum.COMPANY">
							<mat-form-field *ngIf="organization.tax_data && organization.tax_data.vat_number">
								<input
									matInput
									disabled
									placeholder="{{ 'company.field.vat_number' | translate | sentencecase }}"
									value="{{ organization.tax_data.vat_number }}"
								/>
							</mat-form-field>
						</div>
						<div class="col-12" *ngIf="organization.organization_type === organizationTypeEnum.PRIVATE">
							<mat-form-field *ngIf="organization.tax_data && organization.tax_data.tax_code">
								<input
									matInput
									disabled
									placeholder="{{ 'company.field.tax_code' | translate | sentencecase }}"
									value="{{ organization.tax_data.tax_code }}"
								/>
							</mat-form-field>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<!-- END B2C CONTEXT -->
	</div>
</div>
