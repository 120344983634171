<div class="card-price">
	<div>
		<div class="card-price__aside">
			<h2>Il tuo ordine</h2>
			<div class="card-price__header">
				<div>
					<h3 class="card-price__titleProduct"><b>Prodotti</b></h3>
					<p class="card-price__paragraphProduct" *ngFor="let product of productsList">
						{{ product.description ? product.description : product.articleDetail.description }}
					</p>
				</div>
				<div class="card-price__content">
					<h3><b>Quantità</b></h3>
					<p class="card-price__text" *ngFor="let product of productsList">
						{{ product.quantity ? product.quantity : product.ordered_quantity }}
					</p>
				</div>
				<div class="card-price__content">
					<h3 class="card-price__content__headline"><b>Totale</b></h3>
					<p *ngFor="let product of productsList">
						{{
							product.total
								? (product.total | currency: 'EUR':'symbol':'':'it')
								: (getCurrentPrice(product) * product.ordered_quantity
								  | currency: 'EUR':'symbol':'':'it')
						}}
					</p>
				</div>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="card-price__containerData">
			<div class="card-price__contentData">
				<h3><b>Totale Parziale</b></h3>
				<p>
					{{
						price?.article
							? (price.article | currency: 'EUR':'symbol':'':'it')
							: (getPartialTotal() | currency: 'EUR':'symbol':'':'it')
					}}
				</p>
			</div>
			<div class="card-price__contentData">
				<h3><b>Spese di spedizione</b></h3>
				<p>
					{{
						price?.shipping
							? (price.shipping | currency: 'EUR':'symbol':'':'it')
							: (shippingFees | currency: 'EUR':'symbol':'':'it')
					}}
				</p>
			</div>
			<div class="card-price__contentData">
				<h3><b>IVA calcolata (22%)</b></h3>
				<p>
					{{
						price?.vat
							? (price.vat | currency: 'EUR':'symbol':'':'it')
							: (ivaCalcolata() | currency: 'EUR':'symbol':'':'it')
					}}
				</p>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="card-price__contentData__total">
			<h3><b>Totale</b></h3>
			<p>
				{{
					price?.total
						? (price.total | currency: 'EUR':'symbol':'':'it')
						: (getTotalPrice() | currency: 'EUR':'symbol':'':'it')
				}}
			</p>
		</div>
	</div>
</div>
