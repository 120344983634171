import { ITdDataTableColumn } from '@covalent/core/data-table';

import { BaseTableListService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

/**
 * Utilizzato nei seguenti componenti:
 * + organization.component.ts
 * + dialog-select-organization.component.ts
 */
@Injectable()
export class OrganizationColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns = [
		{
			name: 'status',
			label: '',
			width: 50,
			sortable: false
		},
		{
			name: 'business_name',
			labelPath: 'organization.field.business_name',
			label: null
		},
		// {
		// 	name: 'organization_type',
		// 	label: this.translate.instant('organization.field.type')
		// },
		{
			name: 'vat_number_or_tax_code',
			labelPath: 'organization.field.vat_number_or_tax_code',
			label: null
		},
		{
			name: 'address',
			labelPath: 'general.address.address',
			label: null,
			sortable: false
		},
		{
			name: 'create_new',
			label: '',
			sortable: false,
			width: 50,
			numeric: true
		}
	];
}
