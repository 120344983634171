// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import {
	ContextCodeAssociationActionEnum,
	ContextCodeAssociationStateAction
} from './context-code-association.actions';
import { BaseStateModel, ContextCodeAssociation } from '@saep-ict/angular-core';

export namespace ContextCodeAssociationStateReducer {
	export const initialState: BaseStateModel<ContextCodeAssociation> = null;

	const _reducer = createReducer(
		initialState,
		on(ContextCodeAssociationStateAction.update, (state, param) => param),
		on(ContextCodeAssociationStateAction.load, state => state),
		on(ContextCodeAssociationStateAction.save, state => state),
		on(ContextCodeAssociationStateAction.saveSuccess, (state, param) => param),
		on(ContextCodeAssociationStateAction.deleteItemSuccess, (state, param) => param),
		on(ContextCodeAssociationStateAction.reset, () => initialState),
		on(ContextCodeAssociationStateAction.error, (state, param) => param)
	);

	export function reducer(
		state: BaseStateModel<ContextCodeAssociation>,
		action: TypedAction<ContextCodeAssociationActionEnum>
	) {
		return _reducer(state, action);
	}
}
