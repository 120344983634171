import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// store
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	SubscribeManagerService,
	UtilService,
	ContextCodeItemLink,
	ContextCodeItem
} from '@saep-ict/angular-core';
// widget & utility
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ConfigurationCustomerContextCodeCreationFormFieldMap } from '../../../constants/configuration-customer/context-code/creation-field-map.constant';
// model
import { BodyTablePouchCustomModel } from '../../../service/pouch-db/model/pouch-base.model';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { AgentListActionEnum } from '../../../state/backoffice/context-code/agent-list/agent-list.actions';
import { BackofficeUserListActionEnum } from '../../../state/backoffice/context-code/backoffice-user-list/backoffice-user-list.actions';
import { ContextCodeAssociationStateAction } from '../../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { OrganizationListActionEnum } from '../../../state/common/organization-list/organization-list.actions';
import { DialogAddContextCodeComponent } from '../dialog-add-context-code/dialog-add-context-code.component';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { B2cUserListActionEnum } from '../../../state/backoffice/context-code/b2c-user-list/b2c-user-list.actions';
import { TranslateService } from '@ngx-translate/core';
import { CrmUserListActionEnum } from '../../../state/backoffice/context-code/crm-user-list/crm-user-list.actions';

@Component({
	selector: 'dialog-create-context-code',
	templateUrl: './dialog-create-context-code.component.html',
	styleUrls: ['./dialog-create-context-code.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogCreateContextCodeComponent implements OnInit, OnDestroy {
	contextCodeCreated: ContextCodeItem;
	form: FormGroup;
	configurationCustomerContextCodeCreationFormFieldMap = ConfigurationCustomerContextCodeCreationFormFieldMap;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ContextCodeItemLink,
		private fb: FormBuilder,
		private store: Store<any>,
		public dialogRef: MatDialogRef<DialogAddContextCodeComponent>,
		private snackBar: MatSnackBar,
		private subscribeManagerService: SubscribeManagerService,
		private permissionUtilService: PermissionUtilService,
		public utilService: UtilService,
		private translate: TranslateService
	) {
		console.log(data);

		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
		this.createForm();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.permissionUtilService.resetContextCodeListAssociatedState();
		this.store.dispatch(ContextCodeAssociationStateAction.reset());
	}

	// subscribe
	subscribeContextCodeList(): Observable<BaseStateModel<ContextCodeItem[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			filter(
				(state: BaseStateModel<ContextCodeItem[]>) =>
					state.type === BackofficeUserListActionEnum.CREATE_ITEM_SUCCESS ||
					state.type === AgentListActionEnum.CREATE_ITEM_SUCCESS ||
					state.type === B2cUserListActionEnum.CREATE_ITEM_SUCCESS ||
					state.type === CrmUserListActionEnum.CREATE_ITEM_SUCCESS ||
					state.type === OrganizationListActionEnum.CREATE_SUCCESS
			),
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				this.contextCodeCreated = <ContextCodeItem>state.data[0];
				const message = this.translate.instant('context_code.x_created', {
					context_code: this.contextCodeCreated.description
				});
				this.snackBar.open(message, null, { duration: 3000 });
				this.dialogRef.close(this.contextCodeCreated);
				return state;
			})
		);
	}

	// form
	createForm() {
		const controls = {};
		for (const field of ConfigurationCustomerContextCodeCreationFormFieldMap[
			ContextApplicationItemCodeEnum[this.data.code]
		]) {
			controls[field.name] = new FormControl(field.value, this.utilService.setRequired(field.required));
		}
		this.form = new FormGroup(controls);
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		formModel.type = this.data.couch_document_key;
		return formModel;
	}

	onFormSubmit() {
		if (this.form.valid) {
			const bodyTablePouch = <BaseStateModel<BodyTablePouchCustomModel | OrganizationPouchModel>>{
				data: this.prepareSaveForm()
			};
			this.store.dispatch(this.data.action.createItem(bodyTablePouch));
		}
	}
}
