import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { AgentActionEnum, AgentStateAction } from './agent.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';

export namespace AgentStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchModel> = null;

	const _reducer = createReducer(
		initialState,
		on(AgentStateAction.load, state => state),
		on(AgentStateAction.update, (state, agent) => agent),
		on(AgentStateAction.reset, () => initialState),
		on(AgentStateAction.error, (state, agent) => ({ data: state ? state.data : null, type: agent.type }))
	);

	export function reducer(state: BaseStateModel<BodyTablePouchModel>, action: TypedAction<AgentActionEnum>) {
		return _reducer(state, action);
	}
}
