import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	SentencecasePipe,
	SubscribeManagerService,
	BaseStateModel,
	BaseState,
	DialogConfirmComponent
} from '@saep-ict/angular-core';
import moment from 'moment';
import {
	OfferOtherDescriptionModel,
	OfferStateModel,
	OfferStatusEnum
} from '../../../../../model/state/offer-state.model';
import { UtilService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../../state';
import { filter, map } from 'rxjs/operators';
import { OfferActionEnum, OfferStateAction } from '../../../../../state/offer/offer.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../../../router/route-naming';
import { OpportunityPouchModel } from '../../../../../model/state/opportunity-state.model';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../../state/opportunity/opportunity.actions';

@Component({
	selector: 'offer-detail-head',
	templateUrl: './offer-detail-head.component.html',
	styleUrls: ['./offer-detail-head.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailHeadComponent implements OnInit, OnDestroy {
	form: FormGroup;
	paymentMethodList: string[] = ['paymentMethod #1', 'paymentMethod #2', 'paymentMethod #3'];
	public offerStatusEnum = OfferStatusEnum;

	// state
	offer: OfferStateModel;
	offer$: Observable<BaseStateModel<OfferStateModel[]>> = this.store.select(StateFeature.getOfferState);

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel;

	constructor(
		private fb: FormBuilder,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: UtilService,
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		this.createForm();

		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');
		this.subscribeManagerService.populate(this.subscribeOffer().subscribe(), 'subscribeOffer');
	}

	// Subscribe
	subscribeOffer() {
		return this.offer$.pipe(
			filter((offerState: BaseStateModel<OfferStateModel[]>) => !!(offerState && offerState.data)),
			map(offerState => {
				console.log(offerState);

				switch (offerState.type) {
					case OfferActionEnum.UPDATE:
						// al primo save aggiorno l'url
						if ((!this.offer || !this.offer._id) && offerState.data[0]._id) {
							this.router.navigate([this.router.url.replace(ROUTE_URL.newOffer, offerState.data[0]._id)]);
						}

						this.offer = offerState.data[0];
						this.setFormFromRemoteData();
						break;

					default:
						break;
				}
			})
		);
	}

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunity = opportunityState.data[0];
						break;

					default:
						break;
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// reset dello state offer nel padre
		this.store.dispatch(OpportunityStateAction.reset());
	}

	/**
	 * Form
	 */
	createForm() {
		this.form = this.fb.group({
			title: ['', { validators: [Validators.required] }],
			date_end_validity: [moment().add(1, 'M'), { validators: [Validators.required] }],
			payment_method: ['', { validators: [Validators.required] }],
			other_descriptions: ['', { validators: [] }],
			object: ['', { validators: [] }],
			summary: ['', { validators: [] }],
			references: ['', { validators: [] }],
			premise: ['', { validators: [] }]
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.store.dispatch(OfferStateAction.save(new BaseState([this.prepareSaveForm()])));
		} else {
			this.showDialog('general.save_missing_data');
		}
	}

	prepareSaveForm(): OfferStateModel {
		let offer: OfferStateModel = this.offer ? this.offer : <OfferStateModel>{};
		const formValue = this.form.value;

		offer.title = formValue.title;
		offer.date_end_validity = formValue.date_end_validity.valueOf();
		offer.payment_method = formValue.payment_method;

		offer.other_descriptions = <OfferOtherDescriptionModel>{};
		if (formValue.other_descriptions) {
			offer.other_descriptions.object = formValue.object;
			offer.other_descriptions.summary = formValue.summary;
			offer.other_descriptions.references = formValue.references;
			offer.other_descriptions.premise = formValue.premise;
		} else {
			delete offer.other_descriptions;
		}

		// Assegno l'opportunity solo in fase di creazione
		// così si cristallizza la versione di opportunity
		// per la quale l'offerta è stata scritta
		if (!this.offer) {
			offer.opportunity = this.opportunity;
		}

		offer.status = this.offer ? this.offer.status : OfferStatusEnum.DRAFT;

		this.utilService.deleteEmptyProperties(offer);
		console.log(offer);
		return offer;
	}

	setFormFromRemoteData() {
		const otherDescription = this.offer.other_descriptions || null;

		this.form.patchValue(
			{
				title: this.offer.title,
				date_end_validity: this.offer.date_end_validity ? moment(this.offer.date_end_validity) : null,
				payment_method: this.offer.payment_method,

				other_descriptions: otherDescription ? true : false,
				object: otherDescription ? otherDescription.object : null,
				summary: otherDescription ? otherDescription.summary : null,
				references: otherDescription ? otherDescription.references : null,
				premise: otherDescription ? otherDescription.premise : null
			},
			{ emitEvent: false }
		);
	}

	sendOffer() {
		if (this.form.valid) {
			const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
				data: {
					title: this.sentencecasePipe.transform(this.translate.instant('offer.send')),
					text: this.sentencecasePipe.transform(this.translate.instant('offer.question.send'))
				},
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					let offer = this.prepareSaveForm();
					offer.status = OfferStatusEnum.SENT;
					this.showDialog('offer.sent');
					this.store.dispatch(OfferStateAction.save(new BaseState([offer])));
				}
			});
		} else {
			this.showDialog('general.save_missing_data');
		}
	}

	/**
	 * Utility
	 */

	showDialog(msg: string, action: string = null, duration: number = 3000) {
		msg = this.sentencecasePipe.transform(this.translate.instant(msg));
		this.snackBar.open(msg, action, { duration: duration });
	}
}
