import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IMG_PLACEHOLDER_PRODUCT } from '../../../../constants/misc.constants';
import { ROUTE_URL } from '../../../../router/route-naming';
import { ICardSliderConfiguration, ItemType } from '../../../../widget/card-product/card-slider.models';
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { ArticlePouchModel, BodyTablePouchModel, DiscountTypeEnum } from '@saep-ict/pouch_agent_models';
import { BaseState, BaseStateModel, DiscountModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { OrderStateModel, OrderWithArticleDetailRequest } from '../../../../model/state/order-state.model';
import { OrderStateAction } from '../../../../state/order/order.actions';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { ExtendedOrganizationPouchModel } from '../checkout/checkout-to-update.model';
import { filter, map, take } from 'rxjs/operators';
import { AuxiliaryTableStateModel } from '../../../../model/state/auxiliary-table-list';
import { ArticleState, OrderProductModel } from '../../../../model/state/article-list-state.model';
import { ArticleStock } from '../../../../enum/article-stock.enum';
import * as _ from 'lodash';
import { CallToActionConfig } from '../../../../widget/call-to-action/call-to-action.component';
import {
	ArticleDetailActionEnum,
	ArticleDetailStateAction
} from '../../../../state/article-detail/article-detail.actions';
import { MeasureFormatter } from '../../../../shared/misc/measure-formatter';
import { DEFAULT_DIVISION_CODE } from '../../../../constants/division.constants';
import { UtilPriceService } from '../../../../service/util/util-price.service';

export interface ExtendedArticlePouchModel extends ArticleState {
	extended_description?: string;
	extra_info?: string;
	related_products?: string[];
	original_price?: number;
	image_path?: string;
	divisions?: any;
}

export interface ExtendedOrderProductModel extends OrderProductModel {
	articleDetail?: ExtendedArticlePouchModel;
}

@Component({
	selector: 'app-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class ProductDetailComponent implements OnInit {
	public viewTypeEnum = ViewTypeEnum;
	public ItemType = ItemType;

	config: ICardSliderConfiguration;

	cartProduct: ExtendedOrderProductModel;
	relatedProducts: ArticlePouchModel[] = [];

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	loginContextCode: ExtendedOrganizationPouchModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	articleList$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticleState[];

	articleDetail$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleDetail);

	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		btnLabel: 'contact.contact_us',
		theme: 'accent'
	};

	measureFormatter = MeasureFormatter;
	ArticleStock = ArticleStock;

	orderedQuantityLabel: string = '';

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<any>,
		public utilOrderService: UtilOrderService,
		private subscribeManagerService: SubscribeManagerService,
		private utilPriceService: UtilPriceService
	) {
		this.subscribeManagerService.populate(
			this.activatedRoute.params.subscribe(params => {
				this.store.dispatch(ArticleDetailStateAction.loadSingleWithCode({ code: params.product_id }));
			}),
			'params'
		);
		this.subscribeManagerService.populate(this.retrieveProduct().subscribe(), 'product');
	}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.order$.subscribe(res => {
				this.order = res ? res.data : null;
			}),
			'order'
		);

		this.loginContextCode$.pipe(take(1)).subscribe(res => {
			this.loginContextCode = res ? ((res.data as unknown) as ExtendedOrganizationPouchModel) : null;
		});

		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});
	}

	retrieveProduct() {
		const articleDetail$ = this.articleDetail$.pipe(
			filter(
				(articleDetail: BaseStateModel<ArticleState[]>) =>
					articleDetail && articleDetail.type !== ArticleDetailActionEnum.LOAD_SINGLE_WITH_CODE
			),
			map((articleDetail: BaseStateModel<ArticleState[]>) => {
				if (articleDetail.type === ArticleDetailActionEnum.ERROR) {
					throw new Error(ArticleDetailActionEnum.ERROR);
				}
				if (articleDetail.type === ArticleDetailActionEnum.UPDATE) {
					return articleDetail;
				}
			})
		);

		return combineLatest([this.articleList$, articleDetail$]).pipe(
			filter(([articleList, articleDetail]) => !!(articleList && articleDetail)),
			map(([articleList, articleDetail]) => {
				this.articleList = articleList ? articleList.data : null;
				if (articleDetail.type === ArticleDetailActionEnum.UPDATE) {
					this.cartProduct = {
						articleDetail: (articleDetail as unknown) as ExtendedArticlePouchModel
					};
					// TODO: remove after product couch update
					this.completeProductInfo();
					this.getRelated();
				}
			})
		);
	}
	completeProductInfo() {
		this.cartProduct.articleDetail = {
			extended_description:
				'Questa è una descrizione del prodotto totalmente cablata ma esposta su un field ("extended_description") che simula quello che andremo ad aggiungere non appena sarà definita una strategia di inserimento info aggiuntive da backoffice alla scheda prodotto, quale è la presente.',
			extra_info:
				'Queste sono informazioni extra sul prodotto selezionato, cablato come la descrizione in alto, su un field chiamato "extra_info". Da capire che info vogliamo specificare qui e in che formato.',
			related_products: ['LA101505', 'LFT00110', 'CSMEM001', 'CCSKP715'],
			image_path: IMG_PLACEHOLDER_PRODUCT,
			...this.cartProduct.articleDetail
		};

		// Simulare altre info su prodotto
		// this.cartProduct.articleDetail.original_price = 10;
		// this.cartProduct.discount = [{value: 5, type: 0}];
		// TODO: rimuovi dopo avera capito se sarà sempre disponibile questo campo e nel caso in cui non lo sia, cosa mostrare al suo posto
		if (!this.cartProduct.articleDetail.stock) {
			this.cartProduct.articleDetail.stock = {
				code_segnalation: 'BU',
				description_segnalation: 'DISPONIBILE'
			} as any;
		}
	}
	async getRelated() {
		this.relatedProducts = [];
		for (const productCode of this.cartProduct.articleDetail.related_products) {
			const articleDetail: ArticleState = this.articleList.find(article => article.code == productCode);
			this.relatedProducts.push(articleDetail);
		}
		this.config = {
			data: this.relatedProducts,
			medias: {
				md: 2,
				lg: 3
			},
			animation: {
				animationDuration: '.8s',
				easing: 'ease-in-out',
				loop: true
			}
		};
	}

	changeValue(quantity: number) {
		if (quantity !== this.cartProduct.ordered_quantity) {
			this.cartProduct.ordered_quantity = quantity;
			this.getOrderedQuantityLabel();
		}
	}

	clickCallToAction() {}

	addToCart() {
		// order creation
		if (!this.order) {
			this.store.dispatch(OrderStateAction.update(new BaseState(new OrderStateModel())));
			if (this.loginContextCode) {
				this.utilOrderService.setOrder(this.order, this.loginContextCode, this.auxiliaryTable);
			}
		}

		// set product
		this.cartProduct.code = this.cartProduct.articleDetail.code;
		this.cartProduct.code_company = this.order.header.society_code;
		this.cartProduct.input_quantity = this.cartProduct.ordered_quantity / this.cartProduct.articleDetail.qty_box;

		// get product data from division
		const articleDivision = this.cartProduct.articleDetail.divisions.find(
			item => item.division === this.order.header.division || DEFAULT_DIVISION_CODE
		);
		if (articleDivision) {
			this.cartProduct.price = articleDivision.price;
			this.cartProduct.discount = this.utilPriceService.getArticleDiscounts(articleDivision);
		} else {
			this.cartProduct.price = 0;
			this.cartProduct.discount = [];
		}

		// product list
		this.order.product_list.push(_.cloneDeep(this.cartProduct));

		// order update
		const requestOrderDetail: OrderWithArticleDetailRequest = {
			orderData: { order: _.cloneDeep(this.order), organization: this.loginContextCode, article: {} }
		};
		this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));

		// notification
		const snackBarRef = this.snackBar.open('Prodotto aggiunto al carrello', 'Apri il carrello', {
			duration: 3000
		});
		snackBarRef.afterDismissed().subscribe((info: MatSnackBarDismiss) => {
			if (info.dismissedByAction) {
				this.router.navigate([`/${ROUTE_URL.public}/${ROUTE_URL.cart}`]);
			}
		});
	}

	noProductSelected() {
		return this.cartProduct ? !this.cartProduct.ordered_quantity : true;
	}

	getOrderedQuantityLabel() {
		const orderedQuantityTotal =
			Math.round(this.cartProduct.articleDetail.qty_box * this.cartProduct.ordered_quantity * 100) / 100;
		if (this.cartProduct.articleDetail) {
			this.orderedQuantityLabel =
				'(' +
				orderedQuantityTotal +
				' ' +
				this.measureFormatter.getMeasure(this.cartProduct.articleDetail.um_warehouse, orderedQuantityTotal) +
				')';
		}
		return this.orderedQuantityLabel;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
