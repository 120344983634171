export interface PasswordRule {
	name: string;
	description: string;
	isRequired: boolean;
	params?: { [s: string]: any };
}

export enum CheckName {
	LENGTH = 'checkLength',
	LOWERCASE = 'checkLowercase',
	UPPERCASE = 'checkUppercase',
	DIGIT = 'checkDigit',
	SPECIAL_CHAR = 'checkSpecialChar'
}

export const passwordRules: PasswordRule[] = [
	{
		name: CheckName.LENGTH,
		description: 'login.password_rules.length',
		isRequired: true,
		params: { minLength: 8 }
	},
	{
		name: CheckName.LOWERCASE,
		description: 'login.password_rules.lowercase',
		isRequired: true
	},
	{
		name: CheckName.UPPERCASE,
		description: 'login.password_rules.uppercase',
		isRequired: true
	},
	{
		name: CheckName.DIGIT,
		description: 'login.password_rules.digit',
		isRequired: false
	},
	{
		name: CheckName.SPECIAL_CHAR,
		description: 'login.password_rules.special_character',
		isRequired: false
	}
];
