import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { chartTimeRange7Days } from '../../../constants/chart-time-range.constants';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { AppUtilService } from '../../util/app-util.service';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { BaseTableClass } from '../../td-data-table/base-table.service';
import { StatisticsBaseSerieProduct } from '../../../model/statistics.model';

@Injectable()
export class OrderCategoriesTableConfigService extends BaseTableClass {
	columns: ITdDataTableColumn[] = [
		{
			name: 'description',
			label: this.translate.instant('category.field.name')
		},
		{
			name: 'value',
			label: this.translate.instant('category.field.total_income'),
			numeric: true
		}
	];

	cardDashboardConfigCategoriesTop5: CardDashboardConfig = {
		title:
			this.context === ContextApplicationItemCodeEnum.B2B
				? 'chart.categories_top_x.title_customer'
				: 'chart.categories_top_x.title_seller',
		subtitle:
			this.context === ContextApplicationItemCodeEnum.B2B
				? 'chart.categories_top_x.subtitle_customer'
				: 'chart.categories_top_x.subtitle_seller',
		helpText: 'chart.categories_top_x.helptext',
		icon: 'assignment'
	};

	// Questa proprietà viene letta anche per popolare cardDashboardConfigOrderTrend
	public timeRange = chartTimeRange7Days;

	constructor(
		public store: Store<any>,
		@Inject(TranslateService) private translate: TranslateService,
		public utilService: AppUtilService,
		public route: ActivatedRoute
	) {
		super(utilService, store, route);
	}

	// Functions

	createDataSerie() {
		let categoriesTop5;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardOrders) {
			categoriesTop5 = this.utilService.findLeaf(this.statistics.data, `categories`);
		} else {
			categoriesTop5 = this.utilService.findLeaf(this.statistics.data, `orders.categories`);
		}

		this.utilService.findLeaf(categoriesTop5, 'days_7').forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetDays7.push(item);
		});

		this.utilService.findLeaf(categoriesTop5, 'days_15').forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetDays15.push(item);
		});

		this.utilService.findLeaf(categoriesTop5, 'days_30').forEach((item: StatisticsBaseSerieProduct) => {
			this.fullDataSetDays30.push(item);
		});
	}

	populateTable(rangeDays: number = this.timeRange.values[0]) {
		switch (rangeDays) {
			case this.timeRange.values[0]:
				this.currentDataSet = this.fullDataSetDays7;
				break;
			case this.timeRange.values[1]:
				this.currentDataSet = this.fullDataSetDays15;
				break;
			case this.timeRange.values[2]:
				this.currentDataSet = this.fullDataSetDays30;
				break;

			default:
				break;
		}
	}
}
