import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';

export enum IrinoxNoProgressOrderStatusEnum {
	HANDLING = 'HANDLING',
	CONFIRMED = 'CONFIRMED'
}

export const IrinoxOrderStatusEnum = {
	...OrderStatusEnum,
	HANDLING: 'HANDLING',
	CONFIRMED: 'CONFIRMED'
};
