<div class="row">
	<div class="col-md-12">
		<h2 mat-dialog-title>
			{{ data.title | translate | sentencecase }}
		</h2>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<prospect-registration *ngIf="data" (closeDialog)="closeDialog()" (saveSuccess)="saveSuccess($event)" [isDialog]="true"></prospect-registration>
</mat-dialog-content>
