import { Validators } from '@angular/forms';
import { FormControlMultipurposeEnum, FormControlMultipurposeModel, FormValidatorCustom } from '@saep-ict/angular-core';

export namespace TicketCenterBodyCustomField {
	export enum RequestTypeEnum {
		COMMERCIAL_REQUEST = 'COMMERCIAL_REQUEST',
		GENERAL_INFORMATIONS_REQUEST = 'GENERAL_INFORMATIONS_REQUEST',
		NOT_CONFORMITY_REPORT = 'NOT_CONFORMITY_REPORT'
	}

	export enum RequestReasonEnum {
		MISSING_ITEM = 'MISSING_ITEM',
		WRONG_ITEM = 'WRONG_ITEM',
		DAMAGED_ITEM_RECEIVED = 'DAMAGED_ITEM_RECEIVED',
		QUANTITY_DELIVERED_IS_DIFFERENT_TO_THE_QUANTITY_ORDERED = 'QUANTITY_DELIVERED_IS_DIFFERENT_TO_THE_QUANTITY_ORDERED',
		PRESENCE_OF_DEFECTS_BARREL_STREAKS = 'PRESENCE_OF_DEFECTS_BARREL_STREAKS',
		DIMENSIONS_DRILLING_NOT_ACCORDING_TO_DRAWING = 'DIMENSIONS_DRILLING_NOT_ACCORDING_TO_DRAWING',
		COMPONENTS_NOT_PRESENT_OR_INCORRECTLY_POSITIONED = 'COMPONENTS_NOT_PRESENT_OR_INCORRECTLY_POSITIONED',
		ACCESSORIES_NOT_PRESENT_OR_INCORRECT = 'ACCESSORIES_NOT_PRESENT_OR_INCORRECT',
		INCORRECT_OR_DAMAGED_GASKET = 'INCORRECT OR DAMAGED GASKET',
		WRONG_OR_MISSING_LABELLING = 'WRONG_OR_MISSING_LABELLING',
		CLARIFICATION_ON_THE_IP_PROTECTION_RATING = 'CLARIFICATION_ON_THE_IP_PROTECTION_RATING',
		OTHER = 'OTHER'
	}

	export const creationFieldMap: FormControlMultipurposeModel.Item[] = [
		{
			name: 'request_type',
			label: 'lib.ticket_center.form.request_type',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.SELECT,
			column_width: 12,
			validator_list: [Validators.required],
			option_list: [
				{
					value: RequestTypeEnum.COMMERCIAL_REQUEST
				},
				{
					value: RequestTypeEnum.GENERAL_INFORMATIONS_REQUEST
				},
				{
					value: RequestTypeEnum.NOT_CONFORMITY_REPORT
				}
			]
		},
		{
			name: 'request_reason',
			label: 'lib.ticket_center.form.request_reason',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.SELECT,
			column_width: 12,
			validator_list: [Validators.required],
			option_list: [
				{
					value: RequestReasonEnum.OTHER,
					label: 'lib.ticket_center.form.request_reason_option.OTHER'
				},
				{
					value: RequestReasonEnum.MISSING_ITEM,
					label: 'lib.ticket_center.form.request_reason_option.MISSING_ITEM'
				},
				{
					value: RequestReasonEnum.WRONG_ITEM,
					label: 'lib.ticket_center.form.request_reason_option.WRONG_ITEM'
				},
				{
					value: RequestReasonEnum.DAMAGED_ITEM_RECEIVED,
					label: 'lib.ticket_center.form.request_reason_option.DAMAGED_ITEM_RECEIVED'
				},
				{
					value: RequestReasonEnum.QUANTITY_DELIVERED_IS_DIFFERENT_TO_THE_QUANTITY_ORDERED,
					label:
						'lib.ticket_center.form.request_reason_option.QUANTITY_DELIVERED_IS_DIFFERENT_TO_THE_QUANTITY_ORDERED'
				},
				{
					value: RequestReasonEnum.PRESENCE_OF_DEFECTS_BARREL_STREAKS,
					label: 'lib.ticket_center.form.request_reason_option.PRESENCE_OF_DEFECTS_BARREL_STREAKS'
				},
				{
					value: RequestReasonEnum.DIMENSIONS_DRILLING_NOT_ACCORDING_TO_DRAWING,
					label: 'lib.ticket_center.form.request_reason_option.DIMENSIONS_DRILLING_NOT_ACCORDING_TO_DRAWING'
				},
				{
					value: RequestReasonEnum.COMPONENTS_NOT_PRESENT_OR_INCORRECTLY_POSITIONED,
					label:
						'lib.ticket_center.form.request_reason_option.COMPONENTS_NOT_PRESENT_OR_INCORRECTLY_POSITIONED'
				},
				{
					value: RequestReasonEnum.ACCESSORIES_NOT_PRESENT_OR_INCORRECT,
					label: 'lib.ticket_center.form.request_reason_option.ACCESSORIES_NOT_PRESENT_OR_INCORRECT'
				},
				{
					value: RequestReasonEnum.INCORRECT_OR_DAMAGED_GASKET,
					label: 'lib.ticket_center.form.request_reason_option.INCORRECT_OR_DAMAGED_GASKET'
				},
				{
					value: RequestReasonEnum.WRONG_OR_MISSING_LABELLING,
					label: 'lib.ticket_center.form.request_reason_option.WRONG_OR_MISSING_LABELLING'
				},
				{
					value: RequestReasonEnum.CLARIFICATION_ON_THE_IP_PROTECTION_RATING,
					label: 'lib.ticket_center.form.request_reason_option.CLARIFICATION_ON_THE_IP_PROTECTION_RATING'
				}
			]
		},
		{
			name: 'organization_referent',
			label: 'lib.ticket_center.form.organization_referent',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6,
			validator_list: [Validators.required]
		},
		{
			name: 'organization_referent_contact_address',
			label: 'lib.ticket_center.form.organization_referent_contact_address',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6,
			validator_list: [FormValidatorCustom.email, Validators.required]
		},
		{
			name: 'order_odv_code',
			label: 'lib.ticket_center.form.order_odv_code',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6
		},
		{
			name: 'article_code_item',
			label: 'lib.ticket_center.form.article_code_item',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6
		},
		{
			name: 'ddt_code_item',
			label: 'lib.ticket_center.form.ddt_code_item',
			on_form_template: true,
			type: FormControlMultipurposeEnum.ItemType.STRING,
			column_width: 6
		},
		{
			name: 'organization_code_item',
			on_form_template: false,
			type: FormControlMultipurposeEnum.ItemType.STRING
		},
		{
			name: 'order_id',
			on_form_template: false,
			type: FormControlMultipurposeEnum.ItemType.STRING
		}
	];
}
