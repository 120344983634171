import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleDetailStateAction, ArticleDetailActionEnum } from './article-detail.actions';
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { ArticleState } from '../../model/state/article-list-state.model';

export namespace ArticleDetailStateReducer {
	export const initialState: BaseStateModel<ArticleState, ArticleListFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(ArticleDetailStateAction.loadSingleWithCode, state => state),
		on(ArticleDetailStateAction.update, (state, articleDetail) => articleDetail),
		on(ArticleDetailStateAction.reset, () => initialState),
		on(ArticleDetailStateAction.error, (state, articleDetail) => ({
			data: state ? state.data : null,
			type: articleDetail.type
		}))
	);

	export function reducer(state: BaseStateModel<ArticleState>, action: TypedAction<ArticleDetailActionEnum>) {
		return _reducer(state, action);
	}
}
