import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
	ContextApplicationItemCodeEnum,
	UserDetailModel,
	UserTypeContextModel,
	BaseStateModel
} from '@saep-ict/angular-core';
import {
	ContextType,
	UtilGuardService,
	PermissionTypeList
} from '../../../service/guard/util-guard/util-guard.service';
import { AuthService } from '../../../service/rest/auth.service';
import { StateFeature } from '../../../state';
import { UtilTranslateService } from '../../../service/util/util-translate.service';
import { Router } from '@angular/router';
import { PATH_URL, ROUTE_URL } from '../../../router/route-naming';
import { UtilFontsizeService } from '../../../service/util/util-fontsize.service';
import { CustomerAppConfig } from '../../../customer-app.config';
import { UtilService } from '@saep-ict/angular-core';

@Component({
	selector: 'ms-toolbar-user-button',
	templateUrl: './toolbar-user-button.component.html',
	styleUrls: ['./toolbar-user-button.component.scss'],
	providers: [UtilTranslateService]
})
export class ToolbarUserButtonComponent implements OnInit {
	@Input() openUp = false;

	panelOpenState = false;
	isOpen: boolean;
	languages = this.translate.getLangs();
	langSelected: string = this.translate.currentLang;
	userInfo: string;

	contextState$: Observable<BaseStateModel<ContextType>>;
	contextState: ContextType;
	currentContext: UserTypeContextModel;
	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	userRole: string;

	ROUTE_URL = ROUTE_URL;
	PATH_URL = PATH_URL;

	/**
	 * Serve a indicare se in almeno in un contesto ho più codici
	 */
	get hasMultipleContextCode() {
		return this.user.context_application_list.some(
			context_application => context_application.context_code_list.length > 1
		);
	}

	constructor(
		public authService: AuthService,
		public translate: TranslateService,
		private store: Store<any>,
		private utilGuardService: UtilGuardService,
		private utilTranslateService: UtilTranslateService,
		public router: Router,
		public utilFontsizeService: UtilFontsizeService,
		public appConfig: CustomerAppConfig,
		private utilService: UtilService
	) {
		this.retrieveUserContext();
		this.setUserInfo();
	}

	ngOnInit() {}

	retrieveUserContext() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.currentContext = this.utilGuardService.retrieveContextPermission(
			this.user.current_permission.context_application
		);
		// TODO: rivedi
		// !== ContextApplicationItemCodeEnum.BACKOFFICE
		if (this.user && this.user.current_permission && this.user.current_permission.context_application) {
			this.contextState$ = this.store.select(this.currentContext.state);
			this.contextState$.pipe(take(1)).subscribe(res => {
				this.contextState = res ? res.data : null;
			});
		}
	}

	setUserInfo() {
		const contextApplication = PermissionTypeList.typeList.find(
			item => item.type === this.user.current_permission.context_application
		);

		// User Role non visibile per il contesto b2c
		if (contextApplication && contextApplication.type !== ContextApplicationItemCodeEnum.B2C) {
			this.userRole = contextApplication.description;
		}

		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				if (!this.contextState[0]) {
					this.userInfo = `${this.user.username}`;
				} else {
					this.userInfo = `${this.contextState[0]['code_item']} ${
						this.contextState[0]['description'] ? ' - ' + this.contextState[0]['description'] : ''
					}`;
				}
				break;
			case ContextApplicationItemCodeEnum.AGENT:
				this.userInfo = `${this.contextState['code_item']} ${
					this.contextState['description'] ? ' - ' + this.contextState['description'] : ''
				}`;
				break;
			case ContextApplicationItemCodeEnum.B2B:
				this.userInfo = `${this.contextState['code_erp']} - ${this.contextState['business_name']}`;
				break;
			default:
				this.userInfo = this.utilService.getUserNameFormatted(this.user) || this.user.username;

				break;
		}
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	onClickOutside() {
		this.isOpen = false;
	}

	langChange(e: MatSelectChange) {
		this.utilTranslateService.setLanguage(e.value, true);
	}
}
