<footer class="footer">
	<div class="container">
		<div class="row">
			<div class="col-md-6 col-lg-3 footer__logo">
				<div class="logo-container"></div>
			</div>
			<div class="col-md-6 col-lg-6 footer__info">
				<div class="footer__info__wrapper">
					<span class="label">
						{{ 'contact.field.phone' | translate | sentencecase }}
					</span>
					<span>
						{{ saepIctReferences.SAEP_ICT_PHONE }}
					</span>
					<span class="label"> {{ 'contact.field.email' | translate | sentencecase }} </span
					><span>
						<a href="mailto:info@saep-ict.it">
							{{ saepIctReferences.SAEP_ICT_EMAIL }}
						</a>
					</span>
				</div>
			</div>
			<div class="col-md-6 col-lg-3 footer__copyright">
				<span>
					{{
						'footer.field.copyright'
							| translate: { year: currentYear, company_name: saepIctReferences.SAEP_ICT_NAME_LONG }
							| sentencecase
					}}
				</span>
			</div>

			<div class="col-md-6 col-lg-3 footer__bio">
				<p>
					{{ 'footer.field.text' | translate | sentencecase }}
				</p>
			</div>
			<div class="col-md-4 col-lg-3 footer__link">
				<app-unordered-list
					[title]="'footer.field.products' | translate | sentencecase"
					[items]="linkCategoryList"
				></app-unordered-list>
			</div>
			<div class="col-md-4 col-lg-3 footer__link">
				<app-unordered-list
					[title]="'footer.field.info' | translate | sentencecase"
					[items]="linkInfoList"
				></app-unordered-list>
			</div>
			<div class="col-md-4 col-lg-3 footer__payments">
				<p>{{ 'footer.field.payment.methods' | translate | sentencecase }}</p>
				<img
					src="/assets/img/metodi-pagamento/cc-mastercard.svg"
					[alt]="'footer.field.payment.mastercard' | translate | sentencecase"
					[title]="'footer.field.payment.mastercard' | translate | sentencecase"
					class="footer__payments__logo"
				/>
				<img
					src="/assets/img/metodi-pagamento/cc-paypal.svg"
					[alt]="'footer.field.payment.paypal' | translate | sentencecase"
					[title]="'footer.field.payment.paypal' | translate | sentencecase"
					class="footer__payments__logo"
				/>
				<img
					src="/assets/img/metodi-pagamento/cc-visa.svg"
					[alt]="'footer.field.payment.visa' | translate | sentencecase"
					[title]="'footer.field.payment.visa' | translate | sentencecase"
					class="footer__payments__logo"
				/>
			</div>
		</div>
	</div>

	<div class="footer__credits">
		<div class="container">
			<div class="row">
				<div class="col-md-6 footer__credits__version">
					<span>
						{{ appTag }}
					</span>
				</div>
				<div class="col-md-6 footer__credits__author">
					<span>
						{{ 'footer.powered_by' | translate | sentencecase }}
						<a href="https://www.saep-ict.it/" target="_blank">{{
							saepIctReferences.SAEP_ICT_NAME | uppercase
						}}</a>
					</span>
				</div>
			</div>
		</div>
	</div>
</footer>
