<div class="checkout" *ngIf="order && article.ordered_quantity && article.articleDetail">
	<!-- Header -->
	<div class="checkout__header">
		<!-- <span class="checkout__header__status" *ngIf="article.articleDetail.stock">
			<div class="chips-mini">
				<span
					[ngClass]="ArticleStock[article.articleDetail.stock.code_segnalation]"
					[matTooltip]="article.articleDetail.stock.description_segnalation"
				></span>
			</div>
		</span> -->
		<h4 class="checkout__header__description">
			{{ article.code }}{{ getArticleDescription() }}
			<br />
			<small>{{ article.articleDetail.description_add }}</small>
		</h4>
		<span class="checkout__header__actions">
			<!-- modifica data consegna -->
			<button
				*ngIf="
					user.current_permission.permission.indexOf(extendedPermissionKeyEnum.BACKOFFICE_ORDER_DELAY) > -1
				"
				mat-icon-button
				color="accent"
				[ngClass]="'opacity-50'"
				(click)="articleDateChange($event, article)"
				[matTooltip]="'general.delivery_date.edit' | translate | sentencecase"
			>
				<mat-icon>calendar_today</mat-icon>
			</button>
			<!-- ticket-center -->
			<button
				mat-icon-button
				color="accent"
				*ngIf="
					user.current_permission.context_application !== contextApplicationItemCodeEnum.BACKOFFICE &&
					order.header.status !== OrderStatusEnum.DRAFT
				"
				(click)="createNewTicket()"
				[matTooltip]="'ticket.report' | translate | sentencecase"
			>
				<mat-icon>support_agent</mat-icon>
			</button>
			<!-- Kit -->
			<span
				class="product-kit"
				*ngIf="
					article.articleDetail.art_kit === 'S' &&
					article.articleDetail.articleKitList &&
					article.articleDetail.articleKitList.length
				"
			>
				<button
					mat-icon-button
					aria-label="Icon to open dialog with kit products"
					(click)="utilArticleKitService.openDialogKit(article.articleDetail)"
					[matTooltip]="'article.kit.show' | translate | sentencecase"
				>
					<mat-icon>shopping_basket</mat-icon>
				</button>
			</span>
			<!-- disegno -->
			<button
				mat-icon-button
				color="accent"
				*ngIf="order.header.status !== OrderStatusEnum.DRAFT"
				[ngClass]="article.articleDocumentLink.length ? null : 'opacity-50'"
				(click)="showDrawings($event, article)"
				[matTooltip]="'article.drawing.download' | translate | sentencecase"
			>
				<mat-icon>architecture</mat-icon>
			</button>
			<!-- note -->
			<button
				mat-icon-button
				color="accent"
				*ngIf="order.header.status === OrderStatusEnum.DRAFT || article.note_order"
				[ngClass]="article.note_order ? null : 'opacity-50'"
				(click)="articleNoteChange($event, article)"
				[matTooltip]="(article.note_order ? 'comment.read' : 'comment.add') | translate | sentencecase"
			>
				<mat-icon>{{ article.note_order ? 'comment' : 'add_comment' }}</mat-icon>
			</button>
			<!-- delete -->
			<button
				mat-icon-button
				color="warn"
				*ngIf="order.header.status === OrderStatusEnum.DRAFT"
				(click)="articleChange($event, article, 'delete')"
				[matTooltip]="'product.remove' | translate | sentencecase"
			>
				<mat-icon>clear</mat-icon>
			</button>
		</span>
	</div>

	<!-- Main data -->
	<div class="checkout__content checkout__content--gray">
		<span class="checkout__content__item">
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'article.field.client_ref' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two">
				<p>{{ article.client_ref }}</p>
			</span>
		</span>
		<!-- <span class="checkout__content__item">
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'article.field.price_list' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two">
				<p>{{ article.articleDetail.articlePrice.price | currency: 'EUR':'symbol':'':'it' }}</p>
				<p>
					<small class="cell-subtitle">cad.</small>
					<small class="cell-subtitle d-block" [innerHTML]="retrieveArticlePrice(article)"> </small>
				</p>
			</span>
		</span> -->
		<!-- <span class="checkout__content__item">
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'order.field.discount' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two">
				<ng-container *ngIf="article.articleDetail.articlePrice.discount.length > 0; else noDiscount">
					<ng-container *ngFor="let discount of article.articleDetail.articlePrice.discount; let i = index">
						<span>{{ discount.value }}%</span>
						<span *ngIf="i !== article.articleDetail.articlePrice.discount.length - 1"> + </span>
					</ng-container>
				</ng-container>
				<ng-template #noDiscount>
					<span>0%</span>
				</ng-template>
			</span>
		</span> -->

		<!-- Extra discount -->
		<!-- <span
			class="checkout__content__item"
			*ngIf="
				configurationCustomerFunctionService.isPropertyInConfigurationCustomer(
					'discount_agent',
					configurationCustomerArticleDiscountProperty
				)
			"
		>
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'order.field.discount_extra' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two numeric">
				<div class="suffix-percentage">
					<input
						appPercentInput
						placeholder="0"
						[value]="article.discount_agent ? article.discount_agent.value : null"
						(input)="articleChange($event, article, 'discount_agent')"
						[disabled]="!canEditOrderProduct"
						stopPropagation
					/>
				</div>
			</span>
		</span> -->

		<!-- last price -->
		<!-- <span
			class="checkout__content__item"
			*ngIf="
				configurationCustomerFunctionService.isPropertyInConfigurationCustomer(
					'last_price',
					configurationCustomerArticlePriceProperty
				)
			"
		>
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'order.field.net_price' | translate | sentencecase }}
				</p>
			</span> -->
		<!-- <span class="line-two numeric">
				<input
					appOnlyNumbers
					decimals="2"
					placeholder="0"
					[value]="article.price ? article.price : null"
					(input)="articleChange($event, article, 'last_price')"
					[disabled]="!canEditOrderProduct"
					stopPropagation
				/>
				<small class="cell-subtitle" [innerHTML]="'Box da ' + article.articleDetail.qty_box + ' articoli'">
				</small>
			</span> -->
		<!-- </span> -->

		<!-- quantità -->
		<span class="checkout__content__item">
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'order.variation.product.ordered_quantity' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two">
				<!-- <input
					appOnlyNumbers
					placeholder="0"
					[value]="article.ordered_quantity ? article.ordered_quantity : null"
					(input)="+$event.data !== article.ordered_quantity ? articleChange($event, article, 'qty') : null"
					[disabled]="!canEditOrderProduct"
					stopPropagation
				/> -->
				{{ article.ordered_quantity }}
				{{
					(article.ordered_quantity > 1 ? 'article.piece_plural' : 'article.piece') | translate | sentencecase
				}}
			</span>
			<small class="cell-subtitle"
				>{{ 'article.field.qty_shipped' | translate | sentencecase }}: {{ article.qty_shipped || 0 }}</small
			>
		</span>

		<!-- input dicount fields - free sample -->
		<!-- <span
			class="checkout__content__item"
			*ngIf="
				configurationCustomerFunctionService.isPropertyInConfigurationCustomer(
					'qty_free',
					configurationCustomerArticleDiscountProperty
				)
			"
		>
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'order.field.free_sample' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two numeric">
				<input
					appOnlyNumbers
					placeholder="0"
					[value]="article.qty_free ? article.qty_free : null"
					(input)="+$event.data !== article.qty_free ? articleChange($event, article, 'qty_free') : null"
					[disabled]="!canEditOrderProduct || article.articleDetail.free_sample === 'S'"
					stopPropagation
				/>
			</span>
		</span> -->

		<span class="checkout__content__item">
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'article.field.scheduled_delivery' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two">
				<p>{{ article.delivery_date | date: 'dd/MM/yyyy' }}</p>
			</span>
			<small class="cell-subtitle" *ngIf="article.delay_date">
				{{ 'article.action.date_delivery_new' | translate | sentencecase }}:
				{{ article.delay_date | date: 'dd/MM/yyyy' }}
			</small>
		</span>

		<span class="checkout__content__item">
			<span class="line-one">
				<p class="checkout__content__item__title">
					{{ 'article.field.progress' | translate | sentencecase }}
				</p>
			</span>
			<span class="line-two">
				<p>{{ 'order.status.' + article.status | translate | sentencecase }}</p>
			</span>
		</span>

		<!-- <span class="checkout__content__item">
			<span class="line-one"> </span>
			<span class="line-two numeric">
				<p>
					{{
						(article.ordered_quantity ? article.articleDetail.calculate_price : 0)
							| currency: 'EUR':'symbol':'':'it'
					}}
				</p>
				<p>
					<small class="cell-subtitle">
						{{ 'order.field.good_price' | translate | sentencecase }}
					</small>
				</p>
			</span>
		</span> -->
	</div>
	<!-- Footer -->
	<!-- <div class="checkout__footer">
		<p>
			{{ 'order.field.good_price' | translate | sentencecase }}:
			{{ utilOrderService.articleTotalCount(article) | currency: 'EUR':'symbol':'':'it' }}
		</p>
	</div> -->

	<!-- Alert partially-fulfilled orders  -->
	<!-- <alert
		type="warning"
		*ngIf="
			article && article.qty_shipped && article.ordered_quantity && article.qty_shipped < article.ordered_quantity
		"
	>
		{{
			'order.checkout.alert'
				| translate
					: { amount: article.ordered_quantity - article.qty_shipped, date: evasionDate | date: 'dd/MM/yyyy' }
		}}
	</alert> -->
</div>
