import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { CrmUserListStateReducer } from "./crm-user-list.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('crm-user-list', CrmUserListStateReducer.reducer)
    ]
})
export class CrmUserListStateModule{}
