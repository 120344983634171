import { BaseTableListService } from '@saep-ict/angular-core';
import { CurrencyPipe } from '@angular/common';
import { DateMomentService } from '@saep-ict/angular-core';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderListColumnService extends BaseTableListService {
	constructor(private currencyPipe: CurrencyPipe, private dateMomentService: DateMomentService) {
		super();
	}

	_columns = [
		{
			name: 'status',
			label: '',
			width: 50
		},
		{
			name: 'id',
			label: 'Order #'
		},
		{
			name: 'address',
			label: 'Indirizzo'
		},
		{
			name: 'date_creation',
			label: 'Creazione',
			numeric: true,
			format: (v: string) => this.dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		},
		{
			name: 'date_delivery',
			label: 'Consegna',
			numeric: true,
			format: (v: string) => this.dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		},
		{
			name: 'total',
			label: 'Totale',
			width: 150,
			numeric: true,
			format: (v: number) => this.currencyPipe.transform(v, 'EUR', 'symbol', '', 'it')
		}
	];
}
