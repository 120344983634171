<ng-container *ngFor="let branch of articleCheckoutTree">
    <!-- <div class="mat-h{{level}}">{{ branch.description }}</div> -->
    <article-checkout-item
        *ngFor="let article of branch.article_list"
        [article]="article"
        [order]="order"
        (articleChangeEmitter)="articleChangeEmitter.emit($event)"
    >
    </article-checkout-item>
    <article-checkout-tree
        [articleCheckoutTree]="branch.category_list"
        [level]="level+1"
        [order]="order"
        (articleChangeEmitter)="articleChangeEmitter.emit($event)"
    >
    </article-checkout-tree>
</ng-container>