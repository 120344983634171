import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { UserManagementStateAction } from '../../../state/backoffice/user-management/user-management.actions';

// widget & utility
import { UserManagementListColumnService } from '../../../service/td-data-table/implementation/backoffice/user-management-list.service';
import {
	ITdDataTableRowClickEvent,
	TdDataTableComponent,
	ITdDataTableSortChangeEvent,
	TdDataTableSortingOrder
} from '@covalent/core/data-table';
import { IPageChangeEvent } from '@covalent/core/paging';
import { Observable } from 'rxjs';
import { skipWhile, map } from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';
import { contextTypeAndApplicationLink } from '../../../constants/configuration-customer/context-application/context-application.constants';
import { SubscribeManagerService, BaseStateModel, UserDetailModel, ListWrapperComponent } from '@saep-ict/angular-core';
import { ConfigurationCustomerUser } from '../../../constants/configuration-customer/structure.constants';

@Component({
	selector: 'user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.scss'],
	providers: [SubscribeManagerService]
})
export class UserManagementComponent implements OnInit, OnDestroy {
	@ViewChild('listWrapper') public listWrapper: ListWrapperComponent;
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	userManagementState$: Observable<BaseStateModel<UserDetailModel[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	public userManagementState: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel> = {
		data: [],
		dataSetting: {
			pagination: {
				page_current: 1,
				page_size: 10
			},
			appliedFilter: {
				text: null,
				context_code: null
			},
			sort: [{ ['username']: 'asc' }]
		}
	};

	contextTypeAndApplicationLink = contextTypeAndApplicationLink;

	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		public router: Router,
		public route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private store: Store<any>,
		public userManagementListColumnService: UserManagementListColumnService,
		public permissionUtilService: PermissionUtilService
	) {
		this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'user-management');

		this.createForm();
		this.handleColumnVisibility();
		this.loadUserList();
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(UserManagementStateAction.reset());
	}

	subscribeUserManagementState() {
		return this.userManagementState$.pipe(
			skipWhile((state: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) => !(state && state.data)),
			map((state: BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>) => {
				this.userManagementState = state;
				if (this.dataTable) {
					this.dataTable.refresh();
				}
			})
		);
	}

	// load
	loadUserList() {
		this.store.dispatch(ConfigurationCustomerUser.store_action.load_list(this.userManagementState));
	}

	// form
	createForm() {
		this.form = this.fb.group({
			context_code: null
		});
	}

	getFormFieldValue(key: string) {
		return this.form.get(key).value;
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = {
			context_code: formModel.context_code as string
		};
		return saveForm;
	}

	resetFilters() {
		this.form.reset();
		this.onFormSubmit();
	}

	onFormSubmit() {
		if (this.form.valid) {
			const formModel = this.prepareSaveForm();
			this.userManagementState.dataSetting.appliedFilter.context_code = formModel.context_code;
			this.loadUserList();
		}
	}

	// navigation
	goToDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([e.row.id], { relativeTo: this.route });
	}

	sortChange(e: ITdDataTableSortChangeEvent) {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				[e.name]: e.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc'
			}
		];
		this.userManagementState.dataSetting.sort = sort;
		this.loadUserList();
	}

	// widget & utility
	toggleColumn(e) {
		for (let i = 0; i < this.userManagementListColumnService.columns.length; i++) {
			if (this.userManagementListColumnService.columns[i].name === e.name) {
				this.userManagementListColumnService.columns[i].hidden = e.hidden;
			}
		}
		this.dataTable.refresh();
	}

	changePageSize(size: number) {
		if (size) {
			this.userManagementState.dataSetting.pagination.page_size = size;
			this.loadUserList();
		}
	}

	paginationChange(pagination: IPageChangeEvent) {
		if (pagination) {
			this.userManagementState.dataSetting.pagination.page_current = pagination.page;
			this.loadUserList();
		}
	}

	handleColumnVisibility() {
		this.userManagementListColumnService.columns.map(col => {
			col.hidden = ['full_name'].includes(col.name);
		});
	}
}
