<app-page-header [title]="searchTerm" [breadcrumbsList]="['home', 'risultati di ricerca']"></app-page-header>

<section class="filter-simple">
	<div class="container">
		<div class="row">
			<div class="col-12 filter-simple__viewtype">
				<app-viewtype-toggle
					[viewTypeDefault]="viewType"
					(changingViewList)="viewType = $event"
				></app-viewtype-toggle>
			</div>
		</div>
	</div>
</section>

<products-list [list]="list" [viewType]="viewType"></products-list>

<app-call-to-action
	[callToActionConfig]="callToActionConfig"
	(clickButton)="clickCallToAction()"
></app-call-to-action>
