<div class="row">
	<div class="col-md-12">
		<h2 mat-dialog-title>
			{{ 'context_code.associate_x_existent' | translate: { context_code: data.description } }}
		</h2>
	</div>
</div>

<mat-dialog-content>
	<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
	<mat-toolbar>
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<td-search-box
				*ngIf="
					localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText
				"
				backIcon="search"
				[(ngModel)]="localListHandlerData.filters.localSearchText.value"
				[showUnderline]="true"
				[debounce]="500"
				[alwaysVisible]="false"
				(searchDebounce)="localListHandlerApplyFilter()"
			>
			</td-search-box>
		</mat-toolbar-row>
	</mat-toolbar>

	<ng-container
		*ngIf="
			localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
			else noData
		"
	>
		<td-data-table
			#dataTable
			[data]="localListHandlerData.dataSubset"
			[columns]="permissionContextListColumnService.columns"
			[clickable]="true"
			[sortable]="true"
			[sortBy]="localListHandlerData.sort.name"
			[sortOrder]="localListHandlerData.sort.order"
			(sortChange)="localListHandlerApplyFilter({ sort: $event })"
			(rowClick)="selectCode($event.row)"
		>
			<ng-template tdDataTableTemplate="description" let-row="row">
				{{ row.description ? row.description : row.business_name }}
			</ng-template>
		</td-data-table>
		<mat-paginator
			#matPaginator
			(page)="localListHandlerApplyFilter({ pagination: $event })"
			[length]="localListHandlerData.pagination.totalFilteredItemCount"
			[pageSize]="localListHandlerData.pagination.pageSize"
			[pageSizeOptions]="[5, 10, 25, 100]"
		>
		</mat-paginator>
	</ng-container>

	<ng-template #noData>
		<p class="placeholder" *ngIf="!isLoading">
			{{ 'general.no_results' | translate | sentencecase }}
		</p>
	</ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-stroked-button mat-dialog-close type="button" color="primary">
		{{ 'general.cancel' | translate | sentencecase }}
	</button>
</mat-dialog-actions>
