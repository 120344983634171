import { Injectable } from '@angular/core';
import { OpportunityPouchModel } from '../../model/state/opportunity-state.model';

@Injectable({
	providedIn: 'root'
})
export class UtilOpportunityService {
	addOpportunityId(opportunity: OpportunityPouchModel): OpportunityPouchModel {
		throw new Error('Method not implemented.');
	}
}
