import { SlicePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseState,
	BaseStateModel,
	SentencecasePipe,
	SubscribeManagerService,
	ThousandPipe,
	UserDetailModel
} from '@saep-ict/angular-core';
import * as _ from 'lodash';
import moment from 'moment';
import { merge, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { OpportunityStep } from '../../../../../constants/opportunity-step.constants';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import {
	OpportunityPouchModel,
	OpportunityStatusCodeEnum,
	OpportunityTypeEnum
} from '../../../../../model/state/opportunity-state.model';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';
import { ROUTE_URL } from '../../../../../router/route-naming';
import { MockOpportunityService } from '../../../../../service/mock/mock-opportunity.service';
import { UtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../../state';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../../state/opportunity/opportunity.actions';
import { DialogSelectAssegneeComponent } from '../../../../../widget/dialog/dialog-select-assegnee/dialog-select-assegnee.component';
import { DialogSelectOrganizationComponent } from '../../../../../widget/dialog/dialog-select-organization/dialog-select-organization.component';
import { DialogCloseOpportunityComponent } from '../../../../../widget/dialog/dialog-close-opportunity/dialog-close-opportunity.component';

@Component({
	selector: 'opportunity-detail-main',
	templateUrl: './opportunity-detail-main.component.html',
	styleUrls: ['./opportunity-detail-main.component.scss'],
	providers: [SubscribeManagerService, SentencecasePipe, SlicePipe]
})
export class OpportunityDetailMainComponent implements OnInit, OnDestroy {
	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	idOpportunity: string;
	opportunityType: string;

	selectedOrganization: OrganizationStateModel;
	selectedAssegnee: UserDetailModel;
	referentList: ContactPouchModel[] = [];

	form: FormGroup;

	public opportunityTypeEnum = OpportunityTypeEnum;
	public opportunityStatusCodeEnum = OpportunityStatusCodeEnum;

	get opportunityStatusCode() {
		return Object.values(OpportunityStatusCodeEnum);
	}

	stepList = new OpportunityStep().stepList;

	constructor(
		private store: Store<any>,
		public route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private dialog: MatDialog,
		public translate: TranslateService,
		private subscribeManagerService: SubscribeManagerService,
		public mockOpportunityService: MockOpportunityService,
		private utilService: UtilService,
		private thousandPipe: ThousandPipe,
		private slicePipe: SlicePipe
	) {}

	ngOnInit() {
		// retrieve opportunity information
		this.opportunityType = `opportunity_${this.route.parent.snapshot.paramMap.get('opportunityType')}`;
		this.idOpportunity = `${this.route.parent.snapshot.paramMap.get('idOpportunity')}`;

		this.createForm();

		// subscribe
		this.subscribeManagerService.populate(
			this.subscribeCurrencyFields().subscribe(),
			'dimension-visible-total-form-change'
		);
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');

		// populate form if not new user
		if (this.idOpportunity.replace('opportunity_', '') !== ROUTE_URL.newOpportunity) {
			this.store.dispatch(OpportunityStateAction.load({ data: { id: this.idOpportunity } }));
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// Reset dello state opportunity nel componente padre
	}

	/**
	 * Form
	 */
	createForm() {
		this.form = this.fb.group({
			origin_name: ['', { validators: [] }],
			id: ['', { validators: [] }],
			title: ['', { validators: [Validators.required] }],
			actual_value: ['', { validators: [] }],
			topic: ['', { validators: [] }],
			budget_amount: ['', { validators: [] }],
			organization: ['', { validators: [Validators.required] }],
			contact: ['', { validators: [] }],
			assegnee: ['', { validators: [] }],
			estimated_close_date: ['', { validators: [Validators.required] }],
			schedule_followup_prospect: ['', { validators: [] }],
			project_interest: ['', { validators: [Validators.min(0), Validators.max(100)] }],
			close_probability: ['', { validators: [Validators.min(0), Validators.max(100)] }],
			step: ['', { validators: [] }]
		});

		this.disableFieldList(['id', 'origin_name']);

		if (this.idOpportunity === ROUTE_URL.newOpportunity) {
			this.populateDefaultData();
		}
	}

	populateDefaultData() {
		this.form.patchValue(
			{
				origin_name: 'Creato da operatore',
				estimated_close_date: moment().add(1, 'M')
			},
			{ emitEvent: false }
		);
	}

	disableFieldList(fieldList: string[]) {
		fieldList.forEach(nameField => {
			this.form.get(nameField).disable({ emitEvent: false });
		});
	}

	setFormFromRemoteData() {
		let contact: ContactPouchModel = null;

		if (this.opportunity.organization) {
			this.selectedOrganization = this.opportunity.organization;
			this.referentList = this.opportunity.organization.contact_list;

			if (this.selectedOrganization.contact_list && this.opportunity.contact_id) {
				contact = this.selectedOrganization.contact_list.find(
					contact => contact._id === this.opportunity.contact_id
				);
			}
		}

		// Compongo assegnee name
		let assegneeName = '';
		if (this.opportunity.assegnee_user_name) {
			assegneeName = `${this.opportunity.assegnee_user_name} - ${this.opportunity.assegnee_full_name}`;
		}

		this.form.patchValue(
			{
				origin_name: this.opportunity.origin_name,
				id: this.getFormattedId(this.opportunity._id || ''),
				title: this.opportunity.title,
				actual_value: this.opportunity.actual_value ? this.formatCurrency(this.opportunity.actual_value) : null,
				topic: this.opportunity.topic,
				budget_amount: this.opportunity.budget_amount
					? this.formatCurrency(this.opportunity.budget_amount)
					: null,
				organization: this.opportunity.organization.business_name,
				contact: this.opportunity.contact_id ? contact : null,
				assegnee: assegneeName,
				estimated_close_date: this.opportunity.estimated_close_date
					? moment(this.opportunity.estimated_close_date)
					: null,
				schedule_followup_prospect: this.opportunity.schedule_followup_prospect
					? moment(this.opportunity.schedule_followup_prospect)
					: null,
				project_interest: this.opportunity.project_interest,
				close_probability: this.opportunity.close_probability,
				step: this.opportunity.step_id ? this.opportunity.step_id : null
			},
			{ emitEvent: false }
		);

		// Disattivo il form se la opportunity è !OPEN
		if (this.opportunity.status_code !== OpportunityStatusCodeEnum.Open) {
			this.form.disable({ emitEvent: false });
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.form.value.type = this.opportunityType;
			this.store.dispatch(OpportunityStateAction.save(new BaseState([this.prepareSaveForm()])));
		} else {
			this.utilService.showDialog('general.save_missing_data');
		}
	}

	prepareSaveForm() {
		const formValue = this.form.getRawValue();

		// initialization
		let newOpportunity: OpportunityPouchModel = this.opportunity
			? _.cloneDeep(this.opportunity)
			: <OpportunityPouchModel>{};

		newOpportunity.created_on = newOpportunity.created_on || moment().valueOf();
		newOpportunity.origin_name = formValue.origin_name ? formValue.origin_name : null;
		newOpportunity.title = formValue.title ? formValue.title : null;
		newOpportunity.actual_value = formValue.actual_value ? this.parseCurrency(formValue.actual_value) : null;
		newOpportunity.topic = formValue.topic ? formValue.topic : null;
		newOpportunity.budget_amount = formValue.budget_amount ? this.parseCurrency(formValue.budget_amount) : null;
		newOpportunity.estimated_close_date = formValue.estimated_close_date
			? formValue.estimated_close_date.valueOf()
			: null;
		newOpportunity.schedule_followup_prospect = formValue.schedule_followup_prospect
			? formValue.schedule_followup_prospect.valueOf()
			: null;
		newOpportunity.project_interest = formValue.project_interest ? formValue.project_interest : null;
		newOpportunity.close_probability = formValue.close_probability ? formValue.close_probability : null;

		newOpportunity.status_code = newOpportunity.status_code || OpportunityStatusCodeEnum.Open;

		// organization
		if (this.selectedOrganization) {
			newOpportunity.organization = this.selectedOrganization;
		}

		// contact
		newOpportunity.contact_id = formValue.contact ? formValue.contact._id : null;
		newOpportunity.contact_name = formValue.contact ? formValue.contact.full_name : null;

		// assegnee
		if (this.selectedAssegnee) {
			newOpportunity.assegnee_user_name = this.selectedAssegnee.username || null;
			newOpportunity.assegnee_user_id = this.selectedAssegnee.id || null;
			newOpportunity.assegnee_avatar = this.selectedAssegnee.avatar || null;
			newOpportunity.assegnee_full_name = `${this.utilService.getUserNameFormatted(this.selectedAssegnee)}`;
		}

		// step
		newOpportunity.step_id = formValue.step ? formValue.step : null;
		// newOpportunity.step_name = formValue.step ? formValue.step.title : null;

		// type
		newOpportunity.type = this.opportunityType;

		// return
		this.utilService.deleteEmptyProperties(newOpportunity);
		console.log(newOpportunity);
		return newOpportunity;
	}

	updateFormFieldValue(key: string, value: any) {
		const formField = {};
		formField[key] = _.cloneDeep(value);
		this.form.patchValue(formField);
	}

	/**
	 * Subscription
	 */

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			skipWhile(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						// Se salvo la prima volta faccio il redirect per aggioranre l'url con il nuovo id
						if ((!this.opportunity || !this.opportunity._id) && opportunityState.data[0]._id) {
							this.router.navigate([
								this.router.url.replace(ROUTE_URL.newOpportunity, opportunityState.data[0]._id)
							]);
						}

						// Se cambia la tipologia di opportunity cambio anche il param
						if (this.opportunity && this.opportunity.type !== opportunityState.data[0].type) {
							const currentType = this.opportunity.type.replace('opportunity_', '');
							const newType = opportunityState.data[0].type.replace('opportunity_', '');
							this.opportunityType = opportunityState.data[0].type;
							this.router.navigate([this.router.url.replace(currentType, newType)]);
						}

						this.opportunity = opportunityState.data[0];
						this.setFormFromRemoteData();
						break;

					case OpportunityActionEnum.ERROR:
						this.utilService.showDialog('opportunity.save_error');
						break;
				}
			})
		);
	}

	subscribeCurrencyFields() {
		const subscribeKey: string[] = ['budget_amount', 'actual_value'];
		const subscribe: Observable<number>[] = [];
		subscribeKey.forEach(i => {
			subscribe.push(this.form.get(i).valueChanges.pipe());
		});
		return merge(...subscribe).pipe(
			skipWhile(data => !data),
			map(data => {
				subscribeKey.forEach(i => {
					const value = this.form.get(i).value;
					if (value) {
						// Converto la stringa in numero non formattato
						let formattedValue: any = this.parseCurrency(value);
						// Formatto con thousandPipe
						formattedValue = this.thousandPipe.transform(formattedValue);
						// Setto il valore
						this.form.get(i).setValue(formattedValue, { emitEvent: false });
					}
				});
			})
		);
	}

	/**
	 * Dialog
	 */

	openDialogSelectOrganization() {
		const dialogRef: MatDialogRef<DialogSelectOrganizationComponent> = this.dialog.open(
			DialogSelectOrganizationComponent,
			{
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		);
		dialogRef.afterClosed().subscribe((organization: OrganizationStateModel) => {
			if (organization) {
				console.log(organization);
				// Aggiorno l'organizzazione selezionata
				this.selectedOrganization = organization;
				// Popolo il field del form
				this.updateFormFieldValue('organization', organization.business_name);

				// Aggiorno la lista di referent
				this.referentList = organization.contact_list;
				// Tolgo il riferimento al vecchio valore
				if (this.opportunity) {
					this.opportunity.contact_id = null;
					this.opportunity.contact_name = null;
				}
				// Svuoto il field del form
				this.updateFormFieldValue('contact', null);
			}
		});
	}

	openDialogSelectAssegnee() {
		const dialogRef: MatDialogRef<DialogSelectAssegneeComponent> = this.dialog.open(DialogSelectAssegneeComponent, {
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe((assegnee: UserDetailModel) => {
			if (assegnee) {
				this.selectedAssegnee = assegnee;
				this.updateFormFieldValue(
					'assegnee',
					`${assegnee.username} - ${this.utilService.getUserNameFormatted(assegnee)}`
				);
			}
		});
	}

	openDialogCloseOpportunity() {
		const dialogRef: MatDialogRef<DialogCloseOpportunityComponent> = this.dialog.open(
			DialogCloseOpportunityComponent,
			{
				disableClose: true,
				data: { opportunityType: this.route.parent.snapshot.paramMap.get('opportunityType') }
			}
		);
		dialogRef.afterClosed().subscribe((res: OpportunityStatusCodeEnum) => {
			if (res) {
				const newOpportunity = _.cloneDeep(this.opportunity);
				newOpportunity.status_code = res;
				newOpportunity.close_date = moment().valueOf();
				this.store.dispatch(OpportunityStateAction.save(new BaseState([newOpportunity])));
			}
		});
	}

	/**
	 * Utilities
	 */

	formatCurrency(currency: number): string {
		return this.thousandPipe.transform(currency);
	}

	parseCurrency(currency: string): number {
		// cancello i punti (divisore migliaia)
		currency = currency.replace(/\./g, '');
		// Sostituisco le virgole con i punti
		currency = currency.replace(/\,/g, '.');
		return parseInt(currency, 10);
	}

	selectOption(option: any, value: any): boolean {
		if (value) {
			return option._id === value._id;
		}
		return false;
	}

	resetSelectField(event, controlName: string) {
		this.form.get(controlName).setValue('');
		event.stopPropagation();
	}

	changeStatusOpportunityTo(type: OpportunityTypeEnum) {
		let opportunity: OpportunityPouchModel = _.cloneDeep(this.opportunity);
		opportunity.type = 'opportunity_' + type;
		this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
	}

	/**
	 * Utility function that return last 8 uppercase char of the UUID
	 * @param id
	 * @returns
	 */
	getFormattedId(id: string) {
		return this.slicePipe.transform(id, -8).toUpperCase();
	}
}
