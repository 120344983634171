import { Component, OnInit, Input } from '@angular/core';

export class JumbotronConfig {
  title?: string;
  titleClass?: string;
  labelButton?: string;
  url?: string;
  backgroundImage?: string;
  hasBackdrop?: boolean;
  backdropColor?: string;
}
@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
})
export class JumbotronComponent implements OnInit {
  @Input() jumbotronConf: JumbotronConfig;

  constructor() {}

  ngOnInit() {}
}
