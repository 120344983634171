import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { LanguageStateModel } from '../../model/state/language-state.model';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class LanguageService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/languages'
	})
	getLanguages: IResourceMethodPromise<void, RestBaseResponse<LanguageStateModel[]>>;
}
