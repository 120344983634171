import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseStateModel, UserDetailModel } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
@Component({
	selector: 'media-center',
	templateUrl: './media-center.component.html',
	styleUrls: ['./media-center.component.scss']
})
export class MediaCenterComponent {
	path: string = this.route.snapshot.paramMap.get('path');

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	customHeaders: object;

	constructor(private route: ActivatedRoute, private store: Store<UserDetailModel>) {
		// retrieve user
		this.user$.pipe(take(1)).subscribe((res: BaseStateModel<UserDetailModel>) => {
			if (res && res.data) {
				this.customHeaders = {
					'context-code': res.data.current_permission.context_code.code,
					'context-application': res.data.current_permission.context_application
				};
			}
		});
	}
}
