import { Breakpoints } from '@angular/cdk/layout';
import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { MediaReplayService } from '@saep-ict/angular-core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ViewTypeEnum } from '../../enum/view-type.enum';
import { ICardModel } from '../../model/card.model';
import { ICardSliderConfiguration } from '../card-product/card-slider.models';
import { animations } from './card-slider.animations';
import { SliderItemDirective } from './slider-content.directive';

@Component({
	selector: 'app-card-slider',
	templateUrl: './card-slider.component.html',
	styleUrls: ['./card-slider.component.scss'],
	animations: [animations]
})
export class CardSliderComponent implements OnInit, OnDestroy {
	public viewTypeEnum = ViewTypeEnum;
	private destroySubscriptions: Subject<boolean> = new Subject<boolean>();
	private onDestroy$ = this.destroySubscriptions.asObservable();

	dataConfig: ICardSliderConfiguration<ICardModel[]> = {
		data: [],
		animation: {
			loop: false,
			animationDuration: '.5s',
			easing: 'ease-in'
		},
		medias: { xs: 1, sm: 1, md: 2, lg: 3 }
	};

	@ContentChild(SliderItemDirective, { read: TemplateRef, static: true }) contentTemplate;

	@Input() set config(config: ICardSliderConfiguration) {
		if (!config) {
			console.error('no config passed to slider');
			this.resetDataConfig();
		} else {
			this.dataConfig.data = config.data;
			if (config.medias) {
				this.dataConfig.medias = {
					...this.dataConfig.medias,
					...config.medias
				};
			}
			if (config.animation) {
				this.dataConfig.animation = {
					...this.dataConfig.animation,
					...config.animation
				};
			}
		}
	}

	maxElement: number = 3;
	chuncks: ICardModel[][] = [];
	selectedIndex = 0;

	constructor(private mediaReplayer: MediaReplayService) {}

	private resetDataConfig(): void {
		this.dataConfig = {
			data: [],
			animation: {
				loop: false,
				animationDuration: '.5s',
				easing: 'ease-in'
			},
			medias: { xs: 1, sm: 1, md: 2, lg: 3 }
		};
	}

	private setMediaReplayObs(breakpoint: string, elNumber: number): void {
		this.mediaReplayer
			.matchTo(breakpoint)
			.pipe(
				takeUntil(
					this.onDestroy$.pipe(
						map(res => {
							// console.log('destoyAll');
							return res;
						})
					)
				)
			)
			.subscribe(res => {
				this.changeMaxElement(elNumber);
			});
	}

	ngOnInit() {
		this.setMediaReplayObs(Breakpoints.XSmall, this.dataConfig.medias.xs);
		this.setMediaReplayObs(Breakpoints.Small, this.dataConfig.medias.sm);
		this.setMediaReplayObs(Breakpoints.Medium, this.dataConfig.medias.md);
		this.setMediaReplayObs(Breakpoints.Large, this.dataConfig.medias.lg);

		this.prepareChunks();
	}

	ngOnDestroy() {
		this.destroySubscriptions.next(true);
		this.destroySubscriptions.complete();
	}

	private changeMaxElement(maxEl: number) {
		this.selectedIndex = 0;
		this.maxElement = maxEl;
		this.prepareChunks();
	}

	public prepareChunks(): void {
		if (this.dataConfig.data && this.dataConfig.data.length > 0) {
			this.chuncks = [];
			const chuncksNumber = Math.floor(this.dataConfig.data.length / this.maxElement);
			const rest = this.dataConfig.data.length % this.maxElement;
			for (let i = 0, j = 0; j < chuncksNumber; i += this.maxElement, j++) {
				this.chuncks.push([...this.dataConfig.data.slice(i, i + this.maxElement)]);
			}
			if (rest > 0) {
				this.chuncks.push([
					...this.dataConfig.data.slice(this.dataConfig.data.length - rest, this.dataConfig.data.length)
				]);
			}
		}
	}

	public changeSliderIndex(idx): void {
		if (idx >= 0 && idx < this.chuncks.length) {
			this.selectedIndex = idx;
		} else if (this.dataConfig.animation.loop) {
			this.selectedIndex = idx >= this.chuncks.length ? 0 : this.chuncks.length - 1;
		}
	}
}
