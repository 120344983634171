<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
			[placeholder]="'receipt.search' | translate | sentencecase"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : false"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : false
		"
	>
		<ng-template tdDataTableTemplate="document_type" let-value="value">
			<p>
				{{ 'receipt.document_type.' + value | translate | sentencecase }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="date" let-value="value">
			<p>
				{{ value | date: 'dd/MM/yyyy' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="expiration_date" let-value="value">
			<p>
				{{ value | date: 'dd/MM/yyyy' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="organization" let-value="value">
			<p>
				{{ value.name | titlecase }} <br />
				<small class="cell-subtitle">
					{{ 'company.field.vat_number_short' | translate | uppercase }} {{ value.id }}
				</small>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="outlay" let-value="value">
			{{ value.amount | currency: '':'':'':'it' }} {{ value.currency }}
		</ng-template>
		<ng-template tdDataTableTemplate="download_path" let-value="value">
			<button
				mat-icon-button
				(click)="printReceipt(value)"
				[matTooltip]="'receipt.download' | translate | sentencecase"
				matTooltipPosition="left"
			>
				<mat-icon>download</mat-icon>
			</button>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
