import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BackofficeUserListActionEnum, BackofficeUserListStateAction } from './backoffice-user-list.actions';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { BaseStateModel } from '@saep-ict/angular-core';

export namespace BackofficeUserListStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(BackofficeUserListStateAction.loadList, state => state),
		on(BackofficeUserListStateAction.updateList, (state, param) => param),
		on(BackofficeUserListStateAction.updateItem, (state, param) => param),
		on(BackofficeUserListStateAction.createItemSuccess, (state, param) => param),
		on(BackofficeUserListStateAction.deleteItemSuccess, (state, param) => param),
		on(BackofficeUserListStateAction.reset, () => initialState),
		on(BackofficeUserListStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<BodyTablePouchModel[]>, action: TypedAction<BackofficeUserListActionEnum>) {
		return _reducer(state, action);
	}
}
