import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { AddressPouchModel, OrganizationLevelEnum, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { OrganizationStateModel } from '../../model/state/organization-state.model';
import { UtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { OrganizationActionEnum, OrganizationStateAction } from '../../state/organization/organization.actions';

@Component({
	selector: 'prospect-registration',
	templateUrl: './prospect-registration.component.html',
	styleUrls: ['./prospect-registration.component.scss'],
	providers: [SubscribeManagerService]
})
export class ProspectRegistrationComponent implements OnInit, OnDestroy {
	@Input() isDialog = false;
	@Output() closeDialog = new EventEmitter<boolean>();
	@Output() saveSuccess = new EventEmitter<OrganizationStateModel>();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	form: FormGroup;
	organizationTypeSelected: OrganizationTypeEnum;

	accountCreated: boolean = false;

	public currentContext: ContextApplicationItemCodeEnum = null;
	// enum
	organizationTypeEnum = OrganizationTypeEnum;
	organizationTypeList = Object.values(OrganizationTypeEnum);

	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private fb: FormBuilder,
		public router: Router,
		private store: Store<any>,
		public utilService: UtilService,
		public subscribeManagerService: SubscribeManagerService,
		private cdr: ChangeDetectorRef
	) {
		// User
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
				this.currentContext = this.user.current_permission.context_application;
			}
		});

		this.subscribeManagerService.populate(
			this.subscribeOrganizationState().subscribe(),
			'subscribeOrganizationState'
		);
	}

	ngOnInit() {
		this.createForm();

		if (this.organization && this.organization.organization_type) {
			this.form.get('organization_type').setValue(this.organization.organization_type);
			// con l'assegnazione programmativa non scatta l'evento onchange
			this.organizationTypeChange(this.organization.organization_type);
		}
	}

	createForm() {
		this.form = this.fb.group({
			organization_type: ['']
		});
	}

	// subscribe
	subscribeOrganizationState() {
		return this.organization$.pipe(
			filter((state: BaseStateModel<OrganizationStateModel>) => !!state),
			map((state: BaseStateModel<OrganizationStateModel>) => {
				switch (state.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = state.data;
						break;

					case OrganizationActionEnum.SAVE_SUCCESS:
						this.accountCreated = true;

						if (this.isDialog) {
							this.saveSuccess.emit(state.data);
						}
						break;

					case OrganizationActionEnum.ERROR:
						console.log('error');
						break;
				}
				return true;
			})
		);
	}

	organizationTypeChange(value: OrganizationTypeEnum) {
		this.organizationTypeSelected = value;
		// Disable field (CRM)
		if (this.currentContext === ContextApplicationItemCodeEnum.CRM) {
			this.form.get('organization_type').disable({ emitEvent: false });
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			let newOrganization = this.organization ? _.cloneDeep(this.organization) : <OrganizationStateModel>{};

			const form = this.form.getRawValue();
			const personalDataForm = form.personalData;
			const addressForm = form.address;
			const detailsForm = form.details;
			const userContactInformationForm = form.userContactInformation;

			newOrganization.organization_type = form.organization_type;

			newOrganization.business_name = personalDataForm.businessName
				? personalDataForm.businessName
				: personalDataForm.firstName + ' ' + personalDataForm.lastName;

			// newOrganization.tax_data.sdi_code = personalDataForm.sdiCode ? personalDataForm.sdiCode : null;
			newOrganization.tax_data = {
				sdi_code: personalDataForm.sdiCode ? personalDataForm.sdiCode : null
			};

			// Private
			newOrganization.tax_data.tax_code = personalDataForm.taxCode ? personalDataForm.taxCode : null;
			newOrganization.tax_data.vat_number = personalDataForm.vatNumber ? personalDataForm.vatNumber : null;

			// Validity
			if (newOrganization.valid === false && newOrganization.valid !== null) {
				newOrganization.valid = false;
			} else {
				newOrganization.valid = true;
			}

			switch (this.organizationTypeSelected) {
				case this.organizationTypeEnum.PRIVATE:
					newOrganization.first_name = personalDataForm.firstName ? personalDataForm.firstName : null;
					newOrganization.last_name = personalDataForm.lastName ? personalDataForm.lastName : null;
					break;
				case this.organizationTypeEnum.COMPANY:
					newOrganization['organization_contact_details'] = {
						email: personalDataForm.businessEmail ? personalDataForm.businessEmail : null
					};
					break;
			}

			// ENABLED
			newOrganization.status = newOrganization.status ? newOrganization.status : 'ENABLED';

			// Address
			if (addressForm) {
				const addressFormValues = {
					address: <AddressPouchModel>{
						address: addressForm.address ? addressForm.address : null,
						locality: addressForm.locality ? addressForm.locality : null,
						zip_code: addressForm.zipCode ? addressForm.zipCode : null,
						province: addressForm.province ? addressForm.province : null,
						country: addressForm.country ? addressForm.country : null
					}
				};
				newOrganization = { ...newOrganization, ...addressFormValues };
			}

			// Details
			if (detailsForm) {
				const detailsFormValues = {
					level: detailsForm.status ? detailsForm.status : OrganizationLevelEnum.LEAD,
					date_first_contact: detailsForm.dateFirstContact ? detailsForm.dateFirstContact.valueOf() : null,
					organization_sector: detailsForm.organizationSector ? detailsForm.organizationSector : null,
					revenue_year: detailsForm.revenueYear ? detailsForm.revenueYear : null,
					employees_number: detailsForm.employeesNumber ? detailsForm.employeesNumber : null,
					organization_international_rating: detailsForm.organizationInternationalRating
						? detailsForm.organizationInternationalRating
						: null,
					organization_rating: detailsForm.organizationRating ? detailsForm.organizationRating : null,
					url_website: detailsForm.websiteUrl ? detailsForm.websiteUrl : null,
					url_linkedin: detailsForm.linkedInUrl ? detailsForm.linkedInUrl : null
				};
				newOrganization = { ...newOrganization, ...detailsFormValues };
			}

			// User contact information
			if (userContactInformationForm) {
				const userContactInformationFormValues = {
					email: userContactInformationForm.email ? userContactInformationForm.email : null,
					phone: userContactInformationForm.phone ? userContactInformationForm.phone : null
				};
				if (this.organizationTypeSelected === this.organizationTypeEnum.COMPANY) {
					userContactInformationFormValues['first_name'] = userContactInformationForm.firstName
						? userContactInformationForm.firstName
						: null;
					userContactInformationFormValues['last_name'] = userContactInformationForm.lastName
						? userContactInformationForm.lastName
						: null;
				}
				newOrganization = { ...newOrganization, ...userContactInformationFormValues };
			}

			this.utilService.deleteEmptyProperties(newOrganization);
			console.log('newOrganization: ', newOrganization);
			this.saveOrganization(newOrganization);
		}
	}

	saveOrganization(newOrganization: OrganizationStateModel) {
		if (!this.currentContext) {
			// Contesto pubblico, registrazione nuova organization
			this.store.dispatch(OrganizationStateAction.createItemApi(new BaseState(newOrganization)));
		} else {
			this.store.dispatch(OrganizationStateAction.save(new BaseState(newOrganization)));
		}
	}

	ngOnDestroy() {
		this.accountCreated = false;
		this.subscribeManagerService.destroy();

		// se non c'è l'oggeto organization allora sono in modalità di modifica
		// nel dettaglio della organization e quindi non devo resettare lo state
		if (!this.organization) {
			this.store.dispatch(OrganizationStateAction.reset());
		}
	}
}
