import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TdPagingBarComponent } from '@covalent/core/paging';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { OrganizationPouchModel, OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';
import { ROUTE_URL } from '../../../router/route-naming';
import { UtilCompanyService } from '../../../service/util/util-company.service';
import { StateFeature } from '../../../state';
import {
	OrganizationListActionEnum,
	OrganizationListStateAction
} from '../../../state/common/organization-list/organization-list.actions';
import { DialogOrganizationDetailComponent } from '../../../widget/dialog/dialog-organization-detail/dialog-organization-detail.component';

@Component({
	selector: 'organization',
	templateUrl: './organization.component.html',
	styleUrls: ['./organization.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrganizationComponent implements OnInit, OnDestroy {
	@ViewChild('pagingBarPageSize') public pagingBarPageSize: TdPagingBarComponent;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	// organizationList: OrganizationPouchModel[];
	organizationList: OrganizationStateModel[];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	currentContext: ContextApplicationItemCodeEnum = null;

	public listPageBaseData = <LocalListHandlerBaseModel<OrganizationStateModel>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code_erp', 'business_name']
			}
		},
		sort: {
			name: 'business_name',
			order: 'ASC'
		}
	};

	filterOpened = false;
	formFilters: FormGroup;
	columns: ITdDataTableColumn[];

	// TODO - Creare un enum?
	companyStates = ['ENABLED', 'DISABLED'];

	organizationLevel: string = '';
	organizationTypeList = Object.values(OrganizationTypeEnum);

	// template
	canAddNew: boolean;

	constructor(
		private store: Store<any>,
		private router: Router,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: UtilCompanyService,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder
	) {
		this.store.dispatch(OrganizationListStateAction.reset());
		this.store.dispatch(OrganizationListStateAction.loadAll());

		this.subscribeManagerService.populate(
			this.subscribeOrganizationList().subscribe(),
			'subscribeOrganizationList'
		);

		// User
		this.user$.pipe(take(1)).subscribe(res => {
			if (res) {
				this.user = res.data;
				this.currentContext = this.user.current_permission.context_application;
				this.canAddNew = this.getCanAddNew();
			}
		});

		this.columns =
			ConfigurationCustomer.Organization.columnList[
				ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
			];

		this.createFormFilters();
	}

	ngOnInit() {}

	ngOnDestroy() {
		// non resettare. Serve per recuperare il dettaglio organization dalla lista di organization
		// this.store.dispatch(OrganizationListStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	/**
	 * Subscription
	 */

	subscribeOrganizationList() {
		return this.activatedRoute.paramMap.pipe(
			mergeMap(params => {
				this.organizationLevel = params.get('organizationLevel')
					? params.get('organizationLevel').toLowerCase()
					: null;
				return this.organizationList$;
			}),
			filter((state: BaseStateModel<OrganizationPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OrganizationPouchModel[]>) => {
				switch (state.type) {
					case OrganizationListActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case OrganizationListActionEnum.ERROR:
						throw new Error(OrganizationListActionEnum.ERROR);
						break;

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(organizationList: OrganizationStateModel[]) {
		// TODO - Introdurre filtri se servono
		switch (this.currentContext) {
			case ContextApplicationItemCodeEnum.AGENT: {
				break;
			}
			case ContextApplicationItemCodeEnum.B2B: {
				break;
			}
			case ContextApplicationItemCodeEnum.BACKOFFICE:
			case ContextApplicationItemCodeEnum.CRM: {
				organizationList = this.getListFilteredByOrganizationLevel(organizationList);
				break;
			}
		}

		this.organizationList = organizationList;
		this.updateListPageBaseData(this.organizationList);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: OrganizationStateModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			company_status: '',
			organization_type: ''
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			company_status: '',
			organization_type: ''
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.organizationList);

		if (this.formFilters && this.formFilters.value.company_status) {
			const status: string = this.formFilters.value.company_status;
			filteredList = filteredList.filter(item => {
				return item.status === status;
			});
		}

		if (this.formFilters && this.formFilters.value.organization_type) {
			const type: string = this.formFilters.value.organization_type;
			filteredList = filteredList.filter(item => {
				return item.organization_type === type;
			});
		}

		this.updateListPageBaseData(filteredList);
	}

	/**
	 * navigation
	 */

	goToOrderDetail(code: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.orders, 'DRAFT', code, 'new', ROUTE_URL.checkout]);
	}

	goToDetail(level: string, code: string) {
		this.router.navigate([ROUTE_URL.private, ROUTE_URL.organizations, level, code]);
	}

	createNew() {
		const dialog = this.dialog.open(DialogOrganizationDetailComponent, {
			data: {
				title: 'organization.detail'
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialog.afterClosed().subscribe(res => {
			// Gestione dei dati nella dialog
			if (res) {
				this.store.dispatch(OrganizationListStateAction.loadAll());
			}
		});
	}

	// Can add new organization
	getCanAddNew(): boolean {
		return false;
	}

	// Organizations filtered by organization level
	getListFilteredByOrganizationLevel(organizationList: OrganizationStateModel[]) {
		return organizationList.filter(
			organization => organization.valid && organization.level === this.organizationLevel
		);
	}
}
