import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyPipe, DatePipe } from '@angular/common';

// constant
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

// model
import { OrderProductModel } from '../../../model/state/article-list-state.model';
import { OrderStateModel } from '../../../model/state/order-state.model';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { OrderRowModel } from '../../../model/order-util.model';
import {
	ConfigurationCustomerFunctionService,
	SubscribeManagerService,
	DialogTextEditComponent,
	BaseStateModel,
	UserDetailModel,
	ContextApplicationItemCodeEnum,
	PermissionKeyEnum
} from '@saep-ict/angular-core';

// constant
import { TicketCenterBodyCustomField } from '../../../constants/configuration-customer/ticket-center/body-custom-field.constant';

// ngrx
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';

// widget & utility
import { UtilOrderService } from '../../../service/util/util-order.service';
import { debounceTime, take } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ArticleStock } from '../../../enum/article-stock.enum';
import { UtilArticleKitService } from '../../../service/util/util-article-kit.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderBackoffice } from '../../../constants/order.constants';
import { TicketCenterMainService } from '@saep-ict/ticket-center';
import { LocalStorage } from 'ngx-webstorage';
import { DialogNewDateComponent } from '../../dialog/dialog-new-date/dialog-new-date.component';
import { DialogDrawingComponent } from '../../dialog/dialog-drawing/dialog-drawing.component';
import { UtilEventTrackingService } from '../../../service/util/util-event-tracking.service';

enum PermissionKeyEnumToAdd {
	BACKOFFICE_ORDER_DELAY = 'backoffice-order-delay'
}

@Component({
	selector: 'article-checkout-item',
	templateUrl: './article-checkout-item.component.html',
	styleUrls: ['./article-checkout-item.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleCheckoutItemComponent implements OnInit {
	@LocalStorage('language') language: string;

	@Input() article: OrderProductModel;
	@Input() order: OrderStateModel;

	@Output() articleChangeEmitter = new EventEmitter<OrderRowModel>();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	OrderStatusEnum = OrderStatusEnum;
	ArticleStock = ArticleStock;
	canEditOrderProduct: boolean;

	private articleChangeDebounce = new Subject();

	configurationCustomer = ConfigurationCustomer;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	extendedPermissionKeyEnum = { ...PermissionKeyEnum, ...PermissionKeyEnumToAdd };

	evasionDate: Date;

	columnKeyNameList: string[];

	ticketCenterBodyCustomField = TicketCenterBodyCustomField;

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		public utilOrderService: UtilOrderService,
		private dialog: MatDialog,
		private currencyPipe: CurrencyPipe,
		public configurationCustomerFunctionService: ConfigurationCustomerFunctionService,
		public translate: TranslateService,
		public utilArticleKitService: UtilArticleKitService,
		private store: Store<any>,
		private ticketCenterMainService: TicketCenterMainService,
		private utilEventTrackingService: UtilEventTrackingService,
		private datePipe: DatePipe
	) {
		this.subscribeManagerService.populate(
			this.articleChangeDebounce.pipe(debounceTime(500)).subscribe((e: OrderRowModel) => {
				this.articleChangeEmitter.emit(e);
			}),
			'article-change-debounce'
		);
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.columnKeyNameList = ConfigurationCustomer.Article.columnList[
				ContextApplicationItemCodeEnum[this.user.current_permission.context_application]
			].map(i => {
				return i.name;
			});
		});
	}

	ngOnInit() {
		this.canEditOrderProduct = this.getCanEditOrderProduct();
		this.getEvasionDate(this.article);
	}

	getArticleDescription(): string {
		let articleDescription = '';
		// if (this.article.articleDescription && this.article.articleDescription.description_list?.length) {
		// 	articleDescription = this.utilTranslateService.getTranslationFromLanguage(
		// 		this.article.articleDescription.description_list
		// 	).description;
		// }
		if (!articleDescription && this.article.articleDetail.description) {
			articleDescription = this.article.articleDetail.description;
		}
		if (!articleDescription && this.article['description']) {
			articleDescription = this.article['description'];
		}
		return articleDescription ? ' | ' + articleDescription : articleDescription;
	}

	// widget & utility
	articleChange($event: any, row: OrderProductModel, key: string, typeNumber: boolean = true) {
		this.articleChangeDebounce.next({ event: $event, row: row, key: key, typeNumber: typeNumber });
	}

	retrieveArticlePrice(rowTable: OrderProductModel) {
		return rowTable.discount.length > 0 || rowTable.discount_agent
			? 'scontato: ' +
					this.currencyPipe.transform(
						this.utilOrderService.retrievePriceWithDiscount(
							rowTable.price,
							rowTable.discount,
							rowTable.discount_agent ? rowTable.discount_agent : null
						),
						'EUR',
						'symbol',
						'',
						'it'
					)
			: null;
	}

	showDrawings(e: any, row: OrderProductModel) {
		const dialog = this.dialog.open(DialogDrawingComponent, {
			data: { product: row, order: this.order },
			panelClass: 'dialog-medium'
		});
		dialog.afterClosed().subscribe(res => {});
	}

	articleNoteChange(e: any, row: OrderProductModel) {
		const dialog = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: `${this.translate.instant('comment.name')} ${this.translate.instant('product.name')} ${
					row.code
				}`,
				text: row.note_order,
				disabled: !(this.order.header.status === OrderStatusEnum.DRAFT),
				optional: true
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (typeof res === 'string') {
				if (res === '' || !res.replace(/\s/g, '').length) {
					row.note_order = null;
				} else {
					row.note_order = res;
				}
				this.articleChange(e, row, 'note_order');
			}
		});
	}

	articleDateChange(e: any, row: OrderProductModel) {
		const dialog = this.dialog.open(DialogNewDateComponent, {
			data: {
				title: `Nuova data ${this.translate.instant('product.name')} ${row.code}`,
				delay_date: row.delay_date,
				optional: true,
				disabled: false
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				row.delay_date = res.valueOf();
				this.articleChange(e, row, 'delay_date');
			}
		});
	}

	getCanEditOrderProduct() {
		if (this.order.header.status === OrderStatusEnum.DRAFT) {
			return true;
		}
		if (
			this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE &&
			OrderBackoffice.filter(el => el.status === this.order.header.status).map(el => el.can_edit)[0]
		) {
			return true;
		}
		return false;
	}

	getEvasionDate(article: OrderProductModel) {
		this.evasionDate = article.delivery_date ? new Date(article.delivery_date) : null;
	}

	async createNewTicket() {
		const orderEventTracking =
			this.order.header.organization.business_name +
			(this.order?.csuite?.order_so_client_ref ? ` - ${this.order.csuite.order_so_client_ref}` : '') +
			' - ' +
			this.utilOrderService.formatOdvCode(this.order) +
			' - ' +
			(this.order.header.date ? this.datePipe.transform(this.order.header.date) : '');

		this.utilEventTrackingService.pushEvent({
			event: 'clickCTASegnalazione',
			ordine: orderEventTracking,
			descrizione_ordine: `${this.article.code} - ${this.article.description}`
		});

		this.ticketCenterMainService.dialogCreateNew({
			body: {
				custom_field: {
					request_type: this.ticketCenterBodyCustomField.RequestTypeEnum.NOT_CONFORMITY_REPORT,
					organization_code_item: this.order.header.organization.code_item,
					order_id: this.order._id,
					order_odv_code:
						this.order.csuite.order_so_number.toString() +
						this.order.csuite.order_so_year +
						this.order.csuite.order_so_series,
					article_code_item: this.article.code,
					orderEventTracking: orderEventTracking
				}
			}
		});
	}
}
