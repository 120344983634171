import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
	StatisticsAgent,
	StatisticsAgentBaseSerieOrder,
	StatisticsAgentBaseSerieClient
} from '../../model/statistics-agent.model';
import { StatisticsAgentActionEnum, StatisticsAgentStateAction } from './statistics-agent.action';
import { DateMomentService } from '@saep-ict/angular-core';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import { AppUtilService } from '../../service/util/app-util.service';

@Injectable()
export class StatisticsAgentEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsAgentActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsAgent>) => from(this.getStatisticsAgent(action))),
			mergeMap((action: BaseStateModel<StatisticsAgent>) => from(this.sortStatisticsAgent(action))),
			mergeMap((action: BaseStateModel<StatisticsAgent>) => from(this.convertDateStatisticsAgent(action))),
			map((action: BaseStateModel<StatisticsAgent>) => StatisticsAgentStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsAgentStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService,
		private utilService: AppUtilService
	) {}

	// Chiavi degli oggetti
	keyClients = ['active_client', 'inactive_client'];
	keyOrder = ['to_authorize', 'processing', 'consolidated', 'average', 'count'];
	keyLongTimeRange = ['days_30', 'days_60', 'days_90'];
	keyClientsTop5 = ['order_value', 'order_quantity'];
	keyBalance = ['due', 'expired'];
	keyFulfilled = ['fulfilled_trend'];

	async getStatisticsAgent(action: BaseStateModel<any>): Promise<BaseStateModel<StatisticsAgent>> {
		await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.AGENT)).statisticsPouch
			.getStatistics(action.data.code_item)
			.then(res => {
				action.data = res;
			})
			.catch(err => {console.log(err); return action});
		return action;
	}

	async sortStatisticsAgent(action: BaseStateModel<StatisticsAgent>): Promise<BaseStateModel<StatisticsAgent>> {
		if (!action.data.data) {
			return action
		}

		// order_trend
		// ordino in base alla data: dalla meno alla più recente
		this.keyOrder.forEach(key => {
			if (this.utilService.findLeaf(action.data.data, `orders.order_trend.${key}`)) {
				this.utilService
					.findLeaf(action.data.data, `orders.order_trend.${key}`)
					.sort((a, b) => a.date - b.date);
			}
		});

		// fulfilled
		// ordino prima per anno (DESC) e poi per Quarter (ASC)
		this.utilService.findLeaf(action.data.data, 'fulfilled.fulfilled_trend').sort((a, b) => {
			if (typeof a.date === 'number' && typeof b.date === 'number') {
				return a.date - b.date;
			}
			return 0;
		});
		this.utilService
			.findLeaf(action.data.data, 'fulfilled.quarters')
			.sort((a, b) => b.year - a.year || +b.name.replace('Q', '') - +a.name.replace('Q', ''));

		// clients
		// ordino in base alla data: dalla meno alla più recente
		this.keyClients.forEach(key => {
			if (this.utilService.findLeaf(action.data.data, `clients.${key}`)) {
				this.utilService.findLeaf(action.data.data, `clients.${key}`).sort((a, b) => a.date - b.date);
			}
		});

		// balance
		// ordino in base alla data: dalla meno alla più recente
		this.keyBalance.forEach(key => {
			if (this.utilService.findLeaf(action.data.data, `balance.${key}`)) {
				this.utilService.findLeaf(action.data.data, `balance.${key}`).sort((a, b) => a.date - b.date);
			}
		});

		return action;
	}

	async convertDateStatisticsAgent(
		action: BaseStateModel<StatisticsAgent>
	): Promise<BaseStateModel<StatisticsAgent>> {
		if (!action.data.data) {
			return action
		}
		
		// order_trend
		this.keyOrder.forEach(key => {
			if (this.utilService.findLeaf(action.data.data, `orders.order_trend.${key}`)) {
				this.utilService
					.findLeaf(action.data.data, `orders.order_trend.${key}`)
					.map((element: StatisticsAgentBaseSerieOrder) => {
						element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
					});
			}
		});

		// clients
		this.keyClients.forEach(key => {
			this.utilService
				.findLeaf(action.data.data, `clients.${key}`)
				.map((element: StatisticsAgentBaseSerieOrder) => {
					element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
				});
		});

		this.keyLongTimeRange.forEach(timeKey => {
			this.keyClientsTop5.forEach(typeKey => {
				this.utilService
					.findLeaf(action.data.data, `clients.top5.${typeKey}.${timeKey}`)
					.map((element: StatisticsAgentBaseSerieClient) => {
						element.last_order_date = this.dateMomentService.convertMilllisStringToStringDate(
							element.last_order_date.toString()
						);
					});
			});
		});

		// balance
		this.keyBalance.forEach(key => {
			this.utilService
				.findLeaf(action.data.data, `balance.${key}`)
				.map((element: StatisticsAgentBaseSerieOrder) => {
					element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
				});
		});

		// fulfilled
		this.keyFulfilled.forEach(key => {
			if (this.utilService.findLeaf(action.data.data, `fulfilled.${key}`)) {
				this.utilService
					.findLeaf(action.data.data, `fulfilled.${key}`)
					.map((element: StatisticsAgentBaseSerieOrder) => {
						element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
					});
			}
		});

		return action;
	}
}
