<div class="jumbotron__container" [style.backgroundImage]="'url(' + jumbotronConf.backgroundImage + ')'">
	<div class="container jumbotron__label">
		<h1 class="jumbotron__text" *ngIf="jumbotronConf.title" [ngClass]="jumbotronConf.titleClass" [innerHTML]="jumbotronConf.title"></h1>
		<button
			*ngIf="jumbotronConf.labelButton"
			[routerLink]="jumbotronConf.url"
			class="custom-button mt-3"
			mat-flat-button
			color="primary"
			[innerHTML]="jumbotronConf.labelButton"
		></button>
	</div>
	<div class="jumbotron__backdrop" *ngIf="jumbotronConf.hasBackdrop" [style.backgroundColor]="jumbotronConf.backdropColor || 'rgba(0,0,0,0.5)'"></div>
</div>
