import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { AgentListFilterModel } from '../../filter/agent-filter.model';
import { BodyTablePouchCustomModel } from '../../model/pouch-base.model';
import { Filters } from '../../../../model/structure/AbstractListModel';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';

export class AgentPouch extends AbstractPouchdbMethod {
	getAgent(agentCode: string): Promise<BodyTablePouchModel> {
		return new Promise((resolve, reject) => {
			this.get('agent_' + agentCode)
				.then((doc: BodyTablePouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getAgentList(data: BaseStateModel<AgentListFilterModel, any>): Promise<BodyTablePouchCustomModel[]> {
		return new Promise(resolve => {
			const selector = {
				$and: [
					{
						type: 'agent',
						code_item: {
							$exists: true
						},
						description: {
							$exists: true
						},
						description_short: {
							$exists: true
						}
					}
				]
			} as PouchDB.Find.Selector;

			const filters: Filters = data.dataSetting.appliedFilter;
			if (filters) {
				if (filters.exclude_code) {
					const s = {
						code_item: {
							$nin: filters.exclude_code
						}
					};
					selector.$and.push(s);
				}
				if (filters.text) {
					const s = {
						$or: [
							{
								code_item: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								description: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								description_short: {
									$regex: `(?i)${filters.text}`
								}
							}
						]
					};
					selector.$and.push(s);
				}
			}

			this.getWithIndexFilter('agent-list', selector, data.dataSetting.pagination, data.dataSetting.sort)
				.then((res: ListDataPouchModel<BodyTablePouchCustomModel>) => {
					resolve(res.docs);
				});
			});
    }
}
