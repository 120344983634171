<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
					<div class="row">
						<!-- origin_name -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.origin_name' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="origin_name" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('origin_name')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<!-- id -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'id' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="id" />
							</mat-form-field>
						</div>
	
						<!-- Separatore -->
						<div class="col-12"></div>
	
						<!-- organizzazione -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label> {{ 'organization.name' | translate | sentencecase }}</mat-label>
								<input matInput formControlName="organization" readonly />
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Edit"
									(click)="openDialogSelectOrganization()"
									*ngIf="!opportunity || (opportunity && !opportunity.close_date)"
								>
									<mat-icon>edit</mat-icon>
								</button>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('organization')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
	
						<!-- referent -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.referent' | translate | sentencecase }}</mat-label>
								<mat-select formControlName="contact" [compareWith]="selectOption">
									<mat-option *ngFor="let referent of referentList" [value]="referent">
										{{ referent.full_name }} - {{ referent.role }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
	
						<!-- Separatore -->
						<div class="col-12"></div>
	
						<!-- Titolo opportunità -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{ 'general.title' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="title" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('title')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<!-- Argomento -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.topic' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="topic" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('topic')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<!-- assegnee -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label> {{ 'opportunity.field.assegnee' | translate | sentencecase }}</mat-label>
								<input matInput formControlName="assegnee" readonly />
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Edit"
									(click)="openDialogSelectAssegnee()"
									*ngIf="!opportunity || (opportunity && !opportunity.close_date)"
								>
									<mat-icon>edit</mat-icon>
								</button>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('assegnee')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
	
						<!-- Interesse progetto -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>
									{{ 'opportunity.field.project_interest' | translate | sentencecase }}
								</mat-label>
								<input matInput type="number" min="0" max="100" formControlName="project_interest" />
								<span matSuffix>%</span>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('project_interest')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
	
						<!-- Probabilità di chiusura -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{
									'opportunity.field.close_probability' | translate | sentencecase
								}}</mat-label>
								<input matInput type="number" min="0" max="100" formControlName="close_probability" />
								<span matSuffix>%</span>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('close_probability')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<!-- Valore opportunità -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'opportunity.field.actual_value' | translate | sentencecase }}</mat-label>
								<input matInput formControlName="actual_value" />
								<span matSuffix>€</span>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('actual_value')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<!-- Budget Disponibile -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>
									{{ 'opportunity.field.budget_amount' | translate | sentencecase }}
								</mat-label>
								<input matInput formControlName="budget_amount" />
								<span matSuffix>€</span>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('budget_amount')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>
	
						<!-- Separatore -->
						<div class="col-12"></div>
	
						<!-- Data chiusura -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{
									'opportunity.field.estimated_close_date' | translate | sentencecase
								}}</mat-label>
								<input
									matInput
									readonly
									formControlName="estimated_close_date"
									[matDatepicker]="estimatedCloseDatePicker"
									(click)="estimatedCloseDatePicker.open()"
								/>
								<mat-datepicker-toggle matSuffix [for]="estimatedCloseDatePicker"></mat-datepicker-toggle>
								<mat-datepicker #estimatedCloseDatePicker></mat-datepicker>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('estimated_close_date')"
									></form-error-message>
								</mat-error>
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Clear"
									*ngIf="
										(!opportunity || (opportunity && !opportunity.close_date)) &&
										form.get('estimated_close_date').value
									"
									(click)="resetSelectField($event, 'estimated_close_date')"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-form-field>
						</div>
						<!-- Data followup -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{
									'opportunity.field.schedule_followup_prospect' | translate | sentencecase
								}}</mat-label>
								<input
									matInput
									readonly
									formControlName="schedule_followup_prospect"
									[matDatepicker]="scheduleFollowUpProspectPicker"
									(click)="scheduleFollowUpProspectPicker.open()"
								/>
								<mat-datepicker-toggle
									matSuffix
									[for]="scheduleFollowUpProspectPicker"
								></mat-datepicker-toggle>
								<mat-datepicker #scheduleFollowUpProspectPicker></mat-datepicker>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('schedule_followup_prospect')"
									></form-error-message>
								</mat-error>
								<button
									type="button"
									mat-button
									matSuffix
									mat-icon-button
									aria-label="Clear"
									*ngIf="
										(!opportunity || (opportunity && !opportunity.close_date)) &&
										form.get('schedule_followup_prospect').value
									"
									(click)="resetSelectField($event, 'schedule_followup_prospect')"
								>
									<mat-icon>clear</mat-icon>
								</button>
							</mat-form-field>
						</div>
	
						<!-- Campi per le opportunity prospect e client -->
						<ng-container
							*ngIf="route.parent.snapshot.paramMap.get('opportunityType') !== opportunityTypeEnum.LEAD"
						>
							<!-- Separatore -->
							<div class="col-12"></div>
	
							<!-- step -->
							<div class="col-md-6">
								<mat-form-field>
									<mat-label>{{ 'opportunity.field.step' | translate | sentencecase }}</mat-label>
									<mat-select formControlName="step">
										<mat-option *ngFor="let step of stepList" [value]="step.id">
											{{ step.name | translate | sentencecase }}
										</mat-option>
									</mat-select>
									<button
										type="button"
										mat-button
										matSuffix
										mat-icon-button
										aria-label="Clear"
										*ngIf="
											(!opportunity || (opportunity && !opportunity.close_date)) &&
											form.get('step').value
										"
										(click)="resetSelectField($event, 'step')"
									>
										<mat-icon>clear</mat-icon>
									</button>
								</mat-form-field>
							</div>
						</ng-container>
					</div>
	
					<div class="row">
						
						<div class="col-12">
							<p class="opacity-50" *ngIf="opportunity && opportunity.created_on">
								Opportunità aperta il {{ opportunity.created_on | date: 'dd/MM/yyyy' }}
							</p>
		
							<p class="opacity-50" *ngIf="opportunity && opportunity.close_date">
								Opportunità chiusa il {{ opportunity.close_date | date: 'dd/MM/yyyy' }}
							</p>
						</div>
	
						<mat-toolbar *ngIf="!opportunity || (opportunity && !opportunity.close_date)">
							<!-- Salva -->
							<button
								mat-raised-button
								color="primary"
								type="submit"
								class="mr-3"
								*ngIf="!opportunity || (opportunity && !opportunity.close_date)"
							>
								{{ 'general.save' | translate | sentencecase }}
							</button>
							<!-- Promuovi -->
							<button
								mat-stroked-button
								type="button"
								class="mr-3"
								(click)="changeStatusOpportunityTo(opportunityTypeEnum.PROSPECT)"
								*ngIf="
									opportunity &&
									opportunity._id &&
									route.parent.snapshot.paramMap.get('opportunityType') === opportunityTypeEnum.LEAD &&
									!opportunity.close_date
								"
							>
								{{ 'opportunity.promote' | translate | sentencecase }}
							</button>
							<!-- Chiudi opportunity -->
							<button
								mat-stroked-button
								type="button"
								(click)="openDialogCloseOpportunity()"
								*ngIf="opportunity && opportunity._id && !opportunity.close_date"
							>
								{{
									(route.parent.snapshot.paramMap.get('opportunityType') === opportunityTypeEnum.LEAD
										? 'request.close'
										: 'opportunity.close')
										| translate
										| sentencecase
								}}
							</button>
						</mat-toolbar>
					</div>
				</form>
			</mat-card>
		</div>
	</div>
	
</div>