import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { WidgetModule } from '../../../widget/widget.module';
import { PageCommonsModule } from '../../commons/page-commons.module';
import { CartComponent } from './cart/cart.component';
import { B2cCatalogComponent } from './b2c-catalog/b2c-catalog.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { IllustrationsModule } from '../../../illustrations/illustrations.module';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ProductsListComponent } from '../../commons/products-list/products-list.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule, IllustrationsModule],
	declarations: [
		HomeComponent,
		B2cCatalogComponent,
		ProductDetailComponent,
		CartComponent,
		CheckoutComponent,
		SearchResultsComponent,
		ProductsListComponent
	],
	exports: [
		HomeComponent,
		B2cCatalogComponent,
		ProductDetailComponent,
		CartComponent,
		CheckoutComponent,
		SearchResultsComponent
	],
	entryComponents: [
		HomeComponent,
		B2cCatalogComponent,
		ProductDetailComponent,
		CartComponent,
		CheckoutComponent,
		SearchResultsComponent
	],
	providers: []
})
export class PageStorefrontModule {}
