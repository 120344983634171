import { PermissionKeyEnum } from '@saep-ict/angular-core';
import { Route } from '@angular/router';
import { DataConfigurationComponent } from '../page/backoffice/data-configuration/data-configuration.component';
import { MediaCenterComponent } from '../page/commons/media-center/media-center.component';
import { UserManagementComponent } from '../page/backoffice/user-management/user-management.component';
import { UserManagementDetailComponent } from '../page/backoffice/user-management/user-management-detail/user-management-detail.component';
import { ContextCodeManagementComponent } from '../page/backoffice/context-code-management/context-code-management.component';
import { ContextCodeManagementDetailComponent } from '../page/backoffice/context-code-management/context-code-management-detail/context-code-management-detail.component';
import { ROUTE_URL } from './route-naming';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { UserComponent } from '../page/commons/user/user.component';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrderDetailComponent } from '../page/commons/order/order-detail/order-detail.component';
import { OrderDetailCatalogComponent } from '../page/commons/order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from '../page/commons/order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailVariationComponent } from '../page/commons/order/order-detail/order-detail-variation/order-detail-variation.component';
import { OrganizationComponent } from '../page/commons/organization/organization.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { FramePageComponent } from '../frame/admin/admin.component';
import { TicketCenterWrapperComponent } from '../page/commons/ticket-center-wrapper/ticket-center.component';
import { TicketCenterRoutes } from '@saep-ict/ticket-center';
import { ArticleListComponent } from '../page/commons/article-list/article-list.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';

export namespace BackofficeRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: FramePageComponent,
			children: [
				// Dashboard
				{
					path: '',
					// component: DashboardComponent, TODO: rimozione redirect
					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD] },
					redirectTo: `${ROUTE_URL.organizations}/customer`,
					pathMatch: 'full'
				},
				// User
				{
					path: ROUTE_URL.user,
					component: UserComponent,
					data: {
						permissionKey: [PermissionKeyEnum.BACKOFFICE_PROFILE],
						meta: {
							title: 'Profilo backoffice',
							description: 'Sezione del profilo del backoffice'
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				// user-management
				{
					path: ROUTE_URL.userManagement,
					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_USER_MANAGEMENT_LIST] },
					component: UserManagementComponent,
					pathMatch: 'full'
				},
				{
					path: `${ROUTE_URL.userManagement}/:idUser`,
					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_USER_MANAGEMENT_LIST] },
					component: UserManagementDetailComponent,
					pathMatch: 'full'
				},
				// configuration
				// {
				// 	path: ROUTE_URL.configurations,
				// 	component: DataConfigurationComponent,
				// 	data: {
				// 		permissionKey: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION],
				// 		cardCodes: ['sync_web_app_with_AS400']
				// 	},
				// 	pathMatch: 'full'
				// },
				// context code
				//{
				//	path: `${ROUTE_URL.contextCodeManagement}/:contextApplicationType`,
				//	data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST] },
				//	children: [
				//		{
				//			path: '',
				//			component: ContextCodeManagementComponent,
				//			data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST] },
				//			pathMatch: 'full'
				//		},
				//		{
				//			path: ':contextCode',
				//			component: ContextCodeManagementDetailComponent,
				//			data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_DETAIL] },
				//			pathMatch: 'full'
				//		}
				//	]
				//},
				// Mediacenter
				{
					path: `${ROUTE_URL.mediaCenter}/path/:path`,
					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_MEDIACENTER] },
					component: MediaCenterComponent,
					pathMatch: 'full'
				},
				// orders
				{
					path: ROUTE_URL.orders,
					data: {
						permissionKey: [
							PermissionKeyEnum.BACKOFFICE_ORDER_LIST,
							PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL
						]
					},
					children: [
						{
							path: ':orderStatus',
							component: OrderComponent,
							data: {
								permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST],
								meta: {
									title: 'Lista ordini',
									description: 'Lista degli ordini divisi per stato'
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: []
						},
						{
							path: ':orderStatus/:organizationId/:orderId',
							component: OrderDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL],
								meta: {
									title: 'Dettaglio ordine',
									description: "Dettaglio testata e prodotti dell'ordine"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.catalog,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.catalog,
									component: OrderDetailCatalogComponent,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
								},
								{
									path: ROUTE_URL.checkout,
									component: OrderDetailCheckoutComponent,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_DETAIL] }
								}
								// {
								// 	path: ROUTE_URL.variation,
								// 	component: OrderDetailVariationComponent,
								// 	data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DASHBOARD] }
								// }
							]
						}
					]
				},
				// organizations
				{
					path: `${ROUTE_URL.organizations}/:organizationLevel`,
					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST] },
					children: [
						{
							path: '',
							component: OrganizationComponent,
							data: {
								permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST],
								meta: {
									title: 'Lista organizzazioni',
									description: 'Lista delle organizzazioni filtrate per tipo'
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':idOrganization',
							component: OrganizationDetailComponent,
							data: {
								permissionKey: [
									PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DASHBOARD,
									PermissionKeyEnum.BACKOFFICE_ORGANIZATION_FULL_DETAIL,
									PermissionKeyEnum.BACKOFFICE_ORDER_LIST,
									PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DESTINATION
								],
								meta: {
									title: 'Dettaglio azienda',
									description: "Sezione di dettaglio dell'azienda"
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.orders,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.details,
									component: OrganizationDetailFullDetailComponent,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_FULL_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.orders,
									component: OrderComponent,
									data: {
										refreshChild: true,
										permissionKey: [PermissionKeyEnum.BACKOFFICE_ORDER_LIST]
									},
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.addresses,
									component: OrganizationDetailDestinationComponent,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_DESTINATION] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.contacts,
									component: ContactComponent,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTACT_LIST] }
								},
								{
									path: ROUTE_URL.credits,
									component: OrganizationDetailCreditComponent,
									data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_ORGANIZATION_CREDIT] }
								}
							]
						}
					]
				},
				// contacts
			//	{
			//		path: `${ROUTE_URL.contacts}`,
			//		data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTACT_LIST] },
			//		children: [
			//			{
			//				path: '',
			//				data: {
			//					permissionKey: [PermissionKeyEnum.BACKOFFICE_CONTACT_LIST],
			//					meta: {
			//						title: 'Contatti backoffice',
			//						description: 'Lista dei contatti associati al backofficer'
			//					}
			//				},
			//				resolve: {
			//					meta: MetaDataResolver
			//				},
			//				component: ContactComponent
			//			}
			//		]
			//	},
				// ticket-center
				{
					path: `${ROUTE_URL.ticketCenter}`,
					data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_TICKET_CENTER] },
					children: [
						{
							path: '',
							data: {
								permissionKey: [PermissionKeyEnum.BACKOFFICE_TICKET_CENTER],
								meta: {
									title: 'Ticket Center',
									description: 'Ticket Center'
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							component: TicketCenterWrapperComponent,
							children: TicketCenterRoutes
						}
					]
				}
				// catalog
				// {
				// 	path: `${ROUTE_URL.catalog}`,
				// 	data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION] }, // TOFIX
				// 	children: [
				// 		{
				// 			path: `${ROUTE_URL.categories}`,
				// 			component: DataConfigurationComponent,
				// 			data: {
				// 				permissionKey: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION], // TOFIX
				// 				cardCodes: ['upload_order_form_category']
				// 			},
				// 			pathMatch: 'full'
				// 		},
				// 		{
				// 			path: `${ROUTE_URL.articles}`,
				// 			component: ArticleListComponent,
				// 			data: { permissionKey: [PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION] }, // TOFIX
				// 			pathMatch: 'full'
				// 		}
				// 	]
				// }
			]
		}
	];
}
