import { Injectable } from '@angular/core';

// model
import { Category } from '../../model/category-list.model';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { ArticleCategory } from '../../model/article.model';
import { LAST_CHARACTER } from '../../constants/pouchdb.constants';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryListAction, CategoryListActionEnum } from './category-list.actions';

// widget & utility
import { MatSnackBar } from '@angular/material/snack-bar';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { UtilCategoryListService } from '../../service/util/util-category-list.service';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { CustomerAppConfig } from '../../customer-app.config';
import { CatalogService } from '../../service/rest/catalog.service';
import { AllDocsDataPouchModel } from '@saep-ict/pouch_agent_models';

@Injectable()
export class CategoryListEffects {
	loadRecursively$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryListActionEnum.LOAD_RECURSIVELY),
			mergeMap((action: BaseStateModel<Category<ArticleCategory>[]>) =>
				from(this.utilCategoryListService.loadCategoryList(action))
			),
			mergeMap((action: BaseStateModel<Category<ArticleCategory>[]>) => from(this.loadCategoryListTree(action))),
			map((action: BaseStateModel<Category<ArticleCategory>[]>) => CategoryListAction.update(action)),
			catchError(() => of({ type: CategoryListActionEnum.ERROR }))
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CategoryListActionEnum.LOAD_ALL),
			mergeMap(() => from(this.loadAll())),
			map((action: BaseStateModel<Category<ArticleCategory>[]>) => CategoryListAction.update(action)),
			catchError(() => of({ type: CategoryListActionEnum.ERROR }))
		)
	);

	constructor(
		private actions$: Actions,
		private utilCategoryListService: UtilCategoryListService,
		private snackBar: MatSnackBar,
		private appConfig: CustomerAppConfig,
		private catalogService: CatalogService,
		private commonsAdapter: PouchDbCommonsAdapter
	) {}

	// TODO: rivedere dopo che saranno normalizzate le risposte REST e couchdb, per capire se serve rimappare per restituire un risultato dello stesso type
	async loadAll() {
		return new Promise((resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				const params: any = {
					include_docs: true,
					startkey: 'category_',
					endkey: 'category_' + LAST_CHARACTER
				};
				this.commonsAdapter.basePouch
					.allDocs(params)
					.then((result: AllDocsDataPouchModel<Category<ArticleCategory>>) =>
						resolve(new BaseState(result.rows.map(cat => cat.doc)))
					);
			} else {
				resolve(this.catalogService.getCategories());
			}
		})
			.then(res => {
				return res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	/**
	 * Scatena loadCategoryChildrenRecursively() sull'array di categorie passate in action
	 * @param action
	 */
	loadCategoryListTree(
		action: BaseStateModel<Category<ArticleCategory>[]>
	): Promise<BaseStateModel<Category<ArticleCategory>[]>> {
		const promises = [];
		for (let i = 0; i < action.data.length; i++) {
			promises.push(
				this.loadCategoryChildrenRecursively(action.data[i])
					.then(res => {
						if (res && res.length > 0) {
							action.data[i].category_list = res;
						}
					})
					.catch(err => {
						console.log(err);
					})
			);
		}
		return Promise.all(promises).then(res => {
			return new BaseState(action.data);
		});
	}

	/**
	 * Restituisce l'intero documento relativo alla specifica categoria
	 * @param adapter
	 * @param id
	 */
	loadCategoryDetail(id) {
		return this.commonsAdapter.categoryListCommonPouch
			.loadCategoryDetail(id)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	// widget & utility

	/**
	 * Restituisce un array di category_list figlie in maniera ricorsiva per tutti i rami della categoria che presentano
	 * elementi nella la proprietà children e rispettano la condizione includeLevelType()
	 *
	 * @param category
	 * @param adapter
	 */
	loadCategoryChildrenRecursively(category: Category<ArticleCategory>): Promise<Category<ArticleCategory>[]> {
		const promises = [];
		const category_list: Category<ArticleCategory>[] = [];

		for (let i = 0; i < category.children.length; i++) {
			promises.push(
				this.loadCategoryDetail(category.children[i])
					.then(async res => {
						if (this.includeLevelType(res.level)) {
							category_list[i] = res;
							if (res && res.children && res.children.length > 0) {
								await this.loadCategoryChildrenRecursively(category_list[i])
									.then(recursiveRes => {
										if (recursiveRes && recursiveRes.length > 0) {
											category_list[i].category_list = recursiveRes;
										}
									})
									.catch(err => {
										console.log(err);
									});
							}
						}
					})
					.catch()
			);
		}
		return Promise.all(promises).then(res => {
			return category_list.sort((a, b) => a.sequence - b.sequence);
		});
	}

	/**
	 * Escludono i documenti di categoria impropri controllando la prop. level come:
	 *
	 * - leaf
	 *
	 * @param level prop. di category
	 */
	includeLevelType(level: string): boolean {
		return level !== 'leaf';
	}

	openSnackBar(message: string, action = 'Ok', duration = 1000) {
		this.snackBar.open(message, action, { duration: duration });
	}
}
