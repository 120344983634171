import { Injector } from '@angular/core';

// model
import { ConfigurationCustomerContextSelector } from '../../../model/structure/configuration-customer.model';

// misc
import {
	DateMomentService,
	ITdDataTableColumnCustom,
	ContextApplicationItemCodeEnum,
	UtilService
} from '@saep-ict/angular-core';
import { TicketCenterStatus } from './status-configuration.constant';
import { TitleCasePipe } from '@angular/common';
import { TicketStatusItem } from '@saep-ict/ticket-center';

const injector = Injector.create({
	providers: [
		{ provide: DateMomentService, deps: [] },
		{ provide: UtilService, deps: [] },
		{ provide: TitleCasePipe, deps: [] }
	]
});

const dateMomentService = injector.get(DateMomentService);
const utilService = injector.get(UtilService);
const titleCasePipe = injector.get(TitleCasePipe);

export namespace TicketCenterColumn {
	export const basePre: ITdDataTableColumnCustom[] = [
		{
			name: 'id',
			label: 'ID'
		},
		{
			name: 'body.custom_field.request_type',
			labelPath: 'lib.ticket_center.custom_field.request_type',
			label: null,
			format: (v: string) => utilService.formatPlaceholder(v)
		}
	];

	export const basePost: ITdDataTableColumnCustom[] = [
		{
			name: 'request.date_request',
			labelPath: 'lib.ticket_center.column.date_request',
			label: null,
			numeric: true,
			format: (v: string) => dateMomentService.convertStringDateFromFormatToFormat(v, 'x', 'DD/MM/YYYY')
		}
	];

	export const b2b: ITdDataTableColumnCustom[] = [
		{
			name: 'status',
			labelPath: 'lib.ticket_center.column.status',
			label: null,
			format: (v: string) => returnContextApplicationMap(v, ContextApplicationItemCodeEnum.B2B)
		}
	];

	export const backoffice: ITdDataTableColumnCustom[] = [
			{
			name: 'body.custom_field.organization_business_name',
			labelPath: 'organization.field.business_name',
			label: null
		},
        {
			name: 'body.custom_field.organization_code_erp',
			labelPath: 'lib.ticket_center.column.organization_code',
				label: null
		},
		{
			name: 'status',
			labelPath: 'lib.ticket_center.column.status',
			label: null,
			format: (v: string) => returnContextApplicationMap(v, ContextApplicationItemCodeEnum.BACKOFFICE)
		}
	];

	export const contextApplicationMap = <ConfigurationCustomerContextSelector<ITdDataTableColumnCustom[]>>{
		BACKOFFICE_ADMIN: [],
		BACKOFFICE: [...TicketCenterColumn.basePre, ...TicketCenterColumn.backoffice, ...TicketCenterColumn.basePost],
		B2B: [...TicketCenterColumn.basePre, ...TicketCenterColumn.b2b, ...TicketCenterColumn.basePost]
	};

	export const returnContextApplicationMap = (
		value: string,
		contextApplication: ContextApplicationItemCodeEnum
	): string => {
		const statusMapItem: TicketStatusItem = TicketCenterStatus.contextApplicationMap[
			contextApplication
		].filter_list.find(i => i.value === TicketCenterStatus.TypeEnum[value]);
		return statusMapItem
			? titleCasePipe.transform(
					utilService
						.formatPlaceholder(statusMapItem.name ? statusMapItem.name : (statusMapItem.value as string))
						.toLocaleLowerCase()
			  )
			: titleCasePipe.transform(utilService.formatPlaceholder(value).toLocaleLowerCase());
	};
}
