// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ReceiptStateModel } from '../../model/state/receipt-state.model';
import { ContextCodeFilterModel } from '../../service/pouch-db/filter/context-code-filter.model';

export enum ReceiptListActionEnum {
	LOAD_ALL = '[receipt-list] Load All',
	UPDATE = '[receipt-list] Update',
	RESET = '[receipt-list] Reset',
	ERROR = '[receipt-list] Error'
}

export namespace ReceiptListStateAction {
	export const loadAll = createAction(ReceiptListActionEnum.LOAD_ALL);
	export const update = createAction(ReceiptListActionEnum.UPDATE, props<BaseStateModel<ReceiptStateModel[], ContextCodeFilterModel>>())
  	export const reset = createAction(ReceiptListActionEnum.RESET);
	export const error = createAction(ReceiptListActionEnum.ERROR, props<BaseStateModel<any>>());
}
