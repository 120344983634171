export const ROUTE_URL = {
	// BASE ROUTING
	private: 'dashboard',
	public: '',
	privacyPolicy: 'privacy-policy',
	probeTest: 'probe-test',
	verifyTokenError: 'verify-token-error',
	authentication: 'authentication',
	login: 'login',
	passwordRecovery: 'password-recovery',
	passwordUpdate: 'password-update',
	contextSelection: 'context-selection',
	accessRequest: 'access-request',
	startupDownload: 'startup-download',
	termsAndConditions: 'terms-and-conditions',
	deliveryPolicy: 'delivery-policy',
	returnPolicy: 'return-policy',

	// COURTESY ROUTE
	courtesy: 'courtesy',

	// BACKOFFICE
	userManagement: 'user-management',
	configurations: 'configurations',
	contextCodeManagement: 'context-code-management',
	mediaCenter: 'media-center',
	ticketCenter: 'ticket-center',
	organizations: 'organizations',
	categories: 'categories',
	articles: 'articles',

	// B2B
	user: 'user',
	company: 'company',

	// AGENT
	overview: 'overview',
	credits: 'credits',
	destinations: 'destinations',
	details: 'details',
	variation: 'variation',
	contacts: 'contacts',
	dashboardOrders: 'dashboard-orders',
	dashboardSold: 'dashboard-sold',
	dashboardClients: 'dashboard-clients',
	dashboardExpired: 'dashboard-expired',
	receipts: 'receipts',

	// B2C
	product: 'product',
	cart: 'cart',
	searchResults: 'search-results',
	checkout: 'checkout',
	createAccount: 'create-account',
	reservedArea: 'reserved-area',
	myAccount: 'my-account',
	userData: 'user-data',

	// CRM
	dashboardCrm: 'dashboard-crm',
	newOpportunity: 'new',
	opportunity: 'opportunity',
	opportunityKanban: 'opportunity-kanban',
	offer: 'offer',
	opportunityDetail: 'detail',
	opportunityComment: 'comment',
	opportunityActivity: 'activity',
	opportunityOffer: 'offer',
	comments: 'comments',
	offerHead: 'head',
	offerBody: 'body',
	newOffer: 'new',

	// COMMONS
	addresses: 'addresses',
	catalog: 'catalog',
	orders: 'orders'
};

export const PATH_URL = {
	PRIVATE: `/${ROUTE_URL.private}`,
	CART: `/${ROUTE_URL.public}/${ROUTE_URL.cart}`,
	MY_ACCOUNT: `/${ROUTE_URL.private}/${ROUTE_URL.myAccount}`
};
