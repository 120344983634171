import { ITdDataTableColumn } from '@covalent/core/data-table';

import { BaseTableListService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class OfferListColumnService extends BaseTableListService {

	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}

	public _columns = [
		{
			name: 'title',
			label: this.translate.instant('general.title')
		},
		{
			name: 'opportunity.title',
			label: this.translate.instant('opportunity.name')
		},
		{
			name: 'opportunity.organization.business_name',
			label: this.translate.instant('organization.name')
		},
		{
			name: 'payment_method',
			label: this.translate.instant('offer.field.payment_method')
		},
		{
			name: 'date_end_validity',
			label: this.translate.instant('offer.field.date_end_validity'),
			numeric: true
		}
	];
}
