import { Injectable } from '@angular/core';

declare global {
	interface Window {
		dataLayer: any[];
	}
}

@Injectable({ providedIn: 'root' })
export class UtilEventTrackingService {
	constructor() {
	}

	pushEvent(eventObject: object) {
		window.dataLayer.push(eventObject);
	}
}
