import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubscribeManagerService, BaseStateModel } from '@saep-ict/angular-core';
import { filter, map, skipWhile } from 'rxjs/operators';
import { OpportunityPouchModel, OpportunityTypeEnum } from '../../../../model/state/opportunity-state.model';
import { ROUTE_URL } from '../../../../router/route-naming';
import { OpportunityStateAction, OpportunityActionEnum } from '../../../../state/opportunity/opportunity.actions';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';

@Component({
	selector: 'opportunity-detail',
	templateUrl: './opportunity-detail.component.html',
	styleUrls: ['./opportunity-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OpportunityDetailComponent implements OnInit, OnDestroy {
	public ROUTE_URL = ROUTE_URL;
	public backButtonRoute;

	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	tabsToExclude: string[] = [ROUTE_URL.opportunityActivity, ROUTE_URL.opportunityComment, ROUTE_URL.opportunityOffer];
	opportunityTypeEnum = OpportunityTypeEnum;

	constructor(
		private store: Store<any>,
		public route: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');
	}

	ngOnInit() {
		this.getBackButtonRoute();
	}

	getBackButtonRoute(){
		if(this.route.snapshot.paramMap.get('opportunityType') === OpportunityTypeEnum.LEAD) {
			this.backButtonRoute = ROUTE_URL.private + '/' + ROUTE_URL.opportunity + '/' + this.route.snapshot.paramMap.get('opportunityType');
		} else {
			this.backButtonRoute = ROUTE_URL.private + '/' + ROUTE_URL.opportunityKanban
		}
	}

	/**
	 * Subscription
	 */

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:


						this.opportunity = opportunityState.data[0];
						const isLead = this.opportunity.type === 'opportunity_' + OpportunityTypeEnum.LEAD;
						if(isLead){
							this.tabsToExclude = [ROUTE_URL.opportunityOffer];
						} else {
							this.tabsToExclude = [];
						}
						break;

					default:
						break;
				}
			})
		);
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
	}
}
