import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromCategoryList from './category-list.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('category-list', fromCategoryList.reducer)
  ]
})
export class CategoryListStateModule {}
