import { RestBasePk } from '@saep-ict/angular-core';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { PouchDeleteResponse } from '../../model/pouch-base-response.model';
import { OfferStateModel } from '../../../../model/state/offer-state.model';

export class OfferPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getOfferDetail(data: RestBasePk): Promise<OfferStateModel> {
		return new Promise((resolve, reject) => {
			this.get(data.id)
				.then((doc: OfferStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putOffer(data: OfferStateModel, isGuid: boolean): Promise<OfferStateModel> {
		const guid = isGuid ? 'offer' : data._id;
		if (!data.type) {
			data.type = 'offer';
		}
		return new Promise((resolve, reject) => {
			this.put<OfferStateModel>(data, guid, isGuid)
				.then((doc: OfferStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteOffer(opportunity: OfferStateModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(opportunity)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
