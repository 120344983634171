import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, UserDetailModel } from '@saep-ict/angular-core';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ViewTypeEnum } from '../../../enum/view-type.enum';
import { StateFeature } from '../../../state';
import { ItemType } from '../../../widget/card-product/card-slider.models';

@Component({
	selector: 'products-list',
	templateUrl: './products-list.component.html',
	styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnChanges {
	@Input() list: ArticlePouchModel[] = [];
	@Input() viewType: ViewTypeEnum = ViewTypeEnum.list;

	pageSlice: ArticlePouchModel[];
	public ViewTypeEnum = ViewTypeEnum;
	ItemType = ItemType;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.list && changes.list.currentValue) {
			this.eventP({ pageSize: 8, pageIndex: 0 });
		}
	}

	eventP(event): void {
		const startIndex = event.pageSize * event.pageIndex;
		let endIndex = startIndex + event.pageSize;
		if (endIndex > this.list.length) {
			endIndex = this.list.length;
		}
		if (this.list && this.list.length > 0) {
			this.pageSlice = this.list.slice(startIndex, endIndex);
		}
	}
}
