<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : false"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : false
		"
		(window:resize)="checkTableColumnVisibility($event.target.innerWidth)"
	>
		<!-- Status -->
		<ng-template tdDataTableTemplate="header.status" let-value="value">
			<div class="chips-mini">
				<span [ngClass]="value" matTooltip="{{ 'order.status.' + value | translate | sentencecase }}"></span>
			</div>
		</ng-template>
		<!-- Ordine # -->
		<ng-template tdDataTableTemplate="_id" let-value="value" let-row="row">
			<p>
				<a href="javascript:void(0)" (click)="selectOrderId.emit(row)">
					{{ row.id_first_row }}
				</a>
				<br />
				<small class="cell-subtitle" *ngIf="row.id_second_row">
					{{ row.id_second_row }}
				</small>

				<!-- <br />
				<small
					class="badge"
					*ngIf="
						row.csuite &&
						(row.csuite.order_so_type_acquisition === 'A' || row.csuite.order_so_type_acquisition === 'S')
					"
				>
					{{ 'order.erp' | translate | uppercase }}
				</small> -->
			</p>
		</ng-template>
		<!-- <ng-template tdDataTableTemplate="_id" let-value="value" let-row="row">
            <p>
                <a href="javascript:void(0)" (click)="goToOrderDetail(row)">
                    {{ row.csuite && row.csuite.id_prev ? row._id.replace('order_', '') : '-' }}
                </a>
                <br /><small class="cell-subtitle">{{
                    row.csuite && row.csuite.id_prev
                        ? row.csuite.id_prev.replace('order_', '')
                        : (row._id | orderIdFormatter)
                }}</small>
            </p>
        </ng-template> -->
		<!-- Azienda -->
		<ng-template tdDataTableTemplate="header.organization.business_name" let-row="row">
			<p class="title-card">
				<a
					href="javascript:void(0)"
					(click)="
						selectCompany.emit({ level: row.header?.level, code: row.header?.organization?.code_item })
					"
				>
					<span class="title">{{ row.header.organization?.business_name | sentencecase }}</span>
				</a>
				<br />
				<small class="cell-subtitle" *ngIf="row.header?.organization?.tax_data?.vat_number"
					>{{ retrieveOrganizationCodeErp(row) }} | P.IVA
					{{ row.header.organization.tax_data.vat_number }}</small
				>
			</p>
		</ng-template>

		<!-- Data creazione-->
		<ng-template tdDataTableTemplate="header.date" let-value="value" let-row="row">
			<p class="title-card">
				<span [matTooltip]="'Data creazione: ' + convertMilllisStringToStringDate(value)">
					{{ convertMilllisStringToStringDate(value) }}
				</span>
				<br />
				<small class="cell-subtitle">{{ convertMillisStringToTime(value) }}</small>
			</p>
		</ng-template>

		<!-- Referenza ordine cliente -->
		<ng-template tdDataTableTemplate="csuite.order_so_client_ref" let-value="value" let-row="row">
			<span>
				{{ value }}
			</span>
		</ng-template>

		<!-- Data ordine cliente -->
		<ng-template tdDataTableTemplate="csuite.order_so_client_date" let-value="value">
			<p *ngIf="value" class="title-card">
				<span
					[matTooltip]="
						('order.field.order_so_client_date' | translate | sentencecase) +
						': ' +
						(value | date: 'dd/MM/yyyy')
					"
				>
					{{ value | date: 'dd/MM/yyyy' }}
				</span>
				<!-- <br />
				<small class="cell-subtitle">{{ value | date: 'dd/MM/yyyy' }}</small> -->
			</p>
		</ng-template>

		<!-- Data consegna -->
		<ng-template tdDataTableTemplate="header.first_evasion_date" let-value="value" let-row="row">
			<p>
				<span
					[ngClass]="{
						'variation-underscore': utilOrderService.getOrderVariationValue(row, 'first_evasion_date')
					}"
					matTooltipClass="allow-break-line"
					[matTooltip]="
						utilOrderService.getOrderVariationValue(row, 'first_evasion_date')
							? 'Valore aggiornato da:&#13;' +
							  convertMilllisStringToStringDate(
									utilOrderService.getOrderVariationValue(row, 'first_evasion_date')
							  )
							: 'Data consegna: ' + convertMilllisStringToStringDate(value)
					"
				>
					{{ convertMilllisStringToStringDate(value) }}
				</span>
				<!-- <br />
				<small class="cell-subtitle">{{ convertMillisStringToTime(value) }}</small> -->
			</p>
		</ng-template>

		<!-- Importo -->
		<ng-template tdDataTableTemplate="iva" let-row="row">
			<span>
				{{ retrieveOrderTotalPrice(row) | currency: 'EUR':'symbol':'':'it' }}
			</span>
		</ng-template>

		<!-- Destinazione -->
		<ng-template tdDataTableTemplate="header.goods_destination_code" let-row="row">
			<p>
				<span
					[ngClass]="{
						'variation-underscore': utilOrderService.getOrderVariationValue(row, 'goods_destination')
					}"
					matTooltipClass="allow-break-line"
					[matTooltip]="
						utilOrderService.getOrderVariationValue(row, 'goods_destination')
							? 'Valore aggiornato da:&#13;' +
							  utilOrderService.getFormattedAddress(row.header.order_progress_detail.goods_destination)
							: ''
					"
				>
					{{ retrieveOrderDestination(row) ? (destination?.address?.locality | sentencecase) : '-' }}
				</span>
				<br />
				<small *ngIf="destination?.address" class="cell-subtitle">
					{{ destination.address.zip_code ? destination.address.zip_code + ' - ' : '' }}
					{{ destination.address.address ? (destination.address.address | sentencecase) : '' }}
					{{ destination.address.country ? '(' + destination.address.country + ')' : '' }}
				</small>
			</p>
		</ng-template>

		<!-- Variation icon -->
		<ng-template tdDataTableTemplate="info" let-value="value" let-row="row">
			<mat-icon
				class="mat-icon variation-icon"
				*ngIf="showGenericVariationIcon(row)"
				[matTooltip]="'order.variation.generic' | translate | sentencecase"
			>
				error
			</mat-icon>
			<ng-container *ngFor="let article of row.product_list">
				<mat-icon
					*ngIf="article.delay_date"
					matTooltip="{{
						('article.action.date_delivery_new' | translate | sentencecase) +
							': ' +
							(article.delay_date | date: 'dd/MM/yyyy')
					}}"
				>
					calendar_today
				</mat-icon>
			</ng-container>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
