import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { Category } from '../../../model/category-list.model';
import { BaseStateModel, Filters } from '@saep-ict/angular-core';
import { ArticleCategory } from '../../../model/article.model';
import { ArticleListFilterModel } from '../filter/article-list-filter.model';

export class CategoryListPouch extends AbstractPouchdbMethod {
	loadCategoryList(
		data: BaseStateModel<Category<ArticleCategory>[], Filters | ArticleListFilterModel>
	): Promise<any> {
		return new Promise(resolve => {
			const pagination =
				data && data.dataSetting && data.dataSetting.pagination ? data.dataSetting.pagination : null;
			const selector = {
				$and: [
					{
						type: 'category'
					}
				]
			} as PouchDB.Find.Selector;
			// filters
			const filters: any =
				data && data.dataSetting && data.dataSetting.appliedFilter ? data.dataSetting.appliedFilter : null;
			if (filters) {
				if (filters.article) {
					if (filters.article.code) {
						const f = {
							code: filters.article.code
						};
						selector.$and.push(f);
					}
					if (filters.article.level) {
						const f = {
							level: filters.article.level
						};
						selector.$and.push(f);
					}
					if (filters.article.only_without_children) {
						const f = {
							children: {
								$exists: false
							}
						};
						selector.$and.push(f);
					}
					if (filters.article.has_hierarchy && filters.article.has_hierarchy.length > 0) {
						const f = {
							hierarchy: {
								$in: filters.article.has_hierarchy
							}
						};
						selector.$and.push(f);
					}
					if (filters.article.text) {
						const f = {
							$or: [
								{
									description: {
										$regex: `(?i)${filters.article.text.trim()}`
									}
								},
								{
									code: {
										$regex: `(?i)${filters.article.text.trim()}`
									}
								}
							]
						};
						selector.$and.push(f);
					}
				}
			} else {
				// TODO: da perfezionare la convenzione per filtrare i soli nodi root
				selector.$and.push(this.rootNodeListSelector());
			}

			const sort = data && data.dataSetting && data.dataSetting.sort ? data.dataSetting.sort : null;
			let sortSelector = [];
			const sortItem = {};
			if (sort) {
				sortItem[sort[0].name] = sort[0].order;
			} else {
				sortItem['sequence'] = 'asc';
			}
			sortSelector = [sortItem];
			this.getWithIndexFilter('category-list', selector, pagination, sortSelector)
				.then(res => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	loadCategoryDetail(id: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.get(id)
				.then(doc => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	// widget & utility
	rootNodeListSelector() {
		const f = {
			level: 'root'
		};
		return f;
	}
}
