import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressPouchModel, AddressTypeEnum, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { OrganizationDetailDestinationComponent } from '../../../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { DEFAULT_DESTINATION_CODE } from '../../../constants/destination.constants';

@Component({
	selector: 'app-dialog-destination-detail',
	templateUrl: './dialog-destination-detail.component.html',
	styleUrls: ['./dialog-destination-detail.component.scss']
})
export class DialogDestinationDetail implements OnInit {
	addressFormDialog: FormGroup;
	label: string = this.data.modalTitle;
	addressData: DestinationPouchModel = this.data.addressData;
	addressTypeEnum = AddressTypeEnum;
	checkedToggle: boolean = false;

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<OrganizationDetailDestinationComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {}

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.addressFormDialog = this.fb.group({
			address: ['', { validators: [Validators.required] }],
			addressType: [{ value: false, disabled: false }],
			locality: ['', { validators: [Validators.required] }],
			zipCode: ['', { validators: [Validators.required] }],
			province: ['', { validators: [Validators.required] }],
			country: ['', { validators: [Validators.required] }]
		});

		if (this.addressData) {
			this.updateFormFieldValue();
			// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
			// if (this.addressFormDialog.get('addressType').value === true) {
			// 				this.addressData.address_type = this.addressTypeEnum.REGISTERED_OFFICE;
			// 			}
			// 			this.checkedToggle = this.addressData.address_type === this.addressTypeEnum.REGISTERED_OFFICE;
			if (this.addressFormDialog.get('addressType').value === true) {
				this.addressData.code = DEFAULT_DESTINATION_CODE;
			}
			this.checkedToggle = this.addressData.code === DEFAULT_DESTINATION_CODE;
		}
	}

	updateFormFieldValue() {
		this.addressFormDialog.patchValue({
			address: this.addressData.address.address ? this.addressData.address.address : null,
			// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
			// addressType: this.addressData.address_type === this.addressTypeEnum.REGISTERED_OFFICE ? true : false,
			addressType: this.addressData.code === DEFAULT_DESTINATION_CODE ? true : false,
			locality: this.addressData.address.locality ? this.addressData.address.locality : null,
			zipCode: this.addressData.address.zip_code ? this.addressData.address.zip_code : null,
			province: this.addressData.address.province ? this.addressData.address.province : null,
			country: this.addressData.address.country ? this.addressData.address.country : null
		});
	}

	toggle(e: MatSlideToggleChange) {
		console.log(e);
	}

	save() {
		if (this.addressFormDialog.valid) {
			this.dialogRef.close(this.addressFormDialog.value);
			console.log(this.addressFormDialog);
		}
	}
}
