import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

@Component({
	selector: 'organization-personal-data-form',
	templateUrl: './organization-personal-data-form.component.html',
	styleUrls: ['./organization-personal-data-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class OrganizationPersonalDataFormComponent implements OnChanges, OnInit {
	@Input() parentForm: FormGroup;
	@Input() organization: OrganizationStateModel;
	@Input() organizationTypeSelected: OrganizationTypeEnum;

	organizationType: OrganizationTypeEnum;
	isCompany = false;
	isPrivate = false;

	minLength = {
		sdi: 7,
		taxCode: 16,
		vatNumber: 11
	};

	constructor(private fb: FormBuilder) {}

	setOrganizationType() {
		if (this.organization) {
			if (this.organization.organization_type) {
				return this.organization.organization_type;
			} else {
				return OrganizationTypeEnum.COMPANY; // manca organization_type
			}
		} else {
			return this.organizationTypeSelected; // registrazione account
		}
	}

	ngOnInit() {}

	ngOnChanges() {
		this.organizationType = this.setOrganizationType();
		this.isCompany = this.organizationType === OrganizationTypeEnum.COMPANY;
		this.isPrivate = this.organizationType === OrganizationTypeEnum.PRIVATE;

		// Reset personalData on organization type change (B2C)
		if (this.parentForm.value.hasOwnProperty('personalData')) {
			this.parentForm.removeControl('personalData');
		}
		switch (this.organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.parentForm.addControl(
					'personalData',
					this.fb.group({
						// Company
						businessName: ['', [Validators.required]],
						vatNumber: ['', [Validators.required, Validators.minLength(this.minLength.vatNumber)]],
						sdiCode: ['', [Validators.required, Validators.minLength(this.minLength.sdi)]],
						businessEmail: ['', [Validators.required, Validators.email]]
					})
				);
				break;

			case OrganizationTypeEnum.PRIVATE:
				this.parentForm.addControl(
					'personalData',
					this.fb.group({
						// Private
						firstName: ['', [Validators.required]],
						lastName: ['', [Validators.required]],
						taxCode: ['', [Validators.required, Validators.minLength(16)]]
					})
				);
				break;
		}

		// Update form from organization
		if (this.organization) {
			this.updateFormFieldValue();
			// Disable form (B2C personal-data)
			this.parentForm.disable();
		}
	}

	updateFormFieldValue() {
		// Common
		this.parentForm['controls']['personalData'].patchValue({
			businessName: this.organization.business_name ? this.organization.business_name : null
		});

		switch (this.organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.parentForm['controls']['personalData'].patchValue({
					vatNumber: this.organization.tax_data && this.organization.tax_data.vat_number ? this.organization.tax_data.vat_number : null,
					pec: this.organization.tax_data &&
					this.organization.tax_data.pec
					? this.organization.tax_data.pec
							: null,
					sdiCode: this.organization.tax_data && this.organization.tax_data.sdi_code ? this.organization.tax_data.sdi_code : null
				});
				break;

			case OrganizationTypeEnum.PRIVATE:
				const mainContact = this.organization.contact_list.find(contact => contact.main_contact);
				this.parentForm['controls']['personalData'].patchValue({
					firstName: mainContact.first_name ? mainContact.first_name : null,
					lastName: mainContact.last_name ? mainContact.last_name : null,
					taxCode: this.organization.tax_data && this.organization.tax_data.tax_code ? this.organization.tax_data.tax_code : null
				});
				break;
		}
	}
}
