<breadcrumb
	[title]="contextCodeItem.description | sentencecase"
	[pathList]="
		contextTypeAndApplicationLink
			? [
					'Context Code',
					contextTypeAndApplicationLink.context_code_item.description | sentencecase,
					(contextCodeItem.description | sentencecase) + ' (' + contextCodeItem.code.toString() + ')'
			  ]
			: []
	"
	[options]="permissionUtilService.canEditContextCode(contextTypeAndApplicationLink.context_application_item_code)"
	[backButton]="
		ROUTE_URL.private +
		'/' +
		ROUTE_URL.contextCodeManagement +
		'/' +
		route.snapshot.paramMap.get('contextApplicationType')
	"
>
	<ng-container actions>
		<button mat-menu-item [disabled]="formEditEnabled" (click)="dialogConfirmDeleteContextCode()">
			<mat-icon>delete</mat-icon>
			<span>{{ 'general.delete' | translate | sentencecase }}</span>
		</button>
		<button
			[disabled]="formEditEnabled"
			mat-menu-item
			(click)="editMode()"
			*ngIf="contextTypeAndApplicationLink.context_code_item.association_type.length > 0"
		>
			<mat-icon>edit</mat-icon>
			<span>{{ 'general.edit' | translate | sentencecase }}</span>
		</button>
	</ng-container>
</breadcrumb>

<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12" *ngIf="formEditEnabled">
				<mat-card>
					<mat-toolbar class="height-auto">
						{{ 'general.edit_mode' | translate | sentencecase }}
						<span class="flex-span"></span>
						<button mat-raised-button color="accent" type="submit" [disabled]="!form.valid">
							{{ 'general.save' | translate | sentencecase }}
						</button>
					</mat-toolbar>
				</mat-card>
			</div>

			<ng-container *ngFor="let context of contextTypeAndApplicationLink.context_code_item.association_type">
				<div class="col-sm-12 col-md-6">
					<mat-accordion class="accordion-permission">
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{
										'context_code.x_associated'
											| translate
												: { context_code: utilService.formatPlaceholder(context.description) }
											| titlecase
									}}
								</mat-panel-title>
							</mat-expansion-panel-header>
							<mat-toolbar>
								<mat-toolbar-row class="p-0">
									<span class="flex-span"></span>
									<td-search-box
										#contextCodeFilterInput
										[placeholder]="
											('general.filter_by' | translate | sentencecase) +
											' ' +
											('context_code.field.code' | translate)
										"
										[showUnderline]="true"
										[debounce]="500"
										[alwaysVisible]="false"
									>
									</td-search-box>
								</mat-toolbar-row>
							</mat-toolbar>
							<!-- Associate existing -->
							<button
								*ngIf="formEditEnabled"
								(click)="dialogAssociateContextCodeItem(context)"
								type="button"
								mat-raised-button
								color="primary"
								class="mb-3 mr-3"
							>
								<mat-icon>link</mat-icon>
								{{
									'context_code.associate_x_existent'
										| translate: { context_code: context.description }
								}}
							</button>

							<!-- Associate all existing -->
							<button
								*ngIf="formEditEnabled"
								(click)="dialogAssociateAllContextCodeItem(context)"
								type="button"
								mat-stroked-button
								color="primary"
								class="mb-3 mr-3"
								[disabled]="
									permissionUtilService.getContextItem(
										getFormFieldValue('context_code_association_list'),
										context.code
									) &&
									permissionUtilService.getContextItem(
										getFormFieldValue('context_code_association_list'),
										context.code
									).context_code_list_all
								"
							>
								<mat-icon>link</mat-icon>
								{{ 'context_code.associate_all_x' | translate: { context_code: context.description } }}
							</button>

							<!-- Create NEW -->
							<button
								*ngIf="
									formEditEnabled &&
									permissionUtilService.canEditContextCode(
										contextApplicationItemCodeEnum[context.context_application_item_code]
									)
								"
								(click)="dialogCreateContextCodeItem(context)"
								type="button"
								mat-stroked-button
								class="mb-3 mr-3"
							>
								<mat-icon>add</mat-icon>
								{{ 'context_code.create_x' | translate: { context_code: context.description } }}
							</button>
							<!-- Delete all -->
							<!-- Mostro il button se ho dei code o la proprieta context_code_list_all a true -->
							<button
								*ngIf="
									formEditEnabled &&
									permissionUtilService.getContextItem(
										getFormFieldValue('context_code_association_list'),
										context.code
									) &&
									((permissionUtilService.getContextItem(
										getFormFieldValue('context_code_association_list'),
										context.code
									).context_code_list &&
										permissionUtilService.getContextItem(
											getFormFieldValue('context_code_association_list'),
											context.code
										).context_code_list.length > 0) ||
										(permissionUtilService.getContextItem(
											getFormFieldValue('context_code_association_list'),
											context.code
										) &&
											permissionUtilService.getContextItem(
												getFormFieldValue('context_code_association_list'),
												context.code
											).context_code_list_all))
								"
								type="button"
								mat-button
								color="warn"
								(click)="dialogConfirmDeleteAssociatedContextCodeList(context)"
								class="mb-3 mr-3"
							>
								<mat-icon>link_off</mat-icon>
								{{
									'context_code.disassociate_all_x' | translate: { context_code: context.description }
								}}
							</button>

							<!-- TODO: gestire lo switchare della prop. passata alla pipe di filtro code_item o code -->

							<td-data-table
								#dataTable
								*ngIf="
									permissionUtilService.getContextItem(
										getFormFieldValue('context_code_association_list'),
										context.code
									) &&
										permissionUtilService.getContextItem(
											getFormFieldValue('context_code_association_list'),
											context.code
										).context_code_list &&
										permissionUtilService.getContextItem(
											getFormFieldValue('context_code_association_list'),
											context.code
										).context_code_list.length > 0;
									else noData
								"
								[data]="
									permissionUtilService.getContextItem(
										getFormFieldValue('context_code_association_list'),
										context.code
									).context_code_list
										| filterByString
											: { propertyListName: ['code'], value: contextCodeFilterInput.value }
								"
								[columns]="permissionContextListColumnService.columns"
							>
								<ng-template tdDataTableTemplate="description" let-row="row">
									{{ row.description ? row.description : row.business_name }}
								</ng-template>
								<ng-template tdDataTableTemplate="action" let-row="row">
									<!-- TODO: da deprecare una volta uniformati code e code_item dei due modelli
								BodyTablePouchCustomModel & AgentCustomerPouchModel (quest'ultimo è stato deprecato) -->
									<button
										mat-icon-button
										stopPropagation
										(click)="dialogConfirmDeleteAssociatedContextCodeItem(context, row.code)"
										type="button"
										color="warn"
										*ngIf="formEditEnabled"
										[matTooltip]="'Disassocia ' + (context.description | titlecase)"
									>
										<mat-icon>link_off</mat-icon>
									</button>
								</ng-template>
							</td-data-table>
							<ng-template #noData>
								<ng-container
									*ngIf="
										permissionUtilService.getContextItem(
											getFormFieldValue('context_code_association_list'),
											context.code
										) &&
											permissionUtilService.getContextItem(
												getFormFieldValue('context_code_association_list'),
												context.code
											).context_code_list_all;
										else noAssociations
									"
								>
									<div class="all-code-associate">
										<mat-icon>vpn_key</mat-icon>
										<p class="all-code-associate__label">
											{{
												'context_code.all_x_associated'
													| translate
														: {
																context_code: context.description
																	?.replace('code', '')
																	.toUpperCase()
														  }
											}}
										</p>
									</div>
								</ng-container>

								<ng-template #noAssociations>
									<p class="placeholder">
										{{
											'context_code.no_x_associated'
												| translate: { context_code: context.description }
										}}
									</p>
								</ng-template>
							</ng-template>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</ng-container>

			<div class="col" *ngIf="contextTypeAndApplicationLink.context_code_item.association_type.length === 0">
				<mat-card>
					<p>{{ 'context_code.cant_associate' | translate | sentencecase }}</p>
				</mat-card>
			</div>
		</div>
	</div>
</form>
