import { Injectable } from '@angular/core';
import { PriceModel } from '../../model/price.model';
import { PricePouchModel, DiscountCurrentModel, PriceDivisionModel } from '@saep-ict/pouch_agent_models';
import { ArticleSheetEnum, DiscountModel, DiscountTypeEnum } from '@saep-ict/angular-core';
import { ArticlePriceState, DiscountStateModel } from '../../model/state/article-list-state.model';
import { couldStartTrivia } from 'typescript';

@Injectable({
	providedIn: 'root'
})
export class UtilPriceService {
	// riferito al vecchio modello dati. Bonificare
	deprecatedMapArticlePrice(price: PriceModel, division?: string): ArticlePriceState {
		let articlePriceState: ArticlePriceState = { price: 0, discount: [] };
		let foundPrice = false;
		let foundDiscount = false;
		let articleSheet = this.selectCorrectArticleSheetForArticle(price.article_sheet);
		let articlePriceList = this.selectCorrectArticlePriceList(price.article_price_list);
		if (articleSheet) {
			({ foundPrice, foundDiscount, articleSheet, articlePriceState } = this.selectAttributeFromSheet(
				articleSheet,
				articlePriceState,
				division
			));
		}
		// end ArticleSheet
		if (!foundPrice || !foundDiscount) {
			if (articlePriceList) {
				({ foundPrice, foundDiscount, articlePriceList, articlePriceState } = this.selectAttributeFromPriceList(
					articlePriceList,
					articlePriceState,
					foundPrice,
					foundDiscount,
					division
				));
			}
		}
		return articlePriceState;
	}

	/**
	 * Get article price from selected division
	 * @param article Current article
	 * @param division Selected division
	 */
	mapArticlePrice(article, division) {
		let articlePrice = { price: 0, discount: [] };
		if (article.divisions) {
			const articleDivision = article.divisions.find(x => x.division === division);
			articlePrice;
			if (articleDivision) {
				articlePrice = {
					price: articleDivision.price,
					discount: this.getArticleDiscounts(articleDivision)
				};
			}
		}
		article.articlePrice = articlePrice;
	}

	/**
	 * Get structured discounts model
	 * @param articleDivision Selected division
	 * @returns Structured discounts
	 */
	getArticleDiscounts(articleDivision): DiscountModel[] {
		return articleDivision.discounts
			.filter(discountValue => discountValue !== 0)
			.map(discountValue => {
				return {
					value: discountValue,
					type: DiscountTypeEnum.percentage
				};
			});
	}

	selectCorrectArticleSheetForArticle(
		articleSheet: PricePouchModel<DiscountCurrentModel>[]
	): PricePouchModel<DiscountCurrentModel> {
		let correctSheet: PricePouchModel<DiscountCurrentModel>;
		Object.keys(ArticleSheetEnum).forEach(type => {
			if (!correctSheet) {
				correctSheet = articleSheet.find(sheet => sheet.sk_type === ArticleSheetEnum[type]);
			}
		});
		return correctSheet;
	}

	selectCorrectArticlePriceList(articlePriceList: PricePouchModel<number>[]): PricePouchModel<number> {
		let correctList: PricePouchModel<number>;
		if (articlePriceList.length > 0) {
			correctList = articlePriceList[0];
		}
		return correctList;
	}

	selectAttributeFromSheet(
		articleSheet: PricePouchModel<DiscountCurrentModel>,
		articlePriceState: ArticlePriceState,
		division?: string
	) {
		let foundPrice = false;
		let foundDiscount = false;
		let articleSheetSelected: PricePouchModel<DiscountCurrentModel> | PriceDivisionModel<DiscountCurrentModel>;

		articleSheetSelected =
			articleSheet.divisions && division ? this.selectCurrentDivision(articleSheet, division) : articleSheet;
		// todo da selezionare con un metodo che ricava il 3 tipi tra current, old ,future
		articleSheetSelected.discounts_current.forEach(articleDiscount => {
			if (articleDiscount.discount_z !== 'Z') {
				if (articleDiscount.discount !== 0) {
					foundDiscount = true;
					if (articleDiscount.discount !== 0) {
						articlePriceState.discount.push(
							new DiscountStateModel({
								value: articleDiscount.discount,
								type: DiscountTypeEnum.percentage
							})
						);
					}
				}
			} else {
				foundDiscount = true;
			}
		});
		if (articleSheetSelected.price_current) {
			foundPrice = true;
			articlePriceState.price = +articleSheetSelected.price_current;
		}
		return {
			foundPrice: foundPrice,
			foundDiscount: foundDiscount,
			articleSheet: articleSheet,
			articlePriceState: articlePriceState
		};
	}

	selectAttributeFromPriceList(
		articlePriceList: PricePouchModel<number>,
		articlePriceState: ArticlePriceState,
		foundPrice: boolean,
		foundDiscount: boolean,
		division?: string
	) {
		let articlePriceListSelected: PricePouchModel<number> | PriceDivisionModel<number>;
		articlePriceListSelected =
			articlePriceList.divisions && division
				? this.selectCurrentDivision(articlePriceList, division)
				: articlePriceList;

		if (!foundPrice) {
			articlePriceState.price = +articlePriceListSelected.price_current;
		}
		if (!foundDiscount) {
			articlePriceListSelected.discounts_current.forEach((articleDiscount: number) => {
				if (articleDiscount !== 0) {
					articlePriceState.discount.push(
						new DiscountStateModel({
							value: articleDiscount,
							type: DiscountTypeEnum.percentage
						})
					);
				}
			});
		}
		return {
			foundPrice: foundPrice,
			foundDiscount: foundDiscount,
			articlePriceList: articlePriceList,
			articlePriceState: articlePriceState
		};
	}

	// deprecato: non serve più partire da una lista prezzi, ma si guarda direttamente in division_list dell'organization
	selectCurrentDivision(priceList: PricePouchModel<any>, division: string): PriceDivisionModel<any> {
		return priceList.divisions.find(priceDivision => {
			return priceDivision.division === division;
		});
	}
}
