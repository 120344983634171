import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { filter, take } from 'rxjs/operators';
import { OrderTrendChartConfigService } from '../../../../service/chart-structure/implementation/order-trend-chart.service';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../../state';
import _ from 'lodash';
import { OrderAverageChartConfigService } from '../../../../service/chart-structure/implementation/order-average-chart.service';
import { OrderQuantityDetailChartConfigService } from '../../../../service/chart-structure/implementation/order-quantity-detail-chart.service';
import { OrderAverageDetailChartConfigService } from '../../../../service/chart-structure/implementation/order-average-detail-chart.service';
import { StatisticsAgentStateAction } from '../../../../state/statistics-agent/statistics-agent.action';
import { MatSelectChange } from '@angular/material/select';
import { chartTimeRange7Days } from '../../../../constants/chart-time-range.constants';
import { ProductsBestSellerChartConfigService } from '../../../../service/chart-structure/implementation/products-best-seller-chart.service';
import { ProductsTop5TableConfigService } from '../../../../service/chart-structure/implementation/products-top5-table.service';
import { StatisticsDetailOrdersStateAction } from '../../../../state/statistics-detail-orders/statistics-detail-orders.action';
import { StatisticsDetailOrders } from '../../../../model/statistics-detail-orders.model';
// import { CategoriesBestSellerChartConfigService } from '../../../../service/chart-structure/implementation/categories-best-seller-chart.service';
// import { CategoriesTop5TableConfigService } from '../../../../service/chart-structure/implementation/categories-top-5-table.service';

/*
	All what concerns CATEGORIES has been commented out waiting for more information about retrieval
*/

@Component({
	selector: 'dashboard-orders',
	templateUrl: './dashboard-orders.component.html',
	styleUrls: ['./dashboard-orders.component.scss'],
	providers: [
		OrderTrendChartConfigService,
		OrderAverageChartConfigService,
		OrderQuantityDetailChartConfigService,
		OrderAverageDetailChartConfigService,
		ProductsBestSellerChartConfigService,
		ProductsTop5TableConfigService
		// CategoriesBestSellerChartConfigService,
		// CategoriesTop5TableConfigService
	]
})
export class DashboardOrdersComponent implements OnDestroy {
	// Per la visualizzazione della data di ultimo aggiornamento delle statistiche
	dateUpdatedStatistics: number;
	statistics$: Observable<BaseStateModel<StatisticsDetailOrders>> = this.store.select(
		StateFeature.getStatisticsDetailOrders
	);
	statistics: StatisticsDetailOrders;

	// Timerange
	timeRange = chartTimeRange7Days;

	constructor(
		private store: Store<any>,
		public orderTrendChartConfigService: OrderTrendChartConfigService,
		public orderAverageDetailChartConfigService: OrderAverageDetailChartConfigService,
		public orderQuantityDetailChartConfigService: OrderQuantityDetailChartConfigService,
		public productsBestSellerChartConfigService: ProductsBestSellerChartConfigService,
		public productsTop5TableConfigService: ProductsTop5TableConfigService // public categoriesBestSellerChartConfigService: CategoriesBestSellerChartConfigService, // public categoriesTop5TableConfigService: CategoriesTop5TableConfigService
	) {
		// Load Statistics agent
		// this.store.dispatch(StatisticsAgentStateAction.load());
		this.store.dispatch(StatisticsDetailOrdersStateAction.load());

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statistics$
			.pipe(
				filter((state: BaseStateModel<StatisticsDetailOrders>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.update_date;
			});
	}

	setTimeRange(selectedTimeRange: MatSelectChange) {
		const charts = [
			this.orderTrendChartConfigService,
			this.orderQuantityDetailChartConfigService,
			this.orderAverageDetailChartConfigService,
			this.productsBestSellerChartConfigService
			// this.categoriesBestSellerChartConfigService
		];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [
			this.productsTop5TableConfigService
			// this.categoriesTop5TableConfigService
		];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.store.dispatch(StatisticsDetailOrdersStateAction.reset());
	}
}
