import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PsToTopService {
	public showToTopSubject = new BehaviorSubject(false);
	_show = false;

	public updateShowToTop(show: boolean) {
		if (this._show !== show) {
			this._show = show;
			this.showToTopSubject.next(show);
		}
	}
}
