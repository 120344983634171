import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// model
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { ROUTE_URL } from '../../../router/route-naming';

// store
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { UserStateAction } from '../../../state/user/user.actions';

// misc
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { UtilService } from '@saep-ict/angular-core';
import { LanguageStateModel } from '../../../model/state/language-state.model';
import { ExtendedUserDetailModel } from '../../../model/user.model';

export interface PersonalInfoUpdate {
	id: string;
	first_name?: string;
	last_name?: string;
}

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class UserComponent implements OnInit, OnDestroy {
	@ViewChild('firstElementFocus') firstElementFocus: ElementRef;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	languageList$: Observable<BaseStateModel<LanguageStateModel[]>> = this.store.select(StateFeature.getLanguageList);
	languageList: LanguageStateModel[];

	// Form
	form: FormGroup;
	isEditing = false;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	currentContext: ContextApplicationItemCodeEnum;
	ROUTE_URL = ROUTE_URL;

	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		private subscribeManagerService: SubscribeManagerService,
		public permissionContextListColumnService: PermissionContextListColumnService,
		public permissionUtilService: PermissionUtilService,
		private utilService: UtilService
	) {
		this.createForm();
		this.setVisibleTableColumn();
		this.subscribeManagerService.populate(this.subscribeUserState().subscribe(), 'user-state');

		// language list
		this.languageList$.pipe(take(1)).subscribe(res => {
			this.languageList = res ? res.data : null;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	subscribeUserState() {
		return this.user$.pipe(
			skipWhile((state: BaseStateModel<UserDetailModel>) => !(state && state.data)),
			map((state: BaseStateModel<UserDetailModel>) => {
				if (state) {
					this.user = state ? state.data : null;
					this.currentContext = this.user.current_permission.context_application;
					this.updateFormFieldValue();
					this.form.disable();
					this.isEditing = false;
				}
			})
		);
	}

	// form
	createForm() {
		this.form = this.fb.group({
			name: [{ value: null, disabled: !this.isEditing }],
			lastName: [{ value: null, disabled: !this.isEditing }],
			language: [{ value: null, disabled: !this.isEditing }],
			email: [{ value: null, disabled: true }]
		});
	}

	updateFormFieldValue() {
		this.form.patchValue({
			name: this.user.first_name ? this.user.first_name : null,
			lastName: this.user.last_name ? this.user.last_name : null,
			language: (this.user as ExtendedUserDetailModel).language || null,
			email: this.user.username ? this.user.username : null
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			let updatedUser = _.cloneDeep(this.user);
			updatedUser.first_name = this.form.value.name;
			updatedUser.last_name = this.form.value.lastName;
			(updatedUser as ExtendedUserDetailModel).language = this.form.value.language;
			delete updatedUser.avatar;

			this.store.dispatch(UserStateAction.save(new BaseState(updatedUser)));
		}
	}

	editForm() {
		this.isEditing = true;
		['name', 'lastName', 'language'].forEach(field => this.form.get(field).enable());
		this.firstElementFocus.nativeElement.focus();
	}

	// utility & widget
	setVisibleTableColumn() {
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'permission')
		].hidden = true;
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'action')
		].hidden = true;
	}
}
