import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { OpportunityPouchModel } from '../../../model/state/opportunity-state.model';
import { StateFeature } from '../../../state';
import { OpportunityActionEnum } from '../../../state/opportunity/opportunity.actions';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { OfferStateModel } from '../../../model/state/offer-state.model';
import { OfferListColumnService } from '../../../service/td-data-table/implementation/offer-list.service';
import { OfferStateAction, OfferActionEnum } from '../../../state/offer/offer.actions';
import * as _ from 'lodash';
@Component({
	selector: 'offer',
	templateUrl: './offer.component.html',
	styleUrls: ['./offer.component.scss'],
	providers: [SubscribeManagerService, OfferListColumnService]
})
export class OfferComponent implements OnInit, OnDestroy {
	// state
	opportunity: OpportunityPouchModel;
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	offerList: OfferStateModel[];
	offer$: Observable<BaseStateModel<OfferStateModel[]>> = this.store.select(StateFeature.getOfferState);

	public idOpportunity = this.activatedRoute.parent.snapshot.paramMap.get('idOpportunity');

	// table
	public columns = this.offerListColumnService.columns;

	public listPageBaseData = <LocalListHandlerBaseModel<OfferStateModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'title',
			order: 'ASC'
		}
	};

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router,
		public activatedRoute: ActivatedRoute,
		private offerListColumnService: OfferListColumnService
	) {
		this.store.dispatch(OfferStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeOfferList().subscribe(), 'subscribeOfferList');
		this.handleColumnVisibility();

		this.opportunity$.pipe(take(1)).subscribe(res => {
			this.opportunity = res ? res.data[0] : null;
		});
	}

	ngOnInit() {}

	handleColumnVisibility() {
		// se sono nel contesto specifico nascondo
		// la colonna con il nome della opportunity
		if (this.idOpportunity) {
			this.columns.map(col => {
				col.hidden = ['opportunity.title'].includes(col.name);
			});
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OfferStateAction.reset());
		// Reset dello state opportunity nel componente padre
	}

	subscribeOfferList() {
		return this.offer$.pipe(
			filter((offerState: BaseStateModel<OfferStateModel[]>) => !!(offerState && offerState.data)),
			map(offerState => {
				switch (offerState.type) {
					case OfferActionEnum.UPDATE:
						this.applyPermanentFilters(offerState.data);
						break;

					default:
						break;
				}
			})
		);
	}

	applyPermanentFilters(offerList: OfferStateModel[]) {
		if (this.idOpportunity) {
			// se sono nel contesto specifico filtro solo le offerte di questa opportunity
			this.offerList = offerList.filter(offer =>
				offer.opportunity ? offer.opportunity._id === this.idOpportunity : false
			);
		} else {
			this.offerList = offerList;
		}

		this.updateListPageBaseData(this.offerList);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: OfferStateModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	createNew() {
		this.router.navigate([
			ROUTE_URL.private,
			ROUTE_URL.offer,
			this.idOpportunity,
			ROUTE_URL.newOffer
		]);
	}

	goToOfferDetail(offer: OfferStateModel) {
		if (offer && offer.opportunity) {
			this.router.navigate([ROUTE_URL.private, ROUTE_URL.offer, offer.opportunity._id, offer._id]);
		} else {
			console.error('goToOfferDetail: offer non presente');
		}
	}
}
