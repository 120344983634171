<div class="quantity-button" [class.disabled]="disabled">
	<button
		*ngIf="showButton"
		matRipple
		type="button"
		class="quantity-button__less"
		(click)="changeQuantity('sub')"
		[disabled]="disabled || value < 1"
	>
		<mat-icon class="mat-icon">remove</mat-icon>
	</button>
	<div class="quantity-button__number">
		<input
			appOnlyNumbers
			#productQty
			type="number"
			[(value)]="value"
			min="0"
			(blur)="onBlur($event)"
			[disabled]="disabled"
			[readonly]="readonly"
		/>
	</div>
	<button
		*ngIf="showButton"
		matRipple
		type="button"
		class="quantity-button__more"
		(click)="changeQuantity('add')"
		[disabled]="disabled"
	>
		<mat-icon class="mat-icon">add</mat-icon>
	</button>
</div>
