import { UtilCategoryListService } from '../../service/util/util-category-list.service';
import { ArticleCategory } from './../../model/article.model';
import { Category } from './../../model/category-list.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ArticlePouchModel, BasePouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ArticleRecapArticleList, ArticleState } from '../../model/state/article-list-state.model';
import { BaseState, BaseStateModel, LinkCodeModel, PouchAdapterEnum } from '@saep-ict/angular-core';
import { ArticleFilterModel, ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';

import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { UtilPriceService } from '../../service/util/util-price.service';
import { ArticleListActionEnum, ArticleListStateAction } from './article-list.actions';
import { IS_MULTIDIVISION, DEFAULT_DIVISION_CODE } from '../../constants/division.constants';
import { UtilArticleKitService } from '../../service/util/util-article-kit.service';
import { CustomerAppConfig } from '../../customer-app.config';
import { CatalogService } from '../../service/rest/catalog.service';
import { StoreUtilService } from '../../service/util/store-util.service';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { LocalStorage } from 'ngx-webstorage';
import { CouchDocumentType } from '../../constants/context-state.map';

@Injectable()
export class ArticleListEffects {
	@LocalStorage('link_code') link_code: LinkCodeModel;

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<ArticleListFilterModel>) => from(this.getArticleList(action))),
			map((articleList: BaseStateModel<ArticleState[]>) => ArticleListStateAction.update(articleList)),
			catchError((error, caught) => {
				this.store.dispatch(ArticleListStateAction.error(null));
				return caught;
			})
		)
	);
	loadFromRecap$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleListActionEnum.LOAD_FROM_RECAP),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.getArticleListFromRecap(action))
			),
			map((articleList: BaseStateModel<ArticleState[]>) => ArticleListStateAction.update(articleList)),
			catchError((error, caught) => {
				this.store.dispatch(ArticleListStateAction.error(null));
				return caught;
			})
		)
	);

	loadWithDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleListActionEnum.LOAD_WITH_DETAIL),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.getArticleListWithPrice(action))
			),
			map((articleList: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				ArticleListStateAction.update(articleList)
			),
			catchError((error, caught) => {
				this.store.dispatch(ArticleListStateAction.error(null));
				return caught;
			})
		)
	);

	loadFromCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleListActionEnum.LOAD_FROM_CATEGORY),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.getCategoryListArticle(action))
			),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.getArticleListWithPrice(action))
			),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.mergeCategoryInformation(action))
			),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.mergeArticleKitDetails(action))
			),
			map((articleList: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				ArticleListStateAction.update(articleList)
			),
			catchError((error, caught) => {
				this.store.dispatch(ArticleListStateAction.error(null));
				return caught;
			})
		)
	);

	loadListFromCode$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleListActionEnum.LOAD_LIST_FROM_CODE),
			mergeMap((action: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				from(this.getArticleListFromSingleCall(action))
			),
			map((articleList: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				ArticleListStateAction.update(articleList)
			),
			catchError((error, caught) => {
				this.store.dispatch(ArticleListStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleListActionEnum.LOAD_ALL),
			mergeMap((action: { forceDefault: boolean }) => from(this.getAllArticlesB2c(action.forceDefault))),
			map((articleList: BaseStateModel<ArticleState[], ArticleListFilterModel>) =>
				ArticleListStateAction.update(articleList)
			),
			catchError((error, caught) => {
				this.store.dispatch(ArticleListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPriceService: UtilPriceService,
		private utilCategoryListService: UtilCategoryListService,
		private utilArticleKitService: UtilArticleKitService,
		private appConfig: CustomerAppConfig,
		private catalogService: CatalogService,
		private storeUtilService: StoreUtilService,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getAllArticlesB2c(forceDefault?: boolean) {
		return new Promise((resolve, reject) => {
			if (this.appConfig.authenticationToken && !forceDefault) {
				// TODO: update model when the new recap model will be loaded on couch library
				this.storeUtilService
					.getCouchDetailAndReturnInDetailState({
						data: { _id: this.link_code ? `article_recap_${this.link_code.code}` : null }
					})
					.then(result => resolve(new BaseState(result.data['article_list'])));
			} else {
				// get articles from commons_article/article_recap_DEFAULT doc
				resolve(this.catalogService.getArticles({ filter: 'DEFAULT' }));
			}
		})
			.then(res => {
				return res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	async getArticleList(
		filter: BaseStateModel<any, any>
	): Promise<BaseStateModel<ArticleState[], ArticleListFilterModel>> {
		return (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).articlePouch
			.getArticleList(filter)
			.then((doc: ArticlePouchModel[]) => {
				return new BaseState(doc, filter.dataSetting);
			})
			.catch(err => {
				throw new Error(err.message);
			});
	}

	async getArticleListFromRecap(
		filter: BaseStateModel<ArticleState[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticleRecapArticleList[]>> {
		const customerCode = filter.dataSetting.appliedFilter.organization
			? filter.dataSetting.appliedFilter.organization.code
			: null;
		return new Promise(async (resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				(await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ARTICLE)).articlePouch
					.getArticleRecap(customerCode)
					.then(result => resolve(result.article_list));
			} else {
				// get articles from commons_article/article_recap_DEFAULT doc
				this.catalogService.getArticles({ filter: 'DEFAULT' }).then(res => resolve(res.data));
			}
		})
			.then((res: ArticleRecapArticleList[]) => {
				for (const article of res) {
					article.productDetail = {};
					const division = IS_MULTIDIVISION
						? filter.dataSetting.appliedFilter.division
						: DEFAULT_DIVISION_CODE;
					this.utilPriceService.mapArticlePrice(article, division);
				}
				return new BaseState(res);
			})
			.catch(err => {
				throw new Error(err);
			});
	}

	async getArticleListFromSingleCall(
		filter: BaseStateModel<ArticleState[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticleState[], ArticleListFilterModel>> {
		const promiseList = [];
		const articleList: ArticleState[] = [];
		for (let i = 0; i < filter.dataSetting.appliedFilter.article.code_list.length; i++) {
			const code = filter.dataSetting.appliedFilter.article.code_list[i];
			promiseList.push(
				(await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).articlePouch
					.getArticleDetail({ id: code })
					.then((article: ArticleState) => {
						article.productDetail = {};
						articleList[i] = article;
					})
					.catch((err: PouchErrorResponse) => {
						console.log(err, 'article not found');
						return articleList;
					})
			);
		}
		await Promise.all(promiseList);
		return new BaseState(articleList, filter.dataSetting);
	}

	async getArticleListWithPrice(
		filter: BaseStateModel<ArticleState[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticleState[], ArticleListFilterModel>> {
		try {
			let articleList: BaseState<ArticleState[], ArticleListFilterModel>;
			if (filter.dataSetting.appliedFilter.article.code_list) {
				await this.getArticleListFromSingleCall(filter)
					.then(res => (articleList = res))
					.catch(err => console.error(err));
			} else {
				await this.getArticleList(filter)
					.then(res => (articleList = res))
					.catch(err => console.error(err));
			}
			const promiseList = [];
			for (const article of articleList.data) {
				const articleFilter: ArticleFilterModel = {
					organization: filter.dataSetting.appliedFilter.organization,
					article: article,
					valid: true
				};
				promiseList.push(
					(await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).pricePouch
						.getPrice(articleFilter)
						.then(doc => {
							article.productDetail = {};
							const division = IS_MULTIDIVISION
								? filter.dataSetting.appliedFilter.division
								: DEFAULT_DIVISION_CODE;
							article.articlePrice = this.utilPriceService.deprecatedMapArticlePrice(doc, division);
							// article.articlePrice = this.utilPriceService.deprecatedMapArticlePrice(doc);
							return article;
						})
						.catch((err: PouchErrorResponse) => {
							console.log(err);
							article.productDetail = {};
							article.articlePrice = { price: 0, discount: [] };
							return article;
						})
				);
			}
			await Promise.all(promiseList);
			return articleList;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async getCategoryListArticle(
		action: BaseStateModel<ArticleState[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticleState[], ArticleListFilterModel>> {
		const categoryListParameter: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel> = {
			data: null,
			dataSetting: {
				adapter: PouchAdapterEnum.AGENT,
				pagination: action.dataSetting.pagination,
				appliedFilter: {
					article: {
						has_hierarchy: action.dataSetting.appliedFilter.article.has_hierarchy,
						only_without_children: true,
						text: action.dataSetting.appliedFilter.article.description
					},
					organization: action.dataSetting.appliedFilter.organization
				}
			}
		};
		let articleListState = <BaseStateModel<ArticleState[], ArticleListFilterModel>>{};
		try {
			return this.utilCategoryListService
				.loadCategoryList(categoryListParameter)
				.then(res => {
					articleListState = {
						data: null,
						dataSetting: {
							appliedFilter: {
								article: {
									code_list: res.data.map(i => i.code),
									categoryList: res.data
								},
								organization: action.dataSetting.appliedFilter.organization
							},
							pagination: action.dataSetting.pagination,
							sort: action.dataSetting.sort
						}
					};
					return new BaseState(null, articleListState.dataSetting);
				})
				.catch(err => {
					console.log(err);
					return new BaseState(null, articleListState.dataSetting);
				});
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}

	async mergeArticleKitDetails(
		action: BaseStateModel<ArticleState[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticleState[], ArticleListFilterModel>> {
		// ciclo tutti i prodotti
		for (let i = 0; i < action.data.length; i++) {
			action.data[i].articleKitList = [];

			// se hanno i kit
			if (action.data[i].art_kit === 'S') {
				// recupero il dettaglio
				await this.utilArticleKitService
					.getArticleKitDetail(action.data[i], action.dataSetting.appliedFilter.organization)
					.then((res: ArticleState[]) => {
						action.data[i].articleKitList = res;
					})
					.catch((err: PouchErrorResponse) => {
						console.log(err, 'article kit not found');
						return null;
					});
			}
		}

		return action;
	}

	async mergeCategoryInformation(
		action: BaseStateModel<ArticleState[], ArticleListFilterModel>
	): Promise<BaseStateModel<ArticleState[], ArticleListFilterModel>> {
		for (let i = 0; i < action.data.length; i++) {
			action.data[i].categoryNodeList = [
				action.dataSetting.appliedFilter.article.categoryList.find(f => f.code === action.data[i].code)
			];
		}
		return action;
	}
}
