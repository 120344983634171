<div class="container-fluid">
	<!-- HEADER -->
	<div class="header">
		<!-- Timerange -->
		<div>{{ 'chart.field.time_range' | translate | sentencecase }}</div>
		&nbsp; &nbsp;
		<mat-form-field class="header__timerange">
			<mat-select [value]="timeRange.values[0]" (selectionChange)="setTimeRange($event)">
				<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
					{{ 'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Separator -->
		<div class="header__separator"></div>

		<!-- Date last update statistics -->
		<div class="header__statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>

	<!-- CLIENT TREND -->
	<div class="row">
		<!-- Clients: active -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="activeClientsChartConfigService.cardDashboardConfigClientsActive">
				<div class="clients-value">
					<div class="row clients-value__header" *ngIf="activeClientsChartConfigService.metrics">
						<div class="clients-value__header__average">
							{{ activeClientsChartConfigService.metrics.average | number: '1.2-2':'it' }}
						</div>
						<div class="clients-value__header__separator"></div>
						<div class="clients-value__header__trend">
							<icon-trend [value]="activeClientsChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row clients-value__body">
						<chart-wrapper
							class="row clients-value__body__chart"
							[config]="activeClientsChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>

		<!-- Clients: inactive -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				class="card-dashboard-accent"
				[cardDashboardConfig]="inactiveClientsChartConfigService.cardDashboardConfigClientsInactive"
			>
				<div class="clients-amount">
					<div class="row clients-amount__header" *ngIf="inactiveClientsChartConfigService.metrics">
						<div class="clients-amount__header__average">
							{{ inactiveClientsChartConfigService.metrics.average | number: '1.2-2':'it' }}
						</div>
						<div class="clients-value__header__separator"></div>
						<div class="clients-amount__header__trend">
							<icon-trend [value]="inactiveClientsChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row clients-amount__body">
						<chart-wrapper
							class="row clients-amount__body__chart"
							[config]="inactiveClientsChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<!-- CLIENTS LIST TABS: active and inactive -->
	<div class="row"></div>

	<!-- ORDERS AVERAGE -->
	<div class="row">
		<!-- Chart: Average value of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6"></div>

		<!-- Table: Average value of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6"></div>
	</div>

	<!-- ORDERS QUANTITY -->
	<div class="row">
		<!-- Chart: number of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6"></div>

		<!-- Table: number of the orders for each client -->
		<div class="col-sm-12 col-md-12 col-lg-6"></div>
	</div>
</div>
