import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { combineLatest, from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { CustomerAppConfig } from '../../customer-app.config';
import { ArticleCategory } from '../../model/article.model';
import { Category } from '../../model/category-list.model';
import { HighlightsViewModel, HomeHighlightsModel } from '../../model/home-highlights.model';
import { ArticleState } from '../../model/state/article-list-state.model';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { PouchDbCommonsAdapter } from '../../service/pouch-db/spin8/pouchdb-commons.adapter';
import { StorefrontConfigurationService } from '../../service/rest/storefront-configuration.service';
import { StateFeature } from '../../state';
import { ArticleListEffects } from '../article-list/article-list.effects';
import { HomeHighlightsActionEnum, HomeHighlightsStateAction } from './home-highlights.actions';

@Injectable()
export class HomeHighlightsEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(HomeHighlightsActionEnum.LOAD),
			mergeMap((action: BaseStateModel<HomeHighlightsModel>) => from(this.getHomeHighlights())),
			mergeMap((highlights: Observable<BaseStateModel<HighlightsViewModel>>) => highlights),
			map((highlights: BaseStateModel<HighlightsViewModel>) => HomeHighlightsStateAction.update(highlights)),
			catchError((error, caught) => {
				this.store.dispatch(HomeHighlightsStateAction.error(null));
				return caught;
			})
		)
	);

	categoryList$: Observable<BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	articleList$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleList);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbCommonsAdapter: PouchDbCommonsAdapter,
		private appConfig: CustomerAppConfig,
		private storefrontConfigurationService: StorefrontConfigurationService
	) {}

	async getHomeHighlights(): Promise<Observable<BaseStateModel<HighlightsViewModel>>> {
		try {
			return new Promise((resolve, reject) => {
				if (this.appConfig.authenticationToken) {
					resolve(this.pouchDbCommonsAdapter.homeHighlightsPouch.getHighlights());
				} else {
					this.storefrontConfigurationService.getConfiguration(conf => resolve(conf.data));
				}
			}).then(async (highlights: HomeHighlightsModel) => {
				let highlightsView: HighlightsViewModel = Object.assign(
					{},
					(highlights as unknown) as HighlightsViewModel
				);
				highlightsView.products = [];
				highlightsView.categories = [];
				return combineLatest([this.articleList$, this.categoryList$]).pipe(
					map(([articleList, categoryList]) => {
						if (articleList && articleList.data) {
							highlightsView.main = articleList.data.find(article => article.code == highlights.main);
							for (const productCode of highlights.products) {
								const articleDetail: ArticleState = articleList.data.find(
									article => article.code == productCode
								);
								highlightsView.products.push(articleDetail);
							}
						}
						if (categoryList && categoryList.data) {
							for (const categoryCode of highlights.categories) {
								const categoryDetail: any = categoryList.data.find(
									category => category._id == categoryCode
								);
								highlightsView.categories.push(categoryDetail);
							}
						}
						return new BaseState(highlightsView);
					})
				);
			});
		} catch (err) {
			throw new Error(err);
		}
	}
}
