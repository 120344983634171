<div class="user-button-container" #userButton (clickOutside)="onClickOutside()" *ngIf="user">
	<button
		mat-button
		class="user-button-container__button"
		(click)="toggleDropdown()"
		[class.open]="isOpen"
		[matTooltip]="user.username"
	>
		<div class="button-content__wrapper">
			<mat-icon>account_circle</mat-icon>
			<div class="button-content__label">
				<span class="button-content__label__user-info">
					{{ userInfo }}
				</span>
				<span class="button-content__label__user-role opacity-50" *ngIf="userRole">
					{{ userRole | translate | uppercase }}
				</span>
			</div>
			<mat-icon class="arrow-down" [class.rotate]="isOpen">keyboard_arrow_down</mat-icon>
		</div>
	</button>

	<div class="user-button-container__dropdown mat-elevation-z1" [class.open]="isOpen" [class.open-up]="openUp">
		<!-- Profile -->
		<ng-template [ngIf]="user.current_permission?.context_application != ContextApplicationItemCodeEnum.B2C">
			<button
				mat-button
				[routerLink]="'/' + ROUTE_URL.private + '/' + ROUTE_URL.user"
				class="dropdown__item"
				(click)="toggleDropdown()"
			>
				<mat-icon class="dropdown__item__icon">perm_identity</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.profile' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- Area riservata -->
		<!-- Pulsante mostrato se il contesto selezionato è B2C (lo /dashboard è presente per qualsiasi altro contesto loggato, 
			 quindi non più discriminante in questo specifico caso)-->
		<ng-template [ngIf]="user.current_permission?.context_application == ContextApplicationItemCodeEnum.B2C">
			<button mat-button [routerLink]="[PATH_URL.MY_ACCOUNT]" class="dropdown__item" (click)="toggleDropdown()">
				<mat-icon class="dropdown__item__icon">perm_identity</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.reserved_area' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- Go to /dashboard -->
		<ng-template
			[ngIf]="
				!router.url.includes(ROUTE_URL.private) &&
				user.current_permission?.context_application != ContextApplicationItemCodeEnum.B2C
			"
		>
			<mat-divider></mat-divider>

			<button mat-button class="dropdown__item" [routerLink]="'/' + ROUTE_URL.private">
				<mat-icon class="dropdown__item__icon">swap_horiz</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.dashboard' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- Go to storefront -->
		<ng-template
			[ngIf]="
				router.url.includes(ROUTE_URL.private) &&
				appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C) &&
				user.current_permission.context_application != ContextApplicationItemCodeEnum.B2C
			"
		>
			<mat-divider></mat-divider>

			<button mat-button class="dropdown__item" [routerLink]="'/' + ROUTE_URL.public">
				<mat-icon class="dropdown__item__icon">swap_horiz</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.storefront' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- Change context -->
		<ng-template [ngIf]="user.context_application_list.length > 1 || hasMultipleContextCode">
			<mat-divider></mat-divider>
			<button mat-button class="dropdown__item" (click)="authService.changeContext()">
				<mat-icon class="dropdown__item__icon">swap_horiz</mat-icon>
				<span class="dropdown__item__title">
					{{ 'general.change_context' | translate | sentencecase }}
				</span>
			</button>
		</ng-template>

		<!-- Language -->
		<mat-divider></mat-divider>
		<div mat-button class="dropdown__item" *ngIf="languages && languages.length > 1">
			<mat-select (selectionChange)="langChange($event)" [(ngModel)]="langSelected">
				<mat-option *ngFor="let lang of languages" [value]="lang"
					>{{ 'language.name' | translate | sentencecase }}: {{ lang | uppercase }}</mat-option
				>
			</mat-select>
		</div>

		<!-- Fontsize -->
		<mat-divider></mat-divider>
		<div mat-button class="dropdown__item">
			<mat-select
				(selectionChange)="utilFontsizeService.fontsizeChange($event)"
				[(ngModel)]="utilFontsizeService.fontSizeSelected"
			>
				<mat-option *ngFor="let fontsize of utilFontsizeService.fontsizeList" [value]="fontsize">
					{{ 'general.fontsize.name' | translate | sentencecase }}:
					{{ 'general.fontsize.' + fontsize | translate | sentencecase }}
				</mat-option>
			</mat-select>
		</div>

		<!-- Logout -->
		<mat-divider></mat-divider>
		<button mat-button (click)="authService.logout()" class="dropdown__item" (click)="toggleDropdown()">
			<mat-icon class="dropdown__item__icon">exit_to_app</mat-icon>
			<span class="dropdown__item__title"> Logout </span>
		</button>
	</div>
</div>
