import { DatePipe, Location, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	ArticleAvailableEnum,
	BaseState,
	BaseStateModel,
	CausalHeaderOrderEnum,
	ContextApplicationItemCodeEnum,
	DateMomentService,
	DialogConfirmComponent,
	SubscribeManagerService,
	UserDetailModel,
	UtilCustomerService,
	UtilService
} from '@saep-ict/angular-core';
import {
	BasePouchModel,
	BodyTablePouchModel,
	DivisionPouchModel,
	OrganizationPouchModel,
	DestinationNew,
	DestinationPouchModel,
	OrderPouchModel,
	OrderStatusEnum,
	PaymentPouchModel,
	OrganizationLevelEnum,
	AddressTypeEnum
} from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, mergeMap, skipWhile, take, tap } from 'rxjs/operators';
import { DEFAULT_DESTINATION_CODE } from '../../../../constants/destination.constants';
import { DEFAULT_DIVISION_CODE, IS_MULTIDIVISION } from '../../../../constants/division.constants';
import { OrderBackoffice } from '../../../../constants/order.constants';
import {
	AuxiliaryTableStateModel,
	LocationDepartureDivisionStateModel
} from '../../../../model/state/auxiliary-table-list';
import {
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	OrderWithArticleDetailRequest,
	Transport
} from '../../../../model/state/order-state.model';
import { StatisticsOrders } from '../../../../model/statistics-orders.model';
import { PATH_URL, ROUTE_URL } from '../../../../router/route-naming';
import { StatisticsOrdersFilter } from '../../../../service/pouch-db/filter/statistics-orders-filter.model';
import { StatusBarOrderService } from '../../../../service/status-bar-config/implementation/status-bar-order.service';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { OrderIdFormatterPipe } from '../../../../shared/pipes/order-id-formatter.pipe';
import { StateFeature } from '../../../../state';
import { AuxiliaryTableStateAction } from '../../../../state/auxiliary-table/auxiliary-table.actions';
import { OrganizationActionEnum, OrganizationStateAction } from '../../../../state/organization/organization.actions';
import { DestinationListStateAction } from '../../../../state/destination-list/destination-list.actions';
import { OrderActionEnum, OrderStateAction } from '../../../../state/order/order.actions';
import { StatisticsOrdersStateAction } from '../../../../state/statistics-orders/statistics-orders.action';
import { DialogOtherDestinationComponent } from '../../../../widget/dialog/dialog-other-destination/dialog-other-destination.component';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UtilCompanyService } from '../../../../service/util/util-company.service';
import { OrganizationStateModel } from '../../../../model/state/organization-state.model';
import { HighlightsViewModel } from '../../../../model/home-highlights.model';
import { OrganizationListStateAction } from '../../../../state/common/organization-list/organization-list.actions';

@Component({
	selector: 'app-order-detail',
	templateUrl: './order-detail.component.html',
	styleUrls: ['./order-detail.component.scss'],
	providers: [SubscribeManagerService, StatusBarOrderService]
})
export class OrderDetailComponent implements OnInit, OnDestroy {
	@ViewChild('toggleNewDestination') toggleNewDestination: MatSlideToggle;
	@ViewChild('toggleOrderCausal') toggleOrderCausal: MatSlideToggle;
	// ngrx state start
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;
	configurationTable$: Observable<BaseStateModel<HighlightsViewModel>> = this.store.select(
		StateFeature.getHomeHighlightsState
	);
	configurationTable: HighlightsViewModel;
	organizationList$: Observable<BaseStateModel<OrganizationPouchModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: BaseStateModel<OrganizationPouchModel[]>;
	destinationList: DestinationPouchModel[];

	// ngrx state end
	organizationId: string;
	orderId: string;
	orderStatus: string;
	organizationPermission: OrganizationPouchModel;
	// order util data
	locationDepartureDivision: LocationDepartureDivisionStateModel[];
	paymentList: { name: string; payment: PaymentPouchModel[] }[];
	paymentDivision: PaymentPouchModel[];
	paymentDefault: PaymentPouchModel[];
	methodDeliveryList: BodyTablePouchModel[];
	// enum
	orderStatusEnum = OrderStatusEnum;
	causalHeaderOrderEnum = CausalHeaderOrderEnum;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	// form
	form: FormGroup;
	// check date
	today: Date = new Date();
	minDate: Date = new Date(
		new Date().setDate(this.today.getHours() - 12 > 0 ? this.today.getDate() + 1 : this.today.getDate())
	);
	maxDate = moment(this.today).add(30, 'days');
	minDateSelected: Date = this.utilOrderService.getFirstValidDate(this.minDate, 1);
	setTodayDate = false;
	setTodayMinusFiveDaysDate = false;
	setProductPartiallyAvailable = false;
	setProductNotAvailable = false;
	orderProductListNotFound = [];

	changeFormSubscription;
	breadcrumbTitle: string;
	breadcrumbPathList: string[];
	// tab wrapper
	tabsToExclude = [];
	// backoffice
	organizationToBeValidated: boolean = false;

	currentContext: ContextApplicationItemCodeEnum;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private location: Location,
		public utilOrderService: UtilOrderService,
		public utilCompanyService: UtilCompanyService,
		public utilService: UtilService,
		public statusBardOrderService: StatusBarOrderService,
		private dialog: MatDialog,
		private utilCustomerService: UtilCustomerService,
		private dateMomentService: DateMomentService,
		private snackBar: MatSnackBar,
		private orderIdFormatterPipe: OrderIdFormatterPipe,
		private translate: TranslateService,
		private titlecasePipe: TitleCasePipe
	) {
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.organizationId = this.route.snapshot.paramMap.get('organizationId');
		this.orderId = this.route.snapshot.paramMap.get('orderId');
		this.orderStatus = this.route.snapshot.paramMap.get('orderStatus');
		this.getTabsToExclude();
		this.statusBardOrderService.selectOnly(this.orderStatus);
		const organizationRequest: BasePouchModel = { _id: this.organizationId };
		this.utilOrderService.retrieveSyncState<UserDetailModel>(this.user$).subscribe(res => {
			this.user = res.data;
			this.currentContext = this.user.current_permission.context_application;
		});
		this.utilOrderService.retrieveSyncState<HighlightsViewModel>(this.configurationTable$).subscribe(res => {
			this.configurationTable = res.data;
		});
		this.subscribeManagerService.populate(
			this.retrieveAuxiliaryTableState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
				this.auxiliaryTable = res.data;
			}),
			'auxiliary-table'
		);
		this.utilOrderService.retrieveSyncState<OrganizationPouchModel[]>(this.organizationList$).subscribe(res => {
			this.organizationList = res;
			this.organization = this.organizationList.data.find(x => x['code_item'] === this.organizationId);
			this.store.dispatch(OrganizationStateAction.update(new BaseState(this.organization)));
			this.destinationList = this.organization?.destination_list;
			if (this.destinationList) {
				this.destinationList.sort((a, b) => (a.code > b.code ? 1 : -1));
				this.store.dispatch(DestinationListStateAction.update(new BaseState(this.destinationList)));
			}
		});
		this.createForm();
		if (this.organizationList) {
			this.verifyOrganizationPermission();
		}
		if (this.organizationPermission || this.currentContext === ContextApplicationItemCodeEnum.BACKOFFICE) {
			// this.store.dispatch(OrganizationStateAction.load(new BaseState(organizationRequest)));
			this.subscribeManagerService.populate(
				this.initMandatoryData().subscribe(
					res => {},
					error => {
						console.log('something went wrong ', error);
						// this.router.navigate(['/not-found']);
					}
				),
				'order-data'
			);
		}
	}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.utilOrderService.propagationSubmitForm.subscribe(res => {
				if (res) {
					this.form.markAllAsTouched();
				}
			}),
			'submit-form'
		);
	}

	getTabsToExclude() {
		if (this.orderStatus) {
			switch (OrderStatusEnum[this.orderStatus.toUpperCase()]) {
				// tabs: catalogo, checkout
				case OrderStatusEnum.DRAFT:
				case OrderStatusEnum.NOT_AUTHORIZED:
					this.tabsToExclude = ['variation', 'checkout'];
					break;
				// tabs: checkout, variazioni
				case OrderStatusEnum.CONSOLIDATED:
					this.tabsToExclude = ['checkout'];
				case OrderStatusEnum.PARTIALLY_FULFILLED:
					this.tabsToExclude = ['checkout'];
				case OrderStatusEnum.FULFILLED:
					this.tabsToExclude = ['catalog', 'checkout'];
					break;
				// tab: nessuna
				default:
					this.tabsToExclude = ['catalog', 'checkout', 'variation'];
			}
		}
	}

	myDateFilter = (d: Date): boolean => {
		const day = moment(d).day();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	};

	retrieveAuxiliaryTableState<T>(state$: Observable<BaseStateModel<T>>): Observable<BaseStateModel<T>> {
		return state$.pipe(
			skipWhile((state: BaseStateModel<T>) => !(state && state.data)),
			tap((state: BaseStateModel<T>) => {
				return state;
			})
		);
	}

	verifyOrganizationPermission() {
		this.organizationPermission = this.organizationList.data.find((organization: OrganizationStateModel) => {
			return organization.code_item === this.organizationId;
		});
		if (!this.organizationPermission) {
			this.router.navigate(['/not-found']);
		}
	}
	initMandatoryData() {
		return this.organization$.pipe(
			filter(
				(organization: BaseStateModel<OrganizationPouchModel>) =>
					organization && organization.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationPouchModel>) => {
				if (organization.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (organization.data) {
					this.organization = organization.data;
				}
				// create state Order
				this.setOrderUtilData();
				if (this.orderId === 'new') {
					this.store.dispatch(OrderStateAction.update(new BaseState(new OrderStateModel())));
				} else {
					const requestOrderDetail: OrderWithArticleDetailRequest = {
						id: this.orderId,
						orderData: { organization: this.organization, article: {} }
					};
					this.store.dispatch(OrderStateAction.loadWithDetail(new BaseState(requestOrderDetail)));
				}
				return this.order$;
			}),
			filter(
				(order: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>>) =>
					this.organization && order && order.type !== OrderActionEnum.LOAD
			),
			map((order: BaseStateModel<OrderStateModel>) => {
				if (order.type === OrderActionEnum.ERROR) {
					throw new Error(OrderActionEnum.ERROR);
				}
				if (order.type === OrderActionEnum.UPDATE) {
					if (order.data && order.data.header.status !== this.orderStatus) {
						this.orderStatus = order.data.header.status;
						this.router.navigate([
							`${PATH_URL.PRIVATE}/orders/${this.orderStatus}/${this.organization.code}/${order.data._id}/checkout`
						]);
						this.statusBardOrderService.selectOnly(this.orderStatus);
					}
					this.setOrder(order.data);

					// Load Statistics orders
					let statisticsOrderFilter: BaseState<StatisticsOrders, StatisticsOrdersFilter> = {
						data: {},
						type: '',
						dataSetting: {
							appliedFilter: {
								code_agent: this.user.current_permission.context_code.code
							}
						}
					};
					this.store.dispatch(StatisticsOrdersStateAction.load(statisticsOrderFilter));

					if (this.order) {
						this.setModelFormValueFromOrder();
						this.showAvailabilityAlerts(this.order);
					}
				}
				if (order.type === OrderActionEnum.REMOVED) {
					this.goBack();
				}
				return this.order;
			})
		);
	}

	setOrder(order: OrderStateModel) {
		this.order = order;
		if (!this.getCanEditOrder()) {
			this.form.disable({ emitEvent: false });
		} else if (!this.order._id) {
			this.order.header.code_agent = this.user.current_permission.context_code.code;
			this.order.header.organization.code_item = this.organization.code;
			this.order.header.society_code = this.organization.code_company || this.configurationTable.society_code;
			this.order.header.organization.code_item = this.organization.code_item;
			// TODO: DA DEPRECARE
			// this.order.header.company = {
			// 	business_name: this.organization.business_name,
			// 	piva: this.organization.tax_data ? this.organization.tax_data.vat_number : null
			// };
			this.order.header.goods_destination_code = DEFAULT_DESTINATION_CODE;
			if (this.destinationList) {
				const goods_destination_object = this.destinationList.find(
					x => x.code === this.order.header.goods_destination_code
				);
				this.order.header.goods_destination_object = goods_destination_object
					? goods_destination_object.address
					: undefined;
			}
			this.order.header.first_evasion_date = this.minDateSelected.valueOf();
			if (!this.order.header.custom_field) {
				this.order.header.custom_field = <ExtraFieldOrderHeaderPouchModel>{};
				if (!this.order.header.custom_field.transport) {
					this.order.header.custom_field.transport = <Transport>{};
				}
			}
			this.order.header.division = this.getDivision();
			this.order.header.order_causal = this.getCustomerPreference(
				'order_causal',
				this.auxiliaryTable.causalHeaderSoList
			);
			this.setOrderCausalHeader(this.order);
			this.order.header.payment_code = this.getCustomerPreference(
				'payment_condition',
				this.auxiliaryTable.paymentList
			);
			this.setOrderPaymentHeader(this.order);
			this.order.header.bank_object = {
				description: this.organization.division_list
					? this.organization.division_list[0].banks_descriptions[0]
					: null,
				iban: this.organization.iban
			};
			if (this.organization.destination_list) {
				// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
				// this.order.header.goods_destination_object = this.organization.destination_list.find(
				// 	destination => destination.address_type === AddressTypeEnum.REGISTERED_OFFICE
				// ).address;
				this.order.header.goods_destination_object = this.organization.destination_list.find(
					destination => destination.code === DEFAULT_DESTINATION_CODE
				).address;
			}
		} else if (this.order.header.status === OrderStatusEnum.DRAFT && this.order._id) {
			this.setFirstValidEvasionDate(this.order.header.first_evasion_date);
		}

		this.breadcrumbPathList = ['Home', this.translate.instant('order.name_plural')];
		this.breadcrumbTitle =
			this.titlecasePipe.transform(this.translate.instant('order.name')) +
			' - ' +
			this.organization.business_name +
			(this.order?.csuite?.order_so_client_ref ? ` - ${this.order.csuite.order_so_client_ref}` : '') +
			' - ' +
			(this.order._id ? this.utilOrderService.formatOdvCode(this.order) : 'Nuovo') +
			' - ' +
			(this.order.header.date ? this.datePipe.transform(this.order.header.date) : '');
	}

	setOrderCausalHeader(order: OrderStateModel) {
		if (order && order.header && order.header.order_causal) {
			let orderHeaderObject = null;
			// retrieve object from auxiliaryTable
			if (this.auxiliaryTable.causalHeaderSoList) {
				orderHeaderObject = this.auxiliaryTable.causalHeaderSoList.find(x => {
					return x.code_item === order.header.order_causal;
				});
			}
			// populate object
			if (orderHeaderObject) {
				order.header.order_causal_object = {
					code_item: orderHeaderObject.code_item,
					description: orderHeaderObject.description,
					description_short: orderHeaderObject.description_short ? orderHeaderObject.description_short : null
				};
			}
		}
	}

	setOrderPaymentHeader(order: OrderStateModel) {
		if (order && order.header && order.header.payment_code) {
			let orderHeaderObject = null;
			// retrieve object from auxiliaryTable
			if (this.auxiliaryTable.paymentList) {
				orderHeaderObject = this.auxiliaryTable.paymentList.find(x => {
					return x.code_item === order.header.payment_code;
				});
			}
			// populate object
			if (orderHeaderObject) {
				order.header.payment_object = {
					code_item: orderHeaderObject.code_item,
					description: orderHeaderObject.description,
					description_short: orderHeaderObject.description_short ? orderHeaderObject.description_short : null
				};
			}
		}
	}

	getCanEditOrder() {
		if (
			this.order.header.status === OrderStatusEnum.DRAFT ||
			this.order.header.status === OrderStatusEnum.NOT_AUTHORIZED
		) {
			return true;
		}
		if (
			this.currentContext === ContextApplicationItemCodeEnum.BACKOFFICE &&
			OrderBackoffice.filter(el => el.status === this.order.header.status).map(el => el.can_edit)[0]
		) {
			return true;
		}
		return false;
	}

	createForm() {
		this.form = this.fb.group({
			shipping_address: [{ value: '' }],
			payment: [{ value: '' }, Validators.required],
			due_date: [{ value: '' }, Validators.required],
			order_causal: [{ value: '' }, Validators.required],
			bank: [{ value: '', disabled: true }],
			iban: [{ value: '', disabled: true }],
			note: [null, { updateOn: 'blur' }]
		});
	}

	getBankName(organization: OrganizationPouchModel): string {
		let bankName = '';

		// TODO - capire quale division si deve controllare
		if (
			organization.division_list &&
			organization.division_list.length &&
			organization.division_list[0]['banks_descriptions'] &&
			organization.division_list[0]['banks_descriptions'].length
		) {
			bankName = organization.division_list[0]['banks_descriptions'][0];
		}

		return bankName;
	}

	setModelFormValueFromOrder() {
		// TODO - gestire multidivision
		const bankName = this.getBankName(this.organization);

		this.form.patchValue(
			{
				shipping_address: this.order.header.goods_destination_code,
				payment: this.order.header.payment_code,
				due_date: moment(this.order.header.first_evasion_date),
				bank: bankName ? bankName : "Nessuna Banca d'appoggio",
				iban: this.organization['iban'], // TODO - aggiungere proprietà al modello dati nella libreria
				order_causal: this.order.header.order_causal,
				note: this.order.header.note_order
			},
			{ emitEvent: false }
		);

		if (!this.subscribeManagerService.hasSubscription('change-form')) {
			this.onChangeForm();
		}
	}

	onChangeForm() {
		this.subscribeManagerService.populate(
			this.form.valueChanges.pipe(debounceTime(500)).subscribe(async formChange => {
				const cloneOrder = _.cloneDeep(this.order);
				const first_evasion_date = formChange['due_date'] as Moment;
				cloneOrder.header.goods_destination_code = formChange['shipping_address'];
				if (this.destinationList) {
					const goods_destination_object = this.destinationList.find(
						x => x.code === cloneOrder.header.goods_destination_code
					);
					if (goods_destination_object) {
						cloneOrder.header.goods_destination_object = goods_destination_object.address;
					}
				}

				cloneOrder.header.payment_code = formChange['payment'];
				this.setOrderPaymentHeader(cloneOrder);
				cloneOrder.header.first_evasion_date = first_evasion_date ? first_evasion_date.valueOf() : null;
				cloneOrder.header.order_causal = formChange['order_causal'];
				this.setOrderCausalHeader(cloneOrder);
				cloneOrder.header.note_order = formChange['note'];

				// da definire quando vanno create queste date
				// TODO: ???
				// cloneOrder.header.customer_order_ref_date = moment().format('DD/MM/YYYY');

				// set product list data
				if (cloneOrder.product_list && cloneOrder.product_list.length > 0) {
					for (let i = 0; i < cloneOrder.product_list.length; i++) {
						if (cloneOrder.product_list[i].order_causal_code !== 'AG') {
							cloneOrder.product_list[i].order_causal_code = this.utilOrderService.setNonFreeRowCausal(
								cloneOrder.header.order_causal,
								this.auxiliaryTable.causalHeaderSoList
							);
						}
					}
				}
				cloneOrder.header.division = this.getDivision();

				const requestOrderDetail: OrderWithArticleDetailRequest = {
					orderData: { order: cloneOrder, organization: this.organization, article: {} }
				};
				this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
				this.utilOrderService.changeFormHeaderValidity(this.form.valid);
			}),
			'change-form'
		);
	}

	getDivision() {
		if (IS_MULTIDIVISION) {
			// TODO - Handle multidivision
			return null;
		} else {
			// division da configuration table, altrimenti prende il default
			return this.configurationTable ? this.configurationTable.division : DEFAULT_DIVISION_CODE;
		}
	}

	setFirstValidEvasionDate(firstEvasionDate: number): void {
		const isBeforeToday: boolean = moment(firstEvasionDate).diff(this.minDateSelected, 'd', false) < 0;
		if (isBeforeToday) {
			const cloneOrder = _.cloneDeep(this.order);
			cloneOrder.header.first_evasion_date = moment(this.minDateSelected).valueOf();
			const requestOrderDetail: OrderWithArticleDetailRequest = {
				orderData: { order: cloneOrder, organization: this.organization, article: {} }
			};
			this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
			this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
				duration: 3000,
				data: {
					message: `La data di consegna è stata aggiornata perché precedente ad oggi`
				}
			});
		}
	}

	setOrderUtilData() {
		const divisionList = this.organization.division_list ? this.organization.division_list : [];
		this.paymentDivision = this.utilOrderService.filterPaymentDivisionList(
			divisionList,
			this.auxiliaryTable.paymentList
		);
		this.paymentDefault = this.utilOrderService.filterPaymentDivisionList(
			this.utilOrderService.agreedPaymentDefault,
			this.auxiliaryTable.paymentList
		);
		this.paymentList = [
			{ name: 'Pagamenti', payment: this.paymentDivision },
			{ name: 'Pagamenti Concordati', payment: this.paymentDefault }
		];
	}
	// method to manage new destination START
	addOtherDestination(e: MatSlideToggleChange) {
		if (e.checked) {
			this.openDialogDestination();
		} else {
			this.enableDestinationCode();
		}
	}

	openDialogDestination() {
		let new_goods_destination_object;
		if (this.order.header.goods_destination_object) {
			new_goods_destination_object = _.cloneDeep(this.order.header.goods_destination_object);
		} else {
			new_goods_destination_object = {
				business_name: null,
				contact_person: null,
				address: {
					address: null,
					locality: null,
					country: null,
					zip_code: null,
					province: null
				}
			};
		}
		const lastDestinationCode = this.order.header.goods_destination_code;

		const dialog = this.dialog.open(DialogOtherDestinationComponent, {
			data: {
				title: 'destination.add_new',
				otherDestination: new_goods_destination_object
			},
			disableClose: true
		});
		dialog.afterClosed().subscribe((res: DestinationNew) => {
			if (res) {
				this.form.controls['shipping_address'].disable();
				this.order.header.goods_destination_code = '';
				this.order.header.goods_destination_object = res;
			} else {
				this.enableDestinationCode(lastDestinationCode);
				this.toggleNewDestination.checked = false;
			}
		});
	}

	enableDestinationCode(destionationCode?: string) {
		this.form.controls['shipping_address'].enable();
		this.order.header.goods_destination_code =
			destionationCode || destionationCode === '' ? destionationCode : DEFAULT_DESTINATION_CODE;
		this.order.header.goods_destination_object = this.destinationList.find(
			x => x.code === this.order.header.goods_destination_code
		).address;

		this.form.patchValue({
			shipping_address: this.order.header.goods_destination_code
		});
	}

	getNewAddress() {
		return this.order.header.goods_destination_object.address.address
			? `${this.order.header.goods_destination_object.address.address} - ${this.order.header.goods_destination_object.address.zip_code} - ${this.order.header.goods_destination_object.address.locality} ( ${this.order.header.goods_destination_object.address.province} ) - ${this.order.header.goods_destination_object.address.country}`
			: '';
	}
	// method to manage new destination END

	openDialogCausal() {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: "Reset dell'ordine",
				text:
					"Cambiando la modalità dell'ordine perderai i prodotti selezionati, sei sicuro di voler continuare?"
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.order.header.order_causal = this.toggleOrderCausal.checked
					? CausalHeaderOrderEnum.NR
					: CausalHeaderOrderEnum.N1;
				this.order.product_list = [];
				// TODO: essendo la causale considerata in alcuni aspetti del settaggio della form di testata
				// salvo temporaneamente l'ordine forzando un onFormChange
				this.form.enable();
			} else {
				this.toggleOrderCausal.checked = !this.toggleOrderCausal.checked;
			}
		});
	}

	// TODO: da sistemare
	openDialogConfirmClone(data: any) {}

	openDialogConfirmDelete(data: any) {
		const dialog = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Elimina Ordine',
				text: "Vuoi davvero eliminare l'ordine in Bozza?"
			}
		});
		dialog.afterClosed().subscribe(res => {
			if (res) {
				if (this.order._id) {
					// TODO
					this.store.dispatch(OrderStateAction.remove(new BaseState(this.order)));
				}
			}
		});
		event.stopPropagation();
	}

	goBack() {
		this.location.back();
	}

	showAvailabilityAlerts(order: OrderStateModel) {
		const orderProductList = [];
		order.product_list.forEach(product =>
			product.articleDetail ? orderProductList.push(product) : this.orderProductListNotFound.push(product)
		);
		this.setTodayMinusFiveDaysDate =
			!order.header.first_evasion_date || moment(order.header.first_evasion_date).diff(moment(), 'd') < 5;
		this.setProductPartiallyAvailable =
			orderProductList.findIndex(prod => prod.articleDetail.cf_available === ArticleAvailableEnum.LOW) !== -1;
		this.setProductNotAvailable =
			orderProductList.findIndex(
				prod => prod.articleDetail.cf_available === ArticleAvailableEnum.NOT_AVAILABLE
			) !== -1;
		if (order.header.first_evasion_date) {
			this.showAlert(moment(order.header.first_evasion_date));
		}
	}

	showAlert(date: Moment) {
		const orderCompositionDate = moment(this.order.header.date, 'DD/MM/YYYY');
		const isSameDay = orderCompositionDate.diff(date, 'd', true) === 0;
		const isBeforeMidday = this.today.getHours() - 12 < 0;

		if (isSameDay && isBeforeMidday) {
			this.setTodayDate = true;
		} else {
			this.setTodayDate = false;
		}
	}

	getOrderCausal(code_item: string) {
		return this.auxiliaryTable.causalHeaderSoList.find(causalHeaderSo => causalHeaderSo.code_item === code_item);
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	getCustomerPreference(propertyCustomerDivision: string, collection: BodyTablePouchModel[]): string {
		let result = '';
		if (this.organization.division_list) {
			const customerDivision: DivisionPouchModel = this.organization.division_list.find(
				division => division.division === this.getDivision()
			);

			if (customerDivision && customerDivision[propertyCustomerDivision]) {
				const defaultObj = collection.find(
					item => item.code_item === customerDivision[propertyCustomerDivision]
				);

				if (defaultObj.code_item) {
					result = defaultObj.code_item;
				}
			}
		}

		return result;
	}

	/**
	 * BACKOFFICE CONTEXT
	 */

	checkOrganizationForOrderValidation() {
		if (this.organization) {
			switch (this.organization.level) {
				case OrganizationLevelEnum.LEAD:
					this.organizationToBeValidated = true;
					break;
				case OrganizationLevelEnum.PROSPECT:
				case OrganizationLevelEnum.CUSTOMER:
					this.dialogConfirmOrderValidation();
					break;
			}
		}
	}

	// Valida ordine
	dialogConfirmOrderValidation() {
		const title = 'Valida ordine';
		const text = "Sei sicuro di voler validare l'ordine?";
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				console.log(res);
				// Modifica livello di organizzazione e salva
				if (this.organization.level === OrganizationLevelEnum.PROSPECT) {
					const updatedOrganization = _.cloneDeep(this.organization);
					updatedOrganization.level = OrganizationLevelEnum.CUSTOMER;
					this.store.dispatch(OrganizationStateAction.update(new BaseState(updatedOrganization)));
				}
				// Modifica ordine e salva
				this.order.header.status = OrderStatusEnum.READY_TO_SEND;
				this.order.header.submission_date = new Date().getTime();
				const requestOrderDetail: OrderWithArticleDetailRequest = {
					orderData: {
						order: _.cloneDeep(this.order),
						organization: _.cloneDeep(this.organization),
						article: {}
					}
				};
				this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
			}
		});
	}

	/**
	 * END BACKOFFICE CONTEXT
	 */

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrganizationStateAction.reset());
		this.store.dispatch(DestinationListStateAction.reset());
		this.store.dispatch(OrderStateAction.reset());
		this.store.dispatch(
			AuxiliaryTableStateAction.resetItem({
				data: {},
				stateSlice: {
					type: 'startingWarehouseCarrierTypeList'
				}
			})
		);
		this.store.dispatch(
			AuxiliaryTableStateAction.resetItem({
				data: {},
				stateSlice: {
					type: 'startingWarehouseDepositChargeList'
				}
			})
		);
		this.store.dispatch(
			AuxiliaryTableStateAction.resetItem({
				data: {},
				stateSlice: {
					type: 'kilometricRangeList'
				}
			})
		);
		this.store.dispatch(
			AuxiliaryTableStateAction.resetItem({
				data: {},
				stateSlice: {
					type: 'carrierTypePumpingChargeList'
				}
			})
		);
	}
}
