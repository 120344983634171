import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, LocalListHandlerBaseModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { ArticleListActionEnum, ArticleListStateAction } from '../../../state/article-list/article-list.actions';
import { ArticleState } from '../../../model/state/article-list-state.model';
import { StateFeature } from '../../../state';
import { filter, map } from 'rxjs/operators';
import _ from 'lodash';
import { ArticleColumnService } from '../../../service/td-data-table/implementation/article.service';

@Component({
	selector: 'article-list',
	templateUrl: './article-list.component.html',
	styleUrls: ['./article-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class ArticleListComponent implements OnInit, OnDestroy {
	articleList$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticleState[];

	// listPageBaseData
	public listPageBaseData = <LocalListHandlerBaseModel<ArticleState>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['description']
			}
		},
		sort: {
			name: 'description',
			order: 'ASC'
		}
	};

	constructor(
		public articleColumnService: ArticleColumnService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.store.dispatch(ArticleListStateAction.loadAll({ forceDefault: true }));
		this.subscribeManagerService.populate(this.subscribeArticleList().subscribe(), 'subscribeArticleList');
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.store.dispatch(ArticleListStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	/**
	 * Subscription
	 */

	subscribeArticleList() {
		return this.articleList$.pipe(
			filter((state: BaseStateModel<ArticleState[]>) => !!(state && state.data)),
			map((state: BaseStateModel<ArticleState[]>) => {
				switch (state.type) {
					case ArticleListActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case ArticleListActionEnum.ERROR:
						this.updateListPageBaseData([]);
						throw new Error(ArticleListActionEnum.ERROR);
				}

				return state;
			})
		);
	}

	/**
	 * Filters
	 */

	applyPermanentFilters(articleList: ArticleState[] = []) {
		this.updateListPageBaseData(articleList);
	}

	/**
	 * Other
	 */

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: ArticleState[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}
}
