import { Injectable } from '@angular/core';

// model
import { LanguageStateModel } from '../../model/state/language-state.model';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LanguageListActionEnum, LanguageListStateAction } from './language-list.actions';
import { BaseState, BaseStateModel, RestBaseMessageError } from '@saep-ict/angular-core';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { LanguageService } from '../../service/rest/language.service';

@Injectable()
export class LanguageListEffects {
	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LanguageListActionEnum.LOAD_ALL),
			mergeMap(() => from(this.getAllLanguages())),
			map((action: BaseStateModel<LanguageStateModel[]>) => LanguageListStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(LanguageListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private languageService: LanguageService) {}

	async getAllLanguages(): Promise<BaseStateModel<LanguageStateModel[]>> {
		return this.languageService
			.getLanguages()
			.then(res => {
				return new BaseState(res.data);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}
}
