import { BaseTableListService } from '@saep-ict/angular-core';
import { Injectable } from "@angular/core";

@Injectable()
export class AddressListColumnService extends BaseTableListService {
	constructor() {
		super();
	}

	_columns = [
		{
			name: 'address.address',
			label: 'Indirizzo'
		},
		{
			name: 'address.zip_code',
			label: 'CAP'
		},
		{
			name: 'address.locality',
			label: 'Città'
		},
		{
			name: 'address.province',
			label: 'Provincia'
		},
		{
			name: 'address.country',
			label: 'Nazione'
		},
		{
			name: 'action',
			label: '',
			width: 50
		}
	];
}
