import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { LoginContextCodeActionEnum, LoginContextCodeStateAction } from './login-context-code.actions';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

// widget & utility
import { from } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';

@Injectable()
export class LoginContextCodeEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(LoginContextCodeActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) => LoginContextCodeStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(LoginContextCodeStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private storeUtilService: StoreUtilService) {}
}
