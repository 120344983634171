<h2 mat-dialog-title>{{ data.title | sentencecase }}</h2>
<mat-dialog-content>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 dialog-order-detail__status-bar mb-3">
				<status-bar id="theme-white" [config]="statusBarOrderB2cService.config"></status-bar>
			</div>

			<div class="col-sm-12 col-md-12 col-lg-8 dialog-order-detail__essay">
				<!-- [labelSendPrice]="'payExpress'" -->
				<app-mirror-costs [productsList]="productsList" [price]="price"></app-mirror-costs>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-4 dialog-order-detail__main-data">
				<div class="row">
					<div class="col-md-6 col-lg-12">
						<h3>Indirizzo di spedizione</h3>
						<p *ngIf="shippingAddress">
							{{ shippingAddress.address }}<br />
							{{ shippingAddress.zip_code }} - {{ shippingAddress.locality }} ({{
								shippingAddress.province
							}})
							<br />
							{{ shippingAddress.country }}
						</p>
					</div>
					<div class="col-md-6 col-lg-12">
						<h3>Metodo di spedizione</h3>
						<p *ngIf="shippingMethod">
							{{ shippingMethod.description }}
						</p>
						<h3>Metodo di pagamento</h3>
						<p *ngIf="paymentMethod">
							{{
								paymentMethod.description_short
									? paymentMethod.description_short
									: paymentMethod.description
							}}
						</p>
					</div>
				</div>

				<h3>Note aggiuntive</h3>
				<p>
					{{ order.header?.note_order }}
				</p>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-stroked-button (click)="downloadInvoice()" class="mb-3">
		<mat-icon>receipt</mat-icon>
		{{ 'Scarica fattura' | sentencecase }}
	</button>

	<button mat-raised-button color="primary" [mat-dialog-close]="false" class="mb-3">
		{{ 'Torna alla lista' | sentencecase }}
	</button>
</mat-dialog-actions>
