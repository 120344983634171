import { createAction, props } from '@ngrx/store';
import { BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { OfferStateModel } from '../../model/state/offer-state.model';

export enum OfferActionEnum {
	UPDATE = '[Offer] Update',
	LOAD = '[Offer] Load',
	LOAD_ALL = '[Offer] Load all',
	SAVE = '[Offer] Save',
	REMOVE = '[Offer] Remove',
	REMOVED = '[Offer] Removed',
	RESET = '[Offer] Reset',
	ERROR = '[Offer] Error'
}

export namespace OfferStateAction {
	export const load = createAction(OfferActionEnum.LOAD, props<BaseStateModel<RestBasePk>>());
	export const loadAll = createAction(OfferActionEnum.LOAD_ALL);
	export const update = createAction(OfferActionEnum.UPDATE, props<BaseStateModel<OfferStateModel[]>>());
	export const save = createAction(OfferActionEnum.SAVE, props<BaseStateModel<OfferStateModel[]>>());
	export const remove = createAction(OfferActionEnum.REMOVE, props<BaseStateModel<OfferStateModel[]>>());
	export const removed = createAction(OfferActionEnum.REMOVED);
	export const reset = createAction(OfferActionEnum.RESET);
	export const error = createAction(OfferActionEnum.ERROR, props<BaseStateModel<any>>());
}
