import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	UserDetailModel,
	ContextOtherItemCodeEnum,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { chartTimeRange7Days } from '../../../constants/chart-time-range.constants';
import { StatisticsAgentBaseSerieProduct } from '../../../model/statistics-agent.model';
import { StateFeature } from '../../../state';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { AppUtilService } from '../../util/app-util.service';

@Injectable()
export class OrderProductsTableConfigService {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	statistics$: Observable<BaseStateModel<any>>;
	statistics: any;

	fullDataSetDays7: StatisticsAgentBaseSerieProduct[] = [];
	fullDataSetDays15: StatisticsAgentBaseSerieProduct[] = [];
	fullDataSetDays30: StatisticsAgentBaseSerieProduct[] = [];

	currentDataSet: StatisticsAgentBaseSerieProduct[] = [];
	context: ContextApplicationItemCodeEnum;

	cardDashboardConfigProductsTop5: CardDashboardConfig;

	columns: ITdDataTableColumn[] = [
		{
			name: 'description',
			label: this.translate.instant('product.field.name')
		},
		{
			name: 'value',
			label: this.translate.instant('product.field.total_income'),
			numeric: true
		}
	];

	// Questa proprietà viene letta anche per popolare cardDashboardConfigOrderTrend
	public timeRange = chartTimeRange7Days;

	constructor(
		public utilService: AppUtilService,
		private store: Store<any>,
		@Inject(TranslateService) private translate: TranslateService,
		private route: ActivatedRoute
	) {
		this.user$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.context = res.data.current_permission.context_application;
				this.cardDashboardConfigProductsTop5 = {
					title:
						this.context === ContextApplicationItemCodeEnum.B2B
							? 'chart.products_top_x.title_customer'
							: 'chart.products_top_x.title_seller',
					subtitle:
						this.context === ContextApplicationItemCodeEnum.B2B
							? 'chart.products_top_x.subtitle_customer'
							: 'chart.products_top_x.subtitle_seller',
					helpText: 'chart.products_top_x.helptext',
					icon: 'assignment'
				};
				switch (this.context) {
					case ContextApplicationItemCodeEnum.AGENT:
						if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardOrders) {
							this.statistics$ = this.store.select(StateFeature.getStatisticsDetailOrders);
						} else {
							this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
						}
						break;
					case ContextApplicationItemCodeEnum.B2B:
						this.statistics$ = this.store.select(StateFeature.getStatisticsOrganization);
						break;
				}
			});

		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.statistics = res.data;
				this.createDataSerie();
				this.populateTable();
			});
	}

	// Functions

	createDataSerie() {
		let productsTop5;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardOrders) {
			productsTop5 = this.statistics.products;
		} else {
			productsTop5 = this.utilService.findLeaf(this.statistics.data, `orders.products`);
		}

		this.utilService.findLeaf(productsTop5, 'days_7').forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetDays7.push(item);
		});

		this.utilService.findLeaf(productsTop5, 'days_15').forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetDays15.push(item);
		});

		this.utilService.findLeaf(productsTop5, 'days_30').forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetDays30.push(item);
		});
	}

	populateTable(rangeDays: number = this.timeRange.values[0]) {
		switch (rangeDays) {
			case this.timeRange.values[0]:
				this.currentDataSet = this.fullDataSetDays7;
				break;
			case this.timeRange.values[1]:
				this.currentDataSet = this.fullDataSetDays15;
				break;
			case this.timeRange.values[2]:
				this.currentDataSet = this.fullDataSetDays30;
				break;

			default:
				break;
		}
	}
}
