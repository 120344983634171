<app-page-header
	[title]="breadcrumbsList[breadcrumbsList.length - 1]"
	[breadcrumbsList]="breadcrumbsList"
></app-page-header>

<div class="container">
	<div class="row">
		<div class="col-sm-12 col-md-6 col-lg-6 my-account__form">
			<router-outlet></router-outlet>
		</div>
		<div class="col-sm-12 col-md-5 offset-md-1 col-lg-5 offset-lg-1 my-account__help">
			
      <ng-container *ngIf="context_type; else noContextIllustration">
				<app-illustration-password-recovery *ngIf="context_type === 'password'">
				</app-illustration-password-recovery>

				<app-illustration-account *ngIf="context_type === 'login'"> </app-illustration-account>
			</ng-container>

      <ng-template #noContextIllustration>
        <app-illustration-online-shopping></app-illustration-online-shopping>
      </ng-template>
		</div>
	</div>
</div>
