import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, RestBaseMessageError } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { InformativePageModel } from '../../model/informative-page.model';
import { InformativePageActionEnum, InformativePageStateAction } from './informative-page.actions';
import { InformativePageService } from '../../service/rest/informativePage.service';

@Injectable()
export class InformativePageEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(InformativePageActionEnum.LOAD),
			mergeMap((action: { slug: string }) => from(this.getInformativePage(action.slug))),
			map((informativePage: BaseStateModel<InformativePageModel>) =>
				InformativePageStateAction.update(informativePage)
			),
			catchError((error, caught) => {
				this.store.dispatch(InformativePageStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private informativePageService: InformativePageService
	) {}

	// returns the requested document based on the slug
	async getInformativePage(slug: string): Promise<BaseStateModel<InformativePageModel>> {
		return this.informativePageService
			.getInformativePage({ slug: slug })
			.then(res => {
				return new BaseState(res.data);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}
}
