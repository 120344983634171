// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';

// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

export enum B2cUserListActionEnum {
	UPDATE_LIST = '[b2c-user-list] Update List',
	UPDATE_ITEM = '[b2c-user-list] Update Item',
	LOAD_ITEM = '[b2c-user-list] Load Item',
	LOAD_LIST = '[b2c-user-list] Load List',
	RESET = '[b2c-user-list] Reset',
	ERROR = '[b2c-user-list] Error',
	CREATE_ITEM = '[b2c-user-list] Create',
	CREATE_ITEM_SUCCESS = '[b2c-user-list] Create Item Success',
	DELETE_ITEM = '[b2c-user-list] Delete Item',
	DELETE_ITEM_SUCCESS = '[b2c-user-list] Delete Item Success'
}

export namespace B2cUserListStateAction {
	export const loadList = createAction(B2cUserListActionEnum.LOAD_LIST);
	export const loadItem = createAction(B2cUserListActionEnum.LOAD_ITEM, props<BaseStateModel<BasePouchModel>>());
	export const updateList = createAction(B2cUserListActionEnum.UPDATE_LIST, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const updateItem = createAction(B2cUserListActionEnum.UPDATE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const reset = createAction(B2cUserListActionEnum.RESET);
	export const error = createAction(B2cUserListActionEnum.ERROR, props<BaseStateModel<any>>());
	export const createItem = createAction(B2cUserListActionEnum.CREATE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const createItemSuccess = createAction(B2cUserListActionEnum.CREATE_ITEM_SUCCESS, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const deleteItem = createAction(B2cUserListActionEnum.DELETE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const deleteItemSuccess = createAction(B2cUserListActionEnum.DELETE_ITEM_SUCCESS, props<BaseStateModel<null>>());
}
