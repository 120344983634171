import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListViewDataPouchModel, ChartStateValuePouchModel } from '@saep-ict/pouch_agent_models';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';
import { LAST_CHARACTER } from '../../../../constants/pouchdb.constants';

const AGENT_STATISTICS_VIEW = 'statistics/agent-order-statistics';
const ORGANIZATION_STATISTICS_VIEW = 'statistics/customer-order-statistics';

export class OrderStatisticsPouch extends AbstractPouchdbMethod {
	findAgentOrdersByDateRange(
		agentCode: string,
		from: string | number,
		to: string | number
	): Promise<ListViewDataPouchModel<ChartStateValuePouchModel>> {
		if (typeof from === 'string') {
			from = parseInt(from, 10);
		}
		if (typeof to === 'string') {
			to = parseInt(to, 10);
		}
		const idx = AGENT_STATISTICS_VIEW;
		const startkey = [agentCode, from];
		const endkey = [agentCode + LAST_CHARACTER, to];
		// TODO Wrappare chiamata a view nella libreria
		return new Promise((resolve, reject) => {
			this.db
				.query(idx, { startkey: startkey, endkey: endkey })
				.then(res => {
					resolve(res);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
					reject(err);
				});
		});
	}

	findAgentOrders(agentCode: string): Promise<ListViewDataPouchModel<ChartStateValuePouchModel>> {
		return this.findAgentOrdersByDateRange(agentCode, null, null);
	}

	findOrganizationOrdersByDateRange(
		agentCode: string,
		organizationCode: string,
		from: string | number,
		to: string | number
	): Promise<ListViewDataPouchModel<ChartStateValuePouchModel>> {
		if (typeof from === 'string') {
			from = parseInt(from, 10);
		}
		if (typeof to === 'string') {
			to = parseInt(to, 10);
		}
		const idx = ORGANIZATION_STATISTICS_VIEW;
		const startkey = [organizationCode, from];
		const endkey = [organizationCode + LAST_CHARACTER, to];
		// TODO Wrappare chiamata a view nella libreria
		return new Promise((resolve, reject) => {
			this.db
				.query(idx, { startkey: startkey, endkey: endkey })
				.then(res => {
					res.rows = res.rows.filter(result => result.value.agent_code === agentCode);
					resolve(res);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
					reject(err);
				});
		});
	}

	findOrganizationOrders(
		agentCode: string,
		organizationCode: string
	): Promise<ListViewDataPouchModel<ChartStateValuePouchModel>> {
		return this.findOrganizationOrdersByDateRange(agentCode, organizationCode, null, null);
	}
}
