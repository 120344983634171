import { ArticleListFilterModel } from './../../service/pouch-db/filter/article-list-filter.model';
import { BaseStateModel } from "@saep-ict/angular-core";
import { ArticleListStateAction, ArticleListActionEnum } from "./article-list.actions";
import { createReducer, on } from "@ngrx/store";
import { TypedAction } from "@ngrx/store/src/models";
import { ArticleState } from "../../model/state/article-list-state.model";

export namespace ArticleListStateReducer {
	export const initialState: BaseStateModel<ArticleState[], ArticleListFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(ArticleListStateAction.load, state => state),
		on(ArticleListStateAction.loadWithDetail, state => state),
		on(ArticleListStateAction.loadListFromCode, state => state),
		on(ArticleListStateAction.update, (state, articleList) => articleList),
		on(ArticleListStateAction.reset, () => initialState),
		on(ArticleListStateAction.error, (state, articleList) => ({ data: state ? state.data : null, type: articleList.type }))
	);

	export function reducer(state: BaseStateModel<ArticleState[]>, action: TypedAction<ArticleListActionEnum>) {
		return _reducer(state, action);
	}
}
