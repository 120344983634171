<form [formGroup]="form">
  <section class="filter-simple">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label> Filtra per categoria </mat-label>
            <mat-select formControlName="category">
              <mat-option>-</mat-option>
              <mat-option
                *ngFor="let category of categoryList"
                [value]="category"
                >{{ category }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label> Ordina per </mat-label>
            <mat-select formControlName="order">
              <mat-option *ngFor="let orderBy of orderByList" [value]="orderBy">
                {{ orderBy }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </section>
</form>
