import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { StatisticsAgent } from '../../../../model/statistics-agent.model';
import { StatisticsCrm } from '../../../../model/statistics-crm.model';
import { StatisticsDetailOrders } from '../../../../model/statistics-detail-orders.model';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { StatisticsDetailSold } from '../../../../model/statistics-detail-sold.model';
import { StatisticsDetailClients } from '../../../../model/statistics-detail-clients.model';
import { StatisticsDetailBalance } from '../../../../model/statistics-detail-balance.model';
import { Statistics } from '../../../../model/statistics.model';

export class StatisticsPouch extends AbstractPouchdbMethod {
	// TODO: uncomment the retrieval of detail-statistics documents

	getStatistics(code: string): Promise<Statistics> {
		return new Promise((resolve, reject) => {
			this.get(`statistics_${code}`)
				.then((doc: Statistics) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailOrders(currentContext: ContextApplicationItemCodeEnum): Promise<StatisticsDetailOrders> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatisticsForContext(currentContext);
			promise
				.then((doc: StatisticsAgent) => {
					// const orders_doc: StatisticsDetailOrders = { ...this.utilService.findLeaf(doc.data, 'orders'), update_date: doc.date_update };
					// resolve(orders_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailSold(currentContext: ContextApplicationItemCodeEnum): Promise<StatisticsDetailSold> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatisticsForContext(currentContext);
			promise
				.then((doc: StatisticsAgent) => {
					// const fulfilled_doc: StatisticsDetailSold = { ...AppUtilService.findLeaf(doc.data, 'fulfilled'), update_date: doc.date_update };
					// resolve(fulfilled_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailClients(currentContext: ContextApplicationItemCodeEnum): Promise<StatisticsDetailClients> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatisticsForContext(currentContext);
			promise
				.then((doc: StatisticsAgent) => {
					// const clients_doc: StatisticsDetailClients = { ...doc.clients, update_date: doc.date_update };
					// resolve(clients_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsDetailBalance(currentContext: ContextApplicationItemCodeEnum): Promise<StatisticsDetailBalance> {
		return new Promise((resolve, reject) => {
			const promise = this.getStatisticsForContext(currentContext);
			promise
				.then((doc: StatisticsAgent) => {
					// const balance_doc: StatisticsDetailBalance = { ...doc.balance, update_date: doc.date_update };
					// resolve(balance_doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getStatisticsForContext(currentContext: ContextApplicationItemCodeEnum): Promise<any> {
		let promise;
		switch (currentContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				promise = this.get(`statistics_agent`);
				break;
			case ContextApplicationItemCodeEnum.B2B:
			case ContextApplicationItemCodeEnum.B2C:
				promise = this.get(`statistics_organization`);
				break;
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				promise = this.get('statistics_backoffice');
		}
		return promise;
	}
}
