import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CompanyAccountBalanceAction, CompanyAccountBalanceActionEnum } from './company-account-balance.actions';
import { CustomerCreditPouchModelCustom } from './company-account-balance.reducer';
import * as moment from 'moment';

import { CompanyAccountBalanceFilterModel } from '../../service/pouch-db/filter/company-account-balance-filter.model';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';

@Injectable()
export class CompanyAccountBalanceEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyAccountBalanceActionEnum.LOAD),
			mergeMap((action: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>) =>
				from(this.getAccountBalance(action))
			),
			mergeMap((action: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>) =>
				from(this.getAccountBalanceSummary(action))
			),
			mergeMap((action: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>) =>
				from(this.sortBalanceSummary(action))
			),
			map((customerCredit: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>) =>
				CompanyAccountBalanceAction.update(customerCredit)
			),
			catchError(err => of({ err, type: CompanyAccountBalanceActionEnum.ERROR }))
		)
	);

	constructor(private actions$: Actions, private pouchAdapterSelectorService: PouchAdapterSelectorService) {}

	async getAccountBalance(
		action: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>
	): Promise<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>> {
		try {
			action.data.header = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
			).companyAccountBalancePouch.loadAccountBalance(action);
			return action;
		} catch {
			console.error('getAccountBalance');
		}
	}

	async getAccountBalanceSummary(
		action: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>
	): Promise<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>> {
		try {
			action.data.summary = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
			).companyAccountBalancePouch.loadAccountBalanceSummary(action);
			return action;
		} catch {
			console.error('getAccountBalanceSummary');
		}
	}

	async sortBalanceSummary(
		action: BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>
	): Promise<BaseStateModel<CustomerCreditPouchModelCustom, CompanyAccountBalanceFilterModel>> {
		if (!action.data.header.batch_list || !action.data.header.batch_list.length) {
			return action;
		}
		action.data.header.batch_list = this.sortByDate(action.data.header.batch_list, 'date_document');
		return action;
	}

	sortByDate(array: any[], fieldName: string): any[] {
		// DESC
		return array.sort((a, b) => moment(b[fieldName]).diff(moment(a[fieldName])));
	}
}
