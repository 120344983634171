<app-page-header
	[title]="isCatalog ? ('general.catalogue' | translate | sentencecase) : category?.description"
	[breadcrumbsList]="['home', isCatalog ? ('general.catalogue' | translate) : category?.description]"
></app-page-header>

<div
	list-wrapper
	#listWrapper
	class="list-wrapper"
	[filterOpened]="!isMobile && !isCatalog"
	[mode]="isMobile ? 'over' : 'side'"
	sidenavWidth="300px"
	(window:resize)="onResize($event)"
>
	{{ articleList | json }}
	<ng-container main>
		<section class="filter-simple">
			<div class="container">
				<div class="row">
					<div *ngIf="!isCatalog" class="col-10 col-lg-8 filter-simple__form">
						<app-filter-simple (changingFilters)="changeFilters($event)"></app-filter-simple>
					</div>
					<div *ngIf="isMobile && !isCatalog" class="col-2 col-lg-4 filter-simple__viewtype">
						<button mat-icon-button (click)="listWrapper.sidenav.open()">
							<mat-icon>filter_list</mat-icon>
						</button>
					</div>
					<div
						class="col-12 col-lg-4"
						[class.filter-simple__viewtype]="!isMobile"
						[class.col-lg-4]="!isMobile"
					>
						<app-viewtype-toggle
							[viewTypeDefault]="viewType"
							(changingViewList)="viewType = $event"
						></app-viewtype-toggle>
					</div>
				</div>
			</div>
		</section>
		<products-list [list]="articleList" [viewType]="viewType"></products-list>
	</ng-container>

	<ng-container side>
		<h3>Categoria</h3>
		<nested-category-list
			[list]="categoryList"
			[debounceTime]="700"
			(categorySelectedChange)="categorySelectedChange($event)"
		></nested-category-list>
		<h3>Prezzo</h3>
		<mat-form-field>
			<input matInput placeholder="Prezzo minimo" />
		</mat-form-field>
		<mat-form-field>
			<input matInput placeholder="Prezzo massimo" />
		</mat-form-field>
		<button mat-flat-button color="primary" class="btn-sidebar__main" type="submit">Filtra risultato</button>
		<button mat-button color="primary" class="btn-sidebar__cancel" type="button">
			Annulla
		</button>
	</ng-container>
</div>

<app-call-to-action [callToActionConfig]="callToActionConfig" (clickButton)="clickCallToAction()"></app-call-to-action>
