import { Route } from '@angular/router';
import { PermissionKeyEnum } from '@saep-ict/angular-core';
import { TicketCenterRoutes } from '@saep-ict/ticket-center';
import { FramePageComponent } from '../frame/admin/admin.component';
import { DashboardB2bComponent } from '../page/b2b/dashboard-b2b/dashboard-b2b.component';
import { ContactComponent } from '../page/commons/contact/contact.component';
import { MediaCenterComponent } from '../page/commons/media-center/media-center.component';
import { OrderDetailCatalogComponent } from '../page/commons/order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from '../page/commons/order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailComponent } from '../page/commons/order/order-detail/order-detail.component';
import { OrderComponent } from '../page/commons/order/order.component';
import { OrganizationDetailCreditComponent } from '../page/commons/organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailDestinationComponent } from '../page/commons/organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailFullDetailComponent } from '../page/commons/organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailComponent } from '../page/commons/organization/organization-detail/organization-detail.component';
import { TicketCenterWrapperComponent } from '../page/commons/ticket-center-wrapper/ticket-center.component';
import { UserComponent } from '../page/commons/user/user.component';
import { MetaDataResolver } from '../service/resolvers/meta-data.resolver';
import { ROUTE_URL } from './route-naming';
import { ShopOnlineComponent } from '../page/b2b/shop-online/shop-online.component';

export namespace B2BRouteList {
	export const routes: Route[] = [
		{
			path: '',
			component: FramePageComponent,
			children: [
				// Dashboard
				{
					path: '',
					component: DashboardB2bComponent,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_DASHBOARD],
						meta: {
							title: 'Dashboard b2b',
							description: 'Home del b2b'
						}
					},
					pathMatch: 'full',
					resolve: {
						meta: MetaDataResolver
					}
				},
				// User
				{
					path: ROUTE_URL.user,
					component: UserComponent,
					pathMatch: 'full',
					data: {
						permissionKey: [PermissionKeyEnum.B2B_PROFILE],
						meta: {
							title: 'Profilo cliente',
							description: 'Sezione del profilo cliente B2B'
						}
					},
					resolve: {
						meta: MetaDataResolver
					}
				},
				// Organization
				{
					path: `${ROUTE_URL.company}/:idOrganization`, // TODO: :idOrganization should be handled during login for B2B
					component: OrganizationDetailComponent,
					data: {
						permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DETAIL],
						meta: {
							title: 'Informazioni azienda',
							description: "Informazioni sull'azienda"
						}
					},
					resolve: {
						meta: MetaDataResolver
					},
					children: [
						{
							path: '',
							pathMatch: 'full',
							redirectTo: ROUTE_URL.details,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DETAIL] }
						},
						{
							path: ROUTE_URL.details,
							component: OrganizationDetailFullDetailComponent,
							data: {
								permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DETAIL],
								meta: {
									title: 'Riassunto azienda',
									description: "Informazioni generali sull'azienda"
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ROUTE_URL.orders,
							component: OrderComponent,
							data: {
								refreshChild: true,
								permissionKey: [PermissionKeyEnum.B2B_ORDER_LIST]
							},
							pathMatch: 'full'
						},
						{
							path: ROUTE_URL.destinations,
							component: OrganizationDetailDestinationComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_DESTINATION] }
						},
						{
							path: ROUTE_URL.contacts,
							component: ContactComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_CONTACT_LIST] }
						},
						{
							path: ROUTE_URL.credits,
							component: OrganizationDetailCreditComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORGANIZATION_CREDIT] }
						}
					]
				},
				// Orders
				{
					path: ROUTE_URL.orders,
					data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_LIST] },
					children: [
						{
							path: ':orderStatus',
							component: OrderComponent,
							pathMatch: 'full',
							data: {
								permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL],
								meta: {
									title: 'Riassunto ordine',
									description: "Informazioni generali sull'ordine"
								}
							},
							resolve: {
								meta: MetaDataResolver
							}
						},
						{
							path: ':orderStatus/:organizationId/:orderId',
							component: OrderDetailComponent,
							data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] },
							children: [
								{
									path: '',
									redirectTo: ROUTE_URL.catalog,
									data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] },
									pathMatch: 'full'
								},
								{
									path: ROUTE_URL.catalog,
									component: OrderDetailCatalogComponent,
									data: { permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL] }
								},
								{
									path: ROUTE_URL.checkout,
									component: OrderDetailCheckoutComponent,
									data: {
										permissionKey: [PermissionKeyEnum.B2B_ORDER_DETAIL],
										meta: {
											title: 'Dettagli ordine',
											description: "Informazioni di dettaglio dell'ordine"
										}
									},
									resolve: {
										meta: MetaDataResolver
									}
								}
							]
						}
					]
				},
				// ticket-center
				{
					path: `${ROUTE_URL.ticketCenter}`,
					data: { permissionKey: [PermissionKeyEnum.B2B_TICKET_CENTER] },
					children: [
						{
							path: '',
							data: {
								permissionKey: [PermissionKeyEnum.B2B_TICKET_CENTER],
								meta: {
									title: 'Ticket Center',
									description: 'Ticket Center'
								}
							},
							resolve: {
								meta: MetaDataResolver
							},
							component: TicketCenterWrapperComponent,
							children: TicketCenterRoutes
						}
					]
				},
				// Mediacenter
				{
					path: `${ROUTE_URL.mediaCenter}/path/:path`,
					data: { permissionKey: [PermissionKeyEnum.B2B_MEDIACENTER] },
					component: MediaCenterComponent
				},
				// ShopOnline
				{
					path:  'shop-online',   
					data: { 
						permissionKey: [PermissionKeyEnum.B2B_ORDER_LIST],
					},
					component: ShopOnlineComponent,
				}
			]
		}
	];
}
