import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ContactApiService } from '../../../../service/rest/app-contact-api.service';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';

@Component({
	selector: 'app-contact-email-confirmation',
	templateUrl: './contact-email-confirmation.component.html',
	styleUrls: ['./contact-email-confirmation.component.scss']
})
export class ContactEmailConfirmation implements OnInit {
	contactActivated = false;
	message: String = 'We are activating your account, please wait until the end of the process...';
	hasError = false;

	constructor(
		private appContactApiService: ContactApiService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar
	) {
		const { email, customerCode, idRecipient, guid } = this.route.snapshot.paramMap['params'];
		if (email && customerCode && idRecipient && guid) {
			this.appContactApiService
				.activateContact(this.route.snapshot.paramMap['params'])
				.then(res => {
					console.log(res);
					if (res && res.code === 200) {
						this.message = res.message;
						this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
							duration: 3000,
							data: {
								message: this.message
							}
						});
					}
				})
				.catch(err => {
					console.error(err);
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 3000,
						data: {
							message: err.message
						}
					});
					this.message = "We're sorry, an Error has occured, please retry later...";
					this.hasError = true;
				});
		}
	}

	ngOnInit() {}
}
