import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { IllustrationsModule } from '../../illustrations/illustrations.module';
import { OrganizationService } from '../../service/rest/organization.service';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { CourtesySpinnerComponent } from './courtesy-spinner/courtesy-spinner.component';
import { DownloadCouchComponent } from './download-couch/download-couch.component';
import { ContactEmailConfirmation } from './authentication-wrapper/contact-email-confirmation/contact-email-confirmation.component';
import { ClientCodeSelectComponent } from './authentication-wrapper/context-selection/context-selection.component';
import { LoginComponent } from './authentication-wrapper/login/login.component';
import { PasswordRecoveryComponent } from './authentication-wrapper/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from './authentication-wrapper/password-update/password-update.component';
import { AuthenticationWrapperComponent } from './authentication-wrapper/authentication-wrapper.component';
import { ContactComponent } from './contact/contact.component';
import { MediaCenterComponent } from './media-center/media-center.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrderDetailCatalogComponent } from './order/order-detail/order-detail-catalog/order-detail-catalog.component';
import { OrderDetailCheckoutComponent } from './order/order-detail/order-detail-checkout/order-detail-checkout.component';
import { OrderDetailVariationComponent } from './order/order-detail/order-detail-variation/order-detail-variation.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { OrderComponent } from './order/order.component';
import { OrganizationDetailCreditComponent } from './organization/organization-detail/organization-detail-credit/organization-detail-credit.component';
import { OrganizationDetailDestinationComponent } from './organization/organization-detail/organization-detail-destination/organization-detail-destination.component';
import { OrganizationDetailFullDetailComponent } from './organization/organization-detail/organization-detail-full-detail/organization-detail-full-detail.component';
import { OrganizationDetailOverviewComponent } from './organization/organization-detail/organization-detail-overview/organization-detail-overview.component';
import { OrganizationDetailComponent } from './organization/organization-detail/organization-detail.component';
import { OrganizationComponent } from './organization/organization.component';
import { ProbeTestComponent } from './probe-test/probe-test.component';

import { B2cAuthenticationWrapper } from '../b2c/reserved-area/b2c-authentication-wrapper/b2c-authentication-wrapper.component';
import { PasswordCreateComponent } from './authentication-wrapper/password-create/password-create.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { TicketCenterWrapperComponent } from './ticket-center-wrapper/ticket-center.component';
import { OrganizationDetailCommentComponent } from './organization/organization-detail/organization-detail-comment/organization-detail-comment.component';
import { AccessRequestComponent } from './authentication-wrapper/access-request/access-request.component';
import { ArticleListComponent } from './article-list/article-list.component';

@NgModule({
	imports: [WidgetModule, SharedModule, IllustrationsModule],
	declarations: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		ClientCodeSelectComponent,
		NotFoundComponent,
		PasswordCreateComponent,
		AuthenticationWrapperComponent,
		ContactEmailConfirmation,
		OrganizationComponent,
		OrganizationDetailComponent,
		OrganizationDetailOverviewComponent,
		OrganizationDetailCreditComponent,
		OrganizationDetailDestinationComponent,
		OrganizationDetailFullDetailComponent,
		OrganizationDetailCommentComponent,
		ContactComponent,
		OrderComponent,
		OrderDetailComponent,
		OrderDetailCatalogComponent,
		OrderDetailCheckoutComponent,
		OrderDetailVariationComponent,
		MediaCenterComponent,
		ProbeTestComponent,
		CourtesySpinnerComponent,
		DownloadCouchComponent,
		B2cAuthenticationWrapper,
		ReceiptComponent,
		TicketCenterWrapperComponent,
		AccessRequestComponent,
		ArticleListComponent
	],
	exports: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		ClientCodeSelectComponent,
		NotFoundComponent,
		PasswordCreateComponent,
		AuthenticationWrapperComponent,
		OrganizationComponent,
		OrganizationDetailComponent,
		OrganizationDetailOverviewComponent,
		OrganizationDetailCreditComponent,
		OrganizationDetailDestinationComponent,
		OrganizationDetailFullDetailComponent,
		OrganizationDetailCommentComponent,
		ContactComponent,
		OrderComponent,
		OrderDetailComponent,
		OrderDetailCatalogComponent,
		OrderDetailCheckoutComponent,
		OrderDetailVariationComponent,
		CourtesySpinnerComponent,
		ReceiptComponent,
		AccessRequestComponent,
		ArticleListComponent
	],
	entryComponents: [
		OrganizationComponent,
		OrganizationDetailComponent,
		OrganizationDetailOverviewComponent,
		OrganizationDetailCreditComponent,
		OrganizationDetailDestinationComponent,
		OrganizationDetailFullDetailComponent,
		OrganizationDetailCommentComponent,
		ContactComponent,
		OrderComponent,
		OrderDetailComponent,
		OrderDetailCatalogComponent,
		OrderDetailCheckoutComponent,
		MediaCenterComponent,
		CourtesySpinnerComponent,
		OrderDetailVariationComponent,
		DownloadCouchComponent,
		ReceiptComponent,
		TicketCenterWrapperComponent,
		ArticleListComponent
	],
	providers: [OrganizationService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageCommonsModule {}
