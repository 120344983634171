import { Injectable } from '@angular/core';

// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrmUserListActionEnum, CrmUserListStateAction } from './crm-user-list.actions';

// widget & utility
import { from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { UtilPouchService } from '../../../../service/util/util-pouch.service';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { LAST_CHARACTER } from '../../../../constants/pouchdb.constants';
import { CouchDocumentType } from '../../../../constants/context-state.map';

@Injectable()
export class CrmUserListEffects {
	loadItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CrmUserListActionEnum.LOAD_ITEM),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInListState<BodyTablePouchCustomModel>(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) => CrmUserListStateAction.updateItem(action)),
			catchError((error, caught) => {
				this.store.dispatch(CrmUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CrmUserListActionEnum.LOAD_LIST),
			mergeMap(() => {
				const opts = {
					startkey: 'crm',
					endkey: 'crm_' + LAST_CHARACTER,
					include_docs: true
				};
				return from(this.utilPouchService.allDocs<BodyTablePouchCustomModel>(opts, CouchDocumentType.USER));
			}),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) => CrmUserListStateAction.updateList(action)),
			catchError((error, caught) => {
				this.store.dispatch(CrmUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	createItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CrmUserListActionEnum.CREATE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.storeUtilService.saveCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(action))
			),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.utilPouchService.saveCodeItem(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				CrmUserListStateAction.createItemSuccess(new BaseState([action.data]))
			),
			catchError((error, caught) => {
				this.store.dispatch(CrmUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	deleteItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CrmUserListActionEnum.DELETE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel, any>) =>
				from(this.utilPouchService.deleteAnyDocument(action.data))
			),
			map((action: BodyTablePouchCustomModel) =>
				CrmUserListStateAction.deleteItemSuccess(new BaseState(null, null))
			),
			catchError((error, caught) => {
				this.store.dispatch(CrmUserListStateAction.error(new BaseState(null)));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: UtilPouchService,
		private storeUtilService: StoreUtilService
	) {}
}
