import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import { ROUTE_URL } from '../../router/route-naming';
import { LinkListClass } from '../../widget/unordered-list/unordered-list.component';
import * as saepIctReferences from '../../constants/saep-ict-references.constants';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	linkCategoryList: LinkListClass[] = [
		{ label: 'Categoria A', url: '/' },
		{ label: 'Categoria B', url: '/' },
		{ label: 'Categoria C', url: '/' }
	];
	linkInfoList: LinkListClass[] = [
		{ label: 'Politica di spedizione', url: `${ROUTE_URL.deliveryPolicy}` },
		{ label: 'Politica di restituzione', url: `${ROUTE_URL.returnPolicy}` },
		{
			label: 'informative_page.type.terms_conditions',
			url: `${ROUTE_URL.termsAndConditions}`
		},
		{ label: 'Contatti', url: '/' },
		{ label: 'Login', url: `${ROUTE_URL.login}` }
	];

	saepIctReferences = saepIctReferences;
	configSubscription: Subscription;
	appTag: string;
	currentYear: number = new Date().getFullYear();

	constructor(public appConfig: CustomerAppConfig) {
		this.configSubscription = this.appConfig.config$.subscribe(config => {
			this.appTag = `${this.saepIctReferences.SOFTWARE} v.${config.tag}`;
		});
	}

	ngOnDestroy() {
		this.configSubscription.unsubscribe();
	}
}
