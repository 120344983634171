<!-- Grid view -->
<ng-container *ngIf="viewType === ViewTypeEnum.module; else listView">
	<div class="card card--grid">
		<a
			href="javascript:void(0)"
			[routerLink]="[
				ROUTE_URL.public,
				itemType === itemTypeEnum.category ? ROUTE_URL.catalog : ROUTE_URL.product,
				linkToItem()
			]"
		>
			<img class="card__image" [src]="imagePath" alt="Card image" />
		</a>
		<div class="card__header" *ngIf="showDetails">
			<h3 class="card__title">
				<a
					href="javascript:void(0)"
					[routerLink]="[
						ROUTE_URL.public,
						itemType === itemTypeEnum.category ? ROUTE_URL.catalog : ROUTE_URL.product,
						linkToItem()
					]"
				>
					{{ this.data?.description | sentencecase }}
				</a>
			</h3>
			<p class="card__price" *ngIf="data.type !== 'category'">
				{{ utilOrderService.getPriceFromDivision(data.divisions) | currency: 'EUR':'symbol':'':'it' }}
			</p>
		</div>
	</div>
</ng-container>

<!-- List View -->
<ng-template #listView>
	<div class="card card--list">
		<div class="row">
			<div class="col-md-6 col-lg-4">
				<a
					href="javascript:void(0)"
					[routerLink]="[
						ROUTE_URL.public,
						itemType === itemTypeEnum.category ? ROUTE_URL.catalog : ROUTE_URL.product,
						linkToItem()
					]"
				>
					<img class="card__image" [src]="imagePath" alt="Card image" />
				</a>
			</div>
			<div class="col-md-6 col-lg-8">
				<div class="card__header" *ngIf="showDetails">
					<h3 class="card__title">
						<a
							href="javascript:void(0)"
							[routerLink]="[
								ROUTE_URL.public,
								itemType === itemTypeEnum.category ? ROUTE_URL.catalog : ROUTE_URL.product,
								linkToItem()
							]"
						>
							{{ this.data?.description | sentencecase }}
						</a>
					</h3>

					<p class="card__price">
						{{ utilOrderService.getPriceFromDivision(data.divisions) | currency: 'EUR':'symbol':'':'it' }}
					</p>
				</div>
				<p class="card__description">{{ this.data?.description }}</p>
			</div>
		</div>
	</div>
</ng-template>
