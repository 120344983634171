import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OfferActionEnum, OfferStateAction } from './offer.actions';
import { OfferStateModel } from '../../model/state/offer-state.model';

export namespace OfferStateReducer {
	export const initialState: BaseStateModel<OfferStateModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(OfferStateAction.load, state => state),
		on(OfferStateAction.loadAll, state => state),
		on(OfferStateAction.update, (state, offer) => offer),
		on(OfferStateAction.save, state => state),
		on(OfferStateAction.remove, (state, offer) => ({
			data: state ? state.data : null,
			type: offer.type
		})),
		on(OfferStateAction.removed, (state, offer) => state),
		on(OfferStateAction.reset, () => initialState),
		on(OfferStateAction.error, (state, offer) => ({
			data: state ? state.data : null,
			type: offer.type
		}))
	);

	export function reducer(state: BaseStateModel<OfferStateModel[]>, action: TypedAction<OfferActionEnum>) {
		return _reducer(state, action);
	}
}
