import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StatisticsBackofficeActionEnum, StatisticsBackofficeStateAction } from './statistics-backoffice.action';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import { StatisticsBackoffice } from '../../model/statistics-backoffice.model';

@Injectable()
export class StatisticsBackofficeEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsBackofficeActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsBackoffice>) => from(this.getStatisticsBackoffice(action))),
			map((action: BaseStateModel<StatisticsBackoffice>) => StatisticsBackofficeStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsBackofficeStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getStatisticsBackoffice(
		action: BaseStateModel<any>
	): Promise<BaseStateModel<StatisticsBackoffice>> {
		await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(
			CouchDocumentType.BACKOFFICE
		)).statisticsPouch
			.getStatistics(action.data.code_item)
			.then(res => {
				action.data = res;
			})
			.catch(err => {console.log(err); return action});
		return action;
	}
}
