import { BaseTableListService } from '@saep-ict/angular-core';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ArticleColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns = [
		{
			name: 'code',
			label: this.translate.instant('article.code'),
			width: 200
		},
		{
			name: 'description',
			label: this.translate.instant('article.field.description')
		}
	];
}
