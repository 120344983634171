import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialComponentsModule } from './modules/material-components.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LoadingOverlayComponent } from '../frame/loading-overlay/loading-overlay.component';
import { PipeModule } from './pipes/pipe.module';
import { DirectivesModule } from './directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CovalentModule } from './modules/covalent.module';
import { ChartsModule } from 'ng2-charts';
import {
	AttachmentResolverService,
	ConfigurationCustomerFunctionService,
	SaepICTAngularCoreModule
} from '@saep-ict/angular-core';
import { MediaCenterModule } from '@saep-ict/media-center';
import { TicketCenterModule } from '@saep-ict/ticket-center';

@NgModule({
	imports: [CommonModule, MaterialComponentsModule],
	declarations: [LoadingOverlayComponent],
	exports: [
		LoadingOverlayComponent,
		RouterModule,
		CovalentModule,
		MaterialComponentsModule,
		NgScrollbarModule,
		ChartsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		DirectivesModule,
		PipeModule,
		MediaCenterModule,
		SaepICTAngularCoreModule,
		TicketCenterModule,
		CommonModule
	],
	providers: [ConfigurationCustomerFunctionService, AttachmentResolverService]
})
export class SharedModule {}
