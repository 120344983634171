import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { StatisticsAgentStateAction, StatisticsAgentActionEnum } from './statistics-agent.action';
import { StatisticsAgent } from '../../model/statistics-agent.model';

export namespace StatisticsAgentStateReducer {
    export const initialState: BaseStateModel<StatisticsAgent> = null;

    const _reducer = createReducer(
		initialState,
		on(StatisticsAgentStateAction.load, state => state),
		on(StatisticsAgentStateAction.update, (state, order) => order),
		on(StatisticsAgentStateAction.reset, () => initialState),
		on(StatisticsAgentStateAction.error, (state, order) => ({ data: state ? state.data : null, type: order.type }))
	);

	export function reducer(
		state: BaseStateModel<StatisticsAgent>,
		action: TypedAction<StatisticsAgentActionEnum>
	) {
		return _reducer(state, action);
	}
}
