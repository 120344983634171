<breadcrumb
	[title]="'contact.list' | translate | sentencecase"
	[pathList]="['Home']"
	*ngIf="
		!activatedRoute.parent.snapshot.paramMap.get('idOrganization') &&
		currentContext !== contextApplicationItemCodeEnum.B2C
	"
></breadcrumb>

<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<contact-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columns"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					[localListHandlerTitle]="'contact.list' | translate | sentencecase"
					[disabledOrderConfirmEmail]="currentContext === contextApplicationItemCodeEnum.B2B"
					(toggleHasNotification)="toggleHasNotification($event)"
					(openDialogContactDetail)="openDialogContactDetail($event)"
					(openDialogConfirmRemoveContact)="openDialogConfirmRemoveContact($event)"
					(selectCompany)="selectCompany($event.level, $event.code)"
				>
				</contact-list-wrapper>
			</mat-card>
		</div>
	</div>
</div>

<button
	mat-fab
	*ngIf="canAddNew"
	class="mat-fab-button"
	(click)="openDialogContact()"
	[matTooltip]="'contact.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
</button>
