import { Injectable } from '@angular/core';
import {
	ITdDataTableColumnKey,
	ConfigurationCustomerFunctionService,
	BaseTableListKeyService
} from '@saep-ict/angular-core';

// constant
import { ConfigurationCustomerArticleDiscountProperty } from '../../../constants/configuration-customer/article/discount-property.constant';

// widget & utility
import { ConfigurationCustomerArticlePriceProperty } from '../../../constants/configuration-customer/article/price-property.constant';

const DECIMAL_FORMAT: (v: any) => any = (v: number) => v.toFixed(2).replace('.', ',');

@Injectable()
export class ProductCatalogColumnService extends BaseTableListKeyService {
	totalColumnSet: ITdDataTableColumnKey[] = [
		{
			name: 'cf_available',
			key: 'cf_available',
			label: '',
			width: 50
		},
		{
			name: 'code',
			key: 'code',
			label: 'Ref',
			width: 120
		},
		{
			name: 'description',
			key: 'description',
			label: 'Descrizione'
		},
		{
			name: 'articlePrice.price',
			key: 'price',
			label: 'Prezzo',
			width: { min: 80, max: 200 },
			numeric: true,
			format: DECIMAL_FORMAT
		},
		{
			name: 'articlePrice.discount',
			key: 'discount',
			label: 'Sconto',
			width: { min: 80, max: 200 },
			format: v => v + '%',
			numeric: true
		},
		{
			name: 'discount_agent',
			key: 'discount_agent',
			label: 'Agent Discount',
			width: { min: 80, max: 200 },
			numeric: true
		},
		{
			name: 'productDetail.input_quantity',
			key: 'qty',
			label: 'Quantità',
			width: { min: 80, max: 200 },
			numeric: true
		},
		{
			name: 'productDetail.qty_free',
			key: 'qty_free',
			label: 'Articoli gratuiti',
			width: { min: 80, max: 200 },
			numeric: true
		},
		{
			name: 'calculate_price',
			key: 'calculate_price',
			label: 'Totale',
			numeric: true,
			format: DECIMAL_FORMAT
		}
	];

	_columns: ITdDataTableColumnKey[] = [
		{
			name: 'cf_available',
			key: 'cf_available',
			label: '',
			width: 50
		},
		{
			name: 'code',
			key: 'code',
			label: 'Ref',
			width: 120
		},
		{
			name: 'description',
			key: 'description',
			label: 'Descrizione'
		},
		{
			name: 'articlePrice.price',
			key: 'price',
			label: 'Prezzo',
			width: { min: 80, max: 200 },
			numeric: true,
			format: DECIMAL_FORMAT
		},
		{
			name: 'articlePrice.discount',
			key: 'discount',
			label: 'Sconto',
			width: { min: 80, max: 200 },
			format: v => v + '%',
			numeric: true
		},
		{
			name: 'discount_agent',
			key: 'discount_agent',
			label: 'Agent Discount',
			width: { min: 80, max: 200 },
			numeric: true
		},
		{
			name: 'productDetail.input_quantity',
			key: 'qty',
			label: 'Quantità',
			width: { min: 80, max: 200 },
			numeric: true
		},
		{
			name: 'productDetail.qty_free',
			key: 'qty_free',
			label: 'Articoli gratuiti',
			width: { min: 80, max: 200 },
			numeric: true
		},
		{
			name: 'calculate_price',
			key: 'calculate_price',
			label: 'Totale',
			numeric: true,
			format: DECIMAL_FORMAT
		}
	];

	// constructor(
	//   private configurationCustomerFunctionService: ConfigurationCustomerFunctionService
	// ) {
	//   super();

	//   this.columns =
	//     this.configurationCustomerFunctionService.returnConfigurationCustomerColumnList(
	//       this.totalColumnSet,
	//       ConfigurationCustomerArticleDiscountProperty
	//     );

	//   this.columns =
	//     this.configurationCustomerFunctionService.returnConfigurationCustomerColumnList(
	//       this.columns,
	//       ConfigurationCustomerArticlePriceProperty
	//     );
	// }
}
@Injectable()
export class ProductKitColumnService extends BaseTableListKeyService {
	constructor() {
		super();
	}

	_columns = [
		{
			name: 'code',
			key: 'code',
			label: 'Ref',
			width: 140
		},
		{
			name: 'description',
			key: 'description',
			label: 'Descrizione'
		},
		{
			name: 'articlePrice.price',
			key: 'price',
			label: 'Prezzo',
			width: { min: 80, max: 120 },
			numeric: true,
			format: DECIMAL_FORMAT
		},
		{
			name: 'productDetail.input_quantity',
			key: 'qty',
			label: 'Quantità',
			width: { min: 80, max: 120 },
			numeric: true
		},
		{
			name: 'productDetail.qty_free',
			key: 'qty_free',
			label: 'Articoli gratuiti',
			width: { min: 80, max: 120 },
			numeric: true
		},
		{
			name: 'calculate_price',
			key: 'calculate_price',
			label: 'Totale',
			width: { min: 80, max: 120 },
			numeric: true,
			format: DECIMAL_FORMAT
		}
	];
}
