import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, UserDetailModel, BaseState, ContextApplicationItemCodeEnum, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';
import { StateFeature } from '../../../state';
import { OrganizationStateAction } from '../../../state/organization/organization.actions';
import { CustomerAppConfig } from '../../../customer-app.config';

@Component({
	selector: 'ticket-center-wrapper',
	templateUrl: './ticket-center-wrapper.component.html',
	styleUrls: ['./ticket-center-wrapper.component.scss'],
    providers: [SubscribeManagerService]
})
export class TicketCenterWrapperComponent implements OnInit {

    organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

    contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
        private store: Store,
        private subscribeManagerService: SubscribeManagerService,
        private appConfig: CustomerAppConfig,
    ) {
        this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
            if (this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B) {
                this.store.dispatch(OrganizationStateAction.load(new BaseState({_id: this.user.current_permission.context_code.code})));
            }
		});
        this.subscribeManagerService.populate(this.subscribeOrganizationStore().subscribe(),'organization-store');
    }

	ngOnInit() {
	}

	ngAfterViewInit(){
		if(this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE){
			window.addEventListener('message', (event) => {
				if (location.ancestorOrigins[0] === this.appConfig.config.portal.origin) {
				  this.hiddenHtmlElement();
				} else {
				  console.log('Data not sent from parent');
				  return;
				}
			  });
		}
	}

	hiddenHtmlElement() {
		const element = document.getElementsByTagName('breadcrumb')[0] as HTMLIFrameElement;
		element.style.visibility = 'hidden';
		element.style.display = 'block';
		element.style.height = '0px';
	}

    ngOnDestroy() {
		this.subscribeManagerService.destroy();
    }

    subscribeOrganizationStore(): Observable<BaseStateModel<OrganizationStateModel>> {
        return this.organization$.pipe(
            filter((store: BaseStateModel<OrganizationStateModel>) => !!(store && store.data)),
            map((store: BaseStateModel<OrganizationStateModel>) => {
                this.organization = store.data;
                return store
            })
        );
    }
}
