import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	CurrentContextPermission,
	LinkCodeModel,
	LinkDetailModel,
	TokenPayload,
	UserDetailModel,
	UserTypeContextModel,
	UtilService
} from '@saep-ict/angular-core';
import { OfflineDeviceService } from '@saep-ict/pouch-db';
import jwt_decode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LanguageListStateAction } from '../../state/language-list/language-list.actions';

import { environment } from '../../../environments/environment';
import { AUTHENTICATED_USER_ENTRY } from '../../constants/misc.constants';
import { CustomerAppConfig } from '../../customer-app.config';
import { courtesyRoute } from '../../router/courtesy-route.config';
import { ROUTE_URL } from '../../router/route-naming';
import { StateFeature } from '../../state';
import { AuxiliaryTableStateAction } from '../../state/auxiliary-table/auxiliary-table.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { AuthService } from '../rest/auth.service';
import { ContextManagerService } from '../structure/context-manager.service';
import { ContextType, UtilGuardService } from './util-guard/util-guard.service';
import { ContextCodeItemErp } from '../../model/configuration-customer/context-code-item.model';
import { UtilEventTrackingService } from '../util/util-event-tracking.service';

@Injectable()
export class AuthTokenGuard implements CanActivate {
	@LocalStorage('authenticationToken') authenticationToken: string;
	// TODO: capire se sia rimpiazzabile da currentContext
	@LocalStorage('link_code') link_code: LinkCodeModel;

	authState: boolean;
	destroy$: Subject<boolean> = new Subject<boolean>();
	user: UserDetailModel;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	contextState$: Observable<BaseStateModel<ContextType>>;
	urlToRedirectTo: string[];

	constructor(
		private router: Router,
		private authService: AuthService,
		private store: Store<any>,
		private utilGuardService: UtilGuardService,
		@Inject(PLATFORM_ID) private platformId: string,
		private offlineDeviceService: OfflineDeviceService,
		protected appConfig: CustomerAppConfig,
		private contextManagerService: ContextManagerService,
		private utilService: UtilService,
		private utilEventTrackingService: UtilEventTrackingService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// TODO: rimuovere questo fix temporaneo dovuto a TODO di frontend/src/app/router/base-routing.ts
		if (route.url.length < 1) {
			this.router.navigate(['dashboard']);
		}
		if (isPlatformServer(this.platformId)) {
			this.router.resetConfig([courtesyRoute, ...this.router.config]);
			this.router.navigate([ROUTE_URL.courtesy]);
			return false;
		}
		// language list
		this.store.dispatch(LanguageListStateAction.loadAll());
		if (!environment.mockup) {
			if (this.authenticationToken) {
				let userState: UserDetailModel;
				let contextState: ContextType;
				let currentContext: UserTypeContextModel;
				this.user$.pipe(take(1)).subscribe(res => {
					userState = res ? res.data : null;
				});

				try {
					if (userState) {
						if (userState.current_permission && userState.current_permission.context_application) {
							currentContext = this.utilGuardService.retrieveContextPermission(
								userState.current_permission.context_application
							);
							this.contextState$ = this.store.select(currentContext.state);
							this.contextState$.pipe(take(1)).subscribe(res => {
								contextState = res ? res.data : null;
							});
						}
					}
				} catch (err) {
					console.log(err);
					this.authService.logout();
					return false;
				}
				if (userState && contextState) {
					return true;
				}
				// decodifico il token e lo piazzo nella variabile del localstorage
				const tk_decoded = jwt_decode(this.authenticationToken);
				this.authService.tokenPayload = new TokenPayload(tk_decoded);
				return this.checkUserPermission(state);
			} else {
				return this.contextManagerService.manageNotAuthenticated(state.url);
			}
		}
		return true;
	}

	async checkUserPermission(state: RouterStateSnapshot): Promise<boolean> {
		// INIT SELECTED DEVICE
		if (!this.offlineDeviceService.selectedDevice) {
			await this.offlineDeviceService.init();
		}
		try {
			this.authState = false;
			let contextList: UserTypeContextModel[];

			// retrieving user information
			this.user = await this.contextManagerService.retrieveUserInfo();

			this.appConfig.config.couch.map(
				async x => (x.offline = await this.contextManagerService.retrieveConnectionState())
			);
			//  start only generic db
			await this.contextManagerService.connectToGenericDb();

			if (!this.link_code) {
				contextList = this.utilGuardService.retrievePermissionRoute(this.user);
				if (contextList.length > 0) {
					this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
				} else {
					this.utilService.showDialog('Context list is not defined', 'OK');
					throw new Error('Context list is not defined');
				}

				if (this.utilGuardService.checkAmbiguousPermission(contextList)) {
					this.urlToRedirectTo = [`/${ROUTE_URL.authentication}/${ROUTE_URL.contextSelection}`];
				} else {
					await this.setUserContext(contextList[0], state.url).catch(err => {
						throw new Error('Cannot connect to couchdb');
					});
				}
			}
			// set current permission by the context, code and general permission
			if (this.link_code) {
				const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
					this.link_code,
					this.user
				);
				// If I don't have any permissions associated I throw an error that logout
				if (!currentPermission.length) {
					this.utilService.showDialog('Permission list is not defined', 'OK');
					throw new Error('Permission list is not defined');
				}

				// TODO: Gestire questi dispatch seguendo anche lo stato di completamento
				this.store.dispatch(AuxiliaryTableStateAction.load());
				// retrieving Auxiliary Permission List
				await this.contextManagerService.retrieveAuxiliaryPermissionList();
				await this.prepareUserContext(state);

				this.user.current_permission = await this.contextManagerService.returnUserCurrentPermission(
					this.link_code.context,
					this.link_code.code,
					currentPermission
				);
				this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
			}

			if (this.urlToRedirectTo) {
				this.router.navigate(this.urlToRedirectTo);
			}
			return this.authState;
		} catch (err) {
			console.error(err);
			this.authService.logout();
			return false;
		}
	}

	/**
	 * Compile currentContext variable which is then passed to the setUserContext method
	 *
	 * @param state - routerSnapshot containing the redirect url
	 */
	async prepareUserContext(state: RouterStateSnapshot): Promise<boolean> {
		const currentContext = this.utilGuardService.retrieveContextPermission(this.link_code.context);
		const contextLink = this.utilGuardService.checkUserContext(this.user, this.link_code.context);
		const currentLink = contextLink.currentContext.context_code_list.find((link: LinkDetailModel) => {
			return link.code === this.link_code.code;
		});
		return new Promise(async (resolve, reject) => {
			if (!currentLink) {
				return reject('current code is invalid');
			}
			return await this.setUserContext(currentContext, state.url, this.link_code.code)
				.then(() => resolve(true))
				.catch(err => reject(false));
		});
	}

	/**
	 * @param context - current context to set in the user model
	 * @param url - User origin URL, to be used to redirect the user to the end of authguard
	 * @param explicitLink - current operator code of the user, if it doesn't exist retrieve the zero element of array
	 */
	setUserContext(context: UserTypeContextModel, url: string, explicitLink?: string): Promise<boolean> {
		if (this.user.current_permission) {
			this.user.current_permission.context_application = context.type;
		} else {
			this.user.current_permission = <CurrentContextPermission>{ context_application: context.type };
		}
		this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
		return new Promise(async (resolve, reject) => {
			if (!explicitLink) {
				const contextLink = this.utilGuardService.checkUserContext(this.user, context.type);
				this.link_code = {
					context: context.type,
					code: contextLink.currentContext.context_code_list[0].code.toString()
				};
			}
			const statusInitContextDb = await this.contextManagerService.initContextDb(context.type);
			if (statusInitContextDb) {
				const sync = await this.contextManagerService.verifyLocalDbSync();
				if (!sync) {
					resolve(true);
				}
			}
			this.utilGuardService.dispatchUserContext(context, this.user, explicitLink);

			this.contextManagerService.initObservableState(context.type).subscribe(
				async (res: ContextType) => {
					return await this.contextManagerService
						.retrieveStateForContext(context.type, res[0] || res)
						.then(() => {
							this.destroy$.next(true);
							this.setRouteTree(context, url);
							return resolve(true);
						})
						.catch(error => {
							this.destroy$.next(true);
							return reject(error);
						});
				},
				error => {
					throw new Error(error);
				}
			);
		});
	}

	/**
	 * route management, hooking the routes for the authenticated user based on the context
	 *
	 * @param userTypeContext - current kind of context with linked data (route, action, state ecc...)
	 * @param url - url to redirect the navigation on the end of workflow
	 */
	setRouteTree(userTypeContext: UserTypeContextModel, url: string) {
		this.loginTracking();
		const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
			this.link_code,
			this.user
		);
		userTypeContext.route[0].children = this.utilGuardService.filterRouteWithPermission(
			userTypeContext.route[0].children,
			currentPermission
		);
		const user_entry = this.router.config.find(route => route.data && route.data.id === AUTHENTICATED_USER_ENTRY);
		if (!user_entry) {
			throw new Error('Route tree does not exist');
		}
		user_entry.children = userTypeContext.route;
		this.router.resetConfig(this.router.config);
		this.authState = true;
		// if user request base url but PUBLIC zone is not available, then navigate to PRIVATE
		if (url == '/' && !this.appConfig.config.permissionContext.includes(ContextApplicationItemCodeEnum.B2C)) {
			this.urlToRedirectTo = ['/', ROUTE_URL.authentication, ROUTE_URL.login];
		} else {
			this.urlToRedirectTo = [url];
		}
	}

	loginTracking() {
		const contextCodeObject: ContextCodeItemErp = this.user.context_application_list
			.find(contextApplication => contextApplication.code === this.link_code.context)
			?.context_code_list.find(contextCode => contextCode.code === this.link_code.code);

		if (contextCodeObject) {
			if ((window.dataLayer[window.dataLayer.length - 1]?.loginDate || 0) < Date.now() - 1000) {
				this.utilEventTrackingService.pushEvent({
					event: 'login',
					userId: this.user.id,
					context: this.link_code.context,
					contextCode: contextCodeObject.code,
					codeErp: contextCodeObject.code_erp,
					loginDate: Date.now()
				});
			}
		}
	}
}
