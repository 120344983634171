import { DatePipe } from '@angular/common';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { BaseTableListService } from '@saep-ict/angular-core';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class CompanyAccountBalanceColumnService extends BaseTableListService {
	constructor(
		@Inject(TranslateService) public translate: TranslateService,
		@Inject(DatePipe) private datePipe: DatePipe
	) {
		super();
	}

	_columns = [
		{
			name: 'number_document',
			label: this.translate.instant('customer_credit.field.number_document'),
			width: 100
		},
		{
			name: 'description_document',
			label: this.translate.instant('customer_credit.field.description_document')
		},
		{
			name: 'flag_give_take_dare',
			label: this.translate.instant('customer_credit.field.give'),
			numeric: true,
			sortable: false
		},
		{
			name: 'flag_give_take_avere',
			label: this.translate.instant('customer_credit.field.take'),
			numeric: true,
			sortable: false
		},
		{
			name: 'date_document',
			label: this.translate.instant('customer_credit.field.date_document'),
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			numeric: true,
			width: 125
		},
		{
			name: 'date_expiration',
			label: this.translate.instant('customer_credit.field.date_expiration'),
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy'),
			numeric: true,
			width: 125
		}
	];
}
