import { ITdDataTableColumn } from '@covalent/core/data-table';
import { BaseTableListService } from '@saep-ict/angular-core';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyDetailDestinationsColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	_columns = [
		{
			name: 'business_name',
			labelPath: 'company.field.business_name',
			label: null
		},
		{
			name: 'address.address',
			labelPath: 'general.address.address',
			label: null
		},
		{
			name: 'address.locality',
			labelPath: 'general.address.city',
			label: null
		},
		{
			name: 'address.province',
			labelPath: 'general.address.province',
			label: null,
			width: 100
		}
		// {
		// 	name: 'action',
		// 	label: '',
		// 	width: 50
		// }
	];
}

@Injectable()
export class CompanyDetailFullDetailPaymentsColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	_columns = [
		{
			name: 'code_item',
			label: 'Codice'
		},
		{
			name: 'description_short',
			label: 'Descrizione'
		}
	];
}
