import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { StatisticsAgentBaseSerieProduct, StatisticsAgentFulfilledData } from '../../../model/statistics-agent.model';
import { StateFeature } from '../../../state';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { QuarterList } from '../chart-configurations/base-chart.service';

@Injectable()
export class FulfilledProductsTableConfigService {
	statistics$: Observable<BaseStateModel<any>>;
	statistics: any;

	fullDataSetQ1: StatisticsAgentBaseSerieProduct[] = [];
	fullDataSetQ2: StatisticsAgentBaseSerieProduct[] = [];
	fullDataSetQ3: StatisticsAgentBaseSerieProduct[] = [];
	fullDataSetQ4: StatisticsAgentBaseSerieProduct[] = [];

	currentDataSet: StatisticsAgentBaseSerieProduct[] = [];

	cardDashboardConfigProductsTop5: CardDashboardConfig = {
		title: 'chart.products_top_x.title',
		subtitle: 'chart.products_top_x.subtitle',
		helpText: 'chart.products_top_x.helptext',
		icon: 'shopping_cart'
	};

	columns: ITdDataTableColumn[] = [
		{
			name: 'description',
			label: this.translate.instant('product.field.name')
		},
		{
			name: 'value',
			label: this.translate.instant('product.field.total_income'),
			numeric: true
		}
	];

	public quarterList;

	constructor(
		private store: Store<any>,
		@Inject(TranslateService) private translate: TranslateService,
		private route: ActivatedRoute
	) {
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			this.statistics$ = this.store.select(StateFeature.getStatisticsDetailSold);
		} else {
			this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
		}
		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.statistics = res.data;
				this.populateQuarterList();
				this.createDataSerie();
				this.populateTable();
			});
	}

	// Functions

	createDataSerie() {
		let productsTop5;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardSold) {
			productsTop5 = this.statistics.products;
		} else {
			productsTop5 = this.statistics.orders.products;
		}

		this.statistics.quarters[0].products.forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetQ1.push(item);
		});

		this.statistics.quarters[1].products.forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetQ2.push(item);
		});

		this.statistics.quarters[2].products.forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetQ3.push(item);
		});

		this.statistics.quarters[3].products.forEach((item: StatisticsAgentBaseSerieProduct) => {
			this.fullDataSetQ4.push(item);
		});
	}

	populateQuarterList() {
		this.quarterList = this.getQuarterList(this.statistics.quarters);
	}

	getQuarterList(list: StatisticsAgentFulfilledData[]): QuarterList[] {
		let quarterList: QuarterList[] = [];
		list.forEach(item => quarterList.push({ q: item.name, y: item.year }));
		return quarterList;
	}

	populateTable(quarter: string = this.quarterList[0].q) {
		switch (quarter) {
			case this.quarterList[0].q:
				this.currentDataSet = this.fullDataSetQ1;
				break;
			case this.quarterList[1].q:
				this.currentDataSet = this.fullDataSetQ2;
				break;
			case this.quarterList[2].q:
				this.currentDataSet = this.fullDataSetQ3;
				break;
			case this.quarterList[3].q:
				this.currentDataSet = this.fullDataSetQ4;
				break;
			default:
				break;
		}
	}
}
