import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import {
	StatisticsDetailClientsActionEnum,
	StatisticsDetailClientsStateAction
} from './statistics-detail-clients.action';
import { StateFeature } from '..';
import { DateMomentService } from '@saep-ict/angular-core';
import { StatisticsDetailClients } from '../../model/statistics-detail-clients.model';
import { StatisticsAgentBaseSerieOrder } from '../../model/statistics-agent.model';

@Injectable()
export class StatisticsDetailClientsEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsDetailClientsActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsDetailClients>) => {
				return from(this.getStatisticsDetailClients(action));
			}),
			mergeMap((action: BaseStateModel<StatisticsDetailClients>) =>
				from(this.sortStatisticsDetailClients(action))
			),
			mergeMap((action: BaseStateModel<StatisticsDetailClients>) =>
				from(this.convertDateStatisticsDetailClients(action))
			),
			map((action: BaseStateModel<StatisticsDetailClients>) => StatisticsDetailClientsStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsDetailClientsStateAction.error(null));
				return caught;
			})
		)
	);

	// Chiavi di Clients
	keyClients = ['active_client', 'inactive_client'];

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService
	) {}

	async getStatisticsDetailClients(
		action: BaseStateModel<StatisticsDetailClients>
	): Promise<BaseStateModel<StatisticsDetailClients>> {
		let currentContext;
		await this.store
			.select(StateFeature.getUserState)
			.pipe(take(1))
			.subscribe(res => {
				currentContext = res ? res.data.current_permission.context_application : null;
			});
		await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.STATISTICS)
		).statisticsPouch
			.getStatisticsDetailClients(currentContext)
			.then(res => {
				action.data = res;
			})
			.catch(err => console.log(err));
		return action;
	}

	async sortStatisticsDetailClients(
		action: BaseStateModel<StatisticsDetailClients>
	): Promise<BaseStateModel<StatisticsDetailClients>> {
		this.keyClients.forEach(key => {
			if (action.data[key]) {
				action.data[key].sort((a, b) => a.date - b.date);
			}
		});
		return action;
	}

	async convertDateStatisticsDetailClients(
		action: BaseStateModel<StatisticsDetailClients>
	): Promise<BaseStateModel<StatisticsDetailClients>> {
		this.keyClients.forEach(key => {
			if (action.data[key]) {
				action.data[key].map((element: StatisticsAgentBaseSerieOrder) => {
					element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
				});
			}
		});

		return action;
	}
}
