<app-page-header [title]="'Il mio account'" [breadcrumbsList]="['Home', 'Il mio account']" class="page-header"></app-page-header>

<section class="tab-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<tab-wrapper></tab-wrapper>
			</div>
		</div>
	</div>
</section>

<!-- <mat-tab-group dynamicHeight mat-align-tabs="start" class="tab">
	<mat-tab>
		<ng-template mat-tab-label>
			<mat-icon class="tab__icon">account_circle</mat-icon>
			{{ 'tab_wrapper.personal_data' | translate | sentencecase }}
		</ng-template>
		<app-my-account-personal-data></app-my-account-personal-data>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>
			<mat-icon class="tab__icon">explore</mat-icon>
			{{ 'tab_wrapper.addresses' | translate | sentencecase }}
		</ng-template>
		<app-my-account-address></app-my-account-address>
	</mat-tab>
	<mat-tab>
		<ng-template mat-tab-label>
			<mat-icon class="tab__icon">local_shipping</mat-icon>
			{{ 'tab_wrapper.orders' | translate | sentencecase }}
		</ng-template>
		<app-my-account-orders></app-my-account-orders>
	</mat-tab>
</mat-tab-group> -->
