<form [formGroup]="form" (ngSubmit)="createNewContact()">
	<div class="row">
		<div class="col-12">
			<h2>{{ data.title | translate | sentencecase }}</h2>
		</div>
	</div>

	<mat-dialog-content class="mat-typograaphy">
		<div class="row">
			<div class="col-md-6">
				<!-- Contact -->
				<mat-form-field>
					<mat-label>
						{{ 'contact.field.first_name' | translate | sentencecase }}
					</mat-label>
					<input matInput formControlName="name" />
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<!-- Contact -->
				<mat-form-field>
					<mat-label>
						{{ 'contact.field.last_name' | translate | sentencecase }}
					</mat-label>
					<input matInput formControlName="surname" />
				</mat-form-field>
			</div>

			<div class="col-md-6">
				<!-- Email -->
				<mat-form-field>
					<mat-label>
						{{ 'contact.field.email' | translate | sentencecase }}
					</mat-label>
					<input matInput formControlName="email" />
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<!-- Phone -->
				<mat-form-field>
					<mat-label>
						{{ 'contact.field.phone' | translate | sentencecase }}
					</mat-label>
					<input matInput formControlName="phone" />
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<!-- Area -->
				<mat-form-field>
					<mat-label>
						{{ 'contact.field.area' | translate | sentencecase }}
					</mat-label>
					<input matInput formControlName="area" />
				</mat-form-field>
			</div>
			<div class="col-md-6">
				<!-- Azienda -->
				<mat-form-field>
					<mat-label>
						{{ 'contact.field.role' | translate | sentencecase }}
					</mat-label>
					<input matInput formControlName="ruoloAziendale" />
				</mat-form-field>
			</div>
			<div class="col-md-12" *ngIf="organization">
				<span class="mr-2">
					{{ 'contact.field.order_confirm_email' | translate | sentencecase }}
				</span>
				<mat-slide-toggle formControlName="toggleConfermaOrdine">
					{{
						'contact.status.' + (form.get('toggleConfermaOrdine').value ? 'active' : 'unactive')
							| translate
							| sentencecase
					}}
				</mat-slide-toggle>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<!-- Da controllare -->
		<mat-toolbar class="height-auto">
			<button mat-button type="button" [mat-dialog-close]="false">
				{{ 'general.close' | translate | sentencecase }}
			</button>
			<button *ngIf="!isDisabled" mat-button mat-raised-button color="primary" type="submit">
				{{ 'general.save' | translate | sentencecase }}
			</button>
		</mat-toolbar>
	</mat-dialog-actions>
</form>
