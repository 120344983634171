import { Component, OnInit, Input } from '@angular/core';

export class AvatarConfigClass {
	urlImage?: string;
	userName: string;
	isRounded?: boolean;
	size?: number;
}
@Component({
	selector: 'avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
	_avatarConfigClass: AvatarConfigClass = null;
	@Input() set avatarConfigClass(avatarConfigClass: AvatarConfigClass) {
		if (avatarConfigClass) {
			this._avatarConfigClass = avatarConfigClass;
		}
	}
	avatarSize: number = 32;

	constructor() {}

	ngOnInit() {
		if (this._avatarConfigClass?.size) {
			this.avatarSize = this._avatarConfigClass.size;
		}
	}

	getInitalsName(name: string): string {
		let initials = '';

		const nameList: string[] = name.split(' ');
		nameList.forEach(item => (initials = initials + item.charAt(0)));

		if (nameList.length > 2) {
			initials = initials.substring(0, 2);
		}

		return initials.toUpperCase();
	}
}
