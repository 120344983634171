import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export class CallToActionConfig {
	text?: string;
	textClass?: string;
	btnLabel?: string;
	theme?: 'primary' | 'accent';
	backgroundImage?: string;
	hasBackdrop?: boolean;
	backdropColor?: string;
}
@Component({
	selector: 'app-call-to-action',
	templateUrl: './call-to-action.component.html',
	styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent implements OnInit {
	@Output() clickButton: EventEmitter<any> = new EventEmitter();

	@Input() callToActionConfig: CallToActionConfig;

	constructor() {}

	ngOnInit(): void {}

	onClickButton() {
		this.clickButton.emit();
	}
}
