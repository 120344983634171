import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// store
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	MediaReplayService,
	LocalListHandlerBase,
	LocalListHandlerBaseModel,
	UtilService,
	ContextCodeItemLink,
	ContextCodeItem
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CouchDocumentType } from '../../../constants/context-state.map';
// model
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
// widet & utility
import { PermissionUtilService } from '../../../service/util/permission-util.service';

@Component({
	selector: 'dialog-add-context-code',
	templateUrl: './dialog-add-context-code.component.html',
	styleUrls: ['./dialog-add-context-code.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class DialogAddContextCodeComponent extends LocalListHandlerBase implements OnInit, OnDestroy {
	localListHandlerData = <LocalListHandlerBaseModel<ContextCodeItem>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code', 'code_erp', 'description']
			}
		},
		pagination: {
			pageSize: 5
		},
		sort: {
			name: 'code',
			order: 'ASC'
		}
	};

	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ContextCodeItemLink,
		private store: Store<any>,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogAddContextCodeComponent>,
		public utilService: UtilService,
		public permissionUtilService: PermissionUtilService,
		public permissionContextListColumnService: PermissionContextListColumnService,
		private subscribeManagerService: SubscribeManagerService
	) {
		super(utilService);
		this.permissionUtilService.resetContextCodeListAssociatedState();
		this.setVisibleTableColumn();
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
		this.store.dispatch(this.data.action.loadList());
	}

	ngOnInit() {}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.permissionUtilService.resetContextCodeListAssociatedState();
	}

	// subscribe
	subscribeContextCodeList(): Observable<BaseStateModel<ContextCodeItem[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				for (let i = 0; i < state.data.length; i++) {
					if (
						!this.data.already_associated_context_code_list.includes(state.data[i]['code']) &&
						Object.values(CouchDocumentType).includes(state.data[i]['type'] as CouchDocumentType)
					) {
						this.localListHandlerData.data.push(state.data[i]);
					}
				}
				this.localListHandlerApplyFilter();
				return state;
			}),
			catchError((error, caught) => {
				console.log(error);
				this.localListHandlerData.data = [];
				this.localListHandlerApplyFilter();
				return caught;
			})
		);
	}

	// widget & utility
	selectCode(permissionContext: ContextCodeItem) {
		this.dialogRef.close(permissionContext);
	}

	setVisibleTableColumn() {
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'permission')
		].hidden = true;
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'action')
		].hidden = true;
	}
}
