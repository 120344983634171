export enum PouchCustomAdapterEnum {
	AGENT = 'pouchDbAgentAdapter',
	ORGANIZATION_B2B = 'pouchDbOrganizationB2BAdapter',
	ORGANIZATION_B2C = 'pouchDbOrganizationB2CAdapter',
	GENERAL = 'pouchDbGeneralAdapter',
	COMMONS = 'pouchDbCommonsAdapter',
	BACKOFFICE = 'pouchDbBackofficeAdapter',
	CRM = 'pouchDbCrmAdapter',
	COMMONS_ORGANIZATION = 'pouchDbCommonsOrganizationAdapter',
	COMMONS_ARTICLE = 'pouchDbCommonsArticleAdapter',
	COMMONS_ORDER = 'pouchDbCommonsOrderAdapter',
	COMMONS_USER = 'pouchDbCommonsUserAdapter',
	COMMONS_AGENT = 'pouchDbCommonsAgentAdapter',
	COMMONS_BACKOFFICE = 'pouchDbCommonsBackofficeAdapter',
	COMMONS_CONTACT = 'pouchDbCommonsContactAdapter'
}
