<div class="row">
	<div class="col-md-12">
		<h2 mat-dialog-title>
			{{ 'opportunity.select_organization' | translate | sentencecase }}
		</h2>
	</div>
</div>

<mat-dialog-content>
	<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>

	<mat-toolbar *ngIf="!isLoading">
		<mat-toolbar-row>
			<span class="flex-span"></span>
			<td-search-box
				backIcon="search"
				[placeholder]="
					('general.filter_by' | translate) + ' ' + ('organization.field.name' | translate) | sentencecase
				"
				[showUnderline]="true"
				[debounce]="500"
				[alwaysVisible]="false"
				[(ngModel)]="localListHandlerData.filters.localSearchText.value"
				(searchDebounce)="localListHandlerApplyFilter()"
			>
			</td-search-box>
		</mat-toolbar-row>
	</mat-toolbar>
	
	<ng-container
		*ngIf="
			localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
			else noData
		"
	>
		<td-data-table
			#dataTable
			[data]="localListHandlerData.dataSubset"
			[columns]="organizationListColumnService.columns"
			[clickable]="true"
			[sortable]="true"
			[sortBy]="localListHandlerData.sort.name"
			[sortOrder]="localListHandlerData.sort.order"
			(sortChange)="localListHandlerApplyFilter({ sort: $event })"
			(rowClick)="selectOrganization($event.row)"
			class="pointer"
		>

		<ng-template tdDataTableTemplate="business_name" let-row="row" let-value="value">
			<p>
				{{ value }}
				<!-- identificativo Spin8 -->
				<ng-container *ngIf="row.code_item">
					<br>
					<small>{{ row.code_item }}</small>
				</ng-container>
				<!-- identificativo ERP -->
				<ng-container *ngIf="row.code_erp">
					<br>
					<small>{{ row.code_erp }}</small>
				</ng-container>
			</p>
			
		</ng-template>
			<ng-template tdDataTableTemplate="description" let-row="row">
				{{ row.business_name }}
			</ng-template>
		</td-data-table>
		<mat-paginator
			#matPaginator
			(page)="localListHandlerApplyFilter({ pagination: $event })"
			[length]="localListHandlerData.pagination.totalFilteredItemCount"
			[pageSize]="localListHandlerData.pagination.pageSize"
			[pageSizeOptions]="[5, 10, 25, 100]"
		>
		</mat-paginator>
	</ng-container>

	<ng-template #noData>
		<p class="placeholder" *ngIf="!isLoading">
			{{ 'general.no_results' | translate | sentencecase }}
		</p>
	</ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-stroked-button mat-dialog-close type="button" color="primary">
		{{ 'general.cancel' | translate | sentencecase }}
	</button>
</mat-dialog-actions>
