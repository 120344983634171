<ms-loading-overlay></ms-loading-overlay>

<ng-scrollbar [autoWidthDisabled]="true" [autoHeightDisabled]="false" [scrollAuditTime]="20"
	#perfectScrollbar
	[style.max-height.px]="mediaReplayService.deviceH"
	class="layout-ctx-public"
	>
	<div #scrollContainer>
		<div [style.min-height.px]="mediaReplayService.deviceH">
			<div class="container-fluid">
				<div class="row mb-0">
					<div class="col-12 p-0">
						<app-header></app-header>
						<router-outlet></router-outlet>
						<app-footer></app-footer>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-scrollbar>
<ps-to-top (onScrollToTop)="scrollToTop()"></ps-to-top>
