import { NgModule } from '@angular/core';

// model
import { LoginContextCodeStateReducer } from './login-context-code.reducer';

// store
import { StoreModule } from '@ngrx/store';

@NgModule({
    imports: [
        StoreModule.forFeature('login-context-code', LoginContextCodeStateReducer.reducer)
    ]
})
export class LoginContextCodeStateModule{}
