import { ITdDataTableColumn } from '@covalent/core/data-table';
import { BaseTableListService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

/**
 * Il service è condiviso in molteplici contesti spesso coesistenti,
 * è consigliabile dunque instanziarlo univocamente nei provider del componente in cui viene utilizzato
 * per evitare che alcuni elementi ritornino inaspettatamente modificati rispetto al default
 *
 * @export
 * @class PermissionContextListColumnService
 * @extends {BaseTableListService}
 */
@Injectable()
export class PermissionContextListColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}

	_columns = [
		{
			name: 'code',
			label: this.translate.instant('context_code.field.code'),
			width: 200
		},
		{
			name: 'code_erp',
			label: this.translate.instant('context_code.field.code_erp'),
			width: 200
		},
		{
			name: 'description',
			label: this.translate.instant('context_code.field.description')
		},
		{
			name: 'permission',
			label: this.translate.instant('context_code.field.permissions')
		},
		{
			name: 'action',
			label: '',
			width: 56,
			numeric: true
		}
	];
}
