<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : false"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : false
		"
	>
		<ng-template tdDataTableTemplate="status" let-value="value">
			<div class="chips-mini">
				<span
					[ngClass]="value"
					[ngStyle]="{ position: 'relative' }"
					matTooltip="{{ 'company.status.' + value | translate | sentencecase }}"
				></span>
			</div>
		</ng-template>
		<ng-template tdDataTableTemplate="business_name" let-value="value" let-row="row">
			<p>
				<a
					href="javascript:void(0)"
					(click)="
						selectCompany.emit({ level: row.level, code: row.code_item ? row.code_item : row.code_erp })
					"
				>
					<!-- selectCompany.emit({ level: row.level, code: row.code_erp ? row.code_erp : row.code_item }) -->
					{{ value | sentencecase }}
				</a>
				<br />
				<small class="cell-subtitle">{{ row.code_erp }}</small>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="address" let-row="row">
			<ng-container *ngFor="let destination of row.destination_list">
				<p *ngIf="destination.is_registered_office && destination.address">
					{{ destination.address.locality | sentencecase }} <br />
					<small class="cell-subtitle">
						{{ destination.address.zip_code ? destination.address.zip_code + ' - ' : '' }}
						{{ destination.address.address | sentencecase }}
						{{ destination.address.country ? '(' + destination.address.country + ')' : '' }}
					</small>
				</p>
			</ng-container>
		</ng-template>
		<ng-template tdDataTableTemplate="average" let-row="row">
			{{ row.average | currency: 'EUR':'symbol':'':'it' }}
		</ng-template>
		<ng-template *ngIf="canAddNew" tdDataTableTemplate="create_new" let-row="row">
			<button
				mat-icon-button
				(click)="openOrderNew.emit(row.code)"
				[matTooltip]="'order.add_new' | translate | sentencecase"
				matTooltipPosition="left"
			>
				<mat-icon>note_add</mat-icon>
			</button>
		</ng-template>
		<ng-template tdDataTableTemplate="organization_type" let-value="value">
			<p>
				{{ value ? ('organization.type.' + value | translate | sentencecase) : '' }}
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="vat_number_or_tax_code" let-row="row">
			<p *ngIf="row.tax_data">
				<!-- <ng-container *ngFor="let destination of row.destination_list">
					<ng-container *ngIf="destination.is_registered_office && destination.address">
						{{ destination.address.country ? destination.address.country : '' }}
					</ng-container>
				</ng-container> -->
				{{ row.tax_data.vat_number ? row.tax_data.vat_number : row.tax_data.tax_code }}
			</p>
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
