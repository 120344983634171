<div class="organization-header" *ngIf="organizationHeaderConfig">
	<div class="organization-header__avatar">
		<avatar [avatarConfigClass]="organizationHeaderConfig.avatar"></avatar>
	</div>

	<div class="organization-header__content">
		<div class="organization-header__info">
			<span class="icon-label" *ngFor="let information of organizationHeaderConfig.informationList">
				<mat-icon>{{ information.icon }}</mat-icon>
				<div class="icon-label__content">
					<div class="icon-label__label">
						{{ information.label | translate | sentencecase }}
					</div>
					<div class="icon-label__value">
						<ng-container *ngIf="information.value; else noValue">
							{{ information.value }}
						</ng-container>
						<ng-template #noValue>
							<span class="no-data">
								{{ 'general.not_defined' | translate | sentencecase }}
							</span>
						</ng-template>
					</div>
				</div>
			</span>
		</div>
	</div>
</div>