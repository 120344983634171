<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
	<div *ngIf="productList && productList.length > 0; else noDataFound">
		<td-data-table #dataTable [data]="productList" [columns]="columns">
			<!-- Description -->
			<ng-template tdDataTableTemplate="description" let-row="row">
				<span [matTooltip]="row.description">
					{{ row.description }} <br />
					<small>{{ row.description_add }}</small>
				</span>
			</ng-template>

			<!-- article price -->
			<ng-template tdDataTableTemplate="articlePrice.price" let-value="value" let-row="row">
				<span>
					{{ value | currency: 'EUR':'symbol':'':'it' }}<br />
					<!-- <small class="cell-subtitle d-block" [innerHTML]="retrieveArticlePrice(row)"> </small> -->
					<small class="cell-subtitle" [innerHTML]="'cad.'"></small>
				</span>
			</ng-template>

			<!-- quantity -->
			<ng-template tdDataTableTemplate="productDetail.input_quantity" let-value="value" let-row="row">
				<span>
					{{ value ? value : 0 }}
					<br />
					<small class="cell-subtitle" [innerHTML]="'Box da ' + row.qty_box + ' art.'"></small>
				</span>
			</ng-template>

			<!-- Articoli gratuiti -->
			<ng-template tdDataTableTemplate="productDetail.qty_free" let-value="value" let-row="row">
				<span>
					{{ value ? value : 0 }}
					<br />
					<small class="cell-subtitle" [innerHTML]="'Box da ' + row.qty_box + ' art.'"></small>
				</span>
			</ng-template>

			<ng-template tdDataTableTemplate="calculate_price" let-value="value" let-row="row">
				<span> {{ (row.productDetail?.input_quantity ? getCalculatePrice(row) : 0) | currency: 'EUR':'symbol':'':'it' }} </span>
			</ng-template>
		</td-data-table>
	</div>
	<ng-template #noDataFound>
		<p>Non ci sono risultati...</p>
	</ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-stroked-button [mat-dialog-close]="false">{{ 'general.back_catalog' | translate | uppercase }}</button>
</mat-dialog-actions>
