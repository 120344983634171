<!-- pre header -->
<div class="pre-header">
	<mat-form-field class="pre-header__lang light">
		<mat-select
			color="primary"
			[disableOptionCentering]="false"
			[value]="language"
			(selectionChange)="langChange($event)"
			[panelClass]="'lang-select-panel'"
		>
			<mat-option *ngFor="let lang of utilTranslateService.languages" [value]="lang"> {{ lang }}</mat-option>
		</mat-select>
	</mat-form-field>
	<span class="flex-span"></span>

	<a
		*ngIf="!user"
		class="pre-header__my-account"
		[routerLink]="['/', ROUTE_URL.login]"
		routerLinkActive="router-link-active"
	>
		{{ 'Il mio account' | uppercase }}
	</a>
	<ms-toolbar-user-button *ngIf="user"></ms-toolbar-user-button>
</div>

<!-- main navbar -->
<div class="header">
	<div class="nav-primary">
		<!-- hamburger menu -->
		<app-header-hamburger
			[navbarIsOpen]="navbarIsOpen"
			(toggleNavBar)="navbarIsOpen = !navbarIsOpen"
		></app-header-hamburger>

		<!-- logo -->
		<a class="nav-primary__logo" [routerLink]="['/' + ROUTE_URL.public]" href="javascript:void(0)"></a>

		<!-- navigazione -->
		<div class="nav-primary__wrapper" [class.open]="navbarIsOpen">
			<ul class="nav-primary__link">
				<li *ngFor="let link of linkList" class="nav-primary__link__item">
					<a
						href="javascript:void(0)"
						[class.active]="path === link.route"
						[title]="link.altText | translate | sentencecase"
						[routerLink]="[link.route]"
					>
						{{ link.label | translate | sentencecase }}
					</a>
				</li>
			</ul>

			<div class="nav-primary__wrapper__footer">
				<a
					*ngIf="!user"
					class="nav-primary__wrapper__footer__my-account"
					[routerLink]="['/', ROUTE_URL.login]"
					routerLinkActive="router-link-active"
				>
					{{ 'Il mio account' | uppercase }}
				</a>
				<ms-toolbar-user-button *ngIf="user" [openUp]="true"></ms-toolbar-user-button>

				<span class="flex-span"></span>
				<mat-form-field class="pre-header__lang">
					<mat-select
						color="primary"
						[disableOptionCentering]="false"
						[value]="Language.ITALIAN"
						(selectionChange)="langChange($event)"
						[panelClass]="'lang-select-panel'"
					>
						<mat-option *ngFor="let lang of utilTranslateService.languages" value="it">
							{{ lang }}</mat-option
						>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div class="backdrop" [class.open]="navbarIsOpen"></div>
	</div>

	<span class="flex-span"></span>

	<div class="nav-primary__search-field__wrapper" [class.open]="mobileSearchIsOpen">
		<button
			mat-icon-button
			aria-label="Chiudi ricerca"
			class="nav-primary__search-field__close"
			(click)="mobileSearchIsOpen = false"
		>
			<mat-icon>clear</mat-icon>
		</button>

		<mat-form-field class="nav-primary__search-field" appearance="outline">
			<!-- [matAutocomplete]="auto"
			ngModel
			(ngModelChange)="filter($event)" -->
			<input
				type="text"
				class="nav-primary__search-field__input"
				matInput
				placeholder="Cerca nel negozio..."
				(keyup.enter)="filter($event.target.value, true)"
				#search
			/>
			<!-- <mat-autocomplete
				#auto="matAutocomplete"
				(optionSelected)="goToArticle($event.option.value)"
				[displayWith]="getOptionText"
			>
				<mat-option *ngFor="let option of filteredOptions | async" [value]="option">
					{{ option.description }}
				</mat-option>
			</mat-autocomplete> -->

			<button
				mat-flat-button
				color="primary"
				(click)="filter(search.value, true)"
				class="nav-primary__search-field__submit"
				[disabled]="!search.value"
			>
				<mat-icon>search</mat-icon>
			</button>
		</mat-form-field>
	</div>

	<button
		class="nav-primary__search-icon"
		mat-icon-button
		aria-label="Apri ricerca"
		(click)="mobileSearchIsOpen = true"
	>
		<mat-icon>search</mat-icon>
	</button>

	<a
		href="javascript:void(0)"
		mat-icon-button
		aria-label="Apri ricerca"
		[routerLink]="['/' + ROUTE_URL.public + '/' + ROUTE_URL.cart]"
		class="nav-primary__cart"
	>
		<mat-icon [matBadge]="getOrderItemsNumber()" matBadgeColor="accent">shopping_cart</mat-icon>
	</a>
</div>

<!-- <div class="header-space"></div> -->
