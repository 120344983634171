import { BodyTablePouchModel, HeaderTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import {
	ArticleListTypePouchModel,
	CausalHeaderSoPouchModel,
	CausalRowSoPouchModel,
	CodeVatPouchModel,
	CountryIsoPouchModel,
	LocationDepartureDivisionPouchModel,
	LocationDepartureGoodsPouchModel,
	PaymentPouchModel,
	UmPouchModel
} from '@saep-ict/pouch_agent_models/model/table-list-pouch.model';
import { DivisionPouchModel } from '@saep-ict/pouch_agent_models/model/organization-pouch.model';
import { PositionPouchModel } from '@saep-ict/pouch_agent_models';

export interface AuxiliaryTableStateModel {
	areaCommercialList?: BodyTablePouchModel[];
	causalHeaderSoList?: CausalHeaderSoPouchModel[];
	causalRowSoList?: CausalRowSoPouchModel[];
	countryIsoList?: CountryIsoPouchModel[];
	codeVatList?: CodeVatPouchModel[];
	articlePriceList?: ArticleListTypePouchModel[];
	methodShippingList?: BodyTablePouchModel[];
	methodDeliveryList?: BodyTablePouchModel[];
	paymentList?: PaymentPouchModel[];
	causalSuspensionOrderList?: BodyTablePouchModel[];
	umList?: UmPouchModel[];
	typesCurrencyList?: BodyTablePouchModel[];
	carrierList?: BodyTablePouchModel[]; // vettori
	divisionList?: DivisionPouchModel[];
	locationDepartureDivisionList?: LocationDepartureDivisionStateModel[];
	locationDepartureGoodsList?: LocationDepartureGoodsPouchModel[];
	groupArticle?: BodyTablePouchModel[];

	// tabelle non trasversali ma aggiornate in base a condizioni
	// relative al flusso applicativo
	kilometricRangeList?: KilometricRangePouchModel[];
	startingWarehouseCarrierTypeList?: StartingWarehouseCarrierTypePouchModel[];
	carrierTypePumpingChargeList?: CarrierPumpingChargePouchModel[];
	startingWarehouseDepositChargeList?: StartingWarehouseDepositPouchModel[];
	currencyList?: CurrencyStateModel[];
}

export interface LocationDepartureDivisionStateModel extends LocationDepartureDivisionPouchModel {
	transport?: StartingWarehouseCarrierTypePouchModel[];
	deposit?: string;
	position?: PositionPouchModel;
}

export interface StartingWarehouseCarrierTypePouchModel {
	code: string;
	description: string;
	doc_detail: string;
}

export interface KilometricRangePouchModel {
	distance: string;
	groups: KilometricRangeGroupPouchModel[];
}

export interface KilometricRangeGroupPouchModel {
	code: string;
	group: string;
	value: number;
}

export interface StartingWarehouseDepositPouchModel {
	article: string;
	value: number;
}

export interface CarrierPumpingChargePouchModel {
	value: number;
	length_max: number;
}

export interface CurrencyStateModel {
	code_iso: string;
	code_item: string;
	description: string;
}

export const AuxiliaryDictionary = [
	{
		key: 'table_area_commercial',
		type: 'areaCommercialList'
	},
	{
		key: 'table_causal_header_so',
		type: 'causalHeaderSoList'
	},
	{
		key: 'table_causal_row_so',
		type: 'causalRowSoList'
	},
	// {
	// 	key: 'table_country_iso',
	// 	type: 'countryIsoList'
	// },
	// {
	// 	key: 'table_code_vat',
	// 	type: 'codeVatList'
	// },
	// {
	// 	key: 'table_list_type',
	// 	type: 'articlePriceList'
	// },
	{
		key: 'table_method_shipping',
		type: 'methodShippingList'
	},
	{
		key: 'table_method_delivery',
		type: 'methodDeliveryList'
	},
	{
		key: 'table_payment',
		type: 'paymentList'
	},
	// {
	// 	key: 'table_causal_suspension_order',
	// 	type: 'causalSuspensionOrderList'
	// },
	// {
	// 	key: 'table_um',
	// 	type: 'umList'
	// },
	// {
	// 	key: 'table_type_currency',
	// 	type: 'typesCurrencyList'
	// },
	// {
	// 	key: 'table_carrier',
	// 	type: 'carrierList'
	// },
	// {
	// 	key: 'table_division',
	// 	type: 'divisionList'
	// },
	// {
	// 	key: 'table_location_departure_division',
	// 	type: 'locationDepartureDivisionList'
	// },
	// {
	// 	key: 'table_location_departure_goods',
	// 	type: 'locationDepartureGoodsList'
	// },
	// {
	// 	key: 'table_group_article',
	// 	type: 'groupArticle'
	// }
	{
		key: 'table_currency',
		type: 'currencyList'
	}
];
