import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import {
	AllDocsDataPouchModel,
	ListDataPouchModel,
	OrderPouchModel,
	OrderProgressPouchModel
} from '@saep-ict/pouch_agent_models';

import { RestBasePk } from '@saep-ict/angular-core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel } from '../../../../model/state/order-state.model';
import { OrderListFilterModel } from '../../filter/order-filter.model';
import { PouchDeleteResponse, PouchErrorResponse } from '../../model/pouch-base-response.model';
import { LAST_CHARACTER } from '../../../../constants/pouchdb.constants';

export class OrderPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getOrderDetail(data: RestBasePk): Promise<OrderStateModel> {
		return new Promise((resolve, reject) => {
			this.get(data.id)
				.then((doc: OrderStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getOrderProgress(data: RestBasePk): Promise<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>> {
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'progress'
					},
					{
						doc_id: data.id
					}
				]
			};
			this.getWithIndexFilter('order-progress', this.selector)
				.then((res: ListDataPouchModel<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>>) => {
					res.docs ? resolve(res.docs[0]) : resolve(undefined);
					// resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	getOrderProgessList(): Promise<AllDocsDataPouchModel<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>>> {
		return new Promise((resolve, reject) => {
			const opts = {
				startkey: 'progress_order',
				endkey: 'progress_order_' + LAST_CHARACTER,
				include_docs: true
			};
			this.allDocs(opts)
				.then((res: AllDocsDataPouchModel<OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>>) => {
					resolve(res);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putOrder(data: OrderStateModel, isGuid: boolean): Promise<OrderStateModel> {
		const guid = isGuid ? 'order' : data._id;
		data.source = 'APPLICATION';
		data.type = 'order';
		return new Promise((resolve, reject) => {
			this.put<OrderStateModel>(data, guid, isGuid)
				.then((doc: OrderStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteOrder(order: OrderStateModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(order)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getOrderList(filter: OrderListFilterModel): Promise<AllDocsDataPouchModel<OrderStateModel>> {
		return new Promise((resolve, reject) => {
			const opts = {
				startkey: 'order_',
				endkey: 'order_' + LAST_CHARACTER,
				include_docs: true
			};
			this.allDocs(opts)
				.then((res: AllDocsDataPouchModel<OrderStateModel>) => {
					if (filter.client_code) {
						res.rows = res.rows.filter(row => filter.client_code === row.doc.header.organization.code_item);
					}
					if (filter.status) {
						res.rows = res.rows.filter(row => filter.status.includes(row.doc.header.status));
					}
					resolve(res);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	setField(key: string, condition: any) {
		this.selector.$and.push({ [key]: condition });
	}

	getAllOrder(filter: OrderListFilterModel): Promise<AllDocsDataPouchModel<OrderStateModel>> {
		return new Promise((resolve, reject) => {
			const opts = {
				startkey: 'order_',
				endkey: 'order_' + LAST_CHARACTER,
				include_docs: true
			};
			this.allDocs(opts)
				.then((res: AllDocsDataPouchModel<OrderStateModel>) => {
					if (filter.client_code) {
						res.rows = res.rows.filter(row => filter.client_code === row.doc.header.organization.code_item);
					}
					if (filter.status) {
						res.rows = res.rows.filter(row => filter.status.includes(row.doc.header.status));
					}
					resolve(res);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
