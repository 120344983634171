import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[appMaxNumberInput]'
})
export class MaxNumberInputDirective {
    @Input("maxValue") maxValue = Number.MAX_SAFE_INTEGER;

    private run() {
        setTimeout(() => {
            const currentValue: number = +this.el.nativeElement.value;
            if (currentValue > this.maxValue) {
                this.el.nativeElement.value = this.maxValue;
            }
        });
    }

    constructor(private el: ElementRef) {}

    @HostListener("keypress", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        this.run();
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        this.run();
    }
}
