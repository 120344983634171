<div class="unordered-list">
	<h4 class="unordered-list__title" *ngIf="title">{{ title }}</h4>

	<ul class="unordered-list__list" *ngIf="items">
		<li class="unordered-list__item" *ngFor="let item of items">
			<a
				href="javascript:void(0)"
				[routerLink]="[item.url]"
				[innerHTML]="item.label | translate | sentencecase"
				[title]="item.label | translate | sentencecase"
			></a>
		</li>
	</ul>
</div>
