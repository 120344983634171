<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="row">
		<ng-container *ngIf="type === typePasswordVerifyEnum.CHANGE">
			<div class="col-2 icon-input">
				<mat-icon>vpn_key</mat-icon>
			</div>
			<div class="col-10">
				<mat-form-field>
					<input matInput formControlName="oldPassword"
						placeholder="{{ 'login.general.oldPassword' | translate | sentencecase }}"
						[type]="hidePassword[0] ? 'password' : 'text'" />
					<mat-icon class="mat-icon-hide highlight" matSuffix (click)="hidePassword[0] = !hidePassword[0]">
						{{ hidePassword[0] ? 'visibility_off' : 'visibility' }}
					</mat-icon>
				</mat-form-field>
			</div>
		</ng-container>
		<div class="col-2 icon-input">
			<mat-icon>lock</mat-icon>
		</div>
		<div class="col-10">
			<mat-form-field>
				<input matInput formControlName="newPassword"
					placeholder="{{ 'login.general.newPassword' | translate | sentencecase }}"
					[type]="hidePassword[1] ? 'password' : 'text'" #newPassword
					(keyup)="onTypePassword(newPassword.value)" />
				<mat-icon class="mat-icon-hide highlight" matSuffix (click)="hidePassword[1] = !hidePassword[1]">
					{{ hidePassword[1] ? 'visibility_off' : 'visibility' }}
				</mat-icon>
			</mat-form-field>
		</div>
		<div class="col-2 icon-input">
			<mat-icon>bookmark</mat-icon>
		</div>
		<div class="col-10">
			<mat-form-field>
				<input matInput formControlName="newPasswordConfirmed"
					placeholder="{{ 'login.general.newPasswordConfirmed' | translate | sentencecase }}"
					[type]="hidePassword[2] ? 'password' : 'text'" />
				<mat-icon class="mat-icon-hide highlight" matSuffix (click)="hidePassword[2] = !hidePassword[2]">
					{{ hidePassword[2] ? 'visibility_off' : 'visibility' }}
				</mat-icon>
			</mat-form-field>
		</div>
	</div>

	<password-meter [newPassword]="newPasswordEmitter.asObservable()"
		(isPasswordValidEmitter)="isPasswordValidCheck($event)"></password-meter>

	<!-- Da controllare -->
	<mat-toolbar>
		<button mat-raised-button [color]="'primary'" [disabled]="!form.valid || !isPasswordValid">
			{{ 'login.password_update.save_new_password' | translate | sentencecase }}
		</button>
	</mat-toolbar>

	
</form>