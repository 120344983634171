// model
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';
// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { AgentListActionEnum, AgentListStateAction } from './agent-list.actions';
import { BaseStateModel } from '@saep-ict/angular-core';

export namespace AgentListStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchModel[]> = null;
	const _reducer = createReducer(
		initialState,
		on(AgentListStateAction.loadList, state => state),
		on(AgentListStateAction.updateList, (state, param) => param),
		on(AgentListStateAction.updateItem, (state, param) => param),
		on(AgentListStateAction.createItemSuccess, (state, param) => param),
		on(AgentListStateAction.deleteItemSuccess, (state, param) => param),
		on(AgentListStateAction.reset, () => initialState),
		on(AgentListStateAction.error, (state, param) => param)
	);

	export function reducer(state: BaseStateModel<BodyTablePouchCustomModel[]>, action: TypedAction<AgentListActionEnum>) {
		return _reducer(state, action);
	}
}
