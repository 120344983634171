import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TdPagingBarComponent } from '@covalent/core/paging';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	ListWrapperComponent,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { OpportunityPouchModel, OpportunityStatusCodeEnum, OpportunityStatusEnum, OpportunityTypeEnum } from '../../../model/state/opportunity-state.model';
import { OpportunityListColumnService } from '../../../service/td-data-table/implementation/opportunity-list.service';
import { UtilOpportunityService } from '../../../service/util/util-opportunity.service';
import { StateFeature } from '../../../state';
import { OpportunityStateAction, OpportunityActionEnum } from '../../../state/opportunity/opportunity.actions';
import * as _ from 'lodash';
import { ROUTE_URL } from '../../../router/route-naming';

@Component({
	selector: 'opportunity',
	templateUrl: './opportunity.component.html',
	styleUrls: ['./opportunity.component.scss'],
	providers: [OpportunityListColumnService, SubscribeManagerService]
})
export class OpportunityComponent implements OnDestroy {
	@ViewChild('listWrapper', { static: false }) public listWrapper: ListWrapperComponent;
	@ViewChild('pagingBarPageSize', { static: false }) public pagingBarPageSize: TdPagingBarComponent;

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel[];
	public opportunityType: string;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	opportunityTypeEnum = OpportunityTypeEnum;
	public columns = this.opportunityColumnService.columns;

	public listPageBaseData = <LocalListHandlerBaseModel<OpportunityPouchModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'title',
			order: 'ASC'
		}
	};

	filterOpened = false;
	formFilters: FormGroup;

	public idOrganization = this.activatedRoute.parent.snapshot.paramMap.get('idOrganization');

	constructor(
		private store: Store<any>,
		public opportunityColumnService: OpportunityListColumnService,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: UtilOpportunityService,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder
	) {
		this.store.dispatch(OpportunityStateAction.loadAll());
		this.createFormFilters();

		// handle column visibility
		// if(this.opportunityType === OpportunityTypeEnum.LEAD){
		// 	this.columns.map(col => {
		// 		col.hidden = ['organization_details'].includes(col.name);
		// 	});
		// }

		this.subscribeManagerService.populate(this.subscribeOpportunityList().subscribe(), 'subscribeOpportunityList');

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	/**
	 * Subscription
	 */

	subscribeOpportunityList() {
		return this.activatedRoute.paramMap.pipe(
			mergeMap(params => {
				this.opportunityType = params.get('opportunityType')
					? params.get('opportunityType').toLowerCase()
					: null;
				return this.opportunity$;
			}),
			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OpportunityPouchModel[]>) => {
				switch (state.type) {
					case OpportunityActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case OpportunityActionEnum.ERROR:
						throw new Error(OpportunityActionEnum.ERROR);

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(opportunityList: OpportunityPouchModel[]) {

		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.CRM: {
				if (this.idOrganization) {
					opportunityList = this.getListFilteredByOrganization(opportunityList);
				} else {
					opportunityList = this.getListFilteredByType(opportunityList);
				}
				break;
			}
			case ContextApplicationItemCodeEnum.AGENT: {
				break;
			}
			case ContextApplicationItemCodeEnum.B2B: {
				break;
			}
			case ContextApplicationItemCodeEnum.BACKOFFICE: {
				break;
			}
		}

		this.opportunity = opportunityList;
		// filtri sidebar
		this.onFormFiltersSubmit();
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: OpportunityPouchModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	/**
	 * Filters sidebar
	 */

	createFormFilters() {
		this.formFilters = this.fb.group({
			status_open: true
		});
	}

	resetFilters() {
		this.formFilters.patchValue({
			status_open: false
		});
		this.onFormFiltersSubmit();
	}

	onFormFiltersSubmit() {
		let filteredList = _.cloneDeep(this.opportunity);

		if (this.formFilters && this.formFilters.get('status_open').value){
			filteredList = filteredList.filter(opportunity => opportunity.status_code === OpportunityStatusCodeEnum.Open);
		}

		this.updateListPageBaseData(filteredList);
	}

	/**
	 * navigation
	 */

	goToOpportunityDetail(opportunity: OpportunityPouchModel) {
		this.router.navigate([
			ROUTE_URL.private,
			ROUTE_URL.opportunity,
			opportunity.type.replace('opportunity_', ''),
			opportunity._id
		]);
	}

	createNew() {
		// http://localhost:4200/dashboard/opportunity/lead/opportunity_14528bb6-0f14-4f8e-741a-ecfa602878f3/detail
		this.router.navigate([
			ROUTE_URL.private,
			ROUTE_URL.opportunity,
			this.opportunityType,
			ROUTE_URL.newOpportunity
		]);
	}

	// CRM filter by type
	getListFilteredByType(opportunityList: OpportunityPouchModel[]) {
		return opportunityList.filter(opportunity => opportunity.type.includes(this.opportunityType));
	}

	// CRM filter by type
	getListFilteredByOrganization(opportunityList: OpportunityPouchModel[]) {
		return opportunityList.filter(opportunity => {
			if(opportunity && opportunity.organization && opportunity.organization.code){
				return opportunity.organization.code === this.idOrganization
			} 
			return false;
		});
	}
}
