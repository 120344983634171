<div class="row">
	<div class="col-md-12">
		<h2 mat-dialog-title>
			{{ 'opportunity.select_assegnee' | translate | sentencecase }}
		</h2>
	</div>
</div>

<mat-dialog-content>
	<!-- <loading-results-spinner *ngIf="isLoading"></loading-results-spinner> -->
	<ng-container
		*ngIf="userManagementState && userManagementState.data && userManagementState.data.length > 0; else noData"
	>
		<mat-toolbar>
			<mat-toolbar-row>
				<span class="flex-span"></span>
				<td-search-box
					backIcon="search"
					[placeholder]="
						('general.filter_by' | translate) + ' ' + ('user.field.username' | translate) | sentencecase
					"
					[showUnderline]="true"
					[debounce]="500"
					[alwaysVisible]="false"
					[(ngModel)]="userManagementState.dataSetting.appliedFilter.text"
					(searchDebounce)="loadUserList()"
				>
				</td-search-box>
			</mat-toolbar-row>
		</mat-toolbar>

		<td-data-table
			#dataTable
			[data]="userManagementState.data"
			[columns]="userManagementListColumnService.columns"
			[clickable]="true"
			(sortChange)="sortChange($event)"
			(rowClick)="selectAssegnee($event.row)"
		>
			<ng-template tdDataTableTemplate="avatar" let-value="value" let-row="row">
				<img
					*ngIf="value; else noAvatar"
					[src]="value"
					[alt]="row.username"
					[title]="row.username"
					class="avatar"
				/>
				<ng-template #noAvatar>
					<mat-icon class="avatar--placeholder">person</mat-icon>
				</ng-template>
			</ng-template>

			<ng-template tdDataTableTemplate="full_name" let-value="value" let-row="row">
				{{ row.first_name || '' }}
				{{ row.first_name ? ' ' : '' }}
				{{ row.last_name || '' }}
				

			</ng-template>
		</td-data-table>

		<td-paging-bar
			#pagingBarPageSize
			[firstLast]="true"
			[pageSize]="userManagementState.dataSetting.pagination.page_size"
			[total]="userManagementState.dataSetting.pagination.total_element"
			(change)="paginationChange($event)"
		>
			<span hide-xs>{{ 'general.items_per_page' | translate | sentencecase }}</span>
			<mat-select
				[style.width.px]="50"
				[(ngModel)]="userManagementState.dataSetting.pagination.page_size"
				(ngModelChange)="changePageSize($event)"
			>
				<mat-option *ngFor="let size of [5, 10, 20, 50]" [value]="size">
					{{ size }}
				</mat-option>
			</mat-select>
		</td-paging-bar>
	</ng-container>
	<ng-template #noData>
		<p class="placeholder">{{ 'user.not_found' | translate | sentencecase }}</p>
	</ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
	<button mat-stroked-button mat-dialog-close type="button" color="primary">
		{{ 'general.cancel' | translate | sentencecase }}
	</button>
</mat-dialog-actions>
