<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<!-- <span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span> -->
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
</mat-toolbar>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : false"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : false
		"
	>
		<ng-template tdDataTableTemplate="number_document" let-row="row" let-value="value">
			{{ value }}
		</ng-template>
		<ng-template tdDataTableTemplate="description_document" let-row="row" let-value="value">
			{{ value | titlecase }}
		</ng-template>
		<!--  se esiste currency allora amount_document non è in euro -->
		<ng-template tdDataTableTemplate="flag_give_take_dare" let-row="row" let-value="value">
			<p *ngIf="row.flag_give_take === giveTakeFlagEnum.take" class="success">
				{{ row.amount_document_euro | currency: 'EUR':'symbol':'':'it' }} <br />
				<small class="opacity-50" *ngIf="row.currency">
					{{ row.amount_document | currency: '':'':'':'it' }}
					{{ row.currency }}
				</small>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="flag_give_take_avere" let-row="row" let-value="value">
			<p *ngIf="row.flag_give_take === giveTakeFlagEnum.give" class="warning">
				{{ row.amount_document_euro | currency: 'EUR':'symbol':'':'it' }} <br />
				<small class="opacity-50" *ngIf="row.currency">
					{{ row.amount_document | currency: '':'':'':'it' }}
					{{ getCurrencyIsoCode(row.currency) }}
				</small>
			</p>
		</ng-template>
		<ng-template tdDataTableTemplate="date_expiration" let-row="row" let-value="value">
			<mat-icon *ngIf="row.flag_expired_bool" matTooltip="{{ 'general.expired' | translate | sentencecase }}"
				>error_outline</mat-icon
			>&nbsp;
			{{ value | date: 'dd/MM/yyyy' }}
		</ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'customer_credit.not_found' | translate | sentencecase }}
	</p>
</ng-template>
