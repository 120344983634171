import { Injectable } from '@angular/core';
import { ArticleState, OrderProductModel, ArticleKitState } from '../../model/state/article-list-state.model';
import { UtilPriceService } from './util-price.service';
import { PriceModel } from '../../model/price.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogProductKitComponent } from '../../widget/dialog/dialog-product-kit/dialog-product-kit.component';
import { ArticleFilterModel } from '../pouch-db/filter/article-list-filter.model';
import { IS_MULTIDIVISION, DEFAULT_DIVISION_CODE } from '../../constants/division.constants';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';
import { PouchAdapterSelectorService } from '../pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';

@Injectable({
	providedIn: 'root'
})
export class UtilArticleKitService {
	constructor(
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private utilPriceService: UtilPriceService,
		private dialog: MatDialog
	) {}

	async getArticleKitDetail(
		article: ArticleState,
		organization: OrganizationPouchModel,
		divisionCode?: string
	): Promise<ArticleState[]> {
		const articleListFromKit: ArticleState[] = [];
		let articleKit: ArticleKitState;

		// recupero article-kit legato all'articole
		await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ARTICLE)).articleKitPouch
			.getArticleKit(article.code)
			.then(res => (articleKit = res))
			.catch(err => console.log(err));

		const articleKitComponents = articleKit ? articleKit.components : [];

		// per ogni elemento dell'array di component
		for (let i = 0; i < articleKitComponents.length; i++) {
			let articleFromKit: ArticleState;

			// recupero il dettaglio dell'articolo
			await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ARTICLE)
			).articlePouch
				.getArticleDetail({
					id: articleKitComponents[i].code_component
				})
				.then(res => (articleFromKit = res))
				.catch(err => console.log(err));

			if (articleFromKit) {
				// e i prezzi
				const articleFilter: ArticleFilterModel = {
					organization: organization,
					article: articleFromKit,
					valid: true
				};
				let articlePrice: PriceModel;

				await (
					await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ARTICLE)
				).pricePouch
					.getPrice(articleFilter)
					.then(res => (articlePrice = res))
					.catch(err => console.log(err));

				if (articlePrice) {
					const division = IS_MULTIDIVISION ? divisionCode : DEFAULT_DIVISION_CODE;
					articleFromKit.articlePrice = this.utilPriceService.deprecatedMapArticlePrice(
						articlePrice,
						division
					);
				}

				// inserisco le info che ho dal kit
				articleFromKit.productDetail = <OrderProductModel>{};
				articleFromKit.productDetail.input_quantity = articleKitComponents[i].coefficient_quantity_goods;
				articleFromKit.productDetail.qty_free = articleKitComponents[i].coefficient_discount_goods;
				articleListFromKit.push(articleFromKit);
			}
		}

		return articleListFromKit;
	}

	openDialogKit(article: ArticleState) {
		this.dialog.open(DialogProductKitComponent, {
			data: {
				title: article.description,
				productList: article.articleKitList
			},
			panelClass: 'dialog-medium'
		});
	}
}
