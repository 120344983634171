import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';

import { ArticleState } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { CustomerAppConfig } from '../../customer-app.config';
import { CatalogService } from '../../service/rest/catalog.service';
import { ArticleDetailActionEnum, ArticleDetailStateAction } from './article-detail.actions';
import { StateFeature } from '..';

@Injectable()
export class ArticleDetailEffects {
	loadSingleWithCode$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ArticleDetailActionEnum.LOAD_SINGLE_WITH_CODE),
			mergeMap((action: { code: string }) => from(this.getSingleArticleDetail(action.code))),
			map((article: BaseStateModel<ArticleState>) => ArticleDetailStateAction.update(article)),
			catchError((error, caught) => {
				this.store.dispatch(ArticleDetailStateAction.error(null));
				return caught;
			})
		)
	);

	articleList$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleList);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private appConfig: CustomerAppConfig,
		private catalogService: CatalogService
	) {}

	getSingleArticleDetail(code: string) {
		return new Promise((resolve, reject) => {
			if (this.appConfig.authenticationToken) {
				this.articleList$.pipe(filter(articleList => articleList != null)).subscribe(articleList => {
					resolve(articleList.data.find(article => article.code == code));
				});
			} else {
				this.catalogService
					.getArticle({ code: code })
					.then(result => resolve(result.data))
					.catch(err => {
						console.log(err);
					});
			}
		})
			.then(res => {
				return res;
			})
			.catch(err => {
				console.log(err);
			});
	}
}
