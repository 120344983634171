import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { chartTimeRange7Days, ChartTimeRangeModel } from '../../constants/chart-time-range.constants';
import { QuarterList } from '../../service/chart-structure/chart-configurations/base-chart.service';

export class CardDashboardConfig {
	title: string;
	subtitle: string;
	subtitleTranslateParams?: {};
	titleTranslateParams?: {};
	icon?: string;
	theme?: 'accent';
	rangeTempList?: ChartTimeRangeModel;
	quarterList?: QuarterList[];
	helpText?: string;
	detailLink?: string;
}

@Component({
	selector: 'card-dashboard',
	templateUrl: './card-dashboard.component.html',
	styleUrls: ['./card-dashboard.component.scss']
})
export class CardDashboardComponent implements OnInit {
	@Input('cardDashboardConfig') set cardDashboardConfig(c: CardDashboardConfig) {
		if (c) {
			this._cardDashboardConfig = c;
		}
	}
	_cardDashboardConfig: CardDashboardConfig;

	@Output() changeTimeRange = new EventEmitter<number>();

	chartTimeRangeDays = chartTimeRange7Days;

	constructor() {}

	ngOnInit() {}
}
