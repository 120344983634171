export enum ProductVariationTypeEnum {
	ADDED = "ADDED",
	REMOVED = "REMOVED",
	REPLACED = "REPLACED",
	PROPERTY_CHANGED = "PROPERTY_CHANGED"
}

export enum ProductVariationIconEnum {
	ADDED = "add_circle",
	REMOVED = "remove_circle",
	REPLACED = "compare_arrows",
	PROPERTY_CHANGED = "call_split"
}
