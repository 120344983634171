import { Component, OnDestroy, OnInit } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { PaymentPouchModel } from '@saep-ict/pouch_agent_models/model/table-list-pouch.model';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AuxiliaryTableStateModel } from '../../../../../model/state/auxiliary-table-list';
import { BaseState, BaseStateModel, ContextApplicationItemCodeEnum, UserDetailModel } from '@saep-ict/angular-core';
import { CompanyDetailFullDetailPaymentsColumnService as CompanyDetailFullDetailPaymentColumnService } from '../../../../../service/td-data-table/implementation/company-detail.service';
import { UtilOrderService } from '../../../../../service/util/util-order.service';
import { StateFeature } from '../../../../../state';
import { ROUTE_URL } from '../../../../../router/route-naming';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';
import {
	OrganizationStateAction,
	OrganizationActionEnum
} from '../../../../../state/organization/organization.actions';
import _ from 'lodash';
import { OrganizationBackoffice } from '../../../../../constants/organization.constants';
import {
	AddressPouchModel,
	AddressTypeEnum,
	OrganizationLevelEnum,
	OrganizationPouchModel,
	OrganizationTypeEnum
} from '@saep-ict/pouch_agent_models';
import { CurrencyPipe } from '@angular/common';
import { DEFAULT_DESTINATION_CODE } from '../../../../../constants/destination.constants';

@Component({
	selector: 'organization-detail-full-detail',
	templateUrl: './organization-detail-full-detail.component.html',
	styleUrls: ['./organization-detail-full-detail.component.scss']
})
export class OrganizationDetailFullDetailComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organizationSub: Subscription;
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	paymentList: PaymentPouchModel[];
	get paymentColumns(): ITdDataTableColumn[] {
		return this.paymentColumnService.columns;
	}

	defaultAddress: AddressPouchModel;

	otherInformationList: { label: string; value: string | number }[] = [];

	// form
	form: FormGroup;
	isEditingOrganization: boolean;
	canEditOrganization: boolean = false;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	organizationTypeEnum = OrganizationTypeEnum;
	addressTypeEnum = AddressTypeEnum;
	currentContext: ContextApplicationItemCodeEnum;

	constructor(
		private store: Store<any>,
		private paymentColumnService: CompanyDetailFullDetailPaymentColumnService,
		private utilOrderService: UtilOrderService,
		private fb: FormBuilder,
		private currencyPipe: CurrencyPipe
	) {
		// User
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = this.user.current_permission.context_application;
		});

		// Organization
		this.organizationSub = this.organization$
			.pipe(
				filter((customerState: BaseStateModel<OrganizationPouchModel>) => !!customerState),
				map((customerState: BaseStateModel<OrganizationPouchModel>) => {
					switch (customerState.type) {
						case OrganizationActionEnum.UPDATE:
							this.organization = customerState.data;
							// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
							// this.defaultAddress = this.organization.destination_list ? this.organization.destination_list.find(destination => destination.address_type === AddressTypeEnum.REGISTERED_OFFICE).address : null;
							this.defaultAddress = this.organization.destination_list?.length
								? this.organization.destination_list.find(
										destination => destination.code === DEFAULT_DESTINATION_CODE
								  ).address
								: null;
							break;

						default:
							break;
					}
				})
			)
			.subscribe();

		// Aux Table
		this.auxiliaryTable$.pipe(take(1)).subscribe(res => {
			this.auxiliaryTable = res ? res.data : null;
		});

		this.contextApplication();
	}

	ngOnInit() {}

	ngOnDestroy() {
		if (this.organizationSub) {
			this.organizationSub.unsubscribe();
		}
	}

	contextApplication() {
		if (this.user) {
			switch (this.currentContext) {
				case ContextApplicationItemCodeEnum.AGENT:
					this.getPayments();
					break;
				case ContextApplicationItemCodeEnum.B2B:
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					this.createForm();
					this.canEditOrganization = this.checkCanEditOrganization();
					break;
				case ContextApplicationItemCodeEnum.CRM:
					this.createOtherInformationList();
					break;
			}
		}
	}

	/**
	 * AGENT CONTEXT
	 */

	getPayments() {
		let division_list = [];
		if (this.organization.division_list) {
			division_list = this.organization.division_list;
		}
		const paymentDivision = this.utilOrderService.filterPaymentDivisionList(
			division_list,
			this.auxiliaryTable.paymentList
		);
		const paymentDefault = this.utilOrderService.filterPaymentDivisionList(
			this.utilOrderService.agreedPaymentDefault,
			this.auxiliaryTable.paymentList
		);
		this.paymentList = paymentDivision.concat(paymentDefault);
	}

	/** END AGENT CONTEXT */

	/**
	 * BACKOFFICE CONTEXT
	 */

	checkCanEditOrganization() {
		if (
			OrganizationBackoffice.filter(el => el.tab === ROUTE_URL.details).map(el => el.can_edit)[0] &&
			this.organization.level !== OrganizationLevelEnum.LEAD
		) {
			return true;
		}
		return false;
	}

	createForm() {
		const organizationType = this.organization.organization_type
			? this.organization.organization_type.toUpperCase()
			: OrganizationTypeEnum.COMPANY; // manca organization_type
		switch (organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.form = this.fb.group({
					// Company
					businessName: ['', { validators: [Validators.required] }],
					vatNumber: ['', { validators: [Validators.required, Validators.minLength(11)] }],
					// Common
					email: ['', { validators: [Validators.required, Validators.email] }],
					phone: ['', { validators: [Validators.required] }]
				});
				break;
			case OrganizationTypeEnum.PRIVATE:
				this.form = this.fb.group({
					// Private
					firstName: ['', { validators: [Validators.required] }],
					lastName: ['', { validators: [Validators.required] }],
					taxCode: ['', { validators: [Validators.required, Validators.minLength(16)] }],
					// Common
					email: ['', { validators: [Validators.required, Validators.email] }],
					phone: ['', { validators: [Validators.required] }]
				});
				break;
		}
		// Update form from organization
		this.updateFormFieldValue();
		// Disable form
		this.form.disable();
		this.isEditingOrganization = false;
	}

	updateFormFieldValue() {
		const organizationType = this.organization.organization_type
			? this.organization.organization_type.toUpperCase()
			: OrganizationTypeEnum.COMPANY; // se manca organization_type
		const mainContact = this.organization.contact_list
			? this.organization.contact_list.find(contact => contact.main_contact)
			: null;
		switch (organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.form.patchValue({
					// Company
					vatNumber:
						this.organization.tax_data && this.organization.tax_data.vat_number
							? this.organization.tax_data.vat_number
							: null,
					// Common
					businessName: this.organization.business_name ? this.organization.business_name : null,
					email: mainContact && mainContact.email ? mainContact.email : null,
					phone: mainContact && mainContact.phone ? mainContact.phone : null
				});
				break;
			case OrganizationTypeEnum.PRIVATE:
				this.form.patchValue({
					// Private
					firstName: this.organization.first_name ? this.organization.first_name : null,
					lastName: this.organization.last_name ? this.organization.last_name : null,
					taxCode:
						this.organization.tax_data && this.organization.tax_data.tax_code
							? this.organization.tax_data.tax_code
							: null,
					// Common
					businessName: this.organization.business_name ? this.organization.business_name : null,
					email: mainContact && mainContact.email ? mainContact.email : null,
					phone: mainContact && mainContact.phone ? mainContact.phone : null
				});
				break;
		}
	}

	getVisibilityField(fieldName: string) {
		return this.form.value.hasOwnProperty(fieldName);
	}

	editOrganization() {
		if (this.isEditingOrganization) {
			this.onFormSubmit();
		} else {
			// Enable form
			this.form.enable();
			this.isEditingOrganization = true;
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			let updatedOrganization = _.cloneDeep(this.organization);
			// Company
			updatedOrganization.tax_data.vat_number = this.form.value.vatNumber;
			// Private
			updatedOrganization.first_name = this.form.value.firstName;
			updatedOrganization.last_name = this.form.value.lastName;
			updatedOrganization.tax_data.tax_code = this.form.value.taxCode;
			// Common
			updatedOrganization.business_name = this.form.value.businessName
				? this.form.value.businessName // Company
				: updatedOrganization.first_name + ' ' + updatedOrganization.last_name; // Private
			updatedOrganization.contact_list.find(contact => contact.main_contact).email = this.form.value.email;
			updatedOrganization.contact_list.find(contact => contact.main_contact).phone = this.form.value.phone;

			// Save organization
			this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));

			// Disable form
			this.form.disable();
			this.isEditingOrganization = false;
		}
	}

	/** END BACKOFFICE CONTEXT */

	/*** CRM CONTEXT */
	createOtherInformationList() {
		if (this.organization) {
			// revenue_year
			if (this.organization.crm && this.organization.crm.revenue_year) {
				const revenue_year = {
					label: 'Fatturato annuo',
					value: this.currencyPipe.transform(this.organization.crm.revenue_year, 'EUR', 'symbol', '', 'it')
				};
				this.otherInformationList.push(revenue_year);
			}

			// employees_number
			if (this.organization.crm && this.organization.crm.employees_number) {
				const employees_number = {
					label: 'Numero dipendenti',
					value: this.organization.crm.employees_number
				};
				this.otherInformationList.push(employees_number);
			}

			// organization_international_rating
			if (this.organization.crm && this.organization.crm.organization_international_rating) {
				const organization_international_rating = {
					label: 'Voto affidabilità (internazionale)',
					value: this.organization.crm.organization_international_rating
				};
				this.otherInformationList.push(organization_international_rating);
			}

			// organization_rating
			if (this.organization.crm && this.organization.crm.organization_rating) {
				const organization_rating = {
					label: 'Voto affidabilità',
					value: this.organization.crm.organization_rating + ' %'
				};
				this.otherInformationList.push(organization_rating);
			}

			// organization_sector
			if (this.organization.crm && this.organization.crm.organization_sector) {
				const organization_sector = {
					label: 'Settore',
					value: this.organization.crm.organization_sector
				};
				this.otherInformationList.push(organization_sector);
			}
		}
	}
	/*** CRM CONTEXT */
}
