import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { ROUTE_URL } from '../../../../router/route-naming';
import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { HighlightsViewModel } from '../../../../model/home-highlights.model';
import { StateFeature } from '../../../../state';
import { HomeHighlightsActionEnum } from '../../../../state/home-highlights/home-highlights.actions';
import { ICardSliderConfiguration, ItemType } from '../../../../widget/card-product/card-slider.models';
import {
	SAEP_ICT_ADDRESS,
	SAEP_ICT_EMAIL,
	SAEP_ICT_NAME_LONG
} from '../../../../constants/saep-ict-references.constants';
import { JumbotronConfig } from '../../../../widget/jumbotron/jumbotron.component';
import { CallToActionConfig } from '../../../../widget/call-to-action/call-to-action.component';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [SubscribeManagerService]
})
export class HomeComponent implements OnInit {
	@ViewChild('mapContainer') gmap: ElementRef;

	public viewTypeEnum = ViewTypeEnum;
	public ItemType = ItemType;

	jumbotronValue: JumbotronConfig = {
		title: `Promozione esclusiva per il periodo natalizio`, // da configurare manualmente
		titleClass: 'txt-white',
		labelButton: 'ACQUISTA ORA', // da configurare manualmente
		url: `${ROUTE_URL.catalog}/category_ORD_80`, // da RECUPERARE da couch tramite configurazione in evidenza
		backgroundImage: '/assets/img/mockup/jumbotron-01.jpg', // da configurare manualmente
		hasBackdrop: true
	};

	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		textClass: 'txt-white',
		btnLabel: 'contact.contact_us',
		theme: 'accent',
		backgroundImage: "'/assets/img/mockup/call-to-action.jpg'",
		hasBackdrop: true
	};

	config: ICardSliderConfiguration;

	homeHighlights$: Observable<BaseStateModel<HighlightsViewModel>> = this.store.select(
		StateFeature.getHomeHighlightsState
	);
	homeHighlights: BaseStateModel<HighlightsViewModel>;

	organizationAddress = SAEP_ICT_ADDRESS;
	organizationName = SAEP_ICT_NAME_LONG;

	map: google.maps.Map;

	coordinates = new google.maps.LatLng(45.444764, 9.176487);

	mapOptions: google.maps.MapOptions = {
		center: this.coordinates,
		zoom: 8,
		disableDefaultUI: true,
		// scrollwheel: false,
		// disableDoubleClickZoom: true,
		// draggable: false,
		gestureHandling: 'cooperative'
	};

	marker = new google.maps.Marker({
		position: this.coordinates
	});

	constructor(private store: Store<any>, private subscribeManagerService: SubscribeManagerService) {
		this.retrieveHighlights();
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.mapInitializer();
	}

	mapInitializer() {
		this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
		this.marker.setMap(this.map);
	}

	async retrieveHighlights() {
		this.subscribeManagerService.populate(this.retrieveHomeHighlights().subscribe(), 'home-highlights');
	}

	retrieveHomeHighlights(): Observable<BaseStateModel<HighlightsViewModel>> {
		return this.homeHighlights$.pipe(
			skipWhile((homeHighlights: BaseStateModel<HighlightsViewModel>) => {
				return !(homeHighlights && homeHighlights.type !== HomeHighlightsActionEnum.LOAD);
			}),
			tap((homeHighlights: BaseStateModel<HighlightsViewModel>) => {
				if (homeHighlights.type === HomeHighlightsActionEnum.ERROR) {
					throw new Error(HomeHighlightsActionEnum.ERROR);
				}
				this.homeHighlights = homeHighlights;
				this.config = {
					data: this.homeHighlights.data.categories,
					medias: {
						md: 2,
						lg: 3
					},
					animation: {
						animationDuration: '.8s',
						easing: 'ease-in-out',
						loop: true
					}
				};
				return homeHighlights;
			})
		);
	}

	getArray(n: number, startFrom: number = 0): number[] {
		const res = [...Array(n).keys()].map(i => i + startFrom);
		return res;
	}

	clickCallToAction() {
		window.location.href = `mailto:${SAEP_ICT_EMAIL}`;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
