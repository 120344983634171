import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ArticlePouchModel, ListDataPouchModel } from '@saep-ict/pouch_agent_models';

import { RestBasePk } from '@saep-ict/angular-core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../filter/article-list-filter.model';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';
import { ArticleRecap } from './../../../../model/state/article-list-state.model';

export class ArticlePouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	// TBD
	// getAll(): Promise<ArticlePouchModel[]>  {
	// 	return new Promise((resolve, reject) => {
	// 		this.get(`article_${data.id}`)
	// 			.then((doc: ArticlePouchModel) => {
	// 				resolve(doc);
	// 			})
	// 			.catch(err => {
	// 				console.log(err);
	// 				reject(err);
	// 			});
	// 	});
	// }

	getArticleRecap(customerCode: string): Promise<ArticleRecap> {
		return new Promise((resolve, reject) => {
			this.get(`article_recap_${customerCode}`)
				.then((doc: ArticleRecap) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getArticleRecapDescription(): Promise<ArticleRecap> {
		return new Promise((resolve, reject) => {
			this.get(`article_recap_description`)
				.then((doc: ArticleRecap) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getArticleDetail(data: RestBasePk): Promise<ArticlePouchModel> {
		return new Promise((resolve, reject) => {
			this.get(`article_${data.id}`)
				.then((doc: ArticlePouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getArticleList(filter: BaseStateModel<any, ArticleListFilterModel>): Promise<ArticlePouchModel[]> {
		const exists = {
			$exists: true
		};
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'article'
					},
					{
						valid: true
					}
				]
			};
			if (filter.data) {
				if (filter.data.article.description) {
					this.selector.$and.push({
						$or: [
							{
								description: {
									$regex: `(?i)${filter.data.article.description.trim()}`
								}
							},
							{
								code: {
									$regex: `(?i)${filter.data.article.description.trim()}`
								}
							}
						]
					});
				} else {
					this.selector.$and.push({ description: exists });
					this.selector.$and.push({ code: exists });
				}
				if (filter.data.article.um_warehouse) {
					this.selector.$and.push({
						um_warehouse: filter.data.article.um_warehouse
					});
				} else {
					this.selector.$and.push({ um_warehouse: exists });
				}
				if (filter.data.article.exclude) {
					for (const key of Object.keys(filter.data.article.exclude)) {
						const not = { $not: {} };
						not.$not[key] = filter.data.article.exclude[key];
						const notExist = {};
						notExist[key] = { $exists: false };
						const or = [not, notExist];
						this.selector.$and.push({ $or: or });
					}
				}
			}
			this.getWithIndexFilter(
				'article-list',
				this.selector,
				filter.dataSetting.pagination,
				filter.dataSetting.sort ? filter.dataSetting.sort : []
			)
				.then((res: ListDataPouchModel<ArticlePouchModel>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}
}
