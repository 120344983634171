<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="row">
		<div class="col-md-12">
			<h2 class="mb-0" mat-dialog-title>
				{{ ('general.add_new' | translate | sentencecase) + ' ' + data.description }}
			</h2>
		</div>
	</div>
	<div class="row">
		<div class="col">
			<mat-form-field
				*ngFor="
					let field of configurationCustomerContextCodeCreationFormFieldMap[
						contextApplicationItemCodeEnum[data.code]
					]
				"
			>
				<mat-label> {{ 'context_code.field.' + field.name | translate | titlecase }}</mat-label>
				<input matInput [formControlName]="field.name" [type]="field.type" />
			</mat-form-field>
		</div>
	</div>
	<mat-dialog-actions>
		<mat-toolbar class="height-auto">
			<button mat-stroked-button mat-dialog-close type="button">
				{{ 'general.cancel' | translate | sentencecase }}
			</button>
			<button [disabled]="!form.valid" type="submit" mat-raised-button color="primary">
				{{ 'general.save' | translate | sentencecase }}
			</button>
		</mat-toolbar>
	</mat-dialog-actions>
</form>
