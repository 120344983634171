import { ITdDataTableColumn } from '@covalent/core/data-table';
import { BaseTableListService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

/**
 * Componenti in cui viene utilizzato:
 * + user-management.component.ts
 * + dialog-select-assegnee.component.ts
 */
 @Injectable()
export class UserManagementListColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}

	_columns = [
		{
			name: 'id',
			label: this.translate.instant('user.field.id'),
			width: 50,
			sortable: true
		},
		{
			name: 'avatar',
			label: '',
			width: 80
		},
		{
			name: 'username',
			label: this.translate.instant('user.field.username'),
			sortable: true
		},
		{
			name: 'full_name',
			label: this.translate.instant('user.field.full_name')
		},
		{
			name: 'context_application_list',
			label: this.translate.instant('user.context')
		},
		{
			name: 'is_active',
			label: '',
			numeric: true,
			width: 50
		}
	];
}
