import { ArticleListFilterModel } from './../../service/pouch-db/filter/article-list-filter.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
	AllDocsDataPouchModel,
	BaseAllDocsPouchModel,
	DestinationPouchModel,
	ProgressOrderStatusEnum
} from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import {
	HeaderStateModelWithDestination,
	OrderStateModel,
	ProductVariationStateModel
} from '../../model/state/order-state.model';
import { DestinationFilterModel } from '../../service/pouch-db/filter/destination-filter.model';
import { OrderListFilterModel } from '../../service/pouch-db/filter/order-filter.model';

import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OrderListActionEnum, OrderListStateAction } from './order-list.actions';
import { OrderEffects } from '../order/order.effects';
import { UtilOrderService } from '../../service/util/util-order.service';
import { ProductVariationTypeEnum } from '../../enum/product-variation.enum';
import { LAST_CHARACTER } from '../../constants/pouchdb.constants';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import _ from 'lodash';

@Injectable()
export class OrderListEffects {
	// load$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(OrderListActionEnum.LOAD),
	// 		mergeMap((action: BaseStateModel<OrderStateModel[]>) => from(this.getOrderList(action))),
	// 		map((orders: BaseStateModel<OrderStateModel[]>) => OrderListStateAction.update(orders)),
	// 		catchError((error, caught) => {
	// 			this.store.dispatch(OrderListStateAction.error(null));
	// 			return caught;
	// 		})
	// 	)
	// );

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrderListActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<OrderStateModel[]>) => from(this.getAllOrders(action))),
			map((orders: BaseStateModel<OrderStateModel[]>) => OrderListStateAction.update(orders)),
			catchError((error, caught) => {
				this.store.dispatch(OrderListStateAction.error(null));
				return caught;
			})
		)
	);

	// loadWithDestination$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(OrderListActionEnum.LOAD_WITH_DESTINATION),
	// 		mergeMap((action: BaseStateModel<OrderStateModel[]>) => from(this.getOrderListWithDestination(action))),
	// 		map((orders: BaseStateModel<OrderStateModel[]>) => OrderListStateAction.update(orders)),
	// 		catchError((error, caught) => {
	// 			this.store.dispatch(OrderListStateAction.error(null));
	// 			return caught;
	// 		})
	// 	)
	// );

	// loadWithDetail$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(OrderListActionEnum.LOAD_WITH_DETAIL),
	// 		mergeMap((action: BaseStateModel<OrderStateModel[], OrderListFilterModel>) =>
	// 			from(this.getOrderListWithDetail(action))
	// 		),
	// 		mergeMap((action: BaseStateModel<OrderStateModel[], OrderListFilterModel>) =>
	// 			from(this.mergeOrderVariation(action))
	// 		),
	// 		map((orders: BaseStateModel<OrderStateModel[]>) => OrderListStateAction.update(orders)),
	// 		catchError((error, caught) => {
	// 			this.store.dispatch(OrderListStateAction.error(null));
	// 			return caught;
	// 		})
	// 	)
	// );

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private orderEffects: OrderEffects,
		private utilOrderService: UtilOrderService,
		private utilPouchService: UtilPouchService,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	// async getOrderList(
	// 	filter: BaseStateModel<OrderStateModel[], OrderListFilterModel>
	// ): Promise<BaseStateModel<OrderStateModel[], OrderListFilterModel>> {
	// 	return this.pouchAdapterSelectorService.retrieveCurrentAdapter().orderPouch
	// 		.getOrderList(filter)
	// 		.then(async orderList => {
	// 			orderList.forEach(order => (order = this.utilOrderService.addOrderId(order)));
	// 			return new BaseState(orderList, {
	// 				pagination: filter.dataSetting.pagination,
	// 				sort: filter.dataSetting.sort
	// 			});
	// 		})
	// 		.catch((err: PouchErrorResponse) => {
	// 			throw new Error(err.error + err.reason);
	// 		});
	// }

	async getAllOrders(action: BaseStateModel<OrderStateModel[]>): Promise<BaseStateModel<OrderStateModel[]>> {
		const documentName = 'order';
		const allDocsParam: any = {
			include_docs: true,
			startkey: documentName + '_',
			endkey: documentName + '_' + LAST_CHARACTER
			// endkey: documentName + '_\\' + LAST_CHARACTER     // rimosso perché lascia fuori gli id che iniziano con lettere invece che con numeri
		};

		return this.utilPouchService
			.allDocs<OrderStateModel>(allDocsParam, documentName as CouchDocumentType)
			.then(async (res: BaseStateModel<OrderStateModel[]>) => {
				const allOrders: OrderStateModel[] = res.data;
				allOrders.forEach(order => (order = this.utilOrderService.addOrderId(order)));

				const allOrderProgress = await (
					await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORDER)
				).orderPouch
					.getOrderProgessList()
					.then(res => {
						return res.rows.map(x => x.doc);
					});

				// map destination
				allOrders.forEach(async order => {
					const headerWithDestination: HeaderStateModelWithDestination = <HeaderStateModelWithDestination>(
						order.header
					);
					if (
						order.header &&
						order.header.goods_destination_new &&
						order.header.goods_destination_new.address
					) {
						headerWithDestination.destination = <DestinationPouchModel>{
							address: order.header.goods_destination_new.address
						};
					} else if (order.header && order.header.goods_destination_new) {
						headerWithDestination.destination = <DestinationPouchModel>{
							address: order.header.goods_destination_object
						};
					}
					order.header = headerWithDestination;

					// Get order progress
					this.orderEffects.addOrderProgress(order, allOrderProgress);
				});

				// Sort list
				const sortedOrders = _.orderBy(
					allOrders,
					['csuite.order_so_year', 'csuite.order_so_number'],
					['desc', 'desc']
				);

				return new BaseState(sortedOrders);
			})
			.catch(err => {
				throw new Error(err.error + err.reason);
			});
	}

	// async getOrderListWithDestination(
	// 	filter: BaseStateModel<OrderStateModel[], OrderListFilterModel>
	// ): Promise<BaseStateModel<OrderStateModel[], OrderListFilterModel>> {
	// 	return this.getOrderList(filter).then(async (orderList: BaseStateModel<OrderStateModel[]>) => {
	// 		const promiseList: Promise<OrderStateModel>[] = [];
	// 		orderList.data.forEach((order: OrderStateModel) => {
	// 			const headerWithDestination: HeaderStateModelWithDestination = <HeaderStateModelWithDestination>(
	// 				order.header
	// 			);
	// 			const destinationFilter: DestinationFilterModel = {
	// 				id: headerWithDestination.goods_destination_code,
	// 				code_customer: headerWithDestination.client_code
	// 			};
	// 			if (order.header.goods_destination_new && order.header.goods_destination_new.address) {
	// 				headerWithDestination.destination = <DestinationPouchModel>{
	// 					address: order.header.goods_destination_new.address
	// 				};
	// 				order.header = headerWithDestination;
	// 				promiseList.push(
	// 					new Promise(resolve => {
	// 						resolve(order);
	// 					})
	// 				);
	// 			} else {
	// 				promiseList.push(
	// 					this.pouchDbSpin8AgentAdapter.destinationPouch
	// 						.getDestination(destinationFilter)
	// 						.then((destination: DestinationPouchModel) => {
	// 							headerWithDestination.destination = destination;
	// 							order.header = headerWithDestination;
	// 							return order;
	// 						})
	// 				);
	// 			}
	// 		});
	// 		return Promise.all(promiseList)
	// 			.then((orders: OrderStateModel[]) => {
	// 				return new BaseState(orders, filter.dataSetting);
	// 			})
	// 			.catch((err: PouchErrorResponse) => {
	// 				throw new Error(err.error + err.reason);
	// 			});
	// 	});
	// }

	// async getOrderListWithDetail(
	// 	filter: BaseStateModel<OrderStateModel[], OrderListFilterModel>
	// ): Promise<BaseStateModel<OrderStateModel[], OrderListFilterModel>> {
	// 	try {
	// 		const orderList = await this.getOrderListWithDestination(filter);

	// 		for (let order of orderList.data) {
	// 			const data: ArticleListFilterModel = { order: order, article: { excludePrice: true } };
	// 			const orderDetail = await this.orderEffects.mergeArticleWithProduct(data);
	// 			if (orderDetail) {
	// 				order = orderDetail;
	// 			}

	// 			// Progress order
	// 			if (Object.values(ProgressOrderStatusEnum).includes(order.header.status as any)) {
	// 				const orderFilter: OrderListFilterModel = { _id: order._id };
	// 				let orderProgress = await this.orderEffects
	// 					.getOrderProgress(orderFilter)
	// 					.then(res => (orderProgress = res))
	// 					.catch(err => console.error(err));
	// 				if (orderProgress) {
	// 					order.order_progress = orderProgress;
	// 				}
	// 				// order progress - values changed
	// 				if (orderProgress && orderProgress.values) {
	// 					const lastIndex = orderProgress.values.length - 1;
	// 					const valuesChanged = orderProgress.values[lastIndex].values_changed;
	// 					if (valuesChanged) {
	// 						if (!order.header.order_progress_detail) {
	// 							order.header.order_progress_detail = {};
	// 						}

	// 						// first evasion date
	// 						if (valuesChanged[`root['header']['first_evasion_date']`]) {
	// 							const firstEvasionDate: any =
	// 								valuesChanged[`root['header']['first_evasion_date']`].old_value;
	// 							order.header.order_progress_detail.first_evasion_date = firstEvasionDate;
	// 						}

	// 						// goods destination
	// 						if (valuesChanged[`root['header']['goods_destination_code']`]) {
	// 							const goodsDestinationCodeOldValue: any =
	// 								valuesChanged[`root['header']['goods_destination_code']`].old_value;
	// 							const destinationFilter: DestinationFilterModel = {
	// 								id: goodsDestinationCodeOldValue,
	// 								code_customer: order.header.organization.code_item
	// 							};
	// 							this.pouchDbSpin8AgentAdapter.destinationPouch
	// 								.getDestination(destinationFilter)
	// 								.then((destination: DestinationPouchModel) => {
	// 									order.header.order_progress_detail.goods_destination = destination;
	// 									return order;
	// 								});
	// 						}

	// 						// order causal code
	// 						if (valuesChanged[`root['header']['order_causal']`]) {
	// 							const orderCausalOld: any = valuesChanged[`root['header']['order_causal']`].old_value;
	// 							order.header.order_progress_detail.order_causal = orderCausalOld;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}
	// 		return orderList;
	// 	} catch (err) {
	// 		throw new Error(err.error + err.reason);
	// 	}
	// }

	async mergeOrderVariation(
		filter: BaseStateModel<OrderStateModel[], OrderListFilterModel>
	): Promise<BaseStateModel<OrderStateModel[], OrderListFilterModel>> {
		try {
			const orderList = filter;
			for (let order of orderList.data) {
				if (Object.values(ProgressOrderStatusEnum).includes(order.header.status as any)) {
					// order progress
					const orderProgress = order.order_progress;
					if (orderProgress && orderProgress.values) {
						if (!order.order_variation_list) {
							order.order_variation_list = [];
						}
						// Ordina gli oggetti per data DESC e prende il primo
						orderProgress.values.sort((a, b) => (a.date < b.date ? 1 : -1));
						const lastOrderProgress = orderProgress.values[0];

						// values changed
						const valuesChanged = lastOrderProgress.values_changed;
						if (valuesChanged) {
							// Product list della first version di un ordine
							const orderProgressProductList = orderProgress['first_version']['product_list'];
							// Contiene gli indici dei prodotti REPLACED
							const indexListReplacedProducts = [];

							// Product REPLACED / PROPERTY_CHANGED
							for (const valueObj in valuesChanged) {
								// Skip se la proprietà proviene da prototype
								if (!valuesChanged.hasOwnProperty(valueObj)) {
									continue;
								}
								const productListLength = orderProgressProductList.length;

								if (valueObj.startsWith(`root['product_list']`)) {
									for (let i = 0; i < productListLength; i++) {
										if (
											valueObj.startsWith(`root['product_list'][${i}]`) &&
											!indexListReplacedProducts.includes(i)
										) {
											// Estrae il nome della proprietà cambiata
											let propertyChanged = valueObj.replace(`root['product_list'][${i}]`, '');
											if (propertyChanged !== '') {
												propertyChanged = propertyChanged.slice(2, -2);
											}

											// Codice prodotto che ha subito la variazione
											const code = orderProgressProductList[i].code;

											const obj: Object = valuesChanged[valueObj];
											let productCode: string;
											let productVariationType: any;

											if (propertyChanged === 'code') {
												// Product REPLACED
												productCode = obj['old_value'];
												productVariationType = ProductVariationTypeEnum.REPLACED;
												indexListReplacedProducts.push(i);
											} else {
												// Product PROPERTY_CHANGED
												productCode = code;
												productVariationType = ProductVariationTypeEnum.PROPERTY_CHANGED;
											}
											const itemChanged: ProductVariationStateModel = {
												productCode: productCode,
												type: productVariationType,
												propertyName: propertyChanged,
												oldValue: obj['old_value'],
												newValue: obj['new_value']
											};
											order.order_variation_list.push(itemChanged);
										}
									}
								}
							}
						}

						// Product ADDED
						const iterableItemAdded = lastOrderProgress.iterable_item_added;
						if (iterableItemAdded) {
							for (const key in iterableItemAdded) {
								// Skip se la proprietà proviene da prototype
								if (!iterableItemAdded.hasOwnProperty(key)) {
									continue;
								}
								const obj: Object = iterableItemAdded[key];
								const itemAdded: ProductVariationStateModel = {
									productCode: obj['code'],
									type: ProductVariationTypeEnum.ADDED
								};
								order.order_variation_list.push(itemAdded);
							}
						}

						// Product REMOVED
						const iterableItemRemoved = lastOrderProgress.iterable_item_removed;
						if (iterableItemRemoved) {
							for (const key in iterableItemRemoved) {
								// Skip se la proprietà proviene da prototype
								if (!iterableItemRemoved.hasOwnProperty(key)) {
									continue;
								}
								const obj: Object = iterableItemRemoved[key];
								const itemRemoved: ProductVariationStateModel = {
									productCode: obj['code'],
									type: ProductVariationTypeEnum.REMOVED
								};
								order.order_variation_list.push(itemRemoved);
							}
						}
					}
				}
			}
			return orderList;
		} catch (err) {
			throw new Error(err.error + err.reason);
		}
	}
}
