<div class="kanban-card__indicator">
	<div
		class="indicator indicator--warn"
		[class.indicator--warn]="daysFromCloseDate <= 1"
		[class.indicator--alert]="daysFromCloseDate > 1 && daysFromCloseDate < 7"
		[class.indicator--success]="daysFromCloseDate > 7"
	></div>
</div>
<div class="kanban-card__container">
	<div class="kanban-card__title">
		<div class="badge--rating">{{ ratingAvg }}</div>
		<p>{{ opportunity.title }}</p>
	</div>
	<div class="kanban-card__main-data">
		<span class="icon-label">
			<mat-icon>access_alarm</mat-icon>
			<span
				[matTooltip]="
					'Data di chiusura stimata' +
					(daysFromCloseDate > 1 ? ': mancano ' + daysFromCloseDate + ' giorni' : '') +
					(daysFromCloseDate === 1 ? ': scade oggi' : '') +
					(daysFromCloseDate < 1 ? ': scaduta' : '')
				"
			>
				{{ opportunity.estimated_close_date | date: 'dd/MM/yyyy' }}
			</span>
		</span>

		<span class="icon-label">
			<mat-icon>account_balance_wallet</mat-icon>
			<mat-icon [matTooltip]="'Offerta allegata'" *ngIf="opportunity.has_offer">description</mat-icon>
			<span [matTooltip]="'Valore opportunità'">
				{{ opportunity.actual_value | currency: 'EUR':'symbol':'':'it' }}
			</span>
		</span>
	</div>

	<div class="kanban-card__secondary-data">
		<div class="opacity-50">
			<div class="kanban-card__organization">
				<span class="icon-label">
					<mat-icon>business</mat-icon>
					<span>
						{{ (opportunity.organization.crm.organization_rating ? opportunity.organization.crm.organization_rating + ' | ' : '') + opportunity.organization.business_name }}
					</span>
				</span>
			</div>
			<div class="kanban-card__contact">
				<span class="icon-label">
					<mat-icon>person</mat-icon>
					<span>{{ opportunity.contact_name ? opportunity.contact_name : '-'}}</span>
				</span>
			</div>
		</div>
		<avatar
			class="kanban-card__owner"
			[matTooltip]="opportunity.assegnee_full_name || opportunity.assegnee_user_name"
			[avatarConfigClass]="avatarConfigClass"
		></avatar>
	</div>
</div>
