import { Injectable } from '@angular/core';

// model
import { OrganizationPouchModel, BasePouchModel } from '@saep-ict/pouch_agent_models';

// store
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationListActionEnum, OrganizationListStateAction } from './organization-list.actions';

// widgte & utility
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { UtilPouchService } from '../../../service/util/util-pouch.service';
import { StoreUtilService } from '../../../service/util/store-util.service';
import { ContextCodeFilterModel } from '../../../service/pouch-db/filter/context-code-filter.model';
import { BaseStateModel, BaseState, UserDetailModel, ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { BodyTablePouchCustomModel } from '../../../service/pouch-db/model/pouch-base.model';
import { LAST_CHARACTER } from '../../../constants/pouchdb.constants';
import { StateFeature } from '../..';
import { CouchDocumentType } from '../../../constants/context-state.map';

@Injectable()
export class OrganizationListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.LOAD),
			mergeMap(() => from(this.utilPouchService.getView<OrganizationPouchModel>('company/get-all-company'))),
			map((organization: BaseStateModel<OrganizationPouchModel[], ContextCodeFilterModel>) =>
				OrganizationListStateAction.update(organization)
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<OrganizationPouchModel[]>) => from(this.getAllOrganization(action))),
			map((organizations: BaseStateModel<OrganizationPouchModel[]>) =>
				OrganizationListStateAction.update(organizations)
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	loadItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.LOAD_ITEM),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInListState<OrganizationPouchModel>(action))
			),
			map((action: BaseStateModel<OrganizationPouchModel[]>) => OrganizationListStateAction.updateItem(action)),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	create$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.CREATE_ITEM),
			mergeMap((action: BaseStateModel<OrganizationPouchModel>) =>
				from(this.storeUtilService.saveCouchDetailAndReturnInDetailState<OrganizationPouchModel>(action))
			),
			mergeMap((action: BaseStateModel<OrganizationPouchModel>) => from(this.utilPouchService.saveCode(action))),
			map((action: BaseStateModel<OrganizationPouchModel>) =>
				OrganizationListStateAction.createSuccess(new BaseState([action.data]))
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	deleteItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationListActionEnum.DELETE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.utilPouchService.deleteAnyDocument(action.data))
			),
			map((action: BodyTablePouchCustomModel) =>
				OrganizationListStateAction.deleteItemSuccess(new BaseState(null, null))
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationListStateAction.error(null));
				return caught;
			})
		)
	);

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private storeUtilService: StoreUtilService,
		private utilPouchService: UtilPouchService
	) {
		this.user$.pipe().subscribe(user => {
			this.user = user ? user.data : null;
		});
	}

	async getAllOrganization(
		action: BaseStateModel<OrganizationPouchModel[]>
	): Promise<BaseStateModel<OrganizationPouchModel[]>> {
		const documentName = 'organization';
		const allDocsParam: any = {
			include_docs: true,
			startkey: documentName + '_',
			endkey: documentName + '_' + LAST_CHARACTER
		};

		await this.utilPouchService
			.allDocs<OrganizationPouchModel>(allDocsParam, documentName as CouchDocumentType)
			.then((result: any) => {
				action.data = result.data;
			})
			.catch(err => {
				console.log(err);
			});

		return action;
	}
}
