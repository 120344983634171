import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	DialogConfirmComponent,
	UserDetailModel
} from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { OrganizationStateAction, OrganizationActionEnum } from '../../../../state/organization/organization.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { OrganizationHeaderConfig } from '../../../../widget/organization-header/organization-header.component';
import { UtilAddressService } from '../../../../service/util/util-address.service';
import { NavigationFloatingMenuService } from '../../../../service/navigation/navigation-floating-menu.service';
import { OrganizationStateModel } from '../../../../model/state/organization-state.model';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { DialogOrganizationDetailComponent } from '../../../../widget/dialog/dialog-organization-detail/dialog-organization-detail.component';
import { OrganizationLevelEnum, OrganizationTypeEnum, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { OrganizationListStateAction } from '../../../../state/common/organization-list/organization-list.actions';
import { AuxiliaryTableStateModel } from '../../../../model/state/auxiliary-table-list';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import * as OrganizationEnum from '../../../../enum/organization.enum';

@Component({
	selector: 'organization-detail',
	templateUrl: './organization-detail.component.html',
	styleUrls: ['./organization-detail.component.scss'],
	providers: [NavigationFloatingMenuService, SubscribeManagerService]
})
export class OrganizationDetailComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: OrganizationStateModel[];

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	entityHeaderConfig: OrganizationHeaderConfig = new OrganizationHeaderConfig();

	currentContext: ContextApplicationItemCodeEnum = null;

	// enum
	organizationLevelEnum = OrganizationLevelEnum;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	toExclude: string[] = [OrganizationEnum.OrganizationTab.CREDITS];

	constructor(
		private route: ActivatedRoute,
		private store: Store<any>,
		private dialog: MatDialog,
		private utilAddressService: UtilAddressService,
		public navigationFloatingMenuService: NavigationFloatingMenuService,
		private subscribeManagerService: SubscribeManagerService,
		private utilOrderService: UtilOrderService
	) {
		this.store.dispatch(OrganizationListStateAction.loadAll());

		const organizationCode: string = this.route.snapshot.paramMap.get('idOrganization');
		// this.store.dispatch(OrganizationStateAction.load(new BaseState({_id: organizationCode})));

		this.subscribeManagerService.populate(
			this.organizationList$
				.pipe(
					filter(res => !!res),
					map(res => {
						// this.organization = res.data.find((
						// 	x //TO FIX
						// ) => (x['code_erp'] ? x['code_erp'] === organizationCode : x.code_item === organizationCode));
						this.organization = res.data.find((
							x //TO FIX
						) => (x.code_item ? x.code_item === organizationCode : x['code_erp'] === organizationCode));
						this.store.dispatch(OrganizationStateAction.update(new BaseState(this.organization)));
					})
				)
				.subscribe(),
			'subscribeOrganizations'
		);

		this.utilOrderService.retrieveSyncState<AuxiliaryTableStateModel>(this.auxiliaryTable$).subscribe(res => {
			this.auxiliaryTable = res.data;
		});

		this.user$.pipe(take(1)).subscribe((res: BaseState<UserDetailModel>) => {
			if (res) {
				this.user = res.data;
				this.currentContext = this.user.current_permission.context_application;
			} else {
				this.user = null;
			}
		});

		this.subscribeManagerService.populate(this.subscribeOrganization().subscribe(), 'subscribeOrganization');
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();

		this.store.dispatch(OrganizationStateAction.reset());
	}

	subscribeOrganization() {
		return this.organization$.pipe(
			filter(
				(organizationState: BaseStateModel<OrganizationStateModel>) =>
					!!(organizationState && organizationState.data)
			),
			map((organizationState: BaseStateModel<OrganizationStateModel>) => {
				switch (organizationState.type) {
					case OrganizationActionEnum.UPDATE:
						this.organization = organizationState.data;
						this.entityHeaderConfig = this.createEntityHeaderConfig(this.organization);
						break;

					default:
						break;
				}
			})
		);
	}

	// Valida organizzazione
	organizationValidation() {
		const title = 'Valida organizzazione';
		const text = "Sei sicuro di voler validare l'organizzazione?";
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// Modifica livello di organizzazione e salva
				const updatedOrganization = _.cloneDeep(this.organization);
				updatedOrganization.level = OrganizationLevelEnum.PROSPECT;
				this.store.dispatch(OrganizationStateAction.update(new BaseState(updatedOrganization)));
				// ??? refresh per caricare modifiche children
			}
		});
	}

	createEntityHeaderConfig(organization: OrganizationStateModel): OrganizationHeaderConfig {
		let organizationHeader: OrganizationHeaderConfig = new OrganizationHeaderConfig();
		organizationHeader.context = this.currentContext;

		// avatar
		organizationHeader.avatar = {
			userName: organization.business_name ? organization.business_name : null,
			size: 96
		};

		// organizationHeader.badgeList = [];
		// Lead, prospect, customer
		// if (organization.level) {
		// 	organizationHeader.badgeList.push(organization.level);
		// }

		// Company, private, etc
		// if (organization.organization_type) {
		// 	organizationHeader.badgeList.push('organization.type.' + organization.organization_type.toUpperCase());
		// }

		// informationList
		organizationHeader.informationList = [];

		// informationList - phone
		let phone = {
			label: 'contact.field.phone',
			value: null,
			icon: 'call',
			order: 1
		};

		// informationList - email
		let email = {
			label: 'contact.field.email',
			value: null,
			icon: 'email',
			order: 2
		};
		if (organization.contact_list) {
			// TODO: riallineare il modello all'utlima versione di libreria
			const mainContact = organization.contact_list.find(contact => contact['is_main_of_list']);
			if (mainContact) {
				// informationList - phone
				phone.value = mainContact.phone ? mainContact.phone : null;
				// informationList - email
				email.value = mainContact.email ? mainContact.email : null;
			}
		}
		organizationHeader.informationList.push(phone);
		organizationHeader.informationList.push(email);

		// informationList - address
		let address = {
			label: 'general.address.address',
			value: null,
			icon: 'location_on',
			order: 6
		};
		if (organization.destination_list?.length) {
			const address_data = organization.destination_list.find(destination => destination['is_registered_office'])
				?.address;
			address.value = address_data ? this.utilAddressService.getFormattedAddress(address_data) : null;
		}
		organizationHeader.informationList.push(address);

		// informationList - codice fiscale
		let taxCode = {
			label: 'organization.field.tax_code',
			value: null,
			icon: 'fingerprint',
			order: 5
		};
		if (organization.tax_data && organization.tax_data.tax_code) {
			taxCode.value = organization.tax_data.tax_code;
		}
		organizationHeader.informationList.push(taxCode);

		// campi dedicati solo alla company
		if (organization.organization_type && organization.organization_type === OrganizationTypeEnum.COMPANY) {
			// informationList - partita iva
			let vatNumber = {
				label: 'organization.field.vat_number',
				value: null,
				icon: 'business',
				order: 4
			};
			// TODO: aggiornare libreria con modello DestinationPouchModel
			const destination: DestinationPouchModel = organization.destination_list.find(
				i => i['is_registered_office']
			);
			vatNumber.value =
				(destination?.address?.country ? destination.address.country : '') +
				(organization?.tax_data?.vat_number ? ' ' + organization.tax_data.vat_number : '');
			organizationHeader.informationList.push(vatNumber);
		}

		// informationList - PAYMENT METHOD INFO
		const paymentMethodInfo = {
			label: 'payment_method.name',
			value: null,
			icon: 'payments',
			order: 7
		};
		if (organization.division_list.length) {
			const paymentMethodCode = organization.division_list[0].payment_condition;

			if (paymentMethodCode && this.auxiliaryTable.paymentList) {
				const paymentMethod = this.auxiliaryTable.paymentList.find(
					payment => payment.code_item === paymentMethodCode
				);

				if (paymentMethod) {
					paymentMethodInfo.value = paymentMethod.description;
				}
			}
		}
		organizationHeader.informationList.push(paymentMethodInfo);

		return organizationHeader;
	}

	editOrganization() {
		const dialog = this.dialog.open(DialogOrganizationDetailComponent, {
			data: {
				title: 'organization.detail'
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialog.afterClosed().subscribe((res: OrganizationStateModel) => {
			// Gestione dei dati nella dialog
			// Se mi torna l'ogetto organization faccio l'update
			if (res) {
				this.store.dispatch(OrganizationStateAction.update(new BaseState(res)));
			}
		});
	}

	onFloatingMenuClicked(event) {
		switch (event) {
			case this.navigationFloatingMenuService.addNote.emitName:
				this.openDialogNote();
				break;

			case this.navigationFloatingMenuService.addContact.emitName:
				this.openDialogContact();
				break;

			case this.navigationFloatingMenuService.addAddress.emitName:
				this.openDialogAddress();
				break;

			case this.navigationFloatingMenuService.addOffer.emitName:
				// TODO - rimandare alla pagina nuova offerta
				// this.router.navigate(['/']);
				break;

			case this.navigationFloatingMenuService.addOpportunity.emitName:
				this.openDialogOpportunity();
				break;

			default:
				break;
		}
	}

	openDialogNote() {
		console.log('openDialogNote');
	}
	openDialogContact() {
		console.log('openDialogContact');
	}
	openDialogAddress() {
		console.log('openDialogAddress');
	}
	openDialogOpportunity() {
		console.log('openDialogOpportunity');
	}
}
