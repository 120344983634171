import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleState } from '../../../model/state/article-list-state.model';
import { ProductKitColumnService } from '../../../service/td-data-table/implementation/product-catalog.service';
import { CurrencyPipe } from '@angular/common';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { ITdDataTableColumnKey } from '@saep-ict/angular-core';

@Component({
	selector: 'dialog-product-kit',
	templateUrl: './dialog-product-kit.component.html',
	styleUrls: ['./dialog-product-kit.component.scss']
})
export class DialogProductKitComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private productKitColumnService: ProductKitColumnService,
		private currencyPipe: CurrencyPipe,
		private utilOrderService: UtilOrderService
	) {}

	productList: ArticleState[];
	columns: ITdDataTableColumnKey[];

	ngOnInit() {
		this.productList = this.data.productList;
		this.columns = this.productKitColumnService.columns;
	}

	retrieveArticlePrice(rowTable: ArticleState) {
		return rowTable.articlePrice.discount.length > 0 || rowTable.productDetail.discount_agent
			? 'scontato: ' +
					this.currencyPipe.transform(
						this.utilOrderService.retrievePriceWithDiscount(
							rowTable.productDetail.price ? rowTable.productDetail.price : rowTable.articlePrice.price,
							rowTable.articlePrice.discount,
							rowTable.productDetail.discount_agent ? rowTable.productDetail.discount_agent : null
						),
						'EUR',
						'symbol',
						'',
						'it'
					)
			: null;
	}

	getCalculatePrice(rowTable: ArticleState){
		const input_quantity = rowTable.productDetail.input_quantity;
		const qty_free = rowTable.productDetail.qty_free;
		const price = rowTable.articlePrice.price;

		return (input_quantity - qty_free) * price;
	}
}
