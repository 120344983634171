import { Component, Input } from '@angular/core';
import { LoaderService } from "@saep-ict/angular-core";

@Component({
  selector: 'ms-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {

  @Input('isLoading') isLoading: boolean = false;
  @Input('type') type: string;
  @Input('id') id: string;

  constructor(private loaderService: LoaderService) {
    this.loaderService.loader$.subscribe((res) => {
      this.isLoading = res;
    });
  }

}
