import { NgModule } from '@angular/core';
import { CovalentPagingModule } from "@covalent/core/paging";
import { CovalentSearchModule } from "@covalent/core/search";
import { CovalentDataTableModule } from "@covalent/core/data-table";

@NgModule({
  exports: [
    CovalentSearchModule,
    CovalentDataTableModule,
    CovalentPagingModule
  ]
})
export class CovalentModule { }
