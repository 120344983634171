<div formGroupName="personalData">
	<div class="row">

		<!-- Nome -->
		<div class="col-lg-6" *ngIf="isPrivate">
			<mat-form-field>
				<mat-label>Nome</mat-label>
				<input matInput formControlName="firstName" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('firstName')"
						minLengthValue="16"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- Cognome -->
		<div class="col-lg-6" *ngIf="isPrivate">
			<mat-form-field>
				<mat-label>Cognome</mat-label>
				<input matInput formControlName="lastName" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('lastName')"
						minLengthValue="16"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- Azienda -->
		<div class="col-lg-12" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>Nome azienda</mat-label>
				<input matInput formControlName="businessName" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('businessName')"
						minLengthValue="16"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- Codice fiscale -->
		<div class="col-lg-12" *ngIf="isPrivate">
			<mat-form-field>
				<mat-label>Codice fiscale</mat-label>
				<input matInput formControlName="taxCode" [maxlength]="minLength.taxCode" appUppercase />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('taxCode')"
						[minLengthValue]="minLength.taxCode"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- Partita IVA -->
		<div class="col-lg-12" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>Partita IVA</mat-label>
				<input matInput formControlName="vatNumber" [maxlength]="minLength.vatNumber" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('vatNumber')"
						[minLengthValue]="minLength.vatNumber"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- Email Aziendale -->
		<div class="col-lg-12" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>{{ 'contact.field.business_email' | translate | sentencecase }}</mat-label>
				<input matInput formControlName="businessEmail" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('businessEmail')"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- Codice SDI -->
		<div class="col-lg-12" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>Codice SDI</mat-label>
				<input matInput formControlName="sdiCode" [maxlength]="minLength.sdi" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('personalData').get('sdiCode')"
						[minLengthValue]="minLength.sdi"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>
	</div>
</div>
