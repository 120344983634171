import { Injectable } from '@angular/core';
import { Category } from '../../model/category-list.model';
import { ArticleCategory } from '../../model/article.model';

@Injectable()
export class MockCategoryListService {
	public categoryList: Category<ArticleCategory>[] = [
		{
			_id: 'category_ORD',
			_rev: '20-8d1c941edd658613183fe0c40e54b15b',
			type: 'category',
			level: 'root',
			code: 'ORD',
			description: 'ORDER FORM',
			sequence: 0,
			valid: true,
			children: [
				'category_ORD_03',
				'category_ORD_80',
				'category_ORD_50',
				'category_ORD_04',
				'category_ORD_V0',
				'category_ORD_06'
			],
			category_list: [
				{
					_id: 'category_ORD_V0',
					_rev: '17-78f29c26eba9c46bfba73121adad4a1b',
					type: 'category',
					level: 'brand',
					code: 'V0',
					description: 'VERSACE',
					sequence: 1,
					valid: true,
					parent: 'category_ORD',
					hierarchy: ['category_ORD'],
					children: [
						'category_ORD_V0_LVG',
						'category_ORD_V0_IVL',
						'category_ORD_V0_GVQ',
						'category_ORD_V0_CVR',
						'category_ORD_V0_AV0',
						'category_ORD_V0_AEF',
						'category_ORD_V0_AEM',
						'category_ORD_V0_BVA',
						'category_ORD_V0_AVP',
						'category_ORD_V0_AEE',
						'category_ORD_V0_AVO',
						'category_ORD_V0_AV',
						'category_ORD_V0_AEB',
						'category_ORD_V0_AE4',
						'category_ORD_V0_AE0',
						'category_ORD_V0_AE1',
						'category_ORD_V0_AEA',
						'category_ORD_V0_AEC',
						'category_ORD_V0_AEI',
						'category_ORD_V0_AED',
						'category_ORD_V0_VSS',
						'category_ORD_V0_AEG',
						'category_ORD_V0_AVN',
						'category_ORD_V0_HVM'
					],
					category_list: [
						{
							_id: 'category_ORD_V0_AEA',
							_rev: '17-e8aa3bd57421282673d75e5a7b9cf3f5',
							type: 'category',
							level: 'line',
							code: 'AEA',
							description: 'VERSACE OUD NOIR',
							sequence: 0,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEA_720260',
								'category_ORD_V0_AEA_720264',
								'category_ORD_V0_AEA_790066',
								'category_ORD_V0_AEA_720210'
							]
						},
						{
							_id: 'category_ORD_V0_AEC',
							_rev: '17-19ecd5f736ddc5edead3b77cebbe8933',
							type: 'category',
							level: 'line',
							code: 'AEC',
							description: 'VERSACE OUD ORIENTAL',
							sequence: 0,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEC_701060',
								'category_ORD_V0_AEC_701064',
								'category_ORD_V0_AEC_701032'
							]
						},
						{
							_id: 'category_ORD_V0_AEI',
							_rev: '17-f3e1f9edfbc869dccc1731d4a81051b4',
							type: 'category',
							level: 'line',
							code: 'AEI',
							description: 'PF DYLAN BLUE',
							sequence: 1,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEI_702030',
								'category_ORD_V0_AEI_702032',
								'category_ORD_V0_AEI_702060',
								'category_ORD_V0_AEI_702062',
								'category_ORD_V0_AEI_702064',
								'category_ORD_V0_AEI_702066',
								'category_ORD_V0_AEI_702080',
								'category_ORD_V0_AEI_702028'
							]
						},
						{
							_id: 'category_ORD_V0_AEG',
							_rev: '17-0300abcfeef2c8a47415940653b414ef',
							type: 'category',
							level: 'line',
							code: 'AEG',
							description: 'EROS POUR FEMME EDT',
							sequence: 20,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEG_750130',
								'category_ORD_V0_AEG_750132',
								'category_ORD_V0_AEG_750140',
								'category_ORD_V0_AEG_750148',
								'category_ORD_V0_AEG_750150',
								'category_ORD_V0_AEG_750160',
								'category_ORD_V0_AEG_750162',
								'category_ORD_V0_AEG_750164',
								'category_ORD_V0_AEG_750128',
								'category_ORD_V0_AEG_750166'
							]
						},
						{
							_id: 'category_ORD_V0_AED',
							_rev: '17-24965501064774708c5e220450c3858c',
							type: 'category',
							level: 'line',
							code: 'AED',
							description: 'EROS POUR FEMME EDP',
							sequence: 30,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AED_750030',
								'category_ORD_V0_AED_750032',
								'category_ORD_V0_AED_750060',
								'category_ORD_V0_AED_750062',
								'category_ORD_V0_AED_750064',
								'category_ORD_V0_AED_750066',
								'category_ORD_V0_AED_750028'
							]
						},
						{
							_id: 'category_ORD_V0_AE0',
							_rev: '17-569f86089687bb38a6da815183380a06',
							type: 'category',
							level: 'line',
							code: 'AE0',
							description: 'VANITAS',
							sequence: 40,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AE0_730028',
								'category_ORD_V0_AE0_730066',
								'category_ORD_V0_AE0_730067',
								'category_ORD_V0_AE0_730080',
								'category_ORD_V0_AE0_730030',
								'category_ORD_V0_AE0_730128',
								'category_ORD_V0_AE0_730130',
								'category_ORD_V0_AE0_730132',
								'category_ORD_V0_AE0_730150',
								'category_ORD_V0_AE0_730160',
								'category_ORD_V0_AE0_730162',
								'category_ORD_V0_AE0_730164',
								'category_ORD_V0_AE0_730166',
								'category_ORD_V0_AE0_730032',
								'category_ORD_V0_AE0_730167',
								'category_ORD_V0_AE0_730180',
								'category_ORD_V0_AE0_730048',
								'category_ORD_V0_AE0_730050',
								'category_ORD_V0_AE0_730060',
								'category_ORD_V0_AE0_730062',
								'category_ORD_V0_AE0_730064'
							]
						},
						{
							_id: 'category_ORD_V0_AE1',
							_rev: '17-f9546f04ecbb5740cb84bbb69e72e05f',
							type: 'category',
							level: 'line',
							code: 'AE1',
							description: 'VERSACE POUR FEMME',
							sequence: 60,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AE1_700028',
								'category_ORD_V0_AE1_700064',
								'category_ORD_V0_AE1_700067',
								'category_ORD_V0_AE1_700066',
								'category_ORD_V0_AE1_700080',
								'category_ORD_V0_AE1_700030',
								'category_ORD_V0_AE1_700032',
								'category_ORD_V0_AE1_700048',
								'category_ORD_V0_AE1_700050',
								'category_ORD_V0_AE1_700060',
								'category_ORD_V0_AE1_700062',
								'category_ORD_V0_AE1_700063',
								'category_ORD_V0_AE1_700164'
							]
						},
						{
							_id: 'category_ORD_V0_AE4',
							_rev: '17-78919c787361bff468db4eec6690aa01',
							type: 'category',
							level: 'line',
							code: 'AE4',
							description: 'VERSENSE',
							sequence: 70,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AE4_780028',
								'category_ORD_V0_AE4_780164',
								'category_ORD_V0_AE4_780066',
								'category_ORD_V0_AE4_780067',
								'category_ORD_V0_AE4_780080',
								'category_ORD_V0_AE4_780030',
								'category_ORD_V0_AE4_780032',
								'category_ORD_V0_AE4_780040',
								'category_ORD_V0_AE4_780048',
								'category_ORD_V0_AE4_780050',
								'category_ORD_V0_AE4_780060',
								'category_ORD_V0_AE4_780062',
								'category_ORD_V0_AE4_780063'
							]
						},
						{
							_id: 'category_ORD_V0_AVN',
							_rev: '17-ec975626d2007d8e1c6499d4a4b916fa',
							type: 'category',
							level: 'line',
							code: 'AVN',
							description: 'CRYSTAL NOIR',
							sequence: 80,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AVN_V07564',
								'category_ORD_V0_AVN_V07147',
								'category_ORD_V0_AVN_V07164',
								'category_ORD_V0_AVN_V07103',
								'category_ORD_V0_AVN_V77046',
								'category_ORD_V0_AVN_V77063',
								'category_ORD_V0_AVN_V070260',
								'category_ORD_V0_AVN_V070460',
								'category_ORD_V0_AVN_V07116',
								'category_ORD_V0_AVN_V07126',
								'category_ORD_V0_AVN_V07146',
								'category_ORD_V0_AVN_V07440',
								'category_ORD_V0_AVN_V07464'
							]
						},
						{
							_id: 'category_ORD_V0_AEB',
							_rev: '17-60350981413338068c9c813a9a17c800',
							type: 'category',
							level: 'line',
							code: 'AEB',
							description: 'VERSACE BC ABSOLU',
							sequence: 90,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEB_511030',
								'category_ORD_V0_AEB_511032',
								'category_ORD_V0_AEB_511060',
								'category_ORD_V0_AEB_511064',
								'category_ORD_V0_AEB_511028'
							]
						},
						{
							_id: 'category_ORD_V0_AVO',
							_rev: '17-a1c6b11b5fd667a93474c31d45f55ddf',
							type: 'category',
							level: 'line',
							code: 'AVO',
							description: 'BRIGHT CRYSTAL',
							sequence: 100,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AVO_V510028',
								'category_ORD_V0_AVO_V510164',
								'category_ORD_V0_AVO_V510080',
								'category_ORD_V0_AVO_V510166',
								'category_ORD_V0_AVO_V510167',
								'category_ORD_V0_AVO_V510030',
								'category_ORD_V0_AVO_V510032',
								'category_ORD_V0_AVO_V510036',
								'category_ORD_V0_AVO_V510040',
								'category_ORD_V0_AVO_V510023A',
								'category_ORD_V0_AVO_V510048',
								'category_ORD_V0_AVO_V510050',
								'category_ORD_V0_AVO_V510060',
								'category_ORD_V0_AVO_V510062'
							]
						},
						{
							_id: 'category_ORD_V0_AEE',
							_rev: '17-b68e135d3b73852788850e0d0c602add',
							type: 'category',
							level: 'line',
							code: 'AEE',
							description: 'VERSACE INTENSE',
							sequence: 110,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEE_521030',
								'category_ORD_V0_AEE_521032',
								'category_ORD_V0_AEE_521060',
								'category_ORD_V0_AEE_521062',
								'category_ORD_V0_AEE_521028',
								'category_ORD_V0_AEE_521064',
								'category_ORD_V0_AEE_521066',
								'category_ORD_V0_AEE_521067'
							]
						},
						{
							_id: 'category_ORD_V0_AVP',
							_rev: '17-638959f2420b1b2c1f176ee3a40b86c8',
							type: 'category',
							level: 'line',
							code: 'AVP',
							description: 'YELLOW DIAMOND',
							sequence: 120,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AVP_520028',
								'category_ORD_V0_AVP_520164',
								'category_ORD_V0_AVP_520080',
								'category_ORD_V0_AVP_520066',
								'category_ORD_V0_AVP_520067',
								'category_ORD_V0_AVP_520030',
								'category_ORD_V0_AVP_520032',
								'category_ORD_V0_AVP_520036',
								'category_ORD_V0_AVP_520040',
								'category_ORD_V0_AVP_520025',
								'category_ORD_V0_AVP_520048',
								'category_ORD_V0_AVP_520050',
								'category_ORD_V0_AVP_520060',
								'category_ORD_V0_AVP_520062'
							]
						},
						{
							_id: 'category_ORD_V0_BVA',
							_rev: '17-a2abd3bd8835c7928ec8e063138e47ee',
							type: 'category',
							level: 'line',
							code: 'BVA',
							description: 'WOMAN',
							sequence: 130,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_BVA_V25016',
								'category_ORD_V0_BVA_V25003',
								'category_ORD_V0_BVA_V25026',
								'category_ORD_V0_BVA_V25046',
								'category_ORD_V0_BVA_V25464',
								'category_ORD_V0_BVA_V25564',
								'category_ORD_V0_BVA_V25246',
								'category_ORD_V0_BVA_V25027',
								'category_ORD_V0_BVA_V25001',
								'category_ORD_V0_BVA_V25006'
							]
						},
						{
							_id: 'category_ORD_V0_AEM',
							_rev: '17-4de291bdc33caeb7a5bd62a1624c8e42',
							type: 'category',
							level: 'line',
							code: 'AEM',
							description: 'EROS FLAME',
							sequence: 135,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEM_741007',
								'category_ORD_V0_AEM_741060',
								'category_ORD_V0_AEM_741062',
								'category_ORD_V0_AEM_741064',
								'category_ORD_V0_AEM_741080',
								'category_ORD_V0_AEM_741066',
								'category_ORD_V0_AEM_741008',
								'category_ORD_V0_AEM_741010',
								'category_ORD_V0_AEM_741011',
								'category_ORD_V0_AEM_741014',
								'category_ORD_V0_AEM_741016',
								'category_ORD_V0_AEM_741020',
								'category_ORD_V0_AEM_741023',
								'category_ORD_V0_AEM_741027'
							]
						},
						{
							_id: 'category_ORD_V0_AEF',
							_rev: '17-9ceb1d1ac34c76a98ced80631bbbfaf3',
							type: 'category',
							level: 'line',
							code: 'AEF',
							description: 'PH DYLAN BLUE',
							sequence: 140,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AEF_721007',
								'category_ORD_V0_AEF_721008',
								'category_ORD_V0_AEF_721010',
								'category_ORD_V0_AEF_721011',
								'category_ORD_V0_AEF_721014',
								'category_ORD_V0_AEF_721016',
								'category_ORD_V0_AEF_721020',
								'category_ORD_V0_AEF_721023',
								'category_ORD_V0_AEF_721027',
								'category_ORD_V0_AEF_721060',
								'category_ORD_V0_AEF_721062',
								'category_ORD_V0_AEF_721064',
								'category_ORD_V0_AEF_721066',
								'category_ORD_V0_AEF_721080'
							]
						},
						{
							_id: 'category_ORD_V0_AV0',
							_rev: '17-131443d352810d0e2b0314aa523cb2f8',
							type: 'category',
							level: 'line',
							code: 'AV0',
							description: 'EROS',
							sequence: 150,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_AV0_740007',
								'category_ORD_V0_AV0_740060',
								'category_ORD_V0_AV0_740062',
								'category_ORD_V0_AV0_740064',
								'category_ORD_V0_AV0_740066',
								'category_ORD_V0_AV0_740067',
								'category_ORD_V0_AV0_740080',
								'category_ORD_V0_AV0_740082',
								'category_ORD_V0_AV0_740008',
								'category_ORD_V0_AV0_740010',
								'category_ORD_V0_AV0_740011',
								'category_ORD_V0_AV0_740014',
								'category_ORD_V0_AV0_740016',
								'category_ORD_V0_AV0_740020',
								'category_ORD_V0_AV0_740023',
								'category_ORD_V0_AV0_740027'
							]
						},
						{
							_id: 'category_ORD_V0_CVR',
							_rev: '17-b2a2f74584544133028a4ec83ad0bdef',
							type: 'category',
							level: 'line',
							code: 'CVR',
							description: 'POUR HOMME',
							sequence: 160,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_CVR_720007',
								'category_ORD_V0_CVR_720060',
								'category_ORD_V0_CVR_720062',
								'category_ORD_V0_CVR_720164',
								'category_ORD_V0_CVR_720066',
								'category_ORD_V0_CVR_720067',
								'category_ORD_V0_CVR_720080',
								'category_ORD_V0_CVR_720008',
								'category_ORD_V0_CVR_720010',
								'category_ORD_V0_CVR_720011',
								'category_ORD_V0_CVR_720014',
								'category_ORD_V0_CVR_720016',
								'category_ORD_V0_CVR_720020',
								'category_ORD_V0_CVR_720023A',
								'category_ORD_V0_CVR_720027'
							]
						},
						{
							_id: 'category_ORD_V0_GVQ',
							_rev: '17-1be0ccfb8edd6418b12058cb9c6b4d8f',
							type: 'category',
							level: 'line',
							code: 'GVQ',
							description: 'MAN EAU FRAICHE',
							sequence: 170,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_GVQ_V500007',
								'category_ORD_V0_GVQ_V500060',
								'category_ORD_V0_GVQ_V500164',
								'category_ORD_V0_GVQ_V500062',
								'category_ORD_V0_GVQ_V500065',
								'category_ORD_V0_GVQ_V500067',
								'category_ORD_V0_GVQ_V500080',
								'category_ORD_V0_GVQ_V500008',
								'category_ORD_V0_GVQ_V500010',
								'category_ORD_V0_GVQ_V500011',
								'category_ORD_V0_GVQ_V500014',
								'category_ORD_V0_GVQ_V500016',
								'category_ORD_V0_GVQ_V500020',
								'category_ORD_V0_GVQ_V500023A',
								'category_ORD_V0_GVQ_V500027'
							]
						},
						{
							_id: 'category_ORD_V0_HVM',
							_rev: '17-4c8f1373eaa2381e554738ca689a21a4',
							type: 'category',
							level: 'line',
							code: 'HVM',
							description: 'THE DREAMER',
							sequence: 180,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_HVM_V15801',
								'category_ORD_V0_HVM_V15060',
								'category_ORD_V0_HVM_V15063',
								'category_ORD_V0_HVM_V15007',
								'category_ORD_V0_HVM_V15008',
								'category_ORD_V0_HVM_V15010',
								'category_ORD_V0_HVM_V15124',
								'category_ORD_V0_HVM_V15144',
								'category_ORD_V0_HVM_V15816',
								'category_ORD_V0_HVM_V15823'
							]
						},
						{
							_id: 'category_ORD_V0_IVL',
							_rev: '17-b82d85dd74284ad5dc70a07cd23fbd53',
							type: 'category',
							level: 'line',
							code: 'IVL',
							description: "L'HOMME",
							sequence: 190,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_IVL_V14007',
								'category_ORD_V0_IVL_V14060',
								'category_ORD_V0_IVL_V14063',
								'category_ORD_V0_IVL_V14008',
								'category_ORD_V0_IVL_V14010',
								'category_ORD_V0_IVL_V14014',
								'category_ORD_V0_IVL_V14723',
								'category_ORD_V0_IVL_V14727'
							]
						},
						{
							_id: 'category_ORD_V0_LVG',
							_rev: '17-1e6a4fb30ce72ed7ebecf797eae5a4ec',
							type: 'category',
							level: 'line',
							code: 'LVG',
							description: 'JEANS COLLECTION',
							sequence: 200,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_LVG_V27075',
								'category_ORD_V0_LVG_V26075',
								'category_ORD_V0_LVG_V26075T',
								'category_ORD_V0_LVG_V27075T',
								'category_ORD_V0_LVG_V26001S',
								'category_ORD_V0_LVG_V27001S',
								'category_ORD_V0_LVG_V26007',
								'category_ORD_V0_LVG_V27007'
							]
						},
						{
							_id: 'category_ORD_V0_VSS',
							_rev: '17-be1cd0a4c1fa6ff9b563c29b181aaeab',
							type: 'category',
							level: 'line',
							code: 'VSS',
							description: 'VERSUS WOMAN',
							sequence: 210,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: [
								'category_ORD_V0_VSS_760028',
								'category_ORD_V0_VSS_760062',
								'category_ORD_V0_VSS_760063',
								'category_ORD_V0_VSS_760164',
								'category_ORD_V0_VSS_760066',
								'category_ORD_V0_VSS_760067',
								'category_ORD_V0_VSS_730080',
								'category_ORD_V0_VSS_760030',
								'category_ORD_V0_VSS_760032',
								'category_ORD_V0_VSS_760048',
								'category_ORD_V0_VSS_760050',
								'category_ORD_V0_VSS_760019',
								'category_ORD_V0_VSS_760042',
								'category_ORD_V0_VSS_760060'
							]
						},
						{
							_id: 'category_ORD_V0_AV',
							_rev: '15-651d689c96448190d6e8c5e2bef44f46',
							type: 'category',
							level: 'line',
							code: 'AV',
							description: 'ATELIER VERSACE',
							sequence: 220,
							valid: true,
							parent: 'category_ORD_V0',
							hierarchy: ['category_ORD', 'category_ORD_V0'],
							children: []
						}
					]
				},
				{
					_id: 'category_ORD_06',
					_rev: '17-71f282d76419b0fe40be68d988d94d0e',
					type: 'category',
					level: 'brand',
					code: '06',
					description: 'MOSCHINO',
					sequence: 2,
					valid: true,
					parent: 'category_ORD',
					hierarchy: ['category_ORD'],
					children: [
						'category_ORD_06_60',
						'category_ORD_06_6W',
						'category_ORD_06_6V',
						'category_ORD_06_6S',
						'category_ORD_06_6T',
						'category_ORD_06_6R',
						'category_ORD_06_6Q',
						'category_ORD_06_6P',
						'category_ORD_06_6K',
						'category_ORD_06_6L',
						'category_ORD_06_6U',
						'category_ORD_06_6M',
						'category_ORD_06_6A',
						'category_ORD_06_61',
						'category_ORD_06_6C',
						'category_ORD_06_6N',
						'category_ORD_06_6OH',
						'category_ORD_06_6F',
						'category_ORD_06_64'
					],
					category_list: [
						{
							_id: 'category_ORD_06_60',
							_rev: '17-6d35262a4ab0132d3d83de29291fa1f5',
							type: 'category',
							level: 'line',
							code: '60',
							description: 'MOSCHINO POUR FEMME',
							sequence: 10,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_60_6030',
								'category_ORD_06_60_6063',
								'category_ORD_06_60_6066',
								'category_ORD_06_60_6070',
								'category_ORD_06_60_6032',
								'category_ORD_06_60_6040',
								'category_ORD_06_60_6046',
								'category_ORD_06_60_6028',
								'category_ORD_06_60_6048',
								'category_ORD_06_60_6050',
								'category_ORD_06_60_6052',
								'category_ORD_06_60_6054',
								'category_ORD_06_60_6060'
							]
						},
						{
							_id: 'category_ORD_06_6OH',
							_rev: '14-0e9c05f12ae497054f2901f88bcdc556',
							type: 'category',
							level: 'line',
							code: '6OH',
							description: 'OH! DE MOSCHINO',
							sequence: 20,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6OH_6328',
								'category_ORD_06_6OH_6330',
								'category_ORD_06_6OH_6332',
								'category_ORD_06_6OH_6360',
								'category_ORD_06_6OH_6362',
								'category_ORD_06_6OH_6363',
								'category_ORD_06_6OH_6367',
								'category_ORD_06_6OH_6382'
							]
						},
						{
							_id: 'category_ORD_06_6C',
							_rev: '17-583a538ba74e45f856ab24563abffd24',
							type: 'category',
							level: 'line',
							code: '6C',
							description: 'MOSCHINO FUNNY',
							sequence: 30,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6C_6C28',
								'category_ORD_06_6C_6C66',
								'category_ORD_06_6C_6C67',
								'category_ORD_06_6C_6C80',
								'category_ORD_06_6C_6C82',
								'category_ORD_06_6C_6C264',
								'category_ORD_06_6C_6C30',
								'category_ORD_06_6C_6C32',
								'category_ORD_06_6C_6C40',
								'category_ORD_06_6C_6C48',
								'category_ORD_06_6C_6C50',
								'category_ORD_06_6C_6C60',
								'category_ORD_06_6C_6C62',
								'category_ORD_06_6C_6C63'
							]
						},
						{
							_id: 'category_ORD_06_61',
							_rev: '17-ce64a7990a25696b494465d8599d2293',
							type: 'category',
							level: 'line',
							code: '61',
							description: 'MOSCHINO CHEAP AND CHIC',
							sequence: 40,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_61_6114',
								'category_ORD_06_61_6162',
								'category_ORD_06_61_6164',
								'category_ORD_06_61_61264',
								'category_ORD_06_61_6165',
								'category_ORD_06_61_6168',
								'category_ORD_06_61_6171',
								'category_ORD_06_61_6180',
								'category_ORD_06_61_6182',
								'category_ORD_06_61_61280',
								'category_ORD_06_61_6130',
								'category_ORD_06_61_6132',
								'category_ORD_06_61_6140',
								'category_ORD_06_61_6142',
								'category_ORD_06_61_6146',
								'category_ORD_06_61_6148',
								'category_ORD_06_61_6150',
								'category_ORD_06_61_61600'
							]
						},
						{
							_id: 'category_ORD_06_6A',
							_rev: '17-91b4a215eebf60bcf121cd757c5545e1',
							type: 'category',
							level: 'line',
							code: '6A',
							description: 'MOSCHINO I LOVE LOVE',
							sequence: 50,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6A_6A28',
								'category_ORD_06_6A_6A62',
								'category_ORD_06_6A_6A264',
								'category_ORD_06_6A_6A64',
								'category_ORD_06_6A_6A65',
								'category_ORD_06_6A_6A67',
								'category_ORD_06_6A_6A80',
								'category_ORD_06_6A_6A82',
								'category_ORD_06_6A_6A30',
								'category_ORD_06_6A_6A32',
								'category_ORD_06_6A_6A40',
								'category_ORD_06_6A_6A43',
								'category_ORD_06_6A_6A48',
								'category_ORD_06_6A_6A50',
								'category_ORD_06_6A_6A600'
							]
						},
						{
							_id: 'category_ORD_06_6F',
							_rev: '14-ff73f14d210cd09c0f1d7edba8b546b1',
							type: 'category',
							level: 'line',
							code: '6F',
							description: 'MOSCHINO LIGHT CLOUDS',
							sequence: 60,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6F_6F28',
								'category_ORD_06_6F_6F80',
								'category_ORD_06_6F_6F82',
								'category_ORD_06_6F_6F30',
								'category_ORD_06_6F_6F32',
								'category_ORD_06_6F_6F48',
								'category_ORD_06_6F_6F50',
								'category_ORD_06_6F_6F60',
								'category_ORD_06_6F_6F62',
								'category_ORD_06_6F_6F63'
							]
						},
						{
							_id: 'category_ORD_06_6M',
							_rev: '17-6d1263471a0a4239a4637385ebb4c202',
							type: 'category',
							level: 'line',
							code: '6M',
							description: 'MOSCHINO CHIC PETALS',
							sequence: 70,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6M_6M28',
								'category_ORD_06_6M_6M67',
								'category_ORD_06_6M_6M80',
								'category_ORD_06_6M_6M264',
								'category_ORD_06_6M_6M30',
								'category_ORD_06_6M_6M32',
								'category_ORD_06_6M_6M48',
								'category_ORD_06_6M_6M50',
								'category_ORD_06_6M_6M60',
								'category_ORD_06_6M_6M62',
								'category_ORD_06_6M_6M66'
							]
						},
						{
							_id: 'category_ORD_06_6U',
							_rev: '17-564ee402240c6d1cb38e294c1d4d8574',
							type: 'category',
							level: 'line',
							code: '6U',
							description: 'MOSCHINO SO REAL',
							sequence: 80,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6U_6U28',
								'category_ORD_06_6U_6U80',
								'category_ORD_06_6U_6U30',
								'category_ORD_06_6U_6U32',
								'category_ORD_06_6U_6U48',
								'category_ORD_06_6U_6U50',
								'category_ORD_06_6U_6U60',
								'category_ORD_06_6U_6U62',
								'category_ORD_06_6U_6U64',
								'category_ORD_06_6U_6U65'
							]
						},
						{
							_id: 'category_ORD_06_6L',
							_rev: '17-c4fa16868caa2cd46fc9e765e7c24465',
							type: 'category',
							level: 'line',
							code: '6L',
							description: 'MOSCHINO PINK BOUQUET',
							sequence: 90,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6L_6L28',
								'category_ORD_06_6L_6L66',
								'category_ORD_06_6L_6L67',
								'category_ORD_06_6L_6L80',
								'category_ORD_06_6L_6L82',
								'category_ORD_06_6L_6L264',
								'category_ORD_06_6L_6L30',
								'category_ORD_06_6L_6L32',
								'category_ORD_06_6L_6L40',
								'category_ORD_06_6L_6L48',
								'category_ORD_06_6L_6L50',
								'category_ORD_06_6L_6L60',
								'category_ORD_06_6L_6L62'
							]
						},
						{
							_id: 'category_ORD_06_64',
							_rev: '17-13373fed93349c2d8766b3ecd7be1697',
							type: 'category',
							level: 'line',
							code: '64',
							description: 'UOMO MOSCHINO',
							sequence: 100,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_64_6423',
								'category_ORD_06_64_6426',
								'category_ORD_06_64_6428',
								'category_ORD_06_64_6460',
								'category_ORD_06_64_6464',
								'category_ORD_06_64_6466',
								'category_ORD_06_64_6482',
								'category_ORD_06_64_6407',
								'category_ORD_06_64_6408',
								'category_ORD_06_64_6410',
								'category_ORD_06_64_6412',
								'category_ORD_06_64_6418',
								'category_ORD_06_64_6420'
							]
						},
						{
							_id: 'category_ORD_06_6K',
							_rev: '17-ad9d70f49388e3fe17f1eb2f1814cc69',
							type: 'category',
							level: 'line',
							code: '6K',
							description: 'MOSCHINO FOREVER',
							sequence: 110,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6K_6K07',
								'category_ORD_06_6K_6K62',
								'category_ORD_06_6K_6K63',
								'category_ORD_06_6K_6K67',
								'category_ORD_06_6K_6K80',
								'category_ORD_06_6K_6K08',
								'category_ORD_06_6K_6K10',
								'category_ORD_06_6K_6K14',
								'category_ORD_06_6K_6K16',
								'category_ORD_06_6K_6K25',
								'category_ORD_06_6K_6K27',
								'category_ORD_06_6K_6K60'
							]
						},
						{
							_id: 'category_ORD_06_6N',
							_rev: '17-4ee88a0771e4c653e4911cee44ac4e07',
							type: 'category',
							level: 'line',
							code: '6N',
							description: 'MOSCHINO SAILING',
							sequence: 120,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6N_6N07',
								'category_ORD_06_6N_6N80',
								'category_ORD_06_6N_6N08',
								'category_ORD_06_6N_6N10',
								'category_ORD_06_6N_6N14',
								'category_ORD_06_6N_6N16',
								'category_ORD_06_6N_6N27',
								'category_ORD_06_6N_6N60',
								'category_ORD_06_6N_6N62',
								'category_ORD_06_6N_6N64'
							]
						},
						{
							_id: 'category_ORD_06_6P',
							_rev: '17-8ccb41b725044a628848b694fe2f6934',
							type: 'category',
							level: 'line',
							code: '6P',
							description: 'MOSCHINO STARS',
							sequence: 130,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6P_6P28',
								'category_ORD_06_6P_6P30',
								'category_ORD_06_6P_6P32',
								'category_ORD_06_6P_6P60',
								'category_ORD_06_6P_6P62',
								'category_ORD_06_6P_6P64'
							]
						},
						{
							_id: 'category_ORD_06_6Q',
							_rev: '17-6a287801e6b2950959d3835d79a068a1',
							type: 'category',
							level: 'line',
							code: '6Q',
							description: 'MOSCHINO TOY',
							sequence: 140,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6Q_6Q60',
								'category_ORD_06_6Q_6Q64',
								'category_ORD_06_6Q_6Q65',
								'category_ORD_06_6Q_6Q68',
								'category_ORD_06_6Q_6Q30'
							]
						},
						{
							_id: 'category_ORD_06_6R',
							_rev: '17-3ca65cbda21bc53a9e6ac397515bd487',
							type: 'category',
							level: 'line',
							code: '6R',
							description: 'MOSCHINO FRESH',
							sequence: 150,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6R_6R28',
								'category_ORD_06_6R_6R80',
								'category_ORD_06_6R_6R30',
								'category_ORD_06_6R_6R32',
								'category_ORD_06_6R_6R48',
								'category_ORD_06_6R_6R50',
								'category_ORD_06_6R_6R60',
								'category_ORD_06_6R_6R62',
								'category_ORD_06_6R_6R64',
								'category_ORD_06_6R_6R66'
							]
						},
						{
							_id: 'category_ORD_06_6T',
							_rev: '17-09b3ae71952ee599427bf069ca1c2824',
							type: 'category',
							level: 'line',
							code: '6T',
							description: 'MOSCHINO PINK FRESH',
							sequence: 160,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6T_6T28',
								'category_ORD_06_6T_6T80',
								'category_ORD_06_6T_6T30',
								'category_ORD_06_6T_6T32',
								'category_ORD_06_6T_6T48',
								'category_ORD_06_6T_6T50',
								'category_ORD_06_6T_6T60',
								'category_ORD_06_6T_6T62',
								'category_ORD_06_6T_6T64',
								'category_ORD_06_6T_6T66'
							]
						},
						{
							_id: 'category_ORD_06_6S',
							_rev: '17-7e8b9670345cc6d6784750594cb193ca',
							type: 'category',
							level: 'line',
							code: '6S',
							description: 'MOSCHINO GOLD FRESH',
							sequence: 170,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6S_6S28',
								'category_ORD_06_6S_6S80',
								'category_ORD_06_6S_6S30',
								'category_ORD_06_6S_6S32',
								'category_ORD_06_6S_6S48',
								'category_ORD_06_6S_6S50',
								'category_ORD_06_6S_6S60',
								'category_ORD_06_6S_6S62',
								'category_ORD_06_6S_6S64',
								'category_ORD_06_6S_6S66'
							]
						},
						{
							_id: 'category_ORD_06_6V',
							_rev: '17-8971595e387a7a0f1099a3475a536319',
							type: 'category',
							level: 'line',
							code: '6V',
							description: 'MOSCHINO TOY 2',
							sequence: 180,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6V_6V28',
								'category_ORD_06_6V_6V80',
								'category_ORD_06_6V_6V30',
								'category_ORD_06_6V_6V32',
								'category_ORD_06_6V_6V48',
								'category_ORD_06_6V_6V50',
								'category_ORD_06_6V_6V60',
								'category_ORD_06_6V_6V62',
								'category_ORD_06_6V_6V64',
								'category_ORD_06_6V_6V66'
							]
						},
						{
							_id: 'category_ORD_06_6W',
							_rev: '17-b67c5524d86cae674a61019a6fb637f6',
							type: 'category',
							level: 'line',
							code: '6W',
							description: 'MOSCHINO TOY BOY',
							sequence: 190,
							valid: true,
							parent: 'category_ORD_06',
							hierarchy: ['category_ORD', 'category_ORD_06'],
							children: [
								'category_ORD_06_6W_6W07',
								'category_ORD_06_6W_6W64',
								'category_ORD_06_6W_6W66',
								'category_ORD_06_6W_6W80',
								'category_ORD_06_6W_6W08',
								'category_ORD_06_6W_6W10',
								'category_ORD_06_6W_6W14',
								'category_ORD_06_6W_6W16',
								'category_ORD_06_6W_6W27',
								'category_ORD_06_6W_6W50',
								'category_ORD_06_6W_6W60',
								'category_ORD_06_6W_6W62'
							]
						}
					]
				},
				{
					_id: 'category_ORD_80',
					_rev: '17-784cb20dd9c4e76d89a9b5f6682d9a65',
					type: 'category',
					level: 'brand',
					code: '80',
					description: 'MISSONI',
					sequence: 3,
					valid: true,
					parent: 'category_ORD',
					hierarchy: ['category_ORD'],
					children: ['category_ORD_80_8B', 'category_ORD_80_8A', 'category_ORD_80_8C', 'category_ORD_80_8D'],
					category_list: [
						{
							_id: 'category_ORD_80_8A',
							_rev: '17-31859f38911e557e83adb9665b587440',
							type: 'category',
							level: 'line',
							code: '8A',
							description: 'MISSONI DONNA EDP',
							sequence: 1,
							valid: true,
							parent: 'category_ORD_80',
							hierarchy: ['category_ORD', 'category_ORD_80'],
							children: [
								'category_ORD_80_8A_800028',
								'category_ORD_80_8A_800030',
								'category_ORD_80_8A_800032',
								'category_ORD_80_8A_800060',
								'category_ORD_80_8A_800062',
								'category_ORD_80_8A_800064',
								'category_ORD_80_8A_800066',
								'category_ORD_80_8A_800080'
							]
						},
						{
							_id: 'category_ORD_80_8B',
							_rev: '17-b6dd0c2ff3826bb3bcf892552c257eaa',
							type: 'category',
							level: 'line',
							code: '8B',
							description: 'MISSONI DONNA EDT',
							sequence: 2,
							valid: true,
							parent: 'category_ORD_80',
							hierarchy: ['category_ORD', 'category_ORD_80'],
							children: [
								'category_ORD_80_8B_800128',
								'category_ORD_80_8B_800166',
								'category_ORD_80_8B_800180',
								'category_ORD_80_8B_800130',
								'category_ORD_80_8B_800132',
								'category_ORD_80_8B_800140',
								'category_ORD_80_8B_800148',
								'category_ORD_80_8B_800150',
								'category_ORD_80_8B_800160',
								'category_ORD_80_8B_800162',
								'category_ORD_80_8B_800164'
							]
						},
						{
							_id: 'category_ORD_80_8C',
							_rev: '17-d11d5b4dbd6296448b566810ebf4c9d3',
							type: 'category',
							level: 'line',
							code: '8C',
							description: 'MISSONI POUR HOMME',
							sequence: 3,
							valid: true,
							parent: 'category_ORD_80',
							hierarchy: ['category_ORD', 'category_ORD_80'],
							children: [
								'category_ORD_80_8C_820062',
								'category_ORD_80_8C_820064',
								'category_ORD_80_8C_820066',
								'category_ORD_80_8C_820080',
								'category_ORD_80_8C_820007',
								'category_ORD_80_8C_820008',
								'category_ORD_80_8C_820010',
								'category_ORD_80_8C_820014',
								'category_ORD_80_8C_820016',
								'category_ORD_80_8C_820020',
								'category_ORD_80_8C_820023',
								'category_ORD_80_8C_820027',
								'category_ORD_80_8C_820060'
							]
						},
						{
							_id: 'category_ORD_80_8D',
							_rev: '14-b3e8c03c44abd287af6b6f6b57f0c933',
							type: 'category',
							level: 'line',
							code: '8D',
							description: 'MISSONI WAVE',
							sequence: 4,
							valid: true,
							parent: 'category_ORD_80',
							hierarchy: ['category_ORD', 'category_ORD_80'],
							children: [
								'category_ORD_80_8D_821007',
								'category_ORD_80_8D_821008',
								'category_ORD_80_8D_821010',
								'category_ORD_80_8D_821016',
								'category_ORD_80_8D_821023',
								'category_ORD_80_8D_821027',
								'category_ORD_80_8D_821060',
								'category_ORD_80_8D_821080'
							]
						}
					]
				},
				{
					_id: 'category_ORD_50',
					_rev: '17-ab830a2055a076b228fe2168c0bbe613',
					type: 'category',
					level: 'brand',
					code: '50',
					description: 'DSQUARED2',
					sequence: 4,
					valid: true,
					parent: 'category_ORD',
					hierarchy: ['category_ORD'],
					children: ['category_ORD_50_5A', 'category_ORD_50_5B', 'category_ORD_50_5C', 'category_ORD_50_5D'],
					category_list: [
						{
							_id: 'category_ORD_50_5A',
							_rev: '17-87947c6ad7ec8a06b961928426211819',
							type: 'category',
							level: 'line',
							code: '5A',
							description: 'DSQUARED2 POUR FEMME',
							sequence: 1,
							valid: true,
							parent: 'category_ORD_50',
							hierarchy: ['category_ORD', 'category_ORD_50'],
							children: [
								'category_ORD_50_5A_5A30',
								'category_ORD_50_5A_5A32',
								'category_ORD_50_5A_5A48',
								'category_ORD_50_5A_5A50',
								'category_ORD_50_5A_5A60',
								'category_ORD_50_5A_5A62',
								'category_ORD_50_5A_5A64',
								'category_ORD_50_5A_5A80',
								'category_ORD_50_5A_5A28',
								'category_ORD_50_5A_5A82'
							]
						},
						{
							_id: 'category_ORD_50_5B',
							_rev: '17-9b891c267de0a96b6098669646fa1878',
							type: 'category',
							level: 'line',
							code: '5B',
							description: 'DSQUARED2 POUR HOMME',
							sequence: 2,
							valid: true,
							parent: 'category_ORD_50',
							hierarchy: ['category_ORD', 'category_ORD_50'],
							children: [
								'category_ORD_50_5B_5B64',
								'category_ORD_50_5B_5B80',
								'category_ORD_50_5B_5Z066',
								'category_ORD_50_5B_5B82',
								'category_ORD_50_5B_5B07',
								'category_ORD_50_5B_5B08',
								'category_ORD_50_5B_5B10',
								'category_ORD_50_5B_5B16',
								'category_ORD_50_5B_5B23',
								'category_ORD_50_5B_5B27',
								'category_ORD_50_5B_5B60',
								'category_ORD_50_5B_5B62'
							]
						},
						{
							_id: 'category_ORD_50_5C',
							_rev: '17-c621a551abbaf8244a7857550ff1ba95',
							type: 'category',
							level: 'line',
							code: '5C',
							description: 'DSQUARED2 RED',
							sequence: 3,
							valid: true,
							parent: 'category_ORD_50',
							hierarchy: ['category_ORD', 'category_ORD_50'],
							children: [
								'category_ORD_50_5C_5C28',
								'category_ORD_50_5C_5C30',
								'category_ORD_50_5C_5C32',
								'category_ORD_50_5C_5C48',
								'category_ORD_50_5C_5C50',
								'category_ORD_50_5C_5C60',
								'category_ORD_50_5C_5C62',
								'category_ORD_50_5C_5C64',
								'category_ORD_50_5C_5C80'
							]
						},
						{
							_id: 'category_ORD_50_5D',
							_rev: '17-ed5c665063c853cf64b3e8b1da12c02a',
							type: 'category',
							level: 'line',
							code: '5D',
							description: 'DSQUARED2 GREEN',
							sequence: 4,
							valid: true,
							parent: 'category_ORD_50',
							hierarchy: ['category_ORD', 'category_ORD_50'],
							children: [
								'category_ORD_50_5D_5D07',
								'category_ORD_50_5D_5D08',
								'category_ORD_50_5D_5D10',
								'category_ORD_50_5D_5D16',
								'category_ORD_50_5D_5D23',
								'category_ORD_50_5D_5D27',
								'category_ORD_50_5D_5D50',
								'category_ORD_50_5D_5D60',
								'category_ORD_50_5D_5D62',
								'category_ORD_50_5D_5D64',
								'category_ORD_50_5D_5D80',
								'category_ORD_50_5D_5Z366'
							]
						}
					]
				},
				{
					_id: 'category_ORD_03',
					_rev: '17-82828555a16b1ac25263834113bdc585',
					type: 'category',
					level: 'brand',
					code: '03',
					description: 'REPORTER',
					sequence: 5,
					valid: true,
					parent: 'category_ORD',
					hierarchy: ['category_ORD'],
					children: ['category_ORD_03_33', 'category_ORD_03_31'],
					category_list: [
						{
							_id: 'category_ORD_03_31',
							_rev: '17-dc68369fa8235557414761d4da7ece41',
							type: 'category',
							level: 'line',
							code: '31',
							description: 'THE FIRST',
							sequence: 1,
							valid: true,
							parent: 'category_ORD_03',
							hierarchy: ['category_ORD', 'category_ORD_03'],
							children: [
								'category_ORD_03_31_3108',
								'category_ORD_03_31_3110',
								'category_ORD_03_31_3112',
								'category_ORD_03_31_3127',
								'category_ORD_03_31_3160',
								'category_ORD_03_31_3163',
								'category_ORD_03_31_3166',
								'category_ORD_03_31_3180'
							]
						},
						{
							_id: 'category_ORD_03_33',
							_rev: '17-880c14353eca7b2c25eb10cc9df08b8d',
							type: 'category',
							level: 'line',
							code: '33',
							description: 'FOR MEN',
							sequence: 2,
							valid: true,
							parent: 'category_ORD_03',
							hierarchy: ['category_ORD', 'category_ORD_03'],
							children: [
								'category_ORD_03_33_3307',
								'category_ORD_03_33_3310',
								'category_ORD_03_33_3365',
								'category_ORD_03_33_3380',
								'category_ORD_03_33_3312',
								'category_ORD_03_33_3316',
								'category_ORD_03_33_3320',
								'category_ORD_03_33_3308',
								'category_ORD_03_33_3323',
								'category_ORD_03_33_3327',
								'category_ORD_03_33_3360',
								'category_ORD_03_33_3362',
								'category_ORD_03_33_3364'
							]
						}
					]
				},
				{
					_id: 'category_ORD_04',
					_rev: '17-040ba5132f5aa66f7583d4900a6d0527',
					type: 'category',
					level: 'brand',
					code: '04',
					description: 'NAJ-OLEARI COSMETICS',
					sequence: 6,
					valid: true,
					parent: 'category_ORD',
					hierarchy: ['category_ORD'],
					children: ['category_ORD_04_58', 'category_ORD_04_SET'],
					category_list: [
						{
							_id: 'category_ORD_04_58',
							_rev: '17-070962425b95b121feddebf5d1d34fff',
							type: 'category',
							level: 'line',
							code: '58',
							description: 'NAJ OLEARI ITALIAN BEAUTY',
							sequence: 0,
							valid: true,
							parent: 'category_ORD_04',
							hierarchy: ['category_ORD', 'category_ORD_04'],
							children: [
								'category_ORD_04_58_580101',
								'category_ORD_04_58_582901',
								'category_ORD_04_58_582902',
								'category_ORD_04_58_582903',
								'category_ORD_04_58_582904',
								'category_ORD_04_58_582905',
								'category_ORD_04_58_582906',
								'category_ORD_04_58_582907',
								'category_ORD_04_58_582908',
								'category_ORD_04_58_582909',
								'category_ORD_04_58_582910',
								'category_ORD_04_58_582911',
								'category_ORD_04_58_582912',
								'category_ORD_04_58_586201',
								'category_ORD_04_58_586202',
								'category_ORD_04_58_586203',
								'category_ORD_04_58_586204',
								'category_ORD_04_58_580201',
								'category_ORD_04_58_581701',
								'category_ORD_04_58_581702',
								'category_ORD_04_58_581703',
								'category_ORD_04_58_581704',
								'category_ORD_04_58_581705',
								'category_ORD_04_58_581706',
								'category_ORD_04_58_581707',
								'category_ORD_04_58_581708',
								'category_ORD_04_58_581709',
								'category_ORD_04_58_581710',
								'category_ORD_04_58_580202',
								'category_ORD_04_58_581711',
								'category_ORD_04_58_581712',
								'category_ORD_04_58_580203',
								'category_ORD_04_58_580204',
								'category_ORD_04_58_580301',
								'category_ORD_04_58_588001',
								'category_ORD_04_58_580302',
								'category_ORD_04_58_580303',
								'category_ORD_04_58_585301',
								'category_ORD_04_58_585302',
								'category_ORD_04_58_585303',
								'category_ORD_04_58_585304',
								'category_ORD_04_58_585305',
								'category_ORD_04_58_585306',
								'category_ORD_04_58_582401',
								'category_ORD_04_58_581101',
								'category_ORD_04_58_587001',
								'category_ORD_04_58_587002',
								'category_ORD_04_58_587003',
								'category_ORD_04_58_587004',
								'category_ORD_04_58_587005',
								'category_ORD_04_58_587006',
								'category_ORD_04_58_587007',
								'category_ORD_04_58_587008',
								'category_ORD_04_58_587009',
								'category_ORD_04_58_587010',
								'category_ORD_04_58_581102',
								'category_ORD_04_58_587011',
								'category_ORD_04_58_587012',
								'category_ORD_04_58_587013',
								'category_ORD_04_58_587014',
								'category_ORD_04_58_587015',
								'category_ORD_04_58_587016',
								'category_ORD_04_58_587017',
								'category_ORD_04_58_587018',
								'category_ORD_04_58_587019',
								'category_ORD_04_58_587020',
								'category_ORD_04_58_581103',
								'category_ORD_04_58_587021',
								'category_ORD_04_58_587022',
								'category_ORD_04_58_587023',
								'category_ORD_04_58_587024',
								'category_ORD_04_58_587025',
								'category_ORD_04_58_587026',
								'category_ORD_04_58_587027',
								'category_ORD_04_58_587028',
								'category_ORD_04_58_587029',
								'category_ORD_04_58_587030',
								'category_ORD_04_58_587031',
								'category_ORD_04_58_587032',
								'category_ORD_04_58_587033',
								'category_ORD_04_58_587034',
								'category_ORD_04_58_587601',
								'category_ORD_04_58_587701',
								'category_ORD_04_58_587901',
								'category_ORD_04_58_589101',
								'category_ORD_04_58_581201',
								'category_ORD_04_58_589201',
								'category_ORD_04_58_589301',
								'category_ORD_04_58_589401',
								'category_ORD_04_58_589501',
								'category_ORD_04_58_589601',
								'category_ORD_04_58_588801',
								'category_ORD_04_58_589701',
								'category_ORD_04_58_589702',
								'category_ORD_04_58_589801',
								'category_ORD_04_58_599903',
								'category_ORD_04_58_581301',
								'category_ORD_04_58_581302',
								'category_ORD_04_58_581303',
								'category_ORD_04_58_581304',
								'category_ORD_04_58_599910',
								'category_ORD_04_58_599918',
								'category_ORD_04_58_599923',
								'category_ORD_04_58_599924',
								'category_ORD_04_58_5806100C',
								'category_ORD_04_58_599934',
								'category_ORD_04_58_58STP01',
								'category_ORD_04_58_583601',
								'category_ORD_04_58_583602',
								'category_ORD_04_58_581601',
								'category_ORD_04_58_581602',
								'category_ORD_04_58_581603',
								'category_ORD_04_58_581604',
								'category_ORD_04_58_581801',
								'category_ORD_04_58_581901',
								'category_ORD_04_58_580501',
								'category_ORD_04_58_580110',
								'category_ORD_04_58_580502',
								'category_ORD_04_58_580503',
								'category_ORD_04_58_582301',
								'category_ORD_04_58_582302',
								'category_ORD_04_58_582303',
								'category_ORD_04_58_582304',
								'category_ORD_04_58_582305',
								'category_ORD_04_58_582306',
								'category_ORD_04_58_582307',
								'category_ORD_04_58_582308',
								'category_ORD_04_58_580504',
								'category_ORD_04_58_580505',
								'category_ORD_04_58_582001',
								'category_ORD_04_58_582002',
								'category_ORD_04_58_582003',
								'category_ORD_04_58_582004',
								'category_ORD_04_58_582005',
								'category_ORD_04_58_582006',
								'category_ORD_04_58_582007',
								'category_ORD_04_58_582008',
								'category_ORD_04_58_582009',
								'category_ORD_04_58_582010',
								'category_ORD_04_58_580506',
								'category_ORD_04_58_582011',
								'category_ORD_04_58_582012',
								'category_ORD_04_58_582013',
								'category_ORD_04_58_582014',
								'category_ORD_04_58_582015',
								'category_ORD_04_58_582016',
								'category_ORD_04_58_582017',
								'category_ORD_04_58_582018',
								'category_ORD_04_58_582019',
								'category_ORD_04_58_582020',
								'category_ORD_04_58_582021',
								'category_ORD_04_58_583701',
								'category_ORD_04_58_583702',
								'category_ORD_04_58_583703',
								'category_ORD_04_58_585201',
								'category_ORD_04_58_585202',
								'category_ORD_04_58_585203',
								'category_ORD_04_58_585204',
								'category_ORD_04_58_585205',
								'category_ORD_04_58_585206',
								'category_ORD_04_58_585207',
								'category_ORD_04_58_585208',
								'category_ORD_04_58_585209',
								'category_ORD_04_58_585210',
								'category_ORD_04_58_583901',
								'category_ORD_04_58_583902',
								'category_ORD_04_58_583903',
								'category_ORD_04_58_583904',
								'category_ORD_04_58_583905',
								'category_ORD_04_58_583906',
								'category_ORD_04_58_583401',
								'category_ORD_04_58_582501',
								'category_ORD_04_58_583301',
								'category_ORD_04_58_583302',
								'category_ORD_04_58_583303',
								'category_ORD_04_58_583304',
								'category_ORD_04_58_583501',
								'category_ORD_04_58_580604',
								'category_ORD_04_58_582601',
								'category_ORD_04_58_580605',
								'category_ORD_04_58_580606',
								'category_ORD_04_58_582701',
								'category_ORD_04_58_5806101',
								'category_ORD_04_58_5806102',
								'category_ORD_04_58_584201',
								'category_ORD_04_58_584202',
								'category_ORD_04_58_584203',
								'category_ORD_04_58_5806103',
								'category_ORD_04_58_584101',
								'category_ORD_04_58_584102',
								'category_ORD_04_58_584103',
								'category_ORD_04_58_5806104',
								'category_ORD_04_58_589001',
								'category_ORD_04_58_5806105',
								'category_ORD_04_58_5806106',
								'category_ORD_04_58_583001',
								'category_ORD_04_58_583002',
								'category_ORD_04_58_583003',
								'category_ORD_04_58_580701',
								'category_ORD_04_58_580702',
								'category_ORD_04_58_580703',
								'category_ORD_04_58_585001',
								'category_ORD_04_58_585002',
								'category_ORD_04_58_585003',
								'category_ORD_04_58_585004',
								'category_ORD_04_58_585005',
								'category_ORD_04_58_585006',
								'category_ORD_04_58_585007',
								'category_ORD_04_58_585008',
								'category_ORD_04_58_585009',
								'category_ORD_04_58_585010',
								'category_ORD_04_58_585011',
								'category_ORD_04_58_585012',
								'category_ORD_04_58_581001',
								'category_ORD_04_58_581002',
								'category_ORD_04_58_581003',
								'category_ORD_04_58_581004',
								'category_ORD_04_58_581005',
								'category_ORD_04_58_581006',
								'category_ORD_04_58_581007',
								'category_ORD_04_58_581008',
								'category_ORD_04_58_581009',
								'category_ORD_04_58_581010',
								'category_ORD_04_58_581011',
								'category_ORD_04_58_581012',
								'category_ORD_04_58_581013',
								'category_ORD_04_58_581014',
								'category_ORD_04_58_581015',
								'category_ORD_04_58_581016',
								'category_ORD_04_58_581017',
								'category_ORD_04_58_581018',
								'category_ORD_04_58_581019',
								'category_ORD_04_58_581020',
								'category_ORD_04_58_584001',
								'category_ORD_04_58_584002',
								'category_ORD_04_58_584003',
								'category_ORD_04_58_584004',
								'category_ORD_04_58_584005',
								'category_ORD_04_58_584006',
								'category_ORD_04_58_584007',
								'category_ORD_04_58_584008',
								'category_ORD_04_58_584009',
								'category_ORD_04_58_584010',
								'category_ORD_04_58_584011',
								'category_ORD_04_58_584012',
								'category_ORD_04_58_580801',
								'category_ORD_04_58_580802',
								'category_ORD_04_58_580803'
							]
						},
						{
							_id: 'category_ORD_04_SET',
							_rev: '4-832e25bdeff051d1fea233e04701e4e6',
							type: 'category',
							level: 'category_1',
							code: 'SET',
							description: 'PROMOZIONE',
							sequence: 999,
							valid: true,
							parent: 'category_ORD_04',
							hierarchy: ['category_ORD', 'category_ORD_04'],
							children: ['category_ORD_04_SET_132']
						}
					]
				}
			]
		},
		{
			_id: 'category_SET_NWL',
			_rev: '15-82928117a7cb7ac9bf7b578ded3f3dca',
			type: 'category',
			level: 'root',
			code: 'SET_NWL',
			description: 'NAJ OLEARI - WONDER LOOK EYESHADOW PALETTE',
			sequence: 230,
			valid: true,
			children: ['category_SET_NWL_04'],
			category_list: [
				{
					_id: 'category_SET_NWL_04',
					_rev: '15-3f8fe6d2c79d2e0167db08cf69b780b4',
					type: 'category',
					level: 'brand',
					code: '04',
					description: 'NAJ-OLEARI COSMETICS',
					sequence: 6,
					valid: true,
					parent: 'category_SET_NWL',
					hierarchy: ['category_SET_NWL'],
					children: ['category_SET_NWL_04_NWL1', 'category_SET_NWL_04_NWL2'],
					category_list: [
						{
							_id: 'category_SET_NWL_04_NWL1',
							_rev: '15-bb4db5b583c7b3a065685eee1a391b3e',
							type: 'category',
							level: 'line',
							code: 'NWL1',
							description: 'NAJ OLEARI – WONDER LOOK EYESHADOW PALETTE – EYES',
							sequence: 231,
							valid: true,
							parent: 'category_SET_NWL_04',
							hierarchy: ['category_SET_NWL', 'category_SET_NWL_04'],
							children: [
								'category_SET_NWL_04_NWL1_583201',
								'category_SET_NWL_04_NWL1_583202',
								'category_SET_NWL_04_NWL1_583203'
							]
						},
						{
							_id: 'category_SET_NWL_04_NWL2',
							_rev: '15-38d184382994917bdc18840cc500f227',
							type: 'category',
							level: 'line',
							code: 'NWL2',
							description: 'NAJ OLEARI – WONDER LOOK EYESHADOW PALETTE – MATERIALE PROMOZIONALE',
							sequence: 232,
							valid: true,
							parent: 'category_SET_NWL_04',
							hierarchy: ['category_SET_NWL', 'category_SET_NWL_04'],
							children: [
								'category_SET_NWL_04_NWL2_593203',
								'category_SET_NWL_04_NWL2_593202',
								'category_SET_NWL_04_NWL2_592018',
								'category_SET_NWL_04_NWL2_592019',
								'category_SET_NWL_04_NWL2_592020',
								'category_SET_NWL_04_NWL2_592021',
								'category_SET_NWL_04_NWL2_59VG32',
								'category_SET_NWL_04_NWL2_593201',
								'category_SET_NWL_04_NWL2_592016',
								'category_SET_NWL_04_NWL2_592012',
								'category_SET_NWL_04_NWL2_592005',
								'category_SET_NWL_04_NWL2_592004',
								'category_SET_NWL_04_NWL2_592003',
								'category_SET_NWL_04_NWL2_592002',
								'category_SET_NWL_04_NWL2_592013',
								'category_SET_NWL_04_NWL2_592001'
							]
						}
					]
				}
			]
		},
		{
			_id: 'category_SET_NPE',
			_rev: '15-b4be0a5b01b117fab2e5d4e9f438e952',
			type: 'category',
			level: 'root',
			code: 'SET_NPE',
			description: 'NAJ OLEARI - COLLEZIONE PRIMAVERA / ESTATE 2020',
			sequence: 240,
			valid: true,
			children: ['category_SET_NPE_04'],
			category_list: [
				{
					_id: 'category_SET_NPE_04',
					_rev: '15-7a3872641b466d3376e2a973f02012cc',
					type: 'category',
					level: 'brand',
					code: '04',
					description: 'NAJ-OLEARI COSMETICS',
					sequence: 6,
					valid: true,
					parent: 'category_SET_NPE',
					hierarchy: ['category_SET_NPE'],
					children: [
						'category_SET_NPE_04_NPE1',
						'category_SET_NPE_04_NPE2',
						'category_SET_NPE_04_NPE3',
						'category_SET_NPE_04_NPE4',
						'category_SET_NPE_04_NPE5'
					],
					category_list: [
						{
							_id: 'category_SET_NPE_04_NPE1',
							_rev: '15-14c28b1f97fad2d93dfc13952ddc7644',
							type: 'category',
							level: 'line',
							code: 'NPE1',
							description: 'NAJ OLEARI – COLLEZIONE PRIMAVERA / ESTATE 2020 – VISO',
							sequence: 241,
							valid: true,
							parent: 'category_SET_NPE_04',
							hierarchy: ['category_SET_NPE', 'category_SET_NPE_04'],
							children: ['category_SET_NPE_04_NPE1_586801']
						},
						{
							_id: 'category_SET_NPE_04_NPE2',
							_rev: '15-ba8926ca41f045b1f145cacf487222d8',
							type: 'category',
							level: 'line',
							code: 'NPE2',
							description: 'NAJ OLEARI – COLLEZIONE PRIMAVERA / ESTATE 2020 – OCCHI',
							sequence: 242,
							valid: true,
							parent: 'category_SET_NPE_04',
							hierarchy: ['category_SET_NPE', 'category_SET_NPE_04'],
							children: ['category_SET_NPE_04_NPE2_586501', 'category_SET_NPE_04_NPE2_586502']
						},
						{
							_id: 'category_SET_NPE_04_NPE3',
							_rev: '15-9c3e90da0127bfd100dd9a5c98b06119',
							type: 'category',
							level: 'line',
							code: 'NPE3',
							description: 'NAJ OLEARI – COLLEZIONE PRIMAVERA / ESTATE 2020 – LABBRA',
							sequence: 243,
							valid: true,
							parent: 'category_SET_NPE_04',
							hierarchy: ['category_SET_NPE', 'category_SET_NPE_04'],
							children: [
								'category_SET_NPE_04_NPE3_586902',
								'category_SET_NPE_04_NPE3_586701',
								'category_SET_NPE_04_NPE3_586702',
								'category_SET_NPE_04_NPE3_586901'
							]
						},
						{
							_id: 'category_SET_NPE_04_NPE4',
							_rev: '15-5977d85b9faec3663cabafeac148dba2',
							type: 'category',
							level: 'line',
							code: 'NPE4',
							description: 'NAJ OLEARI – COLLEZIONE PRIMAVERA / ESTATE 2020 – MANI',
							sequence: 244,
							valid: true,
							parent: 'category_SET_NPE_04',
							hierarchy: ['category_SET_NPE', 'category_SET_NPE_04'],
							children: [
								'category_SET_NPE_04_NPE4_587102',
								'category_SET_NPE_04_NPE4_587101',
								'category_SET_NPE_04_NPE4_587103'
							]
						},
						{
							_id: 'category_SET_NPE_04_NPE5',
							_rev: '15-469b0b9b0bdd04d836f6c1ec3d1ebfd4',
							type: 'category',
							level: 'line',
							code: 'NPE5',
							description: 'NAJ OLEARI – COLLEZIONE PRIMAVERA / ESTATE 2020 – MATERIALE PROMOZIONALE',
							sequence: 245,
							valid: true,
							parent: 'category_SET_NPE_04',
							hierarchy: ['category_SET_NPE', 'category_SET_NPE_04'],
							children: [
								'category_SET_NPE_04_NPE5_5911',
								'category_SET_NPE_04_NPE5_59VG39',
								'category_SET_NPE_04_NPE5_599935',
								'category_SET_NPE_04_NPE5_599936'
							]
						}
					]
				}
			]
		},
		{
			_id: 'category_SET_NPM',
			_rev: '15-9787a467c290efb2bb94203fc5b92623',
			type: 'category',
			level: 'root',
			code: 'SET_NPM',
			description: 'NAJ OLEARI - PROMO MASCARA',
			sequence: 250,
			valid: true,
			children: ['category_SET_NPM_04'],
			category_list: [
				{
					_id: 'category_SET_NPM_04',
					_rev: '15-e96569921df1db80e01a39c2f5be9352',
					type: 'category',
					level: 'brand',
					code: '04',
					description: 'NAJ-OLEARI COSMETICS',
					sequence: 6,
					valid: true,
					parent: 'category_SET_NPM',
					hierarchy: ['category_SET_NPM'],
					children: ['category_SET_NPM_04_NPM1', 'category_SET_NPM_04_NPM2'],
					category_list: [
						{
							_id: 'category_SET_NPM_04_NPM1',
							_rev: '15-5850398a12c5842777808524f40a2d4a',
							type: 'category',
							level: 'line',
							code: 'NPM1',
							description: 'NAJ OLEARI – PROMO MASCARA - EYES',
							sequence: 251,
							valid: true,
							parent: 'category_SET_NPM_04',
							hierarchy: ['category_SET_NPM', 'category_SET_NPM_04'],
							children: ['category_SET_NPM_04_NPM1_580019', 'category_SET_NPM_04_NPM1_580020']
						},
						{
							_id: 'category_SET_NPM_04_NPM2',
							_rev: '15-4e8a572058c442366f67ecd7fbd557fc',
							type: 'category',
							level: 'line',
							code: 'NPM2',
							description: 'NAJ OLEARI – PROMO MASCARA – MATERIALE PROMOZIONALE',
							sequence: 252,
							valid: true,
							parent: 'category_SET_NPM_04',
							hierarchy: ['category_SET_NPM', 'category_SET_NPM_04'],
							children: ['category_SET_NPM_04_NPM2_599933']
						}
					]
				}
			]
		},
		{
			_id: 'category_SET_NMU',
			_rev: '15-199d70e828c1858bf22ef906b68b92e0',
			type: 'category',
			level: 'root',
			code: 'SET_NMU',
			description: 'NAJ OLEARI MAKE UP',
			sequence: 260,
			valid: true,
			children: ['category_SET_NMU_04'],
			category_list: [
				{
					_id: 'category_SET_NMU_04',
					_rev: '15-764c087c470963ba06bce0bc1430cd76',
					type: 'category',
					level: 'brand',
					code: '04',
					description: 'NAJ-OLEARI COSMETICS',
					sequence: 6,
					valid: true,
					parent: 'category_SET_NMU',
					hierarchy: ['category_SET_NMU'],
					children: [
						'category_SET_NMU_04_NMU1',
						'category_SET_NMU_04_NMU2',
						'category_SET_NMU_04_NMU3',
						'category_SET_NMU_04_NMU4',
						'category_SET_NMU_04_NMU5',
						'category_SET_NMU_04_NMU6'
					],
					category_list: [
						{
							_id: 'category_SET_NMU_04_NMU1',
							_rev: '15-84adef6ee9f00b8ade968c5c91f29894',
							type: 'category',
							level: 'category_1',
							code: 'NMU1',
							description: 'NAJ OLEARI MAKE UP – FACE',
							sequence: 261,
							valid: true,
							parent: 'category_SET_NMU_04',
							hierarchy: ['category_SET_NMU', 'category_SET_NMU_04'],
							children: [
								'category_SET_NMU_04_NMU1_580603',
								'category_SET_NMU_04_NMU1_580606',
								'category_SET_NMU_04_NMU1_580506',
								'category_SET_NMU_04_NMU1_580505',
								'category_SET_NMU_04_NMU1_580504',
								'category_SET_NMU_04_NMU1_580503',
								'category_SET_NMU_04_NMU1_580502',
								'category_SET_NMU_04_NMU1_580501',
								'category_SET_NMU_04_NMU1_580101',
								'category_SET_NMU_04_NMU1_580602',
								'category_SET_NMU_04_NMU1_580605',
								'category_SET_NMU_04_NMU1_580202',
								'category_SET_NMU_04_NMU1_581604',
								'category_SET_NMU_04_NMU1_580303',
								'category_SET_NMU_04_NMU1_581101',
								'category_SET_NMU_04_NMU1_581102',
								'category_SET_NMU_04_NMU1_581103',
								'category_SET_NMU_04_NMU1_581201',
								'category_SET_NMU_04_NMU1_581301',
								'category_SET_NMU_04_NMU1_580302',
								'category_SET_NMU_04_NMU1_581302',
								'category_SET_NMU_04_NMU1_581304',
								'category_SET_NMU_04_NMU1_583601',
								'category_SET_NMU_04_NMU1_583602',
								'category_SET_NMU_04_NMU1_581601',
								'category_SET_NMU_04_NMU1_581901',
								'category_SET_NMU_04_NMU1_581801',
								'category_SET_NMU_04_NMU1_581303',
								'category_SET_NMU_04_NMU1_581602',
								'category_SET_NMU_04_NMU1_580301',
								'category_SET_NMU_04_NMU1_580203',
								'category_SET_NMU_04_NMU1_5806101',
								'category_SET_NMU_04_NMU1_5806102',
								'category_SET_NMU_04_NMU1_5806103',
								'category_SET_NMU_04_NMU1_5806104',
								'category_SET_NMU_04_NMU1_5806105',
								'category_SET_NMU_04_NMU1_5806106',
								'category_SET_NMU_04_NMU1_580204',
								'category_SET_NMU_04_NMU1_580701',
								'category_SET_NMU_04_NMU1_580703',
								'category_SET_NMU_04_NMU1_580801',
								'category_SET_NMU_04_NMU1_580802',
								'category_SET_NMU_04_NMU1_580803',
								'category_SET_NMU_04_NMU1_580201',
								'category_SET_NMU_04_NMU1_580604',
								'category_SET_NMU_04_NMU1_580702',
								'category_SET_NMU_04_NMU1_581603'
							]
						},
						{
							_id: 'category_SET_NMU_04_NMU2',
							_rev: '15-150515f8edd5d511b0cb227164ce97da',
							type: 'category',
							level: 'category_1',
							code: 'NMU2',
							description: 'NAJ OLEARI MAKE UP – EYES',
							sequence: 262,
							valid: true,
							parent: 'category_SET_NMU_04',
							hierarchy: ['category_SET_NMU', 'category_SET_NMU_04'],
							children: [
								'category_SET_NMU_04_NMU2_583202',
								'category_SET_NMU_04_NMU2_580110',
								'category_SET_NMU_04_NMU2_583203',
								'category_SET_NMU_04_NMU2_582001',
								'category_SET_NMU_04_NMU2_582002',
								'category_SET_NMU_04_NMU2_582003',
								'category_SET_NMU_04_NMU2_582004',
								'category_SET_NMU_04_NMU2_582005',
								'category_SET_NMU_04_NMU2_582006',
								'category_SET_NMU_04_NMU2_582007',
								'category_SET_NMU_04_NMU2_582008',
								'category_SET_NMU_04_NMU2_582308',
								'category_SET_NMU_04_NMU2_582010',
								'category_SET_NMU_04_NMU2_582011',
								'category_SET_NMU_04_NMU2_582012',
								'category_SET_NMU_04_NMU2_582013',
								'category_SET_NMU_04_NMU2_582014',
								'category_SET_NMU_04_NMU2_582015',
								'category_SET_NMU_04_NMU2_582016',
								'category_SET_NMU_04_NMU2_582017',
								'category_SET_NMU_04_NMU2_582018',
								'category_SET_NMU_04_NMU2_582019',
								'category_SET_NMU_04_NMU2_582020',
								'category_SET_NMU_04_NMU2_582021',
								'category_SET_NMU_04_NMU2_582307',
								'category_SET_NMU_04_NMU2_582306',
								'category_SET_NMU_04_NMU2_582305',
								'category_SET_NMU_04_NMU2_582304',
								'category_SET_NMU_04_NMU2_582303',
								'category_SET_NMU_04_NMU2_582302',
								'category_SET_NMU_04_NMU2_582301',
								'category_SET_NMU_04_NMU2_583201',
								'category_SET_NMU_04_NMU2_582009',
								'category_SET_NMU_04_NMU2_585208',
								'category_SET_NMU_04_NMU2_584201',
								'category_SET_NMU_04_NMU2_583302',
								'category_SET_NMU_04_NMU2_583303',
								'category_SET_NMU_04_NMU2_583304',
								'category_SET_NMU_04_NMU2_583501',
								'category_SET_NMU_04_NMU2_582601',
								'category_SET_NMU_04_NMU2_582701',
								'category_SET_NMU_04_NMU2_583301',
								'category_SET_NMU_04_NMU2_584202',
								'category_SET_NMU_04_NMU2_584101',
								'category_SET_NMU_04_NMU2_584102',
								'category_SET_NMU_04_NMU2_584103',
								'category_SET_NMU_04_NMU2_589001',
								'category_SET_NMU_04_NMU2_583001',
								'category_SET_NMU_04_NMU2_583002',
								'category_SET_NMU_04_NMU2_584203',
								'category_SET_NMU_04_NMU2_582501',
								'category_SET_NMU_04_NMU2_583401',
								'category_SET_NMU_04_NMU2_583906',
								'category_SET_NMU_04_NMU2_583703',
								'category_SET_NMU_04_NMU2_585201',
								'category_SET_NMU_04_NMU2_585202',
								'category_SET_NMU_04_NMU2_585203',
								'category_SET_NMU_04_NMU2_585204',
								'category_SET_NMU_04_NMU2_585205',
								'category_SET_NMU_04_NMU2_585206',
								'category_SET_NMU_04_NMU2_585207',
								'category_SET_NMU_04_NMU2_585209',
								'category_SET_NMU_04_NMU2_585210',
								'category_SET_NMU_04_NMU2_583901',
								'category_SET_NMU_04_NMU2_583902',
								'category_SET_NMU_04_NMU2_583903',
								'category_SET_NMU_04_NMU2_583904',
								'category_SET_NMU_04_NMU2_583905',
								'category_SET_NMU_04_NMU2_583003',
								'category_SET_NMU_04_NMU2_583702',
								'category_SET_NMU_04_NMU2_583701'
							]
						},
						{
							_id: 'category_SET_NMU_04_NMU3',
							_rev: '15-8ec8a8c8ed31c7cbe19a4de32bbaa042',
							type: 'category',
							level: 'category_1',
							code: 'NMU3',
							description: 'NAJ OLEARI MAKE UP – LIPS',
							sequence: 263,
							valid: true,
							parent: 'category_SET_NMU_04',
							hierarchy: ['category_SET_NMU', 'category_SET_NMU_04'],
							children: [
								'category_SET_NMU_04_NMU3_584008',
								'category_SET_NMU_04_NMU3_582912',
								'category_SET_NMU_04_NMU3_582911',
								'category_SET_NMU_04_NMU3_582910',
								'category_SET_NMU_04_NMU3_582909',
								'category_SET_NMU_04_NMU3_582908',
								'category_SET_NMU_04_NMU3_582907',
								'category_SET_NMU_04_NMU3_582906',
								'category_SET_NMU_04_NMU3_582905',
								'category_SET_NMU_04_NMU3_582904',
								'category_SET_NMU_04_NMU3_582903',
								'category_SET_NMU_04_NMU3_582902',
								'category_SET_NMU_04_NMU3_582901',
								'category_SET_NMU_04_NMU3_584012',
								'category_SET_NMU_04_NMU3_584011',
								'category_SET_NMU_04_NMU3_585001',
								'category_SET_NMU_04_NMU3_586201',
								'category_SET_NMU_04_NMU3_586202',
								'category_SET_NMU_04_NMU3_586203',
								'category_SET_NMU_04_NMU3_586204',
								'category_SET_NMU_04_NMU3_585304',
								'category_SET_NMU_04_NMU3_585303',
								'category_SET_NMU_04_NMU3_585302',
								'category_SET_NMU_04_NMU3_585301',
								'category_SET_NMU_04_NMU3_581712',
								'category_SET_NMU_04_NMU3_581711',
								'category_SET_NMU_04_NMU3_581710',
								'category_SET_NMU_04_NMU3_585002',
								'category_SET_NMU_04_NMU3_581709',
								'category_SET_NMU_04_NMU3_581707',
								'category_SET_NMU_04_NMU3_581706',
								'category_SET_NMU_04_NMU3_581705',
								'category_SET_NMU_04_NMU3_581704',
								'category_SET_NMU_04_NMU3_581703',
								'category_SET_NMU_04_NMU3_581702',
								'category_SET_NMU_04_NMU3_581701',
								'category_SET_NMU_04_NMU3_581708',
								'category_SET_NMU_04_NMU3_584009',
								'category_SET_NMU_04_NMU3_585003',
								'category_SET_NMU_04_NMU3_585005',
								'category_SET_NMU_04_NMU3_581013',
								'category_SET_NMU_04_NMU3_581014',
								'category_SET_NMU_04_NMU3_581015',
								'category_SET_NMU_04_NMU3_581016',
								'category_SET_NMU_04_NMU3_581017',
								'category_SET_NMU_04_NMU3_581018',
								'category_SET_NMU_04_NMU3_581019',
								'category_SET_NMU_04_NMU3_581020',
								'category_SET_NMU_04_NMU3_584001',
								'category_SET_NMU_04_NMU3_584002',
								'category_SET_NMU_04_NMU3_584003',
								'category_SET_NMU_04_NMU3_584004',
								'category_SET_NMU_04_NMU3_584005',
								'category_SET_NMU_04_NMU3_584006',
								'category_SET_NMU_04_NMU3_584007',
								'category_SET_NMU_04_NMU3_581012',
								'category_SET_NMU_04_NMU3_581011',
								'category_SET_NMU_04_NMU3_581010',
								'category_SET_NMU_04_NMU3_584010',
								'category_SET_NMU_04_NMU3_585006',
								'category_SET_NMU_04_NMU3_585007',
								'category_SET_NMU_04_NMU3_585008',
								'category_SET_NMU_04_NMU3_585009',
								'category_SET_NMU_04_NMU3_585010',
								'category_SET_NMU_04_NMU3_582401',
								'category_SET_NMU_04_NMU3_585011',
								'category_SET_NMU_04_NMU3_585004',
								'category_SET_NMU_04_NMU3_585012',
								'category_SET_NMU_04_NMU3_581002',
								'category_SET_NMU_04_NMU3_581003',
								'category_SET_NMU_04_NMU3_581004',
								'category_SET_NMU_04_NMU3_581005',
								'category_SET_NMU_04_NMU3_581006',
								'category_SET_NMU_04_NMU3_581007',
								'category_SET_NMU_04_NMU3_581008',
								'category_SET_NMU_04_NMU3_581001',
								'category_SET_NMU_04_NMU3_581009',
								'category_SET_NMU_04_NMU3_585305',
								'category_SET_NMU_04_NMU3_585306'
							]
						},
						{
							_id: 'category_SET_NMU_04_NMU4',
							_rev: '15-f1e1b484100dc35ba16ce97dc3396de7',
							type: 'category',
							level: 'category_1',
							code: 'NMU4',
							description: 'NAJ OLEARI MAKE UP – NAILS',
							sequence: 264,
							valid: true,
							parent: 'category_SET_NMU_04',
							hierarchy: ['category_SET_NMU', 'category_SET_NMU_04'],
							children: [
								'category_SET_NMU_04_NMU4_587018',
								'category_SET_NMU_04_NMU4_587024',
								'category_SET_NMU_04_NMU4_587025',
								'category_SET_NMU_04_NMU4_587026',
								'category_SET_NMU_04_NMU4_587027',
								'category_SET_NMU_04_NMU4_587028',
								'category_SET_NMU_04_NMU4_587029',
								'category_SET_NMU_04_NMU4_587019',
								'category_SET_NMU_04_NMU4_587031',
								'category_SET_NMU_04_NMU4_587032',
								'category_SET_NMU_04_NMU4_587601',
								'category_SET_NMU_04_NMU4_587701',
								'category_SET_NMU_04_NMU4_587901',
								'category_SET_NMU_04_NMU4_587001',
								'category_SET_NMU_04_NMU4_589101',
								'category_SET_NMU_04_NMU4_587023',
								'category_SET_NMU_04_NMU4_587022',
								'category_SET_NMU_04_NMU4_587030',
								'category_SET_NMU_04_NMU4_587020',
								'category_SET_NMU_04_NMU4_587017',
								'category_SET_NMU_04_NMU4_587021',
								'category_SET_NMU_04_NMU4_587016',
								'category_SET_NMU_04_NMU4_587014',
								'category_SET_NMU_04_NMU4_587013',
								'category_SET_NMU_04_NMU4_587012',
								'category_SET_NMU_04_NMU4_587011',
								'category_SET_NMU_04_NMU4_587010',
								'category_SET_NMU_04_NMU4_587015',
								'category_SET_NMU_04_NMU4_587009',
								'category_SET_NMU_04_NMU4_587002',
								'category_SET_NMU_04_NMU4_587004',
								'category_SET_NMU_04_NMU4_587005',
								'category_SET_NMU_04_NMU4_587003',
								'category_SET_NMU_04_NMU4_587007',
								'category_SET_NMU_04_NMU4_587008',
								'category_SET_NMU_04_NMU4_587006'
							]
						},
						{
							_id: 'category_SET_NMU_04_NMU5',
							_rev: '15-610792a066279383f18b14001584cb3a',
							type: 'category',
							level: 'category_1',
							code: 'NMU5',
							description: 'NAJ OLEARI MAKE UP -ACCESSORIES',
							sequence: 265,
							valid: true,
							parent: 'category_SET_NMU_04',
							hierarchy: ['category_SET_NMU', 'category_SET_NMU_04'],
							children: [
								'category_SET_NMU_04_NMU5_589201',
								'category_SET_NMU_04_NMU5_589501',
								'category_SET_NMU_04_NMU5_589801',
								'category_SET_NMU_04_NMU5_589702',
								'category_SET_NMU_04_NMU5_589701',
								'category_SET_NMU_04_NMU5_588801',
								'category_SET_NMU_04_NMU5_589601',
								'category_SET_NMU_04_NMU5_589401'
							]
						},
						{
							_id: 'category_SET_NMU_04_NMU6',
							_rev: '15-db6388fa36b84ae56711275628bb0c08',
							type: 'category',
							level: 'category_1',
							code: 'NMU6',
							description: 'NAJ OLEARI MAKE UP – MATERIALE PROMOZIONALE',
							sequence: 266,
							valid: true,
							parent: 'category_SET_NMU_04',
							hierarchy: ['category_SET_NMU', 'category_SET_NMU_04'],
							children: [
								'category_SET_NMU_04_NMU6_599923',
								'category_SET_NMU_04_NMU6_590',
								'category_SET_NMU_04_NMU6_599924',
								'category_SET_NMU_04_NMU6_599910',
								'category_SET_NMU_04_NMU6_59SPT01',
								'category_SET_NMU_04_NMU6_599903',
								'category_SET_NMU_04_NMU6_599918'
							]
						}
					]
				}
			]
		},
		{
			_id: 'category_SET_NPT',
			_rev: '15-8150aedaee3574b9f870d050b7f4325f',
			type: 'category',
			level: 'root',
			code: 'SET_NPT',
			description: 'NAJ OLEARI - PROMO TERRA ',
			sequence: 270,
			valid: true,
			children: ['category_SET_NPT_04'],
			category_list: [
				{
					_id: 'category_SET_NPT_04',
					_rev: '15-fcbf186c4e03bc40403cafa770b71e42',
					type: 'category',
					level: 'brand',
					code: '04',
					description: 'NAJ-OLEARI COSMETICS',
					sequence: 6,
					valid: true,
					parent: 'category_SET_NPT',
					hierarchy: ['category_SET_NPT'],
					children: ['category_SET_NPT_04_NPT1', 'category_SET_NPT_04_NPT2'],
					category_list: [
						{
							_id: 'category_SET_NPT_04_NPT1',
							_rev: '16-e9a11eee164f32a960a83d816a5cd7a3',
							type: 'category',
							level: 'line',
							code: 'NPT1',
							description: 'NAJ OLEARI – PROMO TERRA 2020 – VISO',
							sequence: 271,
							valid: true,
							parent: 'category_SET_NPT_04',
							hierarchy: ['category_SET_NPT', 'category_SET_NPT_04'],
							children: ['category_SET_NPT_04_NPT1_584401', 'category_SET_NPT_04_NPT1_594401']
						},
						{
							_id: 'category_SET_NPT_04_NPT2',
							_rev: '15-badea99bad79fb47689bad8aec5f8933',
							type: 'category',
							level: 'line',
							code: 'NPT2',
							description: 'NAJ OLEARI – PROMO TERRA 2020 – MATERIALE PROMOZIONALE',
							sequence: 272,
							valid: true,
							parent: 'category_SET_NPT_04',
							hierarchy: ['category_SET_NPT', 'category_SET_NPT_04'],
							children: [
								'category_SET_NPT_04_NPT2_599921',
								'category_SET_NPT_04_NPT2_584402',
								'category_SET_NPT_04_NPT2_594402'
							]
						}
					]
				}
			]
		},
		{
			_id: 'category_SET_CHR',
			_rev: '15-a3056269763aa1c5126a5bac3e835907',
			type: 'category',
			level: 'root',
			code: 'SET_CHR',
			description: 'MOSCHNO - CHRISTMAS 2020',
			sequence: 320,
			valid: true,
			children: [
				'category_SET_CHR_06',
				'category_SET_CHR_80',
				'category_SET_CHR_50',
				'category_SET_CHR_03',
				'category_SET_CHR_V0'
			],
			category_list: [
				{
					_id: 'category_SET_CHR_V0',
					_rev: '15-c6904cd5b788fcc1c4f1cf404067cc9e',
					type: 'category',
					level: 'brand',
					code: 'V0',
					description: 'VERSACE',
					sequence: 1,
					valid: true,
					parent: 'category_SET_CHR',
					hierarchy: ['category_SET_CHR'],
					children: [
						'category_SET_CHR_V0_CHR50',
						'category_SET_CHR_V0_CHR51',
						'category_SET_CHR_V0_CHR07',
						'category_SET_CHR_V0_CHR78',
						'category_SET_CHR_V0_CHR75',
						'category_SET_CHR_V0_CHR70',
						'category_SET_CHR_V0_CHRS0',
						'category_SET_CHR_V0_CHR72',
						'category_SET_CHR_V0_CHR73',
						'category_SET_CHR_V0_CHR74',
						'category_SET_CHR_V0_CHRS4',
						'category_SET_CHR_V0_CHR7S'
					],
					category_list: [
						{
							_id: 'category_SET_CHR_V0_CHR50',
							_rev: '15-99f4a8c7d9fc1527bf129cb4893975c7',
							type: 'category',
							level: 'line',
							code: 'CHR50',
							description: 'VERSACE -  BRIGHT CRYSTAL',
							sequence: 333,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: []
						},
						{
							_id: 'category_SET_CHR_V0_CHR51',
							_rev: '15-5f65b4c973c5045a7d64c92dd0ca86ba',
							type: 'category',
							level: 'line',
							code: 'CHR51',
							description: 'VERSACE - BRIGHT CRYSTAL ABSOLU',
							sequence: 334,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: ['category_SET_CHR_V0_CHR51_51106001']
						},
						{
							_id: 'category_SET_CHR_V0_CHR07',
							_rev: '15-b2e79b43b27d4ebedbfc25010b4ee87c',
							type: 'category',
							level: 'line',
							code: 'CHR07',
							description: 'VERSACE - CRYSTAL NOIR',
							sequence: 336,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: []
						},
						{
							_id: 'category_SET_CHR_V0_CHR78',
							_rev: '15-ff360a22517cdb522ed5efbe0d796469',
							type: 'category',
							level: 'line',
							code: 'CHR78',
							description: 'VERSACE - VERSENSE',
							sequence: 337,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: ['category_SET_CHR_V0_CHR78_7800670', 'category_SET_CHR_V0_CHR78_78006003']
						},
						{
							_id: 'category_SET_CHR_V0_CHR75',
							_rev: '15-970f74733b4303e74924619ca71d34ff',
							type: 'category',
							level: 'line',
							code: 'CHR75',
							description: 'VERSACE - EROS POUR FEMME',
							sequence: 338,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: ['category_SET_CHR_V0_CHR75_7500680']
						},
						{
							_id: 'category_SET_CHR_V0_CHR70',
							_rev: '15-3326a417a07a3769d5394835a46b8abc',
							type: 'category',
							level: 'line',
							code: 'CHR70',
							description: 'VERSACE - POUR FEMME DYLAN BLUE',
							sequence: 339,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: [
								'category_SET_CHR_V0_CHR70_7020680',
								'category_SET_CHR_V0_CHR70_7020670',
								'category_SET_CHR_V0_CHR70_70206003'
							]
						},
						{
							_id: 'category_SET_CHR_V0_CHRS0',
							_rev: '15-c0d48d050654006b86d20b3dea30d935',
							type: 'category',
							level: 'line',
							code: 'CHRS0',
							description: 'VERSACE - MAN EAU FRAICHE',
							sequence: 340,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: []
						},
						{
							_id: 'category_SET_CHR_V0_CHR72',
							_rev: '15-467842a1281b3b7bd1b5f1b0a89fb80e',
							type: 'category',
							level: 'line',
							code: 'CHR72',
							description: 'VERSACE - POUR HOMME',
							sequence: 341,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: ['category_SET_CHR_V0_CHR72_72006003', 'category_SET_CHR_V0_CHR72_7200670']
						},
						{
							_id: 'category_SET_CHR_V0_CHR73',
							_rev: '15-ed2253700fc83eb6b637d3ee2d7903fe',
							type: 'category',
							level: 'line',
							code: 'CHR73',
							description: 'VERSACE - POUR HOMME DYLAN BLUE',
							sequence: 342,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: [
								'category_SET_CHR_V0_CHR73_7210680',
								'category_SET_CHR_V0_CHR73_7210670',
								'category_SET_CHR_V0_CHR73_72106003'
							]
						},
						{
							_id: 'category_SET_CHR_V0_CHR74',
							_rev: '15-5ddef7c9cba081debd37c06c2f196a50',
							type: 'category',
							level: 'line',
							code: 'CHR74',
							description: 'VERSACE - EROS POUR HOMME ',
							sequence: 343,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: [
								'category_SET_CHR_V0_CHR74_7400680',
								'category_SET_CHR_V0_CHR74_7400670',
								'category_SET_CHR_V0_CHR74_74006003'
							]
						},
						{
							_id: 'category_SET_CHR_V0_CHRS4',
							_rev: '15-9a2adb44447061268b6bf580517138fb',
							type: 'category',
							level: 'line',
							code: 'CHRS4',
							description: 'VERSACE - EROS FLAME ',
							sequence: 344,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: [
								'category_SET_CHR_V0_CHRS4_7410680',
								'category_SET_CHR_V0_CHRS4_7410670',
								'category_SET_CHR_V0_CHRS4_74106003'
							]
						},
						{
							_id: 'category_SET_CHR_V0_CHR7S',
							_rev: '15-b9c17abc28922794e054ceb25e9e9959',
							type: 'category',
							level: 'line',
							code: 'CHR7S',
							description: 'VERSACE - EROS POUR FEMME EDT',
							sequence: 345,
							valid: true,
							parent: 'category_SET_CHR_V0',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_V0'],
							children: ['category_SET_CHR_V0_CHR7S_7501670']
						}
					]
				},
				{
					_id: 'category_SET_CHR_06',
					_rev: '15-7fe94a9f7d21d93b6718193d1cc4be13',
					type: 'category',
					level: 'brand',
					code: '06',
					description: 'MOSCHINO',
					sequence: 2,
					valid: true,
					parent: 'category_SET_CHR',
					hierarchy: ['category_SET_CHR'],
					children: [
						'category_SET_CHR_06_CHR61',
						'category_SET_CHR_06_CHR60',
						'category_SET_CHR_06_CHR62',
						'category_SET_CHR_06_CHR63',
						'category_SET_CHR_06_CHR64'
					],
					category_list: [
						{
							_id: 'category_SET_CHR_06_CHR60',
							_rev: '15-77fdc00246b830d2bd13ea6e31068e66',
							type: 'category',
							level: 'line',
							code: 'CHR60',
							description: 'MOSCHINO - CHEAP AND CHIC',
							sequence: 321,
							valid: true,
							parent: 'category_SET_CHR_06',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_06'],
							children: ['category_SET_CHR_06_CHR60_610680', 'category_SET_CHR_06_CHR60_610670']
						},
						{
							_id: 'category_SET_CHR_06_CHR61',
							_rev: '15-6e1388092253ef3d1607650de6a76962',
							type: 'category',
							level: 'line',
							code: 'CHR61',
							description: 'MOSCHINO - CHEAP AND CHIC - I LOVE LOVE',
							sequence: 322,
							valid: true,
							parent: 'category_SET_CHR_06',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_06'],
							children: ['category_SET_CHR_06_CHR61_6A0680', 'category_SET_CHR_06_CHR61_6A0670']
						},
						{
							_id: 'category_SET_CHR_06_CHR62',
							_rev: '15-3ba0e3bbfd7c558a30b129c785ccf2bb',
							type: 'category',
							level: 'line',
							code: 'CHR62',
							description: 'MOSCHINO - GOLD FRESH COUTURE',
							sequence: 323,
							valid: true,
							parent: 'category_SET_CHR_06',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_06'],
							children: [
								'category_SET_CHR_06_CHR62_6S0680',
								'category_SET_CHR_06_CHR62_6S0690',
								'category_SET_CHR_06_CHR62_6S0670'
							]
						},
						{
							_id: 'category_SET_CHR_06_CHR63',
							_rev: '15-545c6dcb9eac407175aff149f41b6a93',
							type: 'category',
							level: 'line',
							code: 'CHR63',
							description: 'MOSCHINO - TOY 2 EDP',
							sequence: 324,
							valid: true,
							parent: 'category_SET_CHR_06',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_06'],
							children: [
								'category_SET_CHR_06_CHR63_6V0670',
								'category_SET_CHR_06_CHR63_6V0680',
								'category_SET_CHR_06_CHR63_6V0690'
							]
						},
						{
							_id: 'category_SET_CHR_06_CHR64',
							_rev: '15-ccba9322a73c60c06f27b6a340975476',
							type: 'category',
							level: 'line',
							code: 'CHR64',
							description: 'MOSCHINO - TOY BOY EDP',
							sequence: 325,
							valid: true,
							parent: 'category_SET_CHR_06',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_06'],
							children: [
								'category_SET_CHR_06_CHR64_6W0670',
								'category_SET_CHR_06_CHR64_6W0680',
								'category_SET_CHR_06_CHR64_6W0690'
							]
						}
					]
				},
				{
					_id: 'category_SET_CHR_80',
					_rev: '15-7c6e50a0bae820e9a8f39bffe4561899',
					type: 'category',
					level: 'brand',
					code: '80',
					description: 'MISSONI',
					sequence: 3,
					valid: true,
					parent: 'category_SET_CHR',
					hierarchy: ['category_SET_CHR'],
					children: ['category_SET_CHR_80_CHR80', 'category_SET_CHR_80_CHR81', 'category_SET_CHR_80_CHR82'],
					category_list: [
						{
							_id: 'category_SET_CHR_80_CHR80',
							_rev: '15-1ce623ed8ce0d4af1d142622dfc4b86c',
							type: 'category',
							level: 'line',
							code: 'CHR80',
							description: 'MISSONI - EDP',
							sequence: 326,
							valid: true,
							parent: 'category_SET_CHR_80',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_80'],
							children: ['category_SET_CHR_80_CHR80_8000680', 'category_SET_CHR_80_CHR80_8000670']
						},
						{
							_id: 'category_SET_CHR_80_CHR81',
							_rev: '15-0db148c813ba2b5a1a0293a7d86ca699',
							type: 'category',
							level: 'line',
							code: 'CHR81',
							description: 'MISSON - EAU DE PARFUM',
							sequence: 327,
							valid: true,
							parent: 'category_SET_CHR_80',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_80'],
							children: ['category_SET_CHR_80_CHR81_8200680', 'category_SET_CHR_80_CHR81_8200670']
						},
						{
							_id: 'category_SET_CHR_80_CHR82',
							_rev: '15-ea03b2db5e5f4135e4d121c24c6b2716',
							type: 'category',
							level: 'line',
							code: 'CHR82',
							description: 'MISSON - WAVE',
							sequence: 327,
							valid: true,
							parent: 'category_SET_CHR_80',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_80'],
							children: [
								'category_SET_CHR_80_CHR82_82106003',
								'category_SET_CHR_80_CHR82_8210670',
								'category_SET_CHR_80_CHR82_8210680'
							]
						}
					]
				},
				{
					_id: 'category_SET_CHR_50',
					_rev: '15-8e7918d2a4ede7028fd054ad9f498d72',
					type: 'category',
					level: 'brand',
					code: '50',
					description: 'DSQUARED2',
					sequence: 4,
					valid: true,
					parent: 'category_SET_CHR',
					hierarchy: ['category_SET_CHR'],
					children: [
						'category_SET_CHR_50_CHR5A',
						'category_SET_CHR_50_CHR5B',
						'category_SET_CHR_50_CHR5C',
						'category_SET_CHR_50_CHR5D'
					],
					category_list: [
						{
							_id: 'category_SET_CHR_50_CHR5A',
							_rev: '15-8366732dc1482beab457aa56138d23a5',
							type: 'category',
							level: 'line',
							code: 'CHR5A',
							description: 'DSQUARED2 - WOOD POUR FEMME',
							sequence: 328,
							valid: true,
							parent: 'category_SET_CHR_50',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_50'],
							children: [
								'category_SET_CHR_50_CHR5A_5A06003',
								'category_SET_CHR_50_CHR5A_5A0670',
								'category_SET_CHR_50_CHR5A_5A0680'
							]
						},
						{
							_id: 'category_SET_CHR_50_CHR5B',
							_rev: '15-5cf0cad85eda0bd6246a31ba5921d2c8',
							type: 'category',
							level: 'line',
							code: 'CHR5B',
							description: 'DSQUARED2 - WOOD POUR HOMME',
							sequence: 329,
							valid: true,
							parent: 'category_SET_CHR_50',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_50'],
							children: [
								'category_SET_CHR_50_CHR5B_5B06003',
								'category_SET_CHR_50_CHR5B_5B0670',
								'category_SET_CHR_50_CHR5B_5B0680'
							]
						},
						{
							_id: 'category_SET_CHR_50_CHR5C',
							_rev: '15-37c146e67023239c2bcedcab07a10dfa',
							type: 'category',
							level: 'line',
							code: 'CHR5C',
							description: 'DSQUARED2 - RED WOOD POUR FEMME',
							sequence: 330,
							valid: true,
							parent: 'category_SET_CHR_50',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_50'],
							children: [
								'category_SET_CHR_50_CHR5C_5C06003',
								'category_SET_CHR_50_CHR5C_5C0680',
								'category_SET_CHR_50_CHR5C_5C0670'
							]
						},
						{
							_id: 'category_SET_CHR_50_CHR5D',
							_rev: '15-124dff99781382dac24e7d264d59331e',
							type: 'category',
							level: 'line',
							code: 'CHR5D',
							description: 'DSQUARED2 - GREEN WOOD POUR HOMME',
							sequence: 331,
							valid: true,
							parent: 'category_SET_CHR_50',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_50'],
							children: [
								'category_SET_CHR_50_CHR5D_5D0680',
								'category_SET_CHR_50_CHR5D_5D0670',
								'category_SET_CHR_50_CHR5D_5D06003'
							]
						}
					]
				},
				{
					_id: 'category_SET_CHR_03',
					_rev: '15-6422d9b77f9de18dbdee52020d59068f',
					type: 'category',
					level: 'brand',
					code: '03',
					description: 'REPORTER',
					sequence: 5,
					valid: true,
					parent: 'category_SET_CHR',
					hierarchy: ['category_SET_CHR'],
					children: ['category_SET_CHR_03_CHR33'],
					category_list: [
						{
							_id: 'category_SET_CHR_03_CHR33',
							_rev: '15-ef58136d1ffac2dedb6db1ff3b363f4b',
							type: 'category',
							level: 'line',
							code: 'CHR33',
							description: 'REPORTER - FOR MEN',
							sequence: 332,
							valid: true,
							parent: 'category_SET_CHR_03',
							hierarchy: ['category_SET_CHR', 'category_SET_CHR_03'],
							children: ['category_SET_CHR_03_CHR33_330610']
						}
					]
				}
			]
		},
		{
			_id: 'category_NO_CC',
			_rev: '13-9095b18bb2855ec7109e262cf2e4c441',
			type: 'category',
			level: 'root',
			code: 'NO_CC',
			description: 'ARTICOLI FUORI CC',
			sequence: 999,
			valid: true,
			children: ['category_NO_CC_TEST'],
			category_list: [
				{
					_id: 'category_NO_CC_TEST',
					_rev: '13-3e2ed4adea7d41c9105f412c65fa632f',
					type: 'category',
					level: 'brand',
					code: 'TEST',
					description: 'CATEGORIA DI PROVA',
					sequence: 999,
					valid: true,
					parent: 'category_NO_CC',
					hierarchy: ['category_NO_CC'],
					children: ['category_NO_CC_TEST_KIT'],
					category_list: [
						{
							_id: 'category_NO_CC_TEST_KIT',
							_rev: '13-23e1e25ec22e410f9c921f3b31e95e7d',
							type: 'category',
							level: 'line',
							code: 'KIT',
							description: 'ARTICOLI KIT',
							sequence: 999,
							valid: true,
							parent: 'category_NO_CC_TEST',
							hierarchy: ['category_NO_CC', 'category_NO_CC_TEST'],
							children: [
								'category_NO_CC_TEST_KIT_120',
								'category_NO_CC_TEST_KIT_122',
								'category_NO_CC_TEST_KIT_106',
								'category_NO_CC_TEST_KIT_109'
							]
						}
					]
				}
			]
		}
	];
}
