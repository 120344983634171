import { NgModule } from "@angular/core";

// model
import { OrganizationListStateReducer } from "./organization-list.reducer";

// store
import { StoreModule } from "@ngrx/store";

@NgModule({
    imports: [
        StoreModule.forFeature('organization-list', OrganizationListStateReducer.reducer)
    ]
})
export class OrganizationListStateModule{}
