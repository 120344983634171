import { Inject, Injectable } from '@angular/core';
import { IAppConfig, IConfigModel } from '@saep-ict/env-configurator';
import { IMediaCenterConfigModel } from '@saep-ict/media-center';
import { ITicketCenterConfigModel } from '@saep-ict/ticket-center';
import { ConfigCouchModel, IPouchAppConfig, IPouchConfigModel } from '@saep-ict/pouch-db';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';

import { ContextApplicationItemCodeEnum, TokenPayload } from '@saep-ict/angular-core';
import { CompanyConfigModel, ConfigModel, PortalModel, UrlConfigModel } from './model/structure/environment-config.model';
import { ConfigurationCustomer } from './constants/configuration-customer';
import { Environment } from './enum/environment.enum';

@Injectable({
	providedIn: 'root'
})
export class CustomerAppConfig implements IPouchAppConfig<CustomerAppConfigModel>, IAppConfig<CustomerAppConfigModel> {
	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;

	@LocalStorage('authenticationToken')
	authenticationToken: string;

	get username(): string {
		return this._tokenPayload && this._tokenPayload.username;
	}
	get signature(): string {
		return this._tokenPayload && this._tokenPayload.signature;
	}
	get config(): CustomerAppConfigModel {
		return this.configSource.getValue();
	}
	set config(val: CustomerAppConfigModel) {
		this.configSource.next(val);
	}
	configSource = new BehaviorSubject<CustomerAppConfigModel>(undefined as CustomerAppConfigModel);
	config$ = this.configSource.asObservable();
	set tag(val: string) {
		const config = this.config;
		config.tag = val;
		this.config = config;
	}
	get token(): string {
		return this.authenticationToken;
	}

	// lib
	libToInitialize: string[] = ['media-center', 'ticket-center'];
	libInitialized: string[] = [];
	libInitializationDone = new BehaviorSubject<boolean>(false);
	libInitializationDone$ = this.libInitializationDone.asObservable();

	// lib - media-center
	private _mc_config: IMediaCenterConfigModel;
	set mc_config(val: IMediaCenterConfigModel) {
		this._mc_config = val;
	}
	get mc_config(): IMediaCenterConfigModel {
		return this._mc_config;
	}
	initializeConfig(media_center_config: IMediaCenterConfigModel): void {
		this.mc_config = media_center_config;
		this.isEveryLibInizialized('media-center');
	}

	// lib - ticket-center
	private _tc_config: ITicketCenterConfigModel;
	set tc_config(val: ITicketCenterConfigModel) {
		this._tc_config = val;
	}
	get tc_config(): ITicketCenterConfigModel {
		return this._tc_config;
	}
	initializeConfigTicketCenter(config: ITicketCenterConfigModel): void {
		this.tc_config = config;
		this.tc_config.customConfiguration = ConfigurationCustomer.TicketCenter.configuration;
		this.isEveryLibInizialized('ticket-center');
	}

	constructor(@Inject('env-config') private envConfig: CustomerAppConfigModel) {
		if (this.envConfig) {
			this.populateConfig(this.envConfig);
		}
	}

	populateConfig(configuration: CustomerAppConfigModel) {
		this.config = configuration;
	}

	isEveryLibInizialized(lib: string) {
		this.libInitialized.push(lib);
		if (this.libToInitialize.every(i => this.libInitialized.includes(i))) {
			this.libInitializationDone.next(true);
		}
	}
}

export class CustomerAppConfigModel implements IPouchConfigModel, IConfigModel, ConfigModel {
	couch: ConfigCouchModel[];
	company: CompanyConfigModel;
	permissionContext: ContextApplicationItemCodeEnum[];
	environment: Environment;
	urlConfig: UrlConfigModel;
	tag: string;
	enableOffline: boolean;
	portal: PortalModel; 
}
