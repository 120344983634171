import { Component, OnInit, OnDestroy } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// store
import { StateFeature } from '../../../../state';
import { Store } from '@ngrx/store';
import {
	ContextCodeAssociationStateAction,
	ContextCodeAssociationActionEnum
} from '../../../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { BackofficeUserListActionEnum } from '../../../../state/backoffice/context-code/backoffice-user-list/backoffice-user-list.actions';
import { AgentListActionEnum } from '../../../../state/backoffice/context-code/agent-list/agent-list.actions';
import { OrganizationListActionEnum } from '../../../../state/common/organization-list/organization-list.actions';

// widget & utility
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { PermissionContextListColumnService } from '../../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { DialogCreateContextCodeComponent } from '../../../../widget/dialog/dialog-create-context-code/dialog-create-context-code.component';
import { DialogAddContextCodeComponent } from '../../../../widget/dialog/dialog-add-context-code/dialog-add-context-code.component';
import {
	SubscribeManagerService,
	BaseStateModel,
	BaseState,
	DialogConfirmComponent,
	ContextCodeAssociationItem,
	ContextApplicationItemCodeEnum,
	ContextOtherItemCodeEnum,
	UtilService,
	ContextTypeAndApplicationLink,
	ContextCodeAssociation,
	ContextCodeItemLink,
	ContextCodeItem
} from '@saep-ict/angular-core';
import { TitleCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { B2cUserListActionEnum } from '../../../../state/backoffice/context-code/b2c-user-list/b2c-user-list.actions';
import { CrmUserListActionEnum } from '../../../../state/backoffice/context-code/crm-user-list/crm-user-list.actions';
import { contextTypeAndApplicationLink } from '../../../../constants/configuration-customer/context-application/context-application.constants';
import * as _ from 'lodash';
import { ROUTE_URL } from '../../../../router/route-naming';

@Component({
	selector: 'context-code-management-detail',
	templateUrl: './context-code-management-detail.component.html',
	styleUrls: ['./context-code-management-detail.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class ContextCodeManagementDetailComponent implements OnInit, OnDestroy {
	contextCodeAssociationState$: Observable<BaseStateModel<ContextCodeAssociation>> = this.store.select(
		StateFeature.getContextCodeAssociationState
	);
	contextTypeAndApplicationLink: ContextTypeAndApplicationLink;
	contextCodeItem = <ContextCodeItem>{};
	contextCodeAssociation = <ContextCodeAssociation>{
		type: 'context_code_association'
	};
	form: FormGroup;

	formEditEnabled = false;
	ROUTE_URL = ROUTE_URL;

	constructor(
		private router: Router,
		public route: ActivatedRoute,
		private fb: FormBuilder,
		public utilService: UtilService,
		public permissionUtilService: PermissionUtilService,
		private subscribeManagerService: SubscribeManagerService,
		public permissionContextListColumnService: PermissionContextListColumnService,
		private snackBar: MatSnackBar,
		private store: Store<any>,
		private dialog: MatDialog,
		private titleCasePipe: TitleCasePipe,
		private translate: TranslateService
	) {
		this.setVisibleTableColumn();
		this.createForm();
		this.subscribeManagerService.populate(this.routeParamSubscribe().subscribe(), 'route-param-state');
		this.subscribeManagerService.populate(
			this.contextCodeAssociationSubscribe().subscribe(),
			'context-code-association-state'
		);
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
	}

	ngOnInit() {}

	printContextCodeList() {
		// this.contextTypeAndApplicationLink.context_code_item.association_type.forEach(context => {
		// console.log('Context: ', context);
		// const contextItem = this.permissionUtilService.getContextItem(
		// 	this.getFormFieldValue('context_code_association_list'),
		// 	context.code
		// );
		// console.log('getContextItem: ', contextItem);
		// console.log('contextCodeItem: ', this.contextCodeItem);
		// });
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(ContextCodeAssociationStateAction.reset());
		this.permissionUtilService.resetContextCodeListAssociatedState();
	}

	editMode() {
		this.formEditEnabled = true;
	}

	/**
	 * Subscription
	 */

	routeParamSubscribe(): Observable<ContextTypeAndApplicationLink> {
		return this.route.paramMap.pipe(
			map((routeParam: ParamMap) => {
				this.contextTypeAndApplicationLink = contextTypeAndApplicationLink.find(
					i =>
						i.context_application_item_code ===
						ContextApplicationItemCodeEnum[routeParam.get('contextApplicationType').toUpperCase()]
				);
				if (this.contextTypeAndApplicationLink) {
					this.contextCodeItem.code = routeParam.get('contextCode');

					this.store.dispatch(
						ContextCodeAssociationStateAction.load(
							new BaseState({ _id: `context_code_association_${this.contextCodeItem.code}` })
						)
					);
					this.store.dispatch(
						this.contextTypeAndApplicationLink.context_code_item.action.loadItem(
							new BaseState({
								_id: `${this.contextTypeAndApplicationLink.context_code_item.couch_document_key}_${this.contextCodeItem.code}`
							})
						)
					);
				} else {
					// TODO: gestire l'accidentale inserimento di un errato parametro contextApplicationType in URL
					this.utilService.showDialog(this.translate.instant('context_code.not_found'));
				}
				return this.contextTypeAndApplicationLink;
			})
		);
	}

	contextCodeAssociationSubscribe(): Observable<ContextCodeAssociation> {
		return this.contextCodeAssociationState$.pipe(
			filter((state: BaseStateModel<ContextCodeAssociation>) => (state ? true : false && state.type)),
			map((state: BaseStateModel<ContextCodeAssociation>) => {
				switch (state.type) {
					case ContextCodeAssociationActionEnum.UPDATE:
						this.contextCodeAssociation = state.data;
						this.setFormFromRemoteData();
						this.printContextCodeList();
						break;
					case ContextCodeAssociationActionEnum.SAVE_SUCCESS:
						this.utilService.showDialog(this.translate.instant('context_code.saved'));
						break;
					case ContextCodeAssociationActionEnum.DELETE_ITEM_SUCCESS:
						this.utilService.showDialog(this.translate.instant('context_code.saved'));
						this.contextCodeAssociation = null;
						break;
					default:
						break;
				}
				return this.contextCodeAssociation;
			})
		);
	}

	subscribeContextCodeList(): Observable<BaseStateModel<ContextCodeItem[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				switch (state.type) {
					case BackofficeUserListActionEnum.UPDATE_ITEM:
					case AgentListActionEnum.UPDATE_ITEM:
					case OrganizationListActionEnum.UPDATE_ITEM:
					case B2cUserListActionEnum.UPDATE_ITEM:
					case CrmUserListActionEnum.UPDATE_ITEM:
						this.contextCodeItem = state.data[0];
						break;

					case BackofficeUserListActionEnum.DELETE_ITEM_SUCCESS:
					case AgentListActionEnum.DELETE_ITEM_SUCCESS:
					case OrganizationListActionEnum.DELETE_ITEM_SUCCESS:
					case B2cUserListActionEnum.DELETE_ITEM_SUCCESS:
					case CrmUserListActionEnum.DELETE_ITEM_SUCCESS:
						if (this.contextCodeAssociation && this.contextCodeAssociation.code_item) {
							this.store.dispatch(
								ContextCodeAssociationStateAction.deleteItem(new BaseState(this.contextCodeAssociation))
							);
						}
						const contextCodeDescription = this.titleCasePipe.transform(
							this.utilService.formatPlaceholder(this.contextTypeAndApplicationLink.application_name)
						);
						this.utilService.showDialog(
							this.translate.instant('context_code.deleted', {
								application_name: contextCodeDescription
							})
						);
						this.router.navigate(['../'], { relativeTo: this.route });
						break;
				}
				return state;
			})
		);
	}

	/**
	 * Form
	 */

	createForm() {
		this.form = this.fb.group({
			context_code_association_list: [[]]
		});
	}

	setFormFromRemoteData() {
		this.form.setValue(
			{
				context_code_association_list:
					this.contextCodeAssociation && this.contextCodeAssociation.context_code_association_list
						? this.contextCodeAssociation && this.contextCodeAssociation.context_code_association_list
						: []
			},
			{
				emitEvent: false
			}
		);
	}

	/**
	 * Restituisce il valore del form field associato alla key passatagli
	 *
	 * @param {string} key
	 * @returns
	 * @memberof BackofficeUserDetailComponent
	 */
	getFormFieldValue(key: string) {
		return this.form.get(key).value;
	}

	/**
	 * Aggiorna il valore del form field associato alla key passatagli
	 *
	 * @param {string} key
	 * @param {*} value
	 * @memberof ContextCodeDetailComponent
	 */
	updateFormFieldValue(key: string, value: any) {
		const formField = {};
		formField[key] = _.cloneDeep(value);
		this.form.patchValue(formField);
	}

	/**
	 * Associa un context code
	 * @param context_application_item_code
	 * @param contextCodeItem
	 */
	addContextCodeItemToFormContextCodeAssociationListItem(
		context_application_item_code: ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum,
		contextCodeItem: ContextCodeItem
	) {
		let returnContextApplicationItem: ContextCodeAssociationItem = this.permissionUtilService.getContextItem(
			this.getFormFieldValue('context_code_association_list'),
			context_application_item_code
		);
		if (!(returnContextApplicationItem && returnContextApplicationItem.context_code_list)) {
			returnContextApplicationItem = {
				code: context_application_item_code,
				context_code_list: []
			};
		}

		returnContextApplicationItem.context_code_list[
			returnContextApplicationItem.context_code_list.length
		] = contextCodeItem;

		const updatedContextListValue = this.permissionUtilService.returnUpdatedContextListValue(
			this.getFormFieldValue('context_code_association_list'),
			context_application_item_code,
			returnContextApplicationItem
		);
		this.updateFormFieldValue(`context_code_association_list`, updatedContextListValue);
	}

	prepareSaveForm(): ContextCodeAssociation {
		const formModel: ContextCodeAssociation = this.form.value;
		const contextCodeAssociation = <ContextCodeAssociation>{
			_id: 'context_code_association_' + this.contextCodeItem.code,
			_rev:
				this.contextCodeAssociation && this.contextCodeAssociation._rev
					? this.contextCodeAssociation._rev
					: null,
			type: 'context_code_association',
			code_item: this.contextCodeItem.code,
			context_code_association_list: []
		};
		for (let i = 0; i < formModel.context_code_association_list.length; i++) {
			formModel.context_code_association_list[i] = this.utilService.deleteEmptyProperties(
				formModel.context_code_association_list[i]
			);
		}
		contextCodeAssociation.context_code_association_list = formModel.context_code_association_list.filter(
			i => i.context_code_list || i.context_code_list_all
		);
		return this.utilService.deleteEmptyProperties(contextCodeAssociation);
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.formEditEnabled = false;
			const contextCodeAssociation: ContextCodeAssociation = this.prepareSaveForm();

			if (contextCodeAssociation.context_code_association_list) {
				this.store.dispatch(ContextCodeAssociationStateAction.save(new BaseState(contextCodeAssociation)));
			} else if (!contextCodeAssociation.context_code_association_list && this.contextCodeAssociation.code_item) {
				this.store.dispatch(
					ContextCodeAssociationStateAction.deleteItem(new BaseState(contextCodeAssociation))
				);
			}
		}
	}

	/**
	 * Dialog
	 */

	// Associa code
	dialogAssociateContextCodeItem(context_code_item: ContextCodeItemLink) {
		const contextCodeAssociationItem = <ContextCodeAssociationItem>(
			this.permissionUtilService.getContextItem(
				this.getFormFieldValue('context_code_association_list'),
				context_code_item.code
			)
		);
		context_code_item.already_associated_context_code_list = [];
		if (
			contextCodeAssociationItem &&
			contextCodeAssociationItem.context_code_list &&
			contextCodeAssociationItem.context_code_list.length > 0
		) {
			contextCodeAssociationItem.context_code_list.forEach(i => {
				context_code_item.already_associated_context_code_list.push(i.code);
			});
		}

		const dialogRef: MatDialogRef<DialogAddContextCodeComponent> = this.dialog.open(DialogAddContextCodeComponent, {
			data: context_code_item,
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.addContextCodeItemToFormContextCodeAssociationListItem(context_code_item.code, res);
			}
		});
	}

	// Associa tutti i code
	dialogAssociateAllContextCodeItem(context_code_item: ContextCodeItemLink) {
		const contextCodeDescription = this.titleCasePipe.transform(
			this.utilService.formatPlaceholder(context_code_item.description)
		);
		const title = this.translate.instant('context_code.associate_all_x', {
			context_code: contextCodeDescription
		});
		const question = this.translate.instant('context_code.question.associate_all_x', {
			context_code: contextCodeDescription
		});

		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let contextApplicationItem = {
					code: context_code_item.code,
					context_code_list: [],
					context_code_list_all: true
				};

				let value = this.permissionUtilService.returnUpdatedContextListValue(
					this.getFormFieldValue('context_code_association_list'),
					context_code_item.code,
					contextApplicationItem
				);

				console.log(value);

				this.updateFormFieldValue(`context_code_association_list`, value);
			}
		});
	}

	// Crea code
	dialogCreateContextCodeItem(context_code_item: ContextCodeItemLink) {
		const dialogRef: MatDialogRef<DialogCreateContextCodeComponent> = this.dialog.open(
			DialogCreateContextCodeComponent,
			{
				data: context_code_item,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		);
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.addContextCodeItemToFormContextCodeAssociationListItem(context_code_item.code, res);
			}
		});
	}

	// Cancella code
	dialogConfirmDeleteContextCode() {
		const contextCodeDescription = this.titleCasePipe.transform(
			this.utilService.formatPlaceholder(this.contextTypeAndApplicationLink.context_code_item.description)
		);

		const contextCodeItemDescription = this.titleCasePipe.transform(
			this.utilService.formatPlaceholder(this.contextCodeItem.description)
		);

		const title = this.translate.instant('context_code.delete_item_x', {
			context_code_item: contextCodeDescription
		});
		const question = this.translate.instant('context_code.question.delete_item_x', {
			context_code_item_description: contextCodeItemDescription,
			context_code_item_description_code: this.contextCodeItem.code
		});

		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.store.dispatch(
					this.contextTypeAndApplicationLink.context_code_item.action.deleteItem(
						new BaseState(this.contextCodeItem)
					)
				);
			}
		});
	}

	// Disassocia code list
	dialogConfirmDeleteAssociatedContextCodeList(context_code_item: ContextCodeItemLink) {
		const contextCodeDescription = this.titleCasePipe.transform(
			this.utilService.formatPlaceholder(context_code_item.description)
		);
		const title = this.translate.instant('context_code.disassociate_all_x', {
			context_code: contextCodeDescription
		});
		const question = this.translate.instant('context_code.question.disassociate_all_x', {
			context_code: contextCodeDescription
		});

		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let contextApplicationItem = {
					code: context_code_item.code,
					context_code_list: []
				};

				this.updateFormFieldValue(
					`context_code_association_list`,
					this.permissionUtilService.returnUpdatedContextListValue(
						this.getFormFieldValue('context_code_association_list'),
						context_code_item.code,
						contextApplicationItem
					)
				);
			}
		});
	}

	// Disassocia code
	dialogConfirmDeleteAssociatedContextCodeItem(context_code_item: ContextCodeItemLink, context_code: string) {
		const contextCodeItemDescription = this.titleCasePipe.transform(this.contextCodeItem.description);
		const contextCodeDescription = this.titleCasePipe.transform(
			this.utilService.formatPlaceholder(context_code_item.description)
		);

		const title = this.translate.instant('context_code.disassociate_x', {
			context_code: contextCodeDescription,
			context_code_item: context_code
		});
		const question = this.translate.instant('context_code.question.disassociate_x', {
			context_code_description: contextCodeDescription,
			subject_description: contextCodeItemDescription
		});

		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question
			},
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const contextApplicationItem = <ContextCodeAssociationItem>(
					this.permissionUtilService.getContextItem(
						this.getFormFieldValue('context_code_association_list'),
						context_code_item.code
					)
				);
				contextApplicationItem.context_code_list.splice(
					this.utilService.getElementIndex(contextApplicationItem.context_code_list, 'code', context_code),
					1
				);
				this.updateFormFieldValue(
					`context_code_association_list`,
					this.permissionUtilService.returnUpdatedContextListValue(
						this.getFormFieldValue('context_code_association_list'),
						context_code_item.code,
						contextApplicationItem
					)
				);
			}
		});
	}

	// widget & utility
	setVisibleTableColumn() {
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'permission')
		].hidden = true;
	}
}
