import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../../../app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccessRequestService } from '../../../../service/rest/access-request.service';
import { AccessRequestModel } from '../../../../model/access-request.model';
// widget
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { ROUTE_URL } from '../../../../router/route-naming';
import { UtilEventTrackingService } from '../../../../service/util/util-event-tracking.service';

@Component({
	selector: 'access-request',
	templateUrl: './access-request.component.html',
	styleUrls: ['./access-request.component.scss'],
	providers: [AccessRequestService, AppConfig]
})
export class AccessRequestComponent implements OnInit {
	@ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

	form: FormGroup;
	logo = '../logo/SPIN8/v2/logo-SPIN8-esales-business.svg';

	ROUTE_URL = ROUTE_URL;

	constructor(
		private appConfig: AppConfig,
		private fb: FormBuilder,
		private router: Router,
		private accessRequestService: AccessRequestService,
		private snackbar: MatSnackBar,
		private translateService: TranslateService, // private http: HttpClient
		private sentencecasePipe: SentencecasePipe,
		private utilEventTrackingService: UtilEventTrackingService
	) {
		if (this.appConfig.config.company && this.appConfig.config.company.logo) {
			this.logo = this.appConfig.config.company.logo;
		}

		this.createForm();
	}

	ngOnInit() {}

	// form
	createForm() {
		this.form = this.fb.group({
			// address: [null, Validators.required],
			// client_code: [null],
			// piva: [null, Validators.required],
			// reference: [null, Validators.required],
			business_name: [null, Validators.required],
			captcha: [null, Validators.required],
			city: [null, Validators.required],
			country: [null, Validators.required],
			department: [null, Validators.required],
			email: [null, [Validators.email, Validators.required]],
			full_name: [null, Validators.required],
			phone: [null, Validators.required],
			privacyCheck: [null, Validators.required]
		});
	}

	prepareSaveForm() {
		const formModel = this.form.value;

		const saveForm: AccessRequestModel = {
			// address: formModel.address as string,
			// code: formModel.client_code as string,
			// piva: formModel.piva as string,
			// reference: formModel.reference as string,
			business_name: formModel.business_name as string,
			captcha: formModel.captcha as string,
			city: formModel.city as string,
			country: formModel.country as string,
			department: formModel.department as string,
			email: formModel.email as string,
			full_name: formModel.full_name as string,
			phone: formModel.phone as string,
			privacyCheck: formModel.privacyCheck as boolean
		};
		return saveForm;
	}

	recaptchaResponse(recaptchaResponse: string) {
		this.form.controls['captcha'].setValue(recaptchaResponse);
	}

	sendAccessRequest() {
		if (this.form.valid) {
			const saveRequest: AccessRequestModel = this.prepareSaveForm();
			const message = this.sentencecasePipe.transform(
				this.translateService.instant('login.access_request.success')
			);
			this.accessRequestService
				.accessRequest(saveRequest)
				.then(res => {
					this.utilEventTrackingService.pushEvent({ event: 'richiestaAccessoPortale' });
					this.snackbar.open(message, 'OK', { duration: 3000 });
					this.router.navigate(['/authentication/login']);
				})
				.catch(err => {
					this.snackbar.open(err.body.message, this.translateService.instant('general.close').toUpperCase(), {
						duration: 3000
					});
				});
		} else {
			Object.keys(this.form.controls).forEach(key => {
				this.form.controls[key].markAsDirty();
			});
		}
	}
}
