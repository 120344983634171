import { Component, ElementRef, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-quantity-button',
	templateUrl: './quantity-button.component.html',
	styleUrls: ['./quantity-button.component.scss']
})
export class QuantityButtonComponent implements OnInit {
	@ViewChild('productQty') productQty: ElementRef;
	@Input() packageSize = 1;
	@Input() value = 0;
	@Input() disabled = false;
	@Input() readonly = false;
	@Input() showButton = true;
	@Output() changeValue: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	changeQuantity(action: 'add' | 'sub') {
		let newValue = this.value;

		switch (action) {
			case 'add':
				// newValue = newValue + this.packageSize;
				newValue = newValue + 1;
				break;
			case 'sub':
				if (this.value > 0) {
					// newValue = newValue - this.packageSize;
					newValue = newValue - 1;
				}
				break;
		}

		this.value = newValue;
		this.changeValue.emit(newValue);
	}

	onBlur($event) {
		const quantity = +$event.target.value;
		if (quantity < 0) {
			this.value = 0;
		} else {
			// this.value = this.roundUpQuantity(quantity);
			this.value = quantity;
		}
		this.changeValue.emit(this.value);
	}

	roundUpQuantity(value: number) {
		return Math.ceil(value / this.packageSize) * this.packageSize;
	}
}
