import { NgModule } from "@angular/core";

// model
import { ContextCodeAssociationStateReducer } from "./context-code-association.reducer";

// store
import { StoreModule } from "@ngrx/store";

@NgModule({
    imports: [
        StoreModule.forFeature('context-code-association', ContextCodeAssociationStateReducer.reducer)
    ]
})
export class ContextCodeAssociationStateModule{}
