<breadcrumb
	*ngIf="currentContext !== contextApplicationItemCodeEnum.B2C"
	[title]="'user.settings' | translate | sentencecase"
	[pathList]="['Home']"
>
</breadcrumb>

<div class="container-fluid">
	<div class="row" *ngIf="user">
		<div class="col-md-12 col-lg-4">
			<person-card-project
				[title]="
					this.user.first_name || this.user.last_name
						? (this.user.first_name ? this.user.first_name + ' ' : '') +
						(this.user.last_name ? this.user.last_name : '')
						: '-'
				"
				[personImage]="user.avatar"
				[subtitle]="user.username"
			></person-card-project>
		</div>
		<div class="col-md-12 col-lg-8 p-0 mt-0">
			<!-- ANAGRAFICA UTENTE -->
			<div class="col-md-12">
				<mat-card>
					<mat-toolbar>
						<mat-toolbar-row>
							{{ 'user.registry' | translate | sentencecase }}
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<form (ngSubmit)="onFormSubmit($event)" [formGroup]="form">
							<div class="row">
								<div class="col-md-6">
									<mat-form-field>
										<input
											#firstElementFocus
											formControlName="name"
											matInput
											[value]="user.first_name"
											placeholder="{{ 'user.field.first_name' | translate | sentencecase }}"
										/>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field>
										<input
											formControlName="lastName"
											matInput
											[value]="user.last_name"
											placeholder="{{ 'user.field.last_name' | translate | sentencecase }}"
										/>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field>
										<input
											formControlName="email"
											matInput
											[value]="user.username"
											placeholder="{{ 'contact.field.email' | translate | sentencecase }}"
										/>
									</mat-form-field>
								</div>
								<div class="col-md-6">
									<mat-form-field>
										<mat-select
											formControlName="language"
											placeholder="{{ 'language.name' | translate | sentencecase }}"
										>
											<mat-option
												*ngFor="let language of languageList"
												[value]="language.description_short"
											>
												{{ 'language.' + language.description_short | translate | sentencecase }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>

							<mat-toolbar class="height-auto">
								<!-- Change user data-->
								<button *ngIf="!isEditing" mat-stroked-button type="button" (click)="editForm()">
									{{ 'user.edit' | translate | sentencecase }}
								</button>

								<!-- Button save -->
								<button
									*ngIf="isEditing"
									[disabled]="!form.valid"
									mat-raised-button
									color="primary"
									type="submit"
									class="ml-4"
								>
									{{ 'general.save' | translate | sentencecase }}
								</button>

								<!-- Button change password-->
								<button
									*ngIf="!isEditing"
									mat-button
									[routerLink]="['/', ROUTE_URL.authentication, ROUTE_URL.passwordUpdate]"
									class="ml-4"
									type="button"
								>
									{{ 'login.general.change_password' | translate | sentencecase }}
								</button>
							</mat-toolbar>
						</form>
					</mat-card-content>
				</mat-card>
			</div>
			<div *ngFor="let contextApplication of user.context_application_list" class="col-md-12">
				<mat-card>
					<!-- evita undefined nel caso si visualizzi la pagina come admin -->
					<mat-toolbar
						*ngIf="permissionUtilService.contextTypeAndApplicationLinkFullObject(contextApplication.code)"
					>
						<mat-toolbar-row>
							{{
								permissionUtilService.contextTypeAndApplicationLinkFullObject(contextApplication.code)
									.application_name
							}}
							<span class="flex-span"></span>
							<mat-icon>
								{{
									permissionUtilService.contextTypeAndApplicationLinkFullObject(contextApplication.code)
										.icon
								}}
							</mat-icon>
						</mat-toolbar-row>
						<mat-toolbar-row class="subtitle">
							{{ 'context_code.linked' | translate | titlecase }}
						</mat-toolbar-row>
					</mat-toolbar>
					<mat-card-content>
						<td-data-table
							#dataTable
							[data]="contextApplication.context_code_list"
							[columns]="permissionContextListColumnService.columns"
						>
						</td-data-table>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
	</div>
</div>
