<div class="mat-fab-button">
	<div class="scroll-to-top" [class.show]="isVisible">
		<button
			mat-fab
			color="primary"
			(click)="onScrollToTop.emit()"
			matTooltip="Torna in cima alla pagina"
			matTooltipPosition="left"
		>
			<mat-icon>arrow_upward</mat-icon>
		</button>
	</div>
</div>
