import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// widget & utility
import { UtilService } from '@saep-ict/angular-core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { GiveTakeFlagEnum } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'organization-detail-credit-list-wrapper',
	templateUrl: './organization-detail-credit-list-wrapper.component.html',
	styleUrls: ['./organization-detail-credit-list-wrapper.component.scss']
})
export class OrganizationDetailCreditListWrapperComponent extends LocalListHandlerWidgetWrapper {
	giveTakeFlagEnum = GiveTakeFlagEnum;

	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}
}
