import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialComponentsModule } from '../shared/modules/material-components.module';
import { SharedModule } from '../shared/shared.module';
import { IllustrationAccountComponent } from './illustration-account.component';
import { IllustrationEmptyComponent } from './illustration-empty.component';
import { IllustrationPasswordRecoveryComponent } from './illustration-password-recovery.component';
import { IllustrationShoppingComponent } from './illustration-shopping.component';
import { IllustrationOnlineShoppingComponent } from './illustration-online-shopping.component';

@NgModule({
	declarations: [
		IllustrationShoppingComponent,
		IllustrationPasswordRecoveryComponent,
		IllustrationAccountComponent,
		IllustrationEmptyComponent,
		IllustrationOnlineShoppingComponent
	],
	imports: [RouterModule, MaterialComponentsModule, SharedModule],
	providers: [],
	exports: [
		IllustrationShoppingComponent,
		IllustrationPasswordRecoveryComponent,
		IllustrationAccountComponent,
		IllustrationEmptyComponent,
		IllustrationOnlineShoppingComponent
	]
})
export class IllustrationsModule { }
