import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BaseState, BaseStateModel, RestBaseMessageError, SentencecasePipe } from '@saep-ict/angular-core';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CouchDocumentType } from '../../constants/context-state.map';
import { OrganizationStateModel } from '../../model/state/organization-state.model';
import { PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { OrganizationService } from '../../service/rest/organization.service';
import { OrganizationActionEnum, OrganizationStateAction } from './organization.actions';

@Injectable()
export class OrganizationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getOrganization(action.data))),
			map((organization: BaseStateModel<OrganizationStateModel>) => OrganizationStateAction.update(organization)),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	createNewItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationActionEnum.CREATE_ITEM_API),
			mergeMap((action: BaseStateModel<OrganizationStateModel>) => from(this.postOrganization(action))),
			map((organization: BaseStateModel<OrganizationStateModel>) =>
				OrganizationStateAction.saveSuccess(organization)
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OrganizationActionEnum.SAVE),
			mergeMap((action: BaseStateModel<OrganizationStateModel>) => from(this.putOrganization(action.data))),
			mergeMap((action: BaseStateModel<OrganizationStateModel>) => from(this.addCode(action))),
			map((organization: BaseStateModel<OrganizationStateModel>) =>
				OrganizationStateAction.saveSuccess(organization)
			),
			catchError((error, caught) => {
				this.store.dispatch(OrganizationStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private organizationService: OrganizationService,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentenceCasePipe: SentencecasePipe,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getOrganization(data: BasePouchModel): Promise<BaseStateModel<OrganizationStateModel>> {
		try {
			const agent = await (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
			).organizationPouch.getOrganization(data._id);
			return new BaseState(agent);
		} catch (err) {
			throw new Error(err);
		}
	}

	// Crea organizzazione tramite API
	async postOrganization(action: BaseStateModel<OrganizationStateModel>): Promise<any> {
		return this.organizationService
			.postNewOrganization(action.data)
			.then(async res => {
				return new BaseState(action.data);
			})
			.catch((err: RestBaseMessageError) => {
				this.showSnackBar(err.body.message);
				throw new Error(err.body.detail);
			});
	}

	// Creo e aggiorna organizzazione
	async putOrganization(data: OrganizationStateModel): Promise<BaseStateModel<OrganizationStateModel>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
		).organizationPouch
			.putOrganization(data, data._id ? true : false)
			.then(async organization => {
				return new BaseState(organization);
			})
			.catch((err: PouchErrorResponse) => {
				console.log(err);
				throw { error: err.error, reason: err.reason, status: err.status };
			});
	}

	async addCode(state: BaseStateModel<OrganizationStateModel>): Promise<BaseStateModel<OrganizationStateModel>> {
		if (!state.data.code_item) {
			state.data.code_item = state.data._id.replace('organization_', '');

			return (
				await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
			).organizationPouch
				.putOrganization(state.data, state.data._id ? true : false)
				.then(async organization => {
					return new BaseState(organization);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
					throw { error: err.error, reason: err.reason, status: err.status };
				});
		}
		return state;
	}

	showSnackBar(message: string, action = '', duration = 3000) {
		let finalMessage = this.translate.instant(message);
		finalMessage = this.sentenceCasePipe.transform(finalMessage);
		this.snackBar.open(finalMessage, action, { duration: duration });
	}
}
