<breadcrumb
	[title]="(offer ? offer.title : 'offer.new') | translate | sentencecase"
	[pathList]="['Home', 'offer.name']"
	[backButton]="backRoute"
	[badge]="
		'offer.status.' + (offer && offer.status ? offer.status : offerStatusEnum.DRAFT) | translate | sentencecase
	"
	badgeClass="badge-light"
></breadcrumb>

<tab-wrapper [toExclude]="tabsToExclude"></tab-wrapper>
