import { ITdDataTableColumn } from '@covalent/core/data-table';

import { BaseTableListService } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class OpportunityListColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	public _columns = [
        {
            name: "title",
            label: this.translate.instant('general.title'),
        },
        {
            name: "organization",
            label: this.translate.instant('organization.name'),
        },
        {
            name: "contact_name",
            label: this.translate.instant('opportunity.field.referent'),
        },
        {
            name: "assegnee_user_name",
            label: this.translate.instant('opportunity.field.assegnee'),
        },
        {
            name: "status_code",
            label: this.translate.instant('opportunity.field.status_code'),
            numeric: true
        },
        {
            name: "actual_value",
            label: this.translate.instant('opportunity.field.actual_value'),
            numeric: true
        },
    ];
}