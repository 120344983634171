import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginAuthRequestModel } from '@saep-ict/angular-core';
import { CustomerConfigurationCompany } from '../../../../constants/configuration-customer/structure.constants';
import { ROUTE_URL } from '../../../../router/route-naming';
import { AuthService } from '../../../../service/rest/auth.service';

/**
 * Contesti in cui viene utilizzato:
 * + /login (parte pubblica)
 * + /authentication/login (parte privata)
 */
@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	form: FormGroup;
	userLogin: LoginAuthRequestModel;
	hide = true;
	canAccessToRegistration: boolean = CustomerConfigurationCompany.registration.can_access_to_registration;

	ROUTE_URL = ROUTE_URL;

	constructor(private router: Router, private authService: AuthService, private fb: FormBuilder) {
		this.createForm();
		this.authService.clearState();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			email: ['', Validators.required],
			password: ['', Validators.required]
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.userLogin = this.form.value;
			this.authService.login(this.userLogin);
		}
	}

	accessLink(): string[] {
		if (this.router.url.includes(ROUTE_URL.authentication)) {
			return ['/', ROUTE_URL.authentication, ROUTE_URL.accessRequest];
		} else {
			return [ROUTE_URL.public, ROUTE_URL.createAccount];
		}
	}
}
