import { Component, OnInit } from '@angular/core';
import { OfferStateModel, OfferStatusEnum } from '../../../../model/state/offer-state.model';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { State, Store } from '@ngrx/store';
import { StateFeature } from '../../../../state';
import { filter, map } from 'rxjs/operators';
import { OfferActionEnum, OfferStateAction } from '../../../../state/offer/offer.actions';
import { ROUTE_URL } from '../../../../router/route-naming';
import { ActivatedRoute } from '@angular/router';
import { OpportunityPouchModel } from '../../../../model/state/opportunity-state.model';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../state/opportunity/opportunity.actions';

@Component({
	selector: 'offer-detail',
	templateUrl: './offer-detail.component.html',
	styleUrls: ['./offer-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailComponent implements OnInit {
	public tabsToExclude: string[] = [ROUTE_URL.offerBody];
	public ROUTE_URL = ROUTE_URL;
	public offerStatusEnum = OfferStatusEnum;

	// state
	offer: OfferStateModel;
	offer$: Observable<BaseStateModel<OfferStateModel[]>> = this.store.select(StateFeature.getOfferState);

	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);
	opportunity: OpportunityPouchModel;

	backRoute: string = null;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public activatedRoute: ActivatedRoute
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(this.subscribeOpportunity().subscribe(), 'subscribeOpportunity');
		this.subscribeManagerService.populate(this.subscribeOffer().subscribe(), 'subscribeOffer');

		// get offer data
		// load univoca nel parent
		if (this.activatedRoute.snapshot.paramMap.get('idOffer') !== ROUTE_URL.newOffer) {
			this.store.dispatch(
				OfferStateAction.load({ data: { id: this.activatedRoute.snapshot.paramMap.get('idOffer') } })
			);
		}

		// get opportunity data
		// Recupero i dati della opportunity per risalire alla organization collegata
		// e ai suoi metodi di pagamento associati
		this.store.dispatch(
			OpportunityStateAction.load({
				data: { id: this.activatedRoute.snapshot.paramMap.get('idOpportunity') }
			})
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OfferStateAction.reset());
	}

	/**
	 * Subscription
	 */
	subscribeOffer() {
		return this.offer$.pipe(
			filter((offerState: BaseStateModel<OfferStateModel[]>) => !!(offerState && offerState.data)),
			map(offerState => {
				switch (offerState.type) {
					case OfferActionEnum.UPDATE:
						this.tabsToExclude = [];
						this.offer = offerState.data[0];
						break;

					default:
						break;
				}
			})
		);
	}

	subscribeOpportunity() {
		return this.opportunity$.pipe(
			filter(
				(opportunityState: BaseStateModel<OpportunityPouchModel[]>) =>
					!!(opportunityState && opportunityState.data)
			),
			map(opportunityState => {
				switch (opportunityState.type) {
					case OpportunityActionEnum.UPDATE:
						this.opportunity = opportunityState.data[0];
						this.populateBackRoute();
						break;

					default:
						break;
				}
			})
		);
	}

	populateBackRoute() {
		this.backRoute = [
			ROUTE_URL.private,
			ROUTE_URL.opportunity,
			this.opportunity.type.replace('opportunity_', ''),
			this.activatedRoute.snapshot.paramMap.get('idOpportunity')
		].join('/');
	}
}
