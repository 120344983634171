import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
	name: 'orderIdFormatter'
})
export class OrderIdFormatterPipe implements PipeTransform {
	transform(value: any) {
		if (!value) {
			return value;
		}
		return value.slice(-12).toUpperCase();
	}
}
