import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { HomeHighlightsModel } from '../../../../model/home-highlights.model';

export class HomeHighlightsPouch extends AbstractPouchdbMethod {
	getHighlights(): Promise<HomeHighlightsModel> {
		return new Promise((resolve, reject) => {
			this.get('table_configuration')
				.then((doc: HomeHighlightsModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
