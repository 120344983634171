<mat-action-list class="parent" *ngIf="categorySelected._id">
	<button mat-list-item (click)="selectCategory(categorySelected.parent ? categorySelected.parent : null)">
		<mat-icon>keyboard_arrow_left</mat-icon>
		{{ categorySelected.description }}
	</button>
</mat-action-list>
<mat-action-list class="child" *ngIf="categorySelected">
	<button mat-list-item *ngFor="let item of categorySelected.category_list" (click)="selectCategory(item._id)">
		{{ item.description }}
		<span class="flex-span"></span>
		<mat-icon>keyboard_arrow_right</mat-icon>
	</button>
</mat-action-list>
