// model
import { ContextApplicationItemCodeEnum, ContextTypeAndApplicationLink } from '@saep-ict/angular-core';
import { TicketCenterConfigurationModel } from '@saep-ict/ticket-center';
import {
	ConfigurationCustomerModel,
	ConfigurationCustomerContextSelector
} from '../../model/structure/configuration-customer.model';
import { OrganizationPouchModel } from '@saep-ict/pouch_agent_models';

// constant
import {
	contextCodeItemBackoffice,
	contextCodeItemCompany,
	contextCodeItemAgent,
	contextCodeItemB2C,
	contextCodeItemCRM
} from './context-application/context-application.constants';
import {
	ConfigurationStatusAggregationMap,
	StateRelatedLinkBackoffice,
	StateRelatedLinkAgent,
	StateRelatedLinkB2b
} from './order/status-aggregation-map.constant';
import { ConfigurationOrderContextApplicationColumnMap } from './order/order-column-map.constant';
import { ConfigurationCustomerArticleContextApplicationColumnMap } from './article/article-column-map.constant';
import { TicketCenterColumn } from './ticket-center/column-map.constant';
import { ConfigurationOrganizationContextApplicationColumnMap } from './organization/organization-column-map.constant';
import { TicketCenterBodyCustomField } from './ticket-center/body-custom-field.constant';
import { TicketCenterStatus } from './ticket-center/status-configuration.constant';
import { TicketCenterLocalSearchTextKeyList } from './ticket-center/local-search-text-key-list.constant';
import { TicketCenterPageListTitle } from './ticket-center/page-list-title.constant';

// store
import { UserManagementStateAction } from '../../state/backoffice/user-management/user-management.actions';

// misc
import { ITdDataTableColumn } from '@covalent/core/data-table';

export namespace ConfigurationCustomer {
	export namespace ContextApplication {
		export const map: ContextTypeAndApplicationLink[] = [
			{
				context_application_item_code: ContextApplicationItemCodeEnum.BACKOFFICE,
				context_code_item: contextCodeItemBackoffice,
				application_name: 'Backoffice',
				icon: 'admin_panel_settings'
			},
			{
				context_application_item_code: ContextApplicationItemCodeEnum.B2B,
				context_code_item: contextCodeItemCompany,
				application_name: 'B2B',
				icon: 'shopping_bag'
			},
			{
				context_application_item_code: ContextApplicationItemCodeEnum.AGENT,
				context_code_item: contextCodeItemAgent,
				application_name: 'Agent App',
				icon: 'work'
			},
			{
				context_application_item_code: ContextApplicationItemCodeEnum.B2C,
				context_code_item: contextCodeItemB2C,
				application_name: 'B2C',
				icon: 'add_shopping_cart'
			},
			{
				context_application_item_code: ContextApplicationItemCodeEnum.CRM,
				context_code_item: contextCodeItemCRM,
				application_name: 'CRM',
				icon: 'filter_alt'
			}
		];
	}

	export namespace UserManagement {
		export const canEditContextCode: ConfigurationCustomerContextSelector<boolean> = {
			BACKOFFICE: true,
			B2B: true,
			AGENT: true,
			B2C: true,
			CRM: true
		};
		export const storeAction: ConfigurationCustomerModel.StoreAction = {
			load_list: UserManagementStateAction.loadListWithAvatar,
			load_detail: UserManagementStateAction.loadDetailWithAvatar,
			reset: UserManagementStateAction.reset
		};
	}

	export namespace Organization {
		export const canCreate: ConfigurationCustomerModel.Organization = {
			detail: {
				BACKOFFICE: true,
				B2B: false,
				AGENT: true,
				B2C: false,
				CRM: true
			},
			destination: {
				BACKOFFICE: true,
				B2B: true,
				AGENT: true,
				B2C: true,
				CRM: true
			}
		};
		export const canEdit: ConfigurationCustomerModel.Organization = {
			detail: {
				BACKOFFICE: true,
				B2B: true,
				AGENT: true,
				B2C: true,
				CRM: true
			},
			destination: {
				BACKOFFICE: true,
				B2B: true,
				AGENT: true,
				B2C: true,
				CRM: true
			}
		};
		export const columnList = <ConfigurationCustomerContextSelector<ITdDataTableColumn[]>>{
			AGENT: ConfigurationOrganizationContextApplicationColumnMap.base,
			BACKOFFICE: ConfigurationOrganizationContextApplicationColumnMap.base.filter(
				i => !ConfigurationOrganizationContextApplicationColumnMap.excludeForBackoffice.includes(i.name)
			),
			CRM: ConfigurationOrganizationContextApplicationColumnMap.base.filter(
				i => !ConfigurationOrganizationContextApplicationColumnMap.excludeForCrm.includes(i.name)
			)
		};
	}

	export namespace Order {
		export const canCreate = (
			contextApplication: ContextApplicationItemCodeEnum,
			organization: OrganizationPouchModel
		): boolean => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN:
					return organization ? true : false;
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return organization ? true : false;
				case ContextApplicationItemCodeEnum.B2B:
					return true;
				case ContextApplicationItemCodeEnum.AGENT:
					return organization ? true : false;
				case ContextApplicationItemCodeEnum.B2C:
					return false;
				case ContextApplicationItemCodeEnum.CRM:
					return false;
				default:
					return false;
			}
		};
		export const statusAggregationMap = <ConfigurationStatusAggregationMap>{
			BACKOFFICE: StateRelatedLinkBackoffice,
			AGENT: StateRelatedLinkAgent,
			B2B: StateRelatedLinkB2b
		};
		export const columnList = (
			columnList: ITdDataTableColumn[],
			contextApplication: ContextApplicationItemCodeEnum,
			organization: OrganizationPouchModel = null
		): ITdDataTableColumn[] => {
			switch (contextApplication) {
				case ContextApplicationItemCodeEnum.B2B:
					return columnList.filter(
						i => !ConfigurationOrderContextApplicationColumnMap.excludeForB2b.includes(i.name)
					);
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					return columnList.filter(
						i => !ConfigurationOrderContextApplicationColumnMap.excludeForBackoffice.includes(i.name)
					);
				default:
					return organization
						? columnList.filter(
								i =>
									!ConfigurationOrderContextApplicationColumnMap.exludeForOrganization.includes(
										i.name
									)
						  )
						: columnList.filter(
								i =>
									!ConfigurationOrderContextApplicationColumnMap.exludeForNonOrganization.includes(
										i.name
									)
						  );
			}
		};
	}

	export namespace Article {
		export const columnList = <ConfigurationCustomerContextSelector<ITdDataTableColumn[]>>{
			AGENT: ConfigurationCustomerArticleContextApplicationColumnMap.base,
			B2B: ConfigurationCustomerArticleContextApplicationColumnMap.base.filter(
				i => !ConfigurationCustomerArticleContextApplicationColumnMap.exludeForB2B.includes(i.name)
			),
			BACKOFFICE: ConfigurationCustomerArticleContextApplicationColumnMap.base.filter(
				i => !ConfigurationCustomerArticleContextApplicationColumnMap.exludeForBackoffice.includes(i.name)
			)
		};
	}

	export const authenticationWrapper: ConfigurationCustomerModel.AuthenticationWrapper = {
		can_access_to_registration: true
	};

	// Manages the display of the following features in the CONTACTS section of the company
	export const contact: ConfigurationCustomerModel.Contact = {
		send_email_confirmation: true,
		can_activate_email_order_receipt: true
	};

	// lib
	export namespace TicketCenter {
		export const configuration = <TicketCenterConfigurationModel>{
			page: {
				list: {
					title: TicketCenterPageListTitle.contextApplicationMap,
					tableColumn: TicketCenterColumn.contextApplicationMap,
					localSearchTextKeyList: TicketCenterLocalSearchTextKeyList.contextApplicationMap
				}
			},
			ticket: {
				bodyCustomField: TicketCenterBodyCustomField.creationFieldMap,
				statusContextApplicationStatusMap: TicketCenterStatus.contextApplicationMap
			}
		};
	}
}
