import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilService } from '@saep-ict/angular-core';
import { OfferStateModel } from '../../../model/state/offer-state.model';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'offer-list-wrapper',
	templateUrl: './offer-list-wrapper.component.html',
	styleUrls: ['./offer-list-wrapper.component.scss']
})
export class OfferListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Output() selectOfferId: EventEmitter<OfferStateModel> = new EventEmitter();

	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}
}
