import { ViewTypeEnum } from './../../enum/view-type.enum';
import { Component, Input, OnInit } from '@angular/core';
import { IMG_PLACEHOLDER_PRODUCT } from '../../constants/misc.constants';
import { ROUTE_URL } from '../../router/route-naming';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { ItemType } from './card-slider.models';
import { DEFAULT_DIVISION_CODE } from '../../constants/division.constants';
import { UtilOrderService } from '../../service/util/util-order.service';

export interface ToBeArticlePouchModel extends ArticlePouchModel {
	divisions: { division: string; price: number; discounts: number[] }[];
}

@Component({
	selector: 'app-card-product',
	templateUrl: './card-product.component.html',
	styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent implements OnInit {
	@Input() viewType: ViewTypeEnum;
	@Input() itemType: ItemType;
	@Input() showDetails: boolean = true;
	@Input() data: ToBeArticlePouchModel;
	public ViewTypeEnum = ViewTypeEnum;

	ROUTE_URL = ROUTE_URL;
	itemTypeEnum = ItemType;

	descrizione =
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
		'Vivamus ullamcorper fermentum hendrerit. Proin eleifend sem vulputate, aliquet eros sit amet, ' +
		'rutrum leo. Pellentesque laoreet ornare accumsan. Nullam a bibendum lorem. Pellentesque ' +
		'pellentesque sapien semper purus consequat gravida. Nulla facilisi. ' +
		'Nam a turpis sit amet nunc ultricies porta eu non turpis. Donec ac tristique lectus, ut tristique mi. Curabitur id tincidunt est.';

	imagePath = IMG_PLACEHOLDER_PRODUCT;

	constructor(public utilOrderService: UtilOrderService) {}

	ngOnInit(): void {
		// if (this.data) {
		//   this.descrizione = this.data.description;
		// }
		//  else {
		//   throw Error('data must be set');
		// }
	}

	linkToItem() {
		if (this.data && this.data.type == 'category') {
			return this.data._id;
		}
		return this.data.code;
	}
}
