import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import {
	BaseStateModel,
	NavItem,
	ContextApplicationItemCodeEnum,
	PermissionKeyEnum,
	UserDetailModel
} from '@saep-ict/angular-core';
import { StateFeature } from '../../state';
import { UtilGuardService } from '../guard/util-guard/util-guard.service';
import { PATH_URL, ROUTE_URL } from '../../router/route-naming';
import { StatisticsOrders } from '../../model/statistics-orders.model';
import { OpportunityTypeEnum } from '../../model/state/opportunity-state.model';
import { OrganizationLevelEnum } from '@saep-ict/pouch_agent_models';
import { Router } from '@angular/router';
@Injectable()
export class NavigationService {
	private _itemsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _items: NavItem[] = [];
	items$: Observable<NavItem[]> = this._itemsSubject.asObservable();

	private _currentlyOpenSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
	private _currentlyOpen: NavItem[] = [];

	currentlyOpen$: Observable<NavItem[]> = this._currentlyOpenSubject.asObservable();

	isIconSidenav: boolean;
	isHorizontal = false;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	_user: Subscription;

	statisticsOrders$: Observable<BaseStateModel<StatisticsOrders>> = this.store.select(
		StateFeature.getStatisticsOrders
	);
	statisticsOrders: StatisticsOrders;

	constructor(
		private store: Store<any>,
		snackbar: MatSnackBar,
		public translate: TranslateService,
		private utilGuardService: UtilGuardService,
		private router: Router
	) {
		// setta di default tutte le voci di menu esplose (aperte)
		this._currentlyOpen = this._items;

		if (!this._user) {
			this._user = this.user$.subscribe(res => {
				if (res) {
					this.user = res.data;
					this._items = [];
					this._itemsSubject.next(this._items);

					// Agent
					if (
						this.user &&
						this.user.current_permission &&
						this.user.current_permission.context_application === ContextApplicationItemCodeEnum.AGENT
					) {
						// Agent code
						const agentCode = this.user.context_application_list.find(
							contextApp => contextApp.code === this.user.current_permission.context_application
						).context_code_list[0].code;

						// Dashboard
						const agent_dashboard_main = this.addItem(
							'sidenav.dashboard',
							'dashboard',
							'',
							1,
							PermissionKeyEnum.AGENT_DASHBOARD
						);

						const agent_dashboard_general = this.addSubItem(
							agent_dashboard_main,
							'general.general',
							'',
							'',
							1,
							PermissionKeyEnum.AGENT_DASHBOARD
						);

						// Mediacenter
						const agent_mediacenter = this.addItem(
							'Mediacenter',
							'perm_media',
							`${ROUTE_URL.mediaCenter}/path/Home`,
							13,
							PermissionKeyEnum.AGENT_MEDIACENTER
						);

						// Organization
						const agent_organizations = this.addItem(
							'sidenav.organizations',
							'perm_contact_calendar',
							'',
							2,
							PermissionKeyEnum.AGENT_ORGANIZATION_LIST
						);
						const agent_organizations_organizations = this.addSubItem(
							agent_organizations,
							'company.name_plural',
							'work',
							`/${ROUTE_URL.organizations}`,
							3,
							PermissionKeyEnum.AGENT_ORGANIZATION_LIST
						);
						const agent_organizations_contacts = this.addSubItem(
							agent_organizations,
							'contact.name_plural',
							'perm_contact_calendar',
							`/${ROUTE_URL.contacts}`,
							4,
							PermissionKeyEnum.AGENT_CONTACT_LIST // AGENT_CONTACTS
						);

						// Ordini
						const agent_orders = this.addItem(
							'order.name_plural',
							'list_alt',
							'',
							5,
							PermissionKeyEnum.AGENT_ORDER_LIST
						);
						const agent_orders_drafts = this.addSubItem(
							agent_orders,
							'order.status.DRAFT',
							'gesture',
							'/orders/draft',
							6,
							PermissionKeyEnum.AGENT_ORDER_LIST
						);
						// const toAuthorize = this.addSubItem(
						// 	orders,
						// 	translate.instant('order.status.TO_AUTHORIZE'),
						// 	'library_add_check',
						// 	'/orders/to_authorize',
						// 	7,
						// 	PermissionKeyEnum.AGENT_ORDER_LIST
						// );
						// const notAuthorized = this.addSubItem(
						// 	orders,
						// 	translate.instant('order.status.NOT_AUTHORIZED'),
						// 	'not_interested',
						// 	'/orders/not_authorized',
						// 	8,
						// 	PermissionKeyEnum.AGENT_ORDER_LIST
						// );
						const agent_orders_sending = this.addSubItem(
							agent_orders,
							'order.status.SENDING',
							'send',
							'/orders/sending',
							9,
							PermissionKeyEnum.AGENT_ORDER_LIST
						);
						const agent_orders_processing = this.addSubItem(
							agent_orders,
							'order.status.PROCESSING',
							'rotate_right',
							'/orders/processing',
							10,
							PermissionKeyEnum.AGENT_ORDER_LIST
						);
						const agent_orders_consolidated = this.addSubItem(
							agent_orders,
							'order.status.CONSOLIDATED',
							'build',
							'/orders/consolidated',
							11,
							PermissionKeyEnum.AGENT_ORDER_LIST
						);
						const agent_orders_archived = this.addSubItem(
							agent_orders,
							'order.status.FULFILLED',
							'archive',
							'/orders/fulfilled',
							12,
							PermissionKeyEnum.AGENT_ORDER_LIST
						);

						// Archiviati
						const agent_archive = this.addItem(
							'sidenav.archive',
							'inventory',
							'',
							19,
							PermissionKeyEnum.AGENT_ORDER_LIST // TODO: add PermissionKeyEnum.AGENT_ARCHIVE
						);
						const agent_archive_receipts = this.addSubItem(
							agent_archive,
							'sidenav.receipts',
							'',
							`/${ROUTE_URL.receipts}`,
							21,
							PermissionKeyEnum.AGENT_ORDER_LIST // TODO - AGENT_RECEIPT
						);

						// Popolo i badge
						this.statisticsOrders$.subscribe((stats: BaseStateModel<StatisticsOrders>) => {
							if (stats && stats.data) {
								agent_orders_drafts.badge = (
									stats.data.draft.count + stats.data.error_preparing.count
								).toString();
								// toAuthorize.badge = stats.data.to_authorize.count.toString();
								// notAuthorized.badge = stats.data.not_authorized.count.toString();
								agent_orders_sending.badge = (
									stats.data.sending.count +
									stats.data.ready_to_send.count +
									stats.data.error_sending.count
								).toString();
								agent_orders_processing.badge = stats.data.processing.count.toString();
								agent_orders_consolidated.badge = (
									stats.data.consolidated.count + stats.data.partially_fulfilled.count
								).toString();
								agent_orders_archived.badge = (
									stats.data.fulfilled.count + stats.data.deleted.count
								).toString();
							}
						});
					}

					// Backoffice
					if (
						this.user &&
						this.user.current_permission &&
						this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE
					) {
						// Dashboard
						// const backoffice_dashboard = this.addItem(
						// 	'Dashboard',
						// 	'dashboard',
						// 	PATH_URL.PRIVATE + '/',
						// 	1,
						// 	PermissionKeyEnum.BACKOFFICE_DASHBOARD
						// );
						// User management
						const backoffice_user_management = this.addItem(
							'user.name_plural',
							'people',
							PATH_URL.PRIVATE + '/' + ROUTE_URL.userManagement,
							2,
							PermissionKeyEnum.BACKOFFICE_USER_MANAGEMENT_LIST
						);
						// Configuration
						// const backoffice_configuration = this.addItem(
						// 	'Configuration',
						// 	'settings',
						// 	PATH_URL.PRIVATE + '/' + ROUTE_URL.configurations,
						// 	3,
						// 	PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION
						// );
						// context code
						//const backoffice_context_code = this.addItem(
						//	'Context Code',
						//	'vpn_key',
						//	'',
						//	4,
						//	PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
						//);
						//const backoffice_context_code_backoffice = this.addSubItem(
						//	backoffice_context_code,
						//	'Backoffice',
						//	'',
						//	`/${ROUTE_URL.contextCodeManagement}/backoffice`,
						//	5,
						//	PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
						//);
						//const backoffice_context_code_b2b = this.addSubItem(
						//	backoffice_context_code,
						//	'B2B',
						//	'',
						//	`/${ROUTE_URL.contextCodeManagement}/b2b`,
						//	6,
						//	PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
						//);
						// const backoffice_context_code_agent = this.addSubItem(
						// 	backoffice_context_code,
						// 	'Agent',
						// 	'',
						// 	`/${ROUTE_URL.contextCodeManagement}/agent`,
						// 	7,
						// 	PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
						// );
						// const backoffice_context_code_b2c = this.addSubItem(
						// 	backoffice_context_code,
						// 	'B2C',
						// 	'',
						// 	`/${ROUTE_URL.contextCodeManagement}/b2c`,
						// 	8,
						// 	PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
						// );
						// const backoffice_context_code_crm = this.addSubItem(
						// 	backoffice_context_code,
						// 	'CRM',
						// 	'',
						// 	`/${ROUTE_URL.contextCodeManagement}/crm`,
						// 	9,
						// 	PermissionKeyEnum.BACKOFFICE_CONTEXT_CODE_MANAGEMENT_LIST
						// );
						// Mediacenter
						const backoffice_mediacenter = this.addItem(
							'Mediacenter',
							'perm_media',
							`${ROUTE_URL.mediaCenter}/path/Home`,
							4,
							PermissionKeyEnum.BACKOFFICE_MEDIACENTER
						);
						// ticket-center
						const backoffice_ticketcenter = this.addItem(
							'lib.ticket_center.page.list.BACKOFFICE',
							'support_agent',
							PATH_URL.PRIVATE + '/ticket-center',
							6,
							PermissionKeyEnum.BACKOFFICE_TICKET_CENTER
						);
						// Ordini
						const backoffice_orders = this.addItem(
							'order.name_plural',
							'list_alt',
							'',
							5,
							PermissionKeyEnum.BACKOFFICE_ORDER_LIST
						);
						const backoffice_orders_processing = this.addSubItem(
							backoffice_orders,
							'order.filterType.processing',
							'',
							'/orders/processing',
							1,
							PermissionKeyEnum.BACKOFFICE_ORDER_LIST
						);
						const backoffice_orders_consolidated = this.addSubItem(
							backoffice_orders,
							'order.filterType.consolidated',
							'',
							'/orders/consolidated',
							2,
							PermissionKeyEnum.BACKOFFICE_ORDER_LIST
						);
						const backoffice_orders_fulfilled = this.addSubItem(
							backoffice_orders,
							'order.filterType.fulfilled',
							'',
							'/orders/fulfilled',
							3,
							PermissionKeyEnum.BACKOFFICE_ORDER_LIST
						);
						// Organizations (only customer level)
						const backoffice_organizations = this.addItem(
							'sidenav.organizations',
							'business',
							`${ROUTE_URL.organizations}/${OrganizationLevelEnum.CUSTOMER}`,
							6,
							PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST
						);
						// Organization
						// const backoffice_organizations = this.addItem(
						// 	'sidenav.organizations',
						// 	'business',
						// 	'',
						// 	6,
						// 	PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST
						// );
						// const backoffice_organizations_lead = this.addSubItem(
						// 	backoffice_organizations,
						// 	'organization.status.lead',
						// 	'',
						// 	`/${ROUTE_URL.organizations}/${OrganizationLevelEnum.LEAD}`,
						// 	1,
						// 	PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST
						// );
						// const backoffice_organizations_prospect = this.addSubItem(
						// 	backoffice_organizations,
						// 	'organization.status.prospect',
						// 	'',
						// 	`/${ROUTE_URL.organizations}/${OrganizationLevelEnum.PROSPECT}`,
						// 	2,
						// 	PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST
						// );
						// const backoffice_organizations_customer = this.addSubItem(
						// 	backoffice_organizations,
						// 	'organization.status.customer',
						// 	'',
						// 	`/${ROUTE_URL.organizations}/${OrganizationLevelEnum.CUSTOMER}`,
						// 	3,
						// 	PermissionKeyEnum.BACKOFFICE_ORGANIZATION_LIST
						// );
						// Contact
						// const contacts = this.addItem(
						//	'contact.name_plural',
						//	'perm_contact_calendar',
						//	`${ROUTE_URL.contacts}`,
						//	10,
						//	PermissionKeyEnum.BACKOFFICE_CONTACT_LIST
						// );
						// Catalogue
						// const backoffice_catalogue = this.addItem(
						// 	'general.catalogue',
						// 	'library_books',
						// 	'',
						// 	11,
						// 	PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION // TOFIX
						// );
						// const backoffice_catalogue_categories = this.addSubItem(
						// 	backoffice_catalogue,
						// 	'general.categories',
						// 	'',
						// 	`/${ROUTE_URL.catalog}/${ROUTE_URL.categories}`,
						// 	1,
						// 	PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION // TOFIX
						// );
						// const backoffice_catalogue_articles = this.addSubItem(
						// 	backoffice_catalogue,
						// 	'general.articles',
						// 	'',
						// 	`/${ROUTE_URL.catalog}/${`${ROUTE_URL.articles}`}`,
						// 	2,
						// 	PermissionKeyEnum.BACKOFFICE_DATA_CONFIGURATION // TOFIX
						// );
					}

					// B2B
					if (
						this.user &&
						this.user.current_permission &&
						this.user.current_permission.context_application === ContextApplicationItemCodeEnum.B2B &&
						this.user.current_permission.context_code &&
						this.user.current_permission.context_code.code
					) {
						// Dashboard
						const b2b_dashboard = this.addItem(
							'sidenav.dashboard',
							'dashboard',
							PATH_URL.PRIVATE,
							1,
							PermissionKeyEnum.B2B_DASHBOARD
						);
						// Profilo utente
						const b2b_user = this.addItem(
							'user.my_account',
							'person',
							`${ROUTE_URL.user}`,
							2,
							PermissionKeyEnum.B2B_PROFILE
						);
						// Organization detail
						const idOrganization = this.user.current_permission.context_code.code;
						const b2b_company = this.addItem(
							'company.name_personal',
							'business',
							`${ROUTE_URL.company}/${idOrganization}/${ROUTE_URL.details}`, // TODO: :idOrganization should be handled during login for B2B
							3,
							PermissionKeyEnum.B2B_ORGANIZATION_DETAIL
						);
						// Orders
						const b2b_orders = this.addItem(
							'order.name_plural_personal',
							'list_alt',
							'',
							4,
							PermissionKeyEnum.B2B_ORDER_LIST
						);
						// const b2b_orders_b2b_drafts = this.addSubItem(
						// 	b2b_orders,
						// 	'order.status.DRAFT',
						// 	'gesture',
						// 	`/${ROUTE_URL.orders}/draft`,
						// 	5,
						// 	PermissionKeyEnum.B2B_ORDER_LIST
						// );
						// const b2b_orders_sending = this.addSubItem(
						// 	b2b_orders,
						// 	'order.status.SENDING',
						// 	'send',
						// 	`/${ROUTE_URL.orders}/sending`,
						// 	9,
						// 	PermissionKeyEnum.B2B_ORDER_LIST
						// );
						const b2b_orders_processing = this.addSubItem(
							b2b_orders,
							'order.status.PROCESSING',
							'rotate_right',
							`/${ROUTE_URL.orders}/processing`,
							10,
							PermissionKeyEnum.B2B_ORDER_LIST
						);
						const b2b_orders_consolidated = this.addSubItem(
							b2b_orders,
							'order.status.CONSOLIDATED',
							'build',
							`/${ROUTE_URL.orders}/consolidated`,
							11,
							PermissionKeyEnum.B2B_ORDER_LIST
						);
						const b2b_orders_archived = this.addSubItem(
							b2b_orders,
							'order.status.FULFILLED',
							'archive',
							`/${ROUTE_URL.orders}/fulfilled`,
							12,
							PermissionKeyEnum.B2B_ORDER_LIST
						);
						// Mediacenter
						const b2b_mediacenter = this.addItem(
							'Mediacenter',
							'perm_media',
							`${ROUTE_URL.mediaCenter}/path/Home`,
							7,
							PermissionKeyEnum.B2B_MEDIACENTER
						);
						// ticket-center
						const b2b_ticketcenter = this.addItem(
							'lib.ticket_center.page.list.B2B',
							'support_agent',
							PATH_URL.PRIVATE + '/ticket-center',
							6,
							PermissionKeyEnum.B2B_TICKET_CENTER
						);
						// shop-online - mostrare quest'item solo se lo user ha come prop Fastorder: true - no PERMISSION ASSOCIATA
						const b2b_shopOnline = this.addItem(
							'shop_online.shop',
							'shopping_cart',
							'shop-online',
							7,
							PermissionKeyEnum.B2B_ORDER_LIST
						);

					}
					// CRM
					if (
						this.user &&
						this.user.current_permission &&
						this.user.current_permission.context_application === ContextApplicationItemCodeEnum.CRM
					) {
						// dashboard
						const crm_dashboard = this.addItem(
							'sidenav.dashboard',
							'dashboard',
							`/${ROUTE_URL.private}`,
							1,
							PermissionKeyEnum.CRM_DASHBOARD
						);
						// organizzazioni
						const crm_organizations = this.addItem(
							'organization.name_plural',
							'work',
							'',
							1,
							PermissionKeyEnum.CRM_ORGANIZATION_LIST
						);
						// organizzazioni - lead
						const crm_organizations_lead = this.addSubItem(
							crm_organizations,
							'organization.status.lead',
							'',
							`/${ROUTE_URL.organizations}/${OrganizationLevelEnum.LEAD}`,
							1,
							PermissionKeyEnum.CRM_ORGANIZATION_LIST
						);
						// organizzazioni - prospect
						const crm_organizations_prospect = this.addSubItem(
							crm_organizations,
							'organization.status.prospect',
							'',
							`/${ROUTE_URL.organizations}/${OrganizationLevelEnum.PROSPECT}`,
							2,
							PermissionKeyEnum.CRM_ORGANIZATION_LIST
						);
						// organizzazioni - clienti
						const crm_organizations_customer = this.addSubItem(
							crm_organizations,
							'organization.status.customer',
							'',
							`/${ROUTE_URL.organizations}/${OrganizationLevelEnum.CUSTOMER}`,
							3,
							PermissionKeyEnum.CRM_ORGANIZATION_LIST
						);
						// Contatti
						const crm_contacts = this.addItem(
							'contact.name_plural',
							'perm_contact_calendar',
							`${ROUTE_URL.contacts}`,
							3,
							PermissionKeyEnum.CRM_CONTACT_LIST
						);
						// Richieste (Opportunity lead)
						const crm_richieste = this.addItem(
							'request.name_plural',
							'all_inbox',
							`${ROUTE_URL.opportunity}/${OpportunityTypeEnum.LEAD}`,
							4,
							PermissionKeyEnum.CRM_DASHBOARD // TODO - CRM_OPPORTUNITY_LIST
						);

						// Opportunità (Opportunity prospect + client)
						const crm_opportunity = this.addItem(
							'opportunity.name',
							'filter_alt',
							`${ROUTE_URL.opportunityKanban}/`,
							5,
							PermissionKeyEnum.CRM_DASHBOARD // TODO - CRM_OPPORTUNITY_LIST
						);

						// Offerte
						const crm_offers = this.addItem(
							'offer.name_plural',
							'request_quote',
							`${ROUTE_URL.offer}`,
							6,
							PermissionKeyEnum.CRM_OFFER_LIST
						);
					}
				}
			});
		}

		//  questa funzione permette di generare menu dinamici ma in questo momento volatili
		const dynamicMenuFunctionDemo = () => {
			const dynamicFunction = () => {
				const dynamicMenu = this.addItem('Dynamic Menu Item', 'extension', dynamicFunction, 12);
				const snackbarRef = snackbar.open('This menu item got added dynamically!', 'Remove item', <
					MatSnackBarConfig
				>{
					duration: 5000
				});
				snackbarRef.onAction().subscribe(() => {
					this.removeItem(dynamicMenu);
				});
			};
		};
	}

	addItem(
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			permission: permission || null,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			this._items.push(item);
			this._itemsSubject.next(this._items);
		}
		return item;
	}

	addSubItem(
		parent: NavItem,
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: string,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: PATH_URL.PRIVATE + route,
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});

		if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			parent.subItems.push(item);
			this._itemsSubject.next(this._items);
		}
		return item;
	}

	// rimuove un elemento dall'itemSubject
	removeItem(item: NavItem) {
		const index = this._items.indexOf(item);
		if (index > -1) {
			this._items.splice(index, 1);
		}

		this._itemsSubject.next(this._items);
	}

	isOpen(item: NavItem) {
		return this._currentlyOpen.indexOf(item) !== -1;
	}

	// ritorna i subItem dell'elemento padre interrogato attivando un observable
	toggleCurrentlyOpen(item: NavItem) {
		let currentlyOpen = this._currentlyOpen;

		if (this.isOpen(item)) {
			if (currentlyOpen.length > 1) {
				currentlyOpen.length = this._currentlyOpen.indexOf(item);
			} else {
				currentlyOpen = [];
			}
		} else {
			currentlyOpen = this.getAllParents(item);
		}

		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	closeAll() {
		if (this._currentlyOpen.length) {
			const currentlyOpen = [];
			this._currentlyOpen = currentlyOpen;
			this._currentlyOpenSubject.next(currentlyOpen);
		}
	}

	getAllParents(item: NavItem, currentlyOpen: NavItem[] = []) {
		currentlyOpen.unshift(item);
		if (item.hasParent()) {
			return this.getAllParents(item.parent, currentlyOpen);
		} else {
			return currentlyOpen;
		}
	}

	nextCurrentlyOpen(currentlyOpen: NavItem[]) {
		this._currentlyOpen = currentlyOpen;
		this._currentlyOpenSubject.next(currentlyOpen);
	}

	nextCurrentlyOpenByRoute(route: string) {
		let currentlyOpen = [];
		const item: NavItem = this.findByRouteRecursive(route, this._items);
		if (item && item.hasParent()) {
			currentlyOpen = this.getAllParents(item);
		} else if (item) {
			currentlyOpen = [item];
		}

		this.nextCurrentlyOpen(currentlyOpen);
	}

	// metodo ricorsivo che recupera l'elemento innestato selezionato
	findByRouteRecursive(route: string, collection: NavItem[]): any {
		let result = _.find(collection, { route: route });
		let typeItem: string;
		if (!result) {
			_.each(collection, item => {
				typeItem = 'subItems';
				const found = this.findByRouteRecursive(route, item[typeItem]);
				if (found) {
					result = found;
					return false;
				}
			});
		}
		return result;
	}

	get currentlyOpen() {
		return this._currentlyOpen;
	}

	set currentlyOpen(currentlyOpen) {
		this._currentlyOpen = currentlyOpen;
	}

	getSidenavItemByRoute(route) {
		return this.findByRouteRecursive(route, this._items);
	}

	getActiveItemByRoute(route) {
		return this.findByRouteRecursive(route, this._items);
	}
}
