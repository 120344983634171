import { ContextApplicationItemCodeEnum, ContextOtherItemCodeEnum } from '@saep-ict/angular-core';

export enum CouchDocumentType {
	AGENT = 'agent',
	ARTICLE = 'article',
	BACKOFFICE = 'backoffice',
	CONTACT = 'contact',
	CRM = 'crm',
	ORGANIZATION = 'organization',
	ORDER = 'order',
	STATISTICS = 'statistics',
	USER = 'user'
}

// Il codice "chiave" è relativo al contesto a cui si vuole accedere, NON a quello dell'utente loggato.
// Es. accedo come backoffice e ho visibilità su un tot di agent: dovrò guardare per il codice chiave 3
export const contextStateMap = new Map<ContextApplicationItemCodeEnum | ContextOtherItemCodeEnum, CouchDocumentType[]>([
	[ContextApplicationItemCodeEnum.BACKOFFICE, [CouchDocumentType.BACKOFFICE]],
	[ContextApplicationItemCodeEnum.B2B, [CouchDocumentType.ORGANIZATION]],
	[ContextApplicationItemCodeEnum.AGENT, [CouchDocumentType.AGENT]],
	[ContextApplicationItemCodeEnum.B2C, []],
	[ContextApplicationItemCodeEnum.CRM, []],
	[ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN, [CouchDocumentType.USER]],
	[ContextOtherItemCodeEnum.ORDER, [CouchDocumentType.ORDER]],
	[ContextOtherItemCodeEnum.ARTICLE, [CouchDocumentType.ARTICLE]],
	[ContextOtherItemCodeEnum.CONTACT, [CouchDocumentType.CONTACT]]
]);
