import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// constant
import { ConfigurationCustomerUser } from '../../../../constants/configuration-customer/structure.constants';

// store
import { StateFeature } from '../../../../state';
import { Store } from '@ngrx/store';
import {
	UserManagementStateAction,
	UserManagementStateActionEnum
} from '../../../../state/backoffice/user-management/user-management.actions';

// widget & utility
import { TdDataTableComponent } from '@covalent/core/data-table';
import { DialogChangePermissionContextCodeComponent } from '../../../../widget/dialog/dialog-change-permission-context-code/dialog-change-permission-context-code.component';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import { PermissionContextListColumnService } from '../../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { DialogAddContextCodeComponent } from '../../../../widget/dialog/dialog-add-context-code/dialog-add-context-code.component';
import { DialogCreateContextCodeComponent } from '../../../../widget/dialog/dialog-create-context-code/dialog-create-context-code.component';
import {
	SubscribeManagerService,
	BaseStateModel,
	PermissionAuxiliaryTableStateModel,
	ContextPermission,
	BaseState,
	DialogConfirmComponent,
	ContextApplicationItemCodeEnum,
	LinkDetailModel,
	ContextCodeItem,
	UtilService,
	UserManagementDialogChangePermissionContextCode,
	ContextTypeAndApplicationLink,
	ContextPermissionGroupItemContextApplicationLink
} from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { contextTypeAndApplicationLink } from '../../../../constants/configuration-customer/context-application/context-application.constants';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { ROUTE_URL } from '../../../../router/route-naming';
import { ExtendedUserDetailModel } from '../../../../model/user.model';
import { LanguageStateModel } from '../../../../model/state/language-state.model';

@Component({
	selector: 'user-management-detail',
	templateUrl: './user-management-detail.component.html',
	styleUrls: ['./user-management-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class UserManagementDetailComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	userManagementState$: Observable<BaseStateModel<ExtendedUserDetailModel[]>> = this.store.select(
		StateFeature.getUserManagementState
	);
	public user = <ExtendedUserDetailModel>{
		id: this.route.snapshot.paramMap.get('idUser') !== 'new' ? this.route.snapshot.paramMap.get('idUser') : null
	};

	permissionAuxiliaryTableState$: Observable<BaseStateModel<PermissionAuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getPermissionAuxiliaryTableState
	);
	public permissionAuxiliaryTableState = <PermissionAuxiliaryTableStateModel>{};

	// form
	form: FormGroup;
	formEditEnabled = this.user.id ? false : true;

	contextTypeAndApplicationLink = contextTypeAndApplicationLink;

	/**
	 * Lista di oggetti aventi chiave e lista di permission tree relativi ai context_application
	 *
	 * @type {ContextPermissionGroupItem[][]}
	 * @memberof BackofficeUserDetailComponent
	 */
	contextApplicationPermissionTreeList: ContextPermissionGroupItemContextApplicationLink[] = [];

	configurationCustomerUserManagement = ConfigurationCustomerUser;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	dataBeforeEdit: ExtendedUserDetailModel;

	ROUTE_URL = ROUTE_URL;

	languageList$: Observable<BaseStateModel<LanguageStateModel[]>> = this.store.select(StateFeature.getLanguageList);
	languageList: LanguageStateModel[];

	constructor(
		public route: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public permissionContextListColumnService: PermissionContextListColumnService,
		private fb: FormBuilder,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		public utilService: UtilService,
		public permissionUtilService: PermissionUtilService,
		private sentencecasePipe: SentencecasePipe,
		private router: Router,
		private translate: TranslateService
	) {
		this.setVisibleTableColumn();
		this.createForm();
		this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'user-management-state');
		this.subscribeManagerService.populate(
			this.subscribePermissionAuxiliaryTableState().subscribe(),
			'permission-auxiliary-table-state'
		);

		// language list
		this.languageList$.pipe(take(1)).subscribe(res => {
			this.languageList = res ? res.data : null;
		});

		if (this.user.id) {
			this.loadUser();
			this.form.disable({ emitEvent: false });
		}
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(UserManagementStateAction.reset());
	}

	// subscribe
	subscribeUserManagementState() {
		return this.userManagementState$.pipe(
			skipWhile(
				(state: BaseStateModel<ExtendedUserDetailModel[]>) => !(state && state.data && state.data.length > 0)
			),
			map((state: BaseStateModel<ExtendedUserDetailModel[]>) => {
				switch (state.type) {
					case UserManagementStateActionEnum.UPDATE:
						if (state.data) {
							if (!this.user.id && state.data[0].id) {
								this.router.navigate([this.router.url.replace('new', state.data[0].id)]);
							}
							this.user = state.data[0];
							this.setFormFromRemoteData();
							this.disableForm();
						}
						break;
				}
				return this.permissionAuxiliaryTableState$;
			})
		);
	}

	subscribePermissionAuxiliaryTableState() {
		return this.permissionAuxiliaryTableState$.pipe(
			skipWhile((state: BaseStateModel<PermissionAuxiliaryTableStateModel>) => !(state && state.data)),
			map((state: BaseStateModel<PermissionAuxiliaryTableStateModel>) => {
				this.permissionAuxiliaryTableState = state.data;
				const exclude_permission_list = [
					'backoffice-context-code-management-detail',
					'backoffice-context-code-management-list',
					'backoffice-dashboard'
				];

				// creazione array di permessi filtrati per context_application_item
				for (let i = 0; i < contextTypeAndApplicationLink.length; i++) {
					this.contextApplicationPermissionTreeList[i] = {
						item_key:
							ContextApplicationItemCodeEnum[
								contextTypeAndApplicationLink[i].context_application_item_code
							],
						context_permission_tree: this.permissionUtilService.createContextPermissionGroupList(
							this.permissionAuxiliaryTableState.permission_group_list,
							this.permissionAuxiliaryTableState.permission_list,
							contextTypeAndApplicationLink[i].context_application_item_code,
							exclude_permission_list
						)
					};
				}
			})
		);
	}

	// load
	loadUser() {
		this.store.dispatch(ConfigurationCustomerUser.store_action.load_detail({ id: this.user.id }));
	}

	// form
	createForm() {
		this.form = this.fb.group({
			username: [null, [Validators.required, Validators.email]],
			first_name: [null, [Validators.required]],
			last_name: [null, [Validators.required]],
			language: [null, [Validators.required]],
			permission: [[]],
			context_application_list: [[]]
		});
	}

	setFormFromRemoteData() {
		this.form.patchValue(
			{
				username: this.user.username,
				first_name: this.user.first_name,
				last_name: this.user.last_name,
				language: this.user.language,
				permission: this.user.permission,
				context_application_list: this.user.context_application_list
			},
			{ emitEvent: false }
		);
	}

	/**
	 * Funzione legata al click del tasto 'edit'
	 */
	enableFormEdit() {
		this.formEditEnabled = true;
		this.form.enable({ emitEvent: false });
		this.form.get('username').disable({ emitEvent: false });
		this.dataBeforeEdit = _.cloneDeep(this.user);
	}

	disableForm() {
		this.form.disable({ emitEvent: false });
		this.formEditEnabled = false;
	}

	exitEditMode() {
		this.user = _.cloneDeep(this.dataBeforeEdit);
		this.setFormFromRemoteData();
		this.disableForm();
	}

	/**
	 * Restituisce il valore del form field associato alla key passatagli
	 *
	 * @param {string} key
	 * @returns
	 * @memberof BackofficeUserDetailComponent
	 */
	getFormFieldValue(key: string) {
		return this.form.get(key).value;
	}

	/**
	 * Aggiorna il valore del form field associato alla key passatagli
	 *
	 * @param {string} key
	 * @param {*} value
	 * @memberof BackofficeUserDetailComponent
	 */
	updateFormFieldValue(key: string, value: any) {
		const formField = {};
		formField[key] = JSON.parse(JSON.stringify(value));
		this.form.patchValue(formField);
	}

	/**
	 * Aggiorna il valore del form field context_application_list eliminando da ogni oggetto di context_code_list
	 * le eventuali permission contenute nel form field permission passatogli
	 *
	 * @param {number[]} permission
	 * @memberof BackofficeUserDetailComponent
	 */
	updateFormContextApplicationListWithoutFormPermissionValue(permission: string[]) {
		const context_application_list: ContextPermission[] = this.getFormFieldValue('context_application_list');
		for (let i = 0; i < context_application_list.length; i++) {
			if (context_application_list[i].context_code_list) {
				for (let n = 0; n < context_application_list[i].context_code_list.length; n++) {
					if (
						context_application_list[i].context_code_list[n].permission &&
						context_application_list[i].context_code_list[n].permission.length > 0
					) {
						context_application_list[i].context_code_list[n].permission = context_application_list[
							i
						].context_code_list[n].permission.filter(p => !permission.includes(p));
					}
				}
			}
		}
		this.updateFormFieldValue('context_application_list', context_application_list);
	}

	/**
	 * Aggiorna i form field permission e context_application_list con i valori passati dal componente
	 * form-permission-group-select.
	 *
	 * Questo metodo è dedicato esclusivamente per gestire il seguente contesto atipico:
	 *
	 * 1 form-permission-group-select riceve
	 *   - [tree]: un'alberatura di permission filtrata per il contesto relativo all'istanza (context_application)
	 *   - [extraFormValue]: il valore del form field permission (dunque non filtrato per context_application)
	 * 2 le modifiche al campo form-permission-group-select emettono (extraFormValueEmit) un valore potenzialmente sporcato da
	 *   dati duplicati, dovuti all'input improprio di extraFormValue (comprensivo di valori non contestuali al context_application)
	 * 3 prima di aggiornare il valore del form field permission vengono eliminati eventuali permission duplicate
	 *
	 * @param {number[]} permission
	 * @param {ContextApplicationItemCodeEnum} context_application_item_code
	 * @memberof BackofficeUserDetailComponent
	 */
	updateFormPermissionFromFormPermissionGroupSelect(
		permission: string[],
		context_application_item_code: ContextApplicationItemCodeEnum
	) {
		let permissionReturn: string[] = this.returnFormPermissionWithoutContextApplicationPermission(
			context_application_item_code
		).concat(permission);
		permissionReturn = permissionReturn.filter((v, i) => permissionReturn.indexOf(v) === i);
		this.updateFormFieldValue('permission', permissionReturn);
		this.updateFormContextApplicationListWithoutFormPermissionValue(permissionReturn);
	}

	/**
	 * Restituisce il valore di form permission, epurato di tutti i valori relativi al context_application passato come
	 * parametro.
	 *
	 * Il metodo è necessario per aggiornare form permission con valori relativi ad un certo context_application, senza perdere i
	 * valori relativi ad altri context_application non considerati dalla particolare istanza
	 *
	 * @param {ContextApplicationItemCodeEnum} context_application_item_code
	 * @returns {number[]}
	 * @memberof BackofficeUserDetailComponent
	 */
	returnFormPermissionWithoutContextApplicationPermission(
		context_application_item_code: ContextApplicationItemCodeEnum
	): string[] {
		const contextApplicationPermissionTree = <ContextPermissionGroupItemContextApplicationLink>(
			this.returnContextApplicationPermissionGroup(ContextApplicationItemCodeEnum[context_application_item_code])
		);
		const permissionToIterate: string[] = _.cloneDeep(this.getFormFieldValue('permission'));
		const permissionReturn: string[] = _.cloneDeep(permissionToIterate);

		for (let p = 0; p < permissionToIterate.length; p++) {
			for (let i = 0; i < contextApplicationPermissionTree.context_permission_tree.length; i++) {
				if (
					contextApplicationPermissionTree.context_permission_tree[i].permission_group_value_list.includes(
						permissionToIterate[p]
					)
				) {
					const index = permissionReturn.indexOf(permissionToIterate[p]);
					if (index > -1) {
						permissionReturn.splice(index, 1);
					}
				}
			}
		}
		return permissionReturn;
	}

	/**
	 * Aggiorna il valore del form field context_application_list selezionando o creando
	 * il context_application_item ed aggiungendovi il context_code_item
	 *
	 * @param {ContextApplicationItemCodeEnum} context_application_item_code
	 * @param {ContextPermission} contextCodeItem
	 * @memberof BackofficeUserDetailComponent
	 */
	updateFormContextApplicationListWithNewContextCodeItem(
		context_application_item_code: ContextApplicationItemCodeEnum,
		contextCodeItem: ContextPermission
	) {
		let returnContextApplicationItem: ContextPermission = this.permissionUtilService.getContextItem(
			this.getFormFieldValue('context_application_list'),
			context_application_item_code
		);
		if (!(returnContextApplicationItem && returnContextApplicationItem.context_code_list)) {
			returnContextApplicationItem = {
				code: context_application_item_code,
				context_code_list: []
			};
		}
		returnContextApplicationItem.context_code_list.push(contextCodeItem);
		this.updateFormFieldValue(
			`context_application_list`,
			this.permissionUtilService.returnUpdatedContextListValue(
				this.getFormFieldValue('context_application_list'),
				context_application_item_code,
				returnContextApplicationItem
			)
		);
	}

	/**
	 * Rimappa l'oggetto form context_application_list preparandolo per la post.
	 * Elimina desfription e oggetti non valorizzati, array vuoti, ecc.
	 *
	 * @param {ContextPermission[]} context_application_list
	 * @returns {ContextPermission[]}
	 * @memberof BackofficeUserDetailComponent
	 */
	prepareSaveContextApplicationList(context_application_list: ContextPermission[]): ContextPermission[] {
		for (let i = 0; i < context_application_list.length; i++) {
			delete context_application_list[i].description;
			context_application_list[i] = this.utilService.deleteEmptyProperties(context_application_list[i]);
			if (context_application_list[i].context_code_list) {
				for (let n = 0; n < context_application_list[i].context_code_list.length; n++) {
					delete context_application_list[i].context_code_list[n].description;
					// pulisco eventuali proprietà vuote
					context_application_list[i].context_code_list[n] = this.utilService.deleteEmptyProperties(
						context_application_list[i].context_code_list[n]
					);
				}
			}
		}
		context_application_list = context_application_list.filter(i => i.context_code_list);
		return context_application_list;
	}

	prepareSaveForm(): ExtendedUserDetailModel {
		const formModel = this.form.value;
		const user: ExtendedUserDetailModel = _.cloneDeep(this.user);

		// sovrascrive le prop. modificabili tramite form
		user.username = formModel.username as string;
		user.first_name = formModel.first_name as string;
		user.last_name = formModel.last_name as string;
		user.language = formModel.language as string;
		user.context_application_list = this.prepareSaveContextApplicationList(
			formModel.context_application_list as ContextPermission[]
		);
		user.permission = formModel.permission as string[];
		return user;
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.store.dispatch(UserManagementStateAction.save(new BaseState([this.prepareSaveForm()])));
		}
	}

	// dialog
	// Rimanda email di conferma utenza
	dialogConfirmResendEmailConfirmationMessage() {
		const title = this.translate.instant('user.resend_email_confirmation');
		const text = this.translate.instant('user.question.resend_email_confirmation', {
			username: this.user.username
		});
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.store.dispatch(UserManagementStateAction.resendEmailConfirmation(new BaseState(this.user)));
			}
		});
	}

	// Disattiva utente
	dialogConfirmDeactivateUser() {
		const title = this.translate.instant('user.deactivate');
		const text = this.translate.instant('user.question.deactivate', { username: this.user.username });
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: text,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.store.dispatch(UserManagementStateAction.deactivateDetail(new BaseState(this.user)));
			}
		});
	}

	// Disassocia code list
	dialogConfirmDeleteContextCodeList(context_application_item_code: ContextApplicationItemCodeEnum) {
		const contextCodeDescription = this.sentencecasePipe.transform(
			this.permissionUtilService.contextTypeAndApplicationLinkFullObject(context_application_item_code)
				.context_code_item.description
		);
		const title = this.translate.instant('context_code.disassociate_all_x', {
			context_code: contextCodeDescription
		});
		const question = this.translate.instant('context_code.question.disassociate_all_x', {
			context_code: contextCodeDescription
		});

		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const contextApplicationItem = <ContextPermission>(
					this.permissionUtilService.getContextItem(
						this.getFormFieldValue('context_application_list'),
						context_application_item_code
					)
				);
				contextApplicationItem.context_code_list = [];
				this.updateFormFieldValue(
					`context_application_list`,
					this.permissionUtilService.returnUpdatedContextListValue(
						this.getFormFieldValue('context_application_list'),
						context_application_item_code,
						contextApplicationItem
					)
				);
			}
		});
	}

	// Disassocia code
	dialogConfirmDeleteContextCodeItem(
		context_application_item_code: ContextApplicationItemCodeEnum,
		context_code: string
	) {
		const contextCodeDescription = this.sentencecasePipe.transform(
			this.permissionUtilService.contextTypeAndApplicationLinkFullObject(context_application_item_code)
				.context_code_item.description
		);

		const title = this.translate.instant('context_code.disassociate_x', {
			context_code: contextCodeDescription,
			context_code_item: context_code
		});
		const question = this.translate.instant('context_code.question.disassociate_x', {
			context_code_description: contextCodeDescription,
			subject_description: this.user.username
		});

		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: title,
				text: question,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				const contextApplicationItem = <ContextPermission>(
					this.permissionUtilService.getContextItem(
						this.getFormFieldValue('context_application_list'),
						context_application_item_code
					)
				);
				contextApplicationItem.context_code_list.splice(
					this.utilService.getElementIndex(contextApplicationItem.context_code_list, 'code', context_code),
					1
				);
				this.updateFormFieldValue(
					`context_application_list`,
					this.permissionUtilService.returnUpdatedContextListValue(
						this.getFormFieldValue('context_application_list'),
						context_application_item_code,
						contextApplicationItem
					)
				);
			}
		});
	}

	dialogAssociateContextCodeItem(contextTypeAndApplicationLink: ContextTypeAndApplicationLink) {
		const contextApplicationItem = <ContextPermission>(
			this.permissionUtilService.getContextItem(
				this.getFormFieldValue('context_application_list'),
				contextTypeAndApplicationLink.context_application_item_code
			)
		);

		contextTypeAndApplicationLink.context_code_item.already_associated_context_code_list =
			contextApplicationItem && contextApplicationItem.context_code_list
				? contextApplicationItem.context_code_list.map(i => i.code)
				: [];

		const dialogRef: MatDialogRef<DialogAddContextCodeComponent> = this.dialog.open(DialogAddContextCodeComponent, {
			data: contextTypeAndApplicationLink.context_code_item,
			autoFocus: false,
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe((res: ContextCodeItem) => {
			if (res) {
				const contextCode = <LinkDetailModel>{
					code: res.code,
					description: res.description
				};
				this.updateFormContextApplicationListWithNewContextCodeItem(
					contextTypeAndApplicationLink.context_application_item_code,
					contextCode
				);
			}
		});
	}

	dialogCreateContextCodeItem(contextTypeAndApplicationLink: ContextTypeAndApplicationLink) {
		const dialogRef: MatDialogRef<DialogCreateContextCodeComponent> = this.dialog.open(
			DialogCreateContextCodeComponent,
			{
				data: contextTypeAndApplicationLink.context_code_item,
				disableClose: true,
				panelClass: 'dialog-large'
			}
		);
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.updateFormContextApplicationListWithNewContextCodeItem(
					contextTypeAndApplicationLink.context_application_item_code,
					res
				);
			}
		});
	}

	dialogChangePermissionContextCodeItem(
		contextTypeAndApplicationLink: ContextTypeAndApplicationLink,
		contextCodeItem: ContextPermission
	) {
		if (
			this.permissionUtilService.isAnyContextGeneralPermission(
				contextTypeAndApplicationLink.context_application_item_code,
				this.permissionAuxiliaryTableState.permission_list
			)
		) {
			const dialogSelectPermission: UserManagementDialogChangePermissionContextCode = {
				contextTypeAndApplicationLink: contextTypeAndApplicationLink,
				contextCodeItem: JSON.parse(JSON.stringify(contextCodeItem)),
				permission_default: this.getFormFieldValue('permission'),
				permission_group_list: this.permissionAuxiliaryTableState.permission_group_list,
				permission_list: this.permissionAuxiliaryTableState.permission_list
			};
			const dialogRef: MatDialogRef<DialogChangePermissionContextCodeComponent> = this.dialog.open(
				DialogChangePermissionContextCodeComponent,
				{
					data: dialogSelectPermission,
					disableClose: true,
					panelClass: 'dialog-large'
				}
			);
			dialogRef.afterClosed().subscribe(res => {
				if (res) {
					const contextApplicationItem = <ContextPermission>(
						this.permissionUtilService.getContextItem(
							this.getFormFieldValue('context_application_list'),
							contextTypeAndApplicationLink.context_application_item_code
						)
					);
					contextApplicationItem.context_code_list[
						this.utilService.getElementIndex(
							contextApplicationItem.context_code_list,
							'code',
							contextCodeItem.code as string
						)
					] = res;
					this.updateFormFieldValue(
						`context_application_list`,
						this.permissionUtilService.returnUpdatedContextListValue(
							this.getFormFieldValue('context_application_list'),
							contextTypeAndApplicationLink.context_application_item_code,
							contextApplicationItem
						)
					);
				}
			});
		} else {
			this.snackBar.open('No permission to define for this context', 'OK', {
				duration: 3000
			});
		}
	}

	// widget & utility
	returnContextApplicationPermissionGroup(item_key: string): ContextPermissionGroupItemContextApplicationLink {
		const contextPermissionGroupItemContextApplicationLink = <ContextPermissionGroupItemContextApplicationLink>(
			this.contextApplicationPermissionTreeList.find(i => i.item_key === item_key)
		);

		return contextPermissionGroupItemContextApplicationLink
			? contextPermissionGroupItemContextApplicationLink
			: <ContextPermissionGroupItemContextApplicationLink>{ context_permission_tree: [] };
	}

	setVisibleTableColumn() {
		this.permissionContextListColumnService.columns.map(col => {
			col.hidden = ['permission'].includes(col.name);
		});
	}
}
