<div class="password-meter" [ngClass]="{'password-meter__weak': passwordMeter === PasswordStrength.WEAK, 
                'password-meter__good': passwordMeter === PasswordStrength.GOOD, 
                'password-meter__excellent': passwordMeter === PasswordStrength.EXCELLENT
            }">
    <div class="password-meter__row">
        <div class="password-meter__row__bar">
            <div class="password-meter__row__bar__segment"></div>
            <div class="password-meter__row__bar__segment"></div>
            <div class="password-meter__row__bar__segment"></div>
        </div>
    </div>
    <div class="password-meter__dialog"> {{passwordMeterDialog | translate | sentencecase}}</div>
</div>

<div class="ruleset">
    <div *ngFor="let passwordRule of passwordRules; let i = index">
        <div class="ruleset__optional" *ngIf="(!!i) && (passwordRules[i].isRequired !== passwordRules[i-1].isRequired)">
            {{
            'login.password_rules.optional'
            | translate | sentencecase }}</div>
        <div class="ruleset__row">
            <div class="ruleset__row__icon">
                <mat-icon [ngClass]="{ 'success': satisfiedRules[i] }">check_circle</mat-icon>
            </div>
            <div class="ruleset__row__description">
                <div>
                    {{ passwordRule.description | translate: passwordRule.params | sentencecase }}
                </div>
            </div>
        </div>
    </div>
</div>