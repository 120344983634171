<div
	list-wrapper
	#listWrapper
	class="list-wrapper"
	[filterOpened]="!isMobile && hasSidebar"
	[mode]="isMobile && !hasSidebar ? 'over' : 'side'"
	[minHeight]="minHeight"
	sidenavWidth="300px"
	(window:resize)="onResize($event)"
>
	<ng-container main>
		<mat-card class="detail-card">
			<mat-toolbar>
				<mat-toolbar-row>
					<!-- TODO - gestire traduzione -->
					{{ 'product.of_catalogue' | translate | sentencecase }}
					<span class="flex-span"></span>
					<td-search-box
						backIcon="arrow_back"
						placeholder="{{
							('general.filter_by' | translate | sentencecase) +
								' ' +
								('article.field.ref' | translate) +
								' ' +
								('general.o' | translate) +
								' ' +
								('article.field.description' | translate)
						}}"
						[showUnderline]="true"
						[debounce]="500"
						[alwaysVisible]="false"
						[(ngModel)]="articleList.dataSetting.appliedFilter.article.description"
						(searchDebounce)="getArticleList()"
					>
					</td-search-box>

					<button
						mat-icon-button
						(click)="listWrapper.sidenav.open()"
						class="filter-list-icon"
						*ngIf="isMobile && hasSidebar"
					>
						<mat-icon [ngClass]="{ accent: filterCategoryLabel }"> filter_list </mat-icon>
					</button>
				</mat-toolbar-row>
			</mat-toolbar>

			<div class="chip-filters">
				<mat-chip-list aria-label="applied filters">
					<mat-chip *ngIf="filterCategoryLabel"
						>{{ 'general.category' | translate | sentencecase }}: {{ filterCategoryLabel }}
					</mat-chip>
				</mat-chip-list>
			</div>

			<article-list-table
				[order]="order"
				[articleList]="articleList"
				[columns]="
					configurationCustomer.Article.columnList[
						contextApplicationItemCodeEnum[user.current_permission.context_application]
					]
				"
				(articleChange)="rowChange($event)"
			>
			</article-list-table>
			<pouch-paging-bar [listStructure]="articleList" (change)="paginationChange($event)"></pouch-paging-bar>
		</mat-card>
	</ng-container>

	<ng-container side>
		<h3>{{ 'general.category' | translate | sentencecase }}</h3>
		<nested-category-list
			[list]="categoryList"
			[debounceTime]="700"
			(categorySelectedChange)="categorySelectedChange($event)"
		></nested-category-list>
	</ng-container>
</div>
