import { Component, OnInit, Inject, ChangeDetectorRef, PLATFORM_ID, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusBarOrderB2cService } from '../../../service/status-bar-config/implementation/status-bar-order-b2c.service';
import { BaseStateModel, MediaReplayService } from '@saep-ict/angular-core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointState } from '@angular/cdk/layout';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';
import {
	ItemDescriptionModel,
	OrderPriceModel,
	OrderStateModel,
	ShippingMethodsModel
} from '../../../model/state/order-state.model';
import { ArticleRecapArticleList } from '../../../model/state/article-list-state.model';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-dialog-order-detail',
	templateUrl: './dialog-order-detail.component.html',
	styleUrls: ['./dialog-order-detail.component.scss'],
	providers: [StatusBarOrderB2cService]
})
export class DialogOrderDetailComponent implements OnInit, OnDestroy {
	articleList$: Observable<BaseStateModel<ArticleRecapArticleList[]>> = this.store.select(
		StateFeature.getArticleList
	);
	articleList: ArticleRecapArticleList[];

	order: OrderStateModel = this.data.order;
	shippingAddress: AddressPouchModel = this.order.header.goods_destination_object;
	shippingMethod: ShippingMethodsModel = this.order.header.shipping_methods_object;
	paymentMethod: ItemDescriptionModel = this.order.header.payment_object;
	price: OrderPriceModel = this.order.header.price;

	productsList = [];

	private _mediaSubscription: Subscription;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		@Inject(PLATFORM_ID) private platform_id: string,
		public statusBarOrderB2cService: StatusBarOrderB2cService,
		private cdr: ChangeDetectorRef,
		public mediaReplayService: MediaReplayService,
		private utilOrderService: UtilOrderService,
		private store: Store<any>
	) {
		this.articleList$.pipe(take(1)).subscribe(res => {
			this.articleList = res ? res.data : null;
		});
		this.getProductsDetail();
	}

	ngOnInit() {
		this.handleStatusBar();
	}

	getProductsDetail() {
		this.order.product_list.forEach(product => {
			const article: ArticleRecapArticleList = this.articleList.find(article => article.code === product.code);
			const orderProduct = {
				description: article.description,
				quantity: product.ordered_quantity,
				total: this.utilOrderService.getPriceFromDivision(article.divisions)
			};
			this.productsList.push(orderProduct);
		});
	}

	downloadInvoice() {
		console.error('Download invoice not implemented');
	}

	handleStatusBar() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media
			.pipe(debounceTime(100))
			.subscribe((change: BreakpointState) => {
				if (isPlatformBrowser(this.platform_id)) {
					if (window.innerWidth < 1000) {
						this.statusBarOrderB2cService.selectOnly(this.order.header.status);
						this.statusBarOrderB2cService.setCondensed(true);
						// console.log('condensed', this.order.header.status);
						// console.log(this.statusBarOrderB2cService.config.steps);
					} else {
						this.statusBarOrderB2cService.selectUntil(this.order.header.status);
						this.statusBarOrderB2cService.setCondensed(false);
						// console.log('expanded', this.order.header.status);
						// console.log(this.statusBarOrderB2cService.config.steps);
					}
				}
			});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}
}
