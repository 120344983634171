<div class="container">
	<div class="row">
		<div class="col-sm-12 col-md-6 col-lg-6 general-info__data">
			<!-- Dati personali -->
			<div class="general-info__full-data">
				<organization-detail-full-detail></organization-detail-full-detail>
			</div>

			<!-- Contatti -->
			<div class="general-info__contacts">
				<contact></contact>
			</div>
		</div>

		<div class="col-sm-12 col-md-5 offset-md-1 col-lg-5 offset-lg-1 general-info__illustration">
			<app-illustration-account [theme]="'accent'"></app-illustration-account>
		</div>
	</div>
</div>
