<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<ng-container *ngIf="offer && offer.line_list  && offer.line_list.length; else noData">
					<div class="line" *ngFor="let line of offer.line_list; let i = index">
						<div class="line__description">{{ line }}</div>
						<div class="line__actions">
							<button
								mat-icon-button
								aria-label="Edit comment"
								[matTooltip]="'offer.line.edit' | translate | sentencecase"
								(click)="editLine(i)"
							>
								<mat-icon>edit</mat-icon>
							</button>
							<button
								mat-icon-button
								aria-label="Delete comment"
								[matTooltip]="'offer.line.remove' | translate | sentencecase"
								(click)="deleteLine(i)"
							>
								<mat-icon>clear</mat-icon>
							</button>
						</div>
					</div>
				</ng-container>
				<ng-template #noData>
					<p class="placeholder">
						{{ 'offer.line.empty' | translate | sentencecase }}
					</p>
				</ng-template>
			</mat-card>
		</div>
	</div>
	
	<button
		class="mat-fab-button"
		(click)="addLine()"
		mat-fab
		[matTooltip]="'offer.line.add_new' | translate | sentencecase"
		matTooltipPosition="left"
	>
		<mat-icon>add</mat-icon>
	</button>
	
</div>