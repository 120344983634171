<ms-loading-overlay></ms-loading-overlay>
<div class="align-items-center">
	<div class="wrapper_page">
		<div>
			<div class="header_cliente">
				<div class="logo-container"></div>
			</div>

			<mat-card>
				<div class="wrapper_header">
					<div class="header_spinotto">
						<!-- <img class="logo" src="../../../../assets/img/logo/irinox/irinox-payoff-logo.svg" /> -->
					</div>
				</div>
				<mat-card-content>
					<router-outlet></router-outlet>
				</mat-card-content>
			</mat-card>

			<div class="wrapper_footer">
				<div class="wrapper_footer_logo">
					<a class="logo_saep" href="https://www.saep-ict.it/" target="_blank">
						<img src="assets/img/logo/SPIN8/v2/logo-SPIN8-generic-grey.svg" alt="logo SPIN8" />
					</a>
					<span class="version">ver {{ appTag }}</span>
				</div>
			</div>
		</div>
		<!-- <div class="hexagon">
			<img src="assets/img/login-decoration.png" alt="decorazione login" />
		</div> -->
	</div>
</div>
