<tab-wrapper *ngIf="organization" [toExclude]="toExclude">
	<breadcrumb
		[title]="organization.business_name"
		[pathList]="['Home', 'company.name_with_code' | translate: { code_erp: organization.code_erp }]"
		[badge]="
			(organization.status && currentContext !== contextApplicationItemCodeEnum.B2B
				? 'company.status.' + organization.status
				: ''
			)
				| translate
				| sentencecase
		"
		[badgeClass]="'badge-light'"
	></breadcrumb>

	<!-- BACKOFFICE CONTEXT -->
	<mat-toolbar
		*ngIf="
			organization.level === organizationLevelEnum.LEAD &&
			currentContext === contextApplicationItemCodeEnum.BACKOFFICE
		"
		class="toolbar organization-validation"
	>
		<img src="assets/img/icon/warning-sign.png" class="warning-sign" alt="Warning Sign" />
		<span class="message">
			{{ 'organization.validation.message' | translate | sentencecase }}
		</span>
		<button mat-flat-button color="primary" (click)="organizationValidation()">
			{{ 'organization.validation.valid' | translate | sentencecase }}
		</button>
	</mat-toolbar>
	<!-- END BACKOFFICE CONTEXT -->

	<organization-header [organizationHeaderConfig]="entityHeaderConfig"></organization-header>
</tab-wrapper>

<!-- <floating-menu
	class="mat-fab-button"
	[menuItems]="navigationFloatingMenuService.organizationDetailFloatinMenu"
	(actionEmitter)="onFloatingMenuClicked($event)"
></floating-menu> -->
