import { BaseTableListService } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderDashboardColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	// _columns = [
	//   {
	//     name: "organization.business_name",
	//     label: "Azienda"
	//   },
	//   {
	//     name: "Address",
	//     label: "Indirizzo",
	//     width: 250
	//   },
	//   {
	//     name: "tot",
	//     label: "Spesa media",
	//     numeric: true,
	//     width: 150,
	//     format: (v: number) => v.toFixed(2).replace('.', ',') + " €" // TODO fare check se corretto
	//   }
	// ];

	_columns = [
		{
			name: 'header.company',
			label: 'Azienda'
		},
		{
			name: 'header.order_number',
			label: 'Ordine #',
			numeric: true,
			width: 150
		},
		{
			name: 'header.customer_order_ref_date',
			label: 'Data creazione',
			numeric: true,
			width: 150
		},
		{
			name: 'header.first_evasion_date',
			label: 'Data consegna',
			numeric: true,
			width: 150
		},
		{
			name: 'header.status',
			label: 'Stato',
			width: 70
		},
		{
			name: 'tot',
			label: 'Importo',
			numeric: true,
			width: 150
		}
	];
}
