import { Component, Input, OnInit } from '@angular/core';
import { PATH_URL, ROUTE_URL } from '../../router/route-naming';

export enum OperationState {
	success,
	error
}

interface BannerStateContent {
	icon: string;
	mainText: string;
	detailText: string;
	action: string;
	actionLink: string;
}

@Component({
	selector: 'operation-state-banner',
	templateUrl: './operation-state-banner.component.html',
	styleUrls: ['./operation-state-banner.component.scss']
})
export class OperationStateBannerComponent implements OnInit {
	@Input() state: OperationState;

	content: BannerStateContent;

	constructor() {}

	ngOnInit() {
		if (this.state == OperationState.success) {
			this.content = {
				icon: 'check_circle',
				mainText: 'Ordine inviato con successo',
				detailText: 'Riceverai una email di conferma',
				action: 'Area riservata',
				actionLink: PATH_URL.MY_ACCOUNT
			};
		} else if (this.state == OperationState.error) {
			this.content = {
				icon: 'error',
				mainText: "C'è stato un problema",
				detailText: 'Il pagamento non è andato a buon fine',
				action: 'Torna al carrello',
				actionLink: PATH_URL.CART
			};
		}
	}
}
