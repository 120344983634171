<breadcrumb [title]="'opportunity.name' | translate | sentencecase" [pathList]="['Home']"></breadcrumb>

<div
	list-wrapper
	#listWrapper
	[filterOpened]="false"
	[titleSidebar]="opportunitySelected ? opportunitySelected.title : ''"
	sidenavWidth="400px"
>
	<ng-container main>
		<div class="container">

			<!-- Filters -->
			<div class="row">

				<!-- Company -->
				<div class="col-md-3">
					<mat-form-field>
						<mat-select
							placeholder="Company"
							[value]="companyFilter"
							(selectionChange)="changeCompany($event)"
						>
							<mat-option *ngFor="let item of companyList" [value]="item">
								{{ item.label | translate | sentencecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Owner / Assegnee -->
				<div class="col-md-3">
					<mat-form-field>
						<mat-select
							placeholder="Proprietario"
							[value]="ownerFilter"
							(selectionChange)="changeOwner($event)"
						>
							<mat-option *ngFor="let item of ownerList" [value]="item">
								{{ item.label | translate | sentencecase }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- Solo opportunity aperte -->
				<div class="col-md-3 center-vertically">
					<mat-checkbox (change)="changeOpenOpportunity($event)" [checked]="statusOpenFilter.filter">{{ statusOpenFilter.label }}</mat-checkbox>
				</div>

				<div class="col-md-6"></div>
			</div>
		</div>

		<kanban-container
			[columnList]="kanbanColumns"
			(cardSorted)="onCardSorted($event)"
			(cardMoved)="onCardMoved($event)"
		></kanban-container>
	</ng-container>

	<ng-container side>
		<ng-container *ngIf="opportunitySelected">
			<mat-tab-group mat-align-tabs="start">
				<!-- Anteprima -->
				<mat-tab label="{{ 'general.preview' | translate | sentencecase }}">
					<div class="sidebar__preview">
						<ng-scrollbar [autoWidthDisabled]="false" [autoHeightDisabled]="false">
							<div class="sidebar__preview__content">
								<!-- Rating -->
								<opportunity-rating [opportunity]="opportunitySelected"></opportunity-rating>

								<!-- Status -->
								<p>
									{{ 'opportunity.field.status_code' | translate | sentencecase }}:
									{{
										'opportunity.status.' + opportunitySelected.status_code
											| translate
											| sentencecase
									}}
								</p>

								<!-- Step -->
								<mat-form-field>
									<mat-select
										placeholder="Step"
										[value]="opportunitySelected.step_id"
										(selectionChange)="changeStep($event)"
									>
										<mat-option *ngFor="let item of opportunityStep.stepList" [value]="item.id">
											{{ item.name | translate | sentencecase }}
										</mat-option>
									</mat-select>
								</mat-form-field>

								<!-- Data di chiusura stimata -->
								<span class="icon-label">
									<mat-icon>access_alarm</mat-icon>
									<span [matTooltip]="'Data di chiusura stimata'">
										{{
											opportunitySelected.estimated_close_date
												? (opportunitySelected.estimated_close_date | date: 'dd/MM/yyyy')
												: '-'
										}}
									</span>
								</span>

								<!-- Data di followup -->
								<span class="icon-label">
									<mat-icon>perm_phone_msg</mat-icon>
									<span [matTooltip]="'Data di followup'">
										{{
											opportunitySelected.schedule_followup_prospect
												? (opportunitySelected.schedule_followup_prospect | date: 'dd/MM/yyyy')
												: '-'
										}}
									</span>
								</span>

								<!-- Valore opportunità -->
								<span class="icon-label">
									<mat-icon>account_balance_wallet</mat-icon>
									<span [matTooltip]="'Valore opportunità'">
										{{
											opportunitySelected.actual_value
												? (opportunitySelected.actual_value | currency: 'EUR':'symbol':'':'it')
												: '-'
										}}
									</span>
								</span>

								<!-- Separatore -->
								<div class="mt-32"></div>

								<!-- Organization -->
								<div class="icon-label">
									<mat-icon>business</mat-icon>
									<span>
										{{
											opportunitySelected.organization.crm.organization_rating
												? opportunitySelected.organization.crm.organization_rating + '|'
												: ''
										}}
										<a
											href="javascript:void(0)"
											[routerLink]="[
												'/',
												ROUTE_URL.private,
												ROUTE_URL.organizations,
												opportunitySelected.organization.level,
												opportunitySelected.organization.code
											]"
										>
											{{ opportunitySelected.organization.business_name }}
										</a>
										<br />
										<span class="opacity-50" *ngIf="defaultOrganizationAddress">
											{{ defaultOrganizationAddress.locality }}
											({{ defaultOrganizationAddress.province }})
										</span>
									</span>
								</div>

								<!-- Contact -->
								<span class="icon-label">
									<mat-icon>person</mat-icon>
									<span>
										{{ opportunitySelected.contact_name || '-' }}
									</span>
								</span>

								<!-- Separatore -->
								<div class="mt-32"></div>

								<!-- assegnee -->
								<span class="icon-label">
									<avatar [avatarConfigClass]="avatarConfigClass"></avatar>
									<span>{{ opportunitySelected.assegnee_full_name }}
										<br />
										<span class="opacity-50">
											{{ opportunitySelected.assegnee_user_name }}
										</span>

									</span>

								</span>
							</div>
						</ng-scrollbar>

						<div class="sidebar__preview__footer">
							<button
								mat-raised-button
								color="primary"
								(click)="openOpportunityDetail(opportunitySelected)"
							>
								{{ 'opportunity.detail' | translate | sentencecase }}
							</button>
						</div>
					</div>
				</mat-tab>

				<!-- Commenti -->
				<mat-tab
					label="{{ 'comment.name_plural' | translate | sentencecase }} ({{
						opportunitySelected.comment_list?.length || 0
					}})"
				>
					<ng-scrollbar [autoWidthDisabled]="false" [autoHeightDisabled]="false">
						<div class="sidebar__comment__content">
							<comment-thread [commentList]="opportunitySelected.comment_list"></comment-thread>
						</div>
					</ng-scrollbar>

					<div class="sidebar__comment__footer">
						<!-- <mat-form-field>
							<mat-label>{{ 'comment.add' | translate | sentencecase }}...</mat-label>
							<input matInput type="text" [(ngModel)]="addCommentValue" autocomplete="off" />
							<button
								mat-button
								[disabled]="!addCommentValue"
								matSuffix
								mat-icon-button
								(click)="addComment()"
							>
								<mat-icon>send</mat-icon>
							</button>
						</mat-form-field> -->
					</div>
				</mat-tab>
			</mat-tab-group>
		</ng-container>
	</ng-container>
</div>
