import { BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MediaReplayService } from '@saep-ict/angular-core';
import { tap } from 'lodash';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { PsToTopService } from '../../widget/ps-to-top/ps-to-top.service';
import { Router } from '@angular/router';

@Component({
	selector: 'public-wrapper',
	templateUrl: './public-wrapper.component.html',
	styleUrls: ['./public-wrapper.component.scss']
})
export class PublicWrapperComponent implements OnInit, AfterViewInit {
	@ViewChild('perfectScrollbar') scrollbarRef: NgScrollbar;
	// Unsubscriber for elementScrolled stream.
	private scrollSubscription = Subscription.EMPTY;

	private _mediaSubscription: Subscription;
	private _routerSubscription: Subscription;

	constructor(
		public mediaReplayService: MediaReplayService,
		private cdr: ChangeDetectorRef,
		private scrollToTopService: PsToTopService,
		@Inject(PLATFORM_ID) private platform_id: string,
		router: Router
	) {
		if (isPlatformBrowser(this.platform_id)) {
			this.mediaReplayService.deviceH = window.innerHeight;
			this.mediaReplayService.deviceW = window.innerWidth;
		}

		// Scroll to top on change routing
		this._routerSubscription = router.events.pipe(debounceTime(100)).subscribe(val => {
			this.scrollToTop();
		});
	}

	ngOnInit() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media
			.pipe(debounceTime(100))
			.subscribe((change: BreakpointState) => {
				if (isPlatformBrowser(this.platform_id)) {
					this.mediaReplayService.deviceH = window.innerHeight;
					this.mediaReplayService.deviceW = window.innerWidth;
				}
			});
	}

	ngAfterViewInit() {
		// Subscribe to scroll event
		this.scrollbarRef.verticalScrolled
			.pipe(map((e: any) => this.scrollToTopService.updateShowToTop(e.target.scrollTop > 100)))
			.subscribe();
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}

		if (this._routerSubscription) {
			this._routerSubscription.unsubscribe();
		}

		this.scrollSubscription.unsubscribe();
	}

	scrollToTop() {
		this.scrollbarRef.scrollTo({ top: 0, duration: 300 });
	}
}
