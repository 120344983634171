import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { UtilService } from '@saep-ict/angular-core';

@Component({
	selector: 'client-list-wrapper',
	templateUrl: './client-list-wrapper.component.html',
	styleUrls: ['./client-list-wrapper.component.scss']
})
export class ClientListWrapperComponent extends LocalListHandlerWidgetWrapper {
	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}
}
