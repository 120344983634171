import { Injectable } from '@angular/core';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';

// TODO - Temporary model based on AddressPouchModel
export class OrderModel {
	status: string;
	id: string;
	// tslint:disable-next-line: variable-name
	date_creation: string;
	// tslint:disable-next-line: variable-name
	date_delivery: string;
	address: AddressPouchModel;
	total: number;
	delivery_method: string;
	payment_method: string;
	note: string;
}

@Injectable({
	providedIn: 'root'
})
export class OrderMockupService {
	addressList: AddressPouchModel[] = [
		{
			country: 'Italia',
			address: 'Via Ascanio Sforza, 87',
			locality: 'Milano',
			province: 'MI',
			zip_code: '20141'
		},
		{
			country: 'Italia',
			address: 'Via Canturina, 69',
			locality: 'Como',
			province: 'CO',
			zip_code: '22100'
		},
		{
			country: '',
			address: 'Via Giacomo, 15',
			locality: 'Genova',
			province: 'CO',
			zip_code: ''
		}
	];

	orderList: OrderModel[] = [
		{
			status: 'CONSOLIDATED',
			id: '001',
			date_creation: '1601646124395',
			date_delivery: '1602280800000',
			address: this.addressList[0],
			total: 100.88,
			delivery_method: 'Express (1 day) - 5,00€',
			payment_method: 'Carta di credito',
			note:
				'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Donec adipiscing tristique risus nec feugiat in fermentum posuere urna. Ultricies integer quis auctor elit'
		}
	];
}
