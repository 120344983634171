import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// store
import { Store } from '@ngrx/store';
import { AgentListActionEnum } from '../../../state/backoffice/context-code/agent-list/agent-list.actions';
import { BackofficeUserListActionEnum } from '../../../state/backoffice/context-code/backoffice-user-list/backoffice-user-list.actions';
import { OrganizationListActionEnum } from '../../../state/common/organization-list/organization-list.actions';

// widget & utility
import { TdDataTableComponent, ITdDataTableRowClickEvent } from '@covalent/core/data-table';
import { Observable } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import {
	UtilService,
	ContextTypeAndApplicationLink,
	ContextCodeItemLink,
	ContextCodeItem
} from '@saep-ict/angular-core';
import { PermissionContextListColumnService } from '../../../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { DialogCreateContextCodeComponent } from '../../../widget/dialog/dialog-create-context-code/dialog-create-context-code.component';
import {
	SubscribeManagerService,
	BaseStateModel,
	LinkDetailModel,
	ContextApplicationItemCodeEnum,
	LocalListHandlerBase,
	LocalListHandlerBaseModel
} from '@saep-ict/angular-core';
import { B2cUserListActionEnum } from '../../../state/backoffice/context-code/b2c-user-list/b2c-user-list.actions';
import { TranslateService } from '@ngx-translate/core';
import { CrmUserListActionEnum } from '../../../state/backoffice/context-code/crm-user-list/crm-user-list.actions';
import { contextTypeAndApplicationLink } from '../../../constants/configuration-customer/context-application/context-application.constants';

@Component({
	selector: 'context-code-management',
	templateUrl: './context-code-management.component.html',
	styleUrls: ['./context-code-management.component.scss'],
	providers: [SubscribeManagerService, PermissionContextListColumnService]
})
export class ContextCodeManagementComponent extends LocalListHandlerBase implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	localListHandlerData = <LocalListHandlerBaseModel<ContextCodeItem>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: ['code', 'description']
			}
		},
		pagination: {
			pageSize: 5
		},
		sort: {
			name: 'code',
			order: 'ASC'
		}
	};

	contextTypeAndApplicationLink: ContextTypeAndApplicationLink;
	isLoading = true;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public permissionContextListColumnService: PermissionContextListColumnService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService,
		private snackBar: MatSnackBar,
		public utilService: UtilService,
		private dialog: MatDialog,
		private translate: TranslateService
	) {
		super(utilService);
		this.setVisibleTableColumn();
		this.subscribeManagerService.populate(this.routeParamSubscribe().subscribe(), 'route-param-state');
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'context-code-list');
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.permissionUtilService.resetContextCodeListAssociatedState();
	}

	// subscribe
	routeParamSubscribe(): Observable<ContextTypeAndApplicationLink> {
		return this.route.paramMap.pipe(
			map((routeParam: ParamMap) => {
				this.localListHandlerData.dataSubset = [];
				this.isLoading = true;

				this.refreshTable();
				// resetto gli state
				this.permissionUtilService.resetContextCodeListAssociatedState();

				this.contextTypeAndApplicationLink = contextTypeAndApplicationLink.find(
					i =>
						i.context_application_item_code ===
						ContextApplicationItemCodeEnum[routeParam.get('contextApplicationType').toUpperCase()]
				);
				if (this.contextTypeAndApplicationLink) {
					// faccio la dispatch per reperire i dati
					this.loadContextCodeList();
				} else {
					// TODO: gestire l'accidentale inserimento di un errato parametro contextApplicationType in URL
					this.snackBar.open('Context Application Not Found', 'OK', { duration: 5000 });
				}
				return this.contextTypeAndApplicationLink;
			})
		);
	}

	subscribeContextCodeList(): Observable<BaseStateModel<LinkDetailModel[]>> {
		return this.permissionUtilService.subscribeContextCodeList().pipe(
			filter(
				(state: BaseStateModel<ContextCodeItem[]>) =>
					state.type === BackofficeUserListActionEnum.UPDATE_LIST ||
					state.type === AgentListActionEnum.UPDATE_LIST ||
					state.type === B2cUserListActionEnum.UPDATE_LIST ||
					state.type === CrmUserListActionEnum.UPDATE_LIST ||
					state.type === OrganizationListActionEnum.UPDATE
			),
			map((state: BaseStateModel<ContextCodeItem[]>) => {
				this.localListHandlerData.data = state.data;
				this.localListHandlerApplyFilter();
				return state;
			}),
			catchError((error, caught) => {
				console.log(error);
				this.localListHandlerData.data = [];
				this.localListHandlerApplyFilter();
				return caught;
			})
		);
	}

	// load
	loadContextCodeList() {
		this.store.dispatch(this.contextTypeAndApplicationLink.context_code_item.action.loadList());
	}

	// navigation
	goToDetail(e: ITdDataTableRowClickEvent) {
		this.router.navigate([e.row.code], { relativeTo: this.route });
	}

	// dialog
	dialogCreateContextCodeItem(context_code_item: ContextCodeItemLink) {
		const dialogRef: MatDialogRef<DialogCreateContextCodeComponent> = this.dialog.open(
			DialogCreateContextCodeComponent,
			{
				data: context_code_item,
				disableClose: true,
				panelClass: 'dialog-medium'
			}
		);
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// Messaggio gestito dalla dialog
				this.loadContextCodeList();
			}
		});
	}

	// widget & utility
	setVisibleTableColumn() {
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'permission')
		].hidden = true;
		this.permissionContextListColumnService.columns[
			this.utilService.getElementIndex(this.permissionContextListColumnService.columns, 'name', 'action')
		].hidden = true;
	}

	refreshTable() {
		if (this.dataTable) {
			this.dataTable.refresh();
		}
	}
}
