<div class="container-fluid">
	<!-- Ordini: Ordini attuali -->
	<div class="row">
		<div class="col-4">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigCurrentOrdersConfirmed">
				<div class="value-orders">{{ getOrderTypeCount('confirmed') }}</div>
			</card-dashboard>
		</div>
		<div class="col-4">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigCurrentOrdersConsolidated">
				<div class="value-orders">
					{{ getOrderTypeCount('consolidated') }}
				</div>
			</card-dashboard>
		</div>
		<div class="col-4">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigCurrentOrdersPartiallyFulfilled">
				<div class="value-orders">{{ getOrderTypeCount('partially_fulfilled') }}</div>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Ordini: Ordini recenti -->
		<div class="col-sm-12 col-md-12 col-lg-12">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigOrdersRecent">
				<order-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseLastOrders"
					[localListHandlerWidgetWrapperColumnList]="columns"
					(selectOrderId)="goToOrderDetail($event)"
					(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code, null)"
				>
				</order-list-wrapper>
			</card-dashboard>
		</div>

		<!-- <div class="col-sm-12 col-md-12 col-lg-4 orders"> -->
		<!-- Ordini: Valore medio -->
		<!-- <div class="orders__value">
				<card-dashboard
					[cardDashboardConfig]="orderAverageChartConfigService.cardDashboardConfigOrdersAverage"
					(changeTimeRange)="
						orderAverageChartConfigService.changeTimeRange($event, orderAverageChartConfigService)
					"
				>
					<div class="row">
						<div class="col-6 m-0">
							<div class="value-trend" *ngIf="orderAverageChartConfigService.metrics">
								<div class="value-trend__value">
									{{ orderAverageChartConfigService.metrics.average | appFormatMultiplesCurrency }}
								</div>
								<div class="value-trend__trend">
									<icon-trend [value]="orderAverageChartConfigService.metrics.trend"></icon-trend>
								</div>
							</div>
						</div>
						<div class="col-6 m-0">
							<chart-wrapper [config]="orderAverageChartConfigService.chart"></chart-wrapper>
						</div>
					</div>
				</card-dashboard>
			</div> -->
		<!-- </div> -->
	</div>

	<!-- PRODUCTS 
	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="orderProductsChartConfigService.cardDashboardConfigProductsBestSeller"
			>
				<ng-container *ngIf="orderProductsChartConfigService.chart.datasets.length && timeRange">
					<div class="row">
						<div class="col-12">
							<chart-wrapper [config]="orderProductsChartConfigService.chart"></chart-wrapper>
						</div>
					</div>

					<div class="row">
						<div class="col-6 m-0">
							<mat-form-field class="card-dashboard__actions__timerange">
								<mat-select
									[value]="timeRange.values[0]"
									(selectionChange)="setTimeRangeProducts($event)"
								>
									<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
										{{
											'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase
										}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</ng-container>
			</card-dashboard>
		</div>
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="orderProductsTableConfigService.cardDashboardConfigProductsTop5">
				<ng-container *ngIf="orderProductsTableConfigService.currentDataSet.length; else noData">
					<td-data-table
						[data]="orderProductsTableConfigService.currentDataSet"
						[columns]="orderProductsTableConfigService.columns"
					>
						<ng-template tdDataTableTemplate="description" let-value="value">
							<p>
								{{ value }}
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="value" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div>
	</div>
	-->

	<!-- <div class="row"> -->
		<!-- Ordini: Numero ordini giornaliero -->
		<!-- <div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				class="card-dashboard-accent"
				[cardDashboardConfig]="orderQuantityChartConfigService.cardDashboardConfigOrdersQuantity"
				(changeTimeRange)="
					orderQuantityChartConfigService.changeTimeRange($event, orderQuantityChartConfigService)
				"
			>
				<div class="row">
					<div class="col-12 m-0">
						<div class="value-trend" *ngIf="orderQuantityChartConfigService.metrics">
							<div class="value-trend__value">
								{{ orderQuantityChartConfigService.metrics.average | number: '1.2-2':'it' }}
							</div>
							<div class="value-trend__trend">
								<icon-trend [value]="orderQuantityChartConfigService.metrics.trend"></icon-trend>
							</div>
						</div>
						<chart-wrapper [config]="orderQuantityChartConfigService.chart"></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div> -->

		<!-- Acquistato: grafico categorie più acquistate -->
		<!-- <div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="orderCategoriesChartConfigService.cardDashboardConfigCategoriesBestSeller"
			>
				<ng-container *ngIf="orderCategoriesChartConfigService.chart.datasets.length && timeRange">
					<div class="row category-chart">
						<div class="col-12">
							<chart-wrapper [config]="orderCategoriesChartConfigService.chart"></chart-wrapper>
						</div>
					</div>

					<div class="row"> -->
						<!-- Range temporale -->
						<!-- <div class="col-6 m-0"> -->
							<!-- Timerange -->
							<!-- <mat-form-field class="card-dashboard__actions__timerange">
								<mat-select
									[value]="timeRange.values[0]"
									(selectionChange)="setTimeRangeCategories($event)"
								>
									<mat-option *ngFor="let rangeTemp of timeRange.values" [value]="rangeTemp">
										{{
											'general.time.last_x_days' | translate: { amount: rangeTemp } | sentencecase
										}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div>
				</ng-container>
			</card-dashboard>
		</div> -->

		<!-- Venduto: lista categories più vendute -->
		<!-- <div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="orderCategoriesTableConfigService.cardDashboardConfigCategoriesTop5">
				<ng-container *ngIf="orderCategoriesTableConfigService.currentDataSet.length; else noData">
					<td-data-table
						[data]="orderCategoriesTableConfigService.currentDataSet"
						[columns]="orderCategoriesTableConfigService.columns"
					>
						<ng-template tdDataTableTemplate="description" let-row="row">
							<p>
								{{ translateService.instant(`category.label.${row.code}`) }}
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="value" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div> -->
	<!-- </div> -->

	<!-- Data ultimo aggiornamento statistiche -->
	<!-- <div class="row">
		<div class="col-12 statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div> -->
</div>
