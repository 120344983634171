import { BaseStatusBarConfigService, StatusBarConfig } from '@saep-ict/angular-core';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class StatusBarOrderB2cService extends BaseStatusBarConfigService {
	constructor() {
		super();
	}

	_config = {
		condensed: false,
		theme: 'white',
		steps: [
			// Bozza
			{
				id: OrderStatusEnum.DRAFT,
				label: 'Bozza',
				icon: 'create',
				selected: false
			},

			// In produzione
			{
				id: OrderStatusEnum.CONSOLIDATED,
				label: 'In produzione',
				icon: 'build',
				selected: false
			},

			// Archiviati
			{
				id: OrderStatusEnum.FULFILLED,
				label: 'Archiviato',
				icon: 'archive',
				selected: false
			}
		]
	};

	// TODO - importare nella libreria
	setCondensed(isCondensed: boolean) {
		const newConfig = _.cloneDeep(this._config);
		newConfig.condensed = isCondensed;
		this._config = newConfig;
	}

	// TODO - importare nella libreria
	selectUntil(key: string) {
		let notFound = true;
		this._config.steps.map(step => {
			step.selected = notFound;
			if (step.id === key) {
				notFound = false;
			}
		});
	}
}
