import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UtilService } from '@saep-ict/angular-core';
import { OpportunityPouchModel } from '../../../model/state/opportunity-state.model';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'opportunity-list-wrapper',
	templateUrl: './opportunity-list-wrapper.component.html',
	styleUrls: ['./opportunity-list-wrapper.component.scss']
})
export class OpportunityListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() title: string;
	@Input() showFilterIcon: boolean;
	@Output() selectOpportunityId: EventEmitter<OpportunityPouchModel> = new EventEmitter();
	@Output() openFilterSidebar: EventEmitter<string> = new EventEmitter();

	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}
}
