import { AbstractPouchdbMethod, ILoaderService } from '@saep-ict/pouch-db';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel, LoaderService } from '@saep-ict/angular-core';
import { HttpClient } from '@angular/common/http';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { PouchDeleteResponse } from '../../model/pouch-base-response.model';

interface ExtContactPouchModel extends ContactPouchModel {
	source?: string;
}
export class ContactPouch extends AbstractPouchdbMethod {
	constructor(
		activeDB: PouchDB.Database,
		httpClient: HttpClient,
		appConfig: CustomerAppConfig,
		private loader?: ILoaderService
	) {
		super(activeDB, httpClient, appConfig, loader);
	}

	getContact(filter: BaseStateModel<{ _id: string }>): Promise<ListDataPouchModel<ContactPouchModel>> {
		return new Promise((resolve, reject) => {
			this.get(filter.data._id)
				.then((doc: ContactPouchModel) => {
					const doc_list: ListDataPouchModel<ContactPouchModel> = { docs: [doc] };
					resolve(doc_list);
				})
				.catch(err => {
					console.error(err);
					reject(err);
				});
		});
	}

	async createContact(contact: ExtContactPouchModel): Promise<ContactPouchModel> {
		if (contact) {
			const guid = (this.loader as LoaderService).guid();
			contact.type = 'contact';
			contact.source = 'APPLICATION';
			if (!contact._id) {
				contact._id = `contact_${guid}`;
			}
			if (!contact.code_item) {
				contact.code_item = guid;
			}
			contact.valid = true;
			return new Promise((resolve, reject) => {
				this.put<ContactPouchModel>(contact, contact._id)
					.then(res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					});
			});
		} else {
			return Promise.reject("can't save contact: contact not set");
		}
	}

	async updateContact(contact: ContactPouchModel): Promise<ContactPouchModel> {
		let ctc: ExtContactPouchModel = (await this.get(contact._id)) as ContactPouchModel;
		console.log(ctc);
		if (ctc) {
			ctc = {
				...ctc,
				...contact
			};
			ctc.type = 'app_contact';
			ctc.source = 'APPLICATION';
			return new Promise((resolve, reject) => {
				this.put<ContactPouchModel>(ctc, ctc._id)
					.then(res => {
						resolve(res);
					})
					.catch(err => {
						console.error(err);
						reject(err);
					});
			});
		} else {
			return Promise.reject(`can't update contact ${contact._id}: contact not found`);
		}
	}

	getTableContactList(): Promise<ContactPouchModel[]> {
		return new Promise((resolve, reject) => {
			this.get('table_contact_list')
				.then((doc: { values: ContactPouchModel[] }) => {
					resolve(doc.values);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteContact(contact: ContactPouchModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(contact)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
