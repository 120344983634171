import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { CategoryListAction, CategoryListActionEnum } from './category-list.actions';
// model
import { Category } from '../../model/category-list.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleCategory } from '../../model/article.model';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';

export const initialState: BaseStateModel<Category<ArticleCategory>[]> = null;

const _reducer = createReducer(
	initialState,
	on(CategoryListAction.update, (state, param) => param),
	on(CategoryListAction.loadRecursively, state => state),
	on(CategoryListAction.loadAll, state => state),
	on(CategoryListAction.notExisting, state => ({ type: CategoryListActionEnum.NOT_EXISTING, ...state })),
	on(CategoryListAction.reset, (state, param) => initialState)
);

export function reducer(
	state: BaseStateModel<Category<ArticleCategory>[]>,
	action: TypedAction<CategoryListActionEnum>
) {
	return _reducer(state, action);
}
