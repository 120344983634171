<div class="slider">
	<div class="slider__arrow slider__arrow__left">
		<button mat-mini-fab class="slider-arrow" color="primary" (click)="changeSliderIndex(selectedIndex - 1)">
			<mat-icon>chevron_left</mat-icon>
		</button>
	</div>
	<div class="slider__main">
		<div
			class="slider__main__content"
			[style.min-height]="'50px'"
			*ngIf="dataConfig.data && dataConfig.data.length > 0 && contentTemplate; else no_data"
		>
			<ng-container *ngFor="let chunck of chuncks; let idx = index">
				<div
					class="slider__main__content__cards"
					[ngClass]="[selectedIndex ? 'state-' + selectedIndex : 'in']"
					[@slide]="{
						value: selectedIndex ? 'state-' + selectedIndex : 'in',
						params: {
							left_position: -selectedIndex,
							timing: dataConfig.animation.animationDuration,
							easing: dataConfig.animation.easing
						}
					}"
				>
					<!-- <ng-template ngFor let-item [ngForOf]="[]" ></ng-template> -->
					<ng-container *ngFor="let item of chunck; let index = index">
						<!-- {{item.description}} -->
						<ng-container
							*ngTemplateOutlet="contentTemplate; context: { $implicit: item, indice: index }"
						></ng-container>
					</ng-container>
				</div>
			</ng-container>
		</div>
		<ng-template #no_data>
			<div class="slider__main__content" [style.min-height]="'48px'" [style.justify-content]="'center'">
				<h1>No data to show</h1>
			</div>
		</ng-template>
		<div class="slider__main__points">
			<mat-icon
				*ngFor="let i of chuncks; let idx = index"
				class="icon-button-bullet"
				matRipple
				(click)="changeSliderIndex(idx)"
			>
				{{ idx === selectedIndex ? 'radio_button_checked' : 'radio_button_unchecked' }}
			</mat-icon>
		</div>
	</div>
	<div class="slider__arrow slider__arrow__right">
		<button mat-mini-fab class="slider-arrow" color="primary" (click)="changeSliderIndex(selectedIndex + 1)">
			<mat-icon>chevron_right</mat-icon>
		</button>
	</div>
</div>
