import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { B2cUserListStateReducer  } from "./b2c-user-list.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('b2c-user-list', B2cUserListStateReducer.reducer)
    ]
})
export class B2cUserListStateModule{}
