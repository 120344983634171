<div class="container">
	<div class="row" *ngIf="list && list.length > 0">
		<div
			*ngFor="let cards of pageSlice"
			class="pt-3 {{ viewType === ViewTypeEnum.list ? 'col-12' : 'col-sm-12 col-md-6 col-lg-4' }}"
		>
			<app-card-product [itemType]="ItemType.product" [data]="cards" [viewType]="viewType"></app-card-product>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<mat-paginator [length]="list.length" [pageSize]="8" [pageSizeOptions]="[4, 8, 12]" (page)="eventP($event)">
			</mat-paginator>
		</div>
	</div>
</div>
