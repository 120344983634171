import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilKanbanService } from '../../../service/util/util-kanban.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { KanbanMoveEvent } from '../model/KanbanMoveEvent';
import { KanbanSortEvent } from '../model/KanbanSortEvent';

@Component({
	selector: 'kanban-column',
	templateUrl: './kanban-column.component.html',
	styleUrls: ['./kanban-column.component.scss']
})
export class KanbanColumnComponent implements OnInit {
	@Input() name = '';
	@Input() columnId: string;
	@Input() opportunityList = [];

	@Output() cardSorted: EventEmitter<KanbanSortEvent> = new EventEmitter();
	@Output() cardMoved: EventEmitter<KanbanMoveEvent> = new EventEmitter();

	constructor(public utilKanbanService: UtilKanbanService) {}

	ngOnInit() {}

	drop(event: CdkDragDrop<any[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			if (event.previousIndex !== event.currentIndex) {
				this.cardSorted.emit(<KanbanSortEvent>{
					columnId: this.columnId,
					column: event.container.data,
					previousIndex: event.previousIndex,
					currentIndex: event.currentIndex
				});
			}
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			this.cardMoved.emit(<KanbanMoveEvent>{
				currentColumnId: this.columnId,
				currentIndex: event.currentIndex,
				currentColumn: event.container.data,

				previousIndex: event.previousIndex,
				previousColumn: event.previousContainer.data,
			});
		}
	}

}
