import { Injectable } from '@angular/core';
import { IResourceMethodPromise, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core';
import { RestBaseResponse } from '@saep-ict/angular-core';
import { InformativePageModel } from '../../model/informative-page.model';
import { BaseApiService } from './baseApi.service';

@Injectable()
export class InformativePageService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/public/informative-page'
	})
	getInformativePage: IResourceMethodPromise<{ slug: string }, RestBaseResponse<InformativePageModel>>;
}
