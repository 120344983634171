<ng-scrollbar [autoWidthDisabled]="true" [autoHeightDisabled]="false" track="horizontal">
	<div class="kanban-container__wrapper" cdkDropListGroup>
		<kanban-column
			(cardSorted)="cardSorted.emit($event)"
			(cardMoved)="cardMoved.emit($event)"
			*ngFor="let column of columnList"
			[columnId]="column.id"
			[name]="column.name"
			[opportunityList]="column.cardList"
		>
		</kanban-column>
	</div>
</ng-scrollbar>