import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ContextApplicationItemCodeEnum, SentencecasePipe, UtilColorService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { chartTimeRange7Days } from '../../../constants/chart-time-range.constants';
import { ChartThemeEnum } from '../../../enum/chart-theme.enum';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { AppUtilService } from '../../util/app-util.service';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';

@Injectable()
export class OrderProductsChartConfigService extends BaseChartClass {
	cardDashboardConfigProductsBestSeller: CardDashboardConfig = {
		title:
			this.context === ContextApplicationItemCodeEnum.B2B
				? 'chart.products_best_seller.title_customer'
				: 'chart.products_best_seller.title_seller',
		subtitle:
			this.context === ContextApplicationItemCodeEnum.B2B
				? 'chart.products_best_seller.subtitle_customer'
				: 'chart.products_best_seller.subtitle_seller',
		subtitleTranslateParams: { amount: '5' },
		helpText: 'chart.products_best_seller.helptext',
		icon: 'assignment'
	};

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public store: Store<any>,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route, translateService);
		this.chart.chartType = 'horizontalBar';
		this.chart.options = this.optionsStyle(ChartThemeEnum.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	// Functions

	populateChart(rangeDays: number = chartTimeRange7Days.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let serieTemp = [];
		let labelTemp = [];

		let products;
		if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardOrders) {
			products = this.statistics.products[`days_${rangeDays}`];
		} else {
			products = this.utilService.findLeaf(this.statistics.data, `orders.products.days_${rangeDays}`);
		}
		products.forEach(product => {
			serieTemp.push(product.value);
			labelTemp.push(product.description);
		});

		let fullDataSet = [];
		fullDataSet.push({
			label: 'chart.products_best_seller.income',
			backgroundColor: [
				this.colorPrimary70,
				this.colorPrimary,
				this.colorPrimary70,
				this.colorPrimary,
				this.colorPrimary70
			],
			data: serieTemp
		});

		this.addData(this.chart.datasets, fullDataSet);
		this.addData(this.chart.labels, labelTemp);
		this.chart = _.cloneDeep(this.chart);
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function (tooltipItem, data) {
				let label = translateService.instant(data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += tooltipItem.value;
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartThemeEnum) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [4, 4],
					color: theme === ChartThemeEnum.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartThemeEnum.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					min: 0,
					// Scala da usare
					stepSize: 100
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartThemeEnum) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
