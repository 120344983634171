import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PATH_URL, ROUTE_URL } from '../../../router/route-naming';


@Component({
  selector: 'shop-online',
  templateUrl: './shop-online.component.html',
  styleUrls: ['./shop-online.component.scss']
})
export class ShopOnlineComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.openRemote('');
    this.router.navigate([ROUTE_URL.private])
  }
  
  openRemote(otherQueryParams: string) {
		// costruzione url per redirect ad applicativo esterno. Passo l'access token ed eventuali altri parametri (redirect parametrico su una certa pag di FastOrder)
		//const url = `https://fastorder.com?token=${this.authService.retrieveSSOToken().access_token}${otherQueryParams}`;
		const url = `https://fastorder.com?=${otherQueryParams}`;
		window.open(url);
	  }
}
