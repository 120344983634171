import { createAction, props } from '@ngrx/store';

import { RestBasePk } from '@saep-ict/angular-core';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OrderStateModel, OrderWithArticleDetailRequest } from '../../model/state/order-state.model';
import { PouchDeleteResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OrderFilterModel } from '../../service/pouch-db/filter/order-filter.model';

export enum OrderActionEnum {
	UPDATE = '[order] Update',
	LOAD = '[order] Load',
	LOAD_WITH_DETAIL = '[order] Load with detail',
	SAVE = '[order] Save',
	REMOVE = '[order] Remove',
	REMOVED = '[order] Removed',
	COMPLETED = '[order] Completed',
	RESET = '[order] Reset',
	ERROR = '[order] Error'
}

export namespace OrderStateAction {
	export const load = createAction(OrderActionEnum.LOAD, props<BaseStateModel<RestBasePk>>());
	export const loadWithDetail = createAction(
		OrderActionEnum.LOAD_WITH_DETAIL,
		props<BaseStateModel<OrderWithArticleDetailRequest>>()
	);
	export const update = createAction(
		OrderActionEnum.UPDATE,
		props<BaseStateModel<OrderStateModel, OrderFilterModel>>()
	);
	export const save = createAction(OrderActionEnum.SAVE, props<BaseStateModel<OrderWithArticleDetailRequest>>());
	export const remove = createAction(OrderActionEnum.REMOVE, props<BaseStateModel<OrderStateModel>>());
	export const removed = createAction(OrderActionEnum.REMOVED, props<BaseStateModel<PouchDeleteResponse>>());
	export const completed = createAction(
		OrderActionEnum.COMPLETED,
		props<BaseStateModel<OrderStateModel, OrderFilterModel>>()
	);
	export const reset = createAction(OrderActionEnum.RESET);
	export const error = createAction(OrderActionEnum.ERROR, props<BaseStateModel<any>>());
}
