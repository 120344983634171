import { Component, Input } from '@angular/core';
import { OrderPriceModel } from '../../model/state/order-state.model';
import { ExtendedOrderProductModel } from '../../page/b2c/storefront/product-detail/product-detail.component';
import { UtilOrderService } from '../../service/util/util-order.service';

@Component({
	selector: 'app-mirror-costs',
	templateUrl: './mirror-costs.component.html',
	styleUrls: ['./mirror-costs.component.scss']
})
export class MirrorCostsComponent {
	@Input() productsList = [];
	@Input() shippingFees: number = 0;
	@Input() price: OrderPriceModel;

	constructor(private utilOrderService: UtilOrderService) {}

	ivaCalcolata() {
		const vatCalculated = (this.getPartialTotal() * 22) / 100;
		return Math.round(vatCalculated * 100) / 100;
	}

	getPartialTotal() {
		return this.productsList.reduce((previous, current) => {
			return previous + this.getCurrentPrice(current) * current.ordered_quantity;
		}, 0);
	}

	getCurrentPrice(product: ExtendedOrderProductModel) {
		return product.price;
		// return this.utilOrderService.getPriceFromDivision(product.articleDetail.divisions);
	}

	getTotalPrice() {
		return this.getPartialTotal() + this.ivaCalcolata() + this.shippingFees;
	}
}
