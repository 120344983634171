import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseState, BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { OpportunityPouchModel } from '../../model/state/opportunity-state.model';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OpportunityActionEnum, OpportunityStateAction } from './opportunity.actions';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { UtilService } from '@saep-ict/angular-core';
import { LAST_CHARACTER } from '../../constants/pouchdb.constants';
import { CouchDocumentType } from '../../constants/context-state.map';

@Injectable()
export class OpportunityEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.LOAD),
			mergeMap((action: BaseStateModel<RestBasePk>) => from(this.getOpportunityDetail(action.data))),
			map((opportunity: BaseStateModel<OpportunityPouchModel[]>) => OpportunityStateAction.update(opportunity)),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(null));
				return caught;
			})
		)
	);

	loadAll$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.LOAD_ALL),
			mergeMap((action: BaseStateModel<OpportunityPouchModel[]>) => from(this.getAllOpportunity(action))),
			map((opportunity: BaseStateModel<OpportunityPouchModel[]>) => OpportunityStateAction.update(opportunity)),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.SAVE),
			mergeMap((action: BaseStateModel<OpportunityPouchModel[]>) => from(this.putOpportunity(action.data))),
			map((opportunities: BaseStateModel<OpportunityPouchModel[]>) =>
				OpportunityStateAction.update(opportunities)
			),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(error));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(OpportunityActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<OpportunityPouchModel>) => from(this.deleteOpportunity(action.data))),
			map((user: BaseStateModel<PouchDeleteResponse>) => OpportunityStateAction.removed()),
			catchError((error, caught) => {
				this.store.dispatch(OpportunityStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private utilPouchService: UtilPouchService,
		private utilService: UtilService
	) {}

	async putOpportunity(data: OpportunityPouchModel[]): Promise<BaseStateModel<OpportunityPouchModel[]>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
		).opportunityPouch
			.putOpportunity(data[0], data[0]._id ? false : true)
			.then(async (opportunity: OpportunityPouchModel) => {
				this.utilService.showDialog('opportunity.save');
				return new BaseState([opportunity]);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getOpportunityDetail(data: RestBasePk): Promise<BaseStateModel<OpportunityPouchModel[]>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
		).opportunityPouch
			.getOpportunityDetail(data)
			.then(async (opportunity: OpportunityPouchModel) => {
				return new BaseState([opportunity]);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async deleteOpportunity(data: OpportunityPouchModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.ORGANIZATION)
		).opportunityPouch
			.deleteOpportunity(data)
			.then(async opportunity => {
				return new BaseState(opportunity);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getAllOpportunity(
		action: BaseStateModel<OpportunityPouchModel[]>
	): Promise<BaseStateModel<OpportunityPouchModel[]>> {
		const documentName = 'opportunity';
		const allDocsParam: any = {
			include_docs: true,
			startkey: documentName + '_',
			endkey: documentName + '_' + LAST_CHARACTER
		};

		await this.utilPouchService
			.allDocs<OpportunityPouchModel>(allDocsParam, CouchDocumentType.ORGANIZATION)
			.then(res => {
				action.data = res.data;
			})
			.catch(err => console.log(err));

		return action;
	}
}
