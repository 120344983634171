import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
	SubscribeManagerService,
	BaseStateModel,
	DialogTextEditComponent,
	BaseState,
	DialogConfirmComponent
} from '@saep-ict/angular-core';
import { OfferStateModel } from '../../../../../model/state/offer-state.model';
import { StateFeature } from '../../../../../state';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OfferActionEnum, OfferStateAction } from '../../../../../state/offer/offer.actions';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { SentencecasePipe } from '@saep-ict/angular-core';
@Component({
	selector: 'offer-detail-body',
	templateUrl: './offer-detail-body.component.html',
	styleUrls: ['./offer-detail-body.component.scss'],
	providers: [SubscribeManagerService]
})
export class OfferDetailBodyComponent implements OnInit, OnDestroy {
	// state
	offer: OfferStateModel;
	offer$: Observable<BaseStateModel<OfferStateModel[]>> = this.store.select(StateFeature.getOfferState);

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private sentencecasePipe: SentencecasePipe
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(this.subscribeOffer().subscribe(), 'subscribeOffer');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// reset dello state offer nel padre
	}

	/**
	 * Subscription
	 */
	subscribeOffer() {
		return this.offer$.pipe(
			filter((offerState: BaseStateModel<OfferStateModel[]>) => !!(offerState && offerState.data)),
			map((offerState: BaseStateModel<OfferStateModel[]>) => {
				console.log(offerState);

				switch (offerState.type) {
					case OfferActionEnum.UPDATE:
						this.offer = offerState.data[0];
						break;

					default:
						break;
				}
			})
		);
	}

	/**
	 * Line
	 */

	addLine() {
		const dialogRef: MatDialogRef<DialogTextEditComponent> = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: this.sentencecasePipe.transform(this.translate.instant('offer.line.detail')),
			},
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let offer = _.cloneDeep(this.offer);
				offer.line_list = offer.line_list || [];
				offer.line_list.push(res);
				this.store.dispatch(OfferStateAction.save(new BaseState([offer])));
			}
		});
	}

	editLine(index: number) {
		const dialogRef: MatDialogRef<DialogTextEditComponent> = this.dialog.open(DialogTextEditComponent, {
			data: {
				title: this.sentencecasePipe.transform(this.translate.instant('offer.line.detail')),
				text: this.offer.line_list[index]
			},
			disableClose: true,
			panelClass: 'dialog-medium'
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let offer = _.cloneDeep(this.offer);
				offer.line_list[index] = res;
				this.store.dispatch(OfferStateAction.save(new BaseState([offer])));
			}
		});
	}

	deleteLine(index: number) {
		const dialogRef: MatDialogRef<DialogConfirmComponent> = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.sentencecasePipe.transform(this.translate.instant('offer.line.remove')),
				text: this.sentencecasePipe.transform(this.translate.instant('offer.line.question.delete')),
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				let offer = _.cloneDeep(this.offer);
				offer.line_list.splice(index, 1);
				this.store.dispatch(OfferStateAction.save(new BaseState([offer])));
			}
		});
	}
}
