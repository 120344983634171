import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import { OrganizationPouchModel, DestinationPouchModel, AddressTypeEnum } from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';

import { DEFAULT_DESTINATION_CODE } from '../../../../../constants/destination.constants';
import {
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	LocalListHandlerBaseModel,
	UserDetailModel,
	UtilCustomerService
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';
import { CompanyDetailDestinationsColumnService } from '../../../../../service/td-data-table/implementation/company-detail.service';
import { StateFeature } from '../../../../../state';
import { DestinationListStateAction } from '../../../../../state/destination-list/destination-list.actions';
import { ConfigurationCustomerUser } from '../../../../../constants/configuration-customer/structure.constants';
import { DialogDestinationDetail } from '../../../../../widget/dialog/dialog-destination-detail/dialog-destination-detail.component';

@Component({
	selector: 'organization-detail-destination',
	templateUrl: './organization-detail-destination.component.html',
	styleUrls: ['./organization-detail-destination.component.scss'],
	providers: [CompanyDetailDestinationsColumnService]
})
export class OrganizationDetailDestinationComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable') dataTable: TdDataTableComponent;

	user$: Observable<BaseStateModel<UserDetailModel>>;
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>>;
	organizationSub: Subscription;
	organization: OrganizationStateModel;

	configurationCustomerUser = ConfigurationCustomerUser;

	destinationList: DestinationPouchModel[];

	public listPageBaseData = <LocalListHandlerBaseModel<DestinationPouchModel>>{
		pagination: {
			pageSize: 10
		},
		sort: {
			name: 'address.address',
			order: 'ASC'
		}
	};

	columns: ITdDataTableColumn[] = this.destinationColumnsService.columns;

	currentContext: ContextApplicationItemCodeEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private store: Store<any>,
		private destinationColumnsService: CompanyDetailDestinationsColumnService,
		private utilCustomerService: UtilCustomerService,
		private dialog: MatDialog
	) {
		this.user$ = this.store.select(StateFeature.getUserState);
		this.organization$ = this.store.select(StateFeature.getOrganizationState);

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = this.user.current_permission.context_application;
		});

		this.organizationSub = this.organization$
			.pipe(
				filter((customerState: BaseStateModel<OrganizationPouchModel>) => !!customerState),
				map((customerState: BaseStateModel<OrganizationPouchModel>) => {
					this.organization = customerState.data;
					this.destinationList = this.organization.destination_list || [];
					this.destinationList.sort((a, b) => (a.code > b.code ? 1 : -1));
					const defaultDestination = this.destinationList.find(
						destination => destination.code === DEFAULT_DESTINATION_CODE
					);
					if (defaultDestination) {
						// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
						// defaultDestination.address = this.organization.destination_list
						// 	? this.organization.destination_list.find(
						// 			destination => destination.address_type === AddressTypeEnum.REGISTERED_OFFICE
						// 	  ).address
						// 	: {};
						defaultDestination.address = this.organization.destination_list
							? this.organization.destination_list.find(
									destination => destination.code === DEFAULT_DESTINATION_CODE
							  ).address
							: {};
					}

					this.updateListPageBaseData(this.destinationList);
				})
			)
			.subscribe();
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: DestinationPouchModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	ngOnInit() {}

	// checkCanEditAddress() {
	// 	if (
	// 		this.currentContext === ContextApplicationItemCodeEnum.BACKOFFICE &&
	// 		OrganizationBackoffice.filter(el => el.tab === ROUTE_URL.addresses).map(el => el.can_edit)[0] &&
	// 		this.organization.level !== OrganizationLevelEnum.LEAD
	// 	) {
	// 		return true;
	// 	}
	// 	return false;
	// }

	addAddress() {
		const dialogRef = this.dialog.open(DialogDestinationDetail, {
			data: {
				modalTitle: 'Aggiungi nuovo indirizzo'
			},
			disableClose: true,
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				if (this.dataTable) {
					this.dataTable.refresh();
				}
			}
		});
	}

	// editAddress(addressData: AddressPouchModel) {
	// 	console.log("addressData", addressData);
	// 	const dialogRef = this.dialog.open(DialogDestinationDetail, {
	// 		data: {
	// 			modalTitle: 'Modifica indirizzo',
	// 			addressData: _.cloneDeep(addressData)
	// 		},
	// 		disableClose: true,
	// 		autoFocus: true
	// 	});

	// 	dialogRef.afterClosed().subscribe(res => {
	// 		if (res) {
	// 			console.log(res);
	// 			if (this.dataTable) {
	// 				this.dataTable.refresh();
	// 			}
	// 		}
	// 	});
	// }

	openDialogDestinationEdit(dataDetail: DestinationPouchModel) {
		console.log('dataDetail', dataDetail);
		const dialogRef = this.dialog.open(DialogDestinationDetail, {
			data: {
				modalTitle: 'Modifica indirizzo',
				addressData: _.cloneDeep(dataDetail)
			},
			disableClose: true,
			autoFocus: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				console.log(res);
				if (this.dataTable) {
					this.dataTable.refresh();
				}
			}
		});
	}

	columnsChange(e: ITdDataTableColumn) {
		this.columns.find((column: ITdDataTableColumn) => column.name === e.name).hidden = e.hidden;
		this.dataTable.refresh();
	}

	ngOnDestroy() {
		if (this.organizationSub) {
			this.organizationSub.unsubscribe();
		}
		this.store.dispatch(DestinationListStateAction.reset());
	}
}
