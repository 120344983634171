import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { HighlightsViewModel } from '../../model/home-highlights.model';

export enum HomeHighlightsActionEnum {
	UPDATE = '[home highlights] Update',
	LOAD = '[home highlights] Load',
	RESET = '[home highlights] Reset',
	ERROR = '[home highlights] Error'
}

export namespace HomeHighlightsStateAction {
	export const load = createAction(HomeHighlightsActionEnum.LOAD);
	export const update = createAction(HomeHighlightsActionEnum.UPDATE, props<BaseStateModel<HighlightsViewModel>>());
	export const reset = createAction(HomeHighlightsActionEnum.RESET);
	export const error = createAction(HomeHighlightsActionEnum.ERROR, props<BaseStateModel<any>>());
}
