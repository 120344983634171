import { BaseTableListService } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyDashboardColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	_columns = [
		{
			name: 'organization.business_name',
			label: 'Azienda',
			sortable: false
		},
		{
			name: 'address',
			label: 'Indirizzo',
			sortable: false
		},
		{
			name: 'average',
			label: 'Spesa media',
			sortable: false,
			numeric: true,
			width: 150
		}
	];
}
