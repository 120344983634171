import { createAction, props } from '@ngrx/store';
import { BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { InformativePageModel } from '../../model/informative-page.model';

export enum InformativePageActionEnum {
	UPDATE = '[informative page] Update',
	LOAD = '[informative page] Load',
	RESET = '[informative page] Reset',
	ERROR = '[informative page] Error'
}

export namespace InformativePageStateAction {
	export const load = createAction(InformativePageActionEnum.LOAD, props<{ slug: string }>());
	export const update = createAction(InformativePageActionEnum.UPDATE, props<BaseStateModel<InformativePageModel>>());
	export const reset = createAction(InformativePageActionEnum.RESET);
	export const error = createAction(InformativePageActionEnum.ERROR, props<BaseStateModel<any>>());
}
