<breadcrumb
	[title]="
		(opportunityType === opportunityTypeEnum.LEAD ? 'request.name_plural' : 'opportunity.name')
			| translate
			| sentencecase
	"
	[pathList]="['Home']"
	*ngIf="!idOrganization"
></breadcrumb>

<div list-wrapper [filterOpened]="filterOpened" #listWrapper>
	<ng-container main>
		<mat-card>
			<opportunity-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[localListHandlerSidebarFilterShow]="true"
				(localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
				(selectOpportunityId)="goToOpportunityDetail($event)"
				[localListHandlerTitle]="(opportunityType === opportunityTypeEnum.LEAD ? 'request.list' : 'opportunity.list')
				| translate
				| sentencecase"

			>
			</opportunity-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container side>
		<form [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
			<div class="mb-3">
				<mat-checkbox formControlName="status_open">Solo richieste aperte</mat-checkbox>
			</div>

			<div>
				<button class="mr-8" mat-raised-button color="primary" type="submit">
					{{ 'general.apply_filters' | translate | sentencecase }}
				</button>

				<button mat-button color="default" type="button" (click)="resetFilters()">
					{{ 'general.reset' | translate | sentencecase }}
				</button>
			</div>
		</form>
	</ng-container>
</div>

<button
	class="mat-fab-button"
	(click)="createNew()"
	mat-fab
	[matTooltip]="'opportunity.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	*ngIf="opportunityType"
>
	<mat-icon>add</mat-icon>
</button>
