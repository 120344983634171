import { NgModule } from '@angular/core';
import { DatePipe, TitleCasePipe, CurrencyPipe } from '@angular/common';
import { OrderIdFormatterPipe } from './order-id-formatter.pipe';
import { TranslatePipe } from '@ngx-translate/core';
import { EnumIteratorPipe } from './enum-iterator.pipe';
import { FormatMultiplesCurrency } from './format-multiples-currency.pipe';

@NgModule({
	declarations: [
		EnumIteratorPipe,
		OrderIdFormatterPipe,
		FormatMultiplesCurrency
	],
	exports: [
		EnumIteratorPipe,
		OrderIdFormatterPipe,
		FormatMultiplesCurrency
	],
	providers: [
		DatePipe,
		TitleCasePipe,
		CurrencyPipe,
		EnumIteratorPipe,
		OrderIdFormatterPipe,
		FormatMultiplesCurrency
	]
})
export class PipeModule {}
