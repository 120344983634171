// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';
import { AgentListFilterModel } from '../../../../service/pouch-db/filter/agent-filter.model';
// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

export enum AgentListActionEnum {
	UPDATE_LIST = '[agent-list] Update List',
	UPDATE_ITEM = '[agent-list] Update Item',
	LOAD_ITEM = '[agent-list] Load Item',
	LOAD_LIST = '[agent-list] Load List',
	RESET = '[agent-list] Reset',
	ERROR = '[agent-list] Error',
	CREATE_ITEM = '[agent-list] Create',
	CREATE_ITEM_SUCCESS = '[agent-list] Create Item Success',
	DELETE_ITEM = '[agent-list] Delete Item',
	DELETE_ITEM_SUCCESS = '[agent-list] Delete Item Success'
}

export namespace AgentListStateAction {
	export const loadList = createAction(AgentListActionEnum.LOAD_LIST);
	export const loadItem = createAction(AgentListActionEnum.LOAD_ITEM, props<BaseStateModel<BasePouchModel>>());
	export const updateList = createAction(AgentListActionEnum.UPDATE_LIST, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const updateItem = createAction(AgentListActionEnum.UPDATE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const reset = createAction(AgentListActionEnum.RESET);
	export const error = createAction(AgentListActionEnum.ERROR, props<BaseStateModel<any>>());
	export const createItem = createAction(AgentListActionEnum.CREATE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const createItemSuccess = createAction(AgentListActionEnum.CREATE_ITEM_SUCCESS, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const deleteItem = createAction(AgentListActionEnum.DELETE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const deleteItemSuccess = createAction(AgentListActionEnum.DELETE_ITEM_SUCCESS, props<BaseStateModel<null>>());
}
