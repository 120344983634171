<breadcrumb [title]="'user.name_plural' | translate | sentencecase" [pathList]="['Home']"></breadcrumb>

<div list-wrapper [filterOpened]="false" #listWrapper [titleSidebar]="'tc.general.filters' | translate | sentencecase ">
	<ng-container main>
		<mat-card>
			<mat-toolbar>
				<mat-toolbar-row>
					{{ 'user.name_plural' | translate | sentencecase }}
					<span class="flex-span"></span>
					<td-search-box
						backIcon="search"
						[placeholder]="
							('general.filter_by' | translate | sentencecase) +
							' ' +
							('user.field.username' | translate) +
							' ' +
							('general.o' | translate) +
							' ' +
							('general.id' | translate)
						"
						[(ngModel)]="userManagementState.dataSetting.appliedFilter.text"
						[showUnderline]="true"
						[debounce]="500"
						[alwaysVisible]="false"
						(searchDebounce)="loadUserList()"
					>
					</td-search-box>
					<table-column-toggle
						[columns]="userManagementListColumnService.columns"
						(emitter)="toggleColumn($event)"
					></table-column-toggle>

					<button mat-icon-button (click)="listWrapper.sidenav.open()">
						<mat-icon>filter_list</mat-icon>
					</button>
				</mat-toolbar-row>
			</mat-toolbar>
			<ng-container
				*ngIf="
					userManagementState && userManagementState.data && userManagementState.data.length > 0;
					else noData
				"
			>
				<td-data-table
					#dataTable
					[data]="userManagementState.data"
					[columns]="userManagementListColumnService.columns"
					[clickable]="true"
					(sortChange)="sortChange($event)"
					(rowClick)="goToDetail($event)"
				>
					<ng-template tdDataTableTemplate="context_application_list" let-value="value">
						<ng-container *ngFor="let item of value">
							<mat-icon
								[matTooltip]="
									permissionUtilService.contextTypeAndApplicationLinkFullObject(item.code)
										? (permissionUtilService.contextTypeAndApplicationLinkFullObject(item.code)
												.application_name | uppercase)
										: ''
								"
							>
								{{
									permissionUtilService.contextTypeAndApplicationLinkFullObject(item.code)
										? permissionUtilService.contextTypeAndApplicationLinkFullObject(item.code).icon
										: ''
								}}
							</mat-icon>
						</ng-container>
					</ng-template>
					<ng-template tdDataTableTemplate="avatar" let-value="value" let-row="row">
						<img
							*ngIf="value; else noAvatar"
							[src]="value"
							[alt]="row.email"
							[title]="row.email"
							class="avatar"
						/>
						<ng-template #noAvatar>
							<mat-icon class="avatar--placeholder">person</mat-icon>
						</ng-template>
					</ng-template>
					<ng-template tdDataTableTemplate="is_active" let-value="value" let-row="row">
						<mat-icon [ngClass]="{ 'txt-green': value }" [matTooltip]="value ? 'Active' : 'Not Active'">
							{{ value ? 'check_circle' : 'check_circle_outline' }}
						</mat-icon>
					</ng-template>
				</td-data-table>

				<td-paging-bar
					#pagingBarPageSize
					[firstLast]="true"
					[pageSize]="userManagementState.dataSetting.pagination.page_size"
					[total]="userManagementState.dataSetting.pagination.total_element"
					(change)="paginationChange($event)"
				>
					<span hide-xs>{{ 'general.items_per_page' | translate | sentencecase }}</span>
					<mat-select
						[style.width.px]="50"
						[(ngModel)]="userManagementState.dataSetting.pagination.page_size"
						(ngModelChange)="changePageSize($event)"
					>
						<mat-option *ngFor="let size of [5, 10, 20, 50]" [value]="size">
							{{ size }}
						</mat-option>
					</mat-select>
				</td-paging-bar>
			</ng-container>
			
			<ng-template #noData>
				<p class="placeholder">{{ 'user.not_found' | translate | sentencecase }}</p>
			</ng-template>
		</mat-card>
	</ng-container>

	<!-- Filtri sidebar -->
	<ng-container side>
		<form [formGroup]="form" (ngSubmit)="onFormSubmit()">

			<!-- Context application code -->
			<mat-form-field>
				<mat-label>{{ 'user.context' | translate | sentencecase }}</mat-label>

				<mat-select formControlName="context_code">

					<mat-select-trigger *ngIf="getFormFieldValue('context_code')">
						<mat-icon>
							{{
								permissionUtilService.contextTypeAndApplicationLinkFullObject(
									getFormFieldValue('context_code')
								).icon
							}}
						</mat-icon>
						{{
							permissionUtilService.contextTypeAndApplicationLinkFullObject(
								getFormFieldValue('context_code')
							).application_name
						}}
					</mat-select-trigger>

					<mat-option
						*ngFor="let item of contextTypeAndApplicationLink"
						[value]="item.context_application_item_code"
					>
						<mat-icon>{{ item.icon }}</mat-icon>
						{{ 'user.has_access_to' | translate | sentencecase }} {{ item.application_name }}
					</mat-option>

				</mat-select>
			</mat-form-field>
			<mat-toolbar>
				<button mat-flat-button color="primary" class="mb-3 mr-3" type="submit">
					{{ 'general.apply_filters' | translate | sentencecase }}
				</button>
				<button mat-stroked-button color="primary" class="mb-3 mr-3" type="button" (click)="resetFilters()">
					{{ 'general.reset' | translate | sentencecase }}
				</button>
			</mat-toolbar>
		</form>
	</ng-container>
</div>

<button
	mat-fab
	color="accent"
	class="mat-fab-button"
	[matTooltip]="'user.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	routerLink="./new"
>
	<mat-icon>add</mat-icon>
</button>
