import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IResourceActionInner, IResourceResponse, Resource, ResourceParams } from '@ngx-resource/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

import { ILoaderExceptionCall } from '../../interface/ILoaderExceptionCall';
import { LoaderService } from '@saep-ict/angular-core';
import { CustomerAppConfig } from '../../customer-app.config';
import { ROUTE_URL } from '../../router/route-naming';

@Injectable()
@ResourceParams({
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PATCH, PUT, DELETE',
		'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
	},
	removeTrailingSlash: false
})
export class BaseApiService extends Resource {
	@LocalStorage('authenticationToken')
	authenticationToken: string;

	loaderExceptions: ILoaderExceptionCall[];

	constructor(
		protected loaderService: LoaderService,
		public router: Router,
		public localStorageService: LocalStorageService,
		protected appConfig: CustomerAppConfig
	) {
		super();
		this.appConfig.config$.subscribe(config => {
			if (config && config.urlConfig) {
				super.$setPathPrefix(config.urlConfig.api);
			}
		});
	}

	$restAction(options: IResourceActionInner): any {
		const exception =
			this.loaderExceptions &&
			this.loaderExceptions.find(exc => {
				return options.actionOptions.path === exc.path && options.actionOptions.method === exc.method;
			});

		if (!exception) {
			const guid = this.loaderService.populateLoader(options.actionOptions.path);
			options.returnData = { id: guid };
		}

		if (this.authenticationToken) {
			this.$setHeaders('Authorization: ' + 'Bearer ' + this.authenticationToken);
		}

		return super.$restAction(options);
	}

	$handleSuccessResponse(options: IResourceActionInner, resp: IResourceResponse): any {
		if (options.returnData && options.returnData.id) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}
		return super.$handleSuccessResponse(options, resp);
	}

	$handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse): any {
		if (options.returnData && options.returnData.id) {
			this.loaderService.changeSingleLoader(options.returnData.id);
		}

		if (resp.status === 401) {
			this.localStorageService.clear('authenticationToken');
			// TOFIX: sostituire con this.authService.logout(); ma risolvere dipendenza circolare
			this.router.navigate([ROUTE_URL.public, ROUTE_URL.login]);
		}
		return super.$handleErrorResponse(options, resp);
	}

	protected setPathPrefix(pathPrefix: string) {
		super.$setPathPrefix(pathPrefix);
	}
}
