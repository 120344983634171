import { ArticleListFilterModel } from '../pouch-db/filter/article-list-filter.model';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ArticleCategory } from '../../model/article.model';
import { Category } from '../../model/category-list.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { PouchDbCommonsAdapter } from '../pouch-db/spin8/pouchdb-commons.adapter';

// model
// pouch instance

// widget & utility
@Injectable({
	providedIn: 'root'
})
export class UtilCategoryListService {
	constructor(private snackBar: MatSnackBar, private pouchDbCommonsAdapter: PouchDbCommonsAdapter) {}

	/**
	 * Chiamata per la restituzione di categorie o nodi (tipo article) filtrabile
	 * @param action
	 */
	loadCategoryList(
		action: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>
	): Promise<BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>> {
		return this.pouchDbCommonsAdapter.categoryListCommonPouch
			.loadCategoryList(action)
			.then(res => {
				action.data = res.docs;
				return action;
			})
			.catch(err => {
				console.log(err);
				this.openSnackBar(`Error loading`);
				return err;
			});
	}

	returnCategoryById(id: string, category_list: Category<ArticleCategory>[]): Promise<Category<ArticleCategory>> {
		return new Promise(resolve => {
			category_list.forEach(category => {
				if (category._id === id) {
					resolve(category);
				} else {
					if (category.category_list && category.category_list.length > 0) {
						resolve(this.returnCategoryById(id, category.category_list));
					}
				}
			});
		});
	}

	openSnackBar(message: string, action = 'Ok', duration = 1000) {
		this.snackBar.open(message, action, { duration: duration });
	}
}
