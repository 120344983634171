<div class="container-fluid">
	<div class="row" *ngIf="order?.header?.status">
		<!-- <div class="col-lg-12 col-xl-3">
			<mat-accordion class="primary">
				<mat-expansion-panel [expanded]="true" [disabled]="true">
					<mat-expansion-panel-header [expandedHeight]="'48px'">
						<mat-panel-title>
							{{ 'order.summary' | translate | titlecase }}
						</mat-panel-title>
					</mat-expansion-panel-header>
					<div class="row">
						<div class="col-sm-12">
							<ng-container *ngIf="order.product_list.length > 0">
								<ng-container *ngFor="let key of retrieveTotalCountKey()">
									<div [ngClass]="totalOrderCount[key].main ? 'total' : 'partial'">
										<p>
											{{ totalOrderCount[key].label }}
										</p>
										<p class="total__price" *ngIf="order.product_list.length > 0">
											{{
												(totalOrderCount[key].value ? totalOrderCount[key].value : 0)
													| currency: 'EUR':'symbol':'':'it'
											}}
										</p>
									</div>
								</ng-container>
							</ng-container>
	
							<div class="total" *ngIf="!(order.product_list.length > 0)">
								<p>
									{{ 'order.field.total' | translate | sentencecase }}
								</p>
								<p class="total__price">
									{{ 0 | currency: 'EUR':'symbol':'':'it' }}
								</p>
							</div>
						</div>
						<div
							class="col-sm-12 submit-order"
							*ngIf="order.product_list.length != 0 && order.header.status === orderStatusEnum.DRAFT"
						>
							<button mat-raised-button color="accent" (click)="sendOrder()">
								{{ 'order.send' | translate | sentencecase }}
							</button>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div> -->
		<div class="col-lg-12 col-xl-12">
			<mat-card>
				<article-checkout-tree
					*ngIf="order && order.product_list && order.product_list.length > 0; else noDataFound"
					[articleCheckoutTree]="articleCheckoutTree"
					[order]="order"
					(articleChangeEmitter)="rowChange($event)"
				></article-checkout-tree>

				<ng-template #noDataFound>
					<p class="no-data">{{ 'order.empty' | translate | sentencecase }}</p>
				</ng-template>
			</mat-card>
		</div>
	</div>
</div>
