import { createAction, props } from '@ngrx/store';
import { BaseStateModel, UserDetailModel } from '@saep-ict/angular-core';

// model
import { BackofficeUserFilterModel } from '../../../service/pouch-db/filter/backoffice-user-filter.model';

export enum UserManagementStateActionEnum {
    UPDATE = '[user-management] Update List',
    LOAD_LIST = '[user-management] Load List',
    LOAD_LIST_WITH_AVATAR = '[user-management] Load List with avatar',
    LOAD_DETAIL = '[user-management] Load Detail',
    LOAD_DETAIL_WITH_AVATAR = '[user-management] Load Detail with avatar',
    SAVE = '[user-management] Save',
    SAVE_SUCCESS = '[user-management] Save Success',
    RESEND_EMAIL_CONFIRMATION = '[user-management] Resend Email Confirmation',
    DEACTIVATE_DETAIL = '[user-management] Deactivate Detail',
    RESET = '[user-management] Reset',
    ERROR = '[user-management] Error'
}

export namespace UserManagementStateAction {
    export const update = createAction(
        UserManagementStateActionEnum.UPDATE,
        props<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>>()
    );
    export const loadList = createAction(
        UserManagementStateActionEnum.LOAD_LIST,
        props<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>>()
    );
    export const loadListWithAvatar = createAction(
        UserManagementStateActionEnum.LOAD_LIST_WITH_AVATAR,
        props<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>>()
    );
    export const loadDetail = createAction(
        UserManagementStateActionEnum.LOAD_DETAIL,
        props<{id: string}>()
    );
    export const loadDetailWithAvatar = createAction(
        UserManagementStateActionEnum.LOAD_DETAIL_WITH_AVATAR,
        props<{id: string}>()
    );
    export const save = createAction(
        UserManagementStateActionEnum.SAVE,
        props<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>>()
    );
    export const saveSuccess = createAction(
        UserManagementStateActionEnum.SAVE_SUCCESS,
        props<BaseStateModel<UserDetailModel[], BackofficeUserFilterModel>>()
    );
    export const resendEmailConfirmation = createAction(
        UserManagementStateActionEnum.RESEND_EMAIL_CONFIRMATION,
        props<BaseStateModel<UserDetailModel>>()
    );
    export const deactivateDetail = createAction(
        UserManagementStateActionEnum.DEACTIVATE_DETAIL,
        props<BaseStateModel<UserDetailModel>>()
    );
    export const reset = createAction(
        UserManagementStateActionEnum.RESET
    );
    export const error = createAction(
        UserManagementStateActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
