import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../../state';
import { UserActivateActionEnum, UserActivateStateAction } from '../../../../state/user-activate/user-activate.actions';
import { PasswordVerifyModel } from '../../../../widget/compound-input-interface/password-verify/password-verify.component';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UserActivationStateModel, UserPasswordChangeModel } from '@saep-ict/angular-core';
import { ROUTE_URL } from '../../../../router/route-naming';

export enum TypeGuidEmail {
	'NEW' = 'NEW',
	'RESET' = 'RESET',
	'RECOVERY' = 'RECOVERY'
}

@Component({
	selector: ' password-create',
	templateUrl: './password-create.component.html',
	styleUrls: ['./password-create.component.scss'],
	providers: [SubscribeManagerService]
})
export class PasswordCreateComponent implements OnInit, OnDestroy {
	@Input() redirectLink = [];
	userActivate$: Observable<BaseStateModel<UserActivationStateModel>> = this.store.select(
		StateFeature.getUserActivateState
	);
	userActivate: BaseStateModel<UserActivationStateModel>;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		private router: Router,
		private snackBar: MatSnackBar
	) {
		this.redirectLink = this.route.snapshot.data['redirectLink'];
		this.userActivate$.pipe(take(1)).subscribe(res => {
			if (res && res.data) {
				this.userActivate = res;
			}
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(UserActivateStateAction.reset());
	}

	activateAccount(data: PasswordVerifyModel) {
		const requestPasswordNew: UserPasswordChangeModel = {
			...this.userActivate.data,
			...{ password: data.newPassword }
		};
		if (this.userActivate.data.type.toUpperCase() === TypeGuidEmail.NEW) {
			this.store.dispatch(UserActivateStateAction.activate(new BaseState(requestPasswordNew)));
		} else {
			this.store.dispatch(UserActivateStateAction.set_password(new BaseState(requestPasswordNew)));
		}
		this.subscribeManagerService.populate(
			this.initObservableState().subscribe(
				res => {
					const succ_mex =
						this.userActivate.data.type.toUpperCase() === TypeGuidEmail.NEW
							? `Attivazione Utente avvenuta con successo`
							: `La password è stata re-impostata con successo`;
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 5000,
						data: {
							message: succ_mex
						}
					});
					this.router.navigate(this.redirectLink);
				},
				error => {
					console.warn(error);
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 5000,
						data: {
							message: `Siamo spiacenti, Qualcosa è andato storto.`
						}
					});
				}
			),
			'user-activate'
		);
	}

	initObservableState(): Observable<BaseStateModel<UserActivationStateModel>> {
		return this.userActivate$.pipe(
			filter((userActivate: BaseStateModel<UserActivationStateModel>) => (userActivate ? true : false)),
			map((userActivate: BaseStateModel<UserActivationStateModel>) => {
				if (userActivate.type === UserActivateActionEnum.COMPLETED) {
					return userActivate;
				} else if (userActivate.type === UserActivateActionEnum.ERROR) {
					throw new Error(UserActivateActionEnum.ERROR);
				}
			}),
			catchError(error => {
				throw new Error(error);
			})
		);
	}
}
