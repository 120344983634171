<breadcrumb [title]="'article.list' | translate | sentencecase" [pathList]="['Home']"></breadcrumb>

<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<article-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="articleColumnService.columns"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					[localListHandlerTitle]="'article.list' | translate | sentencecase"
				>
				</article-list-wrapper>
			</mat-card>
		</div>
	</div>
</div>
