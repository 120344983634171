import {Injectable} from '@angular/core';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {MetaDataModel} from '../../model/meta-data.model';


@Injectable({
  providedIn: 'root',
})
export class MetaDataResolver implements Resolve<any> {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {

    const metaData: MetaDataModel = route.data.meta;

    Object.keys(metaData as { title: string; description: string }).forEach(
      (k) => {
        const val = metaData[k];
        const mt: MetaDefinition = {
          name: k,
          content: val,
        };
        if (!this.meta.getTag(`name="${k}"`)) {
          this.meta.addTag(mt);
        } else {
          this.meta.updateTag(mt);
        }
      }
    );
    this.titleService.setTitle(metaData.title);
  }

  // public getRouterPaths(config: Route, prefix = ''): any {
  //   if (!config.children) {
  //     return [{ path: prefix + config.path, config }];
  //   } else {
  //     return [
  //       { path: prefix + config.path, config },
  //       ...config.children.map((ch) => {
  //         return this.getRouterPaths(
  //           ch,
  //           prefix || config.path ? prefix + config.path + '/' : ''
  //         );
  //       }),
  //     ].reduce((acc, val) => {
  //       if (val instanceof Array) {
  //         return [...acc, ...val];
  //       }
  //       return [...acc, val];
  //     }, []);
  //   }
  // }
}
