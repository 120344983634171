import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomValidator } from '../../../shared/validators/custom.validator';
import { DestinationNew } from '@saep-ict/pouch_agent_models';

@Component({
	selector: 'dialog-other-destination',
	templateUrl: './dialog-other-destination.component.html',
	styleUrls: ['./dialog-other-destination.component.scss']
})
export class DialogOtherDestinationComponent implements OnInit {
	form: FormGroup;

	zipCodeMinLength = 5;
	provinceMinLength = 2;
	countryMinLength = 2;

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<DialogOtherDestinationComponent>
	) {
		this.createForm();
		if (this.data.otherDestination) {
			this.populateForm();
		}
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			address: [{ value: null, disabled: false }, [Validators.required]],
			locality: [{ value: null, disabled: false }, [Validators.required]],
			zip_code: [
				{ value: null, disabled: false },
				[Validators.required, Validators.minLength(this.zipCodeMinLength)]
			],
			province: [
				{ value: null, disabled: false },
				[Validators.required, Validators.minLength(this.provinceMinLength), CustomValidator.cannotContainSpace]
			],
			country: [
				{ value: null, disabled: false },
				[Validators.required, Validators.minLength(this.countryMinLength), CustomValidator.cannotContainSpace]
			]
		});
	}

	populateForm() {
		const otherDestination: DestinationNew = this.data.otherDestination;
		this.form.patchValue({
			address: otherDestination.address.address ? otherDestination.address.address : null,
			locality: otherDestination.address.locality ? otherDestination.address.locality : null,
			zip_code: otherDestination.address.zip_code ? otherDestination.address.zip_code : null,
			province: otherDestination.address.province ? otherDestination.address.province : null,
			country: otherDestination.address.country ? otherDestination.address.country : null
		});
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}

	prepareSaveForm() {
		const formModel = this.form.value;

		const saveForm: DestinationNew = {
			address: {
				address: formModel.address.trim() as string,
				locality: formModel.locality.trim() as string,
				zip_code: formModel.zip_code as string,
				province: formModel.province.toUpperCase() as string,
				country: formModel.country.toUpperCase() as string
			}
		};
		return saveForm;
	}
}
