import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { StatisticsOrders } from '../../model/statistics-orders.model';
import { StatisticsOrdersActionEnum, StatisticsOrdersStateAction } from './statistics-orders.action';
import { StatisticsOrdersFilter } from '../../service/pouch-db/filter/statistics-orders-filter.model';

export namespace StatisticsOrdersStateReducer {
	export const initialState: BaseStateModel<StatisticsOrders, StatisticsOrdersFilter> = null;

	const _reducer = createReducer(
		initialState,
		on(StatisticsOrdersStateAction.load, state => state),
		on(StatisticsOrdersStateAction.update, (state, order) => order),
		on(StatisticsOrdersStateAction.reset, () => initialState),
		on(StatisticsOrdersStateAction.error, (state, order) => ({ data: state ? state.data : null, type: order.type }))
	);

	export function reducer(state: BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>, action: TypedAction<StatisticsOrdersActionEnum>) {
		return _reducer(state, action);
	}
}
