import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { IMG_PLACEHOLDER_PRODUCT } from '../../../../constants/misc.constants';
import { ROUTE_URL } from '../../../../router/route-naming';
import { Observable } from 'rxjs';
import { BaseState, BaseStateModel } from '@saep-ict/angular-core';
import { OrderStateModel, OrderWithArticleDetailRequest } from '../../../../model/state/order-state.model';
import { StateFeature } from '../../../../state';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { OrderActionEnum, OrderStateAction } from '../../../../state/order/order.actions';
import { ExtendedOrderProductModel } from '../product-detail/product-detail.component';
import { UtilOrderService } from '../../../../service/util/util-order.service';
import { ExtendedOrganizationPouchModel } from '../checkout/checkout-to-update.model';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { MeasureFormatter } from '../../../../shared/misc/measure-formatter';

interface ExtendedOrderStateModel extends OrderStateModel {
	product_list: ExtendedOrderProductModel[];
}

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
	ROUTE_URL = ROUTE_URL;
	IMG_PLACEHOLDER_PRODUCT = IMG_PLACEHOLDER_PRODUCT;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: ExtendedOrderStateModel;

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	loginContextCode: ExtendedOrganizationPouchModel;

	measureFormatter = MeasureFormatter;
	math = Math;

	constructor(public router: Router, private store: Store<any>, private utilOrderService: UtilOrderService) {}

	ngOnInit() {
		this.order$
			.pipe(
				filter((order: BaseStateModel<OrderStateModel>) => order && order.type !== OrderActionEnum.LOAD),
				take(1)
			)
			.subscribe(res => {
				this.order = res ? (res.data as ExtendedOrderStateModel) : null;
			});

		this.loginContextCode$.pipe(take(1)).subscribe(res => {
			this.loginContextCode = res ? ((res.data as unknown) as ExtendedOrganizationPouchModel) : null;
		});
	}

	clickCallToAction() {}

	removeItem(codeProduct: string) {
		this.order.product_list = this.order.product_list.filter(i => i.code !== codeProduct);
		this.saveOrder();
		this.checkIfLastItemInCart();
	}

	checkIfLastItemInCart() {
		if (!this.order.product_list.length) {
			this.store.dispatch(OrderStateAction.reset());
		}
	}

	changeQuantity(newQuantity: number, codeProduct: string) {
		if (newQuantity === 0) {
			this.removeItem(codeProduct);
			return;
		}

		for (const [index, product] of this.order.product_list.entries()) {
			if (product.code === codeProduct) {
				this.order.product_list[index].ordered_quantity = newQuantity;
			}
		}
		this.saveOrder();
	}

	saveOrder() {
		const requestOrderDetail: OrderWithArticleDetailRequest = {
			orderData: { order: _.cloneDeep(this.order), organization: this.loginContextCode, article: {} }
		};
		this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
	}

	calculateSubtotal(): number {
		if (!this.order.product_list.length) {
			return 0;
		}

		return _.sumBy(this.order.product_list, product => {
			return this.getPrice(product) * product.ordered_quantity;
		});
	}

	getPrice(product: ExtendedOrderProductModel) {
		return this.utilOrderService.getPriceFromDivision(product.articleDetail.divisions);
	}
}
