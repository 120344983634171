import { BaseStateModel } from '@saep-ict/angular-core';
import { createAction, props } from '@ngrx/store';
import { OrderListFilterModel } from '../../service/pouch-db/filter/order-filter.model';
import { OrderStateModel } from '../../model/state/order-state.model';
import { TypedAction, ActionCreator } from '@ngrx/store/src/models';

export enum OrderListActionEnum {
	UPDATE = '[order list] Update',
	LOAD = '[order list] Load',
	LOAD_WITH_DESTINATION = '[order list] Load With Destination',
	LOAD_WITH_DETAIL = '[order list] Load With Detail',
	LOAD_ALL = '[order list] Load All',
	LOAD_ALL_DONE = '[order list] Load All Done',
	COMPLETED = '[order list] Completed',
	RESET = '[order list] Reset',
	ERROR = '[order list] Error'
}

export namespace OrderListStateAction {
	export const load = createAction(OrderListActionEnum.LOAD, props<BaseStateModel<OrderStateModel[]>>());
	export const loadWithDestination = createAction(OrderListActionEnum.LOAD_WITH_DESTINATION, props<BaseStateModel<OrderStateModel[]>>());
	export const loadWithDetail = createAction(OrderListActionEnum.LOAD_WITH_DETAIL, props<BaseStateModel<OrderStateModel[], OrderListFilterModel>>());
	export const loadAll = createAction(OrderListActionEnum.LOAD_ALL);
	export const update = createAction(OrderListActionEnum.UPDATE, props<BaseStateModel<OrderStateModel[]>>());
	export const completed = createAction(OrderListActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const reset = createAction(OrderListActionEnum.RESET);
	export const error = createAction(OrderListActionEnum.ERROR, props<BaseStateModel<any>>());
}
