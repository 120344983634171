import { Inject, Injectable } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { TranslateService } from '@ngx-translate/core';
import { BaseTableListService } from '@saep-ict/angular-core';

@Injectable()
export class ClientsTop5ColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns = [
		{
			name: 'business_name',
			label: this.translate.instant('organization.field.name')
		},
		{
			name: 'address',
			label: this.translate.instant('general.address.address')
		},
		{
			name: 'last_order_date',
			label: this.translate.instant('order.last'),
			width: 100
		},
		{
			name: 'total',
			label: this.translate.instant('general.total_spending'),
			numeric: true,
			width: 100
		}
	];
}
