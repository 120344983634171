import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PageCommonsModule } from '../commons/page-commons.module';
import { DashboardB2bComponent } from './dashboard-b2b/dashboard-b2b.component';
import { ShopOnlineComponent } from './shop-online/shop-online.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [DashboardB2bComponent, ShopOnlineComponent],
	exports: [DashboardB2bComponent],
	entryComponents: [DashboardB2bComponent],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageB2BModule {}
