import { Component, Input, OnChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { OrganizationTypeEnum } from '@saep-ict/pouch_agent_models';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

@Component({
	selector: 'user-contact-information-form',
	templateUrl: './user-contact-information-form.component.html',
	styleUrls: ['./user-contact-information-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class UserContactInformationFormComponent implements OnChanges {
	@Input() parentForm: FormGroup;
	@Input() organization: OrganizationStateModel;
	@Input() organizationTypeSelected: OrganizationTypeEnum;
	@Input() currentContext: ContextApplicationItemCodeEnum;

	form: FormGroup;
	organizationType: OrganizationTypeEnum;

	constructor(private fb: FormBuilder) {
		this.organizationType = this.setOrganizationType();
	}

	setOrganizationType() {
		if (this.organization) {
			if (this.organization.organization_type) {
				return this.organization.organization_type;
			} else {
				return OrganizationTypeEnum.COMPANY; // manca organization_type
			}
		} else {
			return this.organizationTypeSelected; // registrazione account
		}
	}

	ngOnChanges() {
		this.organizationType = this.setOrganizationType();

		this.form = this.parentForm;
		switch (this.organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.form.addControl(
					'userContactInformation',
					this.fb.group({
						// Company
						firstName: ['', [Validators.required]],
						lastName: ['', [Validators.required]],
						// Common
						email: ['', [Validators.required, Validators.email]],
						phone: ['', [Validators.required]]
					})
				);
				// if (this.currentContext === ContextApplicationItemCodeEnum.B2C) {
				// 	this.form.addControl(
				// 		'personalData',
				// 		this.fb.group({
				// 			// User
				// 			firstName: ['', [Validators.required]],
				// 			lastName: ['', [Validators.required]]
				// 		})
				// 	);
				// }
				break;
			case OrganizationTypeEnum.PRIVATE:
				this.form.addControl(
					'userContactInformation',
					this.fb.group({
						// Common
						email: ['', [Validators.required, Validators.email]],
						phone: ['', [Validators.required]]
					})
				);
				break;
		}

		// Update form from organization
		if (this.organization) {
			this.updateFormFieldValue();
			this.form.disable();
		}
	}

	updateFormFieldValue() {
		switch (this.organizationType) {
			case OrganizationTypeEnum.COMPANY:
				this.form.patchValue({
					userContactInformation: {
						// Company
						firstName: this.organization.first_name ? this.organization.first_name : null,
						lastName: this.organization.last_name ? this.organization.last_name : null,
						// Common
						email:
							this.organization.contact_list
								? this.organization.contact_list.find(contact => contact.main_contact).email
								: null,
						phone:
						this.organization.contact_list
						? this.organization.contact_list.find(contact => contact.main_contact).phone
								: null
					}
				});
				break;
			case OrganizationTypeEnum.PRIVATE:
				this.form.patchValue({
					userContactInformation: {
						// Common
						email:
						this.organization.contact_list
						? this.organization.contact_list.find(contact => contact.main_contact).email
								: null,
						phone:
						this.organization.contact_list
						? this.organization.contact_list.find(contact => contact.main_contact).phone
								: null
					}
				});
				break;
		}
	}

	getVisibilityField(fieldName: string) {
		return this.form.get('userContactInformation')['controls'].hasOwnProperty(fieldName);
	}
}
