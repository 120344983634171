<div class="container-fluid">
	<!-- Ordini: Ordini attuali -->
	<div class="row">
		<div class="col-sm-12 col-md-4 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="cardDashboardConfigCurrentOrdersToAuthorize"
				*ngIf="!!statisticsAgent"
			>
				<div class="value-orders">
					{{
						statisticsAgent.orders.order_trend.to_authorize[
							statisticsAgent.orders.order_trend.to_authorize.length - 1
						].value
					}}
				</div>
			</card-dashboard>
		</div>
		<div class="col-sm-12 col-md-4 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="cardDashboardConfigCurrentOrdersProcessing"
				*ngIf="!!statisticsAgent"
			>
				<div class="value-orders">
					{{
						statisticsAgent.orders.order_trend.processing[
							statisticsAgent.orders.order_trend.processing.length - 1
						].value
					}}
				</div>
			</card-dashboard>
		</div>
		<div class="col-sm-12 col-md-4 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="cardDashboardConfigCurrentOrdersConsolidated"
				*ngIf="!!statisticsAgent"
			>
				<div class="value-orders">
					{{
						statisticsAgent.orders.order_trend.consolidated[
							statisticsAgent.orders.order_trend.consolidated.length - 1
						].value
					}}
				</div>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Ordini: Ordini recenti -->
		<div class="col-sm-12 col-md-12 col-lg-8">
			<card-dashboard [cardDashboardConfig]="cardDashboardConfigOrdersRecent">
				<order-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseLastOrders"
					[localListHandlerWidgetWrapperColumnList]="columns"
					[localListHandlerPaginatorShow]="true"
					(selectOrderId)="goToOrderDetail($event)"
					(selectCompany)="utilCompanyService.navigateToCompanyDetail($event.level, $event.code, null)"
				>
				</order-list-wrapper>
			</card-dashboard>
		</div>

		<div class="col-sm-12 col-md-12 col-lg-4 orders">
			<!-- Ordini: Valore medio -->
			<div class="orders__value">
				<card-dashboard
					[cardDashboardConfig]="orderAverageChartConfigService.cardDashboardConfigOrdersAverage"
					(changeTimeRange)="
						orderAverageChartConfigService.changeTimeRange($event, orderAverageChartConfigService)
					"
				>
					<div class="row">
						<div class="col-6 m-0">
							<div class="value-trend" *ngIf="orderAverageChartConfigService.metrics">
								<div class="value-trend__value">
									{{ orderAverageChartConfigService.metrics.average | appFormatMultiplesCurrency }}
								</div>
								<div class="value-trend__trend">
									<icon-trend [value]="orderAverageChartConfigService.metrics.trend"></icon-trend>
								</div>
							</div>
						</div>
						<div class="col-6 m-0">
							<chart-wrapper [config]="orderAverageChartConfigService.chart"></chart-wrapper>
						</div>
					</div>
				</card-dashboard>
			</div>

			<!-- Ordini: Quantità -->
			<div class="orders__amount">
				<card-dashboard
					class="card-dashboard-accent"
					[cardDashboardConfig]="orderQuantityChartConfigService.cardDashboardConfigOrdersQuantity"
					(changeTimeRange)="
						orderQuantityChartConfigService.changeTimeRange($event, orderQuantityChartConfigService)
					"
				>
					<div class="row">
						<div class="col-6 m-0">
							<div class="value-trend" *ngIf="orderQuantityChartConfigService.metrics">
								<div class="value-trend__value">
									{{ orderQuantityChartConfigService.metrics.average | number: '1.2-2':'it' }}
								</div>
								<div class="value-trend__trend">
									<icon-trend [value]="orderQuantityChartConfigService.metrics.trend"></icon-trend>
								</div>
							</div>
						</div>
						<div class="col-6 m-0">
							<chart-wrapper [config]="orderQuantityChartConfigService.chart"></chart-wrapper>
						</div>
					</div>
				</card-dashboard>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-sm-12 col-md-12 col-lg-4 balance">
			<!-- Venduto: andamento -->
			<div class="balance__sold">
				<card-dashboard
					[cardDashboardConfig]="fulfilledTrendChartConfigService.cardDashboardConfigFulfilledTrend"
					(changeTimeRange)="
						fulfilledTrendChartConfigService.changeTimeRange($event, fulfilledTrendChartConfigService)
					"
				>
					<div class="row">
						<div class="col-6 m-0">
							<div class="fulfilled-trend" *ngIf="fulfilledTrendChartConfigService.metrics">
								<div class="fulfilled-trend__value">
									{{ fulfilledTrendChartConfigService.metrics.average | appFormatMultiplesCurrency }}
								</div>
								<div class="fulfilled-trend__trend">
									<icon-trend [value]="fulfilledTrendChartConfigService.metrics.trend"></icon-trend>
								</div>
							</div>
						</div>
						<div class="col-6 m-0">
							<chart-wrapper [config]="fulfilledTrendChartConfigService.chart"></chart-wrapper>
						</div>
					</div>
				</card-dashboard>
			</div>

			<!-- Scaduto: andamento -->
			<div class="balance__expired">
				<card-dashboard
					[cardDashboardConfig]="this.accountBalanceExpiredChartConfigService.cardDashboardConfigExpired"
					(changeTimeRange)="
						accountBalanceExpiredChartConfigService.changeTimeRange(
							$event,
							accountBalanceExpiredChartConfigService
						)
					"
				>
					<div class="row">
						<div class="col-6 m-0">
							<div class="value-trend" *ngIf="accountBalanceExpiredChartConfigService.metrics">
								<div class="value-trend__value">
									{{
										accountBalanceExpiredChartConfigService.metrics.average
											| appFormatMultiplesCurrency
									}}
								</div>
								<div class="value-trend__trend">
									<icon-trend
										[value]="accountBalanceExpiredChartConfigService.metrics.trend"
									></icon-trend>
								</div>
							</div>
						</div>
						<div class="col-6 m-0">
							<chart-wrapper [config]="accountBalanceExpiredChartConfigService.chart"></chart-wrapper>
						</div>
					</div>
				</card-dashboard>
			</div>
		</div>

		<!-- Clients : top5 -->
		<div class="col-sm-12 col-md-12 col-lg-8">
			<card-dashboard
				[cardDashboardConfig]="cardDashboardConfigClientsTop5"
				(changeTimeRange)="changeTimeRangeClientsTop5($event)"
			>
				<client-list-wrapper
					[localListHandlerWidgetWrapperData]="clientsTop5TableConfigService.listClientsData"
					[localListHandlerWidgetWrapperColumnList]="clientsTop5ColumnService.columns"
					[localListHandlerPaginatorShow]="true"
				>
				</client-list-wrapper>
			</card-dashboard>
		</div>
	</div>

	<!-- Ordini: Andamento ordini -->
	<!-- <div class="row">
		<div class="col-12">
			<card-dashboard [cardDashboardConfig]="orderTrendChartConfigService.cardDashboardConfigOrdersTrend"
				(changeTimeRange)="orderTrendChartConfigService.changeTimeRange($event, orderTrendChartConfigService)">
				<chart-wrapper [config]="orderTrendChartConfigService.chart"></chart-wrapper>
			</card-dashboard>
		</div>
	</div> -->

	<!-- Ordini: Prodotti più ordinati -->
	<!-- <div class="row">
		<div class="col-sm-12 col-md-12 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="orderBestOrderedChartConfigService.cardDashboardConfigOrdersBestOrdered"
				(changeTimeRange)="
					orderBestOrderedChartConfigService.changeTimeRange($event, orderBestOrderedChartConfigService)
				"
			>
				<chart-wrapper [config]="orderBestOrderedChartConfigService.chart"></chart-wrapper>
			</card-dashboard>
		</div>
	</div> -->

	<!-- Venduto: prodotti più venduti -->
	<!-- <div class="row">
		<div class="col-sm-12 col-md-12 col-lg-4">
			<card-dashboard
				[cardDashboardConfig]="fulfilledBestSellerChartConfigService.cardDashboardConfigFulfilledBestSeller"
				(changeTimeRange)="
					fulfilledBestSellerChartConfigService.changeTimeRange($event, fulfilledBestSellerChartConfigService)
				"
			>
				<ng-container *ngIf="fulfilledBestSellerChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="fulfilledBestSellerChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>
	</div> -->

	<!-- <div class="row">
		<div class="col-sm-12 col-md-12 col-lg-4">
			<div class="row height-100"> -->
	<!-- Clienti : attivi -->
	<!-- <div class="col-12">
					<card-dashboard
						[cardDashboardConfig]="clientsActiveChartConfigService.cardDashboardConfigClientsActive"
						(changeTimeRange)="
							clientsActiveChartConfigService.changeTimeRange($event, clientsActiveChartConfigService)
						"
					>
						<div class="row">
							<div class="col-6 m-0">
								<div class="value-trend" *ngIf="clientsActiveChartConfigService.metrics">
									<div class="value-trend__value">
										{{ clientsActiveChartConfigService.metrics.average | number: '1.2-2':'it' }}
									</div>
									<div class="value-trend__trend">
										<icon-trend [value]="clientsActiveChartConfigService.metrics.trend"></icon-trend>
									</div>
								</div>
							</div>
							<div class="col-6 m-0">
								<chart-wrapper [config]="clientsActiveChartConfigService.chart"></chart-wrapper>
							</div>
						</div>
					</card-dashboard>
				</div> -->

	<!-- Clienti : inattivi -->
	<!-- <div class="col-12 mt-3">
					<card-dashboard
						[cardDashboardConfig]="clientsInactiveChartConfigService.cardDashboardConfigClientsInactive"
						(changeTimeRange)="
							clientsInactiveChartConfigService.changeTimeRange($event, clientsInactiveChartConfigService)
						"
					>
						<div class="row">
							<div class="col-6 m-0">
								<div class="value-trend" *ngIf="clientsInactiveChartConfigService.metrics">
									<div class="value-trend__value">
										{{ clientsInactiveChartConfigService.metrics.average | number: '1.2-2':'it' }}
									</div>
									<div class="value-trend__trend">
										<icon-trend [value]="clientsInactiveChartConfigService.metrics.trend"></icon-trend>
									</div>
								</div>
							</div>
							<div class="col-6 m-0">
								<chart-wrapper [config]="clientsInactiveChartConfigService.chart"></chart-wrapper>
							</div>
						</div>
					</card-dashboard>
				</div>
			</div>
		</div>
	</div> -->

	<!-- Dovuto -->
	<!-- <div class="row">
		<div class="col-md-12 col-lg-6">
			<card-trend [config]="this.accountBalanceConfigService.cardTrendConfigurationDue">
			</card-trend>
		</div>
	</div> -->

	<!-- Data ultimo aggiornamento statistiche -->
	<div class="row">
		<div class="col-12 statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>
</div>
