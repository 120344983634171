import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { animations } from './header-hamburger.animations';

@Component({
  selector: 'app-header-hamburger',
  templateUrl: './header-hamburger.component.html',
  styleUrls: ['./header-hamburger.component.scss'],
  animations: [animations]
})
export class HeaderHamburgerComponent implements OnInit {
  @Input() navbarIsOpen: boolean;
  @Output() toggleNavBar: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
