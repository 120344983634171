<div class="background">
	<div class="wrapper">
		<svg
			id="younique-icon"
			data-name="younique icon"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 250 250"
			class="loader"
		>
			<defs>
				<style>
					.cls-1 {
						fill: #e94f21;
					}
					.cls-2 {
						fill: #395274;
					}
				</style>
			</defs>
			<path
				class="cls-1"
				d="M167.63,135.71l7.24-10.65-21.28-14.49-7.26,10.64,0,0L112.72,170.5a46.73,46.73,0,0,1-77.25-52.59l40.84-60L54.93,43.35l-40.84,60a72.58,72.58,0,0,0,120,81.69l33.59-49.32Z"
			/>
			<path
				class="cls-2"
				d="M82.37,114.29l-7.24,10.65,21.28,14.49,7.26-10.64,0,0L137.28,79.5a46.73,46.73,0,0,1,77.25,52.59l-40.84,60,21.38,14.56,40.84-60a72.58,72.58,0,0,0-120-81.69L82.32,114.26Z"
			/>
		</svg>

		<svg
			id="Raggruppa_897"
			data-name="Raggruppa 897"
			xmlns="http://www.w3.org/2000/svg"
			width="146.1"
			height="30.188"
			viewBox="0 0 146.1 30.188"
			class="loader-text"
		>
			<path
				id="Tracciato_277"
				data-name="Tracciato 277"
				d="M3022-50.068v-30.12h18.5l5.657,9.924L3040.5-60.088l5.657,10.02h-6.734l-6.238-10.02,6.238-10.176-2.491-3.616h-8.905v23.812Z"
				transform="translate(-3008 80.188)"
				fill="#fff"
			/>
			<rect
				id="Rettangolo_540"
				data-name="Rettangolo 540"
				width="7"
				height="30"
				transform="translate(0 0.188)"
				fill="#fff"
			/>
			<rect
				id="Rettangolo_542"
				data-name="Rettangolo 542"
				width="7"
				height="30"
				transform="translate(44 0.188)"
				fill="#fff"
			/>
			<path
				id="Unione_1"
				data-name="Unione 1"
				d="M17.1,30l-5.05-8.858L7,30H0L8.55,15,0,0H7l5.05,8.858L17.1,0h7L15.55,15,24.1,30Z"
				transform="translate(122 0.192)"
				fill="#fff"
			/>
			<path
				id="Sottrazione_1"
				data-name="Sottrazione 1"
				d="M25.5,30H8.5L0,15,8.5,0h17L34,15,25.5,30ZM12,6h0L7,15l5,9H22l5-9L22,6Z"
				transform="translate(88 0.188)"
				fill="#fff"
			/>
			<path
				id="Tracciato_281"
				data-name="Tracciato 281"
				d="M3066.154-105.068v-29.975h7.19l11.008,19.333v-19.333h6.021v29.975h-7.04l-11.019-19.019v19.019Z"
				transform="translate(-3008 135.043)"
				fill="#fff"
			/>
		</svg>
	</div>
</div>
