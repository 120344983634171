import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { StatusBarOrderService } from '../../service/status-bar-config/implementation/status-bar-order.service';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { PageCommonsModule } from '../commons/page-commons.module';
import { DashboardAgentComponent } from './dashboard/dashboard-agent/dashboard-agent.component';
import { UserComponent } from '../commons/user/user.component';
import { DashboardOrdersComponent } from './dashboard/dashboard-orders/dashboard-orders.component';
import { DashboardClientsComponent } from './dashboard/dashboard-clients/dashboard-clients.component';
import { DashboardSoldComponent } from './dashboard/dashboard-sold/dashboard-sold.component';
import { DashboardExpiredComponent } from './dashboard/dashboard-expired/dashboard-expired.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [
		DashboardAgentComponent,
		UserComponent,
		DashboardOrdersComponent,
		DashboardClientsComponent,
		DashboardSoldComponent,
		DashboardExpiredComponent
	],
	exports: [
		DashboardAgentComponent,
		UserComponent,
		DashboardOrdersComponent,
		DashboardClientsComponent,
		DashboardSoldComponent,
		DashboardExpiredComponent
	],
	entryComponents: [
		DashboardAgentComponent,
		UserComponent,
		DashboardOrdersComponent,
		DashboardClientsComponent,
		DashboardSoldComponent,
		DashboardExpiredComponent
	],
	providers: [StatusBarOrderService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageAgentModule {}
