import { Injectable } from '@angular/core';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';

@Injectable({
	providedIn: 'root'
})
export class UtilAddressService {
	constructor() {}

	getFormattedAddress(address: AddressPouchModel): string {
		let res: string[] = [];

		if (!(address && address.address)) {
			return '';
		}

		if (address.address) {
			res.push(address.address);
			res.push('-');
		}

		if (address.zip_code) {
			res.push(address.zip_code);
		}

		if (address.locality) {
			res.push(address.locality);
		}

		if (address.province) {
			res.push('(' + address.province + ')');
		}

        if (address.country) {
			res.push('(' + address.country + ')');
		}

		return res.join(' ');
	}
}
