import { Injectable } from '@angular/core';
import { ResourceAction, ResourceRequestMethod, IResourceMethod, IResourceMethodPromiseStrict } from '@ngx-resource/core';
import { BaseApiService } from '../baseApi.service';

@Injectable()
export class ConfigurationService extends BaseApiService {
	@ResourceAction({
		path: '/bo/sync',
		method: ResourceRequestMethod.Post
	})
	synchAs400: IResourceMethod<void, void>;

	@ResourceAction({
		path: '/bo/download-product-stock',
		method: ResourceRequestMethod.Post
	})
	syncProductStock: IResourceMethod<void, void>;

	@ResourceAction({
		path: '/file/upload',
		method: ResourceRequestMethod.Post
	})
	uploadCategoryTree: IResourceMethodPromiseStrict<string, {type: string}, void, void>;
}
