import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { DestinationActionEnum, DestinationStateAction } from './destination.actions';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { DestinationFilterModel } from '../../service/pouch-db/filter/destination-filter.model';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';

@Injectable()
export class DestinationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DestinationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<DestinationFilterModel>) => from(this.getDestination(action.data))),
			map((destination: BaseStateModel<DestinationPouchModel>) => DestinationStateAction.update(destination)),
			catchError((error, caught) => {
				this.store.dispatch(DestinationStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getDestination(data: DestinationFilterModel): Promise<BaseStateModel<DestinationPouchModel>> {
		try {
			const destination = await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).destinationPouch.getDestination(
				data
			);
			return new BaseState(destination);
		} catch (err) {
			throw new Error(err);
		}
	}
}
