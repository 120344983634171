import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService, UtilService } from '@saep-ict/angular-core';
import _ from 'lodash';
import { ChartThemeEnum } from '../../../enum/chart-theme.enum';
import { FormatMultiplesCurrency } from '../../../shared/pipes/format-multiples-currency.pipe';
import { AppUtilService } from '../../util/app-util.service';
import { BaseChartClass } from '../chart-configurations/base-chart.service';

@Injectable()
export class FulfilledProductsChartConfigService extends BaseChartClass {
	quarterList;

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		public translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private formatMultiplesCurrency: FormatMultiplesCurrency,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route, translateService);
		this.cardDashboardConfigProductsBestSeller.icon = 'shopping_cart';
		this.chart.chartType = 'horizontalBar';
		this.chart.options = this.optionsStyle(ChartThemeEnum.DEFAULT);
		this.chart.colors = [{ borderColor: this.colorPrimary }];
	}

	populateQuarterList() {
		this.quarterList = this.getQuarterList(this.utilService.findLeaf(this.statistics.data, `fulfilled.quarters`));
	}

	// Functions

	populateChart(quarter: string = this.quarterList[0].q) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let serieTemp = [];
		let labelTemp = [];

		const products = this.utilService
			.findLeaf(this.statistics.data, `fulfilled.quarters`)
			.find(item => item.name === quarter).products;
		products.forEach(product => {
			serieTemp.push(product.value);
			labelTemp.push(product.description);
		});

		let fullDataSet = [];
		fullDataSet.push({
			label: 'chart.products_best_seller.income',
			backgroundColor: [
				this.colorPrimary70,
				this.colorPrimary,
				this.colorPrimary70,
				this.colorPrimary,
				this.colorPrimary70
			],
			data: serieTemp
		});

		this.addData(this.chart.datasets, fullDataSet);
		this.addData(this.chart.labels, labelTemp);
		this.chart = _.cloneDeep(this.chart);
	}

	// Style

	tooltipsCallbacks(translateService: TranslateService, sentencecasePipe: SentencecasePipe) {
		return {
			label: function (tooltipItem, data) {
				let label = translateService.instant(data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += tooltipItem.value;
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartThemeEnum) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [4, 4],
					color: theme === ChartThemeEnum.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					// min: 0,
					// Scala da usare
					// stepSize: 1000,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartThemeEnum.DEFAULT ? this.colorBlack : this.colorWhite
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					min: 0,
					// Scala da usare
					stepSize: 100
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartThemeEnum) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
