import { Injectable } from '@angular/core';
import { ChartjsModel, UtilService, UtilColorService } from '@saep-ict/angular-core';
import { ChartThemeEnum } from '../../../enum/chart-theme.enum';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LineCurrencyChartConfigurationServiceLocal extends BaseChartClass {
	constructor(
		public utilService: UtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		public route: ActivatedRoute,
		public translateService: TranslateService
	) {
		super(utilService, utilColorService, store, route, translateService);
	}

	default: ChartjsModel = {
		data: null,
		datasets: [],
		labels: [],
		chartType: 'line',
		options: this.optionsStyle(ChartThemeEnum.DEFAULT),
		colors: [
			{
				backgroundColor: this.colorPrimary10,
				borderColor: this.colorPrimary
			}
		],
		legend: false
	};

	accent: ChartjsModel = {
		...this.default,
		options: this.optionsStyle(ChartThemeEnum.ACCENT),
		colors: [
			{
				backgroundColor: this.colorBlack30,
				borderColor: this.colorBlack
			},
			{
				backgroundColor: this.colorWhite50,
				borderColor: this.colorWhite
			}
		]
	};

	tooltipsCallbacks() {
		return {
			label: function (tooltipItem, data) {
				var label = data.datasets[tooltipItem.datasetIndex].label || '';

				if (label) {
					label += ': ';
				}
				label += (tooltipItem.yLabel / 1000).toFixed(2).replace('.', ',') + 'k';
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartThemeEnum) {
		return [
			{
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [4, 4],
					color: theme === ChartThemeEnum.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					min: 0,
					// Scala da usare
					stepSize: 10000,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartThemeEnum.DEFAULT ? this.colorBlack : this.colorWhite,
					callback: this.ticksCallback()
				}
			}
		];
	}
	xAxesStyle() {
		return [
			{
				gridLines: {
					drawBorder: false,
					display: false
				},
				ticks: {
					display: false
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks()
		};
	}

	optionsStyle(theme: ChartThemeEnum) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			}
		};
	}
}
