import { ConfigurationCustomerContextSelector } from '@saep-ict/ticket-center';

export namespace TicketCenterLocalSearchTextKeyList {
	export const base: string[] = ['id'];
	export const backoffice: string[] = ['id', 'body.custom_field.organization_code_item'];

	export const contextApplicationMap = <ConfigurationCustomerContextSelector<string[]>>{
		BACKOFFICE: TicketCenterLocalSearchTextKeyList.backoffice,
		B2B: TicketCenterLocalSearchTextKeyList.base
	};
}
