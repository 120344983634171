<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="localListHandlerWidgetWrapperColumnList"
	>
    <ng-template tdDataTableTemplate="business_name" let-row="row" let-value="value">
        <p>
            {{ value }}
            <br />
            <small class="opacity-50">
                {{ row.piva }}
            </small>
        </p>
    </ng-template>

    <ng-template tdDataTableTemplate="total" let-row="row" let-value="value">
        <p>
            {{ value | appFormatMultiplesCurrency }}
            <br />
            <small class="opacity-50">
                {{ row.count }}
                {{ (row.count > 1 ? 'order.name_plural' : 'order.name') | translate }}
            </small>
        </p>
    </ng-template>

    <ng-template tdDataTableTemplate="address" let-row="row" let-value="value">
        <p *ngIf="value">
            {{ value.address }}
            <br />
            <small class="opacity-50">
                {{ value.zip_code }} {{ value.locality }} ({{ value.province }})
            </small>
        </p>
    </ng-template>
	</td-data-table>
	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.pageSize"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
