import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommentStateModel } from '../../model/state/comment-state.model';

@Component({
	selector: 'comment-thread',
	templateUrl: './comment-thread.component.html',
	styleUrls: ['./comment-thread.component.scss']
})
export class CommentThreadComponent implements OnInit {
	@Input() canEdit = false;

	_commentList: CommentStateModel[];
	@Input('commentList') set commentList(c: CommentStateModel[]) {
		if (c) {
			this._commentList = c.sort((a, b) => b.created_on - a.created_on);
		}
	}

	@Output() onEdit = new EventEmitter<number>();
	@Output() onDelete = new EventEmitter<number>();

	constructor() {}

	ngOnInit() {}
}
