import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseTableListService } from '@saep-ict/angular-core';
import { CurrencyPipe } from '@angular/common';

export const dashboardRecentOrdersColumns = ['header.status', 'odv_code', 'header.organization.business_name'];

@Injectable()
export class OrderListColumnService extends BaseTableListService {
	constructor(
		@Inject(TranslateService) public translate: TranslateService,
		@Inject(TranslateService) public currencyPipe: CurrencyPipe
	) {
		super();
	}
	_columns = [
		{
			name: 'header.status',
			label: '',
			width: 50,
			sortable: false
		},
		{
			sortable: false,
			name: '_id',
			label: this.translate.instant('order.name') + ' #',
			width: 128
		},
		{
			name: 'header.organization.business_name',
			label: this.translate.instant('order.field.organization')
		},
		{
			name: 'header.goods_destination_code',
			label: this.translate.instant('order.field.destination'),
			sortable: false
		},
		{
			name: 'csuite.order_so_client_ref',
			label: this.translate.instant('order.field.order_so_client_ref')
		},
		{
			name: 'csuite.order_so_client_date',
			label: this.translate.instant('order.field.order_so_client_date')
		},
		{
			name: 'header.date',
			label: this.translate.instant('order.field.creation_date'),
			numeric: true,
			width: 100
		},
		{
			name: 'header.first_evasion_date',
			label: this.translate.instant('order.field.delivery_date'),
			numeric: true,
			width: 100
		},
		{
			// TODO: rendere sortable dopo aver mergiato il valore dell'ordine, attualmente è solo in visualizzazione
			name: 'iva',
			sortable: false,
			label: this.translate.instant('order.field.amount'),
			numeric: true,
			width: 130,
			format: (v: number) => this.currencyPipe.transform(v, 'EUR', 'symbol', '', 'it')
		},
		{
			name: 'info',
			sortable: false,
			label: '',
			width: 50
		}
	];
}
