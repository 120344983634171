<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="row">
		<div class="col-md-12">
			<h2 class="mb-0" mat-dialog-title>
				{{ data.contextTypeAndApplicationLink.context_code_item.description | titlecase }}:
				{{ data.contextCodeItem['code_erp'] || data.contextCodeItem.code }}
			</h2>
			<h2>{{ data.contextCodeItem.description }}</h2>
			<p>
				{{ 'context_code.intended_for' | translate | sentencecase }}
				<strong>
					{{ utilService.formatPlaceholder(data.contextTypeAndApplicationLink.application_name) }}
				</strong>
			</p>
		</div>
	</div>
	<ng-scrollbar [style.max-height.px]="mediaReplayService.deviceH - 250">
		<form-permission-group-select formControlName="permission" [groupList]="contextPermissionGroup">
		</form-permission-group-select>
	</ng-scrollbar>
	<mat-dialog-actions align="end">
		<!-- Da controllare -->
		<mat-toolbar class="height-auto">
			<button mat-stroked-button mat-dialog-close type="button" color="primary">
				{{ 'general.cancel' | translate | uppercase }}
			</button>
			<button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">
				{{ 'general.save' | translate | uppercase }}
			</button>
		</mat-toolbar>
	</mat-dialog-actions>
</form>
