import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, LocalListHandlerBaseModel } from '@saep-ict/angular-core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { chartTimeRange30Days } from '../../../constants/chart-time-range.constants';
import { StatisticsAgent, StatisticsAgentBaseSerieClient } from '../../../model/statistics-agent.model';
import { StateFeature } from '../../../state';
import { AppUtilService } from '../../util/app-util.service';

@Injectable()
export class ClientsTop5TableConfigService {
	statisticsAgent$: Observable<BaseStateModel<StatisticsAgent>> = this.store.select(StateFeature.getStatisticsAgent);
	statisticsAgent: StatisticsAgent;

	fullDataSetDays30: StatisticsAgentBaseSerieClient[] = [];
	fullDataSetDays60: StatisticsAgentBaseSerieClient[] = [];
	fullDataSetDays90: StatisticsAgentBaseSerieClient[] = [];

	currentDataSet: StatisticsAgentBaseSerieClient[] = [];

	// Questa proprietà viene letta anche per popolare cardDashboardConfigOrderTrend
	public timeRange = chartTimeRange30Days;

	public listClientsData = <LocalListHandlerBaseModel<StatisticsAgentBaseSerieClient>>{
		pagination: {
			pageSize: 5
		}
	};

	constructor(private store: Store<any>, private utilService: AppUtilService) {
		this.statisticsAgent$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.statisticsAgent = res.data;
				this.createDataSerie();
				this.populateTable();
			});
	}

	// Functions

	createDataSerie() {
		const clientsTop5 = this.utilService.findLeaf(this.statisticsAgent.data, 'clients.top5.order_value');

		this.utilService.findLeaf(clientsTop5, 'days_30').forEach((item: StatisticsAgentBaseSerieClient) => {
			this.fullDataSetDays30.push(item);
		});

		this.utilService.findLeaf(clientsTop5, 'days_60').forEach((item: StatisticsAgentBaseSerieClient) => {
			this.fullDataSetDays60.push(item);
		});

		this.utilService.findLeaf(clientsTop5, 'days_90').forEach((item: StatisticsAgentBaseSerieClient) => {
			this.fullDataSetDays90.push(item);
		});
	}

	populateTable(rangeDays: number = this.timeRange.values[0]) {
		switch (rangeDays) {
			case 30:
				this.currentDataSet = this.fullDataSetDays30;
				break;
			case 60:
				this.currentDataSet = this.fullDataSetDays60;
				break;
			case 90:
				this.currentDataSet = this.fullDataSetDays90;
				break;

			default:
				break;
		}
		this.updateListClientsData(this.currentDataSet);
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListClientsData(list: StatisticsAgentBaseSerieClient[]) {
		this.listClientsData.data = _.cloneDeep(list);
		this.listClientsData = Object.assign({}, this.listClientsData);
	}
}
