import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	LocalListHandlerBaseModel,
	Pagination,
	SubscribeManagerService,
	UserDetailModel,
	UtilService
} from '@saep-ict/angular-core';
import { OrderPouchModel } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, skipWhile, take, filter } from 'rxjs/operators';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel } from '../../../model/state/order-state.model';
import { StatisticsOrders } from '../../../model/statistics-orders.model';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { PATH_URL } from '../../../router/route-naming';
import { ClientsActiveChartConfigService } from '../../../service/chart-structure/implementation/clients-active-chart.service';
import { ClientsInactiveChartConfigService } from '../../../service/chart-structure/implementation/clients-inactive-chart.service';
import { ClientsTop5TableConfigService } from '../../../service/chart-structure/implementation/clients-top5-table.service';
import { ProductsBestSellerChartConfigService } from '../../../service/chart-structure/implementation/products-best-seller-chart.service';
import { FulfilledTrendChartConfigService } from '../../../service/chart-structure/implementation/fulfilled-trend-chart.service';
import { OrderAverageChartConfigService } from '../../../service/chart-structure/implementation/order-average-chart.service';
import { OrderBestOrderedChartConfigService } from '../../../service/chart-structure/implementation/order-best-ordered-chart.service';
import { OrderQuantityChartConfigService } from '../../../service/chart-structure/implementation/order-quantity-chart.service';
import { OrderTrendChartConfigService } from '../../../service/chart-structure/implementation/order-trend-chart.service';
import { OrderListFilterModel } from '../../../service/pouch-db/filter/order-filter.model';
import { ClientsTop5ColumnService } from '../../../service/td-data-table/implementation/clients-top5.service';
import {
	dashboardRecentOrdersColumns,
	OrderListColumnService
} from '../../../service/td-data-table/implementation/order-list.service';
import { UtilCompanyService } from '../../../service/util/util-company.service';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { StatisticsAgent } from '../../../model/statistics-agent.model';
import { StatisticsAgentStateAction } from '../../../state/statistics-agent/statistics-agent.action';

@Component({
	selector: 'dashboard-crm',
	templateUrl: './dashboard-crm.component.html',
	styleUrls: ['./dashboard-crm.component.scss'],
	providers: [
		SubscribeManagerService,
		OrderListColumnService,
		ClientsTop5ColumnService,
		ClientsActiveChartConfigService,
		OrderTrendChartConfigService,
		ClientsInactiveChartConfigService,
		ClientsTop5TableConfigService,
		ProductsBestSellerChartConfigService,
		FulfilledTrendChartConfigService,
		OrderAverageChartConfigService,
		OrderBestOrderedChartConfigService,
		OrderQuantityChartConfigService
	]
})
export class DashboardCrmComponent implements OnInit, OnDestroy {
	@ViewChild('dataTableClientsTop5', { static: false }) dataTableClientsTop5: TdDataTableComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);

	statisticsOrders$: Observable<BaseStateModel<StatisticsOrders>> = this.store.select(
		StateFeature.getStatisticsOrders
	);
	statisticsOrders: StatisticsOrders;

	// Per la visualizzazione della data di ultimo aggiornamento delle statistiche
	dateUpdatedStatistics: number;
	statisticsAgent$: Observable<BaseStateModel<StatisticsAgent>> = this.store.select(StateFeature.getStatisticsAgent);

	// TODO: deprecare
	orderFilter: BaseStateModel<OrderStateModel[], OrderListFilterModel>;
	orderPagination: Pagination = { page_current: 1, page_size: 10 };
	orderSort: { [key: string]: 'asc' | 'desc' }[] = [
		{
			'header.date': 'desc'
		},
		{
			'header.status': 'desc'
		}
	];

	listPageBaseLastOrder = <LocalListHandlerBaseModel<TableOrderModel>>{
		pagination: {
			pageSize: 5
		}
	};

	// Configurazione dashboard-card senza chart
	cardDashboardConfigOrdersCurrent: CardDashboardConfig = {
		title: 'chart.current_orders.title',
		subtitle: 'chart.current_orders.subtitle',
		icon: 'list_alt',
		helpText: 'chart.current_orders.helptext'
	};
	cardDashboardConfigOrdersRecent: CardDashboardConfig = {
		title: 'chart.recent_orders.title',
		subtitle: 'chart.recent_orders.subtitle',
		subtitleTranslateParams: { amount: '5' },
		icon: 'list_alt'
	};
	cardDashboardConfigClientsTop5: CardDashboardConfig = {
		title: 'chart.clients_top_x.title',
		titleTranslateParams: { amount: '5' },
		subtitle: 'chart.clients_top_x.subtitle',
		icon: 'work',
		rangeTempList: this.clientsTop5TableConfigService.timeRange
	};

	columns: ITdDataTableColumn[] = [];

	// Fulfilled (venduto)

	constructor(
		private store: Store<any>,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		public orderListColumnService: OrderListColumnService,
		private utilOrderService: UtilOrderService,
		public utilCompanyService: UtilCompanyService,
		public utilService: UtilService,
		// Tabella
		public clientsTop5ColumnService: ClientsTop5ColumnService,
		public clientsTop5TableConfigService: ClientsTop5TableConfigService,
		// Grafici
		public orderTrendChartConfigService: OrderTrendChartConfigService,
		public orderAverageChartConfigService: OrderAverageChartConfigService,
		public orderQuantityChartConfigService: OrderQuantityChartConfigService,
		public orderBestOrderedChartConfigService: OrderBestOrderedChartConfigService,
		public fulfilledTrendChartConfigService: FulfilledTrendChartConfigService,
		public productsBestSellerChartConfigService: ProductsBestSellerChartConfigService,
		public clientsActiveChartConfigService: ClientsActiveChartConfigService,
		public clientsInactiveChartConfigService: ClientsInactiveChartConfigService
	) {
		// setto corrette colonne per la lista desiderata
		this.columns = this.orderListColumnService.columns.filter(col =>
			dashboardRecentOrdersColumns.includes(col.name)
		);

		this.user$
			.pipe(
				skipWhile((res: BaseState<UserDetailModel>) => !(res && res.data && res.data.current_permission)),
				take(1)
			)
			.subscribe((res: BaseState<UserDetailModel>) => {
				this.user = res.data;
				// TODO - user_code hardcodato per vedere dei dati.
				// nel contesto CRM this.user.current_permission.context_code.code non è popolato, non è un agent
				this.user.current_permission.context_code = {
					code: '017',
					context_code_association_list: []
				};
				this.orderFilter = new BaseState<OrderStateModel[], OrderListFilterModel>(null, {
					appliedFilter: {
						user_code: this.user.current_permission.context_code.code
					},
					pagination: this.orderPagination,
					// TODO: l'indicazione del sort viene attualmente ingnorata e gestita univocamente presso order-list-wrapper
					sort: this.orderSort
				});
				this.store.dispatch(OrderListStateAction.loadWithDetail(this.orderFilter));
				this.subscribeManagerService.populate(this.retrieveOrderList().subscribe(), 'order-list');
			});

		this.statisticsOrders$
			.pipe(
				filter((state: BaseStateModel<StatisticsOrders>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				if (state && state.data) {
					this.statisticsOrders = state.data;
					console.log('---> ', this.statisticsOrders);
					console.log('---> ', this.statisticsOrders);
				}
			});

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statisticsAgent$
			.pipe(
				filter((state: BaseStateModel<StatisticsAgent>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.dateUpdatedStatistics = state.data.date_update;
			});
	}

	ngOnInit() {}

	retrieveOrderList(): Observable<BaseStateModel<OrderStateModel[]>> {
		return this.orderList$.pipe(
			skipWhile(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					!(
						this.user.current_permission.context_code.code &&
						orderList &&
						orderList.type !== OrderListActionEnum.LOAD
					)
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				if (orderList.type === OrderListActionEnum.ERROR) {
					throw new Error(OrderListActionEnum.ERROR);
				}
				this.listPageBaseLastOrder.data = this.utilOrderService.getTableOrderList(_.cloneDeep(orderList.data));
				// il setter non viene scatta pur presentando i nuovi valori passati da getTableOrderList
				// senza il riassegnamento dell'intero oggetto.
				this.listPageBaseLastOrder = Object.assign({}, this.listPageBaseLastOrder);
				return orderList;
			})
		);
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		this.router.navigate([
			`${PATH_URL.PRIVATE}/orders/${selectedOrder.header.status}/${
				(selectedOrder.header as ExtraFieldOrderHeaderPouchModel).organization.code_item
			}/${selectedOrder._id}/checkout`
		]);
	}

	// Table
	changeTimeRangeClientsTop5(e: MatSelectChange) {
		this.clientsTop5TableConfigService.populateTable(e.value);

		if (this.dataTableClientsTop5) {
			this.dataTableClientsTop5.refresh();
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrderListStateAction.reset());
	}
}
