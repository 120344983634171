<form (ngSubmit)="userPasswordRecovery()" [formGroup]="form">
	<div class="row">
		<div class="col-1 icon-input"><mat-icon>email</mat-icon></div>
		<div class="col-10">
			<mat-form-field>
				<input
					matInput
					formControlName="email"
					placeholder="{{ 'login.general.email' | translate | sentencecase }}"
				/>
			</mat-form-field>
		</div>
	</div>

	<mat-toolbar-row>
		<button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">
			{{ 'login.password_recovery.reset_password' | translate | sentencecase }}
		</button>
	
		<button mat-stroked-button type="button" color="primary" [routerLink]="cancelLink">
			{{ 'general.cancel' | translate | sentencecase }}
		</button>
	</mat-toolbar-row>
</form>
