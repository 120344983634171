import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import { StateFeature } from '..';
import { DateMomentService } from '@saep-ict/angular-core';
import { StatisticsDetailSoldActionEnum, StatisticsDetailSoldStateAction } from './statistics-detail-sold.action';
import { StatisticsDetailSold } from '../../model/statistics-detail-sold.model';
import { StatisticsAgentBaseSerieOrder } from '../../model/statistics-agent.model';

@Injectable()
export class StatisticsDetailSoldEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsDetailSoldActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsDetailSold>) => {
				return from(this.getStatisticsDetailSold(action));
			}),
			mergeMap((action: BaseStateModel<StatisticsDetailSold>) => from(this.sortStatisticsDetailSold(action))),
			map((action: BaseStateModel<StatisticsDetailSold>) => StatisticsDetailSoldStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsDetailSoldStateAction.error(null));
				return caught;
			})
		)
	);

	// Chiavi di Venduto
	keyFulfilled = ['fulfilled_trend'];

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService
	) {}

	async getStatisticsDetailSold(
		action: BaseStateModel<StatisticsDetailSold>
	): Promise<BaseStateModel<StatisticsDetailSold>> {
		let currentContext;
		await this.store
			.select(StateFeature.getUserState)
			.pipe(take(1))
			.subscribe(res => {
				currentContext = res ? res.data.current_permission.context_application : null;
			});
		await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.STATISTICS)
		).statisticsPouch
			.getStatisticsDetailSold(currentContext)
			.then(res => {
				action.data = res;
			})
			.catch(err => console.log(err));
		return action;
	}

	async sortStatisticsDetailSold(
		action: BaseStateModel<StatisticsDetailSold>
	): Promise<BaseStateModel<StatisticsDetailSold>> {
		this.keyFulfilled.forEach(key => {
			if (action.data[key]) {
				action.data[key].sort((a, b) => a.date - b.date);
			}
		});
		return action;
	}
}
