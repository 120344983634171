<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<h2 mat-dialog-title>
		{{ data.title | translate | sentencecase }}
	</h2>
	<mat-dialog-content class="mat-typography">
		<!-- TODO -->
		<!-- <company-detail-destination-new></company-detail-destination-new> -->
	</mat-dialog-content>
	<mat-dialog-actions *ngIf="!data.disabled">
		<!-- Da controllare -->
		<mat-toolbar class="height-auto">
			<button mat-button type="button" [mat-dialog-close]="false">
				{{ 'general.cancel' | translate | sentencecase }}
			</button>
			<button mat-raised-button color="primary" type="submit">
				{{ 'destination.add_new' | translate | sentencecase }}
			</button>
		</mat-toolbar>
	</mat-dialog-actions>
</form>
