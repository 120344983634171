import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AuthService } from '../service/rest/auth.service';
import { UserService } from '../service/rest/user.service';
import { CompanyDashboardColumnService } from '../service/td-data-table/implementation/company-dashboard.service';
import { OrganizationColumnService } from '../service/td-data-table/implementation/organization-list.service';
import { OpportunityListColumnService } from '../service/td-data-table/implementation/opportunity-list.service';
import {
	ProductCatalogColumnService,
	ProductKitColumnService
} from '../service/td-data-table/implementation/product-catalog.service';
import { ProductCheckoutColumnService } from '../service/td-data-table/implementation/product-checkout.service';
import { PageDataService } from '../service/util/page-data.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { OrderDashboardColumnService } from './../service/td-data-table/implementation/order-dashboard.service';
import { PageAgentModule } from './agent/page-agent.module';
import { PageB2BModule } from './b2b/page-b2b.module';
import { PageBackofficeModule } from './backoffice/page-backoffice.module';
import { PageCommonsModule } from './commons/page-commons.module';
import {
	CompanyDetailDestinationsColumnService,
	CompanyDetailFullDetailPaymentsColumnService
} from '../service/td-data-table/implementation/company-detail.service';
import { ConfigurationService } from '../service/rest/backoffice/configuration.service';
import { CompanyAccountBalanceColumnService } from '../service/td-data-table/implementation/company-account-balance.service';
import { ContactColumnService } from '../service/td-data-table/implementation/contact.service';
import { PageStorefrontModule } from './b2c/storefront/page-storefront.module';
import { OrderListColumnService } from '../service/td-data-table/implementation/reserved-area/order-list.service';
import { AddressListColumnService } from '../service/td-data-table/implementation/reserved-area/address-list.service';
import { CompanyService } from '../service/rest/company.service';
import { UserManagementListColumnService } from '../service/td-data-table/implementation/backoffice/user-management-list.service';
import { PermissionContextListColumnService } from '../service/td-data-table/implementation/backoffice/permission-context-list.service';
import { CatalogService } from '../service/rest/catalog.service';
import { StorefrontConfigurationService } from '../service/rest/storefront-configuration.service';
import { ReceiptColumnService } from '../service/td-data-table/implementation/receipt-list.service';
import { PageCrmModule } from './crm/page-crm.module';
import { OfferListColumnService } from '../service/td-data-table/implementation/offer-list.service';
import { InformativePageComponent } from './commons/informative-page/informative-page.component';
import { PageB2cModule } from './b2c/page-b2c.module';
import { InformativePageService } from '../service/rest/informativePage.service';
import { ArticleColumnService } from '../service/td-data-table/implementation/article.service';
import { OrderService } from '../service/rest/order.service';
import { LanguageService } from '../service/rest/language.service';
import { DrawingColumnService } from '../service/td-data-table/implementation/drawing.service';
import { ProductService } from '../service/rest/product.service';
import { UtilEventTrackingService } from '../service/util/util-event-tracking.service';

@NgModule({
	imports: [
		WidgetModule,
		SharedModule,
		PageAgentModule,
		PageB2BModule,
		PageB2cModule,
		PageBackofficeModule,
		PageCommonsModule,
		PageStorefrontModule,
		PageCrmModule
	],
	declarations: [InformativePageComponent],
	providers: [
		AuthService,
		PageDataService,
		UserService,
		CompanyService,
		CatalogService,
		StorefrontConfigurationService,
		OrderService,
		InformativePageService,
		LanguageService,
		ProductService,

		// backoffice
		ConfigurationService,

		// td-data-table columns
		OrderDashboardColumnService,
		CompanyDashboardColumnService,
		OrganizationColumnService,
		OpportunityListColumnService,
		OfferListColumnService,
		ReceiptColumnService,
		ProductCatalogColumnService,
		ProductKitColumnService,
		ProductCheckoutColumnService,
		UserManagementListColumnService,
		CompanyDetailDestinationsColumnService,
		CompanyDetailFullDetailPaymentsColumnService,
		CompanyAccountBalanceColumnService,
		ContactColumnService,
		OrderListColumnService,
		AddressListColumnService,
		PermissionContextListColumnService,
		ArticleColumnService,
		DrawingColumnService,
		UtilEventTrackingService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
