<mat-card>
	<div class="mat-card-header">
		<img class="img-icon" [src]="personImage ? personImage : '../../../assets/img/avatar-placeholder.svg'" />
	</div>
	<mat-card-content>
		<div class="title-card">
			<p class="title">{{ title }}</p>
			<p>
				<span *ngIf="isActiveIndicator !== null" class="isActive" [ngClass]="isActiveIndicator ? 'active' : 'not-active'"></span>
				<small class="subtitle">{{ subtitle }}</small>
			</p>
		</div>
		<div class="content-card">
			<ng-content select="[content]"></ng-content>
		</div>
	</mat-card-content>
	<mat-card-actions>
		<ng-content select="[action]"></ng-content>
	</mat-card-actions>
</mat-card>
