<breadcrumb [title]="(currentContext === ContextApplicationItemCodeEnum.BACKOFFICE ? 'client.list' : 'organization.list') | translate | sentencecase" [pathList]="['Home']"> </breadcrumb>
<div list-wrapper #listWrapper [filterOpened]="filterOpened" [titleSidebar]="'tc.general.filters' | translate | sentencecase ">
	<ng-container main>
		<mat-card>
			<organization-list-wrapper
				[localListHandlerWidgetWrapperData]="listPageBaseData"
				[localListHandlerWidgetWrapperColumnList]="columns"
				[localListHandlerTextFilterShow]="true"
				[localListHandlerPaginatorShow]="true"
				[currentContext]="currentContext"
				[canAddNew]="canAddNew"
				[localListHandlerSidebarFilterShow]="true"
				(localListHandlerOpenSidebarFilter)="listWrapper.sidenav.open()"
				(selectCompany)="goToDetail($event.level, $event.code)"
				(openOrderNew)="goToOrderDetail($event)"
				[localListHandlerTitle]="
					currentContext === ContextApplicationItemCodeEnum.BACKOFFICE ? ('client.list' | translate | sentencecase) : 
					(('organization.list' | translate | sentencecase) +
					(organizationLevel ? ' - ' + (organizationLevel | sentencecase) : ''))
				"
			>
			</organization-list-wrapper>
		</mat-card>
	</ng-container>

	<ng-container side>
		<form [formGroup]="formFilters" (ngSubmit)="onFormFiltersSubmit()">
			<mat-form-field>
				<mat-label>{{ 'organization.field.status' | translate | sentencecase }}</mat-label>
				<mat-select formControlName="company_status">
					<mat-option *ngFor="let item of companyStates" [value]="item">
						<div class="chips-mini">
							<span [ngClass]="item.toUpperCase()"></span>
						</div>
						{{ 'company.status.' + item | translate | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- filtro tipologia organizzazione -->
			<!--
			<mat-form-field>
				<mat-label>{{ 'tipologia' | translate | sentencecase }}</mat-label>
				<mat-select formControlName="organization_type">
					<mat-option *ngFor="let item of organizationTypeList" [value]="item">
						{{ 'organization.type.' + item | translate | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			-->
			<mat-toolbar>
				<button class="mr-8" mat-raised-button color="primary" type="submit">
					{{ 'general.apply_filters' | translate | sentencecase }}
				</button>
				<button mat-button color="default" type="button" (click)="resetFilters()">
					{{ 'general.reset' | translate | sentencecase }}
				</button>
			</mat-toolbar>
		</form>
	</ng-container>
</div>
<!--
<button
	class="mat-fab-button"
	(click)="createNew()"
	mat-fab
	[matTooltip]="'organization.add_new' | translate | sentencecase"
	matTooltipPosition="left"
	*ngIf="currentContext === ContextApplicationItemCodeEnum.CRM"
>
	<mat-icon>add</mat-icon>
</button>
-->
