<div class="container-fluid">
	<!-- HEADER -->
	<div class="header">
		<!-- Quarter -->
		<div>{{ 'chart.field.time_range' | translate | sentencecase }}</div>
		&nbsp; &nbsp;
		<mat-form-field class="header__quarter" *ngIf="timeRange">
			<mat-select [value]="timeRange[0].q" (selectionChange)="setTimeRange($event)">
				<mat-option *ngFor="let rangeTemp of timeRange" [value]="rangeTemp.q">
					{{ 'general.time.quarter.' + rangeTemp.q | translate: { year: rangeTemp.y } | sentencecase }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- Separator -->
		<div class="header__separator"></div>

		<!-- Date last update statistics -->
		<div class="header__statistics">
			{{ 'agent.updated_statistics' | translate }} {{ dateUpdatedStatistics | date: 'dd/MM/yyyy' }}
		</div>
	</div>

	<!-- SOLD TREND -->
	<div class="row">
		<div class="col-12">
			<card-dashboard
				[cardDashboardConfig]="fulfilledQuartersChartConfigService.cardDashboardConfigFulfilledQuarters"
			>
				<chart-wrapper [config]="fulfilledQuartersChartConfigService.chart"></chart-wrapper>
			</card-dashboard>
		</div>
	</div>

	<!-- AVERAGE -->
	<div class="row">
		<!-- Ordini: Valore medio -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="fulfilledTrendDetailChartConfigService.cardDashboardConfigFulfilledTrend"
			>
				<div class="orders-value">
					<div class="row orders-value__header" *ngIf="fulfilledTrendDetailChartConfigService.metrics">
						<div class="orders-value__header__average">
							{{ fulfilledTrendDetailChartConfigService.metrics.average | appFormatMultiplesCurrency }}
						</div>
						<div class="orders-value__header__separator"></div>
						<div class="orders-value__header__trend">
							<icon-trend [value]="fulfilledTrendDetailChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row orders-value__body">
						<chart-wrapper
							class="row orders-value__body__chart"
							[config]="fulfilledTrendDetailChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>

		<!-- Ordini: Quantità -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="fulfilledQuantityChartConfigService.cardDashboardConfigFulfilledQuantity"
			>
				<div class="orders-value">
					<div class="row orders-value__header" *ngIf="fulfilledQuantityChartConfigService.metrics">
						<div class="orders-value__header__average">
							{{ fulfilledQuantityChartConfigService.metrics.average | appFormatMultiplesCurrency }}
						</div>
						<div class="orders-value__header__separator"></div>
						<div class="orders-value__header__trend">
							<icon-trend [value]="fulfilledQuantityChartConfigService.metrics.trend"></icon-trend>
						</div>
					</div>
					<div class="row orders-value__body">
						<chart-wrapper
							class="row orders-value__body__chart"
							[config]="fulfilledQuantityChartConfigService.chart"
						></chart-wrapper>
					</div>
				</div>
			</card-dashboard>
		</div>
	</div>

	<!-- PRODUCTS -->
	<div class="row">
		<!-- Venduto: grafico prodotti più venduti -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard
				[cardDashboardConfig]="fulfilledProductsChartConfigService.cardDashboardConfigProductsBestSeller"
			>
				<ng-container *ngIf="fulfilledProductsChartConfigService.chart.datasets.length">
					<chart-wrapper [config]="fulfilledProductsChartConfigService.chart"></chart-wrapper>
				</ng-container>
			</card-dashboard>
		</div>

		<!-- Venduto: lista prodotti più venduti -->
		<div class="col-sm-12 col-md-12 col-lg-6">
			<card-dashboard [cardDashboardConfig]="fulfilledProductsTableConfigService.cardDashboardConfigProductsTop5">
				<ng-container *ngIf="fulfilledProductsTableConfigService.currentDataSet.length; else noData">
					<td-data-table
						[data]="fulfilledProductsTableConfigService.currentDataSet"
						[columns]="fulfilledProductsTableConfigService.columns"
					>
						<ng-template tdDataTableTemplate="description" let-value="value">
							<p>
								{{ value }}
							</p>
						</ng-template>

						<ng-template tdDataTableTemplate="value" let-value="value">
							<p>
								{{ value | appFormatMultiplesCurrency }}
							</p>
						</ng-template>
					</td-data-table>
				</ng-container>
				<ng-template #noData>
					<chart-no-data icon="grid_on"></chart-no-data>
				</ng-template>
			</card-dashboard>
		</div>
	</div>

	<div class="row">
		<!-- Venduto: grafico categorie più vendute -->
		<div class="col-sm-12 col-md-12 col-lg-6"></div>

		<!-- Venduto: lista categorie più vendute -->
		<div class="col-sm-12 col-md-12 col-lg-6"></div>
	</div>
</div>
