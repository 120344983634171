import { BaseTableListService } from '@saep-ict/angular-core';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, TitleCasePipe } from '@angular/common';

@Injectable()
export class DrawingColumnService extends BaseTableListService {
	constructor(
		@Inject(TranslateService) public translate: TranslateService,
		@Inject(DatePipe) private datePipe: DatePipe,
		private titleCasePipe: TitleCasePipe
	) {
		super();
	}

	_columns = [
		{
			name: 'titolo_allegato',
			label: this.titleCasePipe.transform(this.translate.instant('general.name')),
			width: { min: 200 }
		},
		{
			name: 'estensione_allegato',
			label: this.titleCasePipe.transform(this.translate.instant('general.file.extension')),
			width: 150
		},
		{
			name: 'data_inserimento',
			label: this.titleCasePipe.transform(this.translate.instant('general.date.insertion')),
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy')
		},
		{
			name: 'data_aggiornamento',
			label: this.titleCasePipe.transform(this.translate.instant('general.date.update')),
			format: (v: number) => this.datePipe.transform(v, 'dd/MM/yyyy')
		},
		{
			name: 'dimensione_allegato_byte',
			label: this.titleCasePipe.transform(this.translate.instant('general.file.size'))
		},
		{
			name: 'action',
			label: '',
			sortable: false,
			numeric: true
		}
	];
}
