<!-- Jumbotron iniziale -->
<section class="jumbotron-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<app-jumbotron [jumbotronConf]="jumbotronValue"> </app-jumbotron>
			</div>
		</div>
	</div>
</section>

<!-- Descrizione -->
<section class="entry-description">
	<div class="container">
		<div class="row">
			<div class="col-md-10 offset-md-1">
				<h2>Lorem impsum dolor sit amet, censetetur sadipscing</h2>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et
					doloLorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et
					dolo.
				</p>
			</div>
		</div>
	</div>
</section>

<!-- Categorie -->
<section class="section-slider">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h3 class="section-slider__title">
					{{ 'general.categories' | translate | sentencecase }}
				</h3>
				<div class="decoration-baseline"></div>
			</div>
		</div>
	</div>
	<app-card-slider *ngIf="config && homeHighlights" [config]="config">
		<div *sliderItem="let item; let idx = index" class="card-slider-item">
			<app-card-product
				[itemType]="ItemType.category"
				[viewType]="viewTypeEnum.module"
				[data]="item"
			></app-card-product>
		</div>
	</app-card-slider>
</section>

<!-- Prodotti in evidenza -->
<section class="highlight-products">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h3 class="highlight-products__title">
					{{ 'home_sections.highlighted_products' | translate | sentencecase }}
				</h3>
				<div class="decoration-baseline"></div>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12 col-md-6 col-lg-4 mt-3" *ngFor="let card of homeHighlights?.data.products">
				<div class="highlight-products__cards">
					<app-card-product
						[itemType]="ItemType.product"
						[viewType]="viewTypeEnum.module"
						[data]="card"
					></app-card-product>
				</div>
			</div>
		</div>
	</div>
</section>

<app-call-to-action
	[callToActionConfig]="callToActionConfig"
	(clickButton)="clickCallToAction()"
></app-call-to-action>

<!-- Mappa -->
<section class="map">
	<div class="container">
		<div class="row">
			<div class="col-md-6 mat-elevation-z2 map__info">
				<h4 class="map__info__name">{{ organizationName }}</h4>
				<p class="map__info__address">{{ organizationAddress }}</p>
				<p class="map__info__opening">
					<span>{{ 'home_sections.schedule.opening_days' | translate | sentencecase }}</span>
					<span>{{ 'home_sections.schedule.closing_days' | translate | sentencecase }}</span>
				</p>
				<button mat-flat-button color="primary" (click)="clickCallToAction()">
					{{ 'contact.contact_us' | translate | sentencecase }}
				</button>
			</div>
		</div>
	</div>
	<div #mapContainer id="map-container"></div>
</section>
