import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { floatingMenuAnimations } from './floating-menu.animations';

// model
export interface FloatingMenuItems {
	icon?: string;
	color?: string;
	emitName?: string;
	tooltipLabel?: string;
}
export interface FloatingMenu extends FloatingMenuItems {
	// theme name
	color: string;
	tooltipLabel?: string;
	tooltipPosition?: string;
	icon: string;
	items: FloatingMenuItems[];
}

@Component({
	selector: 'floating-menu',
	templateUrl: './floating-menu.component.html',
	styleUrls: ['./floating-menu.component.scss'],
	animations: floatingMenuAnimations
})
export class FloatingMenuComponent implements OnInit {
	@Input() menuItems: FloatingMenu;
	@Input() expansionSide: string = 'top';
	@Output() actionEmitter: EventEmitter<any> = new EventEmitter<any>();
	subItemVisible = false;
	fabTogglerState = 'inactive';

	constructor() {}

	ngOnInit() {}

	itemClick(item: FloatingMenuItems, first?: boolean) {
		if (first) {
			if (this.fabTogglerState === 'active') {
				this.fabTogglerState = 'inactive';
			} else {
				this.fabTogglerState = 'active';
			}
			this.subItemVisible = !this.subItemVisible;
		} else {
			this.fabTogglerState = 'inactive';
			this.subItemVisible = false;
			this.actionEmitter.emit(item.emitName);
		}
	}
	// necessario importare nel progetto ClickOutsideDirective
	onClickOutside() {
		this.fabTogglerState = 'inactive';
		this.subItemVisible = false;
	}
}
