import { FormFieldItem } from '../../../model/util.model';
import { ConfigurationCustomerContextSelector } from '../../../model/structure/configuration-customer.model';

export const ConfigurationCustomerContextCodeCreationFormFieldMap = <ConfigurationCustomerContextSelector<FormFieldItem[]>> {
    BACKOFFICE: [
        {
            name: "description",
            type: "string",
            required: true,
            value: null,
        }
    ],
    B2C: [
        {
            name: "description",
            type: "string",
            required: true,
            value: null,
        }
    ],
    CRM: [
        {
            name: "description",
            type: "string",
            required: true,
            value: null,
        }
    ],
    B2B: [
        {
            name: "business_name",
            type: "string",
            required: true,
            value: null,
        }
    ],
    AGENT: [
        {
            name: "description",
            type: "string",
            required: true,
            value: null,
        },
        {
            name: "description_short",
            type: "string",
            required: true,
            value: null,
        }
    ]
};
