import { Injectable } from "@angular/core";
import { BaseApiService } from "./baseApi.service";
// model
import { CompanyMostActiveModel, CompanyListModel, EnumRole, CompanyModel } from "../../model/company.model";
import { Observable, BehaviorSubject } from "rxjs";
import { ResourceAction, ResourceRequestMethod, IResourceMethod } from '@ngx-resource/core';
import { Profile } from "selenium-webdriver/firefox/profile";

@Injectable()
export class CompanyService extends BaseApiService {

  @ResourceAction({
    path: '/users/{!id}/password/reset',
    method: ResourceRequestMethod.Post
  })
  resendEmailConfirmation: IResourceMethod<{id: number}, Profile>;

  // MOCKUP
  companies: CompanyListModel = {
    pagination: {
      page_current: 1,
      page_size: 10,
      total_element: 10
    },
    filters: {
      order_states: [""],
      company_states: [""]
    },
    data: [
      {
        id: "0",
        client_code: "0",
        business_name: "Company 0",
        address_favorite: {
          province: "MI",
          city: "Milano"
        },
        person_favorite: {
          id: "0",
          first_name: "Mario",
          last_name: "Rossi",
          role: EnumRole['MARKETING_MANAGER']
        },
        order_states_count: [
          {
            name: "DRAFT",
            number: 16
          },
          {
            name: "PROCESSING",
            number: 1
          },
          {
            name: "BLOCKED",
            number: 1
          }
        ],
        state: "ACTIVE"
      },
      {
        id: "1",
        client_code: "1",
        business_name: "Company 1",
        address_favorite: {
          province: "MI",
          city: "Milano"
        },
        person_favorite: {
          id: "0",
          first_name: "Mario",
          last_name: "Rossi",
          role: EnumRole['MARKETING_MANAGER']
        },
        order_states_count: [
          {
            name: "DRAFT",
            number: 2
          },
          {
            name: "PROCESSING",
            number: 1
          },
          {
            name: "BLOCKED",
            number: 1
          }
        ],
        state: "ACTIVE"
      }
    ]
  };
  companyMostActiveSemester: CompanyMostActiveModel[] = [
    {
      id: "1",
      business_name: "Company 1",
      revenue: 35000,
      revenue_trend_percentage: 23,
      contribution_on_total_percentage: 30,
    },
    {
      id: "2",
      business_name: "Company 2",
      revenue: 3000,
      revenue_trend_percentage: 20,
      contribution_on_total_percentage: 15,
    },
    {
      id: "3",
      business_name: "Company 3",
      revenue: 4000,
      revenue_trend_percentage: 21,
      contribution_on_total_percentage: 10,
    },
    {
      id: "4",
      business_name: "Company 4",
      revenue: 4000,
      revenue_trend_percentage: 21,
      contribution_on_total_percentage: 10,
    },
    {
      id: "5",
      business_name: "Company 5",
      revenue: 4000,
      revenue_trend_percentage: 21,
      contribution_on_total_percentage: 10,
    },
    {
      id: "6",
      business_name: "Company 6",
      revenue: 15000,
      revenue_trend_percentage: 5,
      contribution_on_total_percentage: 5,
    },
    {
      id: "7",
      business_name: "Company 7",
      revenue: 15000,
      revenue_trend_percentage: 8.2,
      contribution_on_total_percentage: 5,
    },
    {
      id: "8",
      business_name: "Company 8",
      revenue: 15000,
      revenue_trend_percentage: 3,
      contribution_on_total_percentage: 5,
    },
    {
      id: "9",
      business_name: "Company 9",
      revenue: 15000,
      revenue_trend_percentage: -2.3,
      contribution_on_total_percentage: 5,
    },
    {
      id: "10",
      business_name: "Company 10",
      revenue: 15000,
      revenue_trend_percentage: 1.7,
      contribution_on_total_percentage: 5,
    },
  ];
  companyStates: string[] = [
    "CANCELED",
    "SUSPENDED",
    "ACTIVE",
    "PROSPECT"
  ];
  company: CompanyModel = {
    id: "0",
    business_name: "Company 1",
    address_favorite: {
      province: "MI",
      city: "Milano"
    },
    person_favorite: {
      id: "0",
      first_name: "Mario",
      last_name: "Rossi",
      role: EnumRole['MARKETING_MANAGER']
    },
    order_states_count: [
      {
        name: "DRAFT",
        number: 16
      },
      {
        name: "PROCESSING",
        number: 1
      },
      {
        name: "BLOCKED",
        number: 1
      }
    ],
    state: "ACTIVE"
  };

  getCompany(c: CompanyModel): Observable<CompanyModel> {
    let company: CompanyModel;
    company = this.companies.data.filter(i => i.id == c.id)[0];
    return new BehaviorSubject<CompanyModel>(company);
  }


}
