import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService, UtilService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { ChartThemeEnum } from '../../../enum/chart-theme.enum';
import { MetricsDetail, StatisticsAgentBaseSerieOrder } from '../../../model/statistics-agent.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { chartTimeRange30Days } from '../../../constants/chart-time-range.constants';

@Injectable()
export class ActiveClientsChartConfigService extends BaseChartClass {
	metrics: MetricsDetail;

	constructor(
		public utilService: UtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		public translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route, translateService);
		this.cardDashboardConfigClientsActive.rangeTempList = null;
		this.chart.chartType = 'line';
		this.chart.options = this.optionsStyle(ChartThemeEnum.DEFAULT);
		this.chart.colors = [
			{
				borderColor: this.colorPrimary
			}
		];
	}

	// Functions

	createDataSerie() {
		const activeClients = this.statistics.active_client;

		const serieTemp: number[] = [];
		activeClients.forEach((element: StatisticsAgentBaseSerieOrder) => {
			serieTemp.push(element.value);
			this.fullLabels.push(element.date);
		});
		this.fullDataSets.push({
			label: 'number',
			data: serieTemp
		});
	}

	populateChart(rangeDays: number = chartTimeRange30Days.values[0]) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];

		this.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data.slice(-rangeDays);
			tempSerie.push(serie);
		});

		this.addData(this.chart.datasets, tempSerie);
		this.addData(this.chart.labels, this.fullLabels.slice(-rangeDays));

		this.getMetrics(rangeDays);
		this.chart = _.cloneDeep(this.chart);
	}

	getMetrics(rangeDays: number) {
		this.statistics$
			.pipe(
				filter(state => !!(state && state.data)),
				take(1)
			)
			.subscribe(res => {
				this.metrics = this.formatMetrics(res.data.metrics.active_client[`days_${rangeDays}`]);
			});
	}

	formatMetrics(data) {
		const metrics = {
			average: data.average < 1 ? +data.average.toFixed(2) : +data.average.toFixed(0),
			trend: data.trend.toFixed(0)
		};
		return metrics;
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function (tooltipItem, data) {
				let label = translateService.instant('client.' + data.datasets[tooltipItem.datasetIndex].label) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += tooltipItem.yLabel.toFixed(0);
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartThemeEnum) {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [8, 6],
					color: theme === ChartThemeEnum.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					// Valore minimo dell'asse
					// min: 0,
					// Scala da usare
					stepSize: 10,
					// Sets the offset of the tick labels from the axis
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartThemeEnum.DEFAULT ? this.colorBlack : this.colorWhite
					// callback: this.ticksCallback()
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: false,
				gridLines: {
					drawBorder: false,
					display: false
				},
				ticks: {
					display: false
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartThemeEnum) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 2
		};
	}
}
