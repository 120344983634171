import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core/data-table';
import { ViewTypeEnum } from '../../enum/view-type.enum';
import { OrderMockupService } from '../../service/mock/order.service';
import { AddressListColumnService } from '../../service/td-data-table/implementation/reserved-area/address-list.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { AddressPouchModel, BodyTablePouchModel, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DestinationListActionEnum } from '../../state/destination-list/destination-list.actions';
import { DEFAULT_DESTINATION_CODE } from '../../constants/destination.constants';
import { OrganizationActionEnum } from '../../state/organization/organization.actions';
import { DialogDestinationDetail } from '../dialog/dialog-destination-detail/dialog-destination-detail.component';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

export enum ListMode {
	show,
	pickable
}

@Component({
	selector: 'address-list',
	templateUrl: './address-list.component.html',
	styleUrls: ['./address-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class AddressListComponent implements OnInit {
	@Input() viewType = ViewTypeEnum.module;
	@Input() mode = ListMode.show;
	@Output() addressPicked = new EventEmitter<AddressPouchModel>();
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;

	// addressList: AddressPouchModel[] = this.orderMockupService.addressList;
	viewTypeEnum = ViewTypeEnum;
	ListMode = ListMode;
	columnsToShow: ITdDataTableColumn[];

	loginContextCode$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(
		StateFeature.getLoginContextCodeState
	);
	destinationList: DestinationPouchModel[];

	constructor(
		public orderMockupService: OrderMockupService,
		private dialog: MatDialog,
		public addressListColumnService: AddressListColumnService,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	ngOnInit() {
		this.columnsToShow = this.addressListColumnService.columns;
		if (this.mode == ListMode.pickable) {
			this.columnsToShow = this.addressListColumnService.columns.filter(c => c.name != 'action');
		}
	}

	initMandatoryData() {
		return this.loginContextCode$.pipe(
			filter(
				(loginContextCode: BaseStateModel<BodyTablePouchModel>) =>
					loginContextCode && loginContextCode.type !== OrganizationActionEnum.LOAD
			),
			map(loginContextCode => {
				if (loginContextCode.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				// TODO: rivedere quando modello sarà aggiornato
				this.destinationList = (loginContextCode.data as OrganizationStateModel).destination_list;
			})
		);
	}

	editAddress(addressData: AddressPouchModel) {
		console.log(addressData);
		const dialogRef = this.dialog.open(DialogDestinationDetail, {
			data: {
				modalTitle: 'Modifica indirizzo',
				addressData: _.cloneDeep(addressData)
			},
			disableClose: true,
			autoFocus: true
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				console.log(res);
				if (this.dataTable) {
					this.dataTable.refresh();
				}
			}
		});
	}

	rowClick(event: any) {
		this.addressPicked.emit(event.row);
	}
}
