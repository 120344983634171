import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-filter-simple',
	templateUrl: './filter-simple.component.html',
	styleUrls: ['./filter-simple.component.scss'],
	providers: [SubscribeManagerService]
})
export class FilterSimpleComponent implements OnInit, OnDestroy {
	@Output() changingFilters: EventEmitter<any> = new EventEmitter();
	form: FormGroup;
	subscription: Subscription;

	constructor(private fb: FormBuilder, private subscribeManagerService: SubscribeManagerService) {}

	orderByList = ['Nome', 'Prezzo'];
	categoryList = ['Categoria A', 'Categoria B', 'Categoria C'];

	ngOnInit() {
		this.createForm();
	}

	createForm() {
		this.form = this.fb.group({
			category: [''],
			order: [this.orderByList[0]]
		});

		// first emit
		this.changingFilters.emit(this.form.value);

		this.onChangeForm();
	}

	onChangeForm() {
		// TODO - if select empty value it emit twice
		this.subscribeManagerService.populate(
			this.form.valueChanges.subscribe(change => {
				this.changingFilters.emit(change);
			}),
			'on-change-form'
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
