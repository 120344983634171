import { ArticlePouchModel, OrderProductPouchModel, DiscountModel, BasePouchModel } from '@saep-ict/pouch_agent_models';
import { DiscountTypeEnum } from '@saep-ict/angular-core';
import { ArticleCategory } from '../article.model';
import { Category } from '../category-list.model';

export interface ArticleState extends ArticlePouchModel {
	productDetail?: OrderProductModel;
	calculate_price?: number;
	articlePrice?: ArticlePriceState;
	cf_code_group_transport?: string;
	categoryNodeList?: Category<ArticleCategory>[];
	articleKitList?: ArticleState[];
}

export interface OrderProductModel extends OrderProductPouchModel {
	articleDescription?: any;
	articleDetail?: ArticleState;
	articleDocumentLink?: [];
	articlePrice?: ArticlePriceState;
	client_ref?: string;
	custom_field?: OrderProductCustomFieldModel;
	delay_date?: number;
	delivery_date?: number;
	description?: string;
	ordered_quantity?: number;
	qty_shipped?: number;
	qty_box?: number;
}

export interface OrderProductCustomFieldModel {
	input_transport?: InputTransport;
}

export interface InputTransport {
	deposit_charge?: InputTransportItem;
	transport_charge?: InputTransportItem;
	carrier_pumping_charge?: InputTransportItem;
}

export interface InputTransportItem {
	default_charge?: number;
	agent_charge?: number;
}

export interface ArticlePriceState {
	price: number;
	discount?: DiscountModel[];
}

export class DiscountStateModel implements DiscountModel {
	value: number;
	type: DiscountTypeEnum;

	constructor(data: DiscountModel) {
		Object.keys(data).forEach(key => {
			this[key] = data[key];
		});
	}
}

export interface ArticleKitState extends BasePouchModel {
	date_updated?: string;
	code_company?: string;
	code?: string;
	valid?: boolean;
	components?: ArticleKitComponentsState[];
}

export interface ArticleKitComponentsState {
	code_component?: string;
	cancellation?: string;
	sequence?: string;
	date_begin_validity?: string;
	date_end_validity?: string;
	use_coefficient?: string;
	coeff_discount_goods?: string;
	coefficient_quantity_goods?: number;
	coefficient_discount_goods?: number;
	causal_document_row?: string;
	price_list?: string;
	causal_row_sc_goods?: string;
}

export interface ArticleRecap extends BasePouchModel {
	code_customer: string;
	article_list: ArticleRecapArticleList[];
}

export interface ArticleRecapArticleList extends ArticleState {
	divisions: ArticleRecapArticleDivision[];
}

export interface ArticleRecapArticleDivision {
	division: string;
	price: number;
	discounts: number[];
}
