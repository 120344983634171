<form (ngSubmit)="onFormSubmit()" [formGroup]="form">
	<div class="client_select-form">
		<mat-form-field *ngIf="multipleContext">
			<mat-label>{{ 'login.client_code_select.context_code' | translate | sentencecase }}</mat-label>
			<mat-select formControlName="context">
				<ng-container *ngFor="let permission of contextTypeList">
					<mat-option *ngIf="permission.type !== 0" [value]="permission">
						{{ permission.description }}
					</mat-option>
				</ng-container>
			</mat-select>
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ 'login.client_code_select.client_code' | translate | sentencecase }}</mat-label>
			<mat-select formControlName="linkCode">

				<mat-form-field class="filter-input">
					<mat-label>{{ 'login.client_code_select.filter_client_code' | translate | sentencecase }}</mat-label>
					<input
						matInput
						type="text"
						#inputSearchClientCode
						autocomplete="off"
					/>
					<button
						mat-button
						*ngIf="inputSearchClientCode.value"
						matSuffix
						mat-icon-button
						aria-label="Clear"
						(click)="inputSearchClientCode.value = ''"
					>
						<mat-icon>close</mat-icon>
					</button>
					<mat-icon matSuffix>search</mat-icon>
				</mat-form-field>

				<mat-option *ngFor="let linkCode of codeList | filterByString : { propertyListName: ['description', 'code'], value: inputSearchClientCode.value }" [value]="linkCode.code">
					{{ linkCode.code_erp }} - {{ linkCode.description }}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-toolbar>
			<button mat-raised-button [disabled]="!form.valid" [color]="'primary'">
				{{ 'general.confirm' | translate | sentencecase }}
			</button>
		</mat-toolbar>
	</div>
</form>
