<div class="align-items-center">
	<div class="wrapper_page">
		<mat-card>
			<div class="wrapper_header">
				<div class="header_spinotto">
					<img class="logo" src="../../../../../assets/img/logo/irinox/irinox-payoff-logo.svg" />
				</div>
				<div class="header_cliente">
					<div class="logo-container"></div>
				</div>
			</div>
			<mat-card-content>
				<!-- TODO : show error code in message -->
				<emoji-response
					[title]="'Activation'"
					[subtitle]="message"
					[message]=""
					[hasError]="hasError"
				></emoji-response>
			</mat-card-content>
		</mat-card>
	</div>
</div>
