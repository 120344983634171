import { BaseTableListService } from "@saep-ict/angular-core";
import { ITdDataTableColumn } from "@covalent/core/data-table";
import { Injectable } from "@angular/core";

const DECIMAL_FORMAT: (v: any) => any = (v: number) => v.toFixed(2).replace('.', ',');

@Injectable()
export class ProductCheckoutColumnService extends BaseTableListService {
  constructor() {
    super();
  }

  _columns = [
    {
      name: "codice",
      label: "Ref",
      width: 80
    },
    {
      name: "descrizione",
      label: "Descrizione"
    },
    {
      name: "article_price",
      label: "Prezzo",
      width: 120,
      numeric: true,
      format: DECIMAL_FORMAT
    },
    {
      name: "discount",
      label: "Sconto",
      width: 100,
      format: v => (v ? v.value : 0) + "%",
      numeric: true
    },
    {
      name: "productDetail.qty_free",
      label: "Articoli gratuiti",
      width: 100,
      numeric: true
    },
    {
      name: "last_price",
      label: "Prezzo finito",
      width: 100,
      numeric: true,
      hidden: true
    },
    {
      name: "qty",
      label: "Quantità",
      width: 100,
      numeric: true
    },
    {
      name: "disponibilita",
      label: "Disponiblità",
      width: 100
    },
    {
      name: "calculate_price",
      label: "Totale",
      width: 100,
      numeric: true,
      format: DECIMAL_FORMAT
    },
    {
      name: "note",
      label: "",
      width: 50,
      numeric: true
    }
  ];
}
