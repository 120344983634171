import { AuxiliaryTableStateModel } from "../../model/state/auxiliary-table-list";
import { BaseStateModel } from "@saep-ict/angular-core";
import { createReducer, on } from "@ngrx/store";
import { AuxiliaryTableActionEnum, AuxiliaryTableStateAction } from "./auxiliary-table.actions";
import { TypedAction } from "@ngrx/store/src/models";

export namespace AuxiliaryTableStateReducer {
	export const initialState: BaseStateModel<AuxiliaryTableStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(AuxiliaryTableStateAction.load, state => state),
		on(AuxiliaryTableStateAction.loadItem, state => state),
		on(AuxiliaryTableStateAction.update, (state, auxiliaryTable) => auxiliaryTable),
		on(AuxiliaryTableStateAction.updateItem, (state, param) => {
			state.data[param.stateSlice.type] = param.data[param.stateSlice.type];
			return JSON.parse(JSON.stringify(state));
		}),
		on(AuxiliaryTableStateAction.completed, (state, auxiliaryTable) => ({ data: state ? state.data : null, type: auxiliaryTable.type })),
		on(AuxiliaryTableStateAction.reset, () => initialState),
		on(AuxiliaryTableStateAction.resetItem, (state, param) => {
			delete state.data[param.stateSlice.type]
			return JSON.parse(JSON.stringify(state));
		}),
		on(AuxiliaryTableStateAction.error, (state, auxiliaryTable) => ({ data: state ? state.data : null, type: auxiliaryTable.type }))
	);

	export function reducer(state: BaseStateModel<AuxiliaryTableStateModel>, action: TypedAction<AuxiliaryTableActionEnum>) {
		return _reducer(state, action);
	}
}
