import {
	ContactPouchModel,
	OrganizationPouchModel,
	HeaderPouchModel,
	OrganizationTypeEnum
} from '@saep-ict/pouch_agent_models';

export interface ExtendedOrganizationPouchModel extends OrganizationPouchModel {
	code_item?: string;
	contact_details: ExtendedContactPouchModel;
	organization_type: OrganizationTypeEnum;
}

export interface ExtendedContactPouchModel extends ContactPouchModel {
	pec?: string;
}

export interface ExtendedHeaderPouchModel extends HeaderPouchModel<any> {
	shipping_charge: ShippingCharge;
	invoice_requested: boolean;
	shipping_methods_object?: any;
}

interface ShippingCharge {
	charge: number;
}

export const DEFAULT_CAUSAL_CODE = 'DEFAULT';
