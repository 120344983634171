import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';

@Component({
	selector: 'dialog-organization-detail',
	templateUrl: './dialog-organization-detail.component.html',
	styleUrls: ['./dialog-organization-detail.component.scss']
})
export class DialogOrganizationDetailComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { title: string },
		private dialogRef: MatDialogRef<DialogOrganizationDetailComponent>
	) {}

	ngOnInit() {}

	closeDialog() {
		this.dialogRef.close();
	}

	saveSuccess(organization: OrganizationStateModel) {
		this.dialogRef.close(organization);
	}
}
