import { NgModule } from "@angular/core";

// model
import { BackofficeUserListStateReducer } from "./backoffice-user-list.reducer";
// store
import { StoreModule } from "@ngrx/store";

@NgModule({
    imports: [
        StoreModule.forFeature('backoffice-user-list', BackofficeUserListStateReducer.reducer)
    ]
})
export class BackofficeUserListStateModule {}
