import {
	AddressPouchModel,
	BodyTablePouchModel,
	Company,
	DestinationNew,
	DestinationPouchModel,
	DiscountModel,
	HeaderPouchModel,
	OrderPouchModel,
	OrderProgressPouchModel,
	OrderStatusEnum,
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';

import { DiscrepancyOrderEnum } from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { OrderProductModel } from './article-list-state.model';
import { CarrierPumpingChargePouchModel } from './auxiliary-table-list';
import { ProductVariationTypeEnum } from '../../enum/product-variation.enum';
import { ExtendedOrganizationPouchModel } from '../../page/b2c/storefront/checkout/checkout-to-update.model';

export class OrderStateModel implements OrderPouchModel<ExtraFieldOrderHeaderPouchModel> {
	_id?: string;
	_rev?: string;
	csuite?: CSuiteStateModel;
	header?: HeaderStateModel = new HeaderStateModel();
	odv_code?: string;
	order_progress?: OrderProgressPouchModel<ExtraFieldOrderHeaderPouchModel>;
	order_variation_list?: ProductVariationStateModel[];
	product_list?: OrderProductModel[] = [];
	source?: string;
	type: string;
}

export class HeaderStateModel implements HeaderPouchModel<ExtraFieldOrderHeaderPouchModel> {
	order_id?: string;
	date?: number = Date.now();
	submission_date?: number;
	first_evasion_date?: number;
	code_agent: string;
	agent_detail?: BodyTablePouchModel;
	code_area_manager: string;
	division = '';
	order_number?: number;
	society_code?: string;
	order_causal?: string;
	client_line = '';
	client_code?: string;
	price_list_code?: string;
	payment_code?: string;
	payment_object?: any; // table ? payment ?
	goods_destination_code?: string;
	goods_destination_object?: any; // destination
	goods_destination_new?: DestinationNew;
	shipping_mode?: string;
	delivery_methods?: string;
	delivery_methods_object?: any; // delivery method ? table ?
	starting_warehouse?: string;
	starting_warehouse_object?: any; // starting_warehouse
	shipping_methods_object?: ShippingMethodsModel; // shipping method
	order_causal_object?: ItemDescriptionModel; // o any?
	price?: OrderPriceModel; // o any?
	note_order?: string;
	discount_agent?: DiscountModel;
	discount: DiscountModel[] = [];
	// TODO: DA DEPRECARE
	// company?: Company;
	organization?: ExtendedOrganizationPouchModel;
	status?: OrderStatusEnum = OrderStatusEnum.DRAFT;
	custom_field?: ExtraFieldOrderHeaderPouchModel;
	calculated_km?: number;
	order_progress_detail?: OrderProgressDetail;
	discrepancy_list?: DiscrepancyOrderEnum[];
	authorized?: Authorized;
	reject_reason?: RejectReason;
	mail_auth_sent?: number;
	bank_object?: BankObject;
}

export interface BankObject {
	description: string;
	iban: string;
}

export interface RejectReason {
	manager: string;
	message: string;
	date: number;
}

export interface ExtraFieldOrderHeaderPouchModel {
	support_type?: string;
	code_cig?: string;
	code_cup?: string;
	transport?: Transport;
	organization?: any;
}

export interface HeaderStateModelWithDestination extends HeaderStateModel {
	destination?: DestinationPouchModel;
}

export interface Transport {
	carrier_type?: CarrierType;
	carrier_pumping_charge?: CarrierPumpingChargePouchModel;
	kilometric_range?: string;
}

export interface CarrierType {
	code: string;
	description: string;
}

export interface OrderWithArticleDetailRequest {
	id?: string;
	orderData?: ArticleListFilterModel;
}

export interface OrderProgressDetail {
	first_evasion_date?: number;
	goods_destination?: DestinationPouchModel;
	starting_warehouse?: string;
	order_causal?: string;
}

export class ProductVariationStateModel {
	productCode: string;
	type: ProductVariationTypeEnum;
	propertyName?: string;
	oldValue?: string;
	newValue?: string;
}

export interface Authorized {
	manager: string;
	date: number;
}
export interface CSuiteStateModel {
	order_number: number;
	order_year: number;
	order_ext_number: string;
	order_ext_progress: string;
	order_so_id: string;
	order_so_number: number;
	order_so_year: number;
	order_so_progress: string;
	order_so_series: string;
	order_so_type_acquisition: string;
	order_so_client_ref: string;
	order_so_client_date: string;
}
export interface ShippingMethodsModel {
	code_item: string;
	description?: string;
	charge?: number;
	delivery_days?: number;
	threshold?: number;
}

export interface ItemDescriptionModel {
	code_item?: string;
	description?: string;
	description_short?: string;
}

export interface OrderPriceModel {
	total?: number;
	article?: number;
	shipping?: number;
	vat?: number;
}
