<div *ngIf="_articleList && _articleList.data && _articleList.data.length > 0; else noDataFound">
	<td-data-table
		#dataTable
		[data]="_articleList.data"
		[columns]="columns"
		(window:resize)="checkTableColumnData($event.target.innerWidth)"
	>
		<!-- available -->
		<ng-template tdDataTableTemplate="cf_available" let-row="row" let-value="value">
			<div class="chips-mini">
				<span
					[ngClass]="ArticleStock[row.stock.code_segnalation]"
					[matTooltip]="row.stock.description_segnalation"
				></span>
			</div>
		</ng-template>

		<!--  TODO - in base al modello dati corretto mostrare la disponibilità -->
		<!-- <ng-template tdDataTableTemplate="cf_available" let-row="row">
			<div class="chips-mini" [matTooltip]="row.stock.description_segnalation | titlecase">
				<span [ngClass]="ArticleStock[(row?.stock.code_segnalation)]"></span>
			</div>
		</ng-template> -->

		<!-- Code -->
		<ng-template tdDataTableTemplate="code" let-value="value" let-row="row">
			<p>
				<small [style.fontWeight]="isTablet ? 'bold' : 'normal'">{{ value }}</small>
				<br />
				<span *ngIf="isTablet" [matTooltip]="row.description">
					{{ row.description }} <br />
					<small>{{ row.description_add }}</small>
				</span>
			</p>
		</ng-template>

		<!-- Description -->
		<ng-template tdDataTableTemplate="description" let-row="row">
			<span [matTooltip]="row.description">
				{{ row.description }} <br />
				<small>{{ row.description_add }}</small>
			</span>

			<span class="product-kit" *ngIf="row.art_kit === 'S' && row.articleKitList && row.articleKitList.length">
				<button
					mat-icon-button
					aria-label="Icon to open dialog with kit products"
					(click)="utilArticleKitService.openDialogKit(row)"
					[matTooltip]="'article.kit.show' | translate | sentencecase"
				>
					<mat-icon>shopping_basket</mat-icon>
				</button>
			</span>
		</ng-template>
		<!-- article price -->
		<ng-template tdDataTableTemplate="articlePrice.price" let-value="value" let-row="row">
			<p>
				{{ value | currency: 'EUR':'symbol':'':'it' }}<br />
				<small class="cell-subtitle d-block" [innerHTML]="retrieveArticlePrice(row)"> </small>
				<small class="cell-subtitle" [innerHTML]="'cad.'"></small>
			</p>
		</ng-template>
		<!-- discount -->
		<ng-template tdDataTableTemplate="articlePrice.discount" let-value="value" let-row="row">
			<ng-container *ngIf="value && value.length > 0; else noDiscount">
				<ng-container *ngFor="let discount of value; let i = index">
					<span>{{ discount.value }}%</span> <span *ngIf="i !== value.length - 1"> + </span>
				</ng-container>
			</ng-container>
			<ng-template #noDiscount>
				<span>0%</span>
			</ng-template>
		</ng-template>
		<!-- Articoli gratuiti -->
		<ng-template tdDataTableTemplate="productDetail.qty_free" let-value="value" let-row="row">
			<p>
				<input
					appOnlyNumbers
					appMaxNumberInput
					[maxValue]="row.productDetail.input_quantity"
					placeholder="0"
					[value]="value ? value : null"
					(input)="+$event.data !== value ? updatedRowSelection($event, row, 'qty_free') : null"
					[disabled]="
						order.header.status != 'DRAFT' || row.free_sample === 'S' || !row.productDetail.input_quantity
					"
					stopPropagation
				/>
			</p>
		</ng-template>
		<!-- quantity -->
		<ng-template tdDataTableTemplate="productDetail.input_quantity" let-value="value" let-row="row">
			<p>
				<input
					appOnlyNumbers
					placeholder="0"
					[value]="value ? value : null"
					[disabled]="
						order.header.status !== OrderStatusEnum.DRAFT ||
						!order.header.order_causal ||
						(!(row.articlePrice && row.articlePrice.price) && row.free_sample !== 'S')
					"
					(input)="+$event.data !== value ? updatedRowSelection($event, row, 'qty') : null"
					stopPropagation
				/>
				<small class="cell-subtitle" [innerHTML]="'Box da ' + row.qty_box + ' art.'"></small>
			</p>
		</ng-template>

		<ng-template tdDataTableTemplate="calculate_price" let-value="value" let-row="row">
			<span> {{ (row.productDetail.input_quantity ? value : 0) | currency: 'EUR':'symbol':'':'it' }} </span>
		</ng-template>
	</td-data-table>
</div>
<ng-template #noDataFound>
	<p>{{ 'article.not_found' | translate | sentencecase }}</p>
</ng-template>
