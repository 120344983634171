<breadcrumb
	[title]="'offer.list' | translate | sentencecase"
	[pathList]="['Home']"
	*ngIf="!opportunity"
></breadcrumb>

<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<offer-list-wrapper
					[localListHandlerWidgetWrapperData]="listPageBaseData"
					[localListHandlerWidgetWrapperColumnList]="columns"
					[localListHandlerTextFilterShow]="true"
					[localListHandlerPaginatorShow]="true"
					(selectOfferId)="goToOfferDetail($event)"
					[localListHandlerTitle]="'offer.list' | translate | sentencecase"
				>
				</offer-list-wrapper>
			</mat-card>
		</div>
	</div>
</div>


<button
	class="mat-fab-button"
	(click)="createNew()"
	*ngIf="opportunity && !opportunity.close_date"
	mat-fab
	[matTooltip]="'offer.add_new' | translate | sentencecase"
	matTooltipPosition="left"
>
	<mat-icon>add</mat-icon>
</button>