import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { OrderStatusEnum, AgentCustomerListPouchModel } from '@saep-ict/pouch_agent_models';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { StateFeature } from '..';
import { LAST_CHARACTER } from '../../constants/pouchdb.constants';
import { OrderStateModel } from '../../model/state/order-state.model';
import { StatisticsOrders } from '../../model/statistics-orders.model';
import { StatisticsOrdersFilter } from '../../service/pouch-db/filter/statistics-orders-filter.model';
import { UtilPouchService } from '../../service/util/util-pouch.service';
import { StatisticsOrdersActionEnum, StatisticsOrdersStateAction } from './statistics-orders.action';

@Injectable()
export class StatisticsOrdersEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsOrdersActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>) =>
				from(this.getStatisticsOrders(action))
			),
			map((action: BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>) =>
				StatisticsOrdersStateAction.update(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsOrdersStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private utilPouchService: UtilPouchService) {}

	async getStatisticsOrders(
		action: BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>
	): Promise<BaseStateModel<StatisticsOrders, StatisticsOrdersFilter>> {
		const documentName = 'order';
		const allDocsParam: any = {
			include_docs: true,
			startkey: documentName + '_',
			endkey: documentName + '_' + LAST_CHARACTER
		};

		await this.utilPouchService
			.allDocs<OrderStateModel>(allDocsParam)
			.then(res => {
				// filtro solo quelli con code_agent corretto
				let allOrders = res.data.filter(order => {
					if (order.header && order.header.code_agent === action.dataSetting.appliedFilter.code_agent) {
						return order;
					}
				});

				// Creo delle sottoliste
				const ordersProcessing = this.filterOrderListByStatus(allOrders, OrderStatusEnum.PROCESSING);
				const ordersConsolidated = this.filterOrderListByStatus(allOrders, OrderStatusEnum.CONSOLIDATED);
				const ordersPartiallyFulfilled = this.filterOrderListByStatus(
					allOrders,
					OrderStatusEnum.PARTIALLY_FULFILLED
				);
				const ordersFulfilled = this.filterOrderListByStatus(allOrders, OrderStatusEnum.FULFILLED);
				const ordersDeleted = this.filterOrderListByStatus(allOrders, OrderStatusEnum.DELETED);
				const ordersDraft = this.filterOrderListByStatus(allOrders, OrderStatusEnum.DRAFT);
				const ordersToAuthorize = this.filterOrderListByStatus(allOrders, OrderStatusEnum.TO_AUTHORIZE);
				const ordersNotAuthorized = this.filterOrderListByStatus(allOrders, OrderStatusEnum.NOT_AUTHORIZED);
				const ordersReadyToSend = this.filterOrderListByStatus(allOrders, OrderStatusEnum.READY_TO_SEND);
				const ordersErrorPreparing = this.filterOrderListByStatus(allOrders, OrderStatusEnum.ERROR_PREPARING);
				const ordersSending = this.filterOrderListByStatus(allOrders, OrderStatusEnum.SENDING);
				const ordersErrorSending = this.filterOrderListByStatus(allOrders, OrderStatusEnum.ERROR_SENDING);

				// popolo l'oggetto
				action.data = {
					processing: { count: ordersProcessing.length },
					consolidated: { count: ordersConsolidated.length },
					partially_fulfilled: { count: ordersPartiallyFulfilled.length },
					fulfilled: { count: ordersFulfilled.length },
					deleted: { count: ordersDeleted.length },
					draft: { count: ordersDraft.length },
					to_authorize: { count: ordersToAuthorize.length },
					not_authorized: { count: ordersNotAuthorized.length },
					ready_to_send: { count: ordersReadyToSend.length },
					error_preparing: { count: ordersErrorPreparing.length },
					sending: { count: ordersSending.length },
					error_sending: { count: ordersErrorSending.length }
				};
			})
			.catch(err => console.log(err));

		return action;
	}

	filterOrderListByStatus(orderList: OrderStateModel[], status: OrderStatusEnum): OrderStateModel[] {
		return orderList.filter((order: OrderStateModel) => {
			if (order.header && order.header.status === status) {
				return order;
			}
		});
	}
}
