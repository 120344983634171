import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	ListWrapperComponent,
	MediaReplayService,
	SentencecasePipe,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { OpportunityStep } from '../../../../constants/opportunity-step.constants';
import {
	OpportunityPouchModel,
	OpportunityStatusCodeEnum,
	OpportunityTypeEnum
} from '../../../../model/state/opportunity-state.model';
import { ROUTE_URL } from '../../../../router/route-naming';
import { OpportunityListColumnService } from '../../../../service/td-data-table/implementation/opportunity-list.service';
import { UtilCompanyService } from '../../../../service/util/util-company.service';
import { UtilKanbanService } from '../../../../service/util/util-kanban.service';
import { StateFeature } from '../../../../state';
import { KanbanStateAction } from '../../../../state/kanban/kanban.actions';
import { OpportunityActionEnum, OpportunityStateAction } from '../../../../state/opportunity/opportunity.actions';
import { AvatarConfigClass } from '../../../../widget/avatar/avatar.component';
import { KanbanMoveEvent } from '../../../../widget/kanban/model/KanbanMoveEvent';
import { KanbanSortEvent } from '../../../../widget/kanban/model/KanbanSortEvent';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AddressPouchModel, AddressTypeEnum } from '@saep-ict/pouch_agent_models';
import { DEFAULT_DESTINATION_CODE } from '../../../../constants/destination.constants';

interface FilterModel {
	label: string;
	filter: string | boolean;
}
@Component({
	selector: 'opportunity-kanban',
	templateUrl: './opportunity-kanban.component.html',
	styleUrls: ['./opportunity-kanban.component.scss'],
	providers: [SubscribeManagerService]
})
export class OpportunityKanbanComponent implements OnInit, OnDestroy {
	@ViewChild('listWrapper', { static: false }) public listWrapper: ListWrapperComponent;
	@ViewChild('commentInput', { static: false }) public commentInput: MatInput;

	opportunityTypeEnum = OpportunityTypeEnum;
	opportunityTypes = [OpportunityTypeEnum.PROSPECT, OpportunityTypeEnum.CLIENT];
	opportunityStep = new OpportunityStep(); // ????????
	opportunitySelected: OpportunityPouchModel;
	opportunityList: OpportunityPouchModel[] = [];
	opportunity$: Observable<BaseStateModel<OpportunityPouchModel[]>> = this.store.select(
		StateFeature.getOpportunityState
	);

	// kanban$: Observable<BaseStateModel<KanbanPouchModel[]>> = this.store.select(StateFeature.getKanbanState);
	// kanban: KanbanPouchModel;
	kanbanColumns: Array<{ id: string; name: string; cardList: any }> = [];

	avatarConfigClass: AvatarConfigClass = null;
	sidebarScrollbarHeight: string;
	addCommentValue = '';
	defaultOrganizationAddress: AddressPouchModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	ROUTE_URL = ROUTE_URL;

	// Mock
	companyList = [];
	ownerList = [];

	filterAllElement: FilterModel = { label: 'Tutti', filter: '*' };
	ownerFilter: FilterModel = this.filterAllElement;
	companyFilter: FilterModel = this.filterAllElement;
	statusOpenFilter: FilterModel = { label: 'Solo opportunity aperte', filter: true };

	constructor(
		public mediaReplayService: MediaReplayService,
		public utilKanbanService: UtilKanbanService,
		public utilCompanyService: UtilCompanyService,
		private store: Store<any>,
		private snackBar: MatSnackBar,
		private translate: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		private router: Router,

		private subscribeManagerService: SubscribeManagerService,
		public opportunityColumnService: OpportunityListColumnService
	) {
		this.utilKanbanService.clearOpportunitySelection();

		//		this.subscribeManagerService.populate(this.subscribeKanbanList().subscribe(), 'subscribeKanbanList');
		this.subscribeManagerService.populate(this.subscribeOpportunityList().subscribe(), 'subscribeOpportunityList');

		this.store.dispatch(KanbanStateAction.loadAll());
		this.store.dispatch(OpportunityStateAction.loadAll());

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
	}

	ngOnInit() {
		this.updateHeightScrollbar(window.innerHeight);
		this.subscribeCardSelection();
	}

	ngOnDestroy() {
		this.store.dispatch(OpportunityStateAction.reset());
		this.subscribeManagerService.destroy();
	}

	/*
	subscribeKanbanList() {
		return this.kanban$.pipe(
			// mergeMap(params => {
			// 	this.kanbanType = params.get('kanbanType')
			// 		? params.get('kanbanType').toLowerCase()
			// 		: null;
			// 	return this.kanban$;
			// }),
			filter((state: BaseStateModel<KanbanPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<KanbanPouchModel[]>) => {
				switch (state.type) {
					case KanbanActionEnum.UPDATE:
						this.kanban = (state && state.data.find(x => x.type.includes('kanban_opportunity'))) || null;
						if (this.kanban == null) {
							this.store.dispatch(KanbanStateAction.save(new BaseState([this.opportunityStep.buildDefaultKanban()])));
						}
						break;

					case KanbanActionEnum.ERROR:
						throw new Error(KanbanActionEnum.ERROR);

					default:
						break;
				}
				return state;
			})
		);
	}
	*/

	subscribeOpportunityList() {
		return this.opportunity$.pipe(
			//			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(this.kanban) && !!(state && state.data)),
			filter((state: BaseStateModel<OpportunityPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OpportunityPouchModel[]>) => {
				switch (state.type) {
					case OpportunityActionEnum.UPDATE:
						this.applyPermanentFilters(state.data);
						break;

					case OpportunityActionEnum.ERROR:
						throw new Error(OpportunityActionEnum.ERROR);

					default:
						break;
				}
				return state;
			})
		);
	}

	applyPermanentFilters(opportunityModelList: OpportunityPouchModel[]) {
		// TODO - Introdurre filtri se servono
		switch (this.user.current_permission.context_application) {
			case ContextApplicationItemCodeEnum.CRM: {
				// CRM filter by type
				const filteredList = opportunityModelList.filter(opportunity =>
					this.opportunityTypes.find(ot => opportunity.type.includes(ot))
				);
				if (this.opportunityList && this.opportunityList.length > 0) {
					this.opportunityList =
						this.opportunityList.map(obj => filteredList.find(o => o._id === obj._id) || obj) ||
						filteredList;
				} else {
					this.opportunityList = filteredList;
					this.fillFilters(this.opportunityList);
				}
				// console.log(this.opportunityList);
				// console.log(filteredList);
				// console.log(opportunityModelList);
				break;
			}
			case ContextApplicationItemCodeEnum.AGENT:
			case ContextApplicationItemCodeEnum.B2B:
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				break;
		}
		this.buildKanbanModel();
	}

	private fillFilters(data: OpportunityPouchModel[]) {
		this.companyList = [this.filterAllElement].concat(
			_.uniqBy(
				data.map(x => {
					return <FilterModel>{ label: x.organization.business_name, filter: x.organization.business_name };
				}),
				'filter'
			)
		);

		this.ownerList = [this.filterAllElement, <FilterModel>{ label: 'Non assegnato', filter: undefined }].concat(
			_.uniqBy(
				data
					.filter(x => x.assegnee_user_name)
					.map(x => {
						return <FilterModel>{ label: x.assegnee_user_name, filter: x.assegnee_user_name };
					}),
				'filter'
			)
		);
	}

	private buildKanbanModel() {
		const filteredList = this.applyDynamicFilters();
		this.kanbanColumns = [];
		filteredList.forEach(o => {
			if (!o.step_id || !this.opportunityStep.findById(o.step_id)) {
				o.step_id = this.opportunityStep.firstStep.id;
				o.step_name = this.opportunityStep.firstStep.name;
			}
		});
		this.opportunityStep.stepList.forEach(step => {
			this.kanbanColumns.push({
				id: step.id,
				name: step.name,
				cardList: _.sortBy(
					filteredList.filter(item => item.step_id === step.id),
					x => x.order_into_step || this.STEP_GAP
				)
			});
		});
		// console.log(this.kanbanColumns);
		// console.log(this.opportunityStep.stepList);
	}

	private STEP_GAP = 1000;

	onCardSorted($event: KanbanSortEvent) {
		//		console.log($event);

		const opportunity: OpportunityPouchModel = $event.column[$event.currentIndex];
		const beforeCardOrder =
			$event.currentIndex > 0
				? (<OpportunityPouchModel>$event.column[$event.currentIndex - 1]).order_into_step
				: 0;
		const afterCardOrder =
			$event.currentIndex + 1 < $event.column.length
				? (<OpportunityPouchModel>$event.column[$event.currentIndex + 1]).order_into_step
				: 0;
		const opportunityStep = this.opportunityStep.findById($event.columnId);
		opportunity.step_id = opportunityStep.id;
		opportunity.step_name = opportunityStep.name;
		opportunity.order_into_step =
			afterCardOrder && afterCardOrder
				? beforeCardOrder + (afterCardOrder - beforeCardOrder) / 2
				: beforeCardOrder + this.STEP_GAP;
		this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
	}

	onCardMoved($event: KanbanMoveEvent) {
		//		console.log($event);

		const opportunity: OpportunityPouchModel = $event.currentColumn[$event.currentIndex];
		const beforeCardOrder =
			$event.currentIndex > 0
				? (<OpportunityPouchModel>$event.currentColumn[$event.currentIndex - 1]).order_into_step
				: null;
		const afterCardOrder =
			$event.currentIndex + 1 < $event.currentColumn.length
				? (<OpportunityPouchModel>$event.currentColumn[$event.currentIndex + 1]).order_into_step
				: null;
		const opportunityStep = this.opportunityStep.findById($event.currentColumnId);
		opportunity.step_id = opportunityStep.id;
		opportunity.step_name = opportunityStep.name;
		opportunity.order_into_step =
			afterCardOrder && afterCardOrder
				? beforeCardOrder + (afterCardOrder - beforeCardOrder) / 2
				: beforeCardOrder + this.STEP_GAP;
		this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
	}

	subscribeCardSelection() {
		this.utilKanbanService.opportunitySelected.subscribe(opportunitySelected => {
			if (opportunitySelected) {
				this.opportunitySelected = opportunitySelected;
				this.avatarConfigClass = {
					urlImage: this.opportunitySelected.assegnee_avatar
						? this.opportunitySelected.assegnee_avatar
						: null,
					userName: this.opportunitySelected.assegnee_full_name
						? this.opportunitySelected.assegnee_full_name
						: this.opportunitySelected.assegnee_user_name,
					isRounded: true
				};
				// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
				// this.defaultOrganizationAddress = opportunitySelected.organization.destination_list.find(destination => destination.address_type === AddressTypeEnum.REGISTERED_OFFICE).address;
				this.defaultOrganizationAddress = opportunitySelected.organization.destination_list.find(
					destination => destination.code === DEFAULT_DESTINATION_CODE
				).address;
				this.listWrapper.sidenav.open();
			}
		});
	}

	private applyDynamicFilters() {
		let filterdList = this.opportunityList;
		if (this.ownerFilter.filter !== '*') {
			filterdList = filterdList.filter(obj => obj.assegnee_user_name === this.ownerFilter.filter);
		}
		if (this.companyFilter.filter !== '*') {
			filterdList = filterdList.filter(obj => obj.organization.business_name === this.companyFilter.filter);
		}
		if (this.statusOpenFilter.filter !== false) {
			filterdList = filterdList.filter(obj => obj.status_code === OpportunityStatusCodeEnum.Open);
		}
		return filterdList;
	}

	changeCompany(e: MatSelectChange) {
		// console.log(e);
		this.companyFilter = e.value;
		this.buildKanbanModel();
	}

	changeOwner(e: MatSelectChange) {
		// console.log(e);
		this.ownerFilter = e.value;
		this.buildKanbanModel();
	}

	changeOpenOpportunity(e: MatCheckboxChange) {
		this.statusOpenFilter.filter = e.checked;
		this.buildKanbanModel();
	}

	changeStep(e: MatSelectChange) {
		console.log(e.value);
		const opportunity = this.opportunitySelected;
		const currentColumnId = e.value;
		const opportunityStep = this.opportunityStep.findById(currentColumnId);
		opportunity.step_id = opportunityStep.id;
		opportunity.step_name = opportunityStep.name;
		this.store.dispatch(OpportunityStateAction.save(new BaseState([opportunity])));
	}

	openOpportunityDetail(opportunity: OpportunityPouchModel) {
		// console.log(ROUTE_URL.private, 'opportunity', opportunity.type, opportunity._id);
		this.router.navigate([
			ROUTE_URL.private,
			'opportunity',
			opportunity.type.replace('opportunity_', ''),
			opportunity._id
		]);
	}

	addComment() {
		this.addCommentValue = '';
		// TODO - persistere il valore del commento
		const msg = this.sentencecasePipe.transform(this.translate.instant('comment.added'));
		this.snackBar.open(msg, '', { duration: 3000 });
	}

	updateHeightScrollbar(height: number) {
		this.sidebarScrollbarHeight = height - 370 + 'px';
	}
}
