import { createAction, props } from '@ngrx/store';
import { ArticleRecap, ArticleState } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';

export enum ArticleDescriptionActionEnum {
	LOAD_DESCRIPTION_FROM_RECAP = '[article list] Load description from recap',
	UPDATE = '[article list] Update',
	RESET = '[article list] Reset',
	ERROR = '[article list] Error'
}

export namespace ArticleDescriptionStateAction {
	export const loadDescriptionFromRecap = createAction(ArticleDescriptionActionEnum.LOAD_DESCRIPTION_FROM_RECAP);
	export const update = createAction(ArticleDescriptionActionEnum.UPDATE, props<BaseStateModel<ArticleRecap>>());
	export const reset = createAction(ArticleDescriptionActionEnum.RESET);
	export const error = createAction(ArticleDescriptionActionEnum.ERROR, props<BaseStateModel<any>>());
}
