import { createAction, props } from '@ngrx/store';
import { ArticleState } from '../../model/state/article-list-state.model';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ArticleListFilterModel } from '../../service/pouch-db/filter/article-list-filter.model';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';

export enum ArticleListActionEnum {
	UPDATE = '[article list] Update',
	LOAD = '[article list] Load',
	LOAD_FROM_RECAP = '[article list] Load from recap',
	LOAD_DESCRIPTION_FROM_RECAP = '[article list] Load description from recap',
	LOAD_ALL = '[article list] Load all',
	LOAD_WITH_DETAIL = '[article list] Load with detail',
	LOAD_FROM_CATEGORY = '[article list] Load from category',
	LOAD_LIST_FROM_CODE = '[article list] Load list from code',
	RESET = '[article list] Reset',
	ERROR = '[article list] Error'
}

export namespace ArticleListStateAction {
	export const load = createAction(
		ArticleListActionEnum.LOAD,
		props<BaseStateModel<ArticleState[], ArticleListFilterModel>>()
	);
	export const loadFromRecap = createAction(
		ArticleListActionEnum.LOAD_FROM_RECAP,
		props<BaseStateModel<ArticleState[], ArticleListFilterModel>>()
	);
	export const loadDescriptionFromRecap = createAction(
		ArticleListActionEnum.LOAD_DESCRIPTION_FROM_RECAP,
		props<BaseStateModel<any>>()
	);
	export const loadAll = createAction(ArticleListActionEnum.LOAD_ALL, props<{ forceDefault: boolean }>());
	export const loadWithDetail = createAction(
		ArticleListActionEnum.LOAD_WITH_DETAIL,
		props<BaseStateModel<ArticleState[], ArticleListFilterModel>>()
	);
	export const loadFromCategory = createAction(
		ArticleListActionEnum.LOAD_FROM_CATEGORY,
		props<BaseStateModel<ArticleState[], ArticleListFilterModel>>()
	);
	export const loadListFromCode = createAction(
		ArticleListActionEnum.LOAD_LIST_FROM_CODE,
		props<BaseStateModel<ArticleState[], ArticleListFilterModel>>()
	);
	export const update = createAction(
		ArticleListActionEnum.UPDATE,
		props<BaseStateModel<ArticleState[], ArticleListFilterModel>>()
	);
	export const reset = createAction(ArticleListActionEnum.RESET);
	export const error = createAction(ArticleListActionEnum.ERROR, props<BaseStateModel<any>>());
}
