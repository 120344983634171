import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

import { TypeCodeTransportCustomFieldHeaderOrderEnum } from '../../../../../enum/typecode-transport-customfield-header-order.enum';
import {
	CausalHeaderOrderEnum,
	DeliveryMethodHeaderOrderEnum,
	UmSellingArticleOrder,
	SentencecasePipe,
	BaseState,
	BaseStateModel,
	UserDetailModel,
	SubscribeManagerService
} from '@saep-ict/angular-core';
import {
	ArticleChangeContextModel,
	ArticleChangeContextResponseModel,
	OrderRowModel
} from '../../../../../model/order-util.model';
import { OrderProductModel } from '../../../../../model/state/article-list-state.model';
import { AuxiliaryTableStateModel } from '../../../../../model/state/auxiliary-table-list';
import { OrderStateModel, OrderWithArticleDetailRequest } from '../../../../../model/state/order-state.model';
import { ArticleListFilterModel } from '../../../../../service/pouch-db/filter/article-list-filter.model';
import { UtilOrderService } from '../../../../../service/util/util-order.service';
import { StateFeature } from '../../../../../state';
import { AuxiliaryTableActionEnum } from '../../../../../state/auxiliary-table/auxiliary-table.actions';
import { OrganizationActionEnum } from '../../../../../state/organization/organization.actions';
import { OrderActionEnum, OrderStateAction } from '../../../../../state/order/order.actions';
import { MatSnackBarWrapperComponent } from '../../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { Category } from '../../../../../model/category-list.model';
import { ArticleCategory, ArticleCheckoutTree } from '../../../../../model/article.model';
import { UtilCategoryListService } from '../../../../../service/util/util-category-list.service';
import { CategoryListActionEnum } from '../../../../../state/category-list/category-list.actions';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationStateModel } from '../../../../../model/state/organization-state.model';
import { AgentStateModel } from '../../../../../model/state/agent-state.model';
import { UtilPriceService } from '../../../../../service/util/util-price.service';

@Component({
	selector: 'app-order-detail-checkout',
	templateUrl: './order-detail-checkout.component.html',
	styleUrls: ['./order-detail-checkout.component.scss'],
	providers: [SubscribeManagerService]
})
export class OrderDetailCheckoutComponent implements OnInit, OnDestroy {
	// entity
	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	order$: Observable<BaseStateModel<OrderStateModel>> = this.store.select(StateFeature.getOrderState);
	order: OrderStateModel;

	auxiliaryTable$: Observable<BaseStateModel<AuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getAuxiliaryTableState
	);
	auxiliaryTable: AuxiliaryTableStateModel;

	articleListFilter = new BaseState<any, ArticleListFilterModel>(null, {
		appliedFilter: {
			article: {}
		}
	});

	categoryList$: Observable<BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);

	articleCheckoutTree: ArticleCheckoutTree[] = [];

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	agent$: Observable<BaseStateModel<AgentStateModel>> = this.store.select(StateFeature.getAgentState);
	agent: AgentStateModel;
	agentIsManager: boolean;

	// enum
	orderStatusEnum = OrderStatusEnum;
	typeCodeTransportCustomFieldHeaderOrderEnum = TypeCodeTransportCustomFieldHeaderOrderEnum;
	umSellingArticleOrder = UmSellingArticleOrder;
	deliveryMethodHeaderOrderEnum = DeliveryMethodHeaderOrderEnum;
	causalHeaderOrderEnum = CausalHeaderOrderEnum;

	// widget & utility

	totalOrderCount;

	discrepancyList = ['Modalità di pagamento', 'Modalità di consegna'];

	formHeaderValidity: boolean;

	constructor(
		private router: Router,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public utilOrderService: UtilOrderService,
		private snackBar: MatSnackBar,
		public utilCategoryListService: UtilCategoryListService,
		private sentencecasePipe: SentencecasePipe,
		private translate: TranslateService,
		private utilPriceService: UtilPriceService
	) {
		// Agent data recovery
		this.agent$.pipe(take(1)).subscribe(res => {
			this.agent = res ? res.data : null;
		});
		// subscribe
		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
					this.router.navigate(['/not-found']);
				}
			),
			'order-data'
		);

		this.subscribeManagerService.populate(
			this.utilOrderService.currentFormHeaderValidity.subscribe(formHeaderValidity => {
				this.formHeaderValidity = formHeaderValidity;
			}),
			'currentFormHeaderValidity'
		);
	}

	ngOnInit() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		this.agentIsManager = this.agent ? this.checkAgentIsManager() : false;
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	// subscribe
	initMandatoryData() {
		return this.organization$.pipe(
			filter(
				(organization: BaseStateModel<OrganizationStateModel>) =>
					organization && organization.type !== OrganizationActionEnum.LOAD
			),
			mergeMap((organization: BaseStateModel<OrganizationStateModel>) => {
				if (organization.type === OrganizationActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (organization.data) {
					this.organization = organization.data;
					this.articleListFilter.dataSetting.appliedFilter.organization = this.organization;
					return this.order$;
				}
			}),
			filter(
				(order: BaseStateModel<OrderStateModel>) =>
					this.organization &&
					order &&
					order.type !== OrderActionEnum.LOAD &&
					order.type !== OrderActionEnum.SAVE
			),
			mergeMap((order: BaseStateModel<OrderStateModel>) => {
				if (order.type === OrderActionEnum.ERROR) {
					throw new Error(OrderActionEnum.ERROR);
				}
				if (order.type === OrderActionEnum.UPDATE) {
					this.order = order.data;
					if (this.order.product_list.length) {
						this.order.product_list.map(x =>
							this.utilPriceService.mapArticlePrice(x, this.order.header.division)
						);
					}
					this.orderTotalCount();
				}
				return this.categoryList$;
			}),
			filter(
				(categoryListState: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) =>
					categoryListState && categoryListState.type !== CategoryListActionEnum.LOAD_RECURSIVELY
			),
			mergeMap((categoryListState: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) => {
				if (categoryListState.type === CategoryListActionEnum.ERROR) {
					throw new Error(OrganizationActionEnum.ERROR);
				}
				if (categoryListState.type === CategoryListActionEnum.NOT_EXISTING) {
					this.articleCheckoutTree = [];
					// per category-list state NOT_EXISTING viene creato un solo branch
					const articleListWithoutCategory = <ArticleCheckoutTree>{};
					articleListWithoutCategory.article_list = this.order.product_list;
					this.articleCheckoutTree.push(articleListWithoutCategory);
				} else {
					this.utilOrderService
						.returnArticleCheckoutTree(_.cloneDeep(this.order), categoryListState.data)
						.then(res => {
							this.articleCheckoutTree = res;
						})
						.catch(err => {
							console.log(err);
						});
				}
				return this.auxiliaryTable$;
			}),
			filter(
				(state: BaseStateModel<AuxiliaryTableStateModel>) =>
					this.organization && state.type !== AuxiliaryTableActionEnum.LOAD
			),
			map((state: BaseStateModel<AuxiliaryTableStateModel>) => {
				if (state.type === AuxiliaryTableActionEnum.UPDATE || state.type === AuxiliaryTableActionEnum.UPDATE) {
					this.auxiliaryTable = state.data;
				}
			})
		);
	}

	// widget & utility
	// order
	sendOrder() {
		this.utilOrderService.propagationSubmitFormPopulate();
		if (this.formHeaderValidity) {
			if (
				!this.agentIsManager &&
				this.order.header.discrepancy_list &&
				this.order.header.discrepancy_list.length
			) {
				this.order.header.status = OrderStatusEnum.TO_AUTHORIZE;
			} else {
				this.order.header.status = OrderStatusEnum.READY_TO_SEND;
			}
			this.order.header.submission_date = new Date().getTime();
			this.order.header.bank_object = {
				description: this.organization.division_list
					? this.organization.division_list[0].banks_descriptions[0]
					: '',
				iban: this.organization.iban
			};
			const requestOrderDetail: OrderWithArticleDetailRequest = {
				orderData: { order: this.order, organization: this.organization, article: {} }
			};
			this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
		} else {
			const orderProblemHeader = this.sentencecasePipe.transform(this.translate.instant('order.problem.header'));
			this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
				duration: 5000,
				data: {
					message: orderProblemHeader
				}
			});
		}
	}

	// article - input change
	rowChange(data: OrderRowModel) {
		const fallback = data.typeNumber ? '0' : '';
		let inputValue = data.event.target.value ? data.event.target.value : fallback;
		inputValue =
			typeof inputValue === 'string' && inputValue !== '' ? parseFloat(inputValue.replace(',', '.')) : inputValue;
		const request: ArticleChangeContextModel = {
			value: inputValue,
			article: data.row,
			order: this.order,
			auxiliaryTable: this.auxiliaryTable
		};
		const changeMethodList = {
			discount_agent: { method: () => this.utilOrderService.changeDiscountAgent(request) },
			qty: { method: () => this.utilOrderService.changeQuantity(request) },
			last_price: { method: () => this.utilOrderService.changeLastPrice(request) },
			delete: { method: () => this.utilOrderService.changeQuantity(request) },
			note_order: { method: () => this.utilOrderService.changeNote(request) },
			qty_free: { method: () => this.utilOrderService.changeQuantityFree(request) },
			delay_date: { method: () => this.utilOrderService.changeDelayDate(request) }
		};
		const contextResponse: ArticleChangeContextResponseModel = changeMethodList[data.key].method();
		if (contextResponse) {
			this.order = contextResponse.order;
			const requestOrderDetail: OrderWithArticleDetailRequest = {
				orderData: { order: _.cloneDeep(this.order), organization: this.organization, article: {} }
			};
			this.store.dispatch(OrderStateAction.save(new BaseState(requestOrderDetail)));
		}
	}

	orderTotalCount() {
		this.totalOrderCount = {
			total: { label: 'Totale', value: 0, main: true }
		};
		this.order.product_list = this.order.product_list.filter(product => product.articleDetail);
		this.order.product_list.forEach((article, index) => {
			const cloneArticle: OrderProductModel = Object.assign({}, article);
			const articleState = this.utilOrderService.mapCurrentArticle(cloneArticle);
			article.articleDetail.calculate_price = this.utilOrderService.retrieveTotalArticlePrice(articleState);
			this.totalOrderCount.total.value += this.utilOrderService.articleTotalCount(article);
		});
		return this.totalOrderCount;
	}

	retrieveTotalCountKey() {
		return Object.keys(this.totalOrderCount);
	}

	checkAgentIsManager() {
		// Check if the agent is also area manager
		let context_manager = this.user.context_application_list.find(context_app => context_app.code == 1);
		if (context_manager) {
			let agent_manager = context_manager.context_code_list.find(
				context_code => context_code.code == this.agent.code_area_manager
			);
			return agent_manager ? true : false;
		}
		return false;
	}
}
