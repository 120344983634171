<app-page-header [title]="'Pagamento'" [breadcrumbsList]="['Home', 'Carrello', 'Pagamento']"> </app-page-header>

<form [formGroup]="checkoutForm" #formDirective="ngForm" (ngSubmit)="onSubmit()">
	<div class="container">
		<div class="row formCardContent">
			<section *ngIf="operationState() == PaymentState.AUTH" class="buttonLink col-12">
				<div class="buttonLink__content">
					<div class="row buttonLink__aside">
						<div class="col-md-12 col-lg-6">
							<button class="buttonLink__button" mat-raised-button color="primary">
								{{ 'checkout.buy' | translate | sentencecase }}
							</button>
						</div>
						<div class="col-md-12 col-lg-6">
							<p class="buttonLink__containerText">
								<a [routerLink]="[ROUTE_URL.public, ROUTE_URL.cart]">{{
									'checkout.to_cart' | translate | sentencecase
								}}</a>
							</p>
						</div>
					</div>
					<mat-divider></mat-divider>
				</div>
			</section>
			<!-- sezione di scelta opzioni di acquisto -->
			<section class="form col-md-12 col-lg-6">
				<div *ngIf="operationState() == PaymentState.COMPLETED" class="row">
					<div class="col-12">
						<operation-state-banner [state]="operationResult"></operation-state-banner>
					</div>
				</div>
				<div *ngIf="operationState() == PaymentState.AUTH" class="container-form">
					<div class="row">
						<div class="list col-12">
							<div class="list__content">
								<h2 class="list__text">
									1. {{ 'checkout.field.personal_data' | translate | sentencecase }}
								</h2>
							</div>
						</div>
						<div class="col-12">
							<ng-container
								*ngIf="
									loginContextCode &&
									loginContextCode.organization_type == OrganizationTypeEnum.PRIVATE
								"
							>
								<div>
									<b>{{ loginContextCode.business_name }}</b>
								</div>
								<div>
									{{ 'company.field.tax_code' | translate | sentencecase }}:
									{{ loginContextCode.tax_data ? loginContextCode.tax_data.tax_code : '-' }}
								</div>
								<div>
									{{ 'contact.field.email' | translate | sentencecase }}:
									{{ loginContextCode.contact_details.email || '-' }}
								</div>
								<div>
									{{ 'contact.field.phone' | translate | sentencecase }}:
									{{ loginContextCode.contact_details.phone || '-' }}
								</div>
								<div class="content-checkbox">
									<!-- (change)="toShow($event)" -->
									<mat-checkbox #checkbox formControlName="invoiceRequested">
										{{ 'checkout.invoice_request' | translate | sentencecase }}
									</mat-checkbox>
								</div>
								<ng-container *ngIf="checkbox.checked">
									<div>
										<b>{{ 'checkout.field.billing_information' | translate | sentencecase }}</b>
									</div>
									<div>
										{{ 'company.field.vat_number' | translate | sentencecase }}:
										{{ loginContextCode.tax_data ? loginContextCode.tax_data.vat_number : '-' }}
									</div>
									<div>
										{{ 'company.field.legal_headquarter' | translate | sentencecase }}:
										{{ getFormattedAddress() }}
									</div>
									<div>
										{{ 'company.field.pec' | translate | sentencecase }}:
										{{ loginContextCode.contact_details.pec || '-' }}
									</div>
									<div>
										{{ 'general.code' | translate | sentencecase }}
										{{ 'company.field.sdi' | translate }}:
										{{ loginContextCode.tax_data ? loginContextCode.tax_data.sdi_code : '-' }}
									</div>
									<alert *ngIf="showWarning()" [type]="AlertType.warning">
										<p>
											{{ 'checkout.missing_fields' | translate | sentencecase }}:
											{{ showWarning() }}
										</p>
									</alert>
								</ng-container>
							</ng-container>
							<ng-container
								*ngIf="
									loginContextCode &&
									loginContextCode.organization_type == OrganizationTypeEnum.COMPANY
								"
								class="col-12"
							>
								<div>
									<b>{{ loginContextCode.business_name }}</b>
								</div>
								<div>
									{{ 'company.field.vat_number' | translate | sentencecase }}:
									{{ loginContextCode.tax_data ? loginContextCode.tax_data.vat_number : '-' }}
								</div>
								<div>
									{{ 'company.field.legal_headquarter' | translate | sentencecase }}:
									{{ getFormattedAddress() }}
								</div>
								<div>
									{{ 'company.field.pec' | translate | sentencecase }}:
									{{ loginContextCode.contact_details.pec || '-' }}
								</div>
								<div>
									{{ 'general.code' | translate | sentencecase }}
									{{ 'company.field.sdi' | translate }}:
									{{ loginContextCode.tax_data ? loginContextCode.tax_data.sdi_code : '-' }}
								</div>
								<div>
									{{ 'company.field.reference_internal.name' | translate | sentencecase }}:
									{{ loginContextCode.reference_internal || '-' }}
								</div>
								<div>
									{{ 'company.field.reference_internal.email' | translate | sentencecase }}:
									{{ loginContextCode.contact_details.email || '-' }}
								</div>
								<div>
									{{ 'company.field.reference_internal.phone' | translate | sentencecase }}:
									{{ loginContextCode.contact_details.phone || '-' }}
								</div>
								<alert *ngIf="showWarning()" [type]="AlertType.warning">
									<p>
										{{ 'checkout.missing_fields' | translate | sentencecase }}: {{ showWarning() }}
									</p>
								</alert>
							</ng-container>
							<div class="personal-area">
								{{ 'checkout.incorrect_data' | translate | sentencecase }}
								<a [routerLink]="[PATH_URL.MY_ACCOUNT]">{{ 'general.personal_area' | translate }}</a>
							</div>
						</div>

						<div class="list col-12">
							<div class="list__content">
								<h2 class="list__text">
									2. {{ 'checkout.field.destination' | translate | sentencecase }}
								</h2>
							</div>
						</div>
						<div class="col-12">
							<p>{{ 'checkout.select_address' | translate | sentencecase }}</p>
						</div>
						<div class="col-12">
							<div class="selected-address">
								<div>{{ selectedAddress.address }}</div>
								<div>
									{{ selectedAddress.zip_code }} - {{ selectedAddress.locality }} ({{
										selectedAddress.province
									}})
								</div>
								<div>{{ selectedAddress.country }}</div>
							</div>
						</div>
						<div class="list col-12">
							<a type="button" class="address-button" mat-stroked-button (click)="pickAddress()">
								{{ 'general.address.change' | translate | sentencecase }}
							</a>
							<a type="button" class="address-button" mat-stroked-button (click)="addAddress()">
								{{ 'general.address.add_new' | translate | sentencecase }}
							</a>
						</div>

						<div class="col-12">
							<div class="list">
								<div class="list__content">
									<h2 class="list__text">
										3. {{ 'checkout.field.shipping_method' | translate | sentencecase }}
									</h2>
								</div>
							</div>
							<section
								*ngIf="
									loginContextCode &&
									loginContextCode.organization_type == OrganizationTypeEnum.PRIVATE
								"
								class="radio-section"
							>
								<mat-radio-group formControlName="shippingMethod">
									<mat-radio-button
										*ngFor="let method of shippingMethods"
										class="example-margin radio-section__content"
										[value]="method.code_item"
										[disabled]="checkDisabledShippingMethod(method)"
									>
										<span [innerHTML]="getShippingLabel(method)"></span>
										<small class="shippingThresholdLabel" *ngIf="!isSmartphone">
											{{
												('checkout.shipping_threshold' | translate | sentencecase) +
													' ' +
													(method.threshold | currency: 'EUR':'symbol':'':'it')
											}}
										</small>
									</mat-radio-button>
								</mat-radio-group>
							</section>
							<section
								*ngIf="
									loginContextCode &&
									loginContextCode.organization_type == OrganizationTypeEnum.COMPANY
								"
							>
								<mat-form-field class="container-form__matForm">
									<mat-select formControlName="shippingMethod">
										<mat-option
											*ngFor="let method of shippingMethods"
											[value]="method.code_item"
											[disabled]="checkDisabledShippingMethod(method)"
										>
											<span [innerHTML]="getShippingLabel(method)"></span>
											<small class="shippingThresholdLabel" *ngIf="!isSmartphone">
												{{
													('checkout.shipping_threshold' | translate | sentencecase) +
														' ' +
														(method.threshold | currency: 'EUR':'symbol':'':'it')
												}}
											</small>
										</mat-option>
									</mat-select>
								</mat-form-field>
							</section>

							<div class="list">
								<div class="list__content">
									<h2 class="list__text">
										4. {{ 'checkout.field.payment_method' | translate | sentencecase }}
									</h2>
								</div>
							</div>
							<section
								*ngIf="
									loginContextCode &&
									loginContextCode.organization_type == OrganizationTypeEnum.PRIVATE
								"
								class="radio-section"
							>
								<mat-radio-group formControlName="paymentMethod">
									<mat-radio-button
										*ngFor="let method of paymentMethods"
										class="radio-section__content"
										[value]="method.code_item"
									>
										{{ method.description_short ? method.description_short : method.description }}
										<div *ngIf="method.code_item == '500'" class="radio-section__contentSvg">
											<!-- <img
												src="../../../assets/img/metodi-pagamento/cc-mastercard.svg"
												alt="metodo di pagamento mastercard"
												title="metodo di pagamento mastercard"
												class="radio-section__colorSvg"
											/> -->
											<img
												src="../../../assets/img/metodi-pagamento/cc-paypal.svg"
												alt="metodo di pagamento paypal"
												title="metodo di pagamento paypal"
												class="radio-section__colorSvg"
											/>
											<!-- <img
												src="../../../assets/img/metodi-pagamento/cc-visa.svg"
												alt="metodo di pagamento visa"
												title="metodo di pagamento visa"
												class="radio-section__colorSvg"
											/> -->
										</div>
									</mat-radio-button>
								</mat-radio-group>
							</section>
							<section
								*ngIf="
									loginContextCode &&
									loginContextCode.organization_type == OrganizationTypeEnum.COMPANY
								"
							>
								<mat-form-field class="container-form__matForm">
									<mat-select formControlName="paymentMethod">
										<mat-option *ngFor="let method of paymentMethods" [value]="method.code_item">
											{{
												method.description_short ? method.description_short : method.description
											}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</section>

							<div class="list">
								<div class="list__content">
									<h2 class="list__text">
										5. {{ 'checkout.field.notes' | translate | sentencecase }}
									</h2>
								</div>
								<mat-form-field>
									<mat-label>{{ 'checkout.add_note' | translate | sentencecase }}</mat-label>
									<textarea formControlName="additionalNotes" matInput></textarea>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="operationState() == PaymentState.NOT_AUTH" class="container-form">
					<div class="row">
						<div class="list col-12">
							<div class="list__content">
								<h2 class="list__text">{{ 'checkout.login' | translate | sentencecase }}</h2>
							</div>
							<b2c-login-form></b2c-login-form>
						</div>
						<div class="list col-12">
							<div class="list__content">
								<h2 class="list__text">{{ 'login.general.new_user?' | translate | sentencecase }}</h2>
							</div>
							<a
								type="button"
								class="login__help__button"
								mat-stroked-button
								[routerLink]="[ROUTE_URL.public, ROUTE_URL.createAccount]"
							>
								{{ 'login.general.new_account' | translate | sentencecase }}
							</a>
						</div>
					</div>
				</div>
			</section>
			<section class="cards-section col-lg-6">
				<div class="row">
					<div class="cards-section__container col-12">
						<app-mirror-costs
							[productsList]="order.product_list"
							[shippingFees]="getShippingPrice()"
						></app-mirror-costs>
					</div>

					<!-- Data di consegna -->
					<div class="cards-section__container col-12">
						<div class="card-orderDetails">
							<h3>
								<b>{{ 'order.field.delivery_date' | translate | sentencecase }}</b>
							</h3>
							{{ 'checkout.delivery_date_info' | translate | sentencecase }}
							<b>{{ order.header.first_evasion_date | date: 'dd/MM/yyyy' }}</b>
						</div>
					</div>

					<div class="cards-section__container col-12">
						<div class="card-information">
							<div class="card-information__aside">
								<div class="row">
									<div class="col-md-4 col-lg-12">
										<div class="card-information__content">
											<span class="card-information__icon material-icons"> security </span>
											<div>
												<h3 class="card-information__textIcon">
													<b>{{ 'general.security' | translate | sentencecase }}</b>
												</h3>
											</div>
										</div>
										<p>
											{{ 'checkout.descriptions.security.start' | translate | sentencecase }}
											<a href="javascript:void(0)" (click)="openPrivacyPolicy()">{{
												'informative_page.type.privacy_policy' | translate
											}}</a>
											{{ 'checkout.descriptions.security.end' | translate }}
										</p>
									</div>
									<div class="col-md-4 col-lg-12">
										<div class="card-information__content">
											<span class="card-information__icon material-icons"> local_shipping </span>
											<h3 class="card-information__textIcon">
												<b>{{ 'general.shipping.name_plural' | translate | sentencecase }}</b>
											</h3>
										</div>
										<p>
											{{ 'checkout.descriptions.shipping' | translate | sentencecase }}
											<a href="javascript:void(0)" (click)="openTermsAndConditions()">{{
												'informative_page.type.shipping_terms' | translate
											}}</a>
										</p>
									</div>
									<div class="col-md-4 col-lg-12">
										<div class="card-information__content">
											<span class="card-information__icon material-icons rotate">
												import_export
											</span>
											<h3 class="card-information__textIcon">
												<b>{{ 'checkout.return_products' | translate | sentencecase }}</b>
											</h3>
										</div>
										<p>
											{{
												'checkout.descriptions.return_products.start' | translate | sentencecase
											}}
											<a href="mailto:info@saep-ict.it">{{ saepIctReferences.SAEP_ICT_EMAIL }}</a>
											{{ 'checkout.descriptions.return_products.end' | translate }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section *ngIf="operationState() == PaymentState.AUTH" class="buttonLink col-12">
				<div class="buttonLink__content">
					<mat-divider></mat-divider>
					<div class="row buttonLink__aside">
						<div class="col-md-12 col-lg-6">
							<button class="buttonLink__button" mat-raised-button color="primary">
								{{ 'checkout.buy' | translate | sentencecase }}
							</button>
						</div>
						<div class="col-md-12 col-lg-6">
							<p class="buttonLink__containerText">
								<a [routerLink]="[ROUTE_URL.public, ROUTE_URL.cart]">
									{{ 'checkout.to_cart' | translate | sentencecase }}
								</a>
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</form>
