<div class="align-items-center">
	<div class="wrapper_page">
		<mat-card>
			<div class="wrapper_header">
				<div class="header_spinotto">
					<img class="logo" src="../../../../assets/img/logo/irinox/irinox-payoff-logo.svg" />
				</div>
				<div class="header_cliente">
					<div class="logo-container"></div>
				</div>
			</div>
			<mat-card-content>
				<emoji-response
					[title]="'404'"
					[subtitle]="'not_found.message_short' | translate | sentencecase"
					[message]="'not_found.message_long' | translate | sentencecase"
					[hasError]="true"
				></emoji-response>
			</mat-card-content>
		</mat-card>
	</div>
</div>
