import {
	ContextApplicationItemCodeEnum,
	ContextTypeAndApplicationLink,
	ContextCodeItemLink
} from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';
import { AgentListStateAction } from '../../../state/backoffice/context-code/agent-list/agent-list.actions';
import { B2cUserListStateAction } from '../../../state/backoffice/context-code/b2c-user-list/b2c-user-list.actions';
import { BackofficeUserListStateAction } from '../../../state/backoffice/context-code/backoffice-user-list/backoffice-user-list.actions';
import { CrmUserListStateAction } from '../../../state/backoffice/context-code/crm-user-list/crm-user-list.actions';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import { OrderListStateAction } from '../../../state/order-list/order-list.actions';

// B2B
export const contextCodeItemCompany: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.B2B,
	description: 'client code',
	couch_document_key: 'organization',
	action: {
		loadList: OrganizationListStateAction.loadAll,
		loadItem: OrganizationListStateAction.loadItem,
		createItem: OrganizationListStateAction.createItem,
		deleteItem: OrganizationListStateAction.deleteItem
	},
	association_type: [],
	already_associated_context_code_list: []
};

// AGENT
export const contextCodeItemAgent: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.AGENT,
	description: 'agent code',
	couch_document_key: 'agent',
	action: {
		loadList: AgentListStateAction.loadList,
		loadItem: AgentListStateAction.loadItem,
		createItem: AgentListStateAction.createItem,
		deleteItem: AgentListStateAction.deleteItem,

		loadListWithDetail: OrderListStateAction.loadWithDetail,
		reset: OrderListStateAction.reset
	},
	state: StateFeature.getOrderListState,
	association_type: [contextCodeItemCompany],
	already_associated_context_code_list: []
};

// BACKOFFICE
export const contextCodeItemBackoffice: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.BACKOFFICE,
	description: 'backoffice user code',
	couch_document_key: 'backoffice',
	action: {
		loadList: BackofficeUserListStateAction.loadList,
		loadItem: BackofficeUserListStateAction.loadItem,
		createItem: BackofficeUserListStateAction.createItem,
		deleteItem: BackofficeUserListStateAction.deleteItem
	},
	association_type: [
		contextCodeItemCompany
		// contextCodeItemAgent
	],
	already_associated_context_code_list: []
};

// B2C
export const contextCodeItemB2C: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.B2C,
	description: 'B2C user code',
	couch_document_key: 'organization',
	action: {
		loadList: OrganizationListStateAction.loadAll,
		loadItem: OrganizationListStateAction.loadItem,
		createItem: OrganizationListStateAction.createItem,
		deleteItem: OrganizationListStateAction.deleteItem
	},
	association_type: [],
	already_associated_context_code_list: []
};

// CRM
export const contextCodeItemCRM: ContextCodeItemLink = {
	code: ContextApplicationItemCodeEnum.CRM,
	description: 'CRM user code',
	couch_document_key: 'crm',
	action: {
		loadList: CrmUserListStateAction.loadList,
		loadItem: CrmUserListStateAction.loadItem,
		createItem: CrmUserListStateAction.createItem,
		deleteItem: CrmUserListStateAction.deleteItem
	},
	association_type: [],
	already_associated_context_code_list: []
};

export const contextTypeAndApplicationLink: ContextTypeAndApplicationLink[] = [
	{
		context_application_item_code: ContextApplicationItemCodeEnum.BACKOFFICE,
		context_code_item: contextCodeItemBackoffice,
		application_name: 'Backoffice',
		icon: 'admin_panel_settings'
	},
	{
		context_application_item_code: ContextApplicationItemCodeEnum.B2B,
		context_code_item: contextCodeItemCompany,
		application_name: 'B2B',
		icon: 'shopping_bag'
	}
	// {
	// 	context_application_item_code: ContextApplicationItemCodeEnum.AGENT,
	// 	context_code_item: contextCodeItemAgent,
	// 	application_name: 'Agent App',
	// 	icon: 'work'
	// },
	// {
	// 	context_application_item_code: ContextApplicationItemCodeEnum.B2C,
	// 	context_code_item: contextCodeItemB2C,
	// 	application_name: 'B2C',
	// 	icon: 'add_shopping_cart'
	// },
	// {
	// 	context_application_item_code: ContextApplicationItemCodeEnum.CRM,
	// 	context_code_item: contextCodeItemCRM,
	// 	application_name: 'CRM',
	// 	icon: 'filter_alt'
	// }
];
