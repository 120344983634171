import { createAction, props } from '@ngrx/store';
import { BaseStateModel, RestBasePk } from '@saep-ict/angular-core';
import { OpportunityPouchModel } from '../../model/state/opportunity-state.model';

export enum OpportunityActionEnum {
	UPDATE = '[opportunity] Update',
	LOAD = '[opportunity] Load',
	LOAD_ALL = '[opportunity] Load all',
	SAVE = '[opportunity] Save',
	REMOVE = '[opportunity] Remove',
	REMOVED = '[opportunity] Removed',
	RESET = '[opportunity] Reset',
	ERROR = '[opportunity] Error'
}

export namespace OpportunityStateAction {
	export const load = createAction(OpportunityActionEnum.LOAD, props<BaseStateModel<RestBasePk>>());
	export const loadAll = createAction(OpportunityActionEnum.LOAD_ALL);
	export const update = createAction(OpportunityActionEnum.UPDATE, props<BaseStateModel<OpportunityPouchModel[]>>());
	export const save = createAction(OpportunityActionEnum.SAVE, props<BaseStateModel<OpportunityPouchModel[]>>());
	export const remove = createAction(OpportunityActionEnum.REMOVE, props<BaseStateModel<OpportunityPouchModel[]>>());
	export const removed = createAction(OpportunityActionEnum.REMOVED);
	export const reset = createAction(OpportunityActionEnum.RESET);
	export const error = createAction(OpportunityActionEnum.ERROR, props<BaseStateModel<any>>());
}
