<section class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p class="page-header__breadcrumbs" *ngIf="breadcrumbsList.length">
          <span *ngFor="let breadcrumbs of breadcrumbsList; let i = index">
            <span *ngIf="i"> / </span>
            {{ breadcrumbs | sentencecase }}
          </span>
          </p>
        <h1 class="page-header__title">{{ title }}</h1>
      </div>
    </div>
  </div>
</section>
