import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProbeTestService } from '../../../service/rest/probe-test.service';

@Component({
	selector: 'probe-test',
	templateUrl: './probe-test.component.html',
	styleUrls: ['./probe-test.component.scss']
})
export class ProbeTestComponent implements OnInit {

	constructor(
		private route: ActivatedRoute,
		private probeTestService: ProbeTestService
	) {}

	ngOnInit() {
		this.route.queryParamMap.subscribe(params => {
			if (params.get('crash')) {
				throw new Error('CRASH');
			}
		});
		this.probeTestService.getProbeTest()
		.then(res => {
			console.log(res);
		})
		.catch(err => {
			console.log(err);
			throw new Error('CRASH');
		});
	}
}
