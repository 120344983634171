import { StoreModule } from '@ngrx/store';
import { NgModule } from "@angular/core";
import { ContactStateReducer } from './contact.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature('contact', ContactStateReducer.reducer)
    ]
})
export class ContactStateModule { }
