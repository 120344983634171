export namespace ConfigurationOrderContextApplicationColumnMap {
	// Organization
	export const exludeForOrganization: string[] = ['header.organization.business_name'];
	export const exludeForNonOrganization: string[] = [];

	// Context
	export const excludeForB2b: string[] = exludeForOrganization.concat([
		'header.date',
		'header.first_evasion_date',
		'iva'
	]);
	export const excludeForBackoffice: string[] = exludeForNonOrganization.concat([
		'header.date',
		'header.first_evasion_date',
		'iva'
	]);
}
