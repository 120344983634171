<app-page-header
	[title]="(informativePage ? informativePage.title : 'informative_page.not_found') | translate | sentencecase"
	[breadcrumbsList]="[
		'Home',
		(informativePage ? informativePage.title : 'informative_page.not_found') | translate | sentencecase
	]"
></app-page-header>

<div class="container">
	<div class="row">
		<div class="col-12 mt-32 mb-32">
			<h1 *ngIf="!informativePage">{{ 'informative_page.not_found' | translate | sentencecase }}</h1>
			<div *ngIf="informativePage" [innerHtml]="informativePage.body"></div>
		</div>
	</div>
</div>
