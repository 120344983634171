import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ChartjsModel } from '@saep-ict/angular-core';

@Component({
	selector: 'chart-wrapper',
	templateUrl: './chart-wrapper.component.html',
	styleUrls: ['./chart-wrapper.component.scss']
})
export class ChartWrapperComponent implements OnInit, OnDestroy {

	@Input('config') set config(r: ChartjsModel) {
		if (r) {
			this._config = <ChartjsModel>{};
			// detectChanges() fa scattare l'ngIf che distrugge il canvas per
			// poi ricrearlo con i dati aggiornati
			this.cdr.detectChanges();
			this._config = r;
		}
	}

	_config: ChartjsModel;

	constructor(private cdr: ChangeDetectorRef,) {}

	ngOnInit() {}

	ngOnDestroy() {}

	public chartClicked(e: any): void {
		// console.log(e);
	}
	public chartHovered(e: any): void {
		// console.log(e);
	}
}
