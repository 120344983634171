<mat-toolbar *ngIf="localListHandlerTextFilterShow">
	<mat-toolbar-row>
		{{ localListHandlerTitle }}
		<span class="badge" [class.ml-3]="localListHandlerTitle">
			{{ localListHandlerData?.data?.length || 0 }}
		</span>
		<span class="flex-span"></span>
		<td-search-box
			*ngIf="localListHandlerData && localListHandlerData.filters && localListHandlerData.filters.localSearchText"
			backIcon="search"
			[(ngModel)]="localListHandlerData.filters.localSearchText.value"
			[showUnderline]="true"
			[debounce]="500"
			[alwaysVisible]="false"
			(searchDebounce)="localListHandlerApplyFilter()"
		>
		</td-search-box>
		<button
			*ngIf="localListHandlerSidebarFilterShow"
			mat-icon-button
			(click)="localListHandlerOpenSidebarFilter.emit()"
		>
			<mat-icon>filter_list</mat-icon>
		</button>
	</mat-toolbar-row>
</mat-toolbar>

<ng-container
	*ngIf="
		localListHandlerData && localListHandlerData.dataSubset && localListHandlerData.dataSubset.length > 0;
		else noData
	"
>
	<td-data-table
		#dataTable
		[data]="localListHandlerData.dataSubset"
		[columns]="_localListHandlerWidgetWrapperColumnList"
		[sortable]="true"
		(sortChange)="localListHandlerApplyFilter({ sort: $event })"
		[sortBy]="localListHandlerData.sort && localListHandlerData.sort.name ? localListHandlerData.sort.name : false"
		[sortOrder]="
			localListHandlerData.sort && localListHandlerData.sort.order ? localListHandlerData.sort.order : false
		"
	>
		<!-- Email confermata -->
		<ng-template *ngIf="companyContact.send_email_confirmation" tdDataTableTemplate="status" let-value="value">
			<div class="chips-mini" [matTooltip]="value | sentencecase">
				<span
					[ngClass]="{
						ACTIVE: value === 'confermato',
						SEND: value === 'validazione in corso',
						BLOCKED: value === 'email non confermata'
					}"
				></span>
			</div>
		</ng-template>

		<!-- full_name -->
		<ng-template tdDataTableTemplate="full_name" let-value="value" let-row="row">
			<p>
				<a href="javascript:void(0)" (click)="openDialogContactDetail.emit(row)">
					{{ value | sentencecase }}
				</a>
				<br />
				<small class="cell-subtitle">{{ row.role ? row.role : '' }}</small>
			</p>
		</ng-template>

		<!-- contact_details -->
		<ng-template tdDataTableTemplate="contact_details" let-row="row">
			<p>
				{{ row && row.email ? row.email : '' }}
				<br />
				<small class="cell-subtitle">
					{{ row && row.phone ? row.phone : '' }}
				</small>
			</p>
		</ng-template>

		<!-- organization_details -->
		<ng-template tdDataTableTemplate="organization_details" let-value="value" let-row="row">
			<p>
				<ng-container *ngIf="value">
					<a href="javascript:void(0)" (click)="selectCompany.emit({ level: value.level, code: value.id })">
						{{ value.business_name | sentencecase }}
					</a>
					<br />
					<small class="cell-subtitle">
						{{ 'company.field.vat_number_short' | translate | uppercase }}
						{{ value.vat_number }}
					</small>
				</ng-container>
			</p>
		</ng-template>

		<!-- order_confirm_email -->
		<ng-template
			*ngIf="companyContact.can_activate_email_order_receipt"
			tdDataTableTemplate="order_confirm_email"
			let-value="value"
			let-row="row"
		>
			<mat-slide-toggle
				[checked]="row.has_notification"
				(change)="toggleHasNotification.emit(row)"
				[disabled]="disabledOrderConfirmEmail"
			>
				{{ 'contact.status.' + (row.has_notification ? 'active' : 'unactive') | translate | sentencecase }}
			</mat-slide-toggle>
		</ng-template>

		<!-- Actions -->
		<ng-template tdDataTableTemplate="actions" let-value="value" let-row="row">
			<!-- <button
				mat-icon-button
				aria-label="edit contact"
				[matTooltip]="'contact.edit' | translate | sentencecase"
				matTooltipPosition="left"
				(click)="openDialogEditContact.emit(row)"
			>
				<mat-icon>edit</mat-icon>
			</button> -->
			<button
				mat-icon-button
				aria-label="remove contact"
				[matTooltip]="'contact.remove' | translate | sentencecase"
				matTooltipPosition="left"
				(click)="openDialogConfirmRemoveContact.emit(row)"
			>
				<mat-icon>clear</mat-icon>
			</button>
		</ng-template>
	</td-data-table>

	<mat-paginator
		*ngIf="localListHandlerPaginatorShow"
		#matPaginator
		(page)="localListHandlerApplyFilter({ pagination: $event })"
		[length]="localListHandlerData.pagination.totalFilteredItemCount"
		[pageSize]="localListHandlerData.pagination.pageSize"
		[pageSizeOptions]="[5, 10, 25, 100]"
	>
	</mat-paginator>
</ng-container>
<loading-results-spinner *ngIf="isLoading"></loading-results-spinner>
<ng-template #noData>
	<p class="placeholder" *ngIf="!isLoading">
		{{ 'general.no_results' | translate | sentencecase }}
	</p>
</ng-template>
