<div formGroupName="details">
	<div class="row">
		<!-- Status Maturazione -->
		<div class="col-lg-12" *ngIf="isPrivate || isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.status' | translate | sentencecase }}</mat-label>
				<mat-select formControlName="status">
					<mat-option *ngFor="let level of organizationLevelList" [value]="level">
						{{ level | sentencecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<!-- Data primo contatto -->
		<div class="col-lg-6" *ngIf="isPrivate || isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.date_first_contact' | translate | sentencecase }}</mat-label>
				<input matInput [matDatepicker]="picker" formControlName="dateFirstContact" />
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker></mat-datepicker>
			</mat-form-field>
		</div>

		<!-- Settore organizzazione -->
		<div class="col-lg-6" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.organization_sector' | translate | sentencecase }}</mat-label>
				<input matInput formControlName="organizationSector" />
			</mat-form-field>
		</div>

		<!-- Fatturato annuo -->
		<div class="col-lg-6" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.revenue_year' | translate | sentencecase }}</mat-label>
				<input matInput formControlName="revenueYear" />
				<span matSuffix><mat-icon [inline]="true">euro_symbol</mat-icon></span>
			</mat-form-field>
		</div>

		<!-- Numero dipendenti -->
		<div class="col-lg-6" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.employees_number' | translate | sentencecase }}</mat-label>
				<input matInput formControlName="employeesNumber" />
			</mat-form-field>
		</div>

		<!-- Voto affidabilità (internazionale) -->
		<div class="col-lg-6" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>{{
					'organization.field.organization_international_rating' | translate | sentencecase
				}}</mat-label>
				<mat-select formControlName="organizationInternationalRating">
					<mat-option *ngFor="let score of internationalTrustScoreList" [value]="score">
						{{ score | uppercase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<!-- Voto affidabilità (0 - 100) -->
		<div class="col-lg-6" *ngIf="isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.organization_rating' | translate | sentencecase }}</mat-label>
				<input matInput type="number" min="0" max="100" formControlName="organizationRating" />
				<mat-error>
					<form-error-message
						ngDefaultControl
						[formControl]="parentForm.get('details').get('organizationRating')"
					></form-error-message>
				</mat-error>
			</mat-form-field>
		</div>

		<!-- URL sito web -->
		<div class="col-lg-6" *ngIf="isPrivate || isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.website_url' | translate | sentencecase }}</mat-label>
				<input matInput formControlName="websiteUrl" />
			</mat-form-field>
		</div>

		<!-- URL LinkedIn -->
		<div class="col-lg-6" *ngIf="isPrivate || isCompany">
			<mat-form-field>
				<mat-label>{{ 'organization.field.linkedin_url' | translate | sentencecase }}</mat-label>
				<input matInput formControlName="linkedInUrl" />
			</mat-form-field>
		</div>
	</div>
</div>
