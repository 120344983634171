import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { OpportunityPouchModel } from './opportunity-state.model';

export interface OfferOtherDescriptionModel {
	object?: string;
	summary?: string;
	references?: string;
	premise?: string;
}

export interface OfferStateModel extends BasePouchModel {
	title: string;
	date_end_validity?: number;
	payment_method?: string;
	other_descriptions?: OfferOtherDescriptionModel;
	line_list: string[];
	opportunity: OpportunityPouchModel;
	status: OfferStatusEnum;
}

export enum OfferStatusEnum {
	DRAFT = 'DRAFT',
	SENT = 'SENT'
}
