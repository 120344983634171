<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<mat-card>
				<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
					<div class="row">
						<!-- Titolo -->
						<div class="col-md-12">
							<mat-form-field>
								<mat-label>{{ 'general.title' | translate | sentencecase }}</mat-label>
								<input matInput type="text" formControlName="title" />
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('title')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- date_end_validity -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>
									{{ 'offer.field.date_end_validity' | translate | sentencecase }}
								</mat-label>
								<input
									matInput
									readonly
									formControlName="date_end_validity"
									[matDatepicker]="DateEndValidityPicker"
									(click)="DateEndValidityPicker.open()"
								/>
								<mat-datepicker-toggle matSuffix [for]="DateEndValidityPicker"></mat-datepicker-toggle>
								<mat-datepicker #DateEndValidityPicker></mat-datepicker>
								<mat-error>
									<form-error-message
										ngDefaultControl
										[formControl]="form.get('date_end_validity')"
									></form-error-message>
								</mat-error>
							</mat-form-field>
						</div>

						<!-- payment_method -->
						<div class="col-md-6">
							<mat-form-field>
								<mat-label>{{ 'offer.field.payment_method' | translate | sentencecase }}</mat-label>
								<mat-select formControlName="payment_method">
									<mat-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod">
										{{ paymentMethod }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>

						<!-- other_descriptions -->
						<div class="col-md-12">
							<mat-checkbox formControlName="other_descriptions">
								{{ 'offer.field.other_descriptions' | translate | sentencecase }}
							</mat-checkbox>
						</div>

						<ng-container *ngIf="form.get('other_descriptions').value">
							<!-- object -->
							<div class="col-md-12">
								<mat-form-field>
									<mat-label>{{ 'offer.field.object' | translate | sentencecase }}</mat-label>
									<input matInput type="text" formControlName="object" />
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('object')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- summary -->
							<div class="col-md-12">
								<mat-form-field>
									<mat-label>{{ 'offer.field.summary' | translate | sentencecase }}</mat-label>
									<input matInput type="text" formControlName="summary" />
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('summary')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- references -->
							<div class="col-md-12">
								<mat-form-field>
									<mat-label>{{ 'offer.field.references' | translate | sentencecase }}</mat-label>
									<input matInput type="text" formControlName="references" />
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('references')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>

							<!-- premise -->
							<div class="col-md-12">
								<mat-form-field class="example-full-width">
									<mat-label>{{ 'offer.field.premise' | translate | sentencecase }}</mat-label>
									<textarea matInput formControlName="premise"></textarea>
									<mat-error>
										<form-error-message
											ngDefaultControl
											[formControl]="form.get('premise')"
										></form-error-message>
									</mat-error>
								</mat-form-field>
							</div>
						</ng-container>

						<div class="col-md-12">
							<!-- Da controllare -->
							<mat-toolbar>
								<!-- Salva -->
								<button mat-raised-button color="primary" type="submit" class="mr-3">
									{{ 'general.save' | translate | sentencecase }}
								</button>
								<!-- Invia offerta -->
								<!-- Viene mostrato se esiste l'id, se è con status draft e se ha almeno una linea -->
								<button
									mat-stroked-button
									color="primary"
									type="button"
									(click)="sendOffer()"
									*ngIf="
										offer &&
										offer._id &&
										offer.status === offerStatusEnum.DRAFT &&
										offer.line_list &&
										offer.line_list.length
									"
								>
									{{ 'offer.send' | translate | sentencecase }}
								</button>
							</mat-toolbar>
						</div>
					</div>
				</form>
			</mat-card>
		</div>
	</div>
</div>
