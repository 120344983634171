import { ArticleListTableComponent } from './article/article-list-table/article-list-table.component';
import { DialogNewContactComponent } from './dialog/dialog-new-contact/dialog-new-contact.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { CardTrendComponent } from './card-trend/card-trend.component';
import { ChartWrapperComponent } from './chart-wrapper/chart-wrapper.component';
import { MatAccordionWrapperComponent } from './mat-accordion-wrapper/mat-accordion-wrapper.component';
import { NestedCategoryListComponent } from './nested-category-list/nested-category-list.component';
import { InputTypeSelectableComponent } from './input-type-selectable/input-type-selectable.component';
import { PersonCardComponent } from './person-card/person-card.component';
import { MatCardHighlightValueComponent } from './mat-card-highlight-value/mat-card-highlight-value.component';
import { DialogOrganizationDetailComponent } from './dialog/dialog-organization-detail/dialog-organization-detail.component';
import { DialogNewCompanyDestinationComponent } from './dialog/dialog-new-company-destination/dialog-new-company-destination.component';
import { MatSnackBarWrapperComponent } from './mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { PasswordVerifyComponent } from './compound-input-interface/password-verify/password-verify.component';
import { DialogOtherDestinationComponent } from './dialog/dialog-other-destination/dialog-other-destination.component';
import { AlertComponent } from './alert/alert.component';
import { EmojiResponseComponent } from './emoji-response/emoji-response.component';
import { ArticleCheckoutTreeComponent } from './article/article-checkout-tree/article-checkout-tree.component';
import { ArticleCheckoutItemComponent } from './article/article-checkout-item/article-checkout-item.component';
import { DialogProductKitComponent } from './dialog/dialog-product-kit/dialog-product-kit.component';
import { CardProductComponent } from './card-product/card-product.component';
import { CardSliderComponent } from './card-slider/card-slider.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { DialogChangePermissionContextCodeComponent } from './dialog/dialog-change-permission-context-code/dialog-change-permission-context-code.component';
import { DialogAddContextCodeComponent } from './dialog/dialog-add-context-code/dialog-add-context-code.component';
import { FormPermissionGroupSelectComponent } from './form/form-permission-group-select/form-permission-group-select.component';
import { DialogCreateContextCodeComponent } from './dialog/dialog-create-context-code/dialog-create-context-code.component';

// kanban
import { KanbanCardComponent } from './kanban/kanban-card/kanban-card.component';
import { KanbanColumnComponent } from './kanban/kanban-column/kanban-column.component';
import { KanbanContainerComponent } from './kanban/kanban-container/kanban-container.component';
import { OpportunityRatingComponent } from './opportunity-rating/opportunity-rating.component';
import { AvatarComponent } from './avatar/avatar.component';
import { KabanModule } from './kanban/kaban.module';
import { CommentThreadComponent } from './comment-thread/comment-thread.component';
import { UnorderedListComponent } from './unordered-list/unordered-list.component';
import { FilterSimpleComponent } from './filter-simple/filter-simple.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ViewtypeToggleComponent } from './viewtype-toggle/viewtype-toggle.component';
import { QuantityButtonComponent } from './quantity-button/quantity-button.component';
import { CardAddressComponent } from './card-address/card-address.component';
import { NoDataFoundComponent } from './no-data-found/no-data-found.component';
import { IllustrationsModule } from '../illustrations/illustrations.module';
import { DialogOrderDetailComponent } from './dialog/dialog-order-detail/dialog-order-detail.component';
import { MirrorCostsComponent } from './mirror-costs/mirror-costs.component';
import { FormErrorMessageComponent } from './form-error-message/form-error-message.component';
import { PsToTopComponent } from './ps-to-top/ps-to-top.component';
import { OrderListWrapperComponent } from './order/order-list-wrapper/order-list-wrapper.component';
import { OrganizationListWrapperComponent } from './organization/organization-list-wrapper/organization-list-wrapper.component';
import { B2CLoginFormComponent } from './form/b2c-login-form/b2c-login-form.component';
import { OperationStateBannerComponent } from './operation-state-banner/operation-state-banner.component';
import { AddressListComponent } from './address-list/address-list.component';
import { DialogPickAddressComponent } from './dialog/dialog-pick-address/dialog-pick-address.component';
import { HighlightNumberComponent } from './highlight-number/highlight-number.component';
import { CardDashboardComponent } from './card-dashboard/card-dashboard.component';
import { IconTrendComponent } from './icon-trend/icon-trend.component';
import { ChartNoDataComponent } from './chart-no-data/chart-no-data.component';
import { LoadingResultsSpinnerComponent } from './loading-results-spinner/loading-results-spinner.component';
import { PasswordMeterComponent } from './password-meter/password-meter.component';
import { ReceiptListWrapperComponent } from './receipt/receipt-list-wrapper/receipt-list-wrapper.component';
import { ContactListWrapperComponent } from './contact/contact-list-wrapper/contact-list-wrapper.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { OrganizationPersonalDataFormComponent } from './organization-personal-data-form/organization-personal-data-form.component';
import { OrganizationDetailDataFormComponent } from './organization-detail-data-form/organization-detail-data-form.component';
import { OpportunityListWrapperComponent } from './opportunity/opportunity-list-wrapper/opportunity-list-wrapper.component';
import { DialogSelectOrganizationComponent } from './dialog/dialog-select-organization/dialog-select-organization.component';
import { DialogSelectAssegneeComponent } from './dialog/dialog-select-assegnee/dialog-select-assegnee.component';
import { UserContactInformationFormComponent } from './user-contact-information-form/user-contact-information-form.component';
import { ProspectRegistrationComponent } from './prospect-registration/prospect-registration.component';
import { OfferListWrapperComponent } from './offer/offer-list-wrapper/offer-list-wrapper.component';
import { RecaptchaComponent } from './recaptcha/recaptcha.component';
import { DestinationListWrapperComponent } from './destination/destination-list-wrapper/destination-list-wrapper.component';
import { ArticleListWrapperComponent } from './article/article-list-wrapper/article-list-wrapper.component';
import { DialogCloseOpportunityComponent } from './dialog/dialog-close-opportunity/dialog-close-opportunity.component';
import { ClientListWrapperComponent } from './client-list-wrapper/client-list-wrapper.component';
import { DialogDestinationDetail } from './dialog/dialog-destination-detail/dialog-destination-detail.component';
import { DialogNewDateComponent } from './dialog/dialog-new-date/dialog-new-date.component';
import { OrganizationHeaderComponent } from './organization-header/organization-header.component';
import { OrganizationDetailCreditListWrapperComponent } from './organization/organization-detail-credit-list-wrapper/organization-detail-credit-list-wrapper.component';
import { DialogDrawingComponent } from './dialog/dialog-drawing/dialog-drawing.component';

@NgModule({
	imports: [SharedModule, KabanModule, IllustrationsModule],
	declarations: [
		// dialog
		DialogChangePermissionContextCodeComponent,
		DialogProductKitComponent,
		DialogOrganizationDetailComponent,
		DialogNewCompanyDestinationComponent,
		DialogOtherDestinationComponent,
		DialogNewContactComponent,
		DialogNewDateComponent,
		DialogAddContextCodeComponent,
		DialogCreateContextCodeComponent,
		DialogPickAddressComponent,
		DialogOrderDetailComponent,
		DialogSelectOrganizationComponent,
		DialogSelectAssegneeComponent,
		DialogCloseOpportunityComponent,
		DialogDestinationDetail,
		AutocompleteComponent,
		FloatingMenuComponent,
		CardTrendComponent,
		ChartWrapperComponent,
		MatAccordionWrapperComponent,
		MatSnackBarWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		PersonCardComponent,
		MatCardHighlightValueComponent,
		PasswordMeterComponent,
		PasswordVerifyComponent,
		AlertComponent,
		EmojiResponseComponent,
		ArticleCheckoutTreeComponent,
		ArticleCheckoutItemComponent,
		ArticleListTableComponent,
		CardProductComponent,
		CardSliderComponent,
		JumbotronComponent,
		CallToActionComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		AvatarComponent,
		CommentThreadComponent,
		UnorderedListComponent,
		FilterSimpleComponent,
		PageHeaderComponent,
		ViewtypeToggleComponent,
		QuantityButtonComponent,
		NoDataFoundComponent,
		CardAddressComponent,
		MirrorCostsComponent,
		FormPermissionGroupSelectComponent,
		FormErrorMessageComponent,
		CommentThreadComponent,
		AvatarComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		PsToTopComponent,
		OpportunityListWrapperComponent,
		OfferListWrapperComponent,
		OrderListWrapperComponent,
		OrganizationListWrapperComponent,
		DestinationListWrapperComponent,
		B2CLoginFormComponent,
		OperationStateBannerComponent,
		AddressListComponent,
		HighlightNumberComponent,
		CardDashboardComponent,
		IconTrendComponent,
		ChartNoDataComponent,
		LoadingResultsSpinnerComponent,
		ContactListWrapperComponent,
		ReceiptListWrapperComponent,
		AddressFormComponent,
		OrganizationPersonalDataFormComponent,
		OrganizationDetailDataFormComponent,
		UserContactInformationFormComponent,
		ProspectRegistrationComponent,
		RecaptchaComponent,
		ArticleListWrapperComponent,
		ClientListWrapperComponent,
		OrganizationHeaderComponent,
		OrganizationDetailCreditListWrapperComponent,
		DialogDrawingComponent
	],
	exports: [
		// dialog
		DialogChangePermissionContextCodeComponent,
		DialogProductKitComponent,
		DialogOrganizationDetailComponent,
		DialogNewCompanyDestinationComponent,
		DialogOtherDestinationComponent,
		DialogNewContactComponent,
		DialogNewDateComponent,
		DialogAddContextCodeComponent,
		DialogCreateContextCodeComponent,
		DialogPickAddressComponent,
		DialogSelectOrganizationComponent,
		DialogSelectAssegneeComponent,
		DialogCloseOpportunityComponent,

		AutocompleteComponent,
		FloatingMenuComponent,
		CardTrendComponent,
		ChartWrapperComponent,
		MatAccordionWrapperComponent,
		MatSnackBarWrapperComponent,
		DestinationListWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		PersonCardComponent,
		MatCardHighlightValueComponent,
		PasswordVerifyComponent,
		AlertComponent,
		EmojiResponseComponent,
		ArticleCheckoutTreeComponent,
		ArticleCheckoutItemComponent,
		ArticleListTableComponent,
		CardProductComponent,
		CardSliderComponent,
		JumbotronComponent,
		CallToActionComponent,
		FormPermissionGroupSelectComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		AvatarComponent,
		CommentThreadComponent,
		UnorderedListComponent,
		FilterSimpleComponent,
		PageHeaderComponent,
		ViewtypeToggleComponent,
		QuantityButtonComponent,
		NoDataFoundComponent,
		CardAddressComponent,
		MirrorCostsComponent,
		FormErrorMessageComponent,
		PsToTopComponent,
		OpportunityListWrapperComponent,
		OfferListWrapperComponent,
		OrderListWrapperComponent,
		OrganizationListWrapperComponent,
		ReceiptListWrapperComponent,
		B2CLoginFormComponent,
		OperationStateBannerComponent,
		AddressListComponent,
		HighlightNumberComponent,
		CardDashboardComponent,
		IconTrendComponent,
		ChartNoDataComponent,
		LoadingResultsSpinnerComponent,
		ContactListWrapperComponent,
		AddressFormComponent,
		OrganizationPersonalDataFormComponent,
		OrganizationDetailDataFormComponent,
		UserContactInformationFormComponent,
		ProspectRegistrationComponent,
		RecaptchaComponent,
		ArticleListWrapperComponent,
		ClientListWrapperComponent,
		OrganizationHeaderComponent,
		OrganizationDetailCreditListWrapperComponent
	],
	entryComponents: [
		// dialog
		DialogChangePermissionContextCodeComponent,
		DialogProductKitComponent,
		DialogOrganizationDetailComponent,
		DialogNewCompanyDestinationComponent,
		DialogOtherDestinationComponent,
		DialogNewContactComponent,
		DialogNewDateComponent,
		DialogAddContextCodeComponent,
		DialogCreateContextCodeComponent,
		DialogOrderDetailComponent,
		DialogPickAddressComponent,
		DialogSelectOrganizationComponent,
		DialogSelectAssegneeComponent,
		DialogCloseOpportunityComponent,
		DialogDestinationDetail,
		MatSnackBarWrapperComponent,
		ArticleListTableComponent,
		FormPermissionGroupSelectComponent,
		MatSnackBarWrapperComponent,
		DestinationListWrapperComponent,
		ArticleListTableComponent,
		KanbanCardComponent,
		KanbanColumnComponent,
		KanbanContainerComponent,
		OpportunityRatingComponent,
		AvatarComponent,
		CommentThreadComponent,
		FormErrorMessageComponent,
		PsToTopComponent,
		HighlightNumberComponent,
		CardDashboardComponent,
		IconTrendComponent,
		ChartNoDataComponent,
		LoadingResultsSpinnerComponent,
		ContactListWrapperComponent,
		UserContactInformationFormComponent,
		ProspectRegistrationComponent,
		RecaptchaComponent,
		ClientListWrapperComponent,
		OrganizationHeaderComponent
	]
})
export class WidgetModule {}
