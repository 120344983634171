import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';

import {
	TokenPayload,
	RestBaseMessageError,
	LoginAuthRequestModel,
	LoginAuthResponseModel,
	LinkCodeModel,
	BaseState,
	UserDetailModel,
	BaseStateModel,
	ContextApplicationItemCodeEnum
} from '@saep-ict/angular-core';
import { AgentStateAction } from '../../state/agent/agent.actions';
import { AuxiliaryTableStateAction } from '../../state/auxiliary-table/auxiliary-table.actions';
import { OrganizationStateAction } from '../../state/organization/organization.actions';
import { DestinationListStateAction } from '../../state/destination-list/destination-list.actions';
import { OrderListStateAction } from '../../state/order-list/order-list.actions';
import { OrderStateAction } from '../../state/order/order.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UserService } from './user.service';
import { take } from 'rxjs/operators';
import { StateFeature } from '../../state';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerAppConfig } from '../../customer-app.config';
import { PATH_URL, ROUTE_URL } from '../../router/route-naming';
import { UtilService } from '@saep-ict/angular-core';
import { ContextCodeAssociationStateAction } from '../../state/backoffice/context-code/context-code-association/context-code-association.actions';
import { LoginContextCodeStateAction } from '../../state/common/login/context-code/login-context-code.actions';
import { KanbanStateAction } from '../../state/kanban/kanban.actions';
import { OfferStateAction } from '../../state/offer/offer.actions';
import { OpportunityStateAction } from '../../state/opportunity/opportunity.actions';
import { CrmUserListStateAction } from '../../state/backoffice/context-code/crm-user-list/crm-user-list.actions';
import { B2cUserListStateAction } from '../../state/backoffice/context-code/b2c-user-list/b2c-user-list.actions';
import { PermissionAuxiliaryTableStateAction } from '../../state/permission-auxiliary-table/permission-auxiliary-table.actions';
import { AgentListStateAction } from '../../state/backoffice/context-code/agent-list/agent-list.actions';
import { BackofficeUserListStateAction } from '../../state/backoffice/context-code/backoffice-user-list/backoffice-user-list.actions';
import { UserManagementStateAction } from '../../state/backoffice/user-management/user-management.actions';
import { ArticleDetailStateAction } from '../../state/article-detail/article-detail.actions';
import { SearchResultsStateAction } from '../../state/search-results/search-results.actions';
import { HomeHighlightsStateAction } from '../../state/home-highlights/home-highlights.actions';
import { InformativePageStateAction } from '../../state/informative-page/informative-page.actions';
import { ReceiptListStateAction } from '../../state/receipt-list/receipt-list.actions';
import { CategoryListAction } from '../../state/category-list/category-list.actions';
import { CompanyAccountBalanceAction } from '../../state/company-account-balance/company-account-balance.actions';
import { OrganizationListStateAction } from '../../state/common/organization-list/organization-list.actions';
import { ArticleListStateAction } from '../../state/article-list/article-list.actions';
import { StatisticsOrdersStateAction } from '../../state/statistics-orders/statistics-orders.action';
import { StatisticsDetailExpiredStateAction } from '../../state/statistics-detail-expired/statistics-detail-expired.action';
import { StatisticsDetailClientsStateAction } from '../../state/statistics-detail-clients/statistics-detail-clients.action';
import { StatisticsDetailSoldStateAction } from '../../state/statistics-detail-sold/statistics-detail-sold.action';
import { StatisticsDetailOrdersStateAction } from '../../state/statistics-detail-orders/statistics-detail-orders.action';
import { StatisticsCrmStateAction } from '../../state/statistics-crm/statistics-crm.action';
import { StatisticsBackofficeStateAction } from '../../state/statistics-backoffice/statistics-backoffice.action';
import { StatisticsOrganizationStateAction } from '../../state/statistics-organization/statistics-organization.action';
import { StatisticsAgentStateAction } from '../../state/statistics-agent/statistics-agent.action';
import { DestinationStateAction } from '../../state/destination/destination.actions';
import { ContactStateAction } from '../../state/contact/contact.actions';
import { UserActivateStateAction } from '../../state/user-activate/user-activate.actions';
import { PouchUtilService } from '@saep-ict/pouch-db';

@Injectable()
export class AuthService {
	@LocalStorage('authenticationToken')
	authenticationToken: string;
	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;
	@LocalStorage('link_code')
	private link_code: LinkCodeModel;

	private tokenPayloadChange: BehaviorSubject<TokenPayload> = new BehaviorSubject<TokenPayload>(undefined);
	tokenPayloadChange$: Observable<TokenPayload> = this.tokenPayloadChange.asObservable();

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private store: Store<any>,
		private router: Router,
		private localStorageService: LocalStorageService,
		private userService: UserService,
		public translate: TranslateService,
		public snackBar: MatSnackBar,
		private http: HttpClient,
		private appConfig: CustomerAppConfig,
		private cookieService: CookieService,
		private utilService: UtilService,
		private pouchService: PouchUtilService,
	) {
		const tk = this.cookieService.get('acstk');
		if (tk) {
			this.authenticationToken = tk;
			const tk_decoded = jwt_decode(this.authenticationToken);
			this.tokenPayload = new TokenPayload(tk_decoded);
		}
	}

	login(loginAuthRequest: LoginAuthRequestModel) {
		let loginCall: Promise<any>;
		if (this.cookieService.get('origin_srv') === 'true') {
			const headers = new HttpHeaders({
				'Content-Type': 'application/json'
			});
			loginCall = this.http
				.post(`${this.appConfig.config.urlConfig.origin}/authenticate`, loginAuthRequest, {
					headers
				})
				.toPromise();
		} else {
			loginCall = this.userService.login(loginAuthRequest);
		}

		loginCall
			.then((res: LoginAuthResponseModel) => {
				console.log('Auth service got token: ', res.accessToken);
				this.authenticationToken = res.accessToken;
				this.router.navigate([ROUTE_URL.private]);
			})
			.catch((err: RestBaseMessageError) => {
				const message = `Accesso non riuscito. <br> ${
					err && err.body && err.body.detail ? err.body.detail : err
				}`;
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: message
					}
				});
			});
	}

	/**
	 * Effettiva azione di logout eseguita dall'utente autenticato e logout forzato da sistema
	 * nel caso l'utente non abbia sufficienti autorizzazioni per accedere alla sezione richiesta
	 */
	logout() {
		this.navigateToAuth();
		this.deleteRouting();
		this.clearOnLogout();
		this.clearPouchConnection;
	}

	private clearPouchConnection(): void {
		// this.pouchService.clearConnection();
		const configToStop = this.appConfig.config.couch.filter(cnf => !!cnf['context']);
		this.pouchService.explicitStopCouchDb(configToStop);
	}

	/**
	 * Pulizia localStorage e store durante il logout
	 */
	clearOnLogout() {
		this.localStorageService.clear();
		this.clearState();
	}

	/**
	 * Cancellazione info relative alle routes navigabili
	 */
	deleteRouting() {
		const routes = this.router.config;
		routes.find(rt => rt.path === ROUTE_URL.private).children = [];
		this.router.resetConfig(routes);
	}

	/**
	 * Navigate to primary baseUrl on logout
	 */
	navigateToAuth() {
		if (this.link_code && this.link_code.context != ContextApplicationItemCodeEnum.B2C) {
			this.router.navigate(['/', ROUTE_URL.authentication, ROUTE_URL.login]);
		} else {
			this.utilService.redirectTo(ROUTE_URL.public);
		}
	}

	clearState() {
		this.store.dispatch(UserStateAction.reset());
		this.store.dispatch(AgentStateAction.reset());
		this.store.dispatch(OrganizationStateAction.reset());
		this.store.dispatch(ContactStateAction.reset());
		this.store.dispatch(UserActivateStateAction.reset());
		this.store.dispatch(OrderStateAction.reset());
		this.store.dispatch(OrderListStateAction.reset());
		this.store.dispatch(AuxiliaryTableStateAction.reset());
		this.store.dispatch(DestinationStateAction.reset());
		this.store.dispatch(DestinationListStateAction.reset());
		this.store.dispatch(StatisticsAgentStateAction.reset());
		this.store.dispatch(StatisticsOrganizationStateAction.reset());
		this.store.dispatch(StatisticsBackofficeStateAction.reset());
		this.store.dispatch(StatisticsCrmStateAction.reset());
		this.store.dispatch(StatisticsDetailOrdersStateAction.reset());
		this.store.dispatch(StatisticsDetailSoldStateAction.reset());
		this.store.dispatch(StatisticsDetailClientsStateAction.reset());
		this.store.dispatch(StatisticsDetailExpiredStateAction.reset());
		this.store.dispatch(StatisticsOrdersStateAction.reset());
		this.store.dispatch(ArticleListStateAction.reset());
		this.store.dispatch(CategoryListAction.reset());
		this.store.dispatch(OrganizationListStateAction.reset());
		this.store.dispatch(ReceiptListStateAction.reset());
		this.store.dispatch(InformativePageStateAction.reset());
		this.store.dispatch(HomeHighlightsStateAction.reset());
		this.store.dispatch(SearchResultsStateAction.reset());
		this.store.dispatch(ArticleDetailStateAction.reset());
		this.store.dispatch(UserManagementStateAction.reset());
		this.store.dispatch(CompanyAccountBalanceAction.reset());
		this.store.dispatch(BackofficeUserListStateAction.reset());
		this.store.dispatch(AgentListStateAction.reset());
		this.store.dispatch(ContextCodeAssociationStateAction.reset());
		this.store.dispatch(PermissionAuxiliaryTableStateAction.reset());
		this.store.dispatch(B2cUserListStateAction.reset());
		this.store.dispatch(CrmUserListStateAction.reset());
		this.store.dispatch(OpportunityStateAction.reset());
		this.store.dispatch(OfferStateAction.reset());
		this.store.dispatch(KanbanStateAction.reset());
		this.store.dispatch(LoginContextCodeStateAction.reset());
		// manca ticket perché gestito in libreria
	}

	get tokenPayload(): TokenPayload {
		if (!this._tokenPayload) {
			return undefined;
		}
		return new TokenPayload(this._tokenPayload);
	}

	set tokenPayload(tk: TokenPayload) {
		if (tk) {
			this._tokenPayload = new TokenPayload(tk);
		} else {
			this._tokenPayload = null;
		}
		this.tokenPayloadChange.next(this._tokenPayload);
	}

	openSnackBar(message: string, action = 'Ok') {
		this.snackBar.open(message, action, {
			duration: 3000,
			verticalPosition: 'top'
		});
	}

	changeContext() {
		// Clear local storage
		this.link_code = null;

		// retreive user data
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});

		// clear current context
		this.user.current_permission.context_application = null;
		this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
		this.clearState();
		this.deleteRouting();
		console.log('clearing pouch connection');
		this.clearPouchConnection();

		// Redirect to context-selection
		this.router.navigate([ROUTE_URL.authentication, ROUTE_URL.contextSelection]);
	}
}
