import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SentencecasePipe, UtilColorService, UtilService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { chartTimeRange7Days } from '../../../constants/chart-time-range.constants';
import { ChartThemeEnum } from '../../../enum/chart-theme.enum';
import { StatisticsAgentBaseSerieOrder } from '../../../model/statistics-agent.model';
import { BaseChartClass } from '../chart-configurations/base-chart.service';
import { ActivatedRoute } from '@angular/router';
import { ROUTE_URL } from '../../../router/route-naming';
import { AppUtilService } from '../../util/app-util.service';

@Injectable()
export class OrderTrendChartConfigService extends BaseChartClass {
	cardDashboardConfigOrdersTrend: CardDashboardConfig = {
		title: 'chart.order_trend.title',
		subtitle: 'chart.order_trend.subtitle',
		icon: 'assignment',
		helpText: 'chart.order_trend.helptext'
	};

	defaultTimeRange: number = chartTimeRange7Days.values[0];

	constructor(
		public utilService: AppUtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		public translateService: TranslateService,
		private sentencecasePipe: SentencecasePipe,
		public route: ActivatedRoute
	) {
		super(utilService, utilColorService, store, route, translateService);
		this.chart.chartType = 'bar';
		this.chart.options = this.optionsStyle(ChartThemeEnum.DEFAULT);
		this.chart.colors = [
			{
				backgroundColor: this.colorPrimary,
				borderColor: this.colorPrimary
			}
		];
	}

	// Functions

	createDataSerie() {
		// let orderTrend;
		// if (this.route.snapshot.routeConfig.path === ROUTE_URL.dashboardOrders) {
		// 	orderTrend = this.statistics.order_trend;
		// } else {
		// 	orderTrend = this.statistics.orders.order_trend;
		// }
		const serieOrderTrendList = [
			{ key: 'to_authorize', color: this.colorPrimary },
			{ key: 'processing', color: this.colorPrimary70 },
			{ key: 'consolidated', color: this.colorPrimary50 }
		];

		serieOrderTrendList.forEach((serieOrderTrend, index) => {
			if (this.utilService.findLeaf(this.statistics.data, `orders.order_trend.${serieOrderTrend.key}`)) {
				const serieTemp = [];
				this.utilService
					.findLeaf(this.statistics.data, `orders.order_trend.${serieOrderTrend.key}`)
					.forEach((element: StatisticsAgentBaseSerieOrder) => {
						serieTemp.push(element.value);

						// Popolo label solo la prima volta
						if (!index) {
							this.fullLabels.push(element.date);
						}
					});
				this.fullDataSets.push({
					label: serieOrderTrend.key,
					data: serieTemp,
					backgroundColor: serieOrderTrend.color,
					barPercentage: 0.4
				});
			}
		});
	}

	populateChart(rangeDays: number = this.defaultTimeRange) {
		this.removeData(this.chart.labels);
		this.removeData(this.chart.datasets);

		let tempSerie = [];
		this.fullDataSets.forEach(item => {
			let serie = _.cloneDeep(item);
			serie.data = item.data.slice(-rangeDays);
			tempSerie.push(serie);
		});

		this.addData(this.chart.datasets, tempSerie);
		this.addData(this.chart.labels, this.fullLabels.slice(-rangeDays));
		this.chart = _.cloneDeep(this.chart);
	}

	// Style

	tooltipsCallbacks(translateService, sentencecasePipe) {
		return {
			label: function (tooltipItem, data) {
				let label =
					translateService.instant(
						'order.status.' + data.datasets[tooltipItem.datasetIndex].label.toUpperCase()
					) || '';

				if (label) {
					label = sentencecasePipe.transform(label);
					label += ': ';
				}
				label += tooltipItem.yLabel;
				return label;
			}
		};
	}

	yAxesStyle(theme: ChartThemeEnum) {
		return [
			{
				stacked: true,
				gridLines: {
					drawBorder: false,
					display: true,
					borderDash: [8, 6],
					color: theme === ChartThemeEnum.DEFAULT ? this.colorBlack30 : this.colorWhite50
				},
				ticks: {
					display: true,
					stepSize: 1,
					padding: 8,
					// Font color for tick labels.
					fontColor: theme === ChartThemeEnum.DEFAULT ? this.colorBlack : this.colorWhite
				}
			}
		];
	}

	xAxesStyle() {
		return [
			{
				stacked: true,
				gridLines: {
					display: false
				},
				ticks: {
					display: true
				}
			}
		];
	}

	tooltipsStyle() {
		return {
			// se false il tooltip compare se sull'asse x
			intersect: false,
			callbacks: this.tooltipsCallbacks(this.translateService, this.sentencecasePipe)
		};
	}

	optionsStyle(theme: ChartThemeEnum) {
		return {
			responsive: true,
			layout: this.layoutStyle(),
			elements: this.elementsStyle(),
			tooltips: this.tooltipsStyle(),
			hover: this.hoverStyle(),
			scales: {
				xAxes: this.xAxesStyle(),
				yAxes: this.yAxesStyle(theme)
			},
			aspectRatio: 4
		};
	}
}
