<!-- <app-page-header
	[title]="cartProduct?.articleDetail.description"
	[breadcrumbsList]="['home', 'Nome categoria', cartProduct?.articleDetail.description]"
></app-page-header> -->

<section class="product-detail">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-4">
				<div class="product-detail__slider">
					<div class="product-detail__slider__main">
						<img [src]="cartProduct?.articleDetail.image_path" alt="{{ 'Product name' }}" />
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-8">
				<div class="product-detail__content">
					<h2 class="product-detail__content__title">{{ cartProduct?.articleDetail.description }}</h2>
					<p class="product-detail__content__description">
						{{ cartProduct?.articleDetail.extended_description }}
					</p>

					<div class="product-detail__content__variant">
						<div class="variant__col">
							<!-- <p class="variant__title">{{ product.description }}</p> -->
							<p class="variant__availability" *ngIf="cartProduct?.articleDetail.stock">
								<span
									class="variant__availability__badge"
									[ngClass]="ArticleStock[(cartProduct?.articleDetail.stock.code_segnalation)]"
								></span>
								{{ cartProduct?.articleDetail.stock.description_segnalation }}
							</p>
						</div>
						<div class="variant__col">
							<p>
								<span class="variant__final-price">
									{{
										utilOrderService.getPriceFromDivision(cartProduct?.articleDetail.divisions)
											| currency: 'EUR':'symbol':'':'it'
									}}
								</span>
								<span class="variant__vat">(iva inclusa)</span>
							</p>
							<p *ngIf="cartProduct?.articleDetail.original_price">
								<span class="variant__original-price">
									{{
										cartProduct?.articleDetail.original_price | currency: 'EUR':'symbol':'':'it'
									}} </span
								><span class="variant__discount">- {{ (cartProduct?.discount)[0].value }}%</span>
							</p>
						</div>
						<div class="variant__col">
							<app-quantity-button
								[packageSize]="cartProduct?.articleDetail.qty_box"
								(changeValue)="changeValue($event)"
							></app-quantity-button>
							<small class="cell-subtitle" *ngIf="orderedQuantityLabel">
								<span [innerHTML]="orderedQuantityLabel"></span>
								<mat-icon
									*ngIf="cartProduct?.articleDetail.qty_box && cartProduct?.articleDetail.qty_box > 1"
									class="mat-icon variant__quantity__help"
									matTooltip="Solo multipli di {{ cartProduct?.articleDetail.qty_box }}"
								>
									help_outline
								</mat-icon>
							</small>
						</div>
					</div>

					<!-- Add cart -->
					<button
						mat-raised-button
						color="primary"
						class="custom-button mt-3"
						[disabled]="noProductSelected()"
						(click)="addToCart()"
					>
						Aggiungi al carrello
					</button>
				</div>
			</div>

			<div class="col-sm-12 col-md-12 col-lg-12">
				<div class="product-detail__details">
					<mat-accordion>
						<!-- Dettagli prodotto -->
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title> Dettagli prodotto </mat-panel-title>
							</mat-expansion-panel-header>
							<p>
								{{ cartProduct?.articleDetail.extra_info }}
							</p>
						</mat-expansion-panel>
						<!-- FAQ -->
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title> FAQ </mat-panel-title>
							</mat-expansion-panel-header>
							<p>This is the primary content of the panel.</p>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="related-products">
	<div class="container">
		<div class="row">
			<div class="col">
				<h2>Related products</h2>
			</div>
		</div>
	</div>
	<div class="container-fluid">
		<div class="row">
			<div class="col">
				<!-- Slider category -->
				<app-card-slider *ngIf="config" [config]="config">
					<div *sliderItem="let item; let idx = index" class="card-slider-item">
						<app-card-product
							[itemType]="ItemType.product"
							[viewType]="viewTypeEnum.module"
							[data]="item"
						></app-card-product>
					</div>
				</app-card-slider>
			</div>
		</div>
	</div>
</section>

<app-call-to-action [callToActionConfig]="callToActionConfig" (clickButton)="clickCallToAction()"></app-call-to-action>
