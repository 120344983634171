import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {ReceiptListStateReducer} from './receipt-list.reducer'


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('receipt-list', ReceiptListStateReducer.reducer)
  ]
})
export class ReceiptListStateModule { }
