<breadcrumb
	[title]="
		opportunity
			? opportunity.title + ' '
			: ((route.parent.snapshot.paramMap.get('opportunityType') === opportunityTypeEnum.LEAD
					? 'request.new'
					: 'opportunity.new')
			  | translate
			  | sentencecase)
	"
	[pathList]="[
		'Home',
		route.parent.snapshot.paramMap.get('opportunityType') === opportunityTypeEnum.LEAD
			? 'request.name_plural'
			: 'opportunity.name'
	]"
	[backButton]="backButtonRoute"
	[badge]="opportunity ? ('opportunity.status.' + opportunity.status_code | translate | sentencecase) : null"
	badgeClass="badge-light"
></breadcrumb>

<tab-wrapper [toExclude]="tabsToExclude"></tab-wrapper>
