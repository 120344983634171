import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core/data-table';
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	DateMomentService,
	ListWrapperComponent,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { OrganizationPouchModel, OrderPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel } from '../../../model/state/order-state.model';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { PATH_URL, ROUTE_URL } from '../../../router/route-naming';
import { DateFilterModel } from '../../../service/pouch-db/filter/order-filter.model';
import { OrderListColumnService } from '../../../service/td-data-table/implementation/order-list.service';
import { UtilCompanyService } from '../../../service/util/util-company.service';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { OrderFilterTypeEnum } from '../../../enum/order-filter-type.enum';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogOrderDetailComponent } from '../../../widget/dialog/dialog-order-detail/dialog-order-detail.component';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';
import { IrinoxOrderStatusEnum } from '../../../enum/app.enum';
import { CustomerAppConfig } from '../../../customer-app.config';

@Component({
	selector: 'order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss'],
	providers: [OrderListColumnService, SubscribeManagerService]
})
export class OrderComponent implements OnInit, OnDestroy {
	@ViewChild('listWrapper') public listWrapper: ListWrapperComponent;
	@ViewChild('dataTable') public dataTable: TdDataTableComponent;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization: OrganizationStateModel;

	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);
	organizationList: BaseStateModel<OrganizationStateModel[]>;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);
	orderList: TableOrderModel[];

	filteredArticleCode: string[];
	filteredArticleRefClient: string[];

	columns: ITdDataTableColumn[];
	orderListFilterForm: FormGroup;

	listPageBaseData = <LocalListHandlerBaseModel<TableOrderModel>>{
		filters: {
			localSearchText: {
				value: null,
				key_list: [
					'_id',
					'header.organization.business_name',
					'header.organization.code_item',
					'product_list.code_erp',
					'csuite.order_so_number',
					'csuite.order_so_client_ref'
				]
			}
		},
		pagination: {
			pageSize: 10
		}
	};

	ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	// Contesto di pagina
	// 'orders'
	pageContext: string;
	orderState: string;
	companyCode: string;
	orderStatesFilterList: OrderStatusEnum | string[] = [];

	currentContext: ContextApplicationItemCodeEnum;

	// template
	canAddNew: boolean;

	constructor(
		private store: Store<any>,
		private router: Router,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private orderListColumnService: OrderListColumnService,
		public dateMomentService: DateMomentService,
		public utilOrderService: UtilOrderService,
		private subscribeManagerService: SubscribeManagerService,
		public utilCompanyService: UtilCompanyService,
		private dialog: MatDialog,
		private appConfig: CustomerAppConfig,
	) {
		this.store.dispatch(OrderListStateAction.loadAll());

		this.createFormFilters();

		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
			this.currentContext = this.user.current_permission.context_application;
			this.canAddNew = this.getCanAddNew();
		});

		// Recupero le informazioni del contesto pagina
		// es. 'orders', il codice della company
		this.route.parent.url.pipe(take(1)).subscribe(url => {
			this.pageContext = url[0].path;
			// console.log('pageContext', this.pageContext);
		});

		// Lista ordini di un'organizzazione
		this.organization$.pipe(take(1)).subscribe(res => {
			this.organization = res ? res.data : null;
		});
		this.subscribeManagerService.populate(this.subscribeOrderList().subscribe(), 'subscribeOrderList');

		// Se siamo in contesto lista ordini e posso filtrare per organization
		// Lasciamo separata la dispatch della lista organization in quanto potrebbe durare molto
		if (this.pageContext === 'orders') {
			this.store.dispatch(OrganizationListStateAction.loadAll());
			this.subscribeManagerService.populate(
				this.subscribeOrganizationList().subscribe(),
				'subscribeOrganizationList'
			);
		}
	}

	ngOnInit() {
		this.orderStatesFilterList = [
			IrinoxOrderStatusEnum.HANDLING,
			IrinoxOrderStatusEnum.CONFIRMED,
			OrderStatusEnum.CONSOLIDATED,
			OrderStatusEnum.PARTIALLY_FULFILLED,
			OrderStatusEnum.FULFILLED
		];
	}

	ngAfterViewInit(){
		if(this.user.current_permission.context_application === ContextApplicationItemCodeEnum.BACKOFFICE){
			window.addEventListener('message', (event) => {
				// Event.origin corrisponde all'url della V2, mentre ancestorOrigin è l'url parent. Configurazione nell'environment
					if (location.ancestorOrigins[0] === this.appConfig.config.portal.origin) {
						console.log('hidden elements ----->')
				  this.hiddenHtmlElement();
				} else {
				  console.log('Data not sent from parent');
				  return;
				}
			  });
		}
	}

	hiddenHtmlElement() {
		const element = document.getElementsByTagName('breadcrumb')[0] as HTMLIFrameElement;
		element.style.visibility = 'hidden';
		element.style.display = 'block';
		element.style.height = '0px';
	}

	subscribeOrganizationList() {
		return this.organizationList$.pipe(
			filter((state: BaseStateModel<OrganizationPouchModel[]>) => !!(state && state.data)),
			map((state: BaseStateModel<OrganizationPouchModel[]>) => {
				this.organizationList = state;
				// restituisco solo le company valide
				this.organizationList.data = this.organizationList.data.filter(company => company.valid);
				return state;
			})
		);
	}

	subscribeOrderList() {
		return this.route.paramMap.pipe(
			mergeMap(params => {
				this.orderState = params.get('orderStatus') ? params.get('orderStatus').toUpperCase() : null;
				this.companyCode = this.route.snapshot.parent.params['idOrganization'];
				this.resetOrderFormFilter();
				this.columns = ConfigurationCustomer.Order.columnList(
					this.orderListColumnService.columns,
					this.user.current_permission.context_application,
					this.organization
				);
				return this.orderList$;
			}),
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					orderList && orderList.type !== OrderListActionEnum.LOAD_ALL
			),
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					!!(this.user.current_permission.context_code.code && orderList)
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				switch (orderList.type) {
					case OrderListActionEnum.UPDATE:
						this.permanentFiltersOrder(orderList.data);
						this.setupFilterArticleCode();
						this.setupFilterArticleRefClient();
						break;

					case OrderListActionEnum.ERROR:
						this.updateListPageBaseData([]);
						throw new Error(OrderListActionEnum.ERROR);
						break;

					default:
						break;
				}
			})
		);
	}

	// Can add new order
	getCanAddNew(): boolean {
		return false;
	}

	// Filtrare in base al contesto
	permanentFiltersOrder(orderList: OrderStateModel[]) {
		// TODO - ci sono degli ordini senza header, come mai?
		orderList = orderList.filter(order => order.header);

		if (this.orderState) {
			orderList = this.filterByOrderStatus(orderList, this.getOrderStatusGroup(this.orderState));
		} else {
			orderList = orderList.filter(order => order.header.status !== OrderStatusEnum.DELETED);
		}

		switch (this.currentContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				orderList = this.permanentFiltersOrderAgent(orderList);
				break;

			case ContextApplicationItemCodeEnum.BACKOFFICE:
				orderList = this.permanentFiltersOrderBackoffice(orderList);
				break;

			case ContextApplicationItemCodeEnum.B2B:
				// orderList = this.permanentFiltersOrderB2b(orderList);
				break;

			case ContextApplicationItemCodeEnum.B2C:
				orderList = this.permanentFiltersOrderB2c(orderList);
				break;

			default:
				break;
		}

		this.orderList = this.utilOrderService.getTableOrderList(_.cloneDeep(orderList));
		this.updateListPageBaseData(this.orderList);
	}

	// Filtro la lista ordini per il contesto agent
	permanentFiltersOrderAgent(orderList: OrderStateModel[]): OrderStateModel[] {
		if (this.pageContext === 'orders') {
			const agentCode = this.user.current_permission.context_code.code;

			// TODO - filtro da deprecare per partizionamento database
			orderList = orderList.filter(order => order.header.code_agent === agentCode);
		} else {
			// contesto organization specifico
			orderList = orderList.filter(order => order.header.organization?.code_item === this.companyCode);
		}

		return orderList;
	}

	// Filtro la lista ordini per il contesto backoffice
	permanentFiltersOrderBackoffice(orderList: OrderStateModel[]): OrderStateModel[] {
		if (this.pageContext === 'orders') {
		} else {
			// contesto organization specifico
			orderList = orderList.filter(order => order.header.organization?.code_item === this.companyCode);
		}

		return orderList;
	}

	// Filtro la lista ordini per il contesto B2B
	// permanentFiltersOrderB2b(orderList: OrderStateModel[]): OrderStateModel[] {
	// 	return orderList.filter(
	// 		order => order.header.organization.code_item === this.user.current_permission.context_code.code
	// 	);
	// }

	// Filtro la lista ordini per il contesto B2C
	permanentFiltersOrderB2c(orderList: OrderStateModel[]): OrderStateModel[] {
		return orderList.filter(order => order.header.status !== OrderStatusEnum.DRAFT);
	}

	// Seleziona il servizio delle colone corretto
	// createTableColumns() {
	// 	if (!this.orderState) {
	// 		this.columns = this.orderListColumnService.columns.filter(col =>
	// 			companyOrderListColumns.includes(col.name)
	// 		);
	// 	} else {
	// 		switch (this.currentContext) {
	// 			case ContextApplicationItemCodeEnum.B2C:
	// 				this.columns = this.orderListColumnService.columns.filter(col =>
	// 					b2cOrderListColumns.includes(col.name)
	// 				);
	// 				break;
	// 			case ContextApplicationItemCodeEnum.BACKOFFICE:
	// 				if (this.companyCode) {
	// 					this.columns = this.orderListColumnService.columns.filter(col =>
	// 						companyOrderListColumns.includes(col.name)
	// 					); // sezione ordini organizzazione
	// 				} else {
	// 					this.columns = this.orderListColumnService.columns.filter(col =>
	// 						backofficeOrderListColumns.includes(col.name)
	// 					); // sezione ordini
	// 				}
	// 				break;
	// 			case ContextApplicationItemCodeEnum.B2B:
	// 				this.columns = this.orderListColumnService.columns.filter(col =>
	// 					b2bOrderListColumns.includes(col.name)
	// 				); // sezione ordini
	// 				break;
	// 			default:
	// 				this.columns = this.orderListColumnService.columns.filter(col =>
	// 					agentOrderListColumns.includes(col.name)
	// 				);
	// 				break;
	// 		}
	// 	}
	// }

	/**
	 * Sidebar - filter
	 */

	// Filters component
	createFormFilters() {
		this.orderListFilterForm = this.fb.group({
			date: [''],
			due_date: [''],
			tot: [''],
			article_code: [''],
			article_client_ref: [''],
			// agent
			business_name: [''],
			// company
			order_states: ['']
		});
	}

	/**
	 * Genera le voci del filtro nel contesto company detail
	 */
	getOrderStatusListFilter() {
		let macroStatusList = [];
		switch (this.currentContext) {
			case ContextApplicationItemCodeEnum.AGENT:
				macroStatusList = this.utilOrderService.getOrderStatusMacroStates('AGENT');
				break;

			case ContextApplicationItemCodeEnum.BACKOFFICE:
			case ContextApplicationItemCodeEnum.B2B: // TODO: implement actual B2B case
				macroStatusList = this.utilOrderService.getOrderStatusMacroStates('BACKOFFICE');
				break;

			default:
				break;
		}
		return macroStatusList;
	}

	// Applica i filtri presenti in sidebar
	submitOrderListFilters() {
		let filteredList = _.cloneDeep(this.orderList);

		// Date
		if (this.orderListFilterForm && this.orderListFilterForm.value.date) {
			const filterDate: DateFilterModel = {
				begin: this.orderListFilterForm.value.date.begin.startOf('day').format('x'),
				end: this.orderListFilterForm.value.date.end.endOf('day').format('x')
			};
			// TODO
		}

		// Due date
		if (this.orderListFilterForm && this.orderListFilterForm.value.due_date) {
			const filterDueDate: DateFilterModel = {
				begin: this.orderListFilterForm.value.due_date.begin.startOf('day').format('x'),
				end: this.orderListFilterForm.value.due_date.end.endOf('day').format('x')
			};
			// TODO - Due date
		}

		// Article code
		if (this.orderListFilterForm && this.orderListFilterForm.value.article_code) {
			filteredList = filteredList.filter(order =>
				order.product_list.find(product => product.code === this.orderListFilterForm.value.article_code)
			);
		}

		// Article code
		if (this.orderListFilterForm && this.orderListFilterForm.value.article_client_ref) {
			filteredList = filteredList.filter(order =>
				order.product_list.find(
					product => product.client_ref === this.orderListFilterForm.value.article_client_ref
				)
			);
		}

		if (this.orderListFilterForm && this.orderListFilterForm.value.order_states) {
			const status: string[] = this.orderListFilterForm.value.order_states;
			const statusList = [];
			status.forEach(status => statusList.push(...this.getOrderStatusGroup(status)));
			filteredList = this.filterByOrderStatus(filteredList, statusList);
		}
		this.updateListPageBaseData(filteredList);
	}

	resetOrderFormFilter() {
		this.orderListFilterForm.patchValue({
			date: '',
			due_date: '',
			tot: '',
			article_code: '',
			article_client_ref: '',
			business_name: '',
			order_states: ''
		});
		this.submitOrderListFilters();
	}

	// Filtro con autocomplete presente in sidebar
	_filterCompanyNames(value: string): OrganizationPouchModel[] {
		const filterValue = value.toLowerCase();

		return this.organizationList.data
			.filter(organization => organization.business_name.toLowerCase().includes(filterValue))
			.sort((a, b) => (a.business_name < b.business_name ? -1 : 1));
	}

	setupFilterArticleCode() {
		this.filteredArticleCode = this.orderList
			// tiro fuori i codici prodotto per ogni articolo di ogni ordine
			.map(order =>
				order.product_list
					? order.product_list.filter(product => product.code).map(product => product.code)
					: []
			)
			// metto insieme i codici e appiattisco l'array padre
			.reduce((acc, val) => acc.concat(val), []);
		// escludo i duplicati
		this.filteredArticleCode = [...new Set(this.filteredArticleCode)];
	}

	setupFilterArticleRefClient() {
		this.filteredArticleRefClient = this.orderList
			// tiro fuori i codici prodotto per ogni articolo di ogni ordine
			.map(order =>
				order.product_list
					? order.product_list.filter(product => product.client_ref).map(product => product.client_ref)
					: []
			)
			// metto insieme i codici e appiattisco l'array padre
			.reduce((acc, val) => acc.concat(val), []);
		// escludo i duplicati
		this.filteredArticleRefClient = [...new Set(this.filteredArticleRefClient)];
	}

	/**
	 * Utility
	 */

	// Aggiorna l'oggetto passato a order-list-wrapper
	updateListPageBaseData(list: TableOrderModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	// Utility per filtrare una lista di ordini in base allo stato dell'ordine
	filterByOrderStatus(orderList: OrderStateModel[], statusList: OrderStatusEnum[]): OrderStateModel[] {
		orderList = orderList.filter(order => {
			if (order.header.status) {
				return statusList.includes(order.header.status);
			} else {
				return false;
			}
		});

		return orderList;
	}

	/**
	 * Dato uno stato dell'ordine, viene restituito l'array di stati al quale corrisponde
	 * @param orderState
	 */
	getOrderStatusGroup(orderState: string): OrderStatusEnum[] {
		if (!orderState) {
			return undefined;
		}
		const result: OrderStatusEnum[] = [];
		let relatedStates;

		switch (
			this.currentContext // TODO: add B2B case
		) {
			case ContextApplicationItemCodeEnum.AGENT:
				relatedStates = this.utilOrderService.getOrderStatusRelatedStates(OrderStatusEnum[orderState], 'AGENT');
				result.push(...relatedStates);
				break;

			case ContextApplicationItemCodeEnum.BACKOFFICE:
				relatedStates = this.utilOrderService.getOrderStatusRelatedStates(
					OrderFilterTypeEnum[orderState],
					'BACKOFFICE'
				);
				result.push(...relatedStates);
				break;

			case ContextApplicationItemCodeEnum.B2B:
				relatedStates = this.utilOrderService.getOrderStatusRelatedStates(OrderStatusEnum[orderState], 'B2B');
				result.push(...relatedStates);
				break;

			default:
				break;
		}
		return result;
	}

	createNew() {
		switch (this.currentContext) {
			case ContextApplicationItemCodeEnum.B2C:
				this.router.navigate([`${ROUTE_URL.public}/${ROUTE_URL.catalog}`]);
				break;
			case ContextApplicationItemCodeEnum.B2B:
				this.router.navigate([
					`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.user.current_permission.context_code.code}/new`
				]);
				break;
			default:
				this.router.navigate([`${PATH_URL.PRIVATE}/orders/${OrderStatusEnum.DRAFT}/${this.companyCode}/new`]);
				break;
		}
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		switch (this.currentContext) {
			case ContextApplicationItemCodeEnum.B2C:
				this.openDialogOrderDetailB2c(selectedOrder);
				break;
			// case ContextApplicationItemCodeEnum.B2B:
			// 	this.router.navigate([
			// 		`${PATH_URL.PRIVATE}/orders/${selectedOrder.header.status}/${this.user.current_permission.context_code.code}/${selectedOrder._id}/checkout`
			// 	]);
			// 	break;
			default:
				if ((selectedOrder.header as ExtraFieldOrderHeaderPouchModel)?.organization?.code_item) {
					this.router.navigate([
						`${PATH_URL.PRIVATE}/orders/${selectedOrder.header.status}/${
							(selectedOrder.header as ExtraFieldOrderHeaderPouchModel).organization.code_item
						}/${selectedOrder._id}/checkout`
					]);
				}
				break;
		}
	}

	openDialogOrderDetailB2c(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		// ??? product_list manca nell'order passato come parametro
		const order = this.orderList.find(order => {
			return order._id === selectedOrder._id;
		});
		const dialogRef = this.dialog.open(DialogOrderDetailComponent, {
			data: {
				title: 'Dettaglio ordine',
				order: order
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
	}

	/**
	 * Destroy
	 */

	ngOnDestroy() {
		// Reset degli stati
		// orderList
		this.store.dispatch(OrderListStateAction.reset());
		// Company List
		// this.store.dispatch(OrganizationListStateAction.reset());

		this.subscribeManagerService.destroy();
	}
}
