import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'mat-card-highlight-value',
  templateUrl: './mat-card-highlight-value.component.html',
  styleUrls: ['./mat-card-highlight-value.component.scss']
})
export class MatCardHighlightValueComponent implements OnInit {

  @Input() title = '';
  @Input() subtitle = '';
  @Input() value = 0;
  @Input() percentage = 0;

  constructor() {}

  ngOnInit() {
  }

}
