import { OrderListStateAction, OrderListActionEnum } from './order-list.actions';
import { on, createReducer } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { TypedAction } from '@ngrx/store/src/models';
import { OrderStateModel } from '../../model/state/order-state.model';

export namespace OrderListStateReducer {
	export const initialState: BaseStateModel<OrderStateModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(OrderListStateAction.load, state => state),
		on(OrderListStateAction.loadAll, state => state),
		on(OrderListStateAction.loadWithDetail, state => state),
		on(OrderListStateAction.update, (state, order) => order),
		on(OrderListStateAction.completed, (state, order) => ({ data: state ? state.data : null, type: order.type })),
		on(OrderListStateAction.reset, () => initialState),
		on(OrderListStateAction.error, (state, order) => ({ data: state ? state.data : null, type: order.type }))
	);

	export function reducer(
		state: BaseStateModel<OrderStateModel[]>,
		action: TypedAction<OrderListActionEnum>
	) {
		return _reducer(state, action);
	}
}
