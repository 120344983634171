import { Injectable } from '@angular/core';
import { of, from } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AgentActionEnum, AgentStateAction } from './agent.actions';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { BasePouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { Store } from '@ngrx/store';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';

@Injectable()
export class AgentEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getAgent(action.data))),
			map((user: BaseStateModel<BodyTablePouchModel>) => AgentStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(AgentStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService
	) {}

	async getAgent(data: BasePouchModel): Promise<BaseStateModel<BodyTablePouchModel>> {
		try {
			const agent = await (await this.pouchAdapterSelectorService.retrieveCurrentAdapter()).agentPouch.getAgent(
				data._id
			);
			return new BaseState(agent);
		} catch (err) {
			throw new Error(err);
		}
	}
}
