import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { UtilAddressService } from '../../service/util/util-address.service';
import { AvatarConfigClass } from '../avatar/avatar.component';

export class OrganizationHeaderConfig {
	avatar: AvatarConfigClass;
	badgeList: string[];
	informationList: {
		label: string;
		value?: string;
		icon: string;
		order: number;
	}[];
	context?: ContextApplicationItemCodeEnum;
}

@Component({
	selector: 'organization-header',
	templateUrl: './organization-header.component.html',
	styleUrls: ['./organization-header.component.scss']
})
export class OrganizationHeaderComponent implements OnInit {
	@Input() organizationHeaderConfig: OrganizationHeaderConfig;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;
	currentContext: ContextApplicationItemCodeEnum;

	constructor(public utilAddressService: UtilAddressService) {}

	ngOnInit() {
		this.currentContext = this.organizationHeaderConfig.context;
	}
}
