<breadcrumb
	[title]="'user.detail' | translate | sentencecase"
	[pathList]="['Home', 'user.name_plural' | translate | sentencecase, route.snapshot.paramMap.get('idUser')]"
	[options]="true"
	[backButton]="ROUTE_URL.private + '/' + ROUTE_URL.userManagement"
>
	<ng-container actions>
		<button mat-menu-item [disabled]="formEditEnabled" (click)="enableFormEdit()">
			<mat-icon>edit</mat-icon>
			<span>{{ 'general.edit' | translate | sentencecase }}</span>
		</button>
	</ng-container>
</breadcrumb>

<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12" *ngIf="formEditEnabled">
				<mat-card>
					<mat-toolbar class="height-auto">
						{{ 'user.is_editing' | translate | sentencecase }}
						<span class="flex-span"></span>

						<button mat-stroked-button (click)="exitEditMode()" class="mr-3" *ngIf="user.id">
							{{ 'general.cancel' | translate | sentencecase }}
						</button>
						<button mat-raised-button color="accent" type="submit" [disabled]="!form.valid">
							{{ 'general.save' | translate | sentencecase }}
						</button>
					</mat-toolbar>
				</mat-card>
			</div>

			<div class="col-md-6 col-lg-4">
				<person-card-project
					[title]="user.first_name ? user.first_name : '' + ' ' + user.last_name ? user.last_name : ''"
					[subtitle]="'general.status.' + (user.is_active ? 'active' : 'inactive') | translate | sentencecase"
					[isActiveIndicator]="user.is_active"
					[personImage]="user.avatar"
				>
					<ng-container content>
						<mat-form-field>
							<input
								matInput
								formControlName="username"
								placeholder="{{
									('user.field.username' | translate | sentencecase) +
										' (' +
										('user.field.email' | translate) +
										')'
								}}"
							/>
						</mat-form-field>
						<mat-form-field>
							<input
								matInput
								formControlName="first_name"
								placeholder="{{ 'user.field.first_name' | translate | sentencecase }}"
							/>
						</mat-form-field>
						<mat-form-field>
							<input
								matInput
								formControlName="last_name"
								placeholder="{{ 'user.field.last_name' | translate | sentencecase }}"
							/>
						</mat-form-field>
						<mat-form-field>
							<mat-select
								formControlName="language"
								placeholder="{{ 'language.name' | translate | sentencecase }}"
							>
								<mat-option *ngFor="let language of languageList" [value]="language.description_short">
									{{ 'language.' + language.description_short | translate | sentencecase }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</ng-container>

					<ng-container action>
						<button
							*ngIf="user.id"
							(click)="dialogConfirmResendEmailConfirmationMessage()"
							type="button"
							class="mb-3"
							mat-raised-button
							color="accent"
							[disabled]="formEditEnabled"
						>
							{{ 'user.resend_email_confirmation' | translate | sentencecase }}
						</button>
						<button
							*ngIf="user.id && user.is_active"
							(click)="dialogConfirmDeactivateUser()"
							type="button"
							mat-stroked-button
							color="accent"
							class="ml-3"
							[disabled]="formEditEnabled"
						>
							{{ 'user.deactivate' | translate | sentencecase }}
						</button>
					</ng-container>
				</person-card-project>
			</div>
			<div class="col-md-6 col-lg-8">
				<mat-accordion class="accordion-permission">
					<!-- Context Application -->
					<mat-expansion-panel *ngFor="let context of contextTypeAndApplicationLink">
						<mat-expansion-panel-header>
							<mat-panel-title>
								{{ utilService.formatPlaceholder(context.application_name) | uppercase }}
								<span class="flex-span"></span>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<!-- General Permissions -->
						<h2>
							<strong>{{ 'context_code.shared_permissions' | translate | sentencecase }}</strong>
						</h2>
						<p>
							{{
								'context_code.inherited_by'
									| translate: { context_code_description: context.context_code_item.description }
									| sentencecase
							}}
						</p>
						<form-permission-group-select
							[extraFormValue]="getFormFieldValue('permission')"
							[groupList]="
								returnContextApplicationPermissionGroup(
									contextApplicationItemCodeEnum[context.context_application_item_code]
								).context_permission_tree
							"
							[disabled]="!formEditEnabled"
							(extraFormValueEmit)="
								updateFormPermissionFromFormPermissionGroupSelect(
									$event,
									context.context_application_item_code
								)
							"
						>
						</form-permission-group-select>

						<!-- Associazione codici utente -->
						<mat-toolbar>
							<mat-toolbar-row class="p-0">
								{{
									'context_code.x_associated'
										| translate: { context_code: context.context_code_item.description }
										| sentencecase
								}}

								<span class="flex-span"></span>
								<td-search-box
									#contextCodeFilterInput
									[showUnderline]="true"
									[debounce]="500"
									[alwaysVisible]="false"
								>
								</td-search-box>
							</mat-toolbar-row>
						</mat-toolbar>
						<button
							*ngIf="formEditEnabled"
							(click)="dialogAssociateContextCodeItem(context)"
							type="button"
							mat-raised-button
							color="primary"
							class="mr-3 mb-3"
						>
							<mat-icon>link</mat-icon>
							{{
								'context_code.associate_x_existent'
									| translate: { context_code: context.context_code_item.description }
							}}
						</button>

						<!-- Create new -->
						<button
							*ngIf="
								formEditEnabled &&
								permissionUtilService.canEditContextCode(
									contextApplicationItemCodeEnum[context.context_application_item_code]
								)
							"
							(click)="dialogCreateContextCodeItem(context)"
							type="button"
							mat-stroked-button
							color="primary"
							class="mr-3 mb-3"
						>
							<mat-icon>add</mat-icon>
							{{
								'context_code.create_x'
									| translate: { context_code: context.context_code_item.description }
							}}
						</button>

						<!-- delete all -->
						<button
							*ngIf="
								formEditEnabled &&
								permissionUtilService.getContextItem(
									getFormFieldValue('context_application_list'),
									context.context_application_item_code
								) &&
								permissionUtilService.getContextItem(
									getFormFieldValue('context_application_list'),
									context.context_application_item_code
								).context_code_list &&
								permissionUtilService.getContextItem(
									getFormFieldValue('context_application_list'),
									context.context_application_item_code
								).context_code_list.length > 0
							"
							type="button"
							mat-button
							color="warn"
							class="mr-3 mb-3"
							(click)="dialogConfirmDeleteContextCodeList(context.context_application_item_code)"
						>
							<mat-icon>link_off</mat-icon>
							{{
								'context_code.disassociate_all_x'
									| translate: { context_code: context.context_code_item.description }
							}}
						</button>

						<td-data-table
							#dataTable
							*ngIf="
								permissionUtilService.getContextItem(
									getFormFieldValue('context_application_list'),
									context.context_application_item_code
								) &&
									permissionUtilService.getContextItem(
										getFormFieldValue('context_application_list'),
										context.context_application_item_code
									).context_code_list &&
									permissionUtilService.getContextItem(
										getFormFieldValue('context_application_list'),
										context.context_application_item_code
									).context_code_list.length > 0;
								else noData
							"
							[data]="
								permissionUtilService.getContextItem(
									getFormFieldValue('context_application_list'),
									context.context_application_item_code
								).context_code_list
									| filterByString
										: {
												propertyListName: ['code', 'code_erp', 'description'],
												value: contextCodeFilterInput.value
										  }
							"
							[columns]="permissionContextListColumnService.columns"
							[clickable]="formEditEnabled"
							(rowClick)="dialogChangePermissionContextCodeItem(context, $event.row)"
						>
							<ng-template tdDataTableTemplate="permission" let-value="value">
								<ng-container *ngFor="let item of value; let last = last">
									{{
										permissionUtilService.returnPermissionFullObject(
											permissionAuxiliaryTableState.permission_list,
											item
										).key
									}}
									{{ !last ? ', ' : '' }}
								</ng-container>
							</ng-template>
							<ng-template tdDataTableTemplate="action" let-row="row">
								<button
									*ngIf="formEditEnabled"
									mat-icon-button
									stopPropagation
									(click)="
										dialogConfirmDeleteContextCodeItem(
											context.context_application_item_code,
											row.code
										)
									"
									type="button"
									color="warn"
								>
									<mat-icon>link_off</mat-icon>
								</button>
							</ng-template>
							<ng-template tdDataTableTemplate="code" let-row="row">
								{{ row.code }}
							</ng-template>
						</td-data-table>
						<ng-template #noData>
							<p class="placeholder">{{ 'context_code.no_items' | translate | sentencecase }}</p>
						</ng-template>
					</mat-expansion-panel>
				</mat-accordion>
			</div>

			<div class="col-12" *ngIf="formEditEnabled">
				<mat-card>
					<mat-toolbar class="height-auto">
						{{ 'user.is_editing' | translate | sentencecase }}
						<span class="flex-span"></span>

						<button mat-stroked-button (click)="exitEditMode()" class="mr-3" *ngIf="user.id">
							{{ 'general.cancel' | translate | sentencecase }}
						</button>
						<button mat-raised-button color="accent" type="submit" [disabled]="!form.valid">
							{{ 'general.save' | translate | sentencecase }}
						</button>
					</mat-toolbar>
				</mat-card>
			</div>
		</div>
	</div>
</form>
