import { Component, Inject } from '@angular/core';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpportunityStatusCodeEnum, OpportunityTypeEnum } from '../../../model/state/opportunity-state.model';

@Component({
	selector: 'dialog-close-opportunity',
	templateUrl: './dialog-close-opportunity.component.html',
	styleUrls: ['./dialog-close-opportunity.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogCloseOpportunityComponent {
	public opportunityTypeEnum = OpportunityTypeEnum;
	public opportunityStatusCodeEnum = OpportunityStatusCodeEnum;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { opportunityType: OpportunityTypeEnum },
		private dialogRef: MatDialogRef<DialogCloseOpportunityComponent>
	) {
		console.log(data.opportunityType);
	}

	closeDialog(status: OpportunityStatusCodeEnum) {
		this.dialogRef.close(status);
	}
}
