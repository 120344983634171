import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	LocalListHandlerBaseModel,
	Pagination,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { OrderPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, skipWhile, take, filter, tap } from 'rxjs/operators';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel } from '../../../model/state/order-state.model';
import { StatisticsOrders } from '../../../model/statistics-orders.model';
import { TableOrderModel } from '../../../model/table/table-order.model';
import { PATH_URL } from '../../../router/route-naming';
import { OrderAverageChartConfigService } from '../../../service/chart-structure/implementation/order-average-chart.service';
import { OrderQuantityChartConfigService } from '../../../service/chart-structure/implementation/order-quantity-chart.service';
import { OrderTrendChartConfigService } from '../../../service/chart-structure/implementation/order-trend-chart.service';
import { OrderListFilterModel } from '../../../service/pouch-db/filter/order-filter.model';
import { OrderListColumnService } from '../../../service/td-data-table/implementation/order-list.service';
import { UtilCompanyService } from '../../../service/util/util-company.service';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { StateFeature } from '../../../state';
import { OrderListActionEnum, OrderListStateAction } from '../../../state/order-list/order-list.actions';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { StatisticsOrganization } from '../../../model/statistics-organization.model';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { StatisticsOrganizationStateAction } from '../../../state/statistics-organization/statistics-organization.action';
import { AppUtilService } from '../../../service/util/app-util.service';
import { OrderProductsChartConfigService } from '../../../service/chart-structure/implementation/order-products-chart.service';
import { OrderProductsTableConfigService } from '../../../service/chart-structure/implementation/order-products-table.service';
import { MatSelectChange } from '@angular/material/select';
import { chartTimeRange7Days } from '../../../constants/chart-time-range.constants';
import { OrderCategoriesChartConfigService } from '../../../service/chart-structure/implementation/order-categories-chart.service';
import { OrderCategoriesTableConfigService } from '../../../service/chart-structure/implementation/order-categories-table.service';
import { ConfigurationCustomer } from '../../../constants/configuration-customer';

@Component({
	selector: 'app-dashboard-b2b',
	templateUrl: './dashboard-b2b.component.html',
	styleUrls: ['./dashboard-b2b.component.scss'],
	providers: [
		SubscribeManagerService,
		OrderListColumnService,
		OrderTrendChartConfigService,
		OrderAverageChartConfigService,
		OrderQuantityChartConfigService,
		OrderProductsChartConfigService,
		OrderProductsTableConfigService,
		OrderCategoriesChartConfigService,
		OrderCategoriesTableConfigService
	]
})
export class DashboardB2bComponent implements OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
		StateFeature.getOrderListState
	);

	statisticsOrders$: Observable<BaseStateModel<StatisticsOrders>> = this.store.select(
		StateFeature.getStatisticsOrders
	);
	statisticsOrders: StatisticsOrders;

	// Per la visualizzazione della data di ultimo aggiornamento delle statistiche
	timeRange = chartTimeRange7Days;
	dateUpdatedStatistics: number;

	statisticsOrganization$: Observable<BaseStateModel<StatisticsOrganization>> = this.store.select(
		StateFeature.getStatisticsOrganization
	);
	statisticsOrganization: StatisticsOrganization;

	// TODO: deprecare
	orderFilter: BaseStateModel<OrderStateModel[], OrderListFilterModel>;
	orderPagination: Pagination = { page_current: 1, page_size: 10 };
	orderSort: { [key: string]: 'asc' | 'desc' }[] = [
		{
			'header.date': 'desc'
		},
		{
			'header.status': 'desc'
		}
	];

	listPageBaseLastOrders = <LocalListHandlerBaseModel<TableOrderModel>>{
		pagination: {
			pageSize: 5
		}
	};

	// Configurazione dashboard-card senza chart
	cardDashboardConfigCurrentOrdersConfirmed: CardDashboardConfig = {
		title: 'order.status.CONFIRMED',
		subtitle: 'chart.current_orders.confirmed',
		icon: 'assignment',
		helpText: 'chart.current_orders.helptext'
	};
	cardDashboardConfigCurrentOrdersConsolidated: CardDashboardConfig = {
		title: 'order.status.CONSOLIDATED',
		subtitle: 'chart.current_orders.consolidated',
		icon: 'assignment',
		helpText: 'chart.current_orders.helptext'
	};
	cardDashboardConfigCurrentOrdersPartiallyFulfilled: CardDashboardConfig = {
		title: 'order.status.PARTIALLY_FULFILLED',
		subtitle: 'chart.current_orders.partially_fulfilled',
		icon: 'assignment',
		helpText: 'chart.current_orders.helptext'
	};
	cardDashboardConfigOrdersRecent: CardDashboardConfig = {
		title: 'chart.recent_orders.title',
		subtitle: 'chart.recent_orders.subtitle',
		subtitleTranslateParams: { amount: '5' },
		helpText: 'chart.current_orders.helptext',
		icon: 'list_alt'
	};

	columns: ITdDataTableColumn[] = [];

	constructor(
		private store: Store<any>,
		private router: Router,
		private subscribeManagerService: SubscribeManagerService,
		public orderListColumnService: OrderListColumnService,
		private utilOrderService: UtilOrderService,
		public utilCompanyService: UtilCompanyService,
		public utilService: AppUtilService,
		// Grafici
		public orderTrendChartConfigService: OrderTrendChartConfigService,
		public orderAverageChartConfigService: OrderAverageChartConfigService,
		public orderQuantityChartConfigService: OrderQuantityChartConfigService,
		public orderProductsChartConfigService: OrderProductsChartConfigService,
		public orderProductsTableConfigService: OrderProductsTableConfigService,
		public orderCategoriesChartConfigService: OrderCategoriesChartConfigService,
		public orderCategoriesTableConfigService: OrderCategoriesTableConfigService
	) {
		this.columns = ConfigurationCustomer.Order.columnList(
			this.orderListColumnService.columns,
			ContextApplicationItemCodeEnum.B2B
		);

		this.user$
			.pipe(
				skipWhile(
					(res: BaseState<UserDetailModel>) =>
						!(res && res.data && res.data.current_permission && res.data.current_permission.context_code)
				),
				take(1)
			)
			.subscribe((res: BaseState<UserDetailModel>) => {
				this.user = res.data;
				this.orderFilter = new BaseState<OrderStateModel[], OrderListFilterModel>(null, {
					appliedFilter: {
						user_code: this.user.current_permission.context_code.code
					},
					pagination: this.orderPagination,
					// TODO: l'indicazione del sort viene attualmente ingnorata e gestita univocamente presso order-list-wrapper
					sort: this.orderSort
				});
				this.store.dispatch(OrderListStateAction.loadAll());
				this.subscribeManagerService.populate(this.retrieveOrderList().subscribe(), 'order-list');
			});

		this.statisticsOrders$
			.pipe(
				filter((state: BaseStateModel<StatisticsOrders>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				if (state && state.data) {
					this.statisticsOrders = state.data;
				}
			});

		// Recupero la data di ultimo aggiornamento delle statistiche
		this.statisticsOrganization$
			.pipe(
				filter((state: BaseStateModel<StatisticsOrganization>) => !!(state && state.data)),
				take(1)
			)
			.subscribe(state => {
				this.statisticsOrganization = state.data;
				this.dateUpdatedStatistics = state.data.date_update;
			});

		// Remove detail link from cards
		delete orderAverageChartConfigService.cardDashboardConfigOrdersAverage.detailLink;
		delete orderQuantityChartConfigService.cardDashboardConfigOrdersQuantity.detailLink;
	}

	retrieveOrderList(): Observable<BaseStateModel<OrderStateModel[]>> {
		return this.orderList$.pipe(
			filter(
				(orderList: BaseStateModel<OrderStateModel[]>) =>
					this.user.current_permission.context_code.code &&
					orderList &&
					orderList.type !== OrderListActionEnum.LOAD
			),
			map((orderList: BaseStateModel<OrderStateModel[]>) => {
				if (orderList.type === OrderListActionEnum.ERROR) {
					throw new Error(OrderListActionEnum.ERROR);
				}
				this.listPageBaseLastOrders.data = this.utilOrderService
					.getTableOrderList(_.cloneDeep(orderList.data))
					.filter(order => order.header.status !== OrderStatusEnum.DELETED);
				// il setter non scatta pur presentando i nuovi valori passati da getTableOrderList
				// senza il riassegnamento dell'intero oggetto.
				this.listPageBaseLastOrders = Object.assign({}, this.listPageBaseLastOrders);
				return orderList;
			})
		);
	}

	getOrderTypeCount(type: string) {
		if (!this.statisticsOrganization?.data) {
			return 0;
		}

		const typedOrders = this.utilService.findLeaf(this.statisticsOrganization.data, `orders.status.${type}`);
		if (typedOrders) {
			return typedOrders;
		} else {
			return 0;
		}
	}

	goToOrderDetail(selectedOrder: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		this.router.navigate([
			`${PATH_URL.PRIVATE}/orders/${selectedOrder.header.status}/${
				(selectedOrder.header as ExtraFieldOrderHeaderPouchModel).organization?.code_item
			}/${selectedOrder._id}/checkout`
		]);
	}

	setTimeRangeProducts(selectedTimeRange: MatSelectChange) {
		const charts = [this.orderProductsChartConfigService];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [this.orderProductsTableConfigService];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	setTimeRangeCategories(selectedTimeRange: MatSelectChange) {
		const charts = [this.orderCategoriesChartConfigService];
		charts.forEach(chart => chart.populateChart(selectedTimeRange.value));

		const tables = [this.orderCategoriesTableConfigService];
		tables.forEach(table => table.populateTable(selectedTimeRange.value));
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrderListStateAction.reset());
		// this.store.dispatch(CompanyAccountBalanceAction.reset());
	}
}
