import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';

@Component({
	selector: 'dialog-new-contact',
	templateUrl: './dialog-new-contact.component.html',
	styleUrls: ['./dialog-new-contact.component.scss']
})
export class DialogNewContactComponent implements OnInit {
	form: FormGroup;
	organization: OrganizationStateModel;
	isUpdating: boolean;
	isDisabled: boolean = false;

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<DialogNewContactComponent>,
		@Inject(MAT_DIALOG_DATA) public data
	) {
		console.log(data);
		this.organization = data['organization'];
		this.isUpdating = data['isUpdating'];
		this.isDisabled = data['isDisabled'];

		this.createForm();
	}

	ngOnInit() {
		if (this.data && this.data.addressData) {
			this.updateFormFieldValue();
		}
	}

	createForm() {
		this.form = this.fb.group({
			name: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
			surname: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
			email: [{ value: null, disabled: this.isDisabled }, [Validators.required, Validators.email]],
			phone: [{ value: null, disabled: this.isDisabled }],
			area: [{ value: null, disabled: this.isDisabled }],
			ruoloAziendale: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
			toggleConfermaOrdine: [{ value: false, disabled: this.isDisabled }]
		});
	}

	updateFormFieldValue() {
		this.form.patchValue({
			name: this.data.addressData.first_name ? this.data.addressData.first_name : null,
			surname: this.data.addressData.last_name ? this.data.addressData.last_name : null,
			email: this.data.addressData.email ? this.data.addressData.email : null,
			phone: this.data.addressData.phone ? this.data.addressData.phone : null,
			area: this.data.addressData.area || null,
			ruoloAziendale: this.data.addressData.role ? this.data.addressData.role : null,
			toggleConfermaOrdine: this.data.addressData.has_notification ? this.data.addressData.has_notification : null
		});
	}

	async createNewContact() {
		if (this.form.valid) {
			const formData = this.form.getRawValue();

			// full name
			let fullName = formData['name'];
			if (formData['surname']) {
				fullName = fullName + ' ' + formData['surname'];
			}

			const contact_to_save: ContactPouchModel = {
				code_item: this.data && this.data.addressData ? this.data.addressData.code_item : null,
				_id: this.data && this.data.addressData ? this.data.addressData._id : null,
				full_name: fullName,
				first_name: formData['name'],
				last_name: formData['surname'],
				role: formData['ruoloAziendale'],
				email: formData['email'],
				phone: formData['phone'],
				area: formData['area'],
				date_creation: new Date().getTime(),
				type: 'contact',
				valid: true,
				organization_list: [],
				has_notification: formData['toggleConfermaOrdine'] || false
			};

			// organization list
			if (this.organization) {
				(contact_to_save.organization_list as OrganizationStateModel[]).push({
					code_item: this.organization.code_item,
					code_erp: this.organization.code_erp,
					business_name: this.organization.business_name,
					tax_data: { vat_number: this.organization.tax_data?.vat_number || null },
					valid: true
				});
			}

			// close dialog
			if (contact_to_save) {
				this.dialogRef.close(contact_to_save);
			}
		}
	}
}
