import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';
import { from } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { PouchAdapterSelectorService } from '../../service/pouch-db/pouch-adapter-selector.service';
import { CouchDocumentType } from '../../constants/context-state.map';
import { StatisticsDetailOrders } from '../../model/statistics-detail-orders.model';
import { StatisticsDetailOrdersActionEnum, StatisticsDetailOrdersStateAction } from './statistics-detail-orders.action';
import { StateFeature } from '..';
import { DateMomentService } from '@saep-ict/angular-core';
import { StatisticsAgentBaseSerieOrder } from '../../model/statistics-agent.model';

@Injectable()
export class StatisticsDetailOrdersEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StatisticsDetailOrdersActionEnum.LOAD),
			mergeMap((action: BaseStateModel<StatisticsDetailOrders>) => {
				return from(this.getStatisticsDetailOrders(action));
			}),
			mergeMap((action: BaseStateModel<StatisticsDetailOrders>) => from(this.sortStatisticsDetailOrders(action))),
			mergeMap((action: BaseStateModel<StatisticsDetailOrders>) =>
				from(this.convertDateStatisticsDetailOrders(action))
			),
			map((action: BaseStateModel<StatisticsDetailOrders>) => StatisticsDetailOrdersStateAction.update(action)),
			catchError((error, caught) => {
				this.store.dispatch(StatisticsDetailOrdersStateAction.error(null));
				return caught;
			})
		)
	);

	// Chiavi di Order
	keyOrder = ['to_authorize', 'processing', 'consolidated', 'average', 'count'];

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchAdapterSelectorService: PouchAdapterSelectorService,
		private dateMomentService: DateMomentService
	) {}

	async getStatisticsDetailOrders(
		action: BaseStateModel<StatisticsDetailOrders>
	): Promise<BaseStateModel<StatisticsDetailOrders>> {
		let currentContext;
		await this.store
			.select(StateFeature.getUserState)
			.pipe(take(1))
			.subscribe(res => {
				currentContext = res ? res.data.current_permission.context_application : null;
			});
		await (
			await this.pouchAdapterSelectorService.retrieveCurrentAdapter(CouchDocumentType.STATISTICS)
		).statisticsPouch
			.getStatisticsDetailOrders(currentContext)
			.then(res => {
				action.data = res;
			})
			.catch(err => console.log(err));
		return action;
	}

	async sortStatisticsDetailOrders(
		action: BaseStateModel<StatisticsDetailOrders>
	): Promise<BaseStateModel<StatisticsDetailOrders>> {
		this.keyOrder.forEach(key => {
			if (action.data.order_trend[key]) {
				action.data.order_trend[key].sort((a, b) => a.date - b.date);
			}
		});
		return action;
	}

	async convertDateStatisticsDetailOrders(
		action: BaseStateModel<StatisticsDetailOrders>
	): Promise<BaseStateModel<StatisticsDetailOrders>> {
		this.keyOrder.forEach(key => {
			if (action.data.order_trend[key]) {
				action.data.order_trend[key].map((element: StatisticsAgentBaseSerieOrder) => {
					element.date = this.dateMomentService.convertMilllisStringToStringDate(element.date.toString());
				});
			}
		});

		return action;
	}
}
