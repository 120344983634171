<h2 mat-dialog-title>{{ product.code }} | {{ product['description'] }}</h2>

<mat-dialog-content>
	<mat-tab-group mat-align-tabs="start">
		<ng-container *ngFor="let drawing of drawings">
			<mat-tab [label]="drawing.label + ' (' + drawing.data.length + ') '">
				<td-data-table
					*ngIf="drawing.data.length; else noData"
					[data]="drawing.data"
					[columns]="drawingColumnService._columns"
					[sortable]="true"
				>
					<ng-template tdDataTableTemplate="dimensione_allegato_byte" let-value="value">{{
						bytesToSize(value)
					}}</ng-template>
					<ng-template tdDataTableTemplate="action" let-row="row"
						><button
							mat-icon-button
							matTooltip="{{ 'article.drawing.download' | translate | sentencecase }}"
							matTooltipPosition="left"
							(click)="downloadDrawing(row)"
						>
							<mat-icon>file_download</mat-icon>
						</button></ng-template
					>
				</td-data-table>
				<ng-template #noData>
					<p class="placeholder">
						{{ 'general.no_results' | translate | sentencecase }}
					</p>
				</ng-template>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions> </mat-dialog-actions>
