import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

// model
import { ArticleCategory } from '../../model/article.model';
import { Category } from '../../model/category-list.model';

// widget & utility
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubscribeManagerService } from '@saep-ict/angular-core';
import * as _ from 'lodash';
import { UtilCategoryListService } from '../../service/util/util-category-list.service';

@Component({
	selector: 'nested-category-list',
	templateUrl: './nested-category-list.component.html',
	styleUrls: ['./nested-category-list.component.scss'],
	providers: [SubscribeManagerService]
})
export class NestedCategoryListComponent implements OnInit, OnDestroy {
	_list: Category<ArticleCategory>[] = [];
	categorySelected = <Category<ArticleCategory>>{};

	private categorySelectedChangeDebounce = new Subject();

	@Input('list') set list(l: Category<ArticleCategory>[]) {
		if (l) {
			this._list = _.cloneDeep(l);
			this.categorySelected.category_list = _.cloneDeep(l);
		}
	}

	@Input() debounceTime = 500;

	@Output() categorySelectedChange = new EventEmitter<Category<ArticleCategory>>();

	constructor(
		private subscribeManagerService: SubscribeManagerService,
		private utilCategoryListService: UtilCategoryListService
	) {}

	ngOnInit() {
		this.subscribeManagerService.populate(
			this.categorySelectedChangeDebounce
				.pipe(debounceTime(this.debounceTime))
				.subscribe((e: Category<ArticleCategory>) => this.categorySelectedChange.emit(e)),
			'change-category-debounce'
		);
	}
	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	async selectCategory(id?: string) {
		this.categorySelected = id
			? await this.utilCategoryListService.returnCategoryById(id, this._list)
			: <Category<ArticleCategory>>{ category_list: this._list };

		this.categorySelectedChangeDebounce.next(this.categorySelected);
	}
}
