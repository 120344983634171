import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// store
import { Store } from '@ngrx/store';
import {
	BaseStateModel,
	SubscribeManagerService,
	MediaReplayService,
	LocalListHandlerBase,
	LocalListHandlerBaseModel
} from '@saep-ict/angular-core';
import { Observable } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { OrganizationListStateAction } from '../../../state/common/organization-list/organization-list.actions';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';
// model
import { OrganizationColumnService } from '../../../service/td-data-table/implementation/organization-list.service';
// widet & utility
import { UtilService } from '@saep-ict/angular-core';
import { StateFeature } from '../../../state';

@Component({
	selector: 'dialog-select-organization',
	templateUrl: './dialog-select-organization.component.html',
	styleUrls: ['./dialog-select-organization.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogSelectOrganizationComponent extends LocalListHandlerBase implements OnDestroy {
	localListHandlerData = <LocalListHandlerBaseModel<OrganizationStateModel>>{
		data: [],
		filters: {
			localSearchText: {
				value: null,
				key_list: ['business_name']
			}
		},
		pagination: {
			pageSize: 5
		},
		sort: {
			name: 'business_name',
			order: 'ASC'
		}
	};

	form: FormGroup;
	organizationList$: Observable<BaseStateModel<OrganizationStateModel[]>> = this.store.select(
		StateFeature.getOrganizationListState
	);

	constructor(
		// @Inject(MAT_DIALOG_DATA) public data: ContextCodeItemLink,
		private store: Store<any>,
		public mediaReplayService: MediaReplayService,
		public dialogRef: MatDialogRef<DialogSelectOrganizationComponent>,
		public utilService: UtilService,
		public organizationListColumnService: OrganizationColumnService,
		private subscribeManagerService: SubscribeManagerService
	) {
		super(utilService);
		this.setVisibleTableColumn();
		this.store.dispatch(OrganizationListStateAction.loadAll());
		this.subscribeManagerService.populate(this.subscribeOrganizationList().subscribe(), 'organization-list');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(OrganizationListStateAction.reset());
	}

	// subscribe
	subscribeOrganizationList(): Observable<BaseStateModel<OrganizationStateModel[]>> {
		return this.organizationList$.pipe(
			filter(state => !!(state && state.data)),
			map((state: BaseStateModel<OrganizationStateModel[]>) => {
				this.localListHandlerData.data = state.data;
				this.localListHandlerApplyFilter();
				return state;
			}),
			catchError((error, caught) => {
				console.log(error);
				this.localListHandlerData.data = [];
				this.localListHandlerApplyFilter();
				return caught;
			})
		);
	}

	// widget & utility
	selectOrganization(organization: OrganizationStateModel) {
		this.dialogRef.close(organization);
	}

	setVisibleTableColumn() {
		this.organizationListColumnService.columns.map(col => {
			col.hidden = ['status', 'address', 'vat_number_or_tax_code'].includes(col.name);
		});
	}
}
