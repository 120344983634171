import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DrawingColumnService } from '../../../service/td-data-table/implementation/drawing.service';
import { OrderProductModel } from '../../../model/state/article-list-state.model';
import { ProductService } from '../../../service/rest/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RestBaseMessageError } from '@saep-ict/angular-core';
import { DrawingEnum } from '../../../enum/drawing.enum';
import { OrderStateModel } from '../../../model/state/order-state.model';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { DatePipe } from '@angular/common';
import { UtilEventTrackingService } from '../../../service/util/util-event-tracking.service';

@Component({
	selector: 'dialog-drawing',
	templateUrl: './dialog-drawing.component.html',
	styleUrls: ['./dialog-drawing.component.scss']
})
export class DialogDrawingComponent implements OnInit {
	product: OrderProductModel = this.data.product;
	order: OrderStateModel = this.data.order;
	drawings = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private productService: ProductService,
		private snackbar: MatSnackBar,
		private translateService: TranslateService,
		public drawingColumnService: DrawingColumnService,
		private utilOrderService: UtilOrderService,
		private datePipe: DatePipe,
		private utilEventTrackingService: UtilEventTrackingService
	) {}

	ngOnInit(): void {
		this.createDrawingTree();
	}

	createDrawingTree() {
		this.drawings.push({
			label: this.translateService.instant('article.drawing.technical').toUpperCase(),
			data:
				this.product.articleDocumentLink?.filter(
					link => link['classe_documentale'] === DrawingEnum.TECHNICAL
				) || []
		});
		this.drawings.push({
			label: this.translateService.instant('article.drawing.customer').toUpperCase(),
			data:
				this.product.articleDocumentLink?.filter(link => link['classe_documentale'] === DrawingEnum.CLIENT) ||
				[]
		});
	}

	bytesToSize(bytes: number) {
		const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let index = 0;
		while (bytes >= 1024 && ++index) {
			bytes = bytes / 1024;
		}
		return bytes.toFixed(bytes < 10 && index > 0 ? 1 : 0) + ' ' + units[index];
	}

	downloadDrawing(product) {
		this.productService
			.downloadProductDrawing({ id: product.id_link })
			.then(res => {
				if (product.classe_documentale === DrawingEnum.TECHNICAL) {
					this.utilEventTrackingService.pushEvent({
						event: 'scaricaDisegnoTecnico',
						ordine:
							this.order.header.organization.business_name +
							(this.order?.csuite?.order_so_client_ref
								? ` - ${this.order.csuite.order_so_client_ref}`
								: '') +
							' - ' +
							this.utilOrderService.formatOdvCode(this.order) +
							' - ' +
							(this.order.header.date ? this.datePipe.transform(this.order.header.date) : ''),
						descrizione_ordine: `${this.product.code} - ${this.product.description}`
					});
				}
				this.downloadBase64(
					res.data,
					product.estensione_allegato,
					`${product.titolo_allegato}.${product.estensione_allegato}`
				);
			})
			.catch((err: RestBaseMessageError) => {
				console.error(err.body);
				this.snackbar.open(
					'Errore durante il download del file.',
					this.translateService.instant('general.close').toUpperCase(),
					{
						duration: 3000
					}
				);
			});
	}

	downloadBase64(base64: string, fileType: string, fileName: string) {
		base64 = atob(base64);
		// TODO: atob is deprecated, test and substitute with following code
		// const buffer = Buffer.from(base64, 'base64');
		// base64 = buffer.toString('base64');
		const byteArrays = [];
		for (let offset = 0; offset < base64.length; offset += 512) {
			const slice = base64.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		const blob = new Blob(byteArrays, { type: fileType });
		const data = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = data;
		link.download = fileName;
		link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
		setTimeout(() => {
			window.URL.revokeObjectURL(data);
			link.remove();
		}, 100);
	}
}
