import { Injectable } from '@angular/core';

// model
import { BasePouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BackofficeUserListActionEnum, BackofficeUserListStateAction } from './backoffice-user-list.actions';

// widget & utility
import { from } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { StoreUtilService } from '../../../../service/util/store-util.service';
import { UtilPouchService } from '../../../../service/util/util-pouch.service';
import { ContextCodeFilterModel } from '../../../../service/pouch-db/filter/context-code-filter.model';
import { BaseStateModel, BaseState } from '@saep-ict/angular-core';
import { CouchDocumentType } from '../../../../constants/context-state.map';
import { LAST_CHARACTER } from '../../../../constants/pouchdb.constants';

@Injectable()
export class BackofficeUserListEffects {
	loadItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeUserListActionEnum.LOAD_ITEM),
			mergeMap((action: BaseStateModel<BasePouchModel>) =>
				from(this.storeUtilService.getCouchDetailAndReturnInListState<BodyTablePouchCustomModel>(action))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) =>
				BackofficeUserListStateAction.updateItem(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeUserListStateAction.error(null));
				return caught;
			})
		)
	);

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeUserListActionEnum.LOAD_LIST),
			mergeMap(() => {
				const opts = {
					startkey: 'backoffice',
					endkey: 'backoffice_' + LAST_CHARACTER,
					include_docs: true
				};
				return from(
					this.utilPouchService.allDocs<BodyTablePouchCustomModel>(opts, CouchDocumentType.BACKOFFICE)
				);
			}),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) =>
				BackofficeUserListStateAction.updateList(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeUserListStateAction.error(null));
				return caught;
			})
		)
	);

	createItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeUserListActionEnum.CREATE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(
					this.storeUtilService.saveCouchDetailAndReturnInDetailState<BodyTablePouchCustomModel>(
						action,
						CouchDocumentType.BACKOFFICE
					)
				)
			),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.utilPouchService.saveCodeItem(action, CouchDocumentType.BACKOFFICE))
			),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				BackofficeUserListStateAction.createItemSuccess(new BaseState([action.data]))
			),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeUserListStateAction.error(null));
				return caught;
			})
		)
	);

	deleteItem$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeUserListActionEnum.DELETE_ITEM),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) =>
				from(this.utilPouchService.deleteAnyDocument(action.data))
			),
			map((action: BodyTablePouchCustomModel) =>
				BackofficeUserListStateAction.deleteItemSuccess(new BaseState(null, null))
			),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeUserListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private utilPouchService: UtilPouchService,
		private storeUtilService: StoreUtilService
	) {}
}
