import { ContactPouch } from './../../../service/pouch-db/spin8/pouch-function/contact.pouch';
import { ContactService } from './../../../service/rest/contact.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	BaseState,
	BaseStateModel,
	ContextApplicationItemCodeEnum,
	DialogConfirmComponent,
	LoaderService,
	LocalListHandlerBaseModel,
	SubscribeManagerService,
	UserDetailModel
} from '@saep-ict/angular-core';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { ContactColumnService } from '../../../service/td-data-table/implementation/contact.service';
import { UtilCompanyService } from '../../../service/util/util-company.service';
import { StateFeature } from '../../../state';
import { ContactActionEnum, ContactStateAction } from '../../../state/contact/contact.actions';
import { DialogNewContactComponent } from '../../../widget/dialog/dialog-new-contact/dialog-new-contact.component';
import { CustomerConfigurationCompany } from '../../../constants/configuration-customer/structure.constants';
import { OrganizationStateModel } from '../../../model/state/organization-state.model';
import { OrganizationStateAction } from '../../../state/organization/organization.actions';

@Component({
	selector: 'contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
	providers: [SubscribeManagerService, LoaderService]
})
export class ContactComponent implements OnInit, OnDestroy {
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	contact$: Observable<BaseStateModel<ListDataPouchModel<ContactPouchModel>>> = this.store.select(
		StateFeature.getContact
	);
	contacts: ContactPouchModel[];

	organization$: Observable<BaseStateModel<OrganizationStateModel>> = this.store.select(
		StateFeature.getOrganizationState
	);
	organization = <OrganizationStateModel>{ code: this.activatedRoute.parent.snapshot.paramMap.get('idOrganization') };

	columns = this.contactColumnService.columns;

	currentContext: ContextApplicationItemCodeEnum;

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	public listPageBaseData = <LocalListHandlerBaseModel<ContactPouchModel>>{
		pagination: {
			pageSize: 10
		},
		filters: {
			localSearchText: {
				value: null,
				key_list: ['full_name']
			}
		},
		sort: {
			name: 'full_name',
			order: 'ASC'
		}
	};
	pageContext: string;

	// template
	canAddNew: boolean;

	constructor(
		private store: Store<any>,
		private dialog: MatDialog,
		public contactColumnService: ContactColumnService,
		public activatedRoute: ActivatedRoute,
		private subscribeManagerService: SubscribeManagerService,
		private translate: TranslateService,
		public utilCompanyService: UtilCompanyService,
		private loader: LoaderService
	) {
		// User
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res.data;
			this.currentContext = this.user.current_permission.context_application;
			this.canAddNew = this.getCanAddNew();
		});

		if (this.organization.code || this.currentContext === ContextApplicationItemCodeEnum.B2C) {
			// CONTESTO: AZIENDA
			this.subscribeOrganization();

			// Nasconde le colonne della tabella
			switch (this.currentContext) {
				case ContextApplicationItemCodeEnum.B2B:
					this.columns.map(col => {
						col.hidden = ['organization_details', 'order_confirm_email', 'actions'].includes(col.name);
					});
					break;
				default:
					this.columns.map(col => {
						col.hidden = ['organization_details'].includes(col.name);
					});
					break;
			}
		} else {
			// CONTESTO: LISTA TRASVERSALE
			this.store.dispatch(ContactStateAction.loadAll());
			this.subscribeManagerService.populate(this.subscribeContactList().subscribe(), 'subscribeContactList');
			// Nascondo le colonne della tabella
			this.columns.map(col => {
				col.hidden = ['actions'].includes(col.name);
			});
		}
	}

	ngOnInit() {
		this.activatedRoute.parent.url.pipe(take(1)).subscribe(url => {
			this.pageContext = url[0].path;
		});
	}

	ngOnDestroy() {
		if (!this.organization.code) {
			this.store.dispatch(ContactStateAction.reset());
		}
		this.subscribeManagerService.destroy();
	}

	/**
	 * Subscription
	 */

	subscribeOrganization() {
		this.organization$.pipe(filter(res => !!res)).subscribe(res => {
			this.organization = res.data;
			// TODO - aggiornare il modello dati
			this.applyPermanentFilters(this.organization.contact_list);
		});
	}

	subscribeContactList() {
		return this.contact$.pipe(
			filter((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => !!state),
			map((state: BaseStateModel<ListDataPouchModel<ContactPouchModel>>) => {
				switch (state.type) {
					case ContactActionEnum.UPDATE:
						this.applyPermanentFilters(state.data.docs);
						break;

					case ContactActionEnum.ERROR:
						this.updateListPageBaseData([]);
						throw new Error(ContactActionEnum.ERROR);
						break;

					default:
						break;
				}

				return state;
			})
		);
	}

	applyPermanentFilters(contactList: ContactPouchModel[] = []) {
		// TODO - Introdurre filtri se servono
		// switch (this.user.current_context) {
		// 	case ContextApplicationItemCodeEnum.AGENT: {
		// 		break;
		// 	}
		// 	case ContextApplicationItemCodeEnum.B2B: {
		// 		break;
		// 	}
		// 	case ContextApplicationItemCodeEnum.BACKOFFICE: {
		// 		break;
		// 	}
		// }

		this.contacts = this.mapContactList(contactList);
		this.updateListPageBaseData(this.contacts);
	}

	mapContactList(list: ContactPouchModel[]): ContactPouchModel[] {
		// Mappare per avere le info corrette da deprecare con un modello dati corretto
		list.map(contact => {
			if (!contact.full_name) {
				contact.full_name = contact['nome_completo'];
			}
			contact.has_notification = CustomerConfigurationCompany.contact.can_activate_email_order_receipt
				? this.setContactNotification(contact)
				: false;
			status = CustomerConfigurationCompany.contact.send_email_confirmation ? this.setStatus(contact) : null;
		});

		return list.filter(contact => contact.valid);
	}

	setContactNotification(contact: ContactPouchModel): boolean {
		if (!contact.has_notification) {
			return false;
		} else {
			return true;
		}
	}

	setStatus(contact: ContactPouchModel): 'confermato' | 'validazione in corso' | 'email non confermata' {
		let res: 'confermato' | 'validazione in corso' | 'email non confermata';
		if (contact.valid) {
			res = 'confermato';
		} else {
			const date = new Date(contact.date_creation);
			const date_plus_month = new Date(date.setMonth(date.getMonth() + 1));
			const date_commparison = new Date().getTime() < date_plus_month.getTime();
			if (date_commparison) {
				res = 'validazione in corso';
			} else {
				res = 'email non confermata';
			}
		}
		return res;
	}

	// Aggiorna l'oggetto passato al list-wrapper
	updateListPageBaseData(list: ContactPouchModel[]) {
		this.listPageBaseData.data = _.cloneDeep(list);
		this.listPageBaseData = Object.assign({}, this.listPageBaseData);
	}

	/**
	 * Actions
	 */

	openDialogContact() {
		const dialog = this.dialog.open(DialogNewContactComponent, {
			data: {
				title: this.translate.instant('contact.add_new'),
				organization: this.organization || null
			},
			panelClass: 'dialog-medium',
			minHeight: '32vh',
			disableClose: true
		});
		dialog.afterClosed().subscribe((contactToSave: ContactPouchModel) => {
			if (contactToSave) {
				// TODO - Da fare creazione del contact
				// console.error('Function not implemented');
				// contactToSave.code_agent = this.user.current_permission.context_code.code;
				if (this.pageContext == 'contacts') {
					this.store.dispatch(ContactStateAction.save(contactToSave));
				} else {
					const updatedOrganization = _.cloneDeep(this.organization);
					const guid = this.loader.guid();
					if (!contactToSave._id) {
						contactToSave._id = `contact_${guid}`;
					}
					if (!contactToSave.code_item) {
						contactToSave.code_item = guid;
					}
					if (!updatedOrganization.contact_list) {
						updatedOrganization.contact_list = [];
					}
					updatedOrganization.contact_list.push(contactToSave);
					this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
				}
			}
		});
	}

	toggleHasNotification(row: ContactPouchModel) {
		row.has_notification = !row.has_notification;
		this.updateContact(row);
	}

	openDialogContactDetail(row: ContactPouchModel) {
		this.dialog.open(DialogNewContactComponent, {
			data: {
				title: this.translate.instant('contact.details'),
				addressData: _.cloneDeep(row),
				isDisabled: true
			}
		});
	}

	openDialogEditContact(row: ContactPouchModel) {
		const dialogRef = this.dialog.open(DialogNewContactComponent, {
			data: {
				title: this.translate.instant('contact.edit'),
				addressData: _.cloneDeep(row),
				organizationCode: this.organization.code ? this.organization.code : null
			},
			disableClose: true,
			autoFocus: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.updateContact(res);
			}
		});
	}

	openDialogConfirmRemoveContact(row: ContactPouchModel) {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: this.translate.instant('contact.remove'),
				text: this.translate.instant('question.remove') + ` ${row.email}?`
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.removeContact(row);
			}
		});
	}

	selectCompany(level: string, code: string) {
		this.utilCompanyService.navigateToCompanyDetail(level, code, null);
	}

	updateContact(row: ContactPouchModel) {
		if (this.pageContext == 'contacts') {
		} else {
			const updatedOrganization = _.cloneDeep(this.organization);
			updatedOrganization.contact_list = updatedOrganization.contact_list.map(contact =>
				contact._id == row._id ? row : contact
			);
			this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
		}
	}

	removeContact(row: ContactPouchModel) {
		if (this.pageContext == 'contacts') {
			this.store.dispatch(ContactStateAction.remove({ data: row }));
		} else {
			const updatedOrganization = _.cloneDeep(this.organization);
			updatedOrganization.contact_list.find(cl => cl._id == row._id).valid = false;
			this.store.dispatch(OrganizationStateAction.save(new BaseState(updatedOrganization)));
		}
	}

	// Can add new contact
	getCanAddNew(): boolean {
		if (this.currentContext) {
			switch (this.currentContext) {
				case ContextApplicationItemCodeEnum.B2B:
					return false;
				default:
					return true;
			}
		}
	}
}
