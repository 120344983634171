// model
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../../../../service/pouch-db/model/pouch-base.model';

// store
import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '@saep-ict/angular-core';

export enum BackofficeUserListActionEnum {
	UPDATE_LIST = '[backoffice-user-list] Update List',
	UPDATE_ITEM = '[backoffice-user-list] Update Item',
	LOAD_ITEM = '[backoffice-user-list] Load Item',
	LOAD_LIST = '[backoffice-user-list] Load List',
	RESET = '[backoffice-user-list] Reset',
	ERROR = '[backoffice-user-list] Error',
	CREATE_ITEM = '[backoffice-user-list] Create Item',
	CREATE_ITEM_SUCCESS = '[backoffice-user-list] Create Item Success',
	DELETE_ITEM = '[backoffice-user-list] Delete Item',
	DELETE_ITEM_SUCCESS = '[backoffice-user-list] Delete Item Success'
}

export namespace BackofficeUserListStateAction {
	export const loadList = createAction(BackofficeUserListActionEnum.LOAD_LIST, props<BaseStateModel<any>>());
	export const loadItem = createAction(BackofficeUserListActionEnum.LOAD_ITEM, props<BaseStateModel<BasePouchModel>>());
	export const updateList = createAction(BackofficeUserListActionEnum.UPDATE_LIST, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const updateItem = createAction(BackofficeUserListActionEnum.UPDATE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const reset = createAction(BackofficeUserListActionEnum.RESET);
	export const error = createAction(BackofficeUserListActionEnum.ERROR, props<BaseStateModel<any>>());
	export const createItem = createAction(BackofficeUserListActionEnum.CREATE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const createItemSuccess = createAction(BackofficeUserListActionEnum.CREATE_ITEM_SUCCESS, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const deleteItem = createAction(BackofficeUserListActionEnum.DELETE_ITEM, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const deleteItemSuccess = createAction(BackofficeUserListActionEnum.DELETE_ITEM_SUCCESS, props<BaseStateModel<null>>());
}
