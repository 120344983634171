import { BreakpointState } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { BaseStateModel, ContextApplicationItemCodeEnum, MediaReplayService, UserDetailModel, UserTypeContextModel } from '@saep-ict/angular-core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { CustomerAppConfig } from '../../customer-app.config';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { UtilGuardService } from '../../service/guard/util-guard/util-guard.service';

@Component({
	selector: 'ms-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
	// encapsulation: ViewEncapsulation.None
})
export class FramePageComponent implements OnInit, OnDestroy {
	private _mediaSubscription: Subscription;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	currentContext: UserTypeContextModel;
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;


	constructor(
		public mediaReplayService: MediaReplayService,
		private cdr: ChangeDetectorRef,
		private appConfig: CustomerAppConfig,
		private store: Store<any>,
		private utilGuardService: UtilGuardService,
		@Inject(PLATFORM_ID) private platform_id: string
	) {
		if (isPlatformBrowser(this.platform_id)) {
			this.mediaReplayService.deviceH = window.innerHeight;
			this.mediaReplayService.deviceW = window.innerWidth;
		}
		this.retrieveUserContext();
	
	}

	ngOnInit() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media
			.pipe(debounceTime(100))
			.subscribe((change: BreakpointState) => {
				if (isPlatformBrowser(this.platform_id)) {
					this.mediaReplayService.deviceH = window.innerHeight;
					this.mediaReplayService.deviceW = window.innerWidth;
				}
			});
	}
	ngAfterViewInit(){
		if(this.currentContext.type === this.contextApplicationItemCodeEnum.BACKOFFICE){
			window.addEventListener('message', (event) => {
				// rendere dinamico l'url V4 in base all'environment
				if (location.ancestorOrigins[0] === this.appConfig.config.portal.origin) {
				  this.hiddenHtmlElement();
				} else {
				  console.log('Data not sent from parent');
				  return;
				}
			  });
		}
	}

	hiddenHtmlElement() {
		//Nascondo Menu laterale
		const element = document.getElementsByTagName('saep-sidebar')[0] as HTMLIFrameElement;
		element.style.visibility = 'hidden';
		// Nascondo cornice
		const toolbar = document.getElementsByTagName('ms-toolbar')[0] as HTMLIFrameElement;
		toolbar.style.visibility = 'hidden';
		toolbar.style.display = 'block';
		toolbar.style.height = '0px';
		const matSidenav = document.getElementsByTagName('mat-sidenav-content')[0] as HTMLIFrameElement;
		matSidenav.style.marginLeft = '0px';
	}

	retrieveUserContext() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.currentContext = this.utilGuardService.retrieveContextPermission(
			this.user.current_permission.context_application
		);
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}
}
