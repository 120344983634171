import { Component, Input, Output, EventEmitter } from '@angular/core';

// widget & utility
import { UtilService } from '@saep-ict/angular-core';
import { CustomerConfigurationCompany } from '../../../constants/configuration-customer/structure.constants';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'contact-list-wrapper',
	templateUrl: './contact-list-wrapper.component.html',
	styleUrls: ['./contact-list-wrapper.component.scss']
})
export class ContactListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() disabledOrderConfirmEmail: boolean;

	@Output() toggleHasNotification = new EventEmitter<{ val: ContactPouchModel }>();
	@Output() openDialogContactDetail = new EventEmitter<{ row: ContactPouchModel }>();
	@Output() openDialogConfirmRemoveContact = new EventEmitter<{ row: ContactPouchModel }>();
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();

	public companyContact = CustomerConfigurationCompany.contact;

	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}
}
