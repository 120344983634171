import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { LocalStorage } from 'ngx-webstorage';
import { SentencecasePipe } from '@saep-ict/angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Language } from '../../enum/language.enum';

export interface LanguageItemCatalog {
	language: Language;
	description: string;
}

@Injectable()
export class UtilTranslateService {
	language_enum: any;
	@LocalStorage('language') private language: string;
	//languages = [Language.ITALIAN, Language.ENGLISH, Language.SPANISH, Language.GERMAN, Language.FRENCH];
	public languages: string[];

	constructor(
		private translate: TranslateService,
		private dateAdapter: DateAdapter<any>,
		private sentencecasePipe: SentencecasePipe,
		private snackBar: MatSnackBar,
		private route: ActivatedRoute
	) {}

	/**
	 * This function set all the mandatory data to handle translation
	 */
	setLanguageFirstTime(lang: any) {
		// this.translate.addLangs(this.languages);
		// const defaultLang = this.translate.getBrowserLang()
		// 	? (this.translate.getBrowserLang() as Language)
		// 	: this.languages[0];
		// this.translate.setDefaultLang(defaultLang);

		// this.setLanguage(this.language ? this.language : defaultLang);

		// this.route.queryParams.subscribe(params => {
		// 	if (this.languages.includes(params.lang)) {
		// 		this.setLanguage(params.lang, false);
		// 	}
		// });
		this.language_enum = lang;
		this.languages = Object.keys(this.language_enum)
			.filter(i => i !== 'DEFAULT' && i !== 'FALLBACK')
			.map(i => this.language_enum[i]);
		this.translate.addLangs(this.languages);
		const browserLang = this.translate.getBrowserLang();
		// Setta la lingua anche per il modulo media center
		if (this.languages.includes(this.language_enum.DEFAULT)) {
			this.translate.setDefaultLang(this.language_enum.DEFAULT);
		} else {
			this.languages[0];
		}

		let selectedLang: string;

		this.route.queryParams.subscribe(params => {
			// prima del set di lingue disponibili
			selectedLang = this.languages[0];
			// default
			if (this.languages.includes(this.language_enum.DEFAULT)) {
				selectedLang = this.language_enum.DEFAULT;
			}
			// browser
			if (this.languages.includes(browserLang)) {
				selectedLang = browserLang;
			}
			// salvata nel local storage
			if (this.languages.includes(this.language)) {
				selectedLang = this.language;
			}
			// query param
			if (params.lang && this.languages.includes(params.lang)) {
				selectedLang = params.lang;
			}
			this.setLanguage(selectedLang, false);
		});
	}

	/**
	 * @param language locale about the language to set
	 * @param showSnackBar boolean to decide to show the snackbar
	 */
	public setLanguage(language, showSnackBar = false) {
		this.translate.use(language).subscribe(res => {
			// set localstorage
			this.language = language;

			// select locale datepicker
			this.dateAdapter.setLocale(language);

			// show snackbar about change lang
			if (showSnackBar) {
				const currentLanguage = this.sentencecasePipe.transform(
					this.translate.instant('language.current_language')
				);
				const newLanguage = this.sentencecasePipe.transform(this.translate.instant('language.' + language));

				this.snackBar.open(this.translate.instant(currentLanguage + ': ' + newLanguage), 'Ok', {
					duration: 10000
				});
			}
		});
	}

	getTranslationFromLanguage(itemCatalog: LanguageItemCatalog[]): LanguageItemCatalog {
		// TEMP FIX for Irinox only
		itemCatalog.map(ic => (ic.language = ic.language.toLowerCase() as Language));

		const requestedLanguage = itemCatalog.find(ic => ic.language == this.language);
		if (requestedLanguage) {
			return requestedLanguage;
		}

		const defaultLanguage = itemCatalog.find(ic => ic.language == Language.DEFAULT);
		if (defaultLanguage) {
			return defaultLanguage;
		}

		return itemCatalog.find(ic => ic.language == Language.FALLBACK);
	}
}
