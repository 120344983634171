export enum Language {
	DEFAULT = 'en',
	FALLBACK = 'it',
	// all
	ENGLISH = 'en',
	FRENCH = 'fr',
	GERMAN = 'de',
	ITALIAN = 'it',
	SPANISH = 'es'
}
