<div class="container-fluid">
    <div class="row">
        <div class="col">
            <mat-card>
                <div>
                    <div class="card-title">{{ 'Variazioni sul tuo ordine' }}</div>
                    <div *ngIf="productListGroupedByCode" class="card-subtitle">
                        Data ultima variazione: <b>{{ lastVariationOrderDate }}</b>
                    </div>
                </div>
    
                <mat-card-content *ngIf="productListGroupedByCode; else noDataFound">
                    <ng-container *ngFor="let productGroup of productListGroupedByCode | keyvalue">
                        <div class="product_card">
                            <div class="product_card-title">{{ productGroup.key }}</div>
                            <div *ngFor="let product of productGroup.value">
                                <!-- Prodotto aggiunto -->
                                <div class="product_card-row added" *ngIf="product.type === productVariationTypeEnum.ADDED">
                                    <mat-icon class="added">
                                        {{ productVariationIconEnum.ADDED }}
                                    </mat-icon>
                                    <span>{{ 'order.variation.product.added' | translate }}</span>
                                </div>
                                <!-- Prodotto rimosso -->
                                <div class="product_card-row removed" *ngIf="product.type === productVariationTypeEnum.REMOVED">
                                    <mat-icon class="removed">
                                        {{ productVariationIconEnum.REMOVED }}
                                    </mat-icon>
                                    <span>{{ 'order.variation.product.removed' | translate }}</span>
                                </div>
                                <!-- Prodotto sostituito -->
                                <div class="product_card-row replaced" *ngIf="product.type === productVariationTypeEnum.REPLACED">
                                    <mat-icon class="replaced">
                                        {{ productVariationIconEnum.REPLACED }}
                                    </mat-icon>
                                    <span>{{ 'order.variation.product.replaced' | translate }} #{{ product.newValue }}</span>
                                </div>
                                <!-- Prodotto che ha subito una variazione -->
                                <div class="product_card-row property_changed" *ngIf="product.type === productVariationTypeEnum.PROPERTY_CHANGED">
                                    <mat-icon class="property_changed">
                                        {{ productVariationIconEnum.PROPERTY_CHANGED }}
                                    </mat-icon>
                                    <span>
                                        {{ 'order.variation.product.property_changed' | translate }}: 
                                        {{ 
                                            'order.variation.product.' + checkPropertyName(
                                                product.propertyName, product.oldValue, product.newValue
                                            ) | translate 
                                        }} 
                                        (da {{ oldValue }} a {{ newValue }})
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
                <ng-template #noDataFound>
                    <p>{{ 'general.no_results' | translate }}</p>
                </ng-template>
            </mat-card>
        </div>
    </div>
</div>
