import { ViewTypeEnum } from '../../../../enum/view-type.enum';
import { Component, OnInit } from '@angular/core';
import { ProductModel } from '../../../../model/card.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticlePouchModel } from '@saep-ict/pouch_agent_models';
import { ArticleListActionEnum } from '../../../../state/article-list/article-list.actions';
import { Category } from '../../../../model/category-list.model';
import { ArticleCategory } from '../../../../model/article.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseStateModel, SubscribeManagerService } from '@saep-ict/angular-core';
import { ArticleState } from '../../../../model/state/article-list-state.model';
import { StateFeature } from '../../../../state';
import { ArticleListFilterModel } from '../../../../service/pouch-db/filter/article-list-filter.model';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CategoryListActionEnum } from '../../../../state/category-list/category-list.actions';
import categories from './catalog-mock.json';
import { CustomerAppConfig } from '../../../../customer-app.config';
import { CallToActionConfig } from '../../../../widget/call-to-action/call-to-action.component';
import { ROUTE_URL } from '../../../../router/route-naming';

@Component({
	selector: 'app-b2c-catalog',
	templateUrl: './b2c-catalog.component.html',
	styleUrls: ['./b2c-catalog.component.scss'],
	providers: [SubscribeManagerService]
})
export class B2cCatalogComponent implements OnInit {
	list: ProductModel[] = [];
	pageSlice: ProductModel[];
	viewType: ViewTypeEnum;

	category: ArticlePouchModel;

	categoryList$: Observable<BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>> = this.store.select(
		StateFeature.getCategoryListState
	);
	categoryList: Category<ArticleCategory>[] = [];

	articleList$: Observable<BaseStateModel<ArticleState[]>> = this.store.select(StateFeature.getArticleList);
	articleList: ArticleState[] = [];

	filterCategoryLabel: string = null;
	isMobile = false;
	isCatalog: boolean;

	callToActionConfig: CallToActionConfig = {
		text: 'Lorem ipsum',
		btnLabel: 'contact.contact_us',
		theme: 'accent'
	};

	constructor(
		private activatedRoute: ActivatedRoute,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private appConfig: CustomerAppConfig,
		private router: Router
	) {
		this.isCatalog = this.router.url.split('/').pop() === ROUTE_URL.catalog;
		this.retrieveCategory();

		if (this.appConfig.authenticationToken) {
			this.viewType = ViewTypeEnum.module;
		} else {
			this.viewType = ViewTypeEnum.list;
		}

		this.pageSlice = this.list.slice(0, 8);

		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {},
				error => {
					console.log('something went wrong ', error);
				}
			),
			'order-data'
		);
	}

	ngOnInit(): void {
		this.isMobile = window.innerWidth < 1200;
	}

	// qui vengono recuperate tutte le categorie. Probabilmente andrà spostato nel recupero dati del auth-token.guard
	async retrieveCategory() {
		const categoryId = this.activatedRoute.snapshot.paramMap.get('category_id');
		// this.category = await this.pouchDbSpin8AgentAdapter.categoryListCommonPouch.loadCategoryDetail(categoryId);
	}

	initMandatoryData() {
		let hasDiff: boolean; // what is
		// TODO: remove temp MOCK data
		this.categoryList = categories as any;
		return this.categoryList$.pipe(
			filter(
				(categoryListState: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) =>
					categoryListState && categoryListState.type !== CategoryListActionEnum.LOAD_ALL
			),
			mergeMap((categoryListState: BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>) => {
				if (categoryListState.type === CategoryListActionEnum.ERROR) {
					throw new Error(CategoryListActionEnum.ERROR);
				}
				// if (hasDiff) {
				// 	// recupero degli articoli a partire dagli article node del category list (type: category)
				// 	// oppure sui documenti type: article
				// 	if (categoryListState.type === CategoryListActionEnum.NOT_EXISTING) {
				// 		this.store.dispatch(ArticleListStateAction.loadWithDetail(this.articleList));
				// 	} else {
				// 		this.categoryList = categoryListState.data;
				// 		this.store.dispatch(ArticleListStateAction.loadFromCategory(this.articleList));
				// 	}
				// }
				return this.articleList$;
			}),
			filter(
				(articleList: BaseStateModel<ArticleState[]>) =>
					articleList &&
					articleList.type !== ArticleListActionEnum.LOAD &&
					articleList.type !== ArticleListActionEnum.LOAD_WITH_DETAIL
			),
			map((articleList: BaseStateModel<ArticleState[]>) => {
				if (articleList.type === ArticleListActionEnum.ERROR) {
					throw new Error(ArticleListActionEnum.ERROR);
				}
				if (articleList.type === ArticleListActionEnum.UPDATE) {
					// this.articleList = new BaseState<ArticleState[], ArticleListFilterModel>(
					// 	this.utilOrderService.mergeProductDetailInArticle(articleList, this.order).data,
					// 	this.articleList.dataSetting
					// );

					this.articleList = articleList.data;
				}
				// if (this.categoryList && this.categoryList.length > 0) {
				// 	this.utilOrderService.returnArticleCategoryTree(_.cloneDeep(this.articleList.data), this.categoryList)
				// 		.then( res => {
				// 			this.articleCategoryTreeList = res;
				// 		}).catch(err => {
				// 			console.log(err);
				// 		});
				// } else {

				// }
			})
		);
	}

	categorySelectedChange($event: Category<ArticleCategory>) {
		// this.filterCategoryLabel = $event.description ? $event.description : null;
		// this.articleList.dataSetting.appliedFilter.article.has_hierarchy = $event._id ? [$event._id] : [];
		// this.store.dispatch(ArticleListStateAction.loadFromCategory(this.articleList));
	}

	// events
	onResize(event) {
		this.isMobile = event.target.innerWidth < 1200;
	}

	eventP(event): void {
		const startIndex = event.pageSize * event.pageIndex;
		let endIndex = startIndex + event.pageSize;
		console.log(endIndex);
		if (endIndex > this.list.length) {
			endIndex = this.list.length;
		}
		if (this.list && this.list.length > 0) {
			this.pageSlice = this.list.slice(startIndex, endIndex);
		}
	}

	clickCallToAction() {
		console.log('clickCallToAction');
	}

	changeFilters(e) {
		console.log(e);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
