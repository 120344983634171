<div formGroupName="userContactInformation">
	<div class="row">
        <!-- Nome -->
<div class="col-lg-6" *ngIf="getVisibilityField('firstName')">
    <mat-form-field>
        <mat-label>{{ 'user.field.first_name' | translate | sentencecase }}</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error
            *ngIf="
                form
                    .get('userContactInformation')
                    .get('firstName')
                    .hasError('required')
            "
        >
            {{ 'general.validators.required' | translate | sentencecase }}
        </mat-error>
    </mat-form-field>
</div>

<!-- Cognome -->
<div class="col-lg-6" *ngIf="getVisibilityField('lastName')">
    <mat-form-field>
        <mat-label>{{ 'user.field.last_name' | translate | sentencecase }}</mat-label>
        <input matInput formControlName="lastName" />
        <mat-error
            *ngIf="
                form
                    .get('userContactInformation')
                    .get('lastName')
                    .hasError('required')
            "
        >
            {{ 'general.validators.required' | translate | sentencecase }}
        </mat-error>
    </mat-form-field>
</div>

<!-- Email -->
<div class="col-lg-12" *ngIf="getVisibilityField('email')">
    <mat-form-field>
        <mat-label>
            {{ 'contact.field.email' | translate | sentencecase }}
        </mat-label>
        <input matInput formControlName="email" />
        <mat-error
            *ngIf="
                form
                    .get('userContactInformation')
                    .get('email')
                    .hasError('required')
            "
        >
            {{ 'general.validators.required' | translate | sentencecase }}
        </mat-error>
        <mat-error
            *ngIf="
                form
                    .get('userContactInformation')
                    .get('email')
                    .hasError('email')
            "
        >
            {{ 'general.validators.email' | translate | sentencecase }}
        </mat-error>
    </mat-form-field>
</div>

<!-- Telefono -->
<div class="col-lg-12" *ngIf="getVisibilityField('phone')">
    <mat-form-field>
        <mat-label>{{ 'contact.field.phone' | translate | sentencecase }}</mat-label>
        <input matInput formControlName="phone" />
        <mat-error
            *ngIf="
                form
                    .get('userContactInformation')
                    .get('phone')
                    .hasError('required')
            "
        >
            {{ 'general.validators.required' | translate | sentencecase }}
        </mat-error>
    </mat-form-field>
</div>
</div>
</div>