import { ROUTE_URL } from '../router/route-naming';

// TOFIX: capire come gestire questa costante
export const OrganizationBackoffice = [
	{
		tab: ROUTE_URL.details,
		can_edit: false
	},
	{
		tab: ROUTE_URL.addresses,
		can_edit: true
	},
	{
		tab: ROUTE_URL.orders,
		can_edit: true
	}
];
