import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { DestinationListStateAction, DestinationListActionEnum } from './destination-list.actions';

// model
import { BaseStateModel } from '@saep-ict/angular-core';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { DestinationListFilterModel } from '../../service/pouch-db/filter/destination-filter.model';

export namespace DestinationListStateReducer {
	export const initialState: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(DestinationListStateAction.load, state => state),
		on(DestinationListStateAction.update, (state, destinationList) => destinationList),
		on(DestinationListStateAction.completed, (state, destination) => ({
			data: state ? state.data : null,
			type: destination.type
		})),
		on(DestinationListStateAction.reset, () => initialState),
		on(DestinationListStateAction.error, (state, destination) => ({
			data: state ? state.data : null,
			type: destination.type
		}))
	);

	export function reducer(
		state: BaseStateModel<DestinationPouchModel[], DestinationListFilterModel>,
		action: TypedAction<DestinationListActionEnum>
	) {
		return _reducer(state, action);
	}
}
