<section class="no-data-found">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <p [innerHTML]="text"></p>
      </div>
      <div class="col-md-4 offset-md-2">
        <app-illustration-empty></app-illustration-empty>
      </div>
    </div>
  </div>
</section>
