import {
	UtilService,
	UtilColorService,
	GlobalColorsEnum,
	BaseStateModel,
	ChartjsModel,
	UserDetailModel,
	ContextApplicationItemCodeEnum,
	DateMomentService
} from '@saep-ict/angular-core';
import { StatisticsAgentFulfilledData } from '../../../model/statistics-agent.model';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StateFeature } from '../../../state';
import { Store } from '@ngrx/store';
import { CardDashboardConfig } from '../../../widget/card-dashboard/card-dashboard.component';
import { chartTimeRange30Days, chartTimeRange7Days } from '../../../constants/chart-time-range.constants';
import { MatSelectChange } from '@angular/material/select';
import _ from 'lodash';
import { ROUTE_URL } from '../../../router/route-naming';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export class ChartBarStackedSerie {
	label: string;
	data: number[];
	backgroundColor?: string;
	barPercentage?: number;
}

export class QuarterList {
	q: string;
	y: number;
}

export class BaseChartClass {
	// store
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	context: ContextApplicationItemCodeEnum;

	statistics$: Observable<BaseStateModel<any>>; // TODO: define a generic Statistics Model if possible
	statistics: any;

	// config - generali

	fullLabels: (string | number)[] = [];
	fullLabelsDays7: (string | number)[] = [];
	fullLabelsDays15: (string | number)[] = [];
	fullLabelsDays30: (string | number)[] = [];

	fullDataSets: ChartBarStackedSerie[] = [];
	fullDataSetDays7: ChartBarStackedSerie[] = [];
	fullDataSetDays15: ChartBarStackedSerie[] = [];
	fullDataSetDays30: ChartBarStackedSerie[] = [];

	averageValue = 0;
	averageTrend = 0;
	activeClientsToday = 0;
	sumOrders = 0;
	fulfilledTotal = 0;
	fulfilledCount = 0;
	fulfilledAverage = 0;
	timeRange = chartTimeRange30Days;

	chart = <ChartjsModel>{
		data: [],
		datasets: [],
		labels: [],
		legend: false
	};

	// config - specifiche

	cardDashboardConfigOrdersBestOrdered: CardDashboardConfig = {
		title: 'chart.best_orders.title',
		subtitle: 'chart.best_orders.subtitle',
		icon: 'assignment',
		rangeTempList: chartTimeRange7Days
	};

	cardDashboardConfigOrdersAverage: CardDashboardConfig = {
		title: 'chart.average_order_value.title',
		subtitle: 'chart.average_order_value.subtitle',
		icon: 'assignment',
		helpText: 'chart.average_order_value.helptext',
		rangeTempList: chartTimeRange7Days,
		detailLink: ROUTE_URL.dashboardOrders
	};

	cardDashboardConfigOrdersQuantity: CardDashboardConfig = {
		title: 'chart.average_orders_per_day.title',
		subtitle: 'chart.average_orders_per_day.subtitle',
		icon: 'assignment',
		helpText: 'chart.average_orders_per_day.helptext',
		theme: 'accent',
		rangeTempList: chartTimeRange7Days,
		detailLink: ROUTE_URL.dashboardOrders
	};

	cardDashboardConfigClientsActive: CardDashboardConfig = {
		title: 'chart.clients_active.title',
		subtitle: 'chart.clients_active.subtitle',
		subtitleTranslateParams: { amount: '30' },
		icon: 'work',
		helpText: 'chart.clients_active.helptext',
		rangeTempList: chartTimeRange30Days
	};

	cardDashboardConfigClientsInactive: CardDashboardConfig = {
		title: 'chart.clients_inactive.title',
		subtitle: 'chart.clients_inactive.subtitle',
		subtitleTranslateParams: { amount: '90' },
		icon: 'work',
		helpText: 'chart.clients_inactive.helptext',
		theme: 'accent',
		rangeTempList: chartTimeRange30Days
	};

	cardDashboardConfigDue: CardDashboardConfig = {
		title: 'chart.due.title',
		subtitle: 'chart.due.subtitle',
		helpText: 'chart.due.helptext',
		icon: 'account_balance',
		theme: 'accent'
	};

	cardDashboardConfigExpired: CardDashboardConfig = {
		title: 'chart.expired.title',
		subtitle: 'chart.expired.subtitle',
		helpText: 'chart.expired.helptext',
		icon: 'account_balance',
		theme: 'accent',
		rangeTempList: chartTimeRange7Days,
		detailLink: ROUTE_URL.dashboardExpired
	};

	cardDashboardConfigFulfilledTrend: CardDashboardConfig = {
		title: 'chart.purchased_trend.title',
		subtitle: 'chart.purchased_trend.subtitle',
		helpText: 'chart.purchased_trend.helptext',
		icon: 'assignment',
		rangeTempList: chartTimeRange7Days,
		detailLink: ROUTE_URL.dashboardSold
	};

	cardDashboardConfigFulfilledQuarters: CardDashboardConfig = {
		title: 'chart.purchased_trend.title',
		subtitle: 'chart.purchased_trend.subtitle',
		helpText: 'chart.purchased_trend.helptext',
		icon: 'shopping_cart'
	};

	cardDashboardConfigProductsBestSeller: CardDashboardConfig = {
		title: 'chart.products_best_seller.title',
		subtitle: 'chart.products_best_seller.subtitle',
		subtitleTranslateParams: { amount: '5' },
		helpText: 'chart.products_best_seller.helptext',
		icon: 'assignment'
	};

	/**
	 * Style
	 */
	colorPrimary = this.utilService.getGlobalColor(GlobalColorsEnum.PRIMARY);
	colorAccent = this.utilService.getGlobalColor(GlobalColorsEnum.ACCENT);

	// Primary variations
	colorPrimaryRGB = this.utilColorService.hexToRgb(this.colorPrimary);
	colorPrimary10 = `rgba(${this.colorPrimaryRGB.r}, ${this.colorPrimaryRGB.g}, ${this.colorPrimaryRGB.b}, 0.1)`;
	colorPrimary50 = `rgba(${this.colorPrimaryRGB.r}, ${this.colorPrimaryRGB.g}, ${this.colorPrimaryRGB.b}, 0.5)`;
	colorPrimary70 = `rgba(${this.colorPrimaryRGB.r}, ${this.colorPrimaryRGB.g}, ${this.colorPrimaryRGB.b}, 0.7)`;

	// white
	colorWhite = 'rgba(255,255,255,1)';
	colorWhite50 = 'rgba(255,255,255,0.5)';

	// black
	colorBlack = 'rgba(0,0,0,0.87)';
	colorBlack30 = 'rgba(0,0,0,0.3)';

	constructor(
		public utilService: UtilService,
		public utilColorService: UtilColorService,
		public store: Store<any>,
		public route: ActivatedRoute,
		public translateService: TranslateService,
		public dateMomentService?: DateMomentService
	) {
		this.getStatistics();
	}

	/**
	 * Functions
	 */

	createDataSerie() {}
	populateChart() {}
	populateQuarterList() {}
	getMetrics(timeRange: number | string) {}

	/**
	 * Funzione per svuotare l'array di dati.
	 * Attenzione: assegnare una quadra vuota non fa funzionare l'aggiornamento del grafico
	 * @param list Array to empty
	 */
	removeData(list) {
		list.length = 0;
	}

	/**
	 * Funzione per popolare l'array di dati
	 * Attenzione: assegnare l'oggetto direttamente non fa funzionare l'aggiornamento del grafico
	 * @param list array to populate
	 * @param listToAdd array to copy in the list param
	 */
	addData(list, listToAdd) {
		list.push(...listToAdd);
	}

	hoverStyle() {
		return {
			// se false il punto si evidenza se si passa sull'asse x
			intersect: false
		};
	}

	layoutStyle() {
		return {
			padding: 4
		};
	}

	elementsStyle() {
		return {
			point: {
				radius: 0
			}
		};
	}

	/**
	 * Funzione necessaria a popolare la dropdown sotto il grafico
	 * @param list Lista dalla quale recuperare la lista da visualizzare nel dropdown della scelta temporale
	 */
	getQuarterList(list: StatisticsAgentFulfilledData[]): QuarterList[] {
		let quarterList: QuarterList[] = [];
		list.forEach(item => quarterList.push({ q: item.name, y: item.year }));
		return quarterList;
	}

	ticksCallback() {
		return function (label) {
			return (label / 1000).toFixed(0) + 'k';
		};
	}

	// TODO: trasferire in utilChartService (?)
	changeTimeRange(e: MatSelectChange, serviceContext) {
		serviceContext.populateChart(e.value);
	}

	// TODO: trasferire in utilService
	getTrendPercentage(oldValue: number, newValue: number): number {
		if (oldValue === 0) {
			newValue++;
			oldValue++;
		}
		return ((newValue - oldValue) / Math.abs(oldValue)) * 100;
	}

	getStatistics() {
		switch (this.route.snapshot.routeConfig.path) {
			case ROUTE_URL.dashboardOrders:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailOrders);
				break;
			case ROUTE_URL.dashboardSold:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailSold);
				break;
			case ROUTE_URL.dashboardExpired:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailExpired);
				break;
			case ROUTE_URL.dashboardClients:
				this.statistics$ = this.store.select(StateFeature.getStatisticsDetailClients);
				break;
			default:
				this.user$.pipe(take(1)).subscribe(res => {
					this.context = res.data.current_permission.context_application;
					switch (this.context) {
						case ContextApplicationItemCodeEnum.AGENT:
							this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
							break;
						case ContextApplicationItemCodeEnum.B2B:
							this.statistics$ = this.store.select(StateFeature.getStatisticsOrganization);
							break;
						default:
							// the default is handled as if Agent
							this.statistics$ = this.store.select(StateFeature.getStatisticsAgent);
					}
				});
				break;
		}
		if (this.statistics$) {
			this.statistics$
				.pipe(
					filter(state => !!(state && state.data)),
					take(1)
				)
				.subscribe(res => {
					this.statistics = res.data;
					this.createDataSerie();
					this.populateQuarterList();
					this.populateChart();
				});
		}
	}
}
