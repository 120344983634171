<breadcrumb
	*ngIf="organization && order"
	[title]="breadcrumbTitle"
	[pathList]="breadcrumbPathList"
	[options]="orderStatus === orderStatusEnum.DRAFT"
	[expandedActions]="true"
>
	<ng-container expandedActions>
		<!-- delete button -->
		<button
			mat-button
			(click)="openDialogConfirmDelete($event)"
			*ngIf="orderStatus === orderStatusEnum.DRAFT && order._id"
		>
			<mat-icon>delete</mat-icon>
			<span>{{ 'general.delete' | translate | sentencecase }}</span>
		</button>
		<!-- clone button -->
		<button mat-button (click)="openDialogConfirmClone($event)" *ngIf="false">
			<mat-icon>file_copy</mat-icon>
			<span>{{ 'general.clone' | translate | sentencecase }}</span>
		</button>
	</ng-container>
</breadcrumb>

<!-- BACKOFFICE CONTEXT -->
<mat-toolbar
	*ngIf="
		order?.header.status === orderStatusEnum.TO_AUTHORIZE &&
		currentContext === contextApplicationItemCodeEnum.BACKOFFICE
	"
	class="toolbar order-validation"
>
	<img src="assets/img/icon/warning-sign.png" class="warning-sign" alt="Warning Sign" />
	<ng-container *ngIf="!organizationToBeValidated">
		<span class="message">
			{{ 'order.validation.message' | translate | sentencecase }}
		</span>
		<button mat-flat-button color="primary" (click)="checkOrganizationForOrderValidation()">
			{{ 'order.validation.valid' | translate | sentencecase }}
		</button>
	</ng-container>
	<ng-container *ngIf="organizationToBeValidated">
		<span class="message">
			{{ 'order.validation.valid_organization' | translate | sentencecase
			}}<a
				href="javascript:void(0)"
				(click)="
					utilCompanyService.navigateToCompanyDetail(
						organization.level,
						organization.code,
						user?.current_permission.context_application
					)
				"
				>{{ 'organization.name' | translate }}</a
			>
		</span>
	</ng-container>
</mat-toolbar>
<!-- END BACKOFFICE CONTEXT -->

<tab-wrapper *ngIf="order?.header?.status" [toExclude]="tabsToExclude">
	<mat-accordion-wrapper [color]="statusBardOrderService.config.theme">
		<ng-container header>
			<button mat-icon-button (click)="goBack()">
				<mat-icon>arrow_back</mat-icon>
			</button>
			<h2>{{ 'order.detail' | translate | sentencecase }}</h2>
			<span class="flex-span">
				<status-bar [config]="statusBardOrderService.config"></status-bar>
			</span>
		</ng-container>
		<ng-container main>
			<form [formGroup]="form">
				<div class="row mt-3">
					<!-- Prezzi resi -->
					<!-- <div class="col-md-12">
						<p class="toggle__label"><strong>Modalità ordine</strong></p>
						<p class="toggle__label">Listino sconto</p>
						<mat-slide-toggle
							[disabled]="order.header.status !== orderStatusEnum.DRAFT"
							#toggleOrderCausal
							[checked]="this.order.header.order_causal === causalHeaderOrderEnum.NR ? true : false"
							(change)="openDialogCausal()"
							>Prezzo netto
						</mat-slide-toggle>
					</div> -->

					<!-- Modalità di consegna -->
					<!-- <div class="col-md-6">
						<mat-form-field>
							<mat-select placeholder="Modalità di consegna" formControlName="delivery_methods">
								<mat-option
									*ngFor="let methodDelivery of methodDeliveryList"
									[value]="methodDelivery.code_item"
								>
									{{ methodDelivery.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div> -->

					<!-- Causale -->
					<div class="col-md-6 note">
						<mat-form-field>
							<span matPrefix *ngIf="utilOrderService.getOrderVariationValue(order, 'order_causal')">
								<mat-icon
									class="mat-icon variation-icon"
									matTooltipClass="allow-break-line"
									[matTooltip]="
										'Valore aggiornato da:&#13;' +
										getOrderCausal(utilOrderService.getOrderVariationValue(order, 'order_causal'))
											?.description_short
									"
								>
									error
								</mat-icon>
							</span>
							<mat-label>{{ 'order.field.causal' | translate | sentencecase }}</mat-label>

							<mat-select formControlName="order_causal" (openedChange)="inputSearchCausal.value = ''">
								<mat-form-field class="filter-input">
									<mat-label>{{ 'causal.filter' | translate | sentencecase }}</mat-label>
									<input matInput type="text" #inputSearchCausal autocomplete="off" />
									<button
										mat-button
										*ngIf="inputSearchCausal.value"
										matSuffix
										mat-icon-button
										aria-label="Clear"
										(click)="inputSearchCausal.value = ''"
									>
										<mat-icon>close</mat-icon>
									</button>
									<mat-icon matSuffix>search</mat-icon>
								</mat-form-field>
								<mat-option
									*ngFor="
										let causal of auxiliaryTable.causalHeaderSoList
											| filterByString
												: {
														propertyListName: ['description'],
														value: inputSearchCausal.value
												  }
									"
									[value]="causal.code_item"
								>
									{{ causal.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<!-- Data di consegna -->
					<!-- <div class="col-md-6">
						<mat-form-field>
							<mat-label>{{ 'order.field.delivery_date' | translate | sentencecase }}</mat-label>
							<input
								matInput
								formControlName="due_date"
								[matDatepicker]="picker"
								[min]="minDate"
								[max]="maxDate"
								[matDatepickerFilter]="myDateFilter"
								stopPropagation
								readonly
								(click)="picker.open()"
							/>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
							<span
								matPrefix
								*ngIf="utilOrderService.getOrderVariationValue(order, 'first_evasion_date')"
							>
								<mat-icon
									class="mat-icon variation-icon"
									matTooltipClass="allow-break-line"
									[matTooltip]="
										'Valore aggiornato da:&#13;' +
										convertMilllisStringToStringDate(
											utilOrderService.getOrderVariationValue(order, 'first_evasion_date')
										)
									"
								>
									error
								</mat-icon>
							</span>
						</mat-form-field>
					</div> -->

					<!-- Indirizzo di destinazione -->
					<div class="col-md-6">
						<ng-container *ngIf="order.header.goods_destination_code">
							<mat-form-field>
								<span
									matPrefix
									*ngIf="utilOrderService.getOrderVariationValue(order, 'goods_destination')"
								>
									<mat-icon
										class="mat-icon variation-icon"
										matTooltipClass="allow-break-line"
										[matTooltip]="
											'Valore aggiornato da:&#13;' +
											utilOrderService.getFormattedAddress(
												utilOrderService.getOrderVariationValue(order, 'goods_destination')
											)
										"
									>
										error
									</mat-icon>
								</span>
								<mat-label>{{ 'order.field.shipping_address' | translate | sentencecase }}</mat-label>
								<mat-select formControlName="shipping_address" (openedChange)="inputSearch.value = ''">
									<mat-form-field class="filter-input">
										<mat-label>{{ 'destination.filter' | translate | sentencecase }}</mat-label>
										<input matInput type="text" #inputSearch autocomplete="off" />
										<button
											mat-button
											*ngIf="inputSearch.value"
											matSuffix
											mat-icon-button
											aria-label="Clear"
											(click)="inputSearch.value = ''"
										>
											<mat-icon>close</mat-icon>
										</button>
										<mat-icon matSuffix>search</mat-icon>
									</mat-form-field>
									<mat-option
										*ngFor="
											let fullAddress of destinationList
												| filterByString
													: {
															propertyListName: [
																'zip_code',
																'address',
																'locality',
																'country',
																'province'
															],
															value: inputSearch.value
													  }
										"
										[value]="fullAddress.code_erp"
									>
										{{ fullAddress.address.address }},
										{{ fullAddress.address.locality }}
										({{ fullAddress.address.province }})
									</mat-option>
								</mat-select>
							</mat-form-field>
						</ng-container>

						<!-- Nuova Destinazione -->
						<ng-container *ngIf="order.header.goods_destination_new">
							<mat-form-field>
								<mat-label>{{ 'destination.other' | translate | sentencecase }}</mat-label>
								<input matInput type="text" [value]="getNewAddress()" disabled />
								<button
									mat-button
									*ngIf="order.header.status === orderStatusEnum.DRAFT"
									matSuffix
									mat-icon-button
									aria-label="edit"
									(click)="openDialogDestination()"
								>
									<mat-icon>edit</mat-icon>
								</button>
							</mat-form-field>
						</ng-container>
					</div>

					<!-- Metodi di pagamento -->
					<div class="col-md-6">
						<mat-form-field>
							<mat-label>{{ 'order.field.payment_methods' | translate | sentencecase }}</mat-label>

							<mat-select formControlName="payment" (openedChange)="inputSearchPayment.value = ''">
								<mat-form-field class="filter-input">
									<mat-label>{{ 'payment_method.filter' | translate | sentencecase }}</mat-label>
									<input matInput type="text" #inputSearchPayment autocomplete="off" />
									<button
										mat-button
										*ngIf="inputSearchPayment.value"
										matSuffix
										mat-icon-button
										aria-label="Clear"
										(click)="inputSearchPayment.value = ''"
									>
										<mat-icon>close</mat-icon>
									</button>
									<mat-icon matSuffix>search</mat-icon>
								</mat-form-field>
								<mat-option
									*ngFor="
										let payment of auxiliaryTable.paymentList
											| filterByString
												: {
														propertyListName: ['description'],
														value: inputSearchPayment.value
												  }
									"
									[value]="payment.code_item"
								>
									{{ payment.description }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="col-md-6 center-vertically">
						<mat-slide-toggle
							#toggleNewDestination
							(change)="addOtherDestination($event)"
							class="mb-12"
							*ngIf="order.header.status === orderStatusEnum.DRAFT"
						>
							<mat-label>{{ 'destination.use_new' | translate | sentencecase }}</mat-label>
						</mat-slide-toggle>
					</div>

					<!-- Banca -->
					<!-- <div class="col-md-4">
						<mat-form-field>
							<mat-label>{{ 'order.field.bank' | translate | sentencecase }}</mat-label>
							<input matInput formControlName="bank" />
						</mat-form-field>
					</div> -->

					<!-- IBAN -->
					<!-- <div class="col-md-4">
						<mat-form-field>
							<mat-label>{{ 'order.field.iban' | translate | uppercase }}</mat-label>
							<input matInput formControlName="iban" />
						</mat-form-field>
					</div> -->

					<!-- Note -->
					<!-- <div class="col-md-6 note">
						<mat-form-field>
							<mat-label>{{ 'order.field.note' | translate | sentencecase }}</mat-label>
							<textarea rows="1" matInput formControlName="note"></textarea>
						</mat-form-field>
					</div> -->

					<!-- Alerts -->
					<ng-container *ngIf="orderProductListNotFound">
						<div class="col-md-12" *ngFor="let product of orderProductListNotFound">
							<alert [type]="'warning'">
								<p>
									<strong>Attenzione!</strong> Il prodotto con codice {{ product.code }} del tuo
									ordine non è stato trovato.
								</p>
							</alert>
						</div>
					</ng-container>
					<!-- <div class="col-md-12" *ngIf="!order.header.order_causal">
						<alert [type]="'warning'">
							<p>Seleziona la causale per poter aggiungere prodotti al tuo ordine</p>
						</alert>
					</div> -->
					<div class="col-md-12" *ngIf="setTodayDate">
						<alert [type]="'warning'">
							<p>
								<strong>Attenzione!</strong> La consegna in data odierna sarà confermata al ricevimento
								dell'ordine.
							</p>
						</alert>
					</div>
					<div class="col-md-12" *ngIf="setProductPartiallyAvailable && setTodayMinusFiveDaysDate">
						<alert [type]="'warning'">
							<p>
								<strong>Attenzione!</strong> Sono stati selezionati alcuni prodotti disponibili in 72
								ore - 3 giorni, la data di consegna sarà confermata al ricevimento dell'ordine.
							</p>
						</alert>
					</div>
					<div class="col-md-12" *ngIf="setProductNotAvailable">
						<alert [type]="'warning'">
							<p>
								<strong>Attenzione!</strong> Sono stati selezionati alcuni prodotti disponibili su
								ordinazione, la data di consegna sarà confermata al ricevimento dell'ordine.
							</p>
						</alert>
					</div>
				</div>
			</form>
		</ng-container>
	</mat-accordion-wrapper>
</tab-wrapper>
