<div formGroupName="address">
	<div class="row">
		<mat-form-field class="col-6">
			<mat-label>Indirizzo e numero civico</mat-label>
			<input matInput formControlName="address" />
			<mat-error
				*ngIf="
					parentForm
						.get('address')
						.get('address')
						.hasError('required')
				"
			>
				Campo obbligatorio
			</mat-error>
		</mat-form-field>

		<mat-form-field class="col-lg-6">
			<mat-label>Città</mat-label>
			<input matInput formControlName="locality" />
			<mat-error
				*ngIf="
					parentForm
						.get('address')
						.get('locality')
						.hasError('required')
				"
			>
				Campo obbligatorio
			</mat-error>
		</mat-form-field>

		<mat-form-field class="col-lg-6">
			<mat-label>CAP</mat-label>
			<input matInput formControlName="zipCode" placeholder="#####" maxlength="5" />
			<mat-error
				*ngIf="
					parentForm
						.get('address')
						.get('zipCode')
						.hasError('required')
				"
			>
				Campo obbligatorio
			</mat-error>
			<mat-error
				*ngIf="
					parentForm
						.get('address')
						.get('zipCode')
						.hasError('minlength')
				"
			>
				Questo campo deve avere 5 caratteri
			</mat-error>
		</mat-form-field>

		<mat-form-field class="col-lg-3">
			<mat-label>Provincia</mat-label>
			<input matInput formControlName="province" placeholder="##" appUppercase />
			<mat-error
				*ngIf="
					parentForm
						.get('address')
						.get('province')
						.hasError('required')
				"
			>
				Campo obbligatorio
			</mat-error>
		</mat-form-field>

		<mat-form-field class="col-lg-3">
			<mat-label>Nazione</mat-label>
			<input matInput formControlName="country" placeholder="##" appUppercase />
			<mat-error
				*ngIf="
					parentForm
						.get('address')
						.get('country')
						.hasError('required')
				"
			>
				Campo obbligatorio
			</mat-error>
		</mat-form-field>
	</div>
</div>
