import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AddressTypeEnum } from '@saep-ict/pouch_agent_models';
import { DEFAULT_DESTINATION_CODE } from '../../constants/destination.constants';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

/**
 * Contesti in cui viene utilizzato:
 * + B2C
 * + CRM
 */
@Component({
	selector: 'address-form',
	templateUrl: './address-form.component.html',
	styleUrls: ['./address-form.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class AddressFormComponent implements OnChanges {
	@Input() parentForm: FormGroup;
	@Input() organization: OrganizationStateModel;

	constructor(private fb: FormBuilder) {}

	ngOnInit() {}

	ngOnChanges() {
		this.parentForm.addControl(
			'address',
			this.fb.group({
				address: ['', { validators: [Validators.required] }],
				locality: ['', { validators: [Validators.required] }],
				zipCode: ['', { validators: [Validators.required, Validators.minLength(5)] }],
				province: ['', { validators: [Validators.required] }],
				country: ['', { validators: [Validators.required] }]
			})
		);

		if (this.organization && this.organization.destination_list) {
			this.fillForm();
		}
	}

	fillForm() {
		// TODO: qui rimuovi temporaneamente il controllo e prendi la destination con code 000
		// const address = this.organization.destination_list.find(destination => destination.address_type === AddressTypeEnum.REGISTERED_OFFICE).address;
		const address = this.organization.destination_list.find(
			destination => destination.code === DEFAULT_DESTINATION_CODE
		).address;

		this.parentForm['controls']['address'].patchValue({
			address: address.address ? address.address : null,
			locality: address.locality ? address.locality : null,
			zipCode: address.zip_code ? address.zip_code : null,
			province: address.province ? address.province : null,
			country: address.country ? address.country : null
		});
	}
}
