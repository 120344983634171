import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContextApplicationItemCodeEnum } from '@saep-ict/angular-core';
import { PATH_URL, ROUTE_URL } from '../../router/route-naming';

@Injectable({
	providedIn: 'root'
})
export class UtilCompanyService {
	constructor(private router: Router) {}

	navigateToCompanyDetail(companyLevel: string, companyCode: string, contextCode: ContextApplicationItemCodeEnum) {
		if (contextCode && companyCode) {
			switch (contextCode) {
				case ContextApplicationItemCodeEnum.AGENT:
					this.router.navigate([
						`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/${companyLevel}/${companyCode}`
					]);
					break;
				case ContextApplicationItemCodeEnum.BACKOFFICE:
					this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/no-filter/${companyCode}`]);
					break;
				default:
					break;
			}
		} else if (companyCode) {
			this.router.navigate([`${PATH_URL.PRIVATE}/${ROUTE_URL.organizations}/${companyLevel}/${companyCode}`]);
		}
	}
}
