import { ArticleCategory, ArticleCheckoutTree, ArticleCheckoutTreeParseUtil } from './../../model/article.model';
import { Category } from './../../model/category-list.model';
import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
	BodyTablePouchModel,
	CausalHeaderSoPouchModel,
	DestinationPouchModel,
	LocationDepartureDivisionPouchModel,
	OrderPouchModel,
	OrderProductPouchModel,
	PaymentPouchModel,
	OrderStatusEnum,
	DivisionPouchModel,
	OrganizationPouchModel
} from '@saep-ict/pouch_agent_models';
import {
	BaseStateModel,
	DiscountModel,
	DiscountTypeEnum,
	CausalHeaderOrderEnum,
	DeliveryMethodHeaderOrderEnum,
	DiscrepancyOrderEnum,
	UtilService
} from '@saep-ict/angular-core';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ArticleChangeContextModel, ArticleChangeContextResponseModel } from '../../model/order-util.model';
import { ArticleState, OrderProductModel } from '../../model/state/article-list-state.model';
import {
	ExtraFieldOrderHeaderPouchModel,
	OrderStateModel,
	HeaderStateModel
} from '../../model/state/order-state.model';
import { TableOrderModel } from '../../model/table/table-order.model';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';
import { DEFAULT_DIVISION_CODE, IS_MULTIDIVISION } from '../../constants/division.constants';
import { DEFAULT_DESTINATION_CODE } from '../../constants/destination.constants';
import { AuxiliaryTableStateModel } from '../../model/state/auxiliary-table-list';
import {
	DEFAULT_CAUSAL_CODE,
	ExtendedOrganizationPouchModel
} from '../../page/b2c/storefront/checkout/checkout-to-update.model';
import { OrderFilterTypeEnum } from '../../enum/order-filter-type.enum';
import {
	StateRelatedLink,
	statusAggregationMap
} from '../../constants/configuration-customer/order/status-aggregation-map.constant';
import { HighlightsViewModel } from '../../model/home-highlights.model';
import { StateFeature } from '../../state';
import { Store } from '@ngrx/store';
import { skipWhile, take, tap } from 'rxjs/operators';

export interface CustomerMethodDelivery extends DivisionPouchModel {
	description?: string;
}

@Injectable({
	providedIn: 'root'
})
export class UtilOrderService {
	configurationTable$: Observable<BaseStateModel<HighlightsViewModel>> = this.store.select(
		StateFeature.getHomeHighlightsState
	);
	configurationTable: HighlightsViewModel;

	private _agreedPaymentDefault: DivisionPouchModel[] = [
		// {
		// 	payment_condition: 'E0220'
		// },
		// {
		// 	payment_condition: 'E0233'
		// },
		// {
		// 	payment_condition: 'E0235'
		// }
	];

	private _customMethodDelivery: CustomerMethodDelivery[] = [
		{
			method_delivery: DeliveryMethodHeaderOrderEnum.FRANCO_ARRIVO,
			description: 'FRANCO ARRIVO',
			description_extended: 'FRANCO ARRIVO'
		},
		{
			method_delivery: DeliveryMethodHeaderOrderEnum.FRANCO_PARTENZA,
			description: 'FRANCO PARTENZA',
			description_extended: 'FRANCO PARTENZA'
		}
	];
	private formHeaderValiditySource = new BehaviorSubject<boolean>(false);
	currentFormHeaderValidity = this.formHeaderValiditySource.asObservable();
	private propagationSubmitForm$ = new BehaviorSubject<boolean>(false);
	propagationSubmitForm = this.propagationSubmitForm$.asObservable();

	constructor(private utilService: UtilService, private titlecasePipe: TitleCasePipe, private store: Store<any>) {
		this.retrieveSyncState<HighlightsViewModel>(this.configurationTable$).subscribe(res => {
			this.configurationTable = res.data;
		});
	}

	retrieveSyncState<T>(state$: Observable<BaseStateModel<T>>): Observable<BaseStateModel<T>> {
		return state$.pipe(
			skipWhile((state: BaseStateModel<T>) => !(state && state.data)),
			take(1),
			tap((state: BaseStateModel<T>) => {
				return state;
			})
		);
	}

	addOrderId(order: OrderStateModel): OrderStateModel {
		if (order.csuite) {
			if (order.csuite['order_number'] && order.csuite['order_year']) {
				order.header.order_id = `${order.csuite['order_year']}_${order.csuite['order_number']}`;
			}
		}
		return order;
	}

	setNonFreeRowCausal(idCausal: string, causalList: CausalHeaderSoPouchModel[]) {
		const filtered = causalList.filter(i => i.code_item === idCausal)[0];
		return filtered ? filtered.code_item : '';
	}

	formatHexOrderCode(code: string): string {
		if (code) {
			let newCode = code.replace('order_', '');
			newCode = newCode.substr(newCode.length - 12, newCode.length);
			newCode = newCode.toUpperCase();
			return newCode;
		} else {
			return '';
		}
	}

	formatOdvCode(order: any): string {
		if (order && order.csuite && order.csuite.order_so_number) {
			return order.csuite.order_so_number + '/' + order.csuite.order_so_series;
		}
		return '-';
	}

	/**
	 * Recursive function to get first valid date
	 */
	getFirstValidDate(currentDate: Date, day: number) {
		const currentDay = moment(currentDate).day();
		if ([0, 6].includes(currentDay)) {
			const oneDayAfter = moment(currentDate).add(day, 'day').toDate();
			return this.getFirstValidDate(oneDayAfter, day);
		} else {
			return currentDate;
		}
	}

	filterLocationDepartureDivisionList(
		customerDivision: DivisionPouchModel[],
		locationDepartureDivision: LocationDepartureDivisionPouchModel[]
	): LocationDepartureDivisionPouchModel[] {
		const division: string[] = customerDivision.map((div: DivisionPouchModel) => div.division);
		console.log(locationDepartureDivision);
		return locationDepartureDivision.filter(locationDeparture => {
			return division.includes(locationDeparture.division);
		});
	}

	filterPaymentDivisionList(
		customerDivision: DivisionPouchModel[],
		paymentList: PaymentPouchModel[]
	): PaymentPouchModel[] {
		const paymentDivision: string[] = customerDivision.map((div: DivisionPouchModel) => div.payment_condition);
		return paymentList.filter(payment => {
			return paymentDivision.includes(payment.code_item);
		});
	}

	filterMethodDeliveryList(
		customerDivision: DivisionPouchModel[],
		methodDeliveryList: BodyTablePouchModel[],
		filterFunction?: (e: any) => boolean
	): BodyTablePouchModel[] {
		const methodDeliveryDivision: string[] = customerDivision.map((div: DivisionPouchModel) => div.method_delivery);
		const respondeMethodDeliveryList = methodDeliveryList.filter(methodDelivery => {
			return methodDeliveryDivision.includes(methodDelivery.code_item) && filterFunction(methodDelivery);
		});
		return respondeMethodDeliveryList.map(responseMethodDelivery => {
			const customMethodDelivery = customerDivision.find(delivery => {
				return delivery.method_delivery === responseMethodDelivery.code_item;
			});
			if (customMethodDelivery && customMethodDelivery['description']) {
				responseMethodDelivery.description = customMethodDelivery['description'];
			}
			return responseMethodDelivery;
		});
	}

	retrievePriceWithDiscount(price: number, discountList: DiscountModel[], discountAgent?: DiscountModel) {
		if (discountList) {
			discountList.forEach(discount => {
				price = this.calculateDecimalFromDiscount(price, discount.value);
			});
		}
		if (discountAgent) {
			price = this.calculateDecimalFromDiscount(price, discountAgent.value);
		}
		return price;
	}

	calculateDecimalFromDiscount(price: number, discount: number) {
		return price - (price * discount) / 100;
	}

	retrieveTotalArticlePrice(article: ArticleState) {
		const priceWithDiscount = this.retrievePriceWithDiscount(
			article.productDetail.price,
			article.productDetail.discount,
			article.productDetail.discount_agent
		);
		const qtyToPay =
			article.productDetail.input_quantity -
			(article.productDetail.qty_free ? article.productDetail.qty_free : 0);
		return priceWithDiscount * qtyToPay * article.qty_box;
	}

	addArticleToOrder(
		article: ArticleState,
		order: OrderStateModel,
		causalCodeList: CausalHeaderSoPouchModel[]
	): OrderStateModel {
		if (article.productDetail.input_quantity) {
			const productIndex = this.findProductInOrder(article.code, order);
			const currentCausal = causalCodeList.find(causalCode => {
				return causalCode.code_item === order.header.order_causal;
			});
			const orderProduct: OrderProductModel = {
				// sup_row_external
				code: article.code,
				code_company: order.header.society_code,
				order_causal_code: article.group === 'V1' ? 'Ri' : currentCausal.causals_row[0], // todo da verificare
				input_quantity: article.productDetail.input_quantity,
				ordered_quantity: +(article.productDetail.input_quantity * article.qty_box).toFixed(2),
				discount_agent:
					order.header.order_causal === CausalHeaderOrderEnum.NR
						? null
						: article.productDetail.discount_agent,
				discount:
					order.header.order_causal === CausalHeaderOrderEnum.NR ? [] : article?.articlePrice?.discount || [],
				note_order: article.productDetail.note_order,
				price: article.productDetail.price,
				qty_free:
					article.productDetail.qty_free > article.productDetail.input_quantity
						? article.productDetail.input_quantity
						: article.productDetail.qty_free,
				articleDetail: _.cloneDeep(article)
			};
			if (productIndex !== -1) {
				order.product_list[productIndex] = { ...order.product_list[productIndex], ...orderProduct };
				if (order.product_list[productIndex].articleDetail.productDetail) {
					delete order.product_list[productIndex].articleDetail.productDetail;
				}
			} else {
				order.product_list.push(orderProduct);
			}
		}
		return order;
	}

	removeProductFromOrder(code: string, order: OrderStateModel): OrderStateModel {
		const productIndex = this.findProductInOrder(code, order);
		if (productIndex !== -1) {
			order.product_list.splice(productIndex, 1);
		}
		return order;
	}

	findProductInOrder(code: string, order: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>) {
		return order.product_list.findIndex((product: OrderProductPouchModel) => product.code === code);
	}

	findArticleWithCode(code: string, articleList: ArticleState[]) {
		return articleList.findIndex((article: OrderProductPouchModel) => article.code === code);
	}

	get agreedPaymentDefault() {
		return this._agreedPaymentDefault;
	}

	get customMethodDelivery() {
		return this._customMethodDelivery;
	}

	mergeProductDetailInArticle(
		articleList: BaseStateModel<ArticleState[], any>,
		order: OrderPouchModel<ExtraFieldOrderHeaderPouchModel>
	): BaseStateModel<ArticleState[], any> {
		articleList.data.forEach(article => {
			const checkoutProduct = order.product_list.find(product => {
				return product.code === article.code;
			});
			article.productDetail = checkoutProduct ? checkoutProduct : <OrderProductPouchModel>{};
			if (article.productDetail.input_quantity) {
				article.calculate_price = this.retrieveTotalArticlePrice(article);
			} else {
				article.calculate_price = 0;
			}
			// article has no qty_box
			if (!(article.hasOwnProperty('qty_box') && article.qty_box !== null)) {
				article.qty_box = 1;
			}
			return article;
		});
		return articleList;
	}

	mapCurrentArticle(article: OrderProductModel) {
		const cloneArticle: OrderProductModel = _.cloneDeep(article);
		const currentArticle = cloneArticle.articleDetail;
		if (currentArticle) {
			currentArticle.productDetail = article;
			if (currentArticle.productDetail.articleDetail) {
				delete currentArticle.productDetail.articleDetail;
			}
		}
		return currentArticle;
	}

	changeQuantity(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		if (acc.value === 0) {
			response.order = <OrderStateModel>this.removeProductFromOrder(acc.article.code, acc.order);
			return response;
		} else {
			let articleIndex: number;
			let currentArticle: ArticleState;
			if (acc.articleList) {
				articleIndex = this.findArticleWithCode(acc.article.code, acc.articleList);
				currentArticle = acc.articleList[articleIndex];
			} else {
				currentArticle = this.mapCurrentArticle(acc.article);
			}
			currentArticle.productDetail.input_quantity = acc.value;

			currentArticle.productDetail.price = currentArticle.articlePrice.price;

			const currentProductDetail = _.cloneDeep(currentArticle.productDetail);
			currentProductDetail.code = acc.article.code;
			if (!currentProductDetail.articleDetail) {
				currentArticle.code = acc.article.code;
				const cloneCurrentArticle = _.cloneDeep(currentArticle);
				delete cloneCurrentArticle.productDetail;
				currentProductDetail.articleDetail = cloneCurrentArticle;
			}

			// when article price is 0
			// if (!currentArticle.productDetail.price) {
			// 	return false;
			// }

			response = {
				currentArticle: currentArticle,
				articleIndex: articleIndex,
				order: <OrderStateModel>(
					this.addArticleToOrder(currentArticle, acc.order, acc.auxiliaryTable.causalHeaderSoList)
				)
			};
			return response;
		}
	}

	changeLastPrice(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		let articleIndex: number;
		let currentArticle: ArticleState;
		if (acc.articleList) {
			articleIndex = this.findArticleWithCode(acc.article.code, acc.articleList);
			currentArticle = acc.articleList[articleIndex];
		} else {
			currentArticle = this.mapCurrentArticle(acc.article);
		}
		currentArticle.productDetail.price = acc.value;
		if (!currentArticle.productDetail.input_quantity) {
			return false;
		}
		response = {
			currentArticle: currentArticle,
			articleIndex: articleIndex,
			order: <OrderStateModel>(
				this.addArticleToOrder(currentArticle, acc.order, acc.auxiliaryTable.causalHeaderSoList)
			)
		};
		return response;
	}

	changeQuantityFree(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		let articleIndex: number;
		let currentArticle: ArticleState;
		if (acc.articleList) {
			articleIndex = this.findArticleWithCode(acc.article.code, acc.articleList);
			currentArticle = acc.articleList[articleIndex];
		} else {
			currentArticle = this.mapCurrentArticle(acc.article);
		}
		currentArticle.productDetail.qty_free = acc.value;
		response = {
			currentArticle: currentArticle,
			articleIndex: articleIndex,
			order: <OrderStateModel>(
				this.addArticleToOrder(currentArticle, acc.order, acc.auxiliaryTable.causalHeaderSoList)
			)
		};
		return response;
	}

	changeDelayDate(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		let articleIndex: number;
		let currentArticle: ArticleState;
		if (acc.articleList) {
			articleIndex = this.findArticleWithCode(acc.article.code, acc.articleList);
			currentArticle = acc.articleList[articleIndex];
		} else {
			currentArticle = this.mapCurrentArticle(acc.article);
		}
		response = {
			currentArticle: currentArticle,
			articleIndex: articleIndex,
			order: <OrderStateModel>(
				this.addArticleToOrder(currentArticle, acc.order, acc.auxiliaryTable.causalHeaderSoList)
			)
		};
		return response;
	}

	changeDiscountAgent(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		let articleIndex: number;
		let currentArticle: ArticleState;
		if (acc.articleList) {
			articleIndex = this.findArticleWithCode(acc.article.code, acc.articleList);
			currentArticle = acc.articleList[articleIndex];
		} else {
			currentArticle = this.mapCurrentArticle(acc.article);
		}
		currentArticle.productDetail.discount_agent = { value: acc.value, type: DiscountTypeEnum.percentage };
		response = {
			currentArticle: currentArticle,
			articleIndex: articleIndex,
			order: <OrderStateModel>(
				this.addArticleToOrder(currentArticle, acc.order, acc.auxiliaryTable.causalHeaderSoList)
			)
		};
		return response;
	}

	changeNote(acc: ArticleChangeContextModel): ArticleChangeContextResponseModel | boolean {
		let response: ArticleChangeContextResponseModel = {};
		let articleIndex: number;
		let currentArticle: ArticleState;
		if (acc.articleList) {
			articleIndex = this.findArticleWithCode(acc.article.code, acc.articleList);
			currentArticle = acc.articleList[articleIndex];
		} else {
			currentArticle = this.mapCurrentArticle(acc.article);
		}
		if (!currentArticle.productDetail.input_quantity) {
			return false;
		}
		response = {
			currentArticle: currentArticle,
			articleIndex: articleIndex,
			order: <OrderStateModel>(
				this.addArticleToOrder(currentArticle, acc.order, acc.auxiliaryTable.causalHeaderSoList)
			)
		};
		return response;
	}

	changeFormHeaderValidity(validity: boolean) {
		this.formHeaderValiditySource.next(validity);
	}

	propagationSubmitFormPopulate() {
		this.propagationSubmitForm$.next(true);
	}

	/**
	 *
	 * @param order This is the order
	 * @param key This is the property of the order to check in order.header.order_progress_detail
	 */
	getOrderVariationValue(order: OrderStateModel, key: string) {
		if (key && order && order.header && order.header.order_progress_detail) {
			if (order.header.order_progress_detail[key]) {
				return order.header.order_progress_detail[key];
			} else {
				return null;
			}
		}
	}

	getFormattedAddress(destination: DestinationPouchModel) {
		const address = destination.address;
		let formattedAddress = '';
		if (address) {
			formattedAddress =
				(address.locality ? address.locality + ', ' : '-') +
				(address.zip_code ? address.zip_code + ' - ' : '') +
				(address.address ? this.titlecasePipe.transform(address.address) : '-');
		}
		return formattedAddress;
	}

	checkDiscrepancies(
		order: OrderStateModel,
		contextResponse?: ArticleChangeContextResponseModel,
		defaultPayment?: PaymentPouchModel
	) {
		if (!order.header.discrepancy_list) {
			order.header.discrepancy_list = [];
		}

		// DiscrepancyOrderEnum.DISCOUNT_TYPE
		if (order.product_list.some(pl => pl.discount_agent && !!pl.discount_agent.value)) {
			this.addDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.DISCOUNT_TYPE);
		} else {
			this.removeDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.DISCOUNT_TYPE);
		}

		// DiscrepancyOrderEnum.NET_PRICE_DISCOUNT
		if (
			contextResponse &&
			order.header.order_causal == CausalHeaderOrderEnum.NR &&
			order.product_list.some(pl => {
				const fifty = (contextResponse.currentArticle.articlePrice.price * 50) / 100;
				return pl.price < contextResponse.currentArticle.articlePrice.price - (fifty + (fifty * 5) / 100);
			})
		) {
			this.addDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.NET_PRICE_DISCOUNT);
		} else {
			this.removeDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.NET_PRICE_DISCOUNT);
		}

		// DiscrepancyOrderEnum.PAYMENT_TYPE
		if (defaultPayment && defaultPayment.code_item != order.header.payment_code) {
			this.addDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.PAYMENT_TYPE);
		} else {
			this.removeDiscrepancyElement(order.header.discrepancy_list, DiscrepancyOrderEnum.PAYMENT_TYPE);
		}
	}

	addDiscrepancyElement(discrepancy_list: DiscrepancyOrderEnum[], discrepancyType: DiscrepancyOrderEnum) {
		if (discrepancy_list.indexOf(discrepancyType) == -1) {
			discrepancy_list.push(discrepancyType);
		}
	}

	removeDiscrepancyElement(discrepancy_list: DiscrepancyOrderEnum[], discrepancyType: DiscrepancyOrderEnum) {
		const indexToRemove = discrepancy_list.indexOf(discrepancyType);
		if (indexToRemove !== -1) {
			discrepancy_list.splice(indexToRemove, 1);
		}
	}

	articleTotalCount(article: OrderProductModel): number {
		return article.articleDetail.calculate_price;
		// + servizi extra
	}

	/**
	 * Restituisce gli gli articoli di order.product_list nella struttura necessaria ai componenti
	 * - article-checkout-tree
	 * - article-checkout-item
	 *
	 * @param order: OrderStateModel
	 * @param category_list: Category<ArticleCategory>[]
	 */
	returnArticleCheckoutTree(
		order: OrderStateModel,
		category_list: Category<ArticleCategory>[]
	): Promise<ArticleCheckoutTree[]> {
		let articleCheckoutTreeList: ArticleCheckoutTree[] = [];
		return this.articleCheckoutTreeRecursivelyParse(order, category_list).then(
			(res: ArticleCheckoutTreeParseUtil) => {
				articleCheckoutTreeList = res.article_checkout_tree;
				// in caso siano ancora presenti articoli in order.product_list non appartenenti a nessuna categoria
				// vengono organizzati in una catagoria di primo livello
				if (res.order.product_list && res.order.product_list.length > 0) {
					const articleListWithoutCategory = <ArticleCheckoutTree>{};
					articleListWithoutCategory.description = 'Articles without Category';
					articleListWithoutCategory.article_list = order.product_list;
					articleCheckoutTreeList.push(articleListWithoutCategory);
				}
				return articleCheckoutTreeList;
			}
		);
	}

	// TODO: gli articoli presenti in più di una categoria vengono visualizzati soltanto nella prima
	// individuata dal flussoin ogni branch di appartenenza

	/**
	 * Metodo ricorsivo che parsa il category-list inserendo in ogni branch gli eventuali articoli di order.product_list
	 * associati alla categoria
	 *
	 * @param order: OrderStateModel
	 * @param category_list: Category<ArticleCategory>[]
	 */
	articleCheckoutTreeRecursivelyParse(
		order: OrderStateModel,
		category_list: Category<ArticleCategory>[]
	): Promise<ArticleCheckoutTreeParseUtil> {
		const promises = [];
		const articleCheckoutTreeList: ArticleCheckoutTree[] = [];
		promises.push(
			category_list.forEach(async category => {
				const articleCheckoutTreeItem = <ArticleCheckoutTree>{
					description: category.description,
					category_list: [],
					article_list: []
				};
				// per ogni categoria, a qualsiasi livello di annidamento, viene controllata
				// l'eventuale appartenenza di uno degli articoli dell'ordine
				order.product_list.forEach(article => {
					article.articleDetail.categoryNodeList.forEach(categoryNode => {
						if (categoryNode.hierarchy.includes(category._id)) {
							if (category.children.includes(categoryNode._id)) {
								articleCheckoutTreeItem.article_list.push(article);
								// viene rimosso l'articolo da order.product_list in modo da organizzare eventuali
								// rimanenti in una categoria di primo livello. Vedere returnArticleCheckoutTree()
								order.product_list.splice(
									this.utilService.getElementIndex(order.product_list, 'code', article.code),
									1
								);
							}
						}
					});
				});
				if (category.category_list && category.category_list.length > 0) {
					await this.articleCheckoutTreeRecursivelyParse(order, category.category_list).then(res => {
						articleCheckoutTreeItem.category_list = res.article_checkout_tree;
						if (articleCheckoutTreeItem.category_list && articleCheckoutTreeItem.category_list.length > 0) {
							articleCheckoutTreeList.push(articleCheckoutTreeItem);
						}
					});
				} else {
					if (articleCheckoutTreeItem.article_list && articleCheckoutTreeItem.article_list.length > 0) {
						articleCheckoutTreeList.push(articleCheckoutTreeItem);
					}
				}
			})
		);
		return Promise.all(promises).then(res => {
			return { article_checkout_tree: articleCheckoutTreeList, order: order };
		});
	}

	/**
	 * This function returns the order list with more properties to easily show in the table
	 * @param orderList
	 */
	getTableOrderList(orderList: OrderStateModel[]): TableOrderModel[] {
		let tableOrderList: TableOrderModel[] = [];

		tableOrderList = orderList
			.filter((order: OrderStateModel) => order.header)
			.map((order: OrderStateModel) => {
				const temp: TableOrderModel = order;
				temp.id_first_row = this.getArticleIdFirstRow(order, order.header.status);
				temp.id_second_row = this.getArticleIdSecondRow(order, order.header.status);
				return temp;
			});
		return tableOrderList;
	}

	/**
	 * Utility function to isolate the logic behind id_first_row
	 */
	getArticleIdFirstRow(order: OrderStateModel, status: OrderStatusEnum): string {
		let id_first_row: string;

		// if (
		// 	status === OrderStatusEnum.CONSOLIDATED ||
		// 	status === OrderStatusEnum.PARTIALLY_FULFILLED ||
		// 	status === OrderStatusEnum.FULFILLED ||
		// 	status === OrderStatusEnum.PROCESSING
		// ) {
		id_first_row = this.formatOdvCode(order);
		// } else {
		// 	id_first_row = this.formatHexOrderCode(order._id);
		// }

		return id_first_row;
	}

	/**
	 * Utility function to isolate the logic behind id_second_row
	 */
	getArticleIdSecondRow(order: OrderStateModel, status: OrderStatusEnum): string {
		let id_second_row: string = null;

		if (order.csuite && order.csuite.order_so_year) {
			id_second_row = order.csuite.order_so_year.toString();
		}

		return id_second_row;
	}

	/**
	 * Setup order info for storefront (reusable for other contexts too)
	 */
	setOrder(order: OrderStateModel, organization: OrganizationPouchModel, auxiliaryTable: AuxiliaryTableStateModel) {
		order.header.organization.code_item = organization.code;
		order.header.society_code = organization.code_company || this.configurationTable.society_code;
		order.header.organization.code_item = (organization as ExtendedOrganizationPouchModel).code_item;
		// TODO: DA DEPRECARE
		// order.header.company = {
		// 	business_name: organization.business_name,
		// 	piva: organization.tax_data ? organization.tax_data.vat_number : null
		// };
		order.header.goods_destination_code = DEFAULT_DESTINATION_CODE;
		order.header.division = this.getDivision();
		order.header.order_causal = this.getOrganizationPreference(
			'order_causal',
			auxiliaryTable.causalHeaderSoList,
			organization
		);
		if (!order.header.order_causal) {
			order.header.order_causal = DEFAULT_CAUSAL_CODE;
		}
		order.header.payment_code = this.getOrganizationPreference(
			'payment_condition',
			auxiliaryTable.paymentList,
			organization
		);
	}

	/**
	 * Auto detect division for organization
	 */
	getDivision() {
		if (IS_MULTIDIVISION) {
			// TODO - Handle multidivision
			return null;
		} else {
			// division da configuration table, altrimenti prende il default
			return this.configurationTable.division || DEFAULT_DIVISION_CODE;
		}
	}

	getOrganizationPreference(
		propertyOrganizationDivision: string,
		collection: BodyTablePouchModel[],
		organization: OrganizationPouchModel
	): string {
		let result = '';

		const organizationDivision: DivisionPouchModel = organization.division_list.find(
			division => division.division === this.getDivision()
		);
		if (organizationDivision && organizationDivision[propertyOrganizationDivision]) {
			const defaultObj = collection.find(
				item => item.code_item === organizationDivision[propertyOrganizationDivision]
			);

			if (defaultObj && defaultObj.code_item) {
				result = defaultObj.code_item;
			}
		}

		return result;
	}

	getPriceFromDivision(divisions) {
		const defaultDivision = divisions ? divisions.find(d => d.division == DEFAULT_DIVISION_CODE) : null;
		return defaultDivision ? defaultDivision.price : 0;
	}

	/**
	 * Utility function che permette di restituire un array di stati collegati tra loro in base all'oggetto orderStatusMacroState.
	 * Se non c'è corrispondenza, viene restituito un array con il solo stato passato come parametro
	 * @param state Stato dell'ordine da interrogare
	 */
	public getOrderStatusRelatedStates(
		state: OrderStatusEnum | OrderFilterTypeEnum,
		context: 'BACKOFFICE' | 'AGENT' | 'B2B'
	): OrderStatusEnum[] {
		let statusList = [];

		const stateRelatedLink: StateRelatedLink[] = statusAggregationMap[context];
		if (stateRelatedLink) {
			const macroStatus = stateRelatedLink.find((s: StateRelatedLink) => s.related_list.includes(state));
			if (macroStatus) {
				statusList.push(...macroStatus.related_list);
			}
		}

		return statusList;
	}

	/**
	 * Utility function per recuperare i macro stati in base all'oggetto orderStatusMacroState.
	 */
	public getOrderStatusMacroStates(context: 'BACKOFFICE' | 'AGENT'): OrderStatusEnum[] {
		let statusList = [];

		const stateRelatedLink: StateRelatedLink[] = statusAggregationMap[context];
		if (stateRelatedLink) {
			stateRelatedLink.forEach(s => statusList.push(s.state));
		}
		return statusList;
	}

	public getMacroStateFromRelatedStatus(state: OrderStatusEnum, context: 'BACKOFFICE' | 'AGENT' | 'B2B') {
		return statusAggregationMap[context].find(link => link.related_list.includes(state)).state;
	}

	/**
	 * Se il prodotto non ha input_quantity valorizzato ma ha ordered_quantity valorizzato
	 * copio il valore per la casistica di articoli aggiunti da AS400
	 */
	fillInputQuantityWithOrderedQuantity(productList: OrderProductModel[]): OrderProductModel[] {
		productList.map((product: OrderProductModel) => {
			if (!product.input_quantity && product.ordered_quantity) {
				if (product.articleDetail && product.articleDetail.qty_box) {
					product.input_quantity = +(product.ordered_quantity / product.articleDetail.qty_box).toFixed(2);
				}
			}
		});
		return productList;
	}
}
