import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseStateModel, ContextApplicationItemCodeEnum, LinkCodeModel, UserDetailModel } from '@saep-ict/angular-core';
import { LocalStorage } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { CouchDocumentType } from '../../constants/context-state.map';
import { PouchCustomAdapterEnum } from '../../enum/pouch-custom-adapter.enum';
import { StateFeature } from '../../state';
import { PermissionUtilService } from '../util/permission-util.service';
import { PouchDbAgentAdapter } from './spin8/pouchdb-agent.adapter';
import { PouchDbBackofficeAdapter } from './spin8/pouchdb-backoffice.adapter';
import { PouchDbCommonsAgentAdapter } from './spin8/pouchdb-commons-agent.adapter';
import { PouchDbCommonsArticleAdapter } from './spin8/pouchdb-commons-article.adapter';
import { PouchDbCommonsBackofficeAdapter } from './spin8/pouchdb-commons-backoffice.adapter';
import { PouchDbCommonsOrderAdapter } from './spin8/pouchdb-commons-order.adapter';
import { PouchDbCommonsOrganizationAdapter } from './spin8/pouchdb-commons-organization.adapter';
import { PouchDbCommonsUserAdapter } from './spin8/pouchdb-commons-user.adapter';
import { PouchDbOrganizationB2CAdapter } from './spin8/pouchdb-organization-b2c.adapter';
import { PouchDbSpin8AgentAdapter } from './spin8/pouchdb-spin8-agent.adapter';
import { CustomerAppConfig } from '../../customer-app.config';
import { PouchUtilService } from '@saep-ict/pouch-db';
import { PouchDbCrmAdapter } from './spin8/pouchdb-crm.adapter';
import { PouchDbCommonsContactAdapter } from './spin8/pouchdb-commons-contact.adapter';
import { PouchDbOrganizationB2BAdapter } from './spin8/pouchdb-organization-b2b.adapter';

const AdapterSetting = [
	{
		key: ContextApplicationItemCodeEnum.AGENT,
		adapter: PouchCustomAdapterEnum.AGENT
	},
	{
		key: ContextApplicationItemCodeEnum.B2B,
		adapter: PouchCustomAdapterEnum.ORGANIZATION_B2B
	},
	{
		key: ContextApplicationItemCodeEnum.B2C,
		adapter: PouchCustomAdapterEnum.ORGANIZATION_B2C
	},
	// {
	// 	key: ContextApplicationItemCodeEnum.BACKOFFICE_ADMIN,
	// 	adapter: PouchCustomAdapterEnum.GENERAL
	// },
	{
		key: ContextApplicationItemCodeEnum.BACKOFFICE,
		adapter: PouchCustomAdapterEnum.BACKOFFICE
	},
	{
		key: ContextApplicationItemCodeEnum.CRM,
		adapter: PouchCustomAdapterEnum.CRM
	}
];

@Injectable()
export class PouchAdapterSelectorService {
	@LocalStorage('link_code') link_code: LinkCodeModel;

	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		private permissionUtilService: PermissionUtilService,
		private store: Store<any>,
		private appConfig: CustomerAppConfig,
		private pouchUtilService: PouchUtilService,
		private pouchDbAgentAdapter: PouchDbAgentAdapter,
		private pouchDbGeneralAdapter: PouchDbSpin8AgentAdapter,
		private pouchDbOrganizationB2CAdapter: PouchDbOrganizationB2CAdapter,
		private pouchDbOrganizationB2BAdapter: PouchDbOrganizationB2BAdapter,
		private pouchDbBackofficeAdapter: PouchDbBackofficeAdapter,
		private pouchDbCrmAdapter: PouchDbCrmAdapter,
		private pouchDbCommonsOrganizationAdapter: PouchDbCommonsOrganizationAdapter,
		private pouchDbCommonsArticleAdapter: PouchDbCommonsArticleAdapter,
		private pouchDbCommonsOrderAdapter: PouchDbCommonsOrderAdapter,
		private pouchDbCommonsUserAdapter: PouchDbCommonsUserAdapter,
		private pouchDbCommonsAgentAdapter: PouchDbCommonsAgentAdapter,
		private pouchDbCommonsBackofficeAdapter: PouchDbCommonsBackofficeAdapter,
		private pouchDbCommonsContactAdapter: PouchDbCommonsContactAdapter
	) {
		this.user$.pipe().subscribe(user => {
			this.user = user ? user.data : null;
		});
	}

	/**
	 * Restituisce l'adapter corretto in base al contesto in maniera dinamica
	 * @param documentType
	 */
	async retrieveCurrentAdapter(
		documentType?: CouchDocumentType
	): Promise<
		| PouchDbAgentAdapter
		| PouchDbSpin8AgentAdapter
		| PouchDbOrganizationB2CAdapter
		| PouchDbOrganizationB2BAdapter
		| PouchDbBackofficeAdapter
		| PouchDbCrmAdapter
		| PouchDbCommonsOrganizationAdapter
		| PouchDbCommonsArticleAdapter
		| PouchDbCommonsOrderAdapter
		| PouchDbCommonsUserAdapter
		| PouchDbCommonsAgentAdapter
		| PouchDbCommonsBackofficeAdapter
		| PouchDbCommonsContactAdapter
	> {
		// verifico se l'utente deve accedere a uno degli adapter per i commons piuttosto che (invece che) per il suo db specifico
		if (
			documentType &&
			this.user &&
			this.permissionUtilService.checkIfUserCanAccessCommons(
				this.user,
				this.permissionUtilService.getPermissionToCheckForStateType(documentType)
			)
		) {
			const commonsAdapterToUse = this.retrieveCommonsAdapter(documentType);
			const toStartDbList = [
				{
					endpoint: this.appConfig.config.couch[0].endpoint,
					database: commonsAdapterToUse.database,
					baseDatabaseTemplate: commonsAdapterToUse.baseDatabaseTemplate

				}
			];
			await this.pouchUtilService.explicitInitCouch(toStartDbList).catch(err => {
				throw new Error('Cannot start specific database');
			});
			return commonsAdapterToUse;
		}
		const currentAdapter = AdapterSetting.find(pouchAdapter => pouchAdapter.key == this.link_code.context);
		if (currentAdapter) {
			return this[currentAdapter.adapter];
		}
		return null;
	}

	/**
	 * Restituisce l'adapter commons per il tipo di documento richiesto
	 * @param documentType tipo di documento richiesto
	 */
	retrieveCommonsAdapter(documentType: CouchDocumentType) {
		switch (documentType) {
			case CouchDocumentType.AGENT:
				return this.pouchDbCommonsAgentAdapter;
			case CouchDocumentType.ARTICLE:
				return this.pouchDbCommonsArticleAdapter;
			case CouchDocumentType.BACKOFFICE:
			case CouchDocumentType.CRM:
				return this.pouchDbCommonsBackofficeAdapter;
			case CouchDocumentType.CONTACT:
				return this.pouchDbCommonsContactAdapter;
			case CouchDocumentType.ORDER:
				return this.pouchDbCommonsOrderAdapter;
			case CouchDocumentType.ORGANIZATION:
				return this.pouchDbCommonsOrganizationAdapter;
			case CouchDocumentType.USER:
				return this.pouchDbCommonsUserAdapter;
		}
	}
}
