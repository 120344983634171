import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// model
import { OrderStateModel } from '../../../model/state/order-state.model';
import { UtilOrderService } from '../../../service/util/util-order.service';
import { OrderRowModel } from '../../../model/order-util.model';
import { ArticleCheckoutTree } from '../../../model/article.model';

@Component({
	selector: 'article-checkout-tree',
	templateUrl: './article-checkout-tree.component.html',
	styleUrls: ['./article-checkout-tree.component.scss']
})
export class ArticleCheckoutTreeComponent implements OnInit {
	@Input() articleCheckoutTree: ArticleCheckoutTree[];
	@Input() order: OrderStateModel;
	@Input() level = 1;

	@Output() articleChangeEmitter = new EventEmitter<OrderRowModel>();

	constructor(public utilOrderService: UtilOrderService) {}

	ngOnInit() {}
}
