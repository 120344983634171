import { RestBasePk } from '@saep-ict/angular-core';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { OpportunityPouchModel } from '../../../../model/state/opportunity-state.model';
import { PouchDeleteResponse } from '../../model/pouch-base-response.model';

export class OpportunityPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getOpportunityDetail(data: RestBasePk): Promise<OpportunityPouchModel> {
		return new Promise((resolve, reject) => {
			this.get(data.id)
				.then((doc: OpportunityPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putOpportunity(data: OpportunityPouchModel, isGuid: boolean): Promise<OpportunityPouchModel> {
		const guid = isGuid ? 'opportunity' : data._id;
		if (!data.type) {
			data.type = 'opportunity';
		}
		return new Promise((resolve, reject) => {
			this.put<OpportunityPouchModel>(data, guid, isGuid)
				.then((doc: OpportunityPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteOpportunity(opportunity: OpportunityPouchModel): Promise<PouchDeleteResponse> {
		return new Promise((resolve, reject) => {
			this.delete(opportunity)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
