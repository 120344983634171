import { Injectable } from '@angular/core';
import { BaseApiService } from './baseApi.service';
import { ResourceAction, ResourceRequestMethod, IResourceMethodPromise } from '@ngx-resource/core';
import { AccessRequestModel } from '../../model/access-request.model';

export class AccessRequestService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/public/authentication/access-request'
	})
	accessRequest: IResourceMethodPromise<any, AccessRequestModel>;
}
