<div class="icon-trend">
<mat-icon [ngClass]="{ warn: value < 0, success: value >= 0 }">
	<ng-container *ngIf="value < 0">
		trending_down
	</ng-container>
	<ng-container *ngIf="value == 0">
		trending_flat
	</ng-container>
	<ng-container *ngIf="value > 0">
		trending_up
	</ng-container>
</mat-icon>
{{ value > 0 ? '+' : '' }}
{{ this.value }} %
</div>