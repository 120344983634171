import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { BaseStateModel } from '@saep-ict/angular-core';
import { OrderStateModel } from '../../model/state/order-state.model';
import { OrderActionEnum, OrderStateAction } from './order.actions';
import { PouchDeleteResponse } from '../../service/pouch-db/model/pouch-base-response.model';
import { OrderFilterModel } from '../../service/pouch-db/filter/order-filter.model';

export namespace OrderStateReducer {
	export const initialState: BaseStateModel<OrderStateModel | PouchDeleteResponse, OrderFilterModel> = null;

	const _reducer = createReducer(
		initialState,
		on(OrderStateAction.load, state => state),
		on(OrderStateAction.update, (state, order) => order),
		on(OrderStateAction.save, state => state),
		on(OrderStateAction.remove, (state, order) => ({ data: state ? state.data : null, type: order.type })),
		on(OrderStateAction.removed, (state, orderRemoved) => orderRemoved),
		on(OrderStateAction.completed, (state, order) => ({ data: state ? state.data : null, type: order.type })),
		on(OrderStateAction.reset, () => initialState),
		on(OrderStateAction.error, (state, order) => ({ data: state ? state.data : null, type: order.type }))
	);

	export function reducer(state: BaseStateModel<OrderStateModel>, action: TypedAction<OrderActionEnum>) {
		return _reducer(state, action);
	}
}
