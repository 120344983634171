import { createAction, props } from '@ngrx/store';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '@saep-ict/angular-core';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';

export enum ContactActionEnum {
	UPDATE = '[contact] Update',
	LOAD = '[contact] Load',
	LOAD_ALL = '[contact] Load All',
	RESET = '[contact] Reset',
	ERROR = '[contact] Error',
	SAVE = '[contact] save_contact',
	REMOVE = '[contact] Remove Contact',
	REMOVED = '[contact] Contact Removed'
}

export namespace ContactStateAction {
	export const load = createAction(
		ContactActionEnum.LOAD,
		props<BaseStateModel<ContactPouchModel, { _id: string }>>()
	);
	export const loadAll = createAction(ContactActionEnum.LOAD_ALL);
	export const update = createAction(
		ContactActionEnum.UPDATE,
		props<BaseStateModel<ListDataPouchModel<ContactPouchModel>>>()
	);
	export const save = createAction(ContactActionEnum.SAVE, props<ContactPouchModel>());
	export const reset = createAction(ContactActionEnum.RESET);
	export const error = createAction(ContactActionEnum.ERROR, props<BaseStateModel<any>>());
	export const remove = createAction(ContactActionEnum.REMOVE, props<BaseStateModel<ContactPouchModel>>());
	export const removed = createAction(ContactActionEnum.REMOVED);
}
