import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	BaseStateModel,
	BaseStateSerializerModel,
	PermissionAuxiliaryTableStateModel,
	UserActivationStateModel,
	UserDetailModel,
	ContextCodeAssociation
} from '@saep-ict/angular-core';
import {
	ArticlePouchModel,
	DestinationPouchModel,
	ListDataPouchModel,
	OrderPouchModel
} from '@saep-ict/pouch_agent_models';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { OrderStatisticsList } from '@saep-ict/pouch_agent_models/model/chart-pouch.model';

import { ArticleCategory } from '../model/article.model';
import { Category } from '../model/category-list.model';
import { ConnectionModel } from '../model/connection.model';
import { HighlightsViewModel } from '../model/home-highlights.model';
import { ArticleRecap, ArticleRecapArticleList, ArticleState } from '../model/state/article-list-state.model';
import { AuxiliaryTableStateModel } from '../model/state/auxiliary-table-list';
import { ExtraFieldOrderHeaderPouchModel, OrderStateModel } from '../model/state/order-state.model';
import { StatisticsAgent } from '../model/statistics-agent.model';
import { StatisticsOrders } from '../model/statistics-orders.model';
import { ArticleListFilterModel } from '../service/pouch-db/filter/article-list-filter.model';
import { ContactPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerCreditPouchModelCustom } from './company-account-balance/company-account-balance.reducer';
import { OpportunityPouchModel } from '../model/state/opportunity-state.model';
import { ReceiptStateModel } from '../model/state/receipt-state.model';
import { OrganizationStateModel } from '../model/state/organization-state.model';
import { OfferStateModel } from '../model/state/offer-state.model';
import { InformativePageModel } from '../model/informative-page.model';
import { KanbanPouchModel } from '../model/state/kanban-state.model';
import { StatisticsOrganization } from '../model/statistics-organization.model';
import { StatisticsBackoffice } from '../model/statistics-backoffice.model';
import { StatisticsCrm } from '../model/statistics-crm.model';
import { StatisticsDetailOrders } from '../model/statistics-detail-orders.model';
import { StatisticsDetailSold } from '../model/statistics-detail-sold.model';
import { StatisticsDetailClients } from '../model/statistics-detail-clients.model';
import { StatisticsDetailBalance } from '../model/statistics-detail-balance.model';
import { LanguageStateModel } from '../model/state/language-state.model';

export namespace StateFeature {
	export const getConnectionState = createFeatureSelector<BaseStateModel<ConnectionModel>>('connection');

	// Login
	export const getLoginContextCodeState = createFeatureSelector<BaseStateModel<BodyTablePouchModel>>(
		'login-context-code'
	);

	// Languages
	export const getLanguageList = createFeatureSelector<BaseStateModel<LanguageStateModel[]>>('language-list');

	export const getUserState = createFeatureSelector<BaseStateModel<UserDetailModel>>('user');
	export const getAgentState = createFeatureSelector<BaseStateModel<BodyTablePouchModel>>('agent');

	// organization
	export const getOrganizationListState = createFeatureSelector<BaseStateModel<OrganizationStateModel[]>>(
		'organization-list'
	);
	export const getOrganizationState = createFeatureSelector<BaseStateModel<OrganizationStateModel>>('organization');

	export const getUserActivateState = createFeatureSelector<BaseStateModel<UserActivationStateModel>>(
		'user-activate'
	);
	export const getOrderState = createFeatureSelector<BaseStateModel<OrderStateModel>>('order');
	export const getOrderListState = createFeatureSelector<
		BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>
	>('order-list');
	export const getAuxiliaryTableState = createFeatureSelector<BaseStateModel<AuxiliaryTableStateModel>>(
		'auxiliary-table'
	);
	export const getAuxiliaryTableItemState = createSelector(
		getAuxiliaryTableState,
		(state, param: BaseStateSerializerModel<AuxiliaryTableStateModel>) => state.data[param.stateSlice.type]
	);

	export const getDestination = createFeatureSelector<BaseStateModel<DestinationPouchModel>>('destination');
	export const getDestinationList = createFeatureSelector<BaseStateModel<DestinationPouchModel[]>>(
		'destination-list'
	);
	export const getOrderStatistics = createFeatureSelector<BaseStateModel<OrderStatisticsList>>('order-statistics');
	export const getContact = createFeatureSelector<BaseStateModel<ListDataPouchModel<ContactPouchModel>>>('contact');

	// Statistics
	export const getStatisticsAgent = createFeatureSelector<BaseStateModel<StatisticsAgent>>('statistics-agent');
	export const getStatisticsOrganization = createFeatureSelector<BaseStateModel<StatisticsOrganization>>(
		'statistics-organization'
	);
	export const getStatisticsCrm = createFeatureSelector<BaseStateModel<StatisticsCrm>>('statistics-crm');
	export const getStatisticsBackoffice = createFeatureSelector<BaseStateModel<StatisticsBackoffice>>(
		'statistics-backoffice'
	);
	export const getStatisticsOrders = createFeatureSelector<BaseStateModel<StatisticsOrders>>('statistics-orders');
	export const getStatisticsDetailOrders = createFeatureSelector<BaseStateModel<StatisticsDetailOrders>>(
		'statistics-detail-orders'
	);
	export const getStatisticsDetailSold = createFeatureSelector<BaseStateModel<StatisticsDetailSold>>(
		'statistics-detail-sold'
	);
	export const getStatisticsDetailClients = createFeatureSelector<BaseStateModel<StatisticsDetailClients>>(
		'statistics-detail-clients'
	);
	export const getStatisticsDetailExpired = createFeatureSelector<BaseStateModel<StatisticsDetailBalance>>(
		'statistics-detail-expired'
	);

	export const getArticleList = createFeatureSelector<BaseStateModel<ArticleRecapArticleList[]>>('article-list');

	export const getArticleDescription = createFeatureSelector<BaseStateModel<ArticleRecap>>('article-description');

	// CompanyAccountBalance
	export const getCompanyAccountBalance = createFeatureSelector<BaseStateModel<CustomerCreditPouchModelCustom>>(
		'company-account-balance'
	);
	// category list
	export const getCategoryListState = createFeatureSelector<
		BaseStateModel<Category<ArticleCategory>[], ArticleListFilterModel>
	>('category-list');
	export const getPermissionAuxiliaryTableState = createFeatureSelector<
		BaseStateModel<PermissionAuxiliaryTableStateModel>
	>('permission-auxiliary-table');
	// context-code-association
	export const getContextCodeAssociationState = createFeatureSelector<BaseStateModel<ContextCodeAssociation>>(
		'context-code-association'
	);

	// receipts
	export const getReceiptListState = createFeatureSelector<BaseStateModel<ReceiptStateModel[]>>('receipt-list');

	// informative page
	export const getInformativePageState = createFeatureSelector<BaseStateModel<InformativePageModel>>(
		'informative-page'
	);

	/**
	 * Backoffice
	 */
	// user-management
	export const getUserManagementState = createFeatureSelector<BaseStateModel<UserDetailModel[]>>('user-management');
	export const getUserManagementList = createFeatureSelector<BaseStateModel<UserDetailModel[]>>(
		'user-management-list'
	);
	// backoffice-code about user-management
	export const getBackofficeUserListState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>(
		'backoffice-user-list'
	);
	// agent
	export const getAgentListState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>('agent-list');
	// B2C
	export const getHomeHighlightsState = createFeatureSelector<BaseStateModel<HighlightsViewModel>>('home-highlights');
	export const getSearchResultsState = createFeatureSelector<BaseStateModel<ArticlePouchModel[]>>('search-results');
	export const getB2cUserListState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>('b2c-user-list');
	export const getArticleDetail = createFeatureSelector<BaseStateModel<ArticleState[]>>('article-detail');
	// CRM
	export const getCrmUserListState = createFeatureSelector<BaseStateModel<BodyTablePouchModel[]>>('crm-user-list');

	/**
	 * CRM
	 */
	// Opportunity
	export const getOpportunityState = createFeatureSelector<BaseStateModel<OpportunityPouchModel[]>>('opportunity');
	export const getOfferState = createFeatureSelector<BaseStateModel<OfferStateModel[]>>('offer');

	// Kanban
	export const getKanbanState = createFeatureSelector<BaseStateModel<KanbanPouchModel[]>>('kanban');
}
