import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment, { Moment } from 'moment';
import { UtilOrderService } from '../../../service/util/util-order.service';

@Component({
	selector: 'dialog-new-date',
	templateUrl: './dialog-new-date.component.html',
	styleUrls: ['./dialog-new-date.component.scss']
})
export class DialogNewDateComponent implements OnInit {
	form: FormGroup;
	today: Date = new Date();
	minDate: Date = new Date(
		new Date().setDate(this.today.getHours() - 12 > 0 ? this.today.getDate() + 1 : this.today.getDate())
	);
	// maxDate = moment(this.today).add(30, 'days');
	minDateSelected: Date = this.utilOrderService.getFirstValidDate(this.minDate, 1);

	myDateFilter = (d: Date): boolean => {
		const day = moment(d).day();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	};

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<DialogNewDateComponent>,
		private utilOrderService: UtilOrderService
	) {
		this.createForm();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			delay_date: [
				moment(this.data.delay_date),
				{ disabled: this.data.disabled, validators: this.data.optional ? null : Validators.required }
			]
		});

		// TODO - controllare come mai non funzionava dal form builder
		if (this.data.disabled) {
			this.form.get('delay_date').disable();
		}
	}

	onFormSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = {
			delay_date: formModel.delay_date
		};
		return saveForm.delay_date;
	}
}
