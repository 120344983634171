import { Injector } from '@angular/core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { OrganizationColumnService } from '../../../service/td-data-table/implementation/organization-list.service';

const injector = Injector.create({
	providers: [{ provide: OrganizationColumnService, deps: [] }]
});

const organizationColumnService = injector.get(OrganizationColumnService);

export namespace ConfigurationOrganizationContextApplicationColumnMap {
	export const base: ITdDataTableColumn[] = organizationColumnService.columns;

	export const excludeForBackoffice: string[] = ['add_new_order'];
	export const excludeForCrm: string[] = ['add_new_order'];
}
