<mat-card [ngClass]="config.theme" class="card-trend">
	<mat-card-content>
		<div class="card-trend__header">
			<h1>{{ config.title | translate | sentencecase }}</h1>
			<h2 class="subtitle">{{ config.subtitle | translate | sentencecase }}</h2>
		</div>
		<div class="card-trend__content row">
			<div class="col-6 value">
				<p class="number-value">{{ config.value }}{{ config.valueType }}</p>
				<p>{{ config.time | translate | sentencecase }}</p>
			</div>

			<div class="col-6 card-trend__content__trend">
				<p>
					<mat-icon [ngClass]="{ warn: config.trend < 0, success: config.trend > 0 }">
						<ng-container *ngIf="config.trend < 0" class="warn">
							trending_down
						</ng-container>
						<ng-container *ngIf="config.trend === 0">
							trending_flat
						</ng-container>
						<ng-container *ngIf="config.trend > 0">
							trending_up
						</ng-container>
					</mat-icon>
					<span class="trend-value">{{ config.trend }} %</span>
				</p>
			</div>
		</div>
		<ng-content></ng-content>
	</mat-card-content>
</mat-card>
