import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { UtilService } from '@saep-ict/angular-core';

@Component({
	selector: 'receipt-list-wrapper',
	templateUrl: './receipt-list-wrapper.component.html',
	styleUrls: ['./receipt-list-wrapper.component.scss']
})
export class ReceiptListWrapperComponent extends LocalListHandlerWidgetWrapper {
	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}

	printReceipt(downloadPath: string) {
		window.open(downloadPath);
	}
}
