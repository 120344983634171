import { Injectable } from '@angular/core';
import { AddressPouchModel, ContactTypeEnum } from '@saep-ict/pouch_agent_models';
import { CommentStateModel } from '../../model/state/comment-state.model';
import { OpportunityLogModel, OpportunityPouchModel } from '../../model/state/opportunity-state.model';
import { OpportunityStatusCodeEnum } from '../../model/state/opportunity-state.model';
import { OrganizationStateModel } from '../../model/state/organization-state.model';

@Injectable({
	providedIn: 'root'
})
export class MockOpportunityService {
	constructor() {}

	organizationAddress: AddressPouchModel = {
		zip_code: '25010',
		address: 'Via Lontana, 12',
		locality: 'Milano',
		country: 'IT',
		province: 'MI'
	};

	organization_001: OrganizationStateModel = {
		_id: 'customer_009076',
		type: 'customer',
		code_company: '005',
		code: '009076',
		banks_descriptions: [],
		business_name: 'CARPIGNANO SPA',
		destination_list: [
			{
				business_name: 'CARPIGNANO SPA',
				address: {
				address: 'CORSO LOMBARDIA 205',
				locality: 'TORINO',
				zip_code: '10151',
				province: 'TO',
				country: 'IT'
			},
			valid: true
		}
		],
		tax_data: {
			vat_number: '02441700016',
			tax_code: '02441700016'
		},
		reference_internal: 'CARPIGNANO',
		charge_transport_costs: 'S',
		domestic_foreign: 'I',
		code_price_list: '10',
		group_orders_ddt: 'N',
		group_ddt_billing: 'S',
		order_confirmation_print: 'S',
		order_confirmation_send: 'N',
		excerpts_process: 'P',
		customer_type_supply_b: '22',
		valid: true,
		status: 'ENABLED',
		crm: {
			organization_rating: 50,
			organization_international_rating: 'B'
		},
		contact_list: [
			{
				_id: 'contact_000001_00001',
				_rev: '11-e73ef7ea855b95365a2dbd9875513f23',
				type: 'contact',
				full_name: 'SILLA SAS DI MATTEI & C.',
				first_name: 'SILLA SAS',
				last_name: 'DI MATTEI & C.',
				role: 'Frontend',
				email: 'amministrazione.ro@silla.it',
				phone: '33341902778'
			}
		]
	};

	comment_001: CommentStateModel = {
		created_on: 1605086100000,
		user_id: 'user_001',
		text:
			'Ei integre referrentur vim. Sint populo assentior ei duo, graeco assueverit eum at, eum iudico libris sadipscing ut. Ne eam case dolorem. Odio oblique id per. Ei usu adhuc ancillae maiestatis.'
	};

	comment_002: CommentStateModel = {
		created_on: 1605082800000,
		user_id: 'user_001',
		text:
			'Quodsi lobortis constituam mei cu. Zril instructior ne vim. Ad debet latine fuisset mei. Magna decore usu in. An eum sensibus definiebas, te vis modo ludus tritani.'
	};
	comment_003: CommentStateModel = {
		created_on: 1605027660000,
		user_id: 'user_001',
		text:
			'Et solum vivendo mei, vis viris inimicus te. Quo ad qualisque assentior voluptatum. Eam erat efficiantur id, ut reque conceptam deseruisse ius. Cibo liber ei qui, natum homero ponderum et sed.'
	};

	logList: OpportunityLogModel[] = [
		{ created_on: 1605083719299, description: 'Mario Rossi ha aggiunto un commento' },
		{ created_on: 1606172400000, description: 'Mario Rossi ha modificato l’opportunità' },
		{ created_on: 1605135600000, description: 'Mario Rossi ha creato l’opportunità' }
	];

	private opportunity_001: OpportunityPouchModel = {
		_id: '001',
		title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, nihil.',
		actual_value: 990.55,
		created_on: 1605091332228,
		estimated_close_date: 1614207600000,
		schedule_followup_prospect: 1605091332228,
		step_id: 'step_002',
		type: 'opportunity_lead',

		// organization
		// organization_id: 'organization_002',
		// organization_rating: 75,
		// organization_name: 'Company Roma SRL',
		// organization_address: this.organizationAddress,

		organization: this.organization_001,

		// referente
		contact_id: 'contact_001',
		contact_name: 'Mario Roma',

		// assegnee
		assegnee_user_id: 'assegnee_user_001',
		assegnee_user_name: 'Rosaria Bianchi',
		assegnee_avatar:
			'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9',

		// rating
		close_probability: 25,
		project_interest: 75,

		// budget
		budget_amount: 1000.0,

		has_offer: false,
		status_code: OpportunityStatusCodeEnum.Open,
		comment_list: [this.comment_002],
		log_list: this.logList
	};

	private opportunity_002: OpportunityPouchModel = {
		_id: '002',
		title: 'Sviluppo e-commerce B2B',
		actual_value: 9999.01,
		created_on: 1605083719299,
		estimated_close_date: 1605481200000,
		schedule_followup_prospect: 1605481200000,
		step_id: 'step_001',
		type: 'opportunity_lead',

		// // organization
		// organization_id: 'organization_002',
		// organization_rating: 100,
		// organization_name: 'Company Milano SRL',
		// organization_address: this.organizationAddress,
		organization: this.organization_001,

		// referente
		contact_id: 'contact_002',
		contact_name: 'Luigi Milano',

		// assegnee
		assegnee_user_id: 'assegnee_user_002',
		assegnee_user_name: 'Mario Antonio Rossi',
		assegnee_avatar: '',

		// rating
		close_probability: 25,
		project_interest: 75,

		comment_list: [this.comment_001],
		has_offer: false,
		status_code: OpportunityStatusCodeEnum.Won,

		log_list: this.logList
	};

	private opportunity_003: OpportunityPouchModel = {
		_id: '003',
		title: 'Restyling sito internet',
		actual_value: 1024.0,
		created_on: 1605083719299,
		estimated_close_date: 1606172400000,
		schedule_followup_prospect: 1605135600000,
		has_offer: true,
		step_id: 'step_003',
		type: 'opportunity_lead',

		// organization
		// organization_id: 'organization_002',
		// organization_rating: 50,
		// organization_name: 'Company Brescia SRL',
		// organization_address: this.organizationAddress,

		organization: this.organization_001,

		// referente
		contact_id: 'contact_003',
		contact_name: 'Marco Brescia',

		// assegnee
		assegnee_user_id: 'assegnee_user_002',
		assegnee_user_name: 'Mario Antonio Rossi',
		assegnee_avatar: '',

		// rating
		close_probability: 50,
		project_interest: 25,

		comment_list: [
			this.comment_001,
			this.comment_002,
			this.comment_003,
			this.comment_001,
			this.comment_002,
			this.comment_003,
			this.comment_001,
			this.comment_002,
			this.comment_003,
			this.comment_001,
			this.comment_002,
			this.comment_003
		],

		status_code: OpportunityStatusCodeEnum.Lost,
		log_list: this.logList
	};

	private opportunity_004: OpportunityPouchModel = {
		_id: '004',
		title: 'Hosting Unix',
		actual_value: 99.0,
		created_on: 1605083719299,
		estimated_close_date: 1604185200000,
		schedule_followup_prospect: 1605135600000,
		has_offer: true,
		step_id: 'step_001',
		type: 'opportunity_lead',

		// organization
		// organization_id: 'organization_003',
		// organization_rating: 100,
		// organization_name: 'Company Torino SRL',
		// organization_address: this.organizationAddress,
		organization: this.organization_001,

		// referente
		contact_id: 'contact_004',
		contact_name: 'Alfio Torino',

		// assegnee
		assegnee_user_id: 'assegnee_user_002',
		assegnee_user_name: 'Mario Antonio Rossi',
		assegnee_avatar: '',

		// rating
		close_probability: 50,
		project_interest: 75,

		comment_list: [],
		status_code: OpportunityStatusCodeEnum.Won,
		log_list: this.logList
	};

	public opportunityList_001 = [this.opportunity_002, this.opportunity_004];
	public opportunityList_002 = [this.opportunity_001];
	public opportunityList_003 = [this.opportunity_003];

	public stepList = [
		{ id: 'step_001', title: 'start', cardList: this.opportunityList_001 },
		{ id: 'step_002', title: 'middle', cardList: this.opportunityList_002 },
		{ id: 'step_003', title: 'end', cardList: this.opportunityList_003 }
	];
}
