<app-page-header [title]="'Carrello'" [breadcrumbsList]="['home', 'Carrello']"></app-page-header>

<div class="cart__list">
	<div class="container">
		<div class="row">
			<div class="col-12" *ngIf="order?.product_list.length; else noProducts">
				<ng-container *ngFor="let product of order.product_list; let i = index">
					<mat-divider *ngIf="i"></mat-divider>
					<div class="row cart__item mt-3 mb-3">
						<div class="col-md-2 col-lg-2 d-none d-lg-block">
							<img 
								*ngIf="product.articleDetail.image_path; else imgPlaceholder"
								[src]="product.articleDetail.image_path"
								[alt]="product.articleDetail.description"
								[title]="product.articleDetail.description"
							/>
							<ng-template #imgPlaceholder>
								<img [src]="IMG_PLACEHOLDER_PRODUCT">
							</ng-template>
						</div>
						<div class="col-md-12 col-lg-4">
							<div class="cart__item__head">
								<h3 class="cart__item__title">{{ product.articleDetail.description }}</h3>
								<!-- <p class="cart__item__variant">{{ product.variant }}</p> -->
								<button mat-button color="warn" (click)="removeItem(product.code)">
									Rimuovi prodotto dal carrello
								</button>
							</div>
						</div>
						<div class="col-md-4 col-lg-1">
							<div class="cart__item__price">
								<span class="cart__item__label"> Prezzo </span>
								<span class="cart__item__value">
									{{ getPrice(product) | currency: 'EUR':'symbol':'':'it' }}
								</span>
							</div>
						</div>
						<div class="col-md-4 col-lg-3">
							<div class="cart__item__quantity">
								<span class="cart__item__label"> Quantità </span>
								<span class="cart__item__value">
									<app-quantity-button
										[value]="product.ordered_quantity"
										(changeValue)="changeQuantity($event, product.code)"
									></app-quantity-button>
									<small class="cell-subtitle">
										<span
											[innerHTML]="
												'(' +
												math.round(
													product.articleDetail.qty_box * product.ordered_quantity * 100
												) /
													100 +
												' ' +
												measureFormatter.getMeasure(
													product.articleDetail.um_warehouse,
													product.articleDetail.qty_box
												) +
												')'
											"
										></span>
									</small>
								</span>
							</div>
						</div>
						<div class="col-md-4 col-lg-2">
							<div class="cart__item__total">
								<span class="cart__item__label"> Totale </span>
								<span class="cart__item__value">
									{{
										getPrice(product) * product.ordered_quantity | currency: 'EUR':'symbol':'':'it'
									}}
								</span>
							</div>
						</div>
					</div>
				</ng-container>

				<div class="row cart__subtotal">
					<div class="col-md-8 col-lg-10 mb-3">
						<span class="cart__subtotal__label">Totale parziale</span>
					</div>
					<div class="col-md-4 col-lg-2">
						<span class="cart__subtotal__value">
							{{ calculateSubtotal() | currency: 'EUR':'symbol':'':'it' }}
						</span>
					</div>
					<div class="col-12">
						<p class="cart__subtotal__message">
							L’IVA e le spedizione di spedizione verranno calcolate al momento del pagamento
						</p>
					</div>
				</div>

				<div class="row cart__action">
					<div class="col-12">
						<a
							class="cart__action__button"
							mat-stroked-button
							href="javascript:void(0)"
							[routerLink]="['/' + ROUTE_URL.public]"
						>
							Continua lo shopping
						</a>
						<a
							class="cart__action__button"
							mat-raised-button
							color="accent"
							href="javascript:void(0)"
							[routerLink]="[ROUTE_URL.checkout]"
						>
							Procedi con il pagamento
						</a>
					</div>
				</div>
			</div>

			<ng-template #noProducts>
				<div class="col-12 cart__no-products">
					<p>Nel tuo carrello non ci sono ancora prodotti</p>
					<a
						mat-raised-button
						color="accent"
						class="custom-button"
						[routerLink]="['/' + ROUTE_URL.public]"
						href="javascript:void(0)"
					>
						Continua lo shopping
					</a>
				</div>
			</ng-template>
		</div>
	</div>
</div>
