import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { HomeHighlightsActionEnum, HomeHighlightsStateAction } from './home-highlights.actions';
import { BaseStateModel } from '@saep-ict/angular-core';
import { HighlightsViewModel } from '../../model/home-highlights.model';

export namespace HomeHighlightsStateReducer {
	export const initialState: BaseStateModel<HighlightsViewModel> = null;

	const _reducer = createReducer(
		initialState,
		on(HomeHighlightsStateAction.load, state => state),
		on(HomeHighlightsStateAction.update, (state, highlights) => highlights),
		on(HomeHighlightsStateAction.reset, () => initialState),
		on(HomeHighlightsStateAction.error, (state, highlights) => ({
			data: state ? state.data : null,
			type: highlights.type
		}))
	);

	export function reducer(state: BaseStateModel<HighlightsViewModel>, action: TypedAction<HomeHighlightsActionEnum>) {
		return _reducer(state, action);
	}
}
