import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// widget & utility
import { UtilService } from '@saep-ict/angular-core';
import { ContextApplicationItemCodeEnum, LocalListHandlerWidgetWrapper } from '@saep-ict/angular-core';
import { DEFAULT_DESTINATION_CODE } from '../../../constants/destination.constants';

@Component({
	selector: 'organization-list-wrapper',
	templateUrl: './organization-list-wrapper.component.html',
	styleUrls: ['./organization-list-wrapper.component.scss']
})
export class OrganizationListWrapperComponent extends LocalListHandlerWidgetWrapper {
	@Input() currentContext: ContextApplicationItemCodeEnum;
	@Input() canAddNew: boolean = false;
	@Output() selectCompany: EventEmitter<{ level: string; code: string }> = new EventEmitter();
	@Output() openOrderNew: EventEmitter<string> = new EventEmitter();

	// enum
	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	DEFAULT_DESTINATION_CODE = DEFAULT_DESTINATION_CODE;

	constructor(public utilService: UtilService, translate: TranslateService) {
		super(utilService, translate);
	}
}
