<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
	<div class="row">
		<div class="col-md-12">
			<h2 mat-dialog-title>
				{{ data.title | translate | sentencecase }}
			</h2>
		</div>
	</div>

	<mat-dialog-content class="mat-typography">
		<div class="row">
			<div class="col-md-12">
				<!-- Indirizzo -->
				<mat-form-field>
					<mat-label>{{ 'general.address.address' | translate | sentencecase }}</mat-label>
					<input matInput formControlName="address" />
					<mat-error>
						<form-error-message ngDefaultControl [formControl]="form.get('address')"></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-12">
				<!-- Città -->
				<mat-form-field>
					<mat-label>{{ 'general.address.city' | translate | sentencecase }}</mat-label>
					<input matInput formControlName="locality" />
					<mat-error>
						<form-error-message ngDefaultControl [formControl]="form.get('locality')"></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<!-- CAP -->
				<mat-form-field>
					<mat-label>{{ 'general.address.zip_code' | translate | sentencecase }}</mat-label>
					<input
						matInput
						appOnlyNumbers
						formControlName="zip_code"
						[maxlength]="zipCodeMinLength"
						#zip_code
						placeholder="#####"
					/>
					<mat-hint align="end">{{ zip_code.value?.length || 0 }}/{{ zipCodeMinLength }}</mat-hint>
					<mat-error>
						<form-error-message
							ngDefaultControl
							[formControl]="form.get('zip_code')"
							[minLengthValue]="zipCodeMinLength"
						></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<!-- Provincia -->
				<mat-form-field>
					<mat-label>{{ 'general.address.province' | translate | sentencecase }}</mat-label>
					<input
						matInput
						appUppercase
						formControlName="province"
						[maxlength]="provinceMinLength"
						#province
						placeholder="##"
					/>
					<mat-hint align="end">{{ province.value?.length || 0 }}/{{ provinceMinLength }}</mat-hint>
					<mat-error>
						<form-error-message
							ngDefaultControl
							[formControl]="form.get('province')"
							[minLengthValue]="provinceMinLength"
						></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="col-md-4">
				<!-- Nazione -->
				<mat-form-field>
					<mat-label>{{ 'general.address.country' | translate | sentencecase }}</mat-label>
					<input
						matInput
						appUppercase
						formControlName="country"
						#country
						[maxlength]="countryMinLength"
						placeholder="##"
					/>
					<mat-hint align="end">{{ country.value?.length || 0 }}/{{ countryMinLength }}</mat-hint>
					<mat-error>
						<form-error-message
							ngDefaultControl
							[formControl]="form.get('country')"
							[minLengthValue]="countryMinLength"
						></form-error-message>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions *ngIf="!data.disabled">
		<!-- Da controllare -->
		<mat-toolbar class="height-auto">
			<button mat-button type="button" [mat-dialog-close]="false">
				{{ 'general.delete' | translate | sentencecase }}
			</button>
			<button mat-button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
				{{ 'general.confirm' | translate | sentencecase }}
			</button>
		</mat-toolbar>
	</mat-dialog-actions>
</form>
