import { BaseTableListService, ITdDataTableColumnCustom } from '@saep-ict/angular-core';
import { ITdDataTableColumn } from '@covalent/core/data-table';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class ContactColumnService extends BaseTableListService {
	constructor(@Inject(TranslateService) public translate: TranslateService) {
		super();
	}
	_columns: ITdDataTableColumnCustom[] = [
		{
			name: 'status',
			label: '',
			width: 50
		},
		{
			name: 'full_name',
			labelPath: 'contact.name',
			label: null,
			width: { min: 150, max: 200 }
		},
		{
			name: 'contact_details',
			labelPath: 'contact.details',
			label: null
		},
		{
			name: 'area',
			labelPath: 'contact.field.area',
			label: null
		},
		{
			name: 'organization_details',
			labelPath: 'organization.name',
			label: null,
			width: { min: 150, max: 200 }
		},
		{
			name: 'order_confirm_email',
			labelPath: 'contact.field.order_confirm_email',
			label: null,
			width: 300
		},
		{
			name: 'actions',
			label: '',
			width: 100
		}
	];
}
